import axios from '../Axios';

const getAuthCodeFromAzure = (projectID) => {
  return window.open(
    `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
    client_id=${process.env.REACT_APP_AZURE_INTEGRATION_CLIENT_ID}&
    response_type=code&
    redirect_uri=${process.env.REACT_APP_BASEURL}/integrations/azure&
    scope=ChannelMessage.Send Team.ReadBasic.All Chat.ReadWrite Group.ReadWrite.All offline_access openid profile&
    state=${projectID}
    `,
    '_self',
  );
};

const setAzureAuthToken = (code, projectID) => {
  return axios.post(`/projects/${projectID}/integrations/azure`, {
    code,
  });
};

const getTeams = ({ projectID, integrationID }) => {
  return axios.get(
    `/projects/${projectID}/integrations/azure/${integrationID}/teams`,
  );
};

const getChannels = ({ projectID, integrationID, teamID }) => {
  return axios.get(
    `/projects/${projectID}/integrations/azure/${integrationID}/channels?teamID=${teamID}`,
  );
};

const setAzureActions = ({ projectID, integrationID, actions, settings }) => {
  return axios.put(
    `/projects/${projectID}/integrations/azure/${integrationID}`,
    {
      actions,
      settings,
    },
  );
};

const disconnectAzureIntegration = ({ projectID, integrationID }) => {
  return axios.delete(
    `/projects/${projectID}/integrations/azure/${integrationID}`,
  );
};

export {
  getAuthCodeFromAzure,
  setAzureAuthToken,
  getTeams,
  getChannels,
  setAzureActions,
  disconnectAzureIntegration,
};
