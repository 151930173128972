import classNames from 'classnames';
import './DropDownShowItems.scss';
import LinkButton from 'components/LinkButton/LinkButton';
import { useRef, useState } from 'react';
import { useClickedOutside, useEscape } from 'services/Helper';

interface DropDownShowItemsProps {
    label?: string;
    className?: any;
    items?: {
        label: string;
        icon?: string;
        iconType?: string;
        onClick: any;
        active?: boolean;
    }[];
}

const DropDownShowItems = ({
    label,
    className,
    items,
}: DropDownShowItemsProps) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const linkButtonClassName = classNames(
        {
            'dropdown-button': true,
            'dropdown-button--opened': showDropdown,
        },
        className,
    );

    const wrapperRef = useRef(null);
    useClickedOutside(wrapperRef, () => {
        setShowDropdown(false);
    });
    useEscape(() => {
        setShowDropdown(false);
    });

    return (
        <div className={linkButtonClassName} ref={wrapperRef}>
            <LinkButton
                label={label}
                className="mr-10 hide-on-mobile"
                iconSideRight={true}
                icon="caret-down"
                faIconType='solid'
                onClick={() => {
                    setShowDropdown(!showDropdown);
                }}
            />
            {showDropdown && (
                <div className="dropdown-view">
                    {items && items.map((item: any, index: number) => {
                        const selectionClassName = classNames(
                            'dropdown-item',
                            { 'dropdown-item--selected': item.active },
                        );
                        return (
                            <div
                                key={index}
                                className={selectionClassName}
                                onClick={() => {
                                    item.onClick();
                                }}
                            >
                                <div className='dropdown-item-first'>
                                    <div className="dropdown-item-first-icon">
                                        {item.icon && <i className={`icon fa-${item.iconType ?? 'regular'} fa-${item.icon}`} />}
                                    </div>
                                    <div className="dropdown-item-first-label">
                                        <span>{item.label}</span>
                                    </div>
                                </div>
                                {item.active && (
                                    <div className="dropdown-item-second">
                                        <i className="fa-solid fa-check" />
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default DropDownShowItems;
