import { useCallback, useEffect, useState } from 'react';
import './SharedFeatureRequestsListContainer.scss';
import { inject, observer } from 'mobx-react';
import { useParams } from 'react-router';
import FeatureStatusRequestFilter from 'components/FeatureStatusRequestFilter/FeatureStatusRequestFilter';
import SharedFeatureRequestsList from 'components/SharedFeatureRequestsList/SharedFeatureRequestsList';
import FeatureRequestsOrder, { PublicOrderFilters } from 'components/FeatureRequestsOrder/FeatureRequestsOrder';
import { useLocation } from 'react-router';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { SharedBoardStorePublic } from 'stores/public/SharedBoardStorePublic';
import { SharedPoweredBy } from '../SharedPoweredBy/SharedPoweredBy';
import { runInAction, set } from 'mobx';
import LinkButton from 'components/LinkButton/LinkButton';
import { debounce } from 'lodash';

interface SharedFeatureRequestsListContainerProps {
  sharedBoardStorePublic?: SharedBoardStorePublic;
  widgetMode?: boolean;
}

const SharedFeatureRequestsListContainer = ({
  sharedBoardStorePublic,
  widgetMode,
}: SharedFeatureRequestsListContainerProps) => {
  const sharedFeatureRequestStates =
    sharedBoardStorePublic?.getFeatureRequestStates(false);
  const { boardShareToken } = useParams();
  const currentTicketDataFilter =
    sharedBoardStorePublic?.currentTicketDataFilter;
  const [statusFilter, setStatusFilter] = useState('NOT_DONE');
  const [order, setOrder] = useState(sharedBoardStorePublic?.currentProject?.flowConfig?.defaultRoadmapOrder ?? 'VOTES');
  const [tags, setTags] = useState([] as String[]);
  const location = useLocation();

  const [isSearchBarVisible, setSearchBarVisibility] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const featureRequests = searchTerm.length > 0 ? sharedBoardStorePublic?.featureRequestListSearch.data : sharedBoardStorePublic?.featureRequestList.data;
  const toggleSearchBar = () => {
    setSearchBarVisibility(!isSearchBarVisible);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const debounceSearch = useCallback(
    debounce((nextValue) => sharedBoardStorePublic?.searchGlobalForSharedTickets(nextValue), 750),
    [],
  );

  const clearSearchBar = () => {
    setSearchTerm('');
    setSearchBarVisibility(false);
    sharedBoardStorePublic?.fetchAndSetFeatureRequestList({});
  };

  useEffect(() => {
    sharedBoardStorePublic?.fetchAndSetFeatureRequestList({});
  }, []);

  useEffect(() => {
    if (featureRequests) {
      var newTags: any = {};
      featureRequests.forEach((featureRequest) => {
        featureRequest.tags?.forEach((tag) => {
          if (!newTags[tag]) {
            newTags[tag] = 0;
          }
          newTags[tag]++;
        });
      });
      setTags(newTags);
    }
  }, [featureRequests]);

  useEffect(() => {
    sharedBoardStorePublic?.filterFeatureRequestList();
  }, [currentTicketDataFilter]);

  useEffect(() => {
    const sort = PublicOrderFilters[order];
    if (sort && sort !== sharedBoardStorePublic?.currentTicketDataSort) {
      sharedBoardStorePublic!.currentTicketDataSort = sort;
      sharedBoardStorePublic?.filterFeatureRequestList();
    }
  }, [order]);

  useEffect(() => {
    runInAction(() => {
      if (statusFilter === 'NOT_DONE') {
        sharedBoardStorePublic!.currentTicketDataFilter = {};
      } else {
        sharedBoardStorePublic!.currentTicketDataFilter = {
          ...currentTicketDataFilter,
          status: statusFilter,
        };
      }
      sharedBoardStorePublic?.filterFeatureRequestList();
    });
  }, [statusFilter]);

  useEffect(() => {
    try {
      const statusFilter = localStorage.getItem(
        `feature_request_board_status_filter${sharedBoardStorePublic?.currentProject?.id}`,
      );
      if (statusFilter) {
        setStatusFilter(statusFilter);
      }

      const params = new URLSearchParams(location.search);
      if (params.get('filter')) {
        setStatusFilter(params.get('filter')!);
      }
    } catch (exp) { }
  }, []);

  if (!sharedBoardStorePublic?.currentProject) {
    return null;
  }

  const renderStatusFilter = () => {
    return (
      <div className="board-filter-status">
        <div className="filter-title">
          {sharedBoardStorePublic?.translateText('featureRequestStatus')}
        </div>
        <FeatureStatusRequestFilter
          featureRequestStates={sharedFeatureRequestStates}
          value={statusFilter ? 'NOTDONE' : statusFilter}
          onValueChanged={(newValue) => {
            if (newValue === 'NOTDONE') {
              setStatusFilter('NOT_DONE');
              try {
                localStorage.removeItem(
                  `feature_request_board_status_filter${sharedBoardStorePublic?.currentProject?.id}`,
                );
              } catch (exp) { }
            } else {
              setStatusFilter(newValue!);
              try {
                localStorage.setItem(
                  `feature_request_board_status_filter${sharedBoardStorePublic?.currentProject?.id}`,
                  newValue!,
                );
              } catch (exp) { }
            }
          }}
        />
      </div>
    );
  };

  const renderTagFilter = () => {
    if (tags.length === 0) {
      return null;
    }

    return (
      <div className="tag-filters">
        <div className="filter-title mt-30">
          {sharedBoardStorePublic?.translateText('featureRequestTags')}
        </div>
        <div className="tags mt-5">
          {Object.keys(tags).map((key) => {
            return (
              <div
                className="tag"
                key={key}
                onClick={() => {
                  runInAction(() => {
                    if (
                      currentTicketDataFilter.tags &&
                      currentTicketDataFilter.tags.includes(key)
                    ) {
                      currentTicketDataFilter.tags =
                        currentTicketDataFilter.tags.filter((tag) => tag !== key);
                    } else {
                      if (!currentTicketDataFilter.tags) {
                        currentTicketDataFilter.tags = [];
                      }

                      currentTicketDataFilter.tags = [
                        ...currentTicketDataFilter.tags,
                        key,
                      ];
                    }

                    sharedBoardStorePublic!.currentTicketDataFilter = {
                      ...currentTicketDataFilter,
                    };
                  });
                }}
              >
                <div className="tag-name">
                  <span>#</span>
                  {key}
                </div>
                {currentTicketDataFilter.tags &&
                  currentTicketDataFilter.tags.includes(key) ? (
                  <div className="tag-selected">
                    <i className="fa-sharp fa-solid fa-check"></i>
                  </div>
                ) : (
                  <div className="tag-count">{tags[key]}</div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="shared-feature-requests-board-main-container">
      {!widgetMode && (
        <div className="shared-feature-requests-board-filters">
          {renderStatusFilter()}
          {renderTagFilter()}
          {!sharedBoardStorePublic?.currentProject?.flowConfig
            ?.hideBranding && <SharedPoweredBy />}
        </div>
      )}
      <div className="shared-feature-requests-board">
        <div className={`board-filter-header ${!widgetMode && "board-filter-header--web"} `}>
          <div
            className={`board-filter-sort ${!widgetMode && 'board-filter-sort--webmode'
              }`}
          >
            <div className={`${widgetMode ? "filter-title" : "board-filter-header-title"}`}>
              {sharedBoardStorePublic?.translateText('featureRequestShow')}
            </div>
            <FeatureRequestsOrder
              value={order}
              onValueChanged={(newValue: any) => {
                try {
                  setOrder(newValue.key);
                } catch (exp) { }
              }}
            />
          </div>
          <div className="board-filter-header-right">
            {widgetMode && renderStatusFilter()}
            {!widgetMode && (
              <>
                <div className="search-bar-container hide-on-mobile">
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={(text) => {
                      handleSearch(text);
                      debounceSearch(text.target.value);
                    }}
                    placeholder={sharedBoardStorePublic?.translateText('search')}
                    className={`special-list-component-searchbar mr-10 ${isSearchBarVisible ? 'visible' : ''}`}
                  />
                </div>
                <LinkButton
                  icon={isSearchBarVisible ? "fa-solid fa-times" : "fa-solid fa-search"}
                  onClick={isSearchBarVisible ? clearSearchBar : toggleSearchBar}
                  className='hide-on-mobile mr-10'
                />
              </>
            )}
            {!widgetMode && sharedBoardStorePublic?.showFeatureRequestButton && (
              <PrimaryButton
                onClick={() => {
                  (window as any).Gleap.startFeedbackFlow('featurerequests');
                }}
                icon="plus"
                iconSideRight={false}
                label={sharedBoardStorePublic?.translateText('requestFeature')}
              />
            )}
          </div>
        </div>
        <SharedFeatureRequestsList
          statusFilter={statusFilter}
          isShared
          isSearchActive={searchTerm.length > 0}
          widgetMode={widgetMode}
          boardShareToken={boardShareToken}
          showUnreadOnly={false}
        />
      </div>
    </div>
  );
};

export default inject('sharedBoardStorePublic')(
  observer(SharedFeatureRequestsListContainer),
);
