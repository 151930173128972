import React, { useState, useEffect, useCallback } from 'react';
import CalendarRangePicker from 'components/CalendarRangePicker/CalendarRangePicker';
import TabButton from 'components/TabButton/TabButton';
import moment, { Moment } from 'moment';
import './SmartDateRangePicker.scss';

interface SmartDateRangePickerProps {
  onDateChange: (startDate: Moment, endDate: Moment) => void;
  startIndex?: number;
}

const initialStartDate = () => moment().subtract(1, 'months').startOf('day') as Moment | null;
const initialEndDate = () => moment().endOf('day') as Moment | null;

const SmartDateRangePicker: React.FC<SmartDateRangePickerProps> = ({ onDateChange, startIndex = 0 }) => {
  const [startDate, setStartDate] = useState<Moment | null>(initialStartDate);
  const [endDate, setEndDate] = useState<Moment | null>(initialEndDate);
  const [activeIndex, setActiveIndex] = useState<number>(startIndex);
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);

  const handleDateChange = useCallback((start: Moment, end: Moment) => {
    const startOfDay = start.clone().startOf('day');
    const endOfDay = end.clone().endOf('day');

    setStartDate(startOfDay);
    setEndDate(endOfDay);
    onDateChange(startOfDay, endOfDay);
  }, [onDateChange]);

  useEffect(() => {
    if (activeIndex !== 5) {
      const now = moment().endOf('day');
      let start: Moment | null = null;
      switch (activeIndex) {
        case 0:
          start = moment().subtract(7, 'days').startOf('day');
          break;
        case 1:
          start = moment().subtract(1, 'month').startOf('day');
          break;
        case 2:
          start = moment().startOf('year').startOf('day');
          break;
        case 3:
          start = moment().subtract(1, 'year').startOf('day');
          break;
        case 4:
          start = moment().subtract(4, 'years').startOf('day');
          break;
        default:
          break;
      }
      if (start && (!startDate || !endDate || !start.isSame(startDate) || !now.isSame(endDate))) {
        handleDateChange(start, now);
      }
    }
  }, [activeIndex, startDate, endDate, handleDateChange]);

  const items = [
    { label: '1W', index: 0 },
    { label: '1M', index: 1 },
    { label: 'YTD', index: 2 },
    { label: '1Y', index: 3 },
    { label: 'MAX', index: 4 },
    { label: 'Custom', icon: 'fa-regular fa-calendars calendar-range-picker-icon', index: 5 },
  ];

  return (
    <>
      <div className="tab-buttons">
        <div className="tab-buttons--inner">
          {items.map(({ label, icon, index }) => (
            <TabButton
              key={label}
              active={activeIndex === index}
              label={label}
              icon={icon}
              onClick={() => setActiveIndex(index)}
            />
          ))}
        </div>
      </div>
      {activeIndex === 5 && (
        <CalendarRangePicker
          startDate={startDate ?? moment()}
          endDate={endDate ?? moment()}
          onDatesChange={({ startDate, endDate }) => {
            if (startDate && endDate) handleDateChange(startDate, endDate);
          }}
        />
      )}
    </>
  );
};

export default SmartDateRangePicker;
