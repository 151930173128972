import Loading from 'components/Loading/Loading';
import { inject, observer } from 'mobx-react';
import { ModalStore } from 'stores/private/ModalStore';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import './Billing.scss';
import PaddleBilling from './PaddleBilling';
import PaddleBillingNew from './PaddleBillingNew';

interface BillingProps {
  organisationStore?: OrganisationStore;
  modalStore?: ModalStore;
}

const Billing = ({ organisationStore, modalStore }: BillingProps) => {
  if (!organisationStore?.currentOrganisation) {
    return <Loading />;
  }

  const currentSubscriptionMetaData =
    organisationStore?.currentOrganisation?.subscription?.metadata ?? {};

  if (
    currentSubscriptionMetaData.seatbased ||
    currentSubscriptionMetaData.newpricing
  ) {
    return <PaddleBillingNew />;
  }

  return <PaddleBilling />;
};

export default inject('organisationStore', 'modalStore')(observer(Billing));
