import ApiKey from 'components/ApiKey/ApiKey';
import InstructionCard from 'components/InstructionCard/InstructionCard';
import LinkButton from 'components/LinkButton/LinkButton';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TabButton from 'components/TabButton/TabButton';
import { inject, observer } from 'mobx-react';
import { Platforms } from 'models/Platform';
import { useState } from 'react';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './ProjectInstallation.scss';

interface ProjectInstallationProps {
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
}

const ProjectInstallation = ({
  modalStore,
  projectStore,
}: ProjectInstallationProps) => {
  const [filter, setFilter] = useState('all');
  const currentProject = projectStore?.currentProject;

  if (!currentProject) {
    return <></>;
  }

  const openInstruction = (platform: any) => {
    modalStore?.openModalSmart(MODALTYPE.SDK_INSTALLATION_TUTORIAL, platform);
  };

  const renderPlatforms = () => {
    const items = Platforms.filter((item) => {
      if (filter === 'all') {
        return true;
      }
      return item.type.includes(filter);
    });

    return items.map((item, key) => {
      return (
        <InstructionCard
          data-name={item.title}
          item={item}
          key={key}
          onClick={openInstruction}
        />
      );
    });
  };

  const renderHeaderSection = () => {
    if (!projectStore?.currentProject?.sdkInstalled) {
      return (
        <>
          <div className="sdk-setup-left mt-5 mb-30">
            <div className="title mb-5">Need help getting started?</div>
            <div className="text mb-15">
              Invite your dev team or schedule a support session with one of our experts.
            </div>
            <PrimaryButton
              label="Invite your team"
              icon="circle-user"
              className="mr-10"
              onClick={() => {
                modalStore!.openModal(MODALTYPE.INVITE_PEOPLE);
              }}
            />
            <LinkButton
              onClick={() => {
                window.open('https://calendly.com/hussein-gleap/30min', '_blank');
              }}
              icon="message-question"
              iconSideRight={false}
              label="Book a demo"
            />
          </div>
        </>
      );
    }

    return null;
  };

  return (
    <PageContainer className="project-installation">
      <PageHeadLine title="Install the Gleap SDK">
        <div className="header-content-right hide-on-mobile">
          {projectStore?.currentProject?.sdkInstalled && (
            <>
              <LinkButton
                onClick={() => {
                  window.open('https://calendly.com/hussein-gleap/30min', '_blank');
                }}
                icon="circle-question"
                iconSideRight={false}
                className="mr-10"
                label="Schedule a support session"
              />
              <PrimaryButton
                label="Invite your team"
                icon="circle-user"
                onClick={() => {
                  modalStore!.openModal(MODALTYPE.INVITE_PEOPLE);
                }}
              />
            </>
          )}
        </div>
      </PageHeadLine>
      <PageContent hasTitle>
        <div className="project-installation-inner-container">
          <div className="project-installation-body mt-50">
            {renderHeaderSection()}
            <div className='sdk-install-header'>
              <div>
                <div className="text bold mb-15">
                  <b>Choose your platform to get started:</b>
                </div>
                <div className="tab-buttons">
                  <div className="tab-buttons--inner">
                    <TabButton
                      active={filter === 'all'}
                      label="All"
                      onClick={() => {
                        setFilter('all');
                      }}
                    />
                    <TabButton
                      active={filter === 'websites'}
                      label="Website"
                      onClick={() => {
                        setFilter('websites');
                      }}
                    />
                    <TabButton
                      active={filter === 'webapps'}
                      label="Web app"
                      onClick={() => {
                        setFilter('webapps');
                      }}
                    />
                    <TabButton
                      active={filter === 'mobileapps'}
                      label="App"
                      onClick={() => {
                        setFilter('mobileapps');
                      }}
                    />
                  </div>
                </div>
              </div>
              <ApiKey apiKey={projectStore?.currentProject?.apiKey ?? '--'} />
            </div>
            <div className="platforms mb-30 mt-15">{renderPlatforms()}</div>
          </div>
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'modalStore',
  'projectStore',
)(observer(ProjectInstallation));
