import { convertTipTapToHtml } from 'helper/TipTapHelper';
import linkifyHtml from 'linkifyjs/lib/linkify-html';
import React from 'react';
import './RichTextEditor.scss';
import { replaceMarkdown } from 'components/PerformAIAction/PerformAIAction';

interface TipTapPreviewProps {
  content: any;
  fallbackValue?: string;
  className?: string;
}

const TipTapPreview = ({ content, fallbackValue, className }: TipTapPreviewProps) => {
  let htmlContent = convertTipTapToHtml({ content, fallbackValue });

  try {
    htmlContent = htmlContent.replaceAll('\n', '<br>');
    htmlContent = replaceMarkdown(htmlContent);
    htmlContent = linkifyHtml(htmlContent, {
      target: {
        url: '_blank',
      },
    });
  } catch (_) { }

  return (
    <div
      className={`ProseMirror ${className}`}
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    ></div>
  );
};

export default TipTapPreview;
