import BotConditionPicker from 'components/BotConditionPicker/BotConditionPicker';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { inject, observer } from 'mobx-react';
import { ConditionChild } from 'models/Bot';
import { ModalStore } from 'stores/private/ModalStore';
import { PropertyStore } from 'stores/private/PropertyStore';

interface ConditionActionEditorProps {
  condition: ConditionChild;
  modalStore?: ModalStore;
  propertyStore?: PropertyStore;
}

const ConditionActionEditor = ({
  condition,
  propertyStore,
  modalStore,
}: ConditionActionEditorProps) => {
  return (
    <div className="input-action-editor">
      <PageContainer>
        <PageHeadLine title="Edit condition">
          <div className="header-content-right">
            <PrimaryButton
              label="Close"
              iconSideRight={false}
              onClick={() => {
                modalStore!.closeModal();
              }}
            />
          </div>
        </PageHeadLine>
        <PageContent hasTitle>
          <BotConditionPicker predicate={condition.predicates} properties={propertyStore?.getProjectProperties(true)} />
        </PageContent>
      </PageContainer>
    </div>
  );
};

export default inject(
  'propertyStore',
  'modalStore',
)(observer(ConditionActionEditor));
