import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import {
  getLanguageProperty,
  setLanguageProperty,
} from 'helper/AssignObjectKeysHelper';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Action } from 'models/Bot';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './CalendlyActionEditor.scss';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router';

interface CalendlyActionEditorProps {
  action: Action;
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
}

const CalendlyActionEditor = ({
  action,
  modalStore,
  projectStore,
}: CalendlyActionEditorProps) => {
  const currentLang = projectStore?.currentLanguage ?? 'en';
  const navigate = useNavigate();
  const hasCalendlyIntegrations = Object.keys(projectStore?.currentProject?.integrations?.calendly ?? {}).length > 0;

  useEffect(() => {
    if (projectStore) {
      projectStore.getCalendlyEventTypes();
    }
  }, [hasCalendlyIntegrations]);

  const renderContent = () => {
    if (hasCalendlyIntegrations) {
      return (<>
        <TextInput
          name="calendly-url"
          label="Calendly link"
          value={action.calendlyLink}
          placeholder="https://calendly.com/..."
          type="text"
          error=""
          onChange={(val) => {
            runInAction(() => {
              action.calendlyLink = val;
            });
          }}
        />
        <TextInput
          name="button-text"
          className="mt-20"
          label="Button text"
          value={getLanguageProperty(action, 'buttonText', currentLang)}
          localizedable
          translationObject={action?.buttonText}
          placeholder="Book now!"
          type="text"
          error=""
          onChange={(val) => {
            runInAction(() => {
              setLanguageProperty(action, `buttonText`, currentLang, val);
            });
          }}
        />
        <TextInput
          name="intro-question"
          className="mt-20"
          label="Introduction message"
          value={getLanguageProperty(action, 'intro', currentLang)}
          localizedable
          translationObject={action?.intro}
          placeholder=""
          type="text"
          error=""
          onChange={(val) => {
            runInAction(() => {
              setLanguageProperty(action, `intro`, currentLang, val);
            });
          }}
        />
      </>);
    }

    return (<>
      <div className='text'>
        In order to use Calendly, you need to connect your Calendly account first. Integrations page and connect your Calendly account.
      </div>
      <PrimaryButton className="mt-20" label='Connect Calendly' onClick={() => {
        modalStore!.closeModal();

        navigate(`/projects/${projectStore?.currentProject?.id}/integrations/connect`);
      }} />
    </>);
  }

  return (
    <div className="calendly-action-editor">
      <PageContainer>
        <PageHeadLine title="Schedule a meeting">
          <div className="header-content-right">
            <PrimaryButton
              label="Save"
              icon="circle-check"
              iconSideRight={false}
              onClick={() => {
                if (!action.calendlyLink || !action?.calendlyLink?.length || (action.calendlyLink && action.calendlyLink.length > 0 && !action.calendlyLink.includes('https://calendly.com/'))) {
                  Swal.fire(
                    'Invalid Calendly link',
                    'Please enter a valid Calendly link starting with https://calendly.com/...',
                    'info',
                  );
                  return;
                }

                modalStore!.closeModal();
              }}
            />
          </div>
        </PageHeadLine>
        <PageContent hasTitle>
          {renderContent()}
        </PageContent>
      </PageContainer>
    </div>
  );
};

export default inject(
  'modalStore',
  'projectStore',
)(observer(CalendlyActionEditor));
