import FeedbackUserFilter from 'components/FeedbackUserFilter/FeedbackUserFilter';
import OutboundMessagePreview from 'components/OutboundMessagePreview/OutboundMessagePreview';
import { runInAction } from 'mobx';
import { Outbound } from 'models/Outbound';
import Switch from 'react-switch';
import React, { useEffect, useRef, useState } from 'react';
import './MessageConfiguration.scss';
import { ProjectStore } from 'stores/private/ProjectStore';
import { inject, observer } from 'mobx-react';
import {
  getLanguageProperty,
  setLanguageProperty,
} from 'helper/AssignObjectKeysHelper';
import EmailEditor from 'components/Editors/EmailEditor/EmailEditor';
import { Editor } from '@tiptap/react';
import Toolbar, { ToolbarItems } from 'components/Editors/ToolBar/Toolbar';
import { languages } from 'constants/Languages';
import LanguageDropdown from 'components/LanguageDropdown/LanguageDropdown';
import classNames from 'classnames';
import BotDropdown from 'components/BotDropdown/BotDropdown';
import { useParams } from 'react-router';
import { getPossibleEventDataKeysForTrigger } from 'services/OutboundHttpService';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';

interface MessageConfigurationProps {
  outboundRule: Outbound;
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

const MessageConfiguration = ({
  outboundRule,
  projectStore,
  modalStore,
}: MessageConfigurationProps) => {
  const [toggleState, setToggleState] = useState(false);
  const editorRef: React.MutableRefObject<Editor | null> = useRef(null);
  const { projectId } = useParams();
  const [eventData, setEventData] = useState<any[]>([]);
  const bots = projectStore?.bots ?? [];
  const currentProject = projectStore?.currentProject;
  const currentLang = projectStore?.currentLanguage ?? 'en';
  const availableLanguages = languages.filter((language) =>
    currentProject?.localizations.find(
      (item) => item.language === language.code,
    ),
  );

  const loadTrigger = async (projectId, eventName) => {
    const possibleDataKeys = await getPossibleEventDataKeysForTrigger(
      projectId,
      eventName,
    );
    if (possibleDataKeys && possibleDataKeys.data) {
      setEventData(possibleDataKeys.data);
    }
  };

  useEffect(() => {
    if (
      outboundRule.trigger &&
      outboundRule.trigger?.event &&
      projectStore?.currentProject?.id
    ) {
      loadTrigger(
        projectStore?.currentProject?.id,
        outboundRule.trigger?.event,
      );
    }
  }, [outboundRule.trigger]);

  useEffect(() => {
    if (projectId) {
      projectStore?.getBots(projectId);
    }
  }, [projectId]);

  const translationTableMessage = outboundRule?.message?.localized ?? {};

  const allTranslated = availableLanguages.every((item) => {
    return (
      translationTableMessage[item.code] &&
      Object.keys(translationTableMessage[item.code]).length !== 0
    );
  });

  const currentLanguage = availableLanguages.find(
    (item) => item.code === currentLang,
  );

  useEffect(() => {
    const message = getLanguageProperty(outboundRule, 'message', currentLang);
    editorRef?.current?.commands.setContent(message !== '' ? message : {});
  }, [editorRef, currentLang]);

  const messageContainerClassName = classNames({
    'message-container': true,
    'message-container--notalltranslated': !allTranslated,
    'message-container--alltranslated': allTranslated,
  });

  return (
    <div className={messageContainerClassName}>
      <div className="controls-group">
        <div className="editor-block-wrapper">
          {editorRef?.current && (
            <>
              <div className="toolbar-wrapper">
                <Toolbar
                  editor={editorRef?.current}
                  aiStyle="email"
                  additionalVariables={eventData}
                  items={[
                    ToolbarItems.Basic,
                    ToolbarItems.AI,
                    ToolbarItems.Image,
                    ToolbarItems.VARIABLES,
                  ]}
                  language={currentLang}
                />
                <Row justifyContent='flex-end' alignItems='center' gap={4}>
                  <PrimaryButton
                    fancy
                    small
                    label="AI-translate"
                    className="button-container"
                    onClick={() => {
                      modalStore?.openModalSmart(
                        MODALTYPE.DYNAMIC_AI_TRANSLATION,
                        {
                          baseLanguage: currentLanguage,
                          targetLanguage: availableLanguages,
                          languageList: availableLanguages,
                          getContent: (baseLang) => {
                            const content = getLanguageProperty(
                              outboundRule,
                              'message',
                              baseLang,
                            );
                            return {
                              content: content,
                            };
                          },
                          setContent: (translatedContent, lang) => {
                            const { content } = translatedContent;
                            if (content) {
                              runInAction(() => {
                                setLanguageProperty(
                                  outboundRule,
                                  'message',
                                  lang,
                                  content,
                                );
                              });
                              if (lang === currentLang) {
                                editorRef?.current?.commands.setContent(
                                  content !== '' ? content : {},
                                );
                              }
                            }
                          },
                        },
                      );
                    }}
                  />
                  <LanguageDropdown
                    className="language-dropdown"
                    items={availableLanguages.map((lang) => {
                      return {
                        ...lang,
                        hasTranslation:
                          translationTableMessage[lang.code] &&
                          Object.keys(translationTableMessage[lang.code])
                            .length !== 0,
                      };
                    })}
                    selectedItem={currentLanguage}
                    onChange={(value) => {
                      runInAction(() => {
                        projectStore!.currentLanguage = value.code;
                      });
                    }}
                  />
                </Row>
              </div>
              <Toolbar
                editor={editorRef?.current}
                additionalVariables={eventData}
                aiStyle="email"
                items={[
                  ToolbarItems.Basic,
                  ToolbarItems.Image,
                  ToolbarItems.AI,
                  ToolbarItems.VARIABLES,
                ]}
                language={currentLang}
                floating
              />
            </>
          )}
          <EmailEditor
            editorRef={editorRef}
            onEditorReady={() => {
              setToggleState(!toggleState);
            }}
            content={getLanguageProperty(outboundRule, 'message', currentLang)}
            inputContentChanged={(content) => {
              runInAction(() => {
                setLanguageProperty(
                  outboundRule,
                  'message',
                  currentLang,
                  content,
                );
              });
            }}
          />
        </div>
        <div className="input-label mt-20">Sender</div>
        <FeedbackUserFilter
          hasBorder
          value={outboundRule.sender}
          truncatePreview={50}
          truncate={50}
          placeholder="Select user"
          onValueChanged={(value) => {
            runInAction(() => {
              if (value) {
                outboundRule.sender = value;
                setToggleState(!toggleState);
              }
            });
          }}
        />
        <div className="filter-form mt-30">
          <div className="input-label">On notification click action</div>
          <div className="mt-10">
            <label className="bb-feedback-multiplechoice-container">
              <div className="text">
                Open conversation{' '}
                {outboundRule?.config?.botId && ' and run workflow'}
              </div>
              <input
                type="radio"
                name="continuously"
                checked={outboundRule.actionType === 'message'}
                onChange={() => {
                  runInAction(() => {
                    outboundRule.actionType = 'message';
                    setToggleState(!toggleState);
                  });
                }}
              />
              <span className="bb-feedback-multiplechoice-checkmark" />
            </label>
            {outboundRule.actionType === 'message' && (
              <div className="bot-picker">
                <BotDropdown
                  projectId={projectId}
                  value={bots.find((action) => {
                    return action.id === outboundRule?.config?.botId;
                  })}
                  options={bots.filter((bot) => bot.status === 'live')}
                  onValueChanged={(selectedItem) => {
                    runInAction(() => {
                      if (!outboundRule.config) {
                        outboundRule.config = {};
                      }

                      outboundRule.config.botId = selectedItem?.id;
                    });
                  }}
                />
              </div>
            )}
            <label className="bb-feedback-multiplechoice-container">
              <div className="text">Open messenger (notification only)</div>
              <input
                type="radio"
                name="once"
                checked={outboundRule.actionType === 'notification'}
                onChange={() => {
                  runInAction(() => {
                    outboundRule.actionType = 'notification';
                    setToggleState(!toggleState);
                  });
                }}
              />
              <span className="bb-feedback-multiplechoice-checkmark" />
            </label>
          </div>
        </div>
        <div className="input-label mt-30">Sound</div>
        <div className="switch-container mb-40">
          <Switch
            width={40}
            onColor="#2142E7"
            height={20}
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={(checked) => {
              runInAction(() => {
                outboundRule.sound = checked;
                setToggleState(!toggleState);
              });
            }}
            checked={
              outboundRule.sound === undefined || outboundRule.sound === true
            }
          />
          <span>Notification sound</span>
        </div>
      </div>
      <div className="message-preview">
        <OutboundMessagePreview outbound={outboundRule} />
      </div>
    </div>
  );
};

export default inject(
  'projectStore',
  'modalStore',
)(observer(MessageConfiguration));
