import Pusher from 'pusher-js/with-encryption';
import WebSocketMessage from './WebSocketMessage';
import { debounce } from 'lodash';

const WEBSOCKET_EVENTS = {
  BUG_CREATED: 'bug.created',
  BUG_UPDATED: 'bug.updated',
  BUG_DELETED: 'bug.deleted',
  BUG_TYPING: 'bug.typing',
  BUG_USER_READ_ALL_COMMENTS: 'bug.userreadallcomments',
  COLUMN_DELETED: 'bug.column_deleted',
  COMMENT_CREATED: 'comment.created',
  COMMENT_DELETED: 'comment.deleted',
  COMMENT_UPDATED: 'comment.updated',
  USER_INVITATION: 'invitation.updated',
  USER_ONBOARDING: 'onboarding.updated',
  CRAWLER_UPDATED: 'crawler.updated',
};

Pusher.logToConsole = false;

class WebSocketHelper {
  private static instance: WebSocketHelper;
  private stores: { [key: string]: WebSocketMessage } = {};
  private pusher?: Pusher;
  private onStatusChange?: (status: string) => void;
  private pusherWasDisconnected = false;
  private currentProject: any = null;
  private currentTicket: any = null;

  public setOnStatusChange(onStatusChange: (status: string) => void) {
    this.onStatusChange = onStatusChange;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {
    if (window && document) {
      /*document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'visible') {
          setTimeout(() => {
            if (this.computerWokeUp) {
              this.computerWokeUp = false;
              this.refreshStoreData();
            }
          }, 1000);
        }
      });

      var TIMEOUT = 5000;
      var lastTime = new Date().getTime();

      setInterval(() => {
        var currentTime = new Date().getTime();
        if (currentTime > lastTime + TIMEOUT + TIMEOUT) {
          this.computerWokeUp = true;
        }
        lastTime = currentTime;
      }, TIMEOUT);*/
    }
  }

  public static getInstance(): WebSocketHelper {
    if (!WebSocketHelper.instance) {
      WebSocketHelper.instance = new WebSocketHelper();
    }

    return WebSocketHelper.instance;
  }

  setStores(stores) {
    this.stores = stores;
  }

  public isConnected() {
    return this.pusher !== undefined;
  }

  public disconnect() {
    if (this.pusher) {
      this.pusher.disconnect();
      this.pusher = undefined;
    }
  }

  public connect = async (token: string, userId: string) => {
    if (this.pusher) {
      this.pusher.disconnect();
      this.pusher = undefined;
    }

    this.pusher = new Pusher('29b0a09928856b262405', {
      cluster: 'eu',
      authEndpoint: `${process.env.REACT_APP_BACKEND_URL}/users/me/pusher`,
      auth: {
        headers: { Authorization: `Bearer ${token}` },
      },
      activityTimeout: 5000,
    });

    this.pusher.connection.bind('state_change', (state) => {
      if (this.onStatusChange) {
        this.onStatusChange(state.current);
      }

      // Temporary failure of the Channels connection will cause: connected -> connecting -> connected
      // If an internet connection disappears: connected -> connecting -> unavailable (after ~ 30s
      if (
        state.current === 'unavailable' ||
        (state.previous === 'connected' && state.current === 'connecting')
      ) {
        this.pusherWasDisconnected = true;
      } else if (state.current === 'connected' && this.pusherWasDisconnected) {
        this.pusherWasDisconnected = false;
        this.refreshStoreData();
      }
    });

    const channel = this.pusher!.subscribe(`private-user-${userId}`);
    const eventKeys = Object.keys(WEBSOCKET_EVENTS);
    for (let i = 0; i < eventKeys.length; i++) {
      const eventValue = WEBSOCKET_EVENTS[eventKeys[i]];
      channel.bind(eventValue, (data) => {
        const storeKeys = Object.keys(this.stores);
        for (let j = 0; j < storeKeys.length; j++) {
          const store = this.stores[storeKeys[j]];
          if (store.onEvent) {
            store.onEvent(eventValue, data);
          }
        }
      });
    }
  };

  public subscribeToProject = (projectId: string) => {
    if (this.currentProject === projectId) {
      return;
    }

    if (this.pusher) {
      if (this.currentProject != null) {
        this.pusher.unsubscribe(`private-project-${this.currentProject}`);
        this.currentProject = null;
      }

      const channel = this.pusher.subscribe(`private-project-${projectId}`);
      const eventKeys = Object.keys(WEBSOCKET_EVENTS);
      for (let i = 0; i < eventKeys.length; i++) {
        const eventValue = WEBSOCKET_EVENTS[eventKeys[i]];
        channel.bind(eventValue, (data) => {
          const storeKeys = Object.keys(this.stores);
          for (let j = 0; j < storeKeys.length; j++) {
            const store = this.stores[storeKeys[j]];
            if (store.onEvent) {
              store.onEvent(eventValue, data);
            }
          }
        });
      }

      this.currentProject = projectId;
    }
  };

  public subscribeToTicket = (ticketId: string) => {
    if (this.currentTicket === ticketId) {
      return;
    }

    if (this.pusher) {
      if (this.currentTicket != null) {
        this.pusher.unsubscribe(`private-ticket-${this.currentTicket}`);
        this.currentTicket = null;
      }

      const channel = this.pusher.subscribe(`private-ticket-${ticketId}`);
      const eventKeys = Object.keys(WEBSOCKET_EVENTS);
      for (let i = 0; i < eventKeys.length; i++) {
        const eventValue = WEBSOCKET_EVENTS[eventKeys[i]];
        channel.bind(eventValue, (data) => {
          const storeKeys = Object.keys(this.stores);
          for (let j = 0; j < storeKeys.length; j++) {
            const store = this.stores[storeKeys[j]];
            if (store.onEvent) {
              store.onEvent(eventValue, data);
            }
          }
        });
      }

      this.currentTicket = ticketId;
    }
  };
  
  private refreshStoreData = debounce(
    () => {
      const storeKeys = Object.keys(this.stores);
      for (let i = 0; i < storeKeys.length; i++) {
        const store = this.stores[storeKeys[i]];
        if (store.refreshData) {
          store.refreshData();
        }
      }
    },
    3000,
    { leading: true, trailing: false },
  );
}

export { WEBSOCKET_EVENTS };

export default WebSocketHelper;
