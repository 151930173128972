import { toggleDarkMode } from 'components/DarkModeToggle/DarkModeToggle';
import { getOrRegisterServiceWorker } from 'FirebaseConfig';
import Gleap from 'gleap';
import 'helper/GlobalHelpers';
import ReactDOM from 'react-dom';
import { isSharedSite } from 'services/Helper';
import App from './App';
import './index.scss';
import * as serviceWorker from './serviceWorker';

function addScript(src, module = false) {
  var s = document.createElement('script');
  s.setAttribute('src', src);
  if (module) {
    s.setAttribute('type', 'module');
  }
  document.body.appendChild(s);
}

function addStylesheet(href) {
  var link = document.createElement('link');
  link.setAttribute('rel', 'stylesheet');
  link.setAttribute('type', 'text/css');
  link.setAttribute('href', href);
  document.head.appendChild(link);
}

function addPaddleScript() {
  var s = document.createElement('script');
  s.setAttribute('src', 'https://cdn.paddle.com/paddle/v2/paddle.js');
  s.setAttribute('async', '');
  s.setAttribute('defer', '');
  document.body.appendChild(s);

  // Cello script
  var cello = document.createElement('script');
  cello.async = true;
  cello.defer = true;
  cello.type = "module";
  cello.src = "https://assets.cello.so/app/latest/cello.js";
  document.body.appendChild(cello);

  // Cello attribution script
  var celloAttr = document.createElement('script');
  celloAttr.async = true;
  celloAttr.defer = true;
  celloAttr.type = "module";
  celloAttr.src = "https://assets.cello.so/attribution/latest/cello-attribution.js";
  document.body.appendChild(celloAttr);
}

function addMarketingScripts() {
  // Tag Manager
  var script2 = document.createElement('script');
  script2.setAttribute('async', '');
  script2.setAttribute('src', 'https://www.googletagmanager.com/gtm.js?id=GTM-TP867NN4');
  document.body.appendChild(script2);
  
  var script3 = document.createElement('script');
  var code3 = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'GTM-TP867NN4');
      gtag('consent', 'default', {
        'ad_storage': 'granted',
        'ad_user_data': 'granted',
        'ad_personalization': 'granted',
        'analytics_storage': 'granted',
        'functionality_storage': 'granted',
        'personalization_storage': 'granted',
      });
  `;
  script3.innerHTML = code3;
  document.body.appendChild(script3);
}

// Load Gleap only when not shared.
if (!isSharedSite()) {
  // Init Gleap
  Gleap.initialize("ogWhNhuiZcGWrva5nlDS8l7a78OfaLlV");

  Gleap.setAiTools([
    {
      name: "toggle_dark_mode",
      description: "Toggle dark mode",
      parameters: [{
        name: "toggle",
        type: "boolean",
        required: true,
        description: "Toggle dark mode on or off (boolean)"
      }],
      response: "Toggled successfully."
    }
  ]);

  Gleap.on("tool-execution", (tool) => {
    if (tool.name === "toggle_dark_mode") {
      let toggle = tool.params.toggle;
      toggleDarkMode(toggle);
    }
  });

  addScript("https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js", true);
  addStylesheet("https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css");
  addPaddleScript();
  addMarketingScripts();
} else {
  // Don't add marketing scripts.
}

ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

try {
  getOrRegisterServiceWorker();
} catch (error) { }