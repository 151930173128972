import './SidenavElement.scss';
import { inject, observer } from 'mobx-react';
import { ProjectStore } from 'stores/private/ProjectStore';
import SingleSidenavElement from './SingleSidenavElement';
import { ReactComponent as InboxLogo } from '../../../assets/icons/inbox-full-solid.svg';
import { ReactComponent as RatingIcon } from '../../../assets/icons/stars_outlined.svg';
import { ReactComponent as FeatureRequestsIcon } from '../../../assets/icons/lightbulb-on-solid.svg';
import { ReactComponent as BugIcon } from '../../../assets/icons/bug_outlined.svg';
import { ReactComponent as OptionsIcon } from '../../../assets/icons/np_option_2753144_000000.svg';
import { ReactComponent as GoalIcon } from '../../../assets/icons/np_goal_3442995_72B95C.svg';
import { ReactComponent as SupportIcon } from '../../../assets/icons/np_support_3154409_4A57F0.svg';
import { ReactComponent as EmailIcon } from '../../../assets/icons/np_email_2258253_9354F6.svg';
import { ReactComponent as KaiIcon } from '../../../assets/icons/kaiiconhighlight.svg';
import { ReactComponent as CrashReportIcon } from '../../../assets/icons/noun-bug-script-3542934-FFFFFF.svg';

export const getIconForFeedbackType = (type) => {
  if (type === 'BUG') {
    return BugIcon;
  }
  if (type === 'CRASH') {
    return CrashReportIcon;
  }
  if (type === 'RATING') {
    return RatingIcon;
  }
  if (type === 'INQUIRY') {
    return InboxLogo;
  }
  if (type === 'FEATURE_REQUEST') {
    return FeatureRequestsIcon;
  }
  if (type === 'GOAL') {
    return GoalIcon;
  }
  if (type === 'SUPPORT') {
    return SupportIcon;
  }
  if (type === 'EMAIL') {
    return EmailIcon;
  }
  if (type === 'BOT') {
    return KaiIcon;
  }

  return OptionsIcon;
};

interface SidenavElementProps {
  projectStore?: ProjectStore;
}

const SidenavElement = ({ projectStore }: SidenavElementProps) => {
  const project = projectStore?.currentProject;

  if (!project) {
    return <></>;
  }

  const sidebarCount = (projectType) => {
    var unreadCount = 0;

    for (const itemKey in projectStore?.unreadItems) {
      if (projectStore?.unreadItems[itemKey] && projectStore?.unreadItems[itemKey].type === projectType) {
        unreadCount++;
        if (unreadCount > 9) {
          return 10;
        }
      }
    }

    return unreadCount;
  };

  return (
    <div className="sidenav-elements">
      {project.projectTypes.map((projectType) => {
        if (projectType.type === 'CRASH' && !projectStore.hasCrashReports) {
          return null;
        }

        return (
          <SingleSidenavElement
            className="sub-sidenav-item"
            key={projectType.path}
            name={projectType.menuName}
            projectPath={projectType.path}
            projectId={project.id}
            Icon={getIconForFeedbackType(projectType.icon)}
            notificationCount={sidebarCount(projectType?.type)}
          />
        );
      })}
    </div>
  );
};

export default inject('projectStore')(observer(SidenavElement));
