import { ReactComponent as BG } from 'assets/BG.svg';
import { ReactComponent as BGClassic } from 'assets/BGclassic.svg';
import { ReactComponent as BGclassicNoFade } from 'assets/BGclassicNoFade.svg';
import { getLanguageProperty } from 'helper/AssignObjectKeysHelper';
import { calculateContrast, calculateShadeColor } from 'helper/Color';
import { inject, observer } from 'mobx-react';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import { ProjectStore } from 'stores/private/ProjectStore';
import { UsersStore } from 'stores/private/UsersStore';
import './ClassicWidgetPreview.scss';

interface ClassicWidgetPreviewProps {
  projectStore?: ProjectStore;
  usersStore?: UsersStore;
}

function ClassicWidgetPreview({
  projectStore,
  usersStore,
}: ClassicWidgetPreviewProps) {
  const { flowConfig } = projectStore?.editingProject || {};
  const currentLang = projectStore?.currentLanguage ?? 'en';

  const showDemoNotice = () => {
    toast.success('You clicked your demo widget 🥳.');
  };

  const cornerRadius = flowConfig?.borderRadius ?? 20;
  const backgroundColor = flowConfig?.backgroundColor ?? '#ffffff';
  const contrastBackgroundColor = calculateContrast(backgroundColor);
  const subTextColor =
    contrastBackgroundColor === '#ffffff'
      ? calculateShadeColor(backgroundColor, 100)
      : calculateShadeColor(backgroundColor, -100);

  const resolveTeamMember = (memberId: any) => {
    if (!projectStore?.currentProjectUsers) {
      return null;
    }

    // Find member in project users
    const member = projectStore?.currentProjectUsers.find(
      (user) => user.id === memberId,
    );
    if (!member) {
      return null;
    }

    return {
      id: member.id,
      profileImageUrl: member.profileImageUrl,
    };
  };
  let team = (projectStore?.teamPreview ?? []).slice(0, 3);
  if (flowConfig?.visibleTeamMembers && flowConfig?.visibleTeamMembers.length > 0) {
    team = flowConfig.visibleTeamMembers
      .map((memberId) => resolveTeamMember(memberId))
      .filter((member) => member !== null) as any;
  }

  const css = `
  :root {
    --widget-header-color: ${flowConfig.headerColor ?? '#000000'};
    --widget-header-color-2: ${flowConfig.headerColor2 ?? '#ffffff'};
    --widget-header-color-3: ${flowConfig.headerColor3 ?? '#ffffff'};
    --widget-primary-color-22: ${flowConfig.color}22;
    --widget-background-color: ${flowConfig.backgroundColor ?? '#ffffff'};
    --widget-background-color-99: ${flowConfig.backgroundColor ?? '#ffffff'}99;
    --widget-background-color-EE: ${flowConfig.backgroundColor ?? '#ffffff'}EE;
    --widget-primary-color: ${flowConfig.color};
    --widget-primary-inv-color: ${calculateContrast(
    flowConfig.color ?? '#fff',
  )};
    --widget-header-inv: ${calculateContrast(
    flowConfig.headerColor ?? '#2142E7',
  )};
    --widget-bg-inv: ${calculateContrast(
    flowConfig.backgroundColor ?? '#2142E7',
  )};
  }`;

  const renderBG = () => {
    if (flowConfig.bgType === 'image') {
      return <></>
    }

    if (flowConfig.bgType === 'classic') {
      if ((flowConfig?.fadebg ?? true)) {
        return (
          <BGClassic className={`feedback-box-bg feedback-box-bg-classic`}
            style={{
              borderTopLeftRadius: cornerRadius,
              borderTopRightRadius: cornerRadius,
            }} />);
      }

      return (
        <BGclassicNoFade className={`feedback-box-bg feedback-box-bg-classic`}
          style={{
            borderTopLeftRadius: cornerRadius,
            borderTopRightRadius: cornerRadius,
          }} />);
    }

    return (
      <>
        <BG className={`feedback-box-bg ${flowConfig.animateBG ? 'bg-animating' : ''}`}
          style={{
            borderTopLeftRadius: cornerRadius,
            borderTopRightRadius: cornerRadius,
          }} />
        <div className='bg-backgrop-blur'></div>
      </>
    );
  }

  return (
    <div className="classic-widget-preview">
      <style>{css}</style>
      {flowConfig?.feedbackButtonPosition &&
        flowConfig?.feedbackButtonPosition.includes('CLASSIC') && (
          <div
            className="widget-bugbattle--feedback-button"
            onClick={() => {
              showDemoNotice();
            }}
          >
            <div
              className={`widget-bugbattle--feedback-button-text ${flowConfig?.feedbackButtonPosition === 'BUTTON_CLASSIC_LEFT'
                ? 'widget-bugbattle--feedback-button-text--left'
                : ''
                }${flowConfig?.feedbackButtonPosition === 'BUTTON_CLASSIC_BOTTOM'
                  ? 'widget-bugbattle--feedback-button-text--bottom'
                  : ''
                }`}
              style={{
                backgroundColor: flowConfig?.buttonColor ?? '#2142E7',
                color: calculateContrast(flowConfig?.buttonColor ?? '#2142E7'),
                borderTopLeftRadius: cornerRadius * 0.4,
                borderTopRightRadius: cornerRadius * 0.4,
              }}
            >
              {getLanguageProperty(
                flowConfig,
                'widgetButtonText',
                currentLang,
                true,
              ) ?? 'Feedback'}
            </div>
          </div>
        )}
      <div className="classic-widget-preview-browser">
        <div className="classic-widget-preview-browser-head">
          <div className="classic-widget-preview-browser-head-close" />
          <div className="classic-widget-preview-browser-head-minimize" />
          <div className="classic-widget-preview-browser-head-maximize" />
        </div>
        <div className="classic-widget-preview-browser-body">
          <div
            className="feedback-box"
            style={{
              borderRadius: cornerRadius,
              backgroundColor,
            }}
          >
            {flowConfig.bgType === 'image' && flowConfig.bgImage && flowConfig.bgImage.length > 0 ?
              <img src={flowConfig.bgImage} className='feedback-box-bg feedback-box-bg--image' /> : <></>
            }
            <div className="feedback-box-scroll" style={flowConfig.bgBlur ? {
              backdropFilter: 'blur(30px)',
              borderRadius: cornerRadius,
              position: 'relative',
            } : {
              borderRadius: cornerRadius,
              position: 'relative',
            }}>
              {renderBG()}
              <div
                className="feedback-box-header"
                style={{
                  borderTopLeftRadius: cornerRadius,
                  borderTopRightRadius: cornerRadius,
                }}
              >
                {flowConfig?.logo?.length > 0 ? (
                  <img src={flowConfig.logo} />
                ) : (
                  <svg
                    width="127"
                    height="129"
                    viewBox="0 0 127 129"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M27.5507 32.306C20.4495 41.0714 17.3437 52.8384 17.3438 64.1091C17.3438 75.3799 20.4497 87.1469 27.5508 95.9123C34.4039 104.372 45.6889 110.937 64.1725 110.937C83.6599 110.937 93.3637 102.356 98.4673 94.1976C102.058 88.4577 103.344 84.2626 103.805 81.4366C104.114 79.5459 105.616 77.9841 107.531 77.9841H117.938C119.853 77.9841 121.429 79.5376 121.265 81.4463C120.835 86.4687 119.175 93.7981 113.171 103.396C105.135 116.242 90.0723 128.281 64.1725 128.281C41.0305 128.281 24.5652 119.779 14.0745 106.83C3.83175 94.1866 0.000111649 78.2036 2.4398e-09 64.1092C-0.000111645 50.0147 3.83155 34.0317 14.0744 21.3884C24.0327 9.09622 39.3744 0.811764 60.7001 0.00243821C62.6145 -0.0702131 64.1725 1.49027 64.1725 3.40601V13.8123C64.1725 15.728 62.6176 17.2712 60.704 17.3608C44.2594 18.1311 33.9643 24.3893 27.5507 32.306Z"
                      fill={calculateContrast(
                        flowConfig.headerColor ?? '#2142E7',
                      )}
                    />
                    <path
                      d="M126.609 43.2966C126.609 50.9596 120.397 57.1716 112.734 57.1716C105.071 57.1716 98.8594 50.9596 98.8594 43.2966C98.8594 35.6337 105.071 29.4216 112.734 29.4216C120.397 29.4216 126.609 35.6337 126.609 43.2966Z"
                      fill={calculateContrast(
                        flowConfig.headerColor ?? '#2142E7',
                      )}
                    />
                  </svg>
                )}
                {!flowConfig?.hideTeam && (
                  <div className="feedback-box-header-team">
                    {team.map((teamUser, index) => {
                      return <img key={index} src={teamUser.profileImageUrl} />;
                    })}
                  </div>
                )}
                <div className="feedback-box-header-title">
                  <span
                    style={{
                      color: calculateContrast(
                        flowConfig?.headerColor ?? '#2142E7',
                      ),
                    }}
                  >
                    Hey{' '}
                    {flowConfig?.hideUsersName
                      ? ''
                      : usersStore?.currentUser?.firstName ?? 'there'}{' '}
                  </span>
                  <span className="hand">
                    {flowConfig?.hideWavingHandAfterName ? '' : '👋'}
                  </span>
                </div>
                <div
                  className="feedback-box-header-subtitle"
                  style={{
                    color: calculateContrast(
                      flowConfig?.headerColor ?? '#2142E7',
                    ),
                  }}
                >
                  {getLanguageProperty(
                    flowConfig,
                    'welcomeText',
                    currentLang,
                    true,
                  ) ?? 'How can we help?'}
                </div>
              </div>
              <div className="feedback-box-body">
                {flowConfig?.showHelpSearchOnHome && (<div
                  className="feedback-box-body-item"
                  style={{
                    backgroundColor: backgroundColor,
                    borderRadius: cornerRadius * 0.6,
                  }}
                  onClick={() => {
                    showDemoNotice();
                  }}>
                  <div className="search-card">
                    <div className="search-card-topbar" style={{
                      borderRadius: cornerRadius * 0.6,
                    }}>
                      <div className="search-card-topbar-title">{'Search for help'}</div>
                      <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512" className="search-card-topbar-icon">
                        <path opacity="1" fill="#1E3050" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"></path>
                      </svg>
                    </div>
                    <div className="search-card-list">
                      <div className="search-card-list-item">
                        <div className="search-card-list-item-title">
                          <Skeleton enableAnimation={false} count={1} width={120} height={12} />
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                          <path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"></path>
                        </svg>
                      </div>
                      <div className="search-card-list-item">
                        <div className="search-card-list-item-title">
                          <Skeleton enableAnimation={false} count={1} width={190} height={12} />
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                          <path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>)}
                {projectStore?.currentProject?.homeWidgets &&
                  projectStore?.currentProject?.homeWidgets.map((homeWidget, key) => {
                    if (homeWidget.type === 'menu') {
                      const menuItem = homeWidget.config;
                      let description = getLanguageProperty(
                        menuItem,
                        'description',
                        currentLang,
                        true,
                      );
                      if (!description || description.length === 0) {
                        description = 'No description';
                      }

                      return (
                        <div
                          className="feedback-box-body-item"
                          key={key}
                          style={{
                            backgroundColor: backgroundColor,
                            borderRadius: cornerRadius * 0.6,
                          }}
                          onClick={() => {
                            showDemoNotice();
                          }}
                        >
                          <div className="feedback-box-body-item-type-text">
                            <div
                              className="feedback-box-body-item-type-title"
                              style={{
                                color: contrastBackgroundColor,
                              }}
                            >
                              {getLanguageProperty(
                                menuItem,
                                'title',
                                currentLang,
                                true,
                              )}
                            </div>
                            <div
                              className="feedback-box-body-item-type-description"
                              style={{
                                color: subTextColor,
                              }}
                            >
                              {description}
                            </div>
                          </div>
                          <img
                            className="feedback-box-body-item-type-icon"
                            src={menuItem.icon}
                          />
                        </div>
                      );
                    }

                    return null;
                  })}
                {flowConfig?.showNewsOnHome && (<div
                  className="feedback-box-body-item"
                  style={{
                    backgroundColor: backgroundColor,
                    borderRadius: cornerRadius * 0.6,
                  }}
                  onClick={() => {
                    showDemoNotice();
                  }}>
                  <div className='news-fake'>
                    <div className='news-fake-image'></div>
                    <div className='news-fake-content'>
                      <Skeleton enableAnimation={false} count={1} width={120} height={10} />
                      <Skeleton enableAnimation={false} count={2} width={210} height={10} />
                    </div>
                  </div>
                </div>)}
              </div>
              <div className="feedback-box-footer">
                {!flowConfig?.hideBranding && (
                  <>
                    <svg
                      className="bolt"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path
                        fill="#ffd43b"
                        d="M240.5 224H352C365.3 224 377.3 232.3 381.1 244.7C386.6 257.2 383.1 271.3 373.1 280.1L117.1 504.1C105.8 513.9 89.27 514.7 77.19 505.9C65.1 497.1 60.7 481.1 66.59 467.4L143.5 288H31.1C18.67 288 6.733 279.7 2.044 267.3C-2.645 254.8 .8944 240.7 10.93 231.9L266.9 7.918C278.2-1.92 294.7-2.669 306.8 6.114C318.9 14.9 323.3 30.87 317.4 44.61L240.5 224z"
                      />
                    </svg>
                    <span
                      style={{
                        color: subTextColor,
                      }}
                    >
                      Powered by
                    </span>
                    <svg
                      className="logo"
                      width="90px"
                      height="32px"
                      viewBox="0 0 90 32"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                      <g
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <g
                          transform="translate(0.653299, 0.000000)"
                          fill={subTextColor}
                          fillRule="nonzero"
                        >
                          <path d="M16.7989119,8.32577189 L22.1014291,8.32577189 C21.4265888,3.43890649 17.1242771,0 11.5448484,0 C5.02513746,0 0,4.70586291 0,12.7178969 C0,20.5368768 4.69977222,25.3876017 11.6532254,25.3876017 C17.8836188,25.3876017 22.3303551,21.4418423 22.3303551,14.9380999 L22.3303551,11.8250016 L12.0027005,11.8250016 L12.0027005,15.7586204 L17.3052177,15.7586204 C17.2328883,18.823461 15.1479759,20.7661416 11.6773352,20.7661416 C7.76078035,20.7661416 5.29034525,17.8340271 5.29034525,12.6696392 C5.29034525,7.52939191 7.85721955,4.62139446 11.6291156,4.62139446 C14.3165389,4.62139446 16.1362435,6.00903014 16.7989119,8.32577189 Z"></path>
                          <polygon points="30.2692671 0.337857389 25.1355185 0.337857389 25.1355185 25.0496341 30.2692671 25.0496341"></polygon>
                          <path d="M41.7991346,25.4117422 C46.3785919,25.4117422 49.4634758,23.1793283 50.1865357,19.7404968 L45.4385438,19.426787 C44.9203002,20.8385398 43.5947294,21.5745684 41.883636,21.5745684 C39.3167617,21.5745684 37.6897014,19.8732229 37.6897014,17.1100453 L37.6897014,17.097975 L50.2951468,17.097975 L50.2951468,15.6862222 C50.2951468,9.38760404 46.486969,6.27448232 41.5943184,6.27448232 C36.1473765,6.27448232 32.6163443,10.1477732 32.6163443,15.8672059 C32.6163443,21.7435053 36.0991569,25.4117422 41.7991346,25.4117422 Z M37.6897014,13.9124785 C37.7983125,11.8008611 39.4010289,10.1115858 41.6785856,10.1115858 C43.9081568,10.1115858 45.4507158,11.7043223 45.4626536,13.9124785 L37.6897014,13.9124785 Z"></path>
                          <path d="M57.9054165,25.3995548 C60.6410594,25.3995548 62.4125444,24.2049497 63.3163107,22.4795574 L63.4609695,22.4795574 L63.4609695,25.0496341 L68.3295103,25.0496341 L68.3295103,12.5489834 C68.3295103,8.13269445 64.593896,6.27448232 60.4722908,6.27448232 C56.0377263,6.27448232 53.121377,8.39817007 52.410255,11.7767205 L57.1582468,12.162852 C57.5077218,10.9320829 58.6043666,10.0271174 60.448181,10.0271174 C62.1955562,10.0271174 63.1957617,10.9079424 63.1957617,12.4283041 L63.1957617,12.5007023 C63.1957617,13.695284 61.9305825,13.8521272 58.7129777,14.1658604 C55.0494587,14.5037108 51.7595245,15.7344799 51.7595245,19.8732229 C51.7595245,23.5414364 54.3746184,25.3995548 57.9054165,25.3995548 Z M59.375646,21.8521143 C57.7970394,21.8521143 56.664347,21.1160622 56.664347,19.7043095 C56.664347,18.2563459 57.8571969,17.5444343 59.6649636,17.291029 C60.7857181,17.1341858 62.6173606,16.8687102 63.2320434,16.4584616 L63.2320434,18.4252828 C63.2320434,20.3679399 61.629327,21.8521143 59.375646,21.8521143 Z"></path>
                          <path d="M71.2943133,32 L76.4280619,32 L76.4280619,22.0813791 L76.5846586,22.0813791 C77.2957806,23.6258111 78.8502774,25.3512737 81.8389562,25.3512737 C86.0567665,25.3512737 89.3467007,22.0089575 89.3467007,15.806878 C89.3467007,9.43586168 85.9121077,6.27448232 81.850894,6.27448232 C78.7538382,6.27448232 77.2716708,8.12062418 76.5846586,9.62891568 L76.3557325,9.62891568 L76.3557325,6.5158174 L71.2943133,6.5158174 L71.2943133,32 Z M76.3196849,15.7827375 C76.3196849,12.4765852 77.717585,10.3649677 80.2121299,10.3649677 C82.7548944,10.3649677 84.104575,12.5731005 84.104575,15.7827375 C84.104575,19.016515 82.7307846,21.2608586 80.2121299,21.2608586 C77.7416948,21.2608586 76.3196849,19.0889132 76.3196849,15.7827375 Z"></path>
                        </g>
                      </g>
                    </svg>
                  </>
                )}
              </div>
            </div>
            {!(
              flowConfig?.hideNews &&
              flowConfig?.hideKnowledgeBase &&
              flowConfig?.hideFeatureRequests &&
              flowConfig?.hideAllConversations
            ) && (
                <div
                  className="tab-bar"
                  style={{
                    backgroundColor: backgroundColor,
                    borderBottomLeftRadius: cornerRadius,
                    borderBottomRightRadius: cornerRadius,
                  }}
                >
                  <div className="tab-bar-item active" style={{
                    backgroundColor: `${flowConfig?.color}22`,
                    borderRadius: `${cornerRadius * 0.6}px`,
                  }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                    >
                      <path
                        fill={flowConfig?.color}
                        d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c.2 35.5-28.5 64.3-64 64.3H128.1c-35.3 0-64-28.7-64-64V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"
                      ></path>
                    </svg>
                    <span
                      style={{
                        color: flowConfig?.color,
                      }}
                    >
                      {getLanguageProperty(
                        flowConfig?.staticTranslations,
                        'homeLabel',
                        currentLang,
                        true,
                      ) ?? 'Home'}
                    </span>
                  </div>
                  {!flowConfig?.hideAllConversations && (
                    <div className="tab-bar-item false">
                      <div className="icon-container">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8V13.2C21 14.8802 21 15.7202 20.673 16.362C20.3854 16.9265 19.9265 17.3854 19.362 17.673C18.7202 18 17.8802 18 16.2 18H9.68375C9.0597 18 8.74767 18 8.44921 18.0613C8.18443 18.1156 7.9282 18.2055 7.68749 18.3285C7.41617 18.4671 7.17252 18.662 6.68521 19.0518L4.29976 20.9602C3.88367 21.2931 3.67563 21.4595 3.50054 21.4597C3.34827 21.4599 3.20422 21.3906 3.10923 21.2716C3 21.1348 3 20.8684 3 20.3355V7.8Z" stroke={contrastBackgroundColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                      </div>
                      <span
                        style={{
                          color: contrastBackgroundColor,
                        }}
                      >
                        {getLanguageProperty(
                          flowConfig,
                          'allConversationsMenuLabel',
                          currentLang,
                          true,
                        ) ?? 'Messages'}
                      </span>
                    </div>
                  )}
                  {!flowConfig?.hideNews && (
                    <div className="tab-bar-item false">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path stroke={contrastBackgroundColor} d="M22 7.99992V11.9999M10.25 5.49991H6.8C5.11984 5.49991 4.27976 5.49991 3.63803 5.82689C3.07354 6.11451 2.6146 6.57345 2.32698 7.13794C2 7.77968 2 8.61976 2 10.2999L2 11.4999C2 12.4318 2 12.8977 2.15224 13.2653C2.35523 13.7553 2.74458 14.1447 3.23463 14.3477C3.60218 14.4999 4.06812 14.4999 5 14.4999V18.7499C5 18.9821 5 19.0982 5.00963 19.1959C5.10316 20.1455 5.85441 20.8968 6.80397 20.9903C6.90175 20.9999 7.01783 20.9999 7.25 20.9999C7.48217 20.9999 7.59826 20.9999 7.69604 20.9903C8.64559 20.8968 9.39685 20.1455 9.49037 19.1959C9.5 19.0982 9.5 18.9821 9.5 18.7499V14.4999H10.25C12.0164 14.4999 14.1772 15.4468 15.8443 16.3556C16.8168 16.8857 17.3031 17.1508 17.6216 17.1118C17.9169 17.0756 18.1402 16.943 18.3133 16.701C18.5 16.4401 18.5 15.9179 18.5 14.8736V5.1262C18.5 4.08191 18.5 3.55976 18.3133 3.2988C18.1402 3.05681 17.9169 2.92421 17.6216 2.88804C17.3031 2.84903 16.8168 3.11411 15.8443 3.64427C14.1772 4.55302 12.0164 5.49991 10.25 5.49991Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                      <span
                        style={{
                          color: contrastBackgroundColor,
                        }}
                      >
                        {getLanguageProperty(
                          flowConfig,
                          'newsMenuLabel',
                          currentLang,
                          true,
                        ) ?? 'News'}
                      </span>
                    </div>
                  )}
                  {!flowConfig?.hideFeatureRequests && (
                    <div className="tab-bar-item false">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 18L2 22V6L9 2M9 18L16 22M9 18V2M16 22L22 18V2L16 6M16 22V6M16 6L9 2" stroke={contrastBackgroundColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>                        <span
                        style={{
                          color: contrastBackgroundColor,
                        }}
                      >
                        {getLanguageProperty(
                          flowConfig,
                          'featureRequestsMenuLabel',
                          currentLang,
                          true,
                        ) ?? 'Roadmap'}
                      </span>
                    </div>
                  )}
                  {!flowConfig?.hideKnowledgeBase && (
                    <div className="tab-bar-item false">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill={contrastBackgroundColor}
                          d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464zM256 336c-18 0-32 14-32 32s13.1 32 32 32c17.1 0 32-14 32-32S273.1 336 256 336zM289.1 128h-51.1C199 128 168 159 168 198c0 13 11 24 24 24s24-11 24-24C216 186 225.1 176 237.1 176h51.1C301.1 176 312 186 312 198c0 8-4 14.1-11 18.1L244 251C236 256 232 264 232 272V288c0 13 11 24 24 24S280 301 280 288V286l45.1-28c21-13 34-36 34-60C360 159 329 128 289.1 128z"
                        />
                      </svg>
                      <span
                        style={{
                          color: contrastBackgroundColor,
                        }}
                      >
                        {getLanguageProperty(
                          flowConfig,
                          'knowledgeBaseMenuLabel',
                          currentLang,
                          true,
                        ) ?? 'Help'}
                      </span>
                    </div>
                  )}
                </div>
              )}
          </div>
          {flowConfig?.feedbackButtonPosition &&
            flowConfig?.feedbackButtonPosition !== 'BUTTON_NONE' &&
            !flowConfig?.feedbackButtonPosition.includes('CLASSIC') && (
              <div
                className={`widget-bugbattle--feedback-new-button ${flowConfig?.feedbackButtonPosition === 'BOTTOM_LEFT'
                  ? 'widget-bugbattle--feedback-new-button--left'
                  : ''
                  }`}
              >
                <div
                  className="widget-bugbattle--feedback-new-button-button"
                  style={{
                    backgroundColor: flowConfig?.buttonColor ?? '#2142E7',
                    color: calculateContrast(
                      flowConfig?.buttonColor ?? '#2142E7',
                    ),
                  }}
                  onClick={() => {
                    showDemoNotice();
                  }}
                >
                  {flowConfig?.buttonLogo && flowConfig?.buttonLogo.length > 0 ? (
                    <img src={flowConfig?.buttonLogo} />
                  ) : (
                    <svg
                      className="widget-default-icon"
                      width="700px"
                      height="700px"
                      viewBox="0 0 700 700"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                      <g
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <g
                          transform="translate(77.000000, 119.000000)"
                          fill={calculateContrast(
                            flowConfig?.buttonColor ?? '#2142E7',
                          )}
                          fillRule="nonzero"
                        >
                          <path d="M476.856373,0 L68.122339,0 C30.601832,0 0,30.5935323 0,67.1461527 L0,372.655827 C0,410.166158 30.601832,439.80198 68.122339,439.80198 L170.305847,439.80198 L170.305847,528.23059 C170.305847,538.605788 182.280477,544.671288 190.657396,538.552581 L323.602398,438.844269 L476.877661,438.844269 C514.398168,438.844269 545,408.250737 545,371.698116 L545,67.1461527 C544.978712,30.5935323 515.28163,0 476.856373,0 Z"></path>
                        </g>
                      </g>
                    </svg>
                  )}
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

export default inject('projectStore', 'usersStore')(observer(ClassicWidgetPreview));
