import LoadingAnimation from 'components/LoadingAnimation/LoadingAnimation';
import { useEffect, useRef, useState } from 'react';
import rrwebPlayer from 'rrweb-player';
import 'rrweb-player/dist/style.css';
import './WebPlayer.scss';

// Define a type for the player state
type Player = InstanceType<typeof rrwebPlayer> | null;

const WebPlayer = ({ bug, onTimeChanged, data }) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [player, setPlayer] = useState<Player>(null);

  const calculatedSizes = () => {
    if (
      !bug ||
      !bug.metaData ||
      !bug.metaData.innerWidth ||
      !bug.metaData.innerHeight
    ) {
      return {
        width: 100,
        height: 100,
        ratio: 1,
        offsetX: 0,
      };
    }

    let iframeWidth = bug.metaData.innerWidth;
    let iframeHeight = bug.metaData.innerHeight;
    const originaliframeWidth = iframeWidth;
    const originaliframeHeight = iframeHeight;
    const containerWidth = window.innerWidth * 0.65 - 80;
    const containerHeight = window.innerHeight - 80 - 100;
    let ratio = 1;

    if (iframeWidth > containerWidth) {
      ratio = containerWidth / iframeWidth;
      iframeWidth *= ratio;
      iframeHeight *= ratio;
    }

    if (iframeHeight > containerHeight) {
      const newRatio = containerHeight / iframeHeight;
      if (newRatio < ratio) {
        ratio = newRatio;
        iframeWidth *= newRatio;
        iframeHeight *= newRatio;
      }
    }

    ratio = iframeHeight / originaliframeHeight;

    const offsetX =
      ((containerWidth - originaliframeWidth * ratio) / 2) * (1 / ratio);

    return {
      width: iframeWidth,
      height: iframeHeight,
      ratio,
      offsetX,
    };
  };

  const events = data?.events ?? [];

  useEffect(() => {
    if (divRef.current && events && events.length >= 2 && player === null) {
      const sizes = calculatedSizes();

      const rrwebPlayerInstance = new rrwebPlayer({
        target: divRef.current, // root element is the referenced div
        props: {
          events,
          width: sizes.width,
          height: sizes.height,
          autoPlay: false,
          inactiveColor: '#000000',
          useVirtualDom: true,
          showController: true,
          skipInactive: false,
          speedOption: [1, 2, 4],
          UNSAFE_replayCanvas: true,
          blockClass: 'gl-block',
        },
      });

      rrwebPlayerInstance.addEventListener(
        'ui-update-current-time',
        (event: any) => {
          if (onTimeChanged) {
            onTimeChanged(event.payload);
          }
        },
      );

      setPlayer(rrwebPlayerInstance);
    }
  }, [events, divRef.current, player]);

  const renderPlayer = () => {
    if (!data || data === null) {
      return <LoadingAnimation />;
    }

    if (data && data?.type !== 'rrweb') {
      return (
        <div className="options-group options-group--card">
          <div className="options-group-header">📦 Update SDK</div>
          <div className="text">
            Exciting news: our web SDK replays now offer enhanced accuracy,
            added features, and require less resources. Update your Gleap SDK to
            10.1.0+ to benefit from these improvements.
          </div>
        </div>
      );
    }

    if (events.length < 2) {
      return (
        <div className="web-player-container">
          <div className="web-player">
            <div className="options-group options-group--card">
              <div className="options-group-header">Recording unavailable.</div>
              <div className="text">
                The replay was not recorded was not successfully. Ensure your
                SDK is properly installed, and refer to our{' '}
                <a
                  href="https://docs.gleap.io/javascript/replays/"
                  target="_blank"
                >
                  documentation
                </a>{' '}
                for guidance.
              </div>
            </div>
          </div>
        </div>
      );
    }

    return <div className="web-player-div" ref={divRef} />;
  };

  return (
    <div className="web-player-container">
      <div className="web-player">{renderPlayer()}</div>
    </div>
  );
};

export default WebPlayer;
