import FancyBlurBG2 from 'assets/bg/fancyblurbg2opa.png';
import OutboundBlankImage from 'assets/icons/outboundmessages.png';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { inject, observer } from 'mobx-react';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';

const AddOutreach = ({ modalStore }: {
    modalStore?: ModalStore,
}) => {
    return (
        <div className='outreach-addon'>
            <div className='intro-card' style={{
                backgroundImage: `url(${FancyBlurBG2})`
            }}>
                <div className='intro-card-content'>
                    <h1>Outreach</h1>
                    <p className='text'>Engage with your customers through surveys, in-app messages, emails, product tours, banners & so much more.</p>
                    <p className='text'>
                        <span className='title-text'>🚀 Increase sales</span><br />
                        <span className='title-text'>👏 Pro-active support</span>
                    </p>
                    <p className='text'>
                        Your plan <b>does not</b> include outbound messaging.<br /><a href="https://www.gleap.io/pricing" target='_blank'>Learn more</a>
                    </p>
                    <PrimaryButton label="Upgrade plan" onClick={() => {
                        modalStore?.openModalSmart(MODALTYPE.MANAGE_SUBSCRIPTION);
                    }} />
                </div>
                <img className='intro-card-image' src={OutboundBlankImage} style={{
                    objectPosition: 'center'
                }} />
            </div>
        </div>
    );
};

export default inject('modalStore')(observer(AddOutreach));