import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { ProjectStore } from 'stores/private/ProjectStore';
import { SessionStore } from 'stores/private/SessionStore';
import './RecentConversations.scss';
import { useEffect } from 'react';
import LoadingAnimationSmall from 'components/LoadingAnimationSmall/LoadingAnimationSmall';
import { formatDateYearLater } from 'helper/DateHelper';
import { Bug } from 'models/Bug';

interface RecentConversationsProps {
  contactId?: string;
  projectStore?: ProjectStore;
  sessionStore?: SessionStore;
  ticket: Bug;
}

const RecentConversations = ({
  contactId,
  projectStore,
  sessionStore,
  ticket,
}: RecentConversationsProps) => {
  if (!contactId) {
    contactId = ticket?.session?.id
  }
  const projectId = projectStore?.currentProject?.id;
  const conversations = sessionStore?.recentConversationList.data;
  const isLoading = sessionStore?.recentConversationList.isLoading;
  const hasMore = sessionStore?.recentConversationList.hasMore; 

  useEffect(() => {
    loadEvents(false);
  }, [contactId]);

  const loadEvents = async (loadMore) => {
    if (projectStore?.currentProject && contactId) {
      await sessionStore!.loadLastConversations(contactId, loadMore);
    }
  };

  return (
    <div>
      <div className="conversation-list">
        {conversations &&
          conversations.length > 0 &&
          conversations.map((convo) => (
            <div
              key={convo.id}
              className="conversation-item"
              onClick={() => {
                window.open(
                  `/projects/${projectId}/bugs/${convo.shareToken}`,
                  '_blank',
                );
              }}
            >
              <i className="fa-regular fa-share-from-square" />
              <div className="infos">
                <p>{convo.title || 'Request Support'}</p>
                <span className="timestamp">
                  {formatDateYearLater(convo.createdAt) + ', #' + convo.bugId}
                </span>
              </div>
            </div>
          ))}
        {hasMore && (
          <div
            className="sidebar-more-button"
            onClick={() => {
              loadEvents(true);
            }}
          >
            Load more
          </div>
        )}
        {isLoading && (
          <div className="loading-container">
            <LoadingAnimationSmall />
          </div>
        )}
      </div>
    </div>
  );
};

export default inject(
  'sessionStore',
  'projectStore',
)(observer(RecentConversations));
