import React, { useCallback, useEffect, useRef, useState } from 'react';
import './SessionPropertyItem.scss';
import ReactTooltip from 'react-tooltip';
import { useClickedOutside } from 'services/Helper';
import { Session } from 'models/Session';
import { getProperty, setProperty } from 'helper/AssignObjectKeysHelper';
import { SessionStore } from 'stores/private/SessionStore';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

interface SessionPropertyItemProps {
  session: Session;
  label: string;
  path: string;
  type?: React.HTMLInputTypeAttribute | undefined;
  valueFormatter?: (value: any) => string | React.ReactElement;
  readonly?: boolean;
  editable?: boolean;
  sessionStore?: SessionStore;
  className?: string;
}

const SessionPropertyItem = ({
  session,
  label,
  path,
  type = 'text',
  valueFormatter,
  readonly,
  editable,
  sessionStore,
  className,
}: SessionPropertyItemProps) => {
  const [isEditing, setIsEditing] = useState(false);

  const [initalValue, setInitalValue] = useState('');

  useEffect(() => {
    setInitalValue(getProperty(session, path));
  }, [session, path]);

  const updateSession = () => {
    if (initalValue === getProperty(session, path)) return;

    // Split the path to get the root array if it exists (because it's not possible to update at a array index)
    const pathParts = path.split(/[\.\[\]]/).filter((p) => p);
    let rootArrayPath = '';

    for (let i = 0; i < pathParts.length; i++) {
      if (!isNaN(parseInt(pathParts[i]))) {
        // Check if the part is an array index
        break; // Stop when we find the first array index
      }
      rootArrayPath += i > 0 ? '.' + pathParts[i] : pathParts[i];
    }

    const updatedValue = rootArrayPath
      ? getProperty(session, rootArrayPath)
      : getProperty(session, path);

    sessionStore?.updateSession(session.id, {
      [rootArrayPath || path]: updatedValue,
    });
  };

  const handleClickedOutside = () => {
    if (!isEditing) return;

    setIsEditing(false);

    updateSession();
  };

  const wrapperRef = useRef(null);
  useClickedOutside(wrapperRef, handleClickedOutside);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setIsEditing(false);

      updateSession();
    }
  };

  const sessionPropertyItemValueClassName = classNames({
    'contact-details-card-value-row-value': true,
    'session-property-item-value': true,
    'session-property-item-value--readonly': readonly,
    'session-property-item-value--editable': editable,
  });

  const contactDetailsCardValueRowClassName = classNames(
    {
      'contact-details-card-value-row': true,
    },
    className,
  );

  return (
    <div className={contactDetailsCardValueRowClassName} ref={wrapperRef}>
      <div className="contact-details-card-value-row-key">{label}</div>
      {isEditing && (
        <input
          type={type}
          autoFocus
          className="session-property-item-input"
          value={getProperty(session, path)}
          onChange={(e) => {
            setProperty(session, path, e.target.value);
          }}
          onKeyDown={handleKeyDown}
        />
      )}
      {!isEditing && (
        <div
          data-for="sessionPropertyItemTooltip"
          data-tip={getProperty(session, path, false, null) ?? 'Add value'}
          className={sessionPropertyItemValueClassName}
          onClick={() => {
            if (readonly) return;
            setIsEditing(true);
          }}
        >
          {!editable && (
            <>
              <ReactTooltip
                id="sessionPropertyItemTooltip"
                className="infoTooltipBold"
                delayHide={0}
                type="light"
                offset={{
                  top: 14,
                }}
                place="top"
                effect="solid"
              />
            </>
          )}
          {valueFormatter
            ? valueFormatter(getProperty(session, path, false, null))
            : getProperty(session, path, false, null) ?? 'Not set'}
          {!readonly && !editable && <i className="fa-solid fa-pen"></i>}
        </div>
      )}
    </div>
  );
};

export default inject('sessionStore')(observer(SessionPropertyItem));
