import React, { useState } from 'react';
import './UsageLimitSettings.scss';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

interface UsageLimitSettingsProps {
  title: string;
  usageTopic: string;
  value: number;
  min: number;
  max: number;
  currencyFormatter: (value: number) => string;
  onSave: (value: number) => Promise<void>;
}

const UsageLimitSettings = ({
  title,
  usageTopic,
  value = 0,
  min,
  max,
  currencyFormatter,
  onSave,
}: UsageLimitSettingsProps) => {
  const [currentValue, setCurrentValue] = useState(value);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Column className="usage-limit-settings">
      <div className="usage-limit-settings-title">{title}</div>
      <div className="usage-limit-settings-amount-preview">
        {currencyFormatter(currentValue)}
      </div>
      <Slider
        min={min}
        max={max}
        value={currentValue}
        onChange={(value) => {
          setCurrentValue(value);
        }}
      />
      {/* <div className="usage-limit-settings-usage-topic-desc">
        {currentValue} {usageTopic} / mo
      </div> */}
      <PrimaryButton
        className="mt-30"
        label={`Change limit to ${currencyFormatter(currentValue)}`}
        isLoading={isLoading}
        onClick={async () => {
          setIsLoading(true);
          await onSave(currentValue);
          setIsLoading(false);
        }}
      />
    </Column>
  );
};

export default UsageLimitSettings;
