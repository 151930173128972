import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import { checkIntegrationData } from 'helper/Integration';
import { inject, observer } from 'mobx-react';
import { INTEGRATION } from 'models/Integration';
import React, { useEffect, useState } from 'react';
import { AzureIntegrationStore } from 'stores/integrations/AzureIntegrationStore';
import { ProjectStore } from 'stores/private/ProjectStore';

interface AzureConfigurationProps {
  integrationID: string;
  index: number;
  azureIntegrationStore?: AzureIntegrationStore;
  projectStore?: ProjectStore;
  onChange: (data: any) => any;
}

const AzureConfiguration = ({
  integrationID,
  index,
  azureIntegrationStore,
  projectStore,
  onChange,
}: AzureConfigurationProps) => {
  const project = projectStore?.currentProject;

  const [currentIntegrationInfo, setCurrentIntegrationInfo] = useState(
    {} as any,
  );

  const teams = azureIntegrationStore?.teams;
  const channels = azureIntegrationStore?.channels;

  const [teamID, setTeamID] = useState('');
  const [channelID, setChannelID] = useState('');

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    if (project && isMounted) {
      initialize();
    }
  }, [project, isMounted]);

  useEffect(() => {
    saveIntegrationInfo();
  }, [teamID, channelID]);

  const initialize = async () => {
    azureIntegrationStore!.setIntegrationID(integrationID);
    azureIntegrationStore!.getTeams();

    if (
      !checkIntegrationData({
        project,
        integrationName: INTEGRATION.AZURE,
        integrationID,
        actionIndex: index,
      })
    ) {
      return;
    }

    const integrationInfo =
      project?.integrations?.azure[integrationID]?.actions[index]
        ?.integrationInfo;

    setCurrentIntegrationInfo(integrationInfo);
  };

  const chooseTeam = async (teamId) => {
    setTeamID(teamId);
    await azureIntegrationStore?.getChannels(teamId);
  };

  const chooseChannel = async (channelId) => {
    setChannelID(channelId);
  };

  const saveIntegrationInfo = () => {
    const integrationInfo = {
      teamID,
      channelID,
    };

    onChange(integrationInfo);
  };

  return (
    <div className="configuration-container mt-30">
      <SelectDropDown
        className="mb-15"
        label="Team"
        labelPropertyName="displayName"
        valuePropertyName="id"
        selectedItem={teams.find(
          (element) => element.id === currentIntegrationInfo?.teamID,
        )}
        isLoading={teams.length === 0}
        items={teams}
        onChange={(option) => {
          chooseTeam(option.id);
        }}
      />
      {teamID !== '' && (
        <SelectDropDown
          className="mb-15"
          label="Channel"
          labelPropertyName="displayName"
          valuePropertyName="id"
          selectedItem={channels.find(
            (element) => element.id === currentIntegrationInfo?.channelID,
          )}
          isLoading={channels.length === 0}
          items={channels}
          onChange={(option) => {
            chooseChannel(option.id);
          }}
        />
      )}
    </div>
  );
};

export default inject(
  'azureIntegrationStore',
  'projectStore',
)(observer(AzureConfiguration));
