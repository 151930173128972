import axios from './Axios';

export const loadPriceTable = async (
  items: {
    price_id: string;
    quantity: number;
  }[],
): Promise<any> => {
  const res = await axios.post(`/paddle/pricetable`, {
    items,
  });
  return res.data;
};

export const getPaymentMethodUpdateTransaction = async (
  organisationId: string,
) => {
  const res = await axios.get(
    `/paddle/update-payment-method-transaction?organisationId=${organisationId}`,
  );
  if (res?.data?.transactionId) {
    return res.data.transactionId;
  }
  return null;
};

export const updateSubscription = async (
  organisationId: string,
  items: {
    price_id: String;
    quantity: Number;
  }[],
) => {
  const res = await axios.put(
    `/paddle/subscription?organisationId=${organisationId}`,
    {
      items,
    },
  );
  if (res?.data) {
    return res.data;
  }
  return null;
};

export const activateSubscription = async (organisationId: string) => {
  const res = await axios.put(
    `/paddle/subscription/activate?organisationId=${organisationId}`,
    {},
  );
  if (res?.data) {
    return res.data;
  }
  return null;
};

export const cancelSubscription = async (
  organisationId: string,
  cancel: boolean,
  reason?: string,
  reasonExtended?: string,
) => {
  const res = await axios.put(
    `/paddle/subscription/cancel?organisationId=${organisationId}`,
    {
      cancel,
      reason,
      reasonExtended,
    },
  );
  if (res?.data) {
    return res.data;
  }
  return null;
};

export const getPDFForTransaction = async (transactionId: string) => {
  const res = await axios.get(
    `/paddle/pdfinvoice?transactionId=${transactionId}`,
  );
  if (res?.data?.url) {
    return res.data.url;
  }
  return null;
};

export const getSubscription = async (organisationId: string) => {
  const res = await axios.get(
    `/paddle/subscription?organisationId=${organisationId}`,
  );
  if (res?.data) {
    return res.data;
  }
  return null;
};

export const getCustomer = async (organisationId: string) => {
  const res = await axios.get(
    `/paddle/customer?organisationId=${organisationId}`,
  );
  if (res?.data) {
    return res.data;
  }
  return null;
};

export const saveCustomer = async (
  organisationId: string,
  data: {
    address?: any;
    business?: any;
  },
) => {
  const res = await axios.put(
    `/paddle/customer?organisationId=${organisationId}`,
    data,
  );
  if (res?.data) {
    return res.data;
  }
  return null;
};
