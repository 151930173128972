import LinkButton from 'components/LinkButton/LinkButton';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import { isEqual } from 'lodash';
import { inject, observer } from 'mobx-react';
import { useParams } from 'react-router';
import Switch from 'react-switch';
import { timezones } from 'services/Timezones';
import { ProjectStore } from 'stores/private/ProjectStore';
import './OperatingHoursSettings.scss';
import TextInput from 'components/TextInput/TextInput';
import {
  getLanguageProperty,
  setLanguageProperty,
} from 'helper/AssignObjectKeysHelper';
import { runInAction } from 'mobx';
import { useEffect, useState } from 'react';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';

const operatingHoursOptions = [
  {
    label: 'All days',
    value: 'All days',
  },
  {
    label: 'Weekends',
    value: 'Weekends',
  },
  {
    label: 'Weekdays',
    value: 'Weekdays',
  },
  {
    label: 'Monday',
    value: 'Monday',
  },
  {
    label: 'Tuesday',
    value: 'Tuesday',
  },
  {
    label: 'Wednesday',
    value: 'Wednesday',
  },
  {
    label: 'Thursday',
    value: 'Thursday',
  },
  {
    label: 'Friday',
    value: 'Friday',
  },
  {
    label: 'Saturday',
    value: 'Saturday',
  },
  {
    label: 'Sunday',
    value: 'Sunday',
  },
];

const operatingHourTimesOption = Array.from(Array(24).keys()).map((hour) => {
  const formattedHour = hour.toString().padStart(2, '0');
  return {
    label: `${formattedHour}:00`,
    value: `${formattedHour}:00`,
  };
});
operatingHourTimesOption.push({
  label: '23:59',
  value: '23:59',
});

interface OperatingHourSettingsProps {
  projectStore?: ProjectStore;
}

const OperatingHoursSettings = ({
  projectStore,
}: OperatingHourSettingsProps) => {
  const { projectId } = useParams();

  const { flowConfig } = projectStore?.editingProject ?? {};
  const currentLang = projectStore?.currentLanguage ?? 'en';

  const dataToUpdate = {
    timezone: flowConfig?.timezone,
    openingTimes: flowConfig?.openingTimes,
    replyTime: flowConfig?.replyTime,
    showOnlineStatus: flowConfig?.showOnlineStatus,
  };

  useEffect(() => {
    projectStore?.setEditingProject();
  }, [projectStore?.currentProject, projectStore?.flowConfig]);

  const buildTimezoneSelection = () => {
    const selectedTimezone = flowConfig.timezone ?? 'Universal';
    return (
      <SelectDropDown
        className="mb-30 operating-hour-options"
        labelPropertyName="name"
        valuePropertyName="value"
        selectedItem={{
          name: selectedTimezone,
          value: selectedTimezone,
        }}
        items={timezones.map((timezone) => ({
          name: timezone,
          value: timezone,
        }))}
        onChange={(option) => {
          flowConfig.timezone = option.value;
        }}
      />
    );
  };

  const buildOperatingHourSelectionItem = ({ operatingHourItem, index }) => {
    const openingTimes = flowConfig.openingTimes ?? [];

    return (
      <OperatingHourSelectionWithReplyTimeItem
        operatingHourItem={operatingHourItem}
        replyTimeInput={
          <TextInput
            placeholder="We usually reply within a few hours"
            value={getLanguageProperty(
              operatingHourItem,
              'replyTime',
              currentLang,
            )}
            localizedable
            translationObject={flowConfig.replyTime}
            onChange={(val) => {
              runInAction(() => {
                setLanguageProperty(
                  operatingHourItem,
                  'replyTime',
                  currentLang,
                  val,
                );
              });
            }}
          />
        }
      >
        <div
          className="operating-hour-selection-item-container mb-5"
          key={`${operatingHourItem.day}-${index}`}
        >
          <SelectDropDown
            className="mr-5 operating-hour-options"
            labelPropertyName="label"
            valuePropertyName="value"
            selectedItem={operatingHoursOptions.find(
              (element) => element.value === operatingHourItem?.day,
            )}
            items={operatingHoursOptions}
            onChange={(option) => {
              openingTimes[index].day = option.value;
            }}
          />
          <SelectDropDown
            className="mr-10 time-options"
            labelPropertyName="label"
            valuePropertyName="value"
            selectedItem={operatingHourTimesOption.find(
              (element) => element.value === operatingHourItem.openingTime,
            )}
            items={operatingHourTimesOption}
            onChange={(option) => {
              openingTimes[index].openingTime = option.value;
            }}
          />
          <div className="text mr-5">to</div>
          <SelectDropDown
            className="mr-10 time-options"
            labelPropertyName="label"
            valuePropertyName="value"
            selectedItem={operatingHourTimesOption.find(
              (element) => element.value === operatingHourItem.closingTime,
            )}
            items={operatingHourTimesOption}
            onChange={(option) => {
              openingTimes[index].closingTime = option.value;
            }}
          />
          <div
            className="delete-opening-time cursor-focus"
            onClick={() => {
              flowConfig.openingTimes.splice(index, 1);
            }}
          >
            <i className="fa-solid fa-trash" />
          </div>
        </div>
      </OperatingHourSelectionWithReplyTimeItem>
    );
  };

  const buildOperatingHours = () => {
    return (
      <>
        <div className="options-group">
          <div className="options-group-header">General</div>
          <div className="switch-container mt-30 mb-30">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={() => {
                flowConfig.showOnlineStatus = !(
                  flowConfig?.showOnlineStatus ?? false
                );
              }}
              checked={flowConfig?.showOnlineStatus ?? false}
            />
            <span>Show online status</span>
          </div>
          {flowConfig?.showOnlineStatus && (
            <>
              <div className="text bold mb-5">Workspace timezone</div>
              <div className="text mb-10">Select your workspace timezone.</div>
              {buildTimezoneSelection()}
              <div className="text bold mb-5">Set your office hours</div>
              <div className="text mb-20">
                Let people know when your team will be back online. They'll see
                hours converted to their own time zone.
              </div>
              {flowConfig.openingTimes.map((item, index) =>
                buildOperatingHourSelectionItem({
                  operatingHourItem: item,
                  index,
                }),
              )}
              <LinkButton
                label="Add hours"
                onClick={() => {
                  if (!flowConfig.openingTimes) {
                    flowConfig.openingTimes = [];
                  }
                  flowConfig.openingTimes.push({
                    day: 'Weekdays',
                    openingTime: '09:00',
                    closingTime: '17:00',
                  });
                }}
              />
            </>
          )}
        </div>
      </>
    );
  };

  const buildReplyTimeOptionItem = ({ option, index }) => {
    return (
      <label
        className="bb-feedback-multiplechoice-container"
        key={`${option.label}-${index}`}
      >
        <div className="text">{option.label}</div>
        <input
          type="radio"
          name="once"
          checked={flowConfig.replyTime === option.value}
          onChange={() => {
            flowConfig.replyTime = option.value;
          }}
        />
        <span className="bb-feedback-multiplechoice-checkmark" />
      </label>
    );
  };

  const buildReplyTime = () => {
    if (!flowConfig?.showOnlineStatus) {
      return null;
    }

    return (
      <>
        <div className="options-group">
          <div className="options-group-header">Reply time</div>
          <div className="text bold mb-5">Share your reply time</div>
          <div className="text mb-30">
            Set the expectations about how quickly your team replies during
            office hours.
          </div>
          <TextInput
            className="mr-10"
            name="replyTime"
            label="Reply time"
            localizedable
            value={getLanguageProperty(flowConfig, 'replyTime', currentLang)}
            translationObject={flowConfig.replyTime}
            placeholder="We usually reply within a few hours"
            type="text"
            error=""
            onChange={(val) => {
              runInAction(() => {
                setLanguageProperty(flowConfig, 'replyTime', currentLang, val);
              });
            }}
          />
        </div>
      </>
    );
  };

  return (
    <PageContainer className="relativ-full-width-page-container">
      <PageHeadLine title="Operating hours">
        <div className="form-widget-buttons header-content-right">
          <PrimaryButton
            label="Save"
            disabled={isEqual(projectStore?.flowConfig, flowConfig)}
            onClick={() => {
              projectStore?.saveEditingProject({ flowConfig: dataToUpdate });
            }}
          />
        </div>
      </PageHeadLine>

      <PageContent hasTitle isMediumBoxed>
        {buildOperatingHours()}
        {buildReplyTime()}
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore')(observer(OperatingHoursSettings));

const OperatingHourSelectionWithReplyTimeItem = ({
  children,
  operatingHourItem,
  replyTimeInput,
}) => {
  const [showOverrideReplyTime, setShowOverrideReplyTime] = useState(false);

  return (
    <Column className="mb-20">
      {children}
      {showOverrideReplyTime ||
      (operatingHourItem.replyTime && operatingHourItem.replyTime !== '') ? (
        replyTimeInput
      ) : (
        <div
          className="text override-reply-time cursor-focus"
          onClick={() => {
            setShowOverrideReplyTime(true);
          }}
        >
          Override default reply time
        </div>
      )}
    </Column>
  );
};
