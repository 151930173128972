import classNames from 'classnames';
import React from 'react';
import './ColumnComponent.scss';

interface ColumnProps {
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch';
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline';
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  onClick?: () => void;
}

const Column = ({
  justifyContent,
  alignItems,
  children,
  className,
  onClick,
}: ColumnProps): JSX.Element => {
  const columnContainerClassName = classNames(
    {
      'column-container': true,
    },
    className,
  );

  return (
    <div
      className={columnContainerClassName}
      style={{ justifyContent, alignItems }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Column;
