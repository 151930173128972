import FeedbackUserFilter from 'components/FeedbackUserFilter/FeedbackUserFilter';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import Switch from 'react-switch';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './CloseTicketActionEditor.scss';
import InfoBox from 'components/InfoBox/InfoBox';

interface CloseTicketActionEditorProps {
  action: any;
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
}

const CloseTicketActionEditor = ({
  action,
  modalStore,
  projectStore,
}: CloseTicketActionEditorProps) => {
  return (
    <div className="edit-feature-request-modal">
      <PageContainer>
        <PageHeadLine title="Close ticket">
          <div className="header-content-right">
            <PrimaryButton
              label="Close"
              iconSideRight={false}
              onClick={() => {
                modalStore!.closeModal();
              }}
            />
          </div>
        </PageHeadLine>
        <PageContent hasTitle>
          <div className='tagcontainer'>
            <div className="input-label">Options</div>
            <div className="switch-container mb-20 mt-10">
              <Switch
                width={40}
                onColor="#2142E7"
                height={20}
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={(checked) => {
                  runInAction(() => {
                    action.closeConversation = checked;
                  });
                }}
                checked={action.closeConversation ?? false}
              />
              <span>Close conversation for user (when enabled, the user can't reply to the ticket anymore)</span>
            </div>

            {action.closeConversation ? <InfoBox>
                The customer will not be able to reply to the ticket anymore.
              </InfoBox> : <InfoBox>
                The customer will still be able to reply to the ticket. A customer reply will re-open the ticket.
              </InfoBox>}
          </div>
        </PageContent>
      </PageContainer>
    </div>
  );
};

export default inject(
  'modalStore',
  'projectStore',
)(observer(CloseTicketActionEditor));
