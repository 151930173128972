import GleapDemoFrame from 'components/GleapDemoFrame/GleapDemoFrame';
import PaddleNewPriceTable from 'components/PaddleNewPriceTable/PaddleNewPriceTable';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import StartFreeTrialButton from 'components/StartFreeTrialButton/StartFreeTrialButton';
import { useEffect, useState } from 'react';
import './UpsellOnboardingModal.scss';
import { trackEvent } from 'services/GTagHelper';

const UpsellOnboardingModal = () => {
  const [page, setPage] = useState('demo');

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin === 'https://demo.arcade.software') {
        try {
          if (event.data.eventName === 'Video Ended') {
            setPage('subscribe');
          }
        } catch (e) {}
      }
    };

    window.addEventListener('message', handleMessage, false);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('message', handleMessage, false);
    };
  }, []);

  if (page === 'demo') {
    return (
      <div className="onboarding-cont">
        <div className="onboarding-cont-main onboarding-cont-main--wide">
          <div className="onboarding-title">
            Let's get started with a quick demo 🎬🍿
          </div>
          <div className="text mb-10">
            Isabella shows you how to get started with Gleap in just a few
            minutes.
          </div>
          <div className="mb-20">
            <PrimaryButton
              iconSideRight
              label="Skip demo"
              icon="forward"
              onClick={() => {
                trackEvent('skip.demo_video');
                setPage('subscribe');
              }}
            />
          </div>
          <GleapDemoFrame />
        </div>
      </div>
    );
  }

  return (
    <div className="onboarding-cont">
      <div className="onboarding-cont-main onboarding-cont-main--wide onboarding-cont-main--autoheight">
        <div className="onboarding-title">Start your 14-day free trial ✨</div>
        <StartFreeTrialButton />
        <PaddleNewPriceTable />
      </div>
    </div>
  );
};

export default UpsellOnboardingModal;
