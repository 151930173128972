import React, { useCallback, useEffect, useState } from 'react';
import './LinkTaskModal.scss';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { inject, observer } from 'mobx-react';
import { getTicketsForProject } from 'services/ProjectHttpService';
import { BugStore } from 'stores/private/BugStore';
import { debounce } from 'lodash';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import TextInput from 'components/TextInput/TextInput';
import LoadingAnimationSmall from 'components/LoadingAnimationSmall/LoadingAnimationSmall';
import TabButton from 'components/TabButton/TabButton';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import { truncate } from 'helper/FormDataHelper';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { linkTicket } from 'services/BugHttpService';

interface LinkTaskModalProps {
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
  bugStore?: BugStore;
}

const LinkTaskModal = ({
  modalStore,
  projectStore,
  bugStore,
}: LinkTaskModalProps) => {
  const currentTicket = bugStore?.currentBug;
  const onFinished = modalStore?.customData?.onFinished;

  const [isLoading, setIsLoading] = useState(false);
  const [fullTextFilter, setFullTextFilter] = useState('');
  const [currentFilterTab, setCurrentFilterTab] = useState('all');
  const [latestContactTickets, setLatestContactTickets] = useState([]);
  const [ticketToLink, setTicketToLink] = useState();

  const ticketsSearchData = projectStore!.ticketSearchData;

  useEffect(() => {
    if (currentTicket) {
      initialize();
    }
  }, [currentTicket]);

  const initialize = async () => {
    try {
      const contactTickets = await getTicketsForProject({
        projectId: currentTicket?.project,
        query: {
          session: currentTicket?.session?.id,
          limit: 15,
          sort: '-createdAt',
        },
      });

      setLatestContactTickets(contactTickets.data.tickets ?? []);
    } catch (_) {}
  };

  const debounceSearch = useCallback(
    debounce((nextValue) => {
      projectStore?.duplicateTicketSearch({ searchTerm: nextValue });
    }, 750),
    [],
  );

  const tickets =
    currentFilterTab === 'session'
      ? latestContactTickets
      : ticketsSearchData.data.map(
          (feedbackItem: any) => feedbackItem.document,
        );

  const filteredTickets = tickets
    .map((feedbackItem: any) => feedbackItem)
    .filter((feedbackItem) => {
      if (!feedbackItem) {
        return false;
      }

      if (feedbackItem.id === currentTicket?.id) {
        return false;
      }

      return true;
    });

  return (
    <Column>
      <div className="link-task-search-container">
        <TextInput
          className="fulltext-filter mb-15"
          placeholder="Search"
          type="text"
          error=""
          icon="magnifying-glass"
          initalValue={fullTextFilter}
          onChange={(text) => {
            setFullTextFilter(text);
            setCurrentFilterTab('all');
            debounceSearch(text);
          }}
        />
        {ticketsSearchData.isLoading && fullTextFilter.length > 0 && (
          <div className="link-task-search-container-loading">
            <LoadingAnimationSmall />
          </div>
        )}
      </div>
      <div className="tab-buttons">
        <div className="tab-buttons--inner">
          <TabButton
            active={currentFilterTab === 'all'}
            label="All"
            onClick={() => {
              setCurrentFilterTab('all');
              debounceSearch(fullTextFilter);
            }}
          />
          <TabButton
            active={currentFilterTab === 'session'}
            label="Current contact"
            onClick={() => {
              setCurrentFilterTab('session');
            }}
          />
        </div>
      </div>
      <div className="list-table-wrapper">
        {filteredTickets.length === 0 && !ticketsSearchData.isLoading && (
          <div className="text mt-20">No tickets found</div>
        )}
        {filteredTickets.map((ticket: any, index) => (
          <Column>
            <Row
              className="link-task-list-item"
              alignItems="center"
              justifyContent="space-between"
              onClick={() => {
                setTicketToLink(ticket.id);
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <input
                  className="link-task-list-item-radio"
                  type="radio"
                  name={`ticket-to-merge-${ticket.id}`}
                  checked={ticketToLink === ticket.id}
                  onChange={() => {
                    setTicketToLink(ticket.id);
                  }}
                />
                <div className="ml-10 text link-task-list-item-ticket-number">
                  #{ticket.bugId}
                </div>
              </div>
              <div className="text link-task-list-item-ticket-title">
                {truncate(ticket.title, 150)}
              </div>
            </Row>
            {index !== tickets.length - 1 && (
              <div className="link-task-list-item-divider" />
            )}
          </Column>
        ))}
      </div>
      <Row justifyContent="flex-end">
        <PrimaryButton
          label="Link ticket"
          disabled={!ticketToLink}
          isLoading={isLoading}
          onClick={async () => {
            if (!ticketToLink || !currentTicket?.id) {
              return;
            }

            setIsLoading(true);

            await linkTicket({
              ticketToLinkId: ticketToLink,
              sourceTicketId: currentTicket?.id,
            });

            if (onFinished) {
              onFinished();
            }

            setIsLoading(false);
            modalStore?.closeModal();
          }}
        />
      </Row>
    </Column>
  );
};

export default inject(
  'modalStore',
  'projectStore',
  'bugStore',
)(observer(LinkTaskModal));
