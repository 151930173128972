import { Editor } from '@tiptap/react';
import AddValue from 'components/AddValue/AddValue';
import EmailEditor from 'components/Editors/EmailEditor/EmailEditor';
import RichTextEditor from 'components/Editors/RichTextEditor/RichTextEditor';
import Toolbar, { ToolbarItems } from 'components/Editors/ToolBar/Toolbar';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useClickedOutside } from 'services/Helper';
import './RichTextPreviewField.scss';
import { toast } from 'react-toastify';
import copyToClipboard from 'copy-to-clipboard';
import { convertTipTapToPlainText } from 'helper/TipTapHelper';
import TipTapPreview from 'components/Editors/RichTextEditor/TipTapPreview';
import { ProjectStore } from 'stores/private/ProjectStore';
import { inject, observer } from 'mobx-react';

interface RichTextPreviewFieldProps {
  readOnly?: boolean;
  value?: string;
  onChange?: (value: any) => void;
  onBlur?: () => void;
  projectStore?: ProjectStore;
}

export const makeRichTextBackwardsComp = (value: any) => {
  if (value && typeof value === 'string') {
    const paragraphs = value.split('\n');
    let paras: any[] = [];

    paragraphs.forEach((para) => {
      // Add a text node if the paragraph is not empty
      if (para.trim()) {
        paras.push({
          type: 'text',
          text: para,
        });
      }

      // Add a hardBreak after each paragraph except the last
      if (para !== para[para.length - 1]) {
        paras.push({
          type: 'hardBreak',
        });
      }
    });

    // Construct the final JSON structure
    return {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: paras,
        },
      ],
    };
  }

  return value;
};

const RichTextPreviewField = ({
  readOnly,
  value,
  onChange,
  onBlur,
  projectStore,
}: RichTextPreviewFieldProps) => {
  const editorRef: MutableRefObject<Editor | null> = useRef(null);
  const currentLang = projectStore?.currentLanguage ?? 'en';
  
  const [isEditing, setIsEditing] = useState(false);
  const [toggleState, setToggleState] = useState(false);

  useEffect(() => {
    if (isEditing && value) {
      editorRef?.current?.commands.setContent(value !== '' ? value : {});
    }
  }, [editorRef, isEditing]);

  useEffect(() => {
    setToggleState(!toggleState);
  }, [value]);

  const wrapperRef = useRef(null);
  useClickedOutside(wrapperRef, () => {
    setIsEditing(false);
    onBlur && onBlur();
    editorRef.current = null;
  });

  const setIsEditingActive = () => {
    if (readOnly) {
      return;
    }

    setIsEditing(true);
  };

  if (!isEditing) {
    return (
      <div
        className="richtext-preview-container"
        onClick={(event) => {
          event.stopPropagation();
          if ((event?.target as any)?.tagName?.toLowerCase() === 'a') {
            return;
          }

          setIsEditingActive();
        }}
      >
        {!value || value === '' ? (
          <AddValue />
        ) : (
          <TipTapPreview key={`richtext-${toggleState}`} content={value} />
        )}
        <Row className="hover-action-row">
          {!readOnly && (
            <div
              className="hover-action mr-5"
              onClick={(event) => {
                event.stopPropagation();
                setIsEditingActive();
              }}
            >
              <i className="fas fa-pencil-alt hover-action-icon"></i>
            </div>
          )}
          <div
            className="hover-action"
            onClick={(event) => {
              event.stopPropagation();

              const plainText = convertTipTapToPlainText({
                content: value,
              });

              copyToClipboard((plainText || '')?.toString());
              toast.success('Copied to clipboard');
            }}
          >
            <i className="fas fa-clipboard hover-action-icon"></i>
          </div>
        </Row>
      </div>
    );
  }

  return (
    <div className="rich-text-preview-field-container" ref={wrapperRef}>
      <>
        {editorRef?.current && (
          <div className="richt-text-preview-toolbar">
            <Toolbar
              editor={editorRef?.current}
              items={[
                ToolbarItems.Basic,
                ToolbarItems.Advanced,
                ToolbarItems.Image,
              ]}
              language={currentLang}
            />
          </div>
        )}
        <EmailEditor
          editorRef={editorRef}
          onEditorReady={() => {
            setToggleState(!toggleState);
          }}
          content={value}
          inputContentChanged={(content) => {
            onChange && onChange(content);
          }}
        />
      </>
    </div>
  );
};

export default inject(
  'projectStore',
)(observer(RichTextPreviewField));