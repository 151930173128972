import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TabButton from 'components/TabButton/TabButton';
import Gleap from 'gleap';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { loadPriceTable } from 'services/PaddleHttpService';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { PaddleStore } from 'stores/private/PaddleStore';
import './PaddlePriceTable.scss';
import LoadingAnimationMedium from 'components/LoadingAnimationMedium/LoadingAnimationMedium';

interface PaddlePriceTableProps {
    paddleStore?: PaddleStore;
    organisationStore?: OrganisationStore;
    showPlans?: PaddlePlantypes[];
}

export const currencySymbols = {
    ARS: "$",
    AUD: "A$",
    BRL: "R$",
    GBP: "£",
    CAD: "CA$",
    CNY: "¥",
    CZK: "Kč",
    DKK: "kr.",
    EUR: "€",
    HKD: "HK$",
    HUF: "Ft",
    INR: "₹",
    ILS: "₪",
    JPY: "¥",
    MXN: "MX$",
    TWD: "NT$",
    NZD: "NZ$",
    NOK: "kr",
    PLN: "zł",
    RUB: "₽",
    SGD: "S$",
    ZAR: "R",
    KRW: "₩",
    SEK: "kr",
    CHF: "Fr.",
    THB: "฿",
    TRY: "₺",
    UAH: "₴",
    USD: "$"
};

function calculateMonthlyPrice(data) {
    // Safely access nested properties
    const totalCents = data?.totals?.subtotal;
    const interval = data?.price?.billing_cycle?.interval;
    const currencyCode = data?.price?.unit_price?.currency_code;

    // If the necessary data is not present or the interval is not "year", return formatted total
    if (!totalCents || interval !== "year") {
        return data?.formatted_totals?.subtotal || null;
    }

    // Calculate the monthly total in cents
    const monthlyTotalCents = parseInt(totalCents) / 12;

    // Convert to dollars and cents for formatting
    const monthlyTotalDollars = Math.floor(monthlyTotalCents / 100);
    const monthlyTotalRemainderCents = monthlyTotalCents % 100;

    // Format using the currencyCode
    return `${currencySymbols[currencyCode] || currencyCode}${monthlyTotalDollars}.${Math.floor(monthlyTotalRemainderCents).toString().padStart(2, '0')}`;
}

export enum PaddlePlantypes {
    HOBBY = 'hobby',
    TEAM = 'team',
}

export const paddlePlans = {
    hobby: {
        name: 'Gleap Hobby',
        nameSolo: 'Hobby',
        featuresHeadline: null,
        subscriptionId: process.env.REACT_APP_PADDLE_HOBBY_PRODUCT_ID,
        monthly: {
            trial: process.env.REACT_APP_PADDLE_HOBBY_PRICE_MONTHLY,
            notrial: process.env.REACT_APP_PADDLE_HOBBY_PRICE_MONTHLY_NOTRIAL
        },
        annually: {
            trial: process.env.REACT_APP_PADDLE_HOBBY_PRICE_ANUALLY,
            notrial: process.env.REACT_APP_PADDLE_HOBBY_PRICE_ANUALLY_NOTRIAL
        },
        teammembers: "1 team member",
        dataRetention: "1 month data retention",
        trackedEvents: "100.000 tracked events",
        features: [
            "In-app bug reporting",
            "Feature requests",
            "Live-chat",
            "Help center",
            "Kai, AI bot"
        ]
    },
    team: {
        name: 'Gleap Team',
        nameSolo: 'Team',
        subscriptionId: process.env.REACT_APP_PADDLE_TEAM_PRODUCT_ID,
        monthly: {
            trial: process.env.REACT_APP_PADDLE_TEAM_PRICE_MONTHLY,
            notrial: process.env.REACT_APP_PADDLE_TEAM_PRICE_MONTHLY_NOTRIAL
        },
        annually: {
            trial: process.env.REACT_APP_PADDLE_TEAM_PRICE_ANUALLY,
            notrial: process.env.REACT_APP_PADDLE_TEAM_PRICE_ANUALLY_NOTRIAL
        },
        featuresHeadline: "Everything in Hobby +",
        teammembers: "Unlimited team members",
        dataRetention: "Unlimited data retention",
        trackedEvents: "10 mio. tracked events",
        features: [
            "All integrations",
            "Console & network logs",
            "Custom domain",
            "Remove Gleap branding"
        ]
    },
    aiOnly: {
        name: 'AppSumo',
        nameSolo: 'AppSumo',
        subscriptionId: process.env.REACT_APP_PADDLE_AI_ONLY_PRODUCT_ID,
        monthly: {
            trial: "",
            notrial: ""
        },
        annually: {
            trial: "",
            notrial: ""
        },
        featuresHeadline: "Everything in Hobby +",
        teammembers: "Unlimited team members",
        dataRetention: "Unlimited data retention",
        trackedEvents: "100k tracked events",
        features: [
            "All integrations",
            "Console & network logs",
            "In-app surveys",
            "Custom domain",
            "Outreach features",
            "Help center",
            "Remove Gleap branding"
        ]
    }
};

export const getPaddlePlanByProductId = (productId) => {
    for (const planKey in paddlePlans) {
        if (paddlePlans.hasOwnProperty(planKey)) {
            if (paddlePlans[planKey].subscriptionId === productId) {
                return paddlePlans[planKey];
            }
        }
    }
    
    return null;
};

const PaddlePriceTable = ({
    paddleStore,
    organisationStore,
    showPlans = [PaddlePlantypes.HOBBY, PaddlePlantypes.TEAM],
}: PaddlePriceTableProps) => {
    const [priceInfo, setPriceInfo] = useState(null as any);
    const [currentPeriod, setCurrentPeriod] = useState('monthly');
    const trialUsed = organisationStore?.currentOrganisation?.trialUsed ? true : false;

    useEffect(() => {
        // Load price info.
        var prices: {
            price_id: string,
            quantity: number,
        }[] = [];

        for (const plan in paddlePlans) {
            prices.push({
                price_id: paddlePlans[plan].monthly.trial,
                quantity: 1,
            });
            prices.push({
                price_id: paddlePlans[plan].monthly.notrial,
                quantity: 1,
            });
            prices.push({
                price_id: paddlePlans[plan].annually.trial,
                quantity: 1,
            });
            prices.push({
                price_id: paddlePlans[plan].annually.notrial,
                quantity: 1,
            });
        }

        loadPriceTable(prices.filter((elem) => elem.price_id && elem.price_id.length > 0)).then((data) => {
            setPriceInfo(data);
        }).catch((err) => { });
    }, []);

    const planButton = (price) => {
        const priceId = !trialUsed ? price.trial : price.notrial;

        return (
            <PrimaryButton
                fullWidth
                className="mt-20"
                onClick={() => {
                    paddleStore?.checkoutWithPrice(priceId);
                }}
                label={
                    organisationStore?.currentOrganisation?.trialUsed
                        ? 'Subscribe now ✨'
                        : 'Start free trial'
                }
            />
        );
    };

    function getPrice(price) {
        const priceId = !trialUsed ? price.trial : price.notrial;
        for (let item of priceInfo) {
            if (item.price.id === priceId) {
                return item;
            }
        }
        return null;
    }

    if (!priceInfo) {
        return (<>
            <LoadingAnimationMedium />
        </>);
    }

    return (
        <div>
            <div className="tab-buttons page-content-tabs">
                <div className="tab-buttons--inner">
                    <TabButton
                        active={currentPeriod === 'monthly'}
                        label="Monthly"
                        onClick={() => {
                            setCurrentPeriod('monthly');
                        }}
                    />
                    <TabButton
                        active={currentPeriod === 'annually'}
                        label="Anually"
                        tagLabel='Save 20%'
                        onClick={() => {
                            setCurrentPeriod('annually');
                        }}
                    />
                </div>
            </div>
            <div className="pricetable-container">
                {showPlans.map((plan) => {
                    const planData = paddlePlans[plan];
                    var price = planData.monthly;
                    if (currentPeriod === 'annually') {
                        price = planData.annually;
                    }
                    const localizedPrice = getPrice(price);

                    var prePrice = null;
                    if (currentPeriod === 'annually') {
                        const localizedMonthlyPrice = getPrice(planData.monthly);
                        prePrice = calculateMonthlyPrice(localizedMonthlyPrice);
                    }


                    return (<div className={`pricing-card ${plan == PaddlePlantypes.TEAM ? 'pricing-card--colored' : ''}`}>
                        <div className='pricing-card-inner'>
                            <div>
                                <h2 className="pricingheadlinetop">{planData.nameSolo}</h2>
                                <h2 className="pricingheadline"><span className='planpre preprice'>{prePrice}</span> <span className='price'>{calculateMonthlyPrice(localizedPrice)}</span> <span className='planpost'>/ month</span></h2>
                            </div>
                            {planButton(price)}
                            <div className="pricinginfocard pricinginfocardtop">
                                <div>{planData.teammembers}</div>
                            </div>
                            <div className="pricinginfocard pricinginfocardmid">
                                <div>{planData.dataRetention}</div>
                            </div>
                            <div className="pricinginfocard pricinginfocardbottom">
                                <div>{planData.trackedEvents}</div>
                            </div>
                            <div className="pricingfeatures">
                                {planData.featuresHeadline && <h5>{planData.featuresHeadline}</h5>}
                                {planData.features.map((feature, index) => (
                                    <React.Fragment key={index}>
                                        {feature}
                                        {index < planData.features.length - 1 && <br />}
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </div>);
                })}
                <div className="pricing-card">
                    <div>
                        <h2 className="pricingheadlinetop">Enterprise</h2>
                        <h2 className="pricingheadline"><span className='price'>Let's talk</span></h2>
                    </div>
                    <PrimaryButton
                        fullWidth
                        className="mt-20"
                        onClick={() => {
                            Gleap.open();
                        }}
                        label={"Contact us"}
                    />
                    <div className="pricinginfocard pricinginfocardtop">
                        <div>Unlimited team members</div>
                    </div>
                    <div className="pricinginfocard pricinginfocardmid">
                        <div>Unlimited data retention</div>
                    </div>
                    <div className="pricinginfocard pricinginfocardbottom">
                        <div>Custom tracked events limit</div>
                    </div>
                    <div className="pricingfeatures">
                        <h5>Everything in Team +</h5>
                        High priority support
                        <br />
                        Custom MAU limit
                        <br />
                        99.9 % SLA
                    </div>
                </div>
            </div>
        </div>
    );
};

export default inject('paddleStore', 'organisationStore')(observer(PaddlePriceTable));
