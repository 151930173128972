import { GenericHttpClient } from './config/http.generic.client';

export class HttpEmailTemplateClientService extends GenericHttpClient<any> {
  static _instance: HttpEmailTemplateClientService;
  static getInstance(): HttpEmailTemplateClientService {
    if (this._instance == null) {
      this._instance = new HttpEmailTemplateClientService('/email-templates');
    }
    return this._instance;
  }
}
