import GleapWidget from 'assets/GleapWidget.png';
import OnboardingHeader from 'components/OnboardingHeader/OnboardingHeader';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import './CreateOrganisationOnboarding.scss';
import { UsersStore } from 'stores/private/UsersStore';

interface CreateOrganisationOnboardingProps {
  organisationStore?: OrganisationStore;
  usersStore?: UsersStore;
}

export const CreateOrganisationOnboarding = ({
  organisationStore,
  usersStore,
}: CreateOrganisationOnboardingProps) => {
  const [imageUrl, setImageUrl] = useState('');
  const [orgaName, setOrgaName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  
  const createOrganization = () => {
    if (!orgaName || orgaName.length === 0 || isLoading) {
      return;
    }

    setIsLoading(true);
    organisationStore!
      .createOrganisation(orgaName, imageUrl)
      .then((createdOrganisation: any) => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  if (!organisationStore) {
    return <></>;
  }

  return (
    <div className='onboarding-cont'>
      <div className='onboarding-cont-main'>
        <div className='onboarding-title'>
          Hey {usersStore?.currentUser?.firstName ?? 'there'} 👋<br />
          <span className='onboarding-title-sub'>Let's get started!</span>
        </div>
        <TextInput
          required
          placeholder="Name of your organization"
          type="text"
          name="organame"
          className="mt-10 max-form-card text-centered"
          error=''
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              createOrganization();
            }
          }}
          onChange={(value) => {
            setOrgaName(value);
          }}
        />
        <div className='onboarding-cont-buttons'>
          <PrimaryButton
            icon='arrow-right'
            iconSideRight={true}
            label="Create"
            isLoading={isLoading}
            disabled={orgaName.length === 0}
            onClick={() => {
              createOrganization();
            }}
          />
        </div>
      </div>
      <div className='onboarding-cont-side'>
        <img src={GleapWidget} />
      </div>
    </div>
  );
};

export default inject('organisationStore', 'usersStore')(observer(CreateOrganisationOnboarding));
