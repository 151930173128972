import MagicFilter from 'components/MagicFilter/MagicFilter';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { BugPriorities } from 'models/Bug';
import { ProjectStore } from 'stores/private/ProjectStore';
import './BoardFilter.scss';

export const UNREAD_OPTIONS = [
  {
    value: true,
    title: 'Unread',
    icon: 'fa-solid fa-eye-slash',
  },
  {
    value: false,
    title: 'Read',
    icon: 'fa-solid fa-eye',
  },
];

export const SLA_BREACHED_OPTIONS = [
  {
    value: true,
    title: 'Breached',
    icon: 'fa-solid fa-triangle-exclamation',
  },
  {
    value: false,
    title: 'Not breached',
    icon: 'fa-solid fa-triangle',
  },
];

interface BoardFilterProps {
  filtersToShow?: string[];
  projectStore?: ProjectStore;
  filterUpdated?: (conditions: any) => void;
  initialFilters?: { key: string; value: any };
  filterStorageKey?: string | null;
}

const BoardFilter = ({
  filtersToShow = [],
  projectStore,
  filterUpdated,
  initialFilters,
  filterStorageKey,
}: BoardFilterProps) => {
  const filterKey =
    filterStorageKey ??
    `project-${projectStore?.currentProject?.id}-${projectStore?.currentFeedbackType?.type}-filters`;
  const renderFilters =
    projectStore?.currentProject?.id && projectStore?.currentFeedbackType?.type;

  const updateFilter = (
    obj,
    newFilter,
    objKey,
    dataFormatter = (value) => {
      return value;
    },
  ) => {
    Object.keys(newFilter).forEach((key) => {
      if (key === objKey || key.startsWith(`${objKey}.`)) {
        obj[key] = dataFormatter(newFilter[key]);
      }
    });
  };

  if (!renderFilters) {
    return null;
  }

  return (
    <>
      <MagicFilter
        projectStore={projectStore}
        filtersChanged={(newActiveFilters) => {
          let newFilterObject: any = {};

          updateFilter(newFilterObject, newActiveFilters, 'priority');
          updateFilter(newFilterObject, newActiveFilters, 'tags');
          updateFilter(newFilterObject, newActiveFilters, 'processingTeam');
          updateFilter(newFilterObject, newActiveFilters, 'processingUser');
          updateFilter(newFilterObject, newActiveFilters, 'url');
          updateFilter(newFilterObject, newActiveFilters, 'title');
          updateFilter(newFilterObject, newActiveFilters, 'createdAt');
          updateFilter(newFilterObject, newActiveFilters, 'mentions');
          updateFilter(
            newFilterObject,
            newActiveFilters,
            'slaBreached',
            (value) => {
              if (value && value.length > 0) {
                return value[0];
              }
              return false;
            },
          );
          updateFilter(
            newFilterObject,
            newActiveFilters,
            'notificationsUnread',
            (value) => {
              if (value && value.length > 0) {
                return value[0];
              }
              return null;
            },
          );

          updateFilter(newFilterObject, newActiveFilters, 'session');
          if (newFilterObject.hasOwnProperty('session')) {
            delete newFilterObject.session;
          }

          filterUpdated?.(newFilterObject);

          // Update filter object
          runInAction(() => {
            if (!filterUpdated) {
              projectStore!.currentTicketDataFilter = newFilterObject;
              projectStore?.filterTicketsData();
            }
          });
        }}
        key={filterKey}
        filterStorageKey={filterKey}
        initialFilters={initialFilters}
        filterOptions={[
          {
            type: 'optionslist',
            key: 'priority',
            name: 'Priority',
            icon: 'fa-solid fa-traffic-light',
            config: {
              options: Object.values(BugPriorities),
              cellRenderer: (item) => {
                return (
                  <div className="magic-list-item">
                    <div
                      className="magic-list-item-dot"
                      style={{
                        backgroundColor: item.color,
                      }}
                    />
                    <span className="magic-list-item-text">{item.title}</span>
                  </div>
                );
              },
            },
          },
          {
            type: 'optionslist',
            key: 'notificationsUnread',
            name: 'Read status',
            icon:
              UNREAD_OPTIONS.find(
                (option) =>
                  option.value ===
                  projectStore?.currentTicketDataFilter?.notificationsUnread,
              )?.icon ?? 'fa-solid fa-eye',
            config: {
              singleSelect: true,
              options: UNREAD_OPTIONS,
              cellRenderer: (item) => {
                return (
                  <div className="magic-list-item">
                    <div className="magic-list-item-icon">
                      <i className={`fa-solid ${item.icon}`} />
                    </div>
                    <span className="magic-list-item-text">{item.title}</span>
                  </div>
                );
              },
            },
          },
          {
            type: 'optionslist',
            key: 'slaBreached',
            name: 'SLA',
            icon:
              SLA_BREACHED_OPTIONS.find(
                (option) =>
                  option.value ===
                  projectStore?.currentTicketDataFilter?.slaBreached,
              )?.icon ?? 'fa-solid fa-triangle-exclamation',
            config: {
              singleSelect: true,
              options: SLA_BREACHED_OPTIONS,
              cellRenderer: (item) => {
                return (
                  <div className="magic-list-item">
                    <div className="magic-list-item-icon">
                      <i className={`fa-solid ${item.icon}`} />
                    </div>
                    <span className="magic-list-item-text">{item.title}</span>
                  </div>
                );
              },
            },
          },
          {
            type: 'tag',
            key: 'tags',
            name: 'Tag',
            icon: 'fa-solid fa-tag',
          },
          {
            type: 'team',
            key: 'processingTeam',
            name: 'Team',
            icon: 'fa-solid fa-screen-users',
          },
          {
            type: 'assigned',
            key: 'processingUser',
            name: 'Assigned',
            icon: 'fa-solid fa-user',
          },
          {
            type: 'text',
            key: 'url',
            name: 'URL',
            icon: 'fa-solid fa-globe-pointer',
            config: {
              placeholder: 'https://...',
            },
          },
          {
            type: 'text',
            key: 'title',
            name: 'Ticket title',
            icon: 'fa-solid fa-input-text',
            config: {
              placeholder: 'Search for a ticket title...',
            },
          },
          {
            type: 'assigned',
            key: 'mentions',
            name: 'Mentioned',
            icon: 'fa-solid fa-at',
          },
          {
            type: 'daterange',
            key: 'createdAt',
            name: 'Created At',
            icon: 'fa-solid fa-calendar-days',
          },
          {
            type: 'audience',
            key: 'session',
            config: {
              nested: true,
            },
            name: 'Contact',
            icon: 'fa-solid fa-address-book',
          },
        ]}
      />
    </>
  );
};

export default inject('projectStore')(observer(BoardFilter));
