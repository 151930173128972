import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import { getChannels } from 'services/integrations/DiscordHttpService';

export class DiscordIntegrationStore {
  stores: any = {};

  integrationID: string = '';

  channels: any = [];

  constructor() {
    makeAutoObservable(this);
  }

  setStores(stores) {
    this.stores = stores;
  }

  setIntegrationID = (integrationID) => {
    this.integrationID = integrationID;
  };

  setChannels = (channels) => {
    this.channels = channels;
  };

  getChannels = async () => {
    if (!this.stores?.projectStore?.currentProject?.id) {
      return;
    }

    try {
      const response = await getChannels({
        projectID: this.stores.projectStore.currentProject.id,
        integrationID: this.integrationID,
      });
      if (response.status === 200) {
        this.setChannels(response.data);
      }
    } catch (err: any) {
      toast.error('Something went wrong!');
    }
  };
}
