import { inject, observer } from 'mobx-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import ReactTooltip from 'react-tooltip';
import { isMacintosh, useClickedOutside } from 'services/Helper';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './SendInputWorkflowAction.scss';

const SendInputWorflowAction = ({
  onWorkflowSelected,
  projectStore,
  modalStore,
}: {
  onWorkflowSelected: (workflow: any) => void;
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}) => {
  const scrollContainerRef = useRef(null as any);
  const navigate = useNavigate();
  const [currentlyFocused, setCurrentlyFocused] = useState(0);
  const currentlyFocusedRef = useRef(currentlyFocused);
  currentlyFocusedRef.current = currentlyFocused;
  const [showWorkflowsList, setShowWorkflowsList] = useState(false);
  const showWorkflowsListRef = useRef(showWorkflowsList);
  const [searchText, setSearchText] = useState('');
  showWorkflowsListRef.current = showWorkflowsList;
  const workflowList: any[] = [
    ...(projectStore?.bots ?? []).filter((workflow) => {
      return workflow.status === 'live' && workflow.project === projectStore?.currentProject?.id;
    }),
    { create: true, status: '', name: '', trigger: '', triggerType: '' },
  ];
  const wrapperRef = useRef(null);
  useClickedOutside(wrapperRef, () => {
    setShowWorkflowsList(false);
  });

  useEffect(() => {
    if (showWorkflowsList && projectStore?.currentProject?.id) {
      projectStore?.getBots(projectStore?.currentProject?.id);
    }
  }, [showWorkflowsList]);

  const filteredWorkflowList = (!searchText || searchText.length === 0) ? workflowList : workflowList
    .filter((workflow) =>
      workflow.name.toLowerCase().includes(searchText.toLowerCase()),
    );
  const filteredWorkflowListRef = useRef(filteredWorkflowList);
  filteredWorkflowListRef.current = filteredWorkflowList;

  useEffect(() => {
    if (scrollContainerRef && scrollContainerRef.current) {
      const children = scrollContainerRef.current.children;
      if (children && children[currentlyFocused]) {
        children[currentlyFocused].scrollIntoView({ block: 'nearest' });
      }
    }
  }, [currentlyFocused]);

  const pasteWorkflow = (workflow: any) => {
    onWorkflowSelected(workflow);
    setShowWorkflowsList(false);
    setSearchText('');
  };

  const performActionForIndex = (index) => {
    if (!filteredWorkflowListRef.current) {
      return;
    }

    const workflow = filteredWorkflowListRef.current[index];
    if (!workflow) {
      return;
    }

    if (workflow.create) {
      modalStore?.closeModal();
      navigate(
        `/projects/${projectStore?.currentProject?.id}/bots`,
      );
      return;
    }

    pasteWorkflow(workflow);
  };

  const handleUserKeyPress = useCallback((event: any) => {
    const { key } = event;

    if ((event.metaKey || event.ctrlKey) && key === 'y') {
      setShowWorkflowsList(!showWorkflowsListRef.current);
      event.preventDefault();
      return;
    }

    if ((event.metaKey || event.ctrlKey) && event.shiftKey && key === 'y') {
      setShowWorkflowsList(!showWorkflowsListRef.current);
      event.preventDefault();
      return;
    }

    if (!showWorkflowsListRef.current) {
      return;
    }

    if (event.key === 'Escape') {
      setSearchText('');
      setShowWorkflowsList(false);
      event.preventDefault();
      return;
    }

    if (event.keyCode === 13) {
      if (showWorkflowsListRef.current) {
        performActionForIndex(currentlyFocusedRef.current);
        event.preventDefault();
        return;
      }
    }

    if (event.keyCode === 40) {
      var newIndex = currentlyFocusedRef.current + 1;
      if (newIndex > filteredWorkflowList.length - 1) {
        newIndex = 0;
      }

      setCurrentlyFocused(newIndex);
      event.preventDefault();
      return;
    }

    if (event.keyCode === 38) {
      var newIndex = currentlyFocusedRef.current - 1;
      if (newIndex < 0) {
        newIndex = filteredWorkflowList.length - 1;
      }

      setCurrentlyFocused(newIndex);
      event.preventDefault();
      return;
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  return (
    <div className="workflow-container" ref={wrapperRef}>
      {showWorkflowsList && (
        <div className="workflow-list">
          <div className="workflow-list-title">
            <input
              className="workflow-list-title-input"
              type="text"
              autoFocus
              placeholder="Search for workflows..."
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                setCurrentlyFocused(0);
              }}
            />
          </div>
          <div className="workflow-list-list" ref={scrollContainerRef}>
            {
              filteredWorkflowList.map((workflow, index) => {
                if (!workflow) {
                  return null;
                }

                return (
                  <div
                    className={`workflow-list-item ${index === currentlyFocused && 'focused'
                      }`}
                    key={index}
                    onMouseEnter={() => {
                      setCurrentlyFocused(index);
                    }}
                    onClick={() => {
                      performActionForIndex(index);
                    }}
                  >
                    <div className="workflow-list-item-shortcut">
                      {workflow?.create ? (
                        <i className="fa-regular fa-plus"></i>
                      ) : (
                        <i className="fa-solid fa-bolt"></i>
                      )}
                    </div>
                    <div className="workflow-list-item-shortcut-content">
                      {workflow?.create ? 'Create workflows' : workflow?.name}
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="workflow-list-footer">
            <div className="hotkeys-list">
              <div className="hotkey-key">
                <i className="fa-regular fa-arrow-up"></i>
              </div>
              <div className="hotkey-key">
                <i className="fa-regular fa-arrow-down"></i>
              </div>
              <div className="hotkeys-list-label">to navigate</div>
            </div>
            <div className="hotkeys-list">
              <div className="hotkey-key">
                <i className="fa-regular fa-turn-down-left"></i>
              </div>
              <div className="hotkeys-list-label">to select</div>
            </div>
            <div className="hotkeys-list">
              <div className="hotkey-key">ESC</div>
              <div className="hotkeys-list-label">to close</div>
            </div>
          </div>
        </div>
      )}
      {!showWorkflowsList && (
        <ReactTooltip
          id="startWorkflowButtonTooltip"
          className="infoTooltip infoTooltipButton"
          delayHide={0}
          type="light"
          effect="solid"
          getContent={(content) => {
            return (
              <div className="send-key-tooltip">
                <span>{content}</span>
                <div className="hotkey-help">
                  {isMacintosh() ? <div>⌘</div> : <div>Ctrl</div>}
                  <div>Y
                  </div>
                </div>
              </div>
            );
          }}
        />
      )}
      <div
        data-for="startWorkflowButtonTooltip"
        data-tip="Start a workflow"
        className={`workflow-container-item ${showWorkflowsList && 'workflow-container-item--active'
          }`}
        onClick={() => {
          setShowWorkflowsList(!showWorkflowsList);
        }}
      >
        <i className="fa-solid fa-bolt" />
      </div>
    </div>
  );
};

export default inject(
  'projectStore',
  'modalStore'
)(observer(SendInputWorflowAction));
