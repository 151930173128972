import classNames from 'classnames';
import Loading from 'components/Loading/Loading';
import { useEffect, useState } from 'react';
import Select, { ActionMeta } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import './SelectDropDown.scss';
import { SelectComponents } from 'react-select/dist/declarations/src/components';
import { getProperty } from 'helper/AssignObjectKeysHelper';

export interface SelectDropDownProps {
  items: any[];
  onChange: (value: any, actionMeta?: ActionMeta<any>) => void;
  selectedItem?: any;
  isMulti?: boolean;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  labelPropertyName?: string;
  valuePropertyName?: string;
  className?: any;
  isSearchable?: boolean;
  isLoading?: boolean;
  isEditingForced?: boolean;
  showBorder?: boolean;
  getOptionlabel?: (option: any) => string;
  getOptionValue?: (option: any) => string;
  createable?: boolean;
  grouped?: boolean;
  components?: Partial<SelectComponents<any, boolean, any>> | undefined;
  menuPosition?: 'fixed' | 'absolute';
  isClearable?: boolean;
}

const SelectDropDown = ({
  items,
  onChange,
  selectedItem,
  isMulti = false,
  label,
  isSearchable = true,
  placeholder = 'Select',
  disabled = false,
  labelPropertyName = 'label',
  valuePropertyName = 'value',
  className,
  isLoading = false,
  getOptionlabel = (option) => getProperty(option, labelPropertyName),
  getOptionValue = (option) => getProperty(option, valuePropertyName),
  createable = false,
  grouped = false,
  components,
  menuPosition,
  isClearable,
}: SelectDropDownProps) => {
  const [initialized, setInitialized] = useState(false);
  const [selectedValue, setSelectedValue] = useState();

  const selectClass = classNames(
    {
      'select-container': true,
    },
    className,
  );

  useEffect(() => {
    if (!items || grouped) {
      return;
    }

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if ('options' in item) {
        delete item.options;
      }
    }
  }, [items, grouped]);

  useEffect(() => {
    if (
      selectedItem &&
      selectedItem !== null &&
      selectedItem !== '' &&
      !initialized
    ) {
      setInitialized(true);

      onChange(selectedItem);
    }

    if (selectedItem) {
      const item = items.find((item) => item?.value === selectedItem);
      if (item) {
        setSelectedValue(item);
      }
    }
  }, [selectedItem]);

  if (isLoading) {
    return (
      <div className="loading-container">
        {label && <div className="select-label mb-5">{label}</div>}
        <Loading className="loading-align mb-15" />
      </div>
    );
  }

  if (createable) {
    return (
      <div className={selectClass}>
        {label && <div className="select-label">{label}</div>}
        <CreatableSelect
          isLoading={isLoading}
          getOptionLabel={getOptionlabel}
          getOptionValue={getOptionValue}
          className="select"
          classNamePrefix="dropdown-selection"
          value={selectedValue ?? selectedItem}
          onChange={(val) => {
            setSelectedValue(val);
            onChange(val);
          }}
          options={items}
          isMulti={isMulti}
          placeholder={placeholder}
          isDisabled={disabled}
        />
      </div>
    );
  }

  return (
    <div className={selectClass}>
      {label && <div className="select-label">{label}</div>}
      <Select
        isLoading={isLoading}
        isSearchable={isSearchable}
        getOptionLabel={getOptionlabel}
        getOptionValue={getOptionValue}
        components={components}
        className="select"
        classNamePrefix="dropdown-selection"
        value={selectedValue ?? selectedItem}
        onChange={(val) => {
          setSelectedValue(val);
          onChange(val);
        }}
        options={items}
        isMulti={isMulti}
        placeholder={placeholder}
        isDisabled={disabled}
        menuPosition={menuPosition}
        isClearable={isClearable}
      />
    </div>
  );
};

export default SelectDropDown;
