import ChargeList from "components/ChargesList/ChargesList";
import SubscriptionList from "components/SubscriptionList/SubscriptionList";
import TabButton from "components/TabButton/TabButton";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import './StripeDetails.scss';
import LinkButton from "components/LinkButton/LinkButton";
import LoadingAnimationSmall from "components/LoadingAnimationSmall/LoadingAnimationSmall";
import { searchStripeSession } from "services/ProjectHttpService";
import { ProjectStore } from "stores/private/ProjectStore";

const StripeDetails = ({ session, projectStore }: {
    session?: any;
    projectStore?: ProjectStore;
}) => {
    const [customerData, setCustomerData] = useState(null as any);
    const [customerDataLoading, setCustomerDataLoading] = useState(true);
    const [tab, setTab] = useState('charges');

    const loadCustomerData = async (session) => {
        setCustomerData(null);

        try {
            const response = await searchStripeSession({
                sessionId: session?.id,
                projectId: projectStore?.currentProject?.id ?? "",
            });

            setCustomerDataLoading(false);
            if (response.status === 200) {
                setCustomerData(response.data);
            }
        } catch (err: any) {
            setCustomerDataLoading(false);
        }
    }

    useEffect(() => {
        if (session) {
            loadCustomerData(session);
        } else {
            setCustomerData(null);
        }
    }, [session]);

    if (customerDataLoading) {
        return (<div className="stripe-details">
            <LoadingAnimationSmall />
        </div>);
    }

    if (!customerData?.customer) {
        return (
            <div className="stripe-details">
                <div className="stripe-details-profile">
                    <div className='text'>
                        <i className="fa-brands fa-cc-stripe" /> <b>No customer found</b><br />
                        We couldn't find a Stripe customer for this contact.
                    </div>
                </div>
                <LinkButton
                    className="bfw mt-15"
                    icon="arrow-up-right"
                    label="Create in Stripe"
                    iconSideRight={true}
                    onClick={() => {
                        window.open(`https://dashboard.stripe.com/customers`, "_blank");
                    }}
                />
            </div>
        );
    }

    const renderPage = () => {
        switch (tab) {
            case 'charges':
                return <ChargeList customer={customerData?.customer} charges={customerData?.charges} />;
            case 'subscriptions':
                return <SubscriptionList customer={customerData?.customer} subscriptions={customerData?.subscriptions} />;
            case 'details':
                return <>
                    {customerData?.customer.address ? (
                        <div className='text mt-20'>
                            <b>{customerData?.customer.name}</b><br />
                            {customerData?.customer.address.line1}<br />
                            {customerData?.customer.address.line2 && <>{customerData?.customer.address.line2}<br /></>}
                            {customerData?.customer.address.postal_code} {customerData?.customer.address.city}<br />
                            {customerData?.customer.address.state}, {customerData?.customer.address.country}
                        </div>
                    ) : <div className='text mt-20'>No address set</div>}
                    <LinkButton
                        className="bfw mt-15"
                        icon="arrow-up-right"
                        label="Open in Stripe"
                        iconSideRight={true}
                        onClick={() => {
                            window.open(`https://dashboard.stripe.com/customers/${customerData?.customer.id}`, "_blank");
                        }}
                    />
                </>;
        }
    }

    return <div className="stripe-details">
        <div className="stripe-details-profile">
            <div className='text'>
                <i className="fa-brands fa-cc-stripe" /> <b>{customerData?.customer.name}</b><br />
                {customerData?.customer.email}
            </div>
            <LinkButton
                className="rounded-icon-button"
                icon="arrow-up-right"
                iconSideRight={true}
                onClick={() => {
                    window.open(`https://dashboard.stripe.com/customers/${customerData?.customer.id}`, "_blank");
                }}
            />
        </div>
        <div className="tab-buttons mt-20">
            <div className="tab-buttons--inner">
                <TabButton
                    active={tab === 'charges'}
                    label="Charges"
                    onClick={() => {
                        setTab('charges');
                    }}
                />
                <TabButton
                    active={tab === 'subscriptions'}
                    label="Subscriptions"
                    onClick={() => {
                        setTab('subscriptions');
                    }}
                />
                <TabButton
                    active={tab === 'details'}
                    label="Address"
                    onClick={() => {
                        setTab('details');
                    }}
                />
            </div>
        </div>
        {renderPage()}
    </div>
};

export default inject(
    'projectStore',
)(observer(StripeDetails));
