import React, { useEffect, useState } from 'react';
import { ProjectStore } from 'stores/private/ProjectStore';
import { inject, observer } from 'mobx-react';
import Select from 'react-select';
import { truncateString } from 'components/FeedbackUserFilter/FeedbackUserFilter';

interface FeedbackTeamFilterProps {
  projectStore?: ProjectStore;
  value?: any;
  placeholder?: string;
  truncatePreview?: number;
  truncate?: number;
  onValueChanged?: (value?: string) => void;
  hasBorder?: boolean;
}

const FeedbackTeamFilter = ({
  projectStore,
  value,
  onValueChanged,
  placeholder = 'Filter by assignee',
  truncatePreview = 5,
  truncate = 13,
  hasBorder = false,
}: FeedbackTeamFilterProps) => {
  const [selectedTeam, setSelectedTeam] = useState(undefined as any);

  useEffect(() => {
    if (value && projectStore?.currentProjectTeams) {
      // Find the team in the list of teams
      const team = projectStore?.currentProjectTeams?.find(
        (team) => team._id === value,
      );

      setSelectedTeam(team);
    } else {
      setSelectedTeam(undefined);
    }
  }, [value, projectStore?.currentProjectTeams]);

  if (!projectStore?.currentProjectTeams) {
    return <></>;
  }

  const filterOptions = (candidate, input) => {
    if (input) {
      if (candidate.label.toLowerCase().includes(input.toLowerCase()))
        return true;

      return false;
    }
    return true;
  };

  return (
    <div className="user-filter">
      <Select
        className={`dropdown-selection ${
          hasBorder && 'dropdown-selection--border'
        }`}
        classNamePrefix="dropdown-selection"
        value={selectedTeam}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option._id}
        placeholder={placeholder}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        isClearable
        filterOption={filterOptions}
        components={{
          SingleValue: (option: any) => {
            return <>{truncateString(option.data.name, 13)}</>;
          },
          Option: (option: any) => {
            return (
              <div
                className="option-list-item user-option-list-item"
                onClick={() => {
                  if (option.selectOption) {
                    option.selectOption(option.data);
                  }
                }}
              >
                {option.data.name}
              </div>
            );
          },
        }}
        onChange={(selectedProcessingTeam: any) => {
          let valToUpdate;
          if (selectedProcessingTeam && selectedProcessingTeam._id) {
            valToUpdate = selectedProcessingTeam._id;
          }

          if (onValueChanged) {
            onValueChanged(valToUpdate);
          }
        }}
        options={projectStore?.currentProjectTeams}
      />
    </div>
  );
};

export default inject('projectStore')(observer(FeedbackTeamFilter));
