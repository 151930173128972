import BoardSearchFilter from 'components/BoardSearchFilter/BoardSearchFilter';
import DropDownButton from 'components/DropDownButton/DropDownButton';
import DropDownShowItems from 'components/DropDownShowItems/DropDownShowItems';
import Filter from 'components/Filter/Filter';
import InfoBox from 'components/InfoBox/InfoBox';
import LinkButton from 'components/LinkButton/LinkButton';
import ListTable, {
  CellLink,
  CellSession,
  CellText,
  CellTextIcon,
} from 'components/ListTable/ListTable';
import Loading from 'components/Loading/Loading';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SimpleSidenavElement from 'components/Sidenav/SimpleSidenavElement/SimpleSidenavElement';
import SidenavContainer from 'components/SidenavContainer/SidenavContainer';
import SubSidenav from 'components/SubSidenav/SubSidenav';
import { debounce } from 'lodash';
import { inject, observer } from 'mobx-react';
import { sessionSchemaFields, unsubscribeSchemaFields } from 'models/Session';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import { HttpSessionService } from 'services/http.clients/http.session.client';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { SessionStore } from 'stores/private/SessionStore';
import './ProjectContacts.scss';
import { toast } from 'react-toastify';
import { PropertyStore } from 'stores/private/PropertyStore';
import OrganisationList from '../OrganisationList/OrganisationList';

const contactFilterStates = [
  { name: 'All', icon: 'user-group', value: 'all' },
  { name: 'Users', icon: 'user', value: 'users' },
  { name: 'Guests', icon: 'user-secret', value: 'guests' },
  { name: 'Unsubscribed', icon: 'ban', value: 'unsubscribed' },
  { name: 'Organisations', icon: 'building', value: 'organisations' },
  { name: 'Custom lists', icon: '', value: 'custom-lists' },
];

interface ProjectContactsProps {
  projectStore?: ProjectStore;
  sessionStore?: SessionStore;
  modalStore?: ModalStore;
  propertyStore?: PropertyStore;
}

const ProjectContacts = ({
  projectStore,
  sessionStore,
  modalStore,
  propertyStore,
}: ProjectContactsProps) => {
  const { projectId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const organisationName = searchParams.get('companyName');
  const organisationId = searchParams.get('companyId');
  const contactViewName = searchParams.get('contactViewName');
  const contactViewId = searchParams.get('contactViewId');

  const sessions = sessionStore!.sessionsDataList.data;

  const [showUserId, setShowUserId] = useState(true);
  const [showEmail, setShowEmail] = useState(true);
  const [showLastActivity, setShowLastActivity] = useState(true);

  const [showCompanyName, setShowCompanyName] = useState(false);
  const [showCompanyId, setShowCompanyId] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const [showLang, setShowLang] = useState(false);
  const [showValue, setShowValue] = useState(false);
  const [showPlan, setShowPlan] = useState(false);
  const [showLastPageView, setShowLastPageView] = useState(false);

  const [fullTextFilter, setFullTextFilter] = useState('');
  const [filter, setFilter] = useState({});
  const [type, setType] = useState(null as any);
  const [onlyWithFeedback, setWithFeedback] = useState('false');

  const localStorageKey = `project-${projectId}-selectedRowItems`;

  const sessionDataAttributes =
    propertyStore?.getProjectPropertiesForType('SESSION') ?? [];

  const createNestedStructure = (attributes) => {
    const root: any = [];

    attributes.forEach((attr) => {
      const parts = attr.path ? attr.path.split('.') : [];
      let currentLevel = root;

      parts.forEach((part, index) => {
        let existing = currentLevel.find((item) => item.key === part);
        if (!existing) {
          existing = { name: part, key: part, children: [] };
          currentLevel.push(existing);
        }
        if (index === parts.length - 1) {
          const sessionProp = {
            name: attr.label,
            key: attr.fieldId,
            required: attr.required ?? false,
          };
          existing.children.push(sessionProp);
        } else {
          currentLevel = existing.children;
        }
      });

      if (parts.length === 0) {
        const sessionProp = {
          name: attr.label,
          key: attr.fieldId,
          required: attr.required ?? false,
        };
        root.push(sessionProp);
      }
    });

    return root;
  };

  const preparedSessionDataAttributes = createNestedStructure(
    sessionDataAttributes,
  );

  const dynamicSessionSchemaFields = [
    ...preparedSessionDataAttributes,
    ...sessionSchemaFields,
  ];

  useEffect(() => {
    if (organisationId) {
      if (!type || type !== 'organisations') {
        setType('organisations');
      }
      setFilter({ companyId: organisationId });
    } else {
      setFilter({});
    }
  }, [organisationId]);

  useEffect(() => {
    if (contactViewId && !filter['contactViewId']) {
      setFilter({ contactViewId });
    }
  }, [contactViewId]);

  useEffect(() => {
    projectStore!.loadProjectById(projectId!);

    const savedSelectedColumns = localStorage.getItem(localStorageKey);
    if (savedSelectedColumns) {
      const selectedColumns = JSON.parse(savedSelectedColumns);
      setShowUserId(selectedColumns.showUserId ?? true);
      setShowEmail(selectedColumns.showEmail ?? true);
      setShowLastActivity(selectedColumns.showLastActivity ?? true);
      setShowCompanyName(selectedColumns.showCompanyName ?? false);
      setShowCompanyId(selectedColumns.showCompanyId ?? false);
      setShowLocation(selectedColumns.showLocation ?? false);
      setShowPhone(selectedColumns.showPhone ?? false);
      setShowLang(selectedColumns.showLang ?? false);
      setShowValue(selectedColumns.showValue ?? false);
      setShowPlan(selectedColumns.showPlan ?? false);
      setShowLastPageView(selectedColumns.showLastPageView ?? false);
    }
  }, []);

  const columns = useMemo(
    () =>
      [
        {
          Header: 'Name',
          width: '25%',
          accessor: (row) => row,
          Cell: (row) => (
            <CellSession
              session={row.value}
              url={`/projects/${projectId}/sessions/${
                row.value.id || row?.cell?.row?.values?.Name?._id
              }`}
            />
          ),
        },
        showCompanyName && {
          className: 'hide-on-mobile right-align',
          width: '15%',
          Header: 'Company name',
          accessor: 'companyName',
          Cell: (row) => <CellText text={row.value} />,
        },
        showCompanyId && {
          className: 'hide-on-mobile',
          width: '15%',
          Header: 'Company id',
          accessor: 'companyId',
          Cell: (row) => <CellText text={row.value} />,
        },
        showUserId && {
          className: 'hide-on-mobile',
          width: '25%',
          Header: 'User id',
          accessor: 'userId',
          Cell: (row) => {
            if (row.value && row.value.length > 0) {
              return <CellTextIcon icon="user" text={row.value} />;
            }

            return null;
          },
        },
        showEmail && {
          className: 'hide-on-mobile',
          width: '20%',
          Header: 'Email',
          accessor: 'email',
          Cell: (row) => (
            <CellLink link={`mailto:${row.value}`} text={row.value} />
          ),
        },
        showPhone && {
          className: 'hide-on-mobile',
          width: '15%',
          Header: 'Phone',
          accessor: 'phone',
          Cell: (row) => (
            <CellLink link={`tel:${row.value}`} text={row.value} />
          ),
        },
        showLang && {
          className: 'hide-on-mobile',
          width: '8%',
          Header: 'Language',
          accessor: 'lang',
          Cell: (row) => <CellText text={row.value} />,
        },
        showLocation && {
          className: 'hide-on-mobile',
          width: '7%',
          Header: 'Location',
          accessor: 'location',
          Cell: (row) => (
            <CellText
              text={row.value && row.value.country ? row.value.country : ''}
            />
          ),
        },
        showValue && {
          className: 'hide-on-mobile',
          width: '8%',
          Header: 'Value',
          accessor: 'value',
          Cell: (row) => <CellText text={row.value} />,
        },
        showPlan && {
          className: 'hide-on-mobile',
          width: '10%',
          Header: 'Plan',
          accessor: 'plan',
          Cell: (row) => <CellText text={row.value} />,
        },
        showLastPageView && {
          className: 'hide-on-mobile',
          width: '15%',
          Header: 'Last page view',
          accessor: 'lastPageView',
          Cell: (row) => (
            <CellText
              text={row.value && row.value.page ? row.value.page : ''}
            />
          ),
        },
        showLastActivity && {
          className: 'hide-on-mobile',
          width: '15%',
          Header: 'Last activity',
          accessor: 'lastActivity',
          Cell: (row) => <TimeAgo date={row.value} />,
        },
      ].filter(Boolean),
    [
      showUserId,
      showEmail,
      showCompanyName,
      showCompanyId,
      showLang,
      showLocation,
      showPhone,
      showValue,
      showPlan,
      showLastPageView,
      showLastActivity,
      projectId,
    ],
  );

  useEffect(() => {
    projectStore!.loadProjectById(projectId!);
  }, []);

  const debounceSearch = useCallback(
    debounce(
      (_type, _withFeedback, _fullTextFilter) =>
        sessionStore!.searchForSessionItems({
          type: _type,
          withFeedback: _withFeedback,
          searchTerm: _fullTextFilter,
        }),
      1000,
    ),
    [],
  );

  useEffect(() => {
    if (projectStore?.currentProject && type && onlyWithFeedback) {
      debounceSearch(type, onlyWithFeedback, fullTextFilter);
    }
  }, [fullTextFilter]);

  useEffect(() => {
    if (projectStore?.currentProject) {
      projectStore.findAllContactViews();
    }
  }, [projectStore?.currentProject]);

  useEffect(() => {
    sessionStore!.sessionsDataList.pageIndex = 0;

    if (projectStore?.currentProject && type) {
      sessionStore!.getSessionItems({
        filter,
        type,
        withFeedback: onlyWithFeedback === 'true',
      });
    }
  }, [projectStore?.currentProject, type, onlyWithFeedback, filter]);

  useEffect(() => {
    let contactsType: any;
    let contactsWithFeedback: any;
    try {
      contactsType = localStorage.getItem('contactsType');
      contactsWithFeedback = localStorage.getItem('contactsWithFeedback');
      // eslint-disable-next-line no-empty
    } catch (exp) {}

    if (organisationId) {
      setType('organisations');
    } else if (contactViewId) {
      setType('custom-lists');
    } else if (contactsType) {
      setType(contactsType);
    } else {
      setType('all');
    }
    if (contactsWithFeedback) {
      setWithFeedback(contactsWithFeedback);
    } else {
      setWithFeedback('false');
    }
  }, []);

  const unsubscribeContacts = async (data) => {
    try {
      await HttpSessionService.getInstance().unsubscribeSessions({
        sessions: data,
      });

      toast('Started unsubscribing contacts. This might take a few minutes.');
    } catch (exp) {
      console.log(exp);
    }
  };

  const importSessions = async (data) => {
    try {
      // Move customData props to root level
      data = data.map((session) => {
        if (!session.customData) return session;

        const customData = session.customData;
        delete session.customData;
        return { ...session, ...customData };
      });

      await HttpSessionService.getInstance().importSessions({ sessions: data });
      sessionStore!.getSessionItems({
        type,
        withFeedback: onlyWithFeedback === 'true',
      });
    } catch (err: any) {
      return { errors: err?.response?.data };
    }
  };

  const renderMainContent = () => {
    const handleColumnToggle = (columnName, newValue) => {
      const updatedState = {
        ...JSON.parse(localStorage.getItem(localStorageKey) || '{}'),
        [columnName]: newValue,
      };
      localStorage.setItem(localStorageKey, JSON.stringify(updatedState));
    };

    return (
      <>
        <InfoBox className="mb-30">
          <>
            Contacts can either be identified users or un-identified guests.{' '}
            <a href="https://docs.gleap.io/javascript/user-identity">
              Learn how to identify contacts
            </a>
          </>
        </InfoBox>
        <div className="mb-20">
          <DropDownShowItems
            label="Display columns"
            items={[
              {
                label: 'User id',
                icon: 'user',
                iconType: 'solid',
                onClick: () => {
                  const newValue = !showUserId;
                  handleColumnToggle('showUserId', newValue);
                  setShowUserId(newValue);
                },
                active: showUserId,
              },
              {
                label: 'Email',
                icon: 'envelope',
                iconType: 'solid',
                onClick: () => {
                  const newValue = !showEmail;
                  handleColumnToggle('showEmail', newValue);
                  setShowEmail(newValue);
                },
                active: showEmail,
              },
              {
                label: 'Company name',
                icon: 'building',
                iconType: 'solid',
                onClick: () => {
                  const newValue = !showCompanyName;
                  handleColumnToggle('showCompanyName', newValue);
                  setShowCompanyName(newValue);
                },
                active: showCompanyName,
              },
              {
                label: 'Company id',
                icon: 'building',
                iconType: 'solid',
                onClick: () => {
                  const newValue = !showCompanyId;
                  handleColumnToggle('showCompanyId', newValue);
                  setShowCompanyId(newValue);
                },
                active: showCompanyId,
              },
              {
                label: 'Phone',
                icon: 'phone',
                iconType: 'solid',
                onClick: () => {
                  const newValue = !showPhone;
                  handleColumnToggle('showPhone', newValue);
                  setShowPhone(newValue);
                },
                active: showPhone,
              },
              {
                label: 'Language',
                icon: 'language',
                iconType: 'solid',
                onClick: () => {
                  const newValue = !showLang;
                  handleColumnToggle('showLang', newValue);
                  setShowLang(newValue);
                },
                active: showLang,
              },
              {
                label: 'Location',
                icon: 'map-marker-alt',
                iconType: 'solid',
                onClick: () => {
                  const newValue = !showLocation;
                  handleColumnToggle('showLocation', newValue);
                  setShowLocation(newValue);
                },
                active: showLocation,
              },
              {
                label: 'Value',
                icon: 'dollar-sign',
                iconType: 'solid',
                onClick: () => {
                  const newValue = !showValue;
                  handleColumnToggle('showValue', newValue);
                  setShowValue(newValue);
                },
                active: showValue,
              },
              {
                label: 'Plan',
                icon: 'credit-card',
                iconType: 'solid',
                onClick: () => {
                  const newValue = !showPlan;
                  handleColumnToggle('showPlan', newValue);
                  setShowPlan(newValue);
                },
                active: showPlan,
              },
              {
                label: 'Last page view',
                icon: 'eye',
                iconType: 'solid',
                onClick: () => {
                  const newValue = !showLastPageView;
                  handleColumnToggle('showLastPageView', newValue);
                  setShowLastPageView(newValue);
                },
                active: showLastPageView,
              },
              {
                label: 'Show last activity',
                icon: 'clock',
                iconType: 'solid',
                onClick: () => {
                  const newValue = !showLastActivity;
                  handleColumnToggle('showLastActivity', newValue);
                  setShowLastActivity(newValue);
                },
                active: showLastActivity,
              },
            ]}
          />
        </div>
        <ListTable data={sessions} columns={columns} />
        {sessionStore?.sessionsDataList.isLoading &&
          sessionStore?.sessionsDataList.data.length === 0 && (
            <div className="mt-20">
              <Loading />
            </div>
          )}
        {sessionStore?.sessionsDataList &&
          sessionStore?.sessionsDataList.data.length > 0 &&
          !sessionStore?.sessionsDataList.isLoading && (
            <div className="mt-20">
              {sessionStore?.sessionsDataList?.hasMore ? (
                <LinkButton
                  isLoading={sessionStore?.sessionsDataList?.isLoading}
                  label="Load more"
                  onClick={() => {
                    sessionStore!.getSessionItems({
                      filter,
                      type,
                      withFeedback: onlyWithFeedback === 'true',
                      loadMore: true,
                    });
                  }}
                />
              ) : (
                <div className="text">No more contacts to load</div>
              )}
            </div>
          )}
      </>
    );
  };

  const render = () => {
    const activeType =
      contactFilterStates.find((state) => state.value === type) ??
      contactFilterStates[0];

    if (
      sessionStore?.sessionsDataList.data.length === 0 &&
      sessionStore?.sessionsDataList.isLoading
    ) {
      return (
        <PageContainer>
          <PageHeadLine title={activeType.name} />
          <PageContent isCentered hasTitle>
            <Loading />
          </PageContent>
        </PageContainer>
      );
    }

    if (activeType.name === 'Organisations') {
      return (
        <PageContainer>
          <PageHeadLine
            {...(filter && filter['companyId']
              ? {
                  onActionBack: () => {
                    setFilter({});
                    setSearchParams({});
                  },
                }
              : {})}
            title={
              filter && filter['companyId'] && organisationId
                ? organisationName || organisationId
                : activeType.name
            }
          >
            {filter && filter['companyId'] ? (
              <div className="header-content-right">
                <BoardSearchFilter />
                <Filter
                  className="ml-10 hide-on-mobile"
                  onValueChange={(value) => {
                    setWithFeedback(value);
                    try {
                      localStorage.setItem('contactsWithFeedback', value);
                      // eslint-disable-next-line no-empty
                    } catch (exp) {}
                  }}
                  value={onlyWithFeedback}
                  options={[
                    { name: 'All', value: 'false' },
                    { name: 'Only with feedback', value: 'true' },
                  ]}
                />
                <PrimaryButton
                  className="ml-15"
                  label="Add contact"
                  onClick={() => {
                    modalStore?.openModal(MODALTYPE.CREATE_SESSION, {
                      form: {
                        companyId: organisationId,
                        companyName: organisationName,
                      },
                    });
                  }}
                />
              </div>
            ) : (
              <div className="header-content-right">
                <BoardSearchFilter />
              </div>
            )}
          </PageHeadLine>
          <PageContent hasTitle isBoxed>
            {filter && filter['companyId'] ? (
              <ListTable data={sessions} columns={columns} />
            ) : (
              <OrganisationList />
            )}
          </PageContent>
        </PageContainer>
      );
    }

    if (activeType.value === 'custom-lists') {
      return (
        <PageContainer>
          <PageHeadLine title={contactViewName || 'Custom list'}>
            <div className="header-content-right">
              <BoardSearchFilter />
            </div>
          </PageHeadLine>
          <PageContent hasTitle isBoxed>
            {renderMainContent()}
          </PageContent>
        </PageContainer>
      );
    }

    return (
      <PageContainer>
        <PageHeadLine title={activeType.name}>
          <div className="header-content-right">
            <DropDownButton
              label="Unsubscribe"
              items={[
                {
                  label: 'By CSV upload',
                  icon: 'file-csv',
                  iconType: 'solid',
                  onClick: () => {
                    modalStore!.openModal(MODALTYPE.CSV_IMPORTER, {
                      schema: unsubscribeSchemaFields,
                      onUpload: unsubscribeContacts,
                    });
                  },
                },
              ]}
            />
            <DropDownButton
              label="Import"
              items={[
                {
                  label: 'Import from Intercom',
                  icon: 'intercom',
                  iconType: 'brands',
                  onClick: () => {
                    modalStore!.openModal(MODALTYPE.INTERCOM_IMPORTER, {
                      onUpload: async ({ endpoint, apiKey }) => {
                        await HttpSessionService.getInstance().importSessionsFromIntercom(
                          {
                            endpoint,
                            apiKey,
                          },
                        );
                      },
                    });
                  },
                },
                {
                  label: 'Import from CSV',
                  icon: 'file-csv',
                  iconType: 'solid',
                  onClick: () => {
                    modalStore!.openModal(MODALTYPE.CSV_IMPORTER, {
                      schema: dynamicSessionSchemaFields,
                      onUpload: importSessions,
                    });
                  },
                },
              ]}
            />
            <BoardSearchFilter />
            <Filter
              className="ml-10 hide-on-mobile"
              onValueChange={(value) => {
                setWithFeedback(value);
                try {
                  localStorage.setItem('contactsWithFeedback', value);
                  // eslint-disable-next-line no-empty
                } catch (exp) {}
              }}
              value={onlyWithFeedback}
              options={[
                { name: 'All', value: 'false' },
                { name: 'Only with feedback', value: 'true' },
              ]}
            />
            <PrimaryButton
              className="ml-15"
              label="Add contact"
              onClick={() => {
                modalStore?.openModal(MODALTYPE.CREATE_SESSION);
              }}
            />
          </div>
        </PageHeadLine>
        <PageContent hasTitle isBoxed>
          {renderMainContent()}
        </PageContent>
      </PageContainer>
    );
  };

  return (
    <SidenavContainer className="sidenav-container--small">
      <SubSidenav title="Contacts">
        {contactFilterStates.map((state) => {
          if (state.value === 'custom-lists') {
            return <div key={state.value} />;
          }
          return (
            <SimpleSidenavElement
              key={state.value}
              name={state.name}
              onClick={() => {
                setFilter({});
                setSearchParams({});
                setType(state.value);
                try {
                  localStorage.setItem('contactsType', state.value);
                  // eslint-disable-next-line no-empty
                } catch (exp) {}
              }}
              faIcon={state.icon}
              isActive={type === state.value}
            />
          );
        })}
        <div className="custom-lists">
          <p className="title">Custom lists</p>
          <i
            className="fas fa-plus cursor-focus"
            onClick={() => {
              modalStore?.openModal(MODALTYPE.CONTACT_VIEW_EDIT);
            }}
          />
        </div>
        <div className="mt-5">
          {Array.isArray(projectStore?.contactViews) &&
            projectStore?.contactViews.map((view) => (
              <div
                onClick={() => {
                  setSearchParams({
                    contactViewName: view.name,
                    contactViewId: view.id,
                  });
                  setFilter({ contactViewId: view.id });
                  setType('custom-lists');
                }}
                key={view.id}
                className={`contact-view-item ${
                  contactViewId && contactViewId === view.id
                    ? 'contact-view-item-active'
                    : ''
                }`}
              >
                <p className="name">{view.name}</p>
                <p className="session-count">{view.count}</p>
                <i
                  className="fas fa-pen cursor-focus"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    modalStore?.openModal(MODALTYPE.CONTACT_VIEW_EDIT, view);
                  }}
                />
              </div>
            ))}
        </div>
      </SubSidenav>
      <div className="project-contacts">{render()}</div>
    </SidenavContainer>
  );
};

export default inject(
  'projectStore',
  'sessionStore',
  'modalStore',
  'propertyStore',
)(observer(ProjectContacts));
