/* eslint-disable spaced-comment */
import IntegrationsImage from 'assets/GleapIntegrations.png';
import { HeadLine } from 'components/HeadLine/HeadLine';
import IntegrationCard from 'components/IntegrationCard/IntegrationCard';
import NotInstalledCard from 'components/NotInstalledCard/NotInstalledCard';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import { Plantypes } from 'components/PriceTable/PriceTable';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SuggestSubscription from 'components/SuggestSubscription/SuggestSubscription';
import { inject, observer } from 'mobx-react';
import { Feature } from 'models/Enums';
import { integrations } from 'models/Integration';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ProjectStore } from 'stores/private/ProjectStore';
import 'styles/swal-theme.scss';
import Swal from 'sweetalert2';
import './IntegrationsOverview.scss';

interface IntegrationsOverviewProps {
  projectStore?: ProjectStore;
}

function IntegrationsOverview({ projectStore }: IntegrationsOverviewProps) {
  // @ts-ignore
  const { projectId } = useParams();
  const planIsEntitled = projectStore?.isFeatureInPlan(Feature.INTEGRATIONS, [
    'some',
    'all',
  ]);
  const navigate = useNavigate();
  const [allIntegrations, setAllIntegrations] = useState([] as any[]);
  const projectIntegrations = projectStore?.currentProject?.integrations;

  useEffect(() => {
    if (projectStore!.flowConfig?.jsMajorVersion !== 'v6') {
      return;
    }

    generateIntegrations();
  }, [projectIntegrations]);

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
  }, [projectId]);

  const addNewIntegration = () => {
    if (!projectStore?.isProjectAdmin) {
      Swal.fire({
        text: 'You require admin permissions to create or edit integrations.',
        showCancelButton: false,
        confirmButtonText: `Ok`,
      });
    } else {
      navigate(`/projects/${projectId}/integrations/connect`);
    }
  };

  const generateIntegrations = () => {
    if (!projectIntegrations) {
      return;
    }

    const integrationItems: any[] = [];
    const integrationKeys = Object.keys(projectIntegrations);

    for (let i = 0; i < integrationKeys.length; i++) {
      const integration = projectIntegrations[integrationKeys[i]];
      const innerIntegrationKeys = Object.keys(integration);

      for (let j = 0; j < innerIntegrationKeys.length; j++) {
        const integrationItem = integration[innerIntegrationKeys[j]];

        if (integrationItem && typeof integrationItem === 'object') {
          integrationItem.name = integrationKeys[i];
          integrationItem.id = innerIntegrationKeys[j];
          integrationItems.push(integrationItem);
        }
      }
    }

    setAllIntegrations(integrationItems);
  };

  const validate = (integration) => {
    let currentIntegrationIsValid = true;
    if (!integration.actions) {
      return false;
    }

    for (let i = 0; i < integration.actions.length; i++) {
      const action = integration.actions[i];

      if (
        !action.local ||
        action.local === '' ||
        !action.integration ||
        action.integration === ''
      ) {
        currentIntegrationIsValid = false;
        break;
      }

      const currentIntegration = integrations[integration.name];

      if (!currentIntegration.validate(action.integrationInfo)) {
        currentIntegrationIsValid = false;
        break;
      }
    }

    return currentIntegrationIsValid;
  };

  return (
    <PageContainer>
      <PageHeadLine title="Integrations">
        <div className="header-content-right">
          {planIsEntitled && (
            <PrimaryButton
              label="Add new integration"
              icon="plus"
              iconSideRight={false}
              onClick={() => {
                addNewIntegration();
              }}
            />
          )}
        </div>
      </PageHeadLine>
      <PageContent
        hasTitle
        isMediumBoxed
        isCentered={allIntegrations.length === 0}
      >
        {allIntegrations.length === 0 && (
          <div className="no-integrations-container">
            <img
              src={IntegrationsImage}
              alt="No integrations added yet"
              className="no-integrations-image"
            />
            {planIsEntitled ? (
              <>
                <HeadLine
                  title="No integrations yet"
                  subtitle="Get started by adding your first integration."
                />
                <PrimaryButton
                  className="mt-10"
                  label="Add integration"
                  icon="plus"
                  onClick={() => {
                    addNewIntegration();
                  }}
                />
              </>
            ) : (
              <SuggestSubscription
                plan={Plantypes.GROWTH}
                title="Integrations"
                description="Easily integrate Gleap into your favourite tools. Unlock all integrations with Gleap Pro."
              />
            )}
          </div>
        )}
        {allIntegrations.length > 0 && (
          <div className="integrations-wrap-grid-container">
            {allIntegrations.map((item, index) => {
              const integrationItem = integrations[item.name];
              if (!integrationItem) {
                return <></>;
              }

              return (
                <IntegrationCard
                  key={index}
                  integrationId={item?.settings?.label ?? item.id}
                  title={integrationItem.title}
                  description={integrationItem.description}
                  image={integrationItem.icon}
                  onClick={() => {
                    if (!projectStore?.isProjectAdmin) {
                      Swal.fire({
                        text: 'You require admin permissions to create or edit integrations.',
                        showCancelButton: false,
                        confirmButtonText: `Ok`,
                      });
                    } else {
                      navigate(
                        `/projects/${projectId}/integrations/${item.name}/${item.id}`,
                      );
                    }
                  }}
                  entitled={projectStore?.isIntegrationInPlan(item.name)}
                  connect={!validate(item)}
                />
              );
            })}
          </div>
        )}
      </PageContent>
    </PageContainer>
  );
}

export default inject('projectStore')(observer(IntegrationsOverview));
