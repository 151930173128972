export interface LocalizedString {
  localized: {
    [key: string]: string;
  };
}

export enum ActionTypes {
  MESSAGE = 'message',
  BUTTONS = 'buttons',
  CONDITION = 'condition',
  INPUT = 'input',
  ACTIONFLOW = 'actionflow',
  RATECONVERSATION = 'rateconversation',
  LINK = 'link',
  BOTFLOW = 'botflow',
  CREATE_TICKET = 'createticket',
  ANSWERBOTFLOW = 'answerbotflow',
  FEEDBACKFLOW = 'feedbackflow',
  TICKETUPDATE_ASSIGN = 'ticketupdate-assign',
  TICKETUPDATE_TAG = 'ticketupdate-tag',
  TICKETUPDATE_AITAG = 'ticketupdate-aitag',
  TICKETUPDATE_DATA = 'ticketupdate-data',
  ADDNOTE = 'addnote',
  TICKETUPDATE_CLOSE = 'ticketupdate-close',
  TICKETUPDATE_STATUS = 'ticketupdate-status',
}

export interface ConditionChild {
  type: ActionTypes.ACTIONFLOW;
  actionFlow: string;
  action: number;
  predicates: PredicateChild;
}

export interface PredicateChild {
  type: 'or' | 'and' | 'date' | 'number';
  predicates?: PredicateChild[];
  dataType?: 'attribute' | 'office-hours' | 'ticket';
  attribute?: string;
  operator?: 'gt' | 'lt' | 'eq' | 'neq' | 'gte' | 'lte' | 'is';
  value?: string;
}

export interface ActionBot {
  type: ActionTypes.BOTFLOW;
  bot: string;
  actionFlow: string;
  action: number;
}

export interface ActionAnswerBot {
  type: ActionTypes.ANSWERBOTFLOW;
}

export interface ActionFeedbackFlow {
  type: ActionTypes.FEEDBACKFLOW;
  flow: string;
}

export interface ActionFlow {
  type: ActionTypes.ACTIONFLOW;
  actionFlow: string;
  action: number;
}

export interface Message {
  type: ActionTypes.MESSAGE;
  data: LocalizedString;
}

export interface ButtonAction {
  text: LocalizedString;
  action: Action;
}

export interface Condition {
  type: ActionTypes.CONDITION;
  conditions: ConditionChild[];
  fallbackAction: {
    type: ActionTypes.ACTIONFLOW;
    actionFlow: string;
    action: number;
  };
}

export interface Button {
  type: ActionTypes.BUTTONS;
  buttons: ButtonAction[];
  action: ActionFlow;
}

export interface OptionValue {
  value: string;
  label: LocalizedString;
}

export interface Input {
  type: ActionTypes.INPUT;
  inputtype: 'text' | 'email' | 'number' | 'dropdown';
  title: LocalizedString;
  placeholder: LocalizedString;
  attribute: string;
  attributeType: 'user' | 'ticket';
  minvalue?: number;
  maxvalue?: number;
  options?: OptionValue[];
}

export type Action =
  | Message
  | Button
  | Input
  | ActionFlow
  | Condition
  | ActionBot
  | ActionAnswerBot
  | ActionFeedbackFlow
  | any;

export interface ActionFlowNode {
  id: string;
  type: 'start' | 'action';
  nodeType: string;
  actions: Action[];
  position: { x: number; y: number };
}
