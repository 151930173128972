import LinkButton from 'components/LinkButton/LinkButton';
import { inject, observer } from 'mobx-react';
import { Session } from 'models/Session';
import ReactTooltip from 'react-tooltip';
import { SessionStore } from 'stores/private/SessionStore';
import './SessionMuteButton.scss';
import { toast } from 'react-toastify';

interface SessionMuteButtonProps {
  sessionStore?: SessionStore;
  session?: Session;
}

const SessionMuteButton = ({
  sessionStore,
  session,
}: SessionMuteButtonProps) => {
  return (
    <>
      {!session?.blocked ? (
        <div
          style={{
            width: '100%',
          }}
          data-for="muteTooltip"
          data-tip={
            'Messages from muted contacts get moved to spam automatically.'
          }
        >
          <LinkButton
            className="bfw"
            icon="volume-mute"
            label="Mute contact"
            iconSideRight={false}
            onClick={() => {
              sessionStore?.updateSession(session?.id, {
                blocked: true,
              }, session, true);

              toast.warn("⛔️ Contact muted");
            }}
          />
        </div>
      ) : (
        <LinkButton
          className="bfw"
          icon="volume-up"
          label="Unmute contact"
          iconSideRight={false}
          onClick={() => {
            sessionStore?.updateSession(session?.id, {
              blocked: false,
            }, session, true);

            toast.success("🔊 Contact unmuted");
          }}
        />
      )}
      <ReactTooltip
        id="muteTooltip"
        className="infoTooltip infoTooltipButton"
        delayHide={500}
        type="light"
        effect="solid"
        getContent={(content) => {
          return (
            <div className="send-key-tooltip">
              <span>{content}</span>
            </div>
          );
        }}
      />
    </>
  );
};

export default inject('sessionStore')(observer(SessionMuteButton));
