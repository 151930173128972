import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ReactComponent as HelpCenterIcon } from 'assets/icons/circle-question-solid.svg';
import './PerformHelpcenterAction.scss';
import ReactTooltip from 'react-tooltip';
import { isMacintosh, useClickedOutside } from 'services/Helper';
import { searchForHelpcenterArticles } from 'services/ProjectHttpService';
import { ProjectStore } from 'stores/private/ProjectStore';
import { inject, observer } from 'mobx-react';
import makeUrlFriendly from 'services/UrlHelper';
import { languages } from 'constants/Languages';
import { debounce } from 'lodash';
import LoadingAnimationSmall from 'components/LoadingAnimationSmall/LoadingAnimationSmall';
import LoadingAnimationMedium from 'components/LoadingAnimationMedium/LoadingAnimationMedium';

interface PerformHelpcenterActionProps {
  onArticleSelected: (article: any) => void;
  position?: 'top' | 'bottom';
  projectStore?: ProjectStore;
}

const PerformHelpcenterAction = ({
  onArticleSelected,
  position = 'top',
  projectStore,
}: PerformHelpcenterActionProps) => {
  const scrollContainerRef = useRef(null as any);
  const currentProject = projectStore?.currentProject;
  const helpcenterConfig = projectStore?.flowConfig?.helpcenterConfig;
  const availableLanguages = languages.filter((language) =>
    helpcenterConfig?.localization.find(
      (item) => item.language === language.code,
    ),
  );

  const [selectedLanguage, setSelectedLanguage] = useState(
    availableLanguages[0],
  );

  const debounceSearch = useCallback(
    debounce((nextValue) => searchForArticles(nextValue), 750),
    [selectedLanguage],
  );

  const [initiallySearched, setInitiallySearched] = useState(false);
  const [searchingArticles, setSearchingArticles] = useState(false);
  const [showArticleList, setShowArticleList] = useState(false);
  const [articles, setArticles] = useState([]);
  const [currentlyFocused, setCurrentlyFocused] = useState(0);

  useEffect(() => {
    if (!showArticleList) {
      setInitiallySearched(false);
      setSearchingArticles(false);
      setArticles([]);
      setCurrentlyFocused(0);
    }
  }, [showArticleList]);

  useEffect(() => {
    if (scrollContainerRef && scrollContainerRef.current) {
      const children = scrollContainerRef.current.children;
      if (children && children[currentlyFocused]) {
        children[currentlyFocused].scrollIntoView({ block: 'nearest' });
      }
    }
  }, [currentlyFocused]);

  const showArticleListRef = useRef(showArticleList);
  showArticleListRef.current = showArticleList;

  const articlesRef = useRef(articles);
  articlesRef.current = articles;

  const currentlyFocusedRef = useRef(0);
  currentlyFocusedRef.current = currentlyFocused;

  const wrapperRef = useRef(null);

  useClickedOutside(wrapperRef, () => {
    setShowArticleList(false);
  });

  const searchForArticles = async (searchTerm: string) => {
    setSearchingArticles(true);
    setInitiallySearched(true);

    const response = await searchForHelpcenterArticles({
      projectId: currentProject!.id,
      query: {
        searchTerm,
        lang: selectedLanguage.code,
      },
    });

    const preparedArticles = response.data.hits.map((article) => {
      return article.document;
    });

    setArticles(preparedArticles);
    setSearchingArticles(false);
    setCurrentlyFocused(0);
  };

  const handleArticleSelect = (article: any) => {
    const shareBaseURL = projectStore?.currentProject?.customDomainHelpCenter
      ? `https://${projectStore?.currentProject?.customDomainHelpCenter}`
      : `https://${projectStore?.currentProject?.defaultDomainHelpCenter}.gleap.help`;
    const articleURL = `${shareBaseURL}/${selectedLanguage.code}/articles/${
      article.docId
    }-${makeUrlFriendly(article.title)}`;

    onArticleSelected({ ...article, url: articleURL });
    setShowArticleList(false);
  };

  const handleUserKeyPress = useCallback((event: any) => {
    if (
      (event.metaKey || event.ctrlKey) &&
      event.shiftKey &&
      event.key === 'h'
    ) {
      setShowArticleList(!showArticleListRef.current);
      event.preventDefault();
      return;
    }

    // Don't perform other key bindings if it's not open.
    if (!showArticleListRef.current) {
      return;
    }

    if (event.key === 'Escape') {
      setShowArticleList(false);
      event.preventDefault();
      return;
    }

    if (event.keyCode === 13) {
      if (showArticleListRef.current) {
        handleArticleSelect(articlesRef.current[currentlyFocusedRef.current]);
        event.preventDefault();
        return;
      }
    }

    if (event.keyCode === 40) {
      var newIndex = currentlyFocusedRef.current + 1;
      if (newIndex > articlesRef.current.length - 1) {
        newIndex = 0;
      }

      setCurrentlyFocused(newIndex);
      event.preventDefault();
      return;
    }

    if (event.keyCode === 38) {
      var newIndex = currentlyFocusedRef.current - 1;
      if (newIndex < 0) {
        newIndex = articlesRef.current.length - 1;
      }

      setCurrentlyFocused(newIndex);
      event.preventDefault();
      return;
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  const renderList = () => {
    if (!articles || articles.length === 0) {
      return (
        <div className="helpcenter-list-wrapper helpcenter-list-wrapper--nocontent">
          {initiallySearched && !searchingArticles && (
            <div className="text">No articles found</div>
          )}
          {searchingArticles && <LoadingAnimationMedium />}
          {!initiallySearched && (
            <div className="text">Start typing to search for articles</div>
          )}
        </div>
      );
    }

    return (
      <div className="helpcenter-list-wrapper" ref={scrollContainerRef}>
        {articles.map((article: any, index: number) => {
          return (
            <div
              key={index}
              className={`helpcenter-list-item ${
                index === currentlyFocused && 'focused'
              }`}
              onMouseEnter={() => {
                setCurrentlyFocused(index);
              }}
              onClick={() => {
                handleArticleSelect(article);
              }}
            >
              <div className="helpcenter-list-item-title">{article.title}</div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="helpcenter-action-container" ref={wrapperRef}>
      {showArticleList && (
        <div className={`article-list article-list--${position}`}>
          <ReactTooltip
            id="mauLimitTooltip"
            className="infoTooltip"
            delayHide={300}
            type="light"
            effect="solid"
            getContent={(content) => {
              try {
                const data = JSON.parse(content);
                return (
                  <div className="ai-info-tooltip">
                    <b>{data.title}</b>
                    <br />
                    {data.description}
                  </div>
                );
              } catch (exp) {}
              return null;
            }}
          />
          <div className="article-list-title">
            <input
              className="article-list-title-input"
              type="text"
              autoFocus
              placeholder="Search"
              onChange={(text) => {
                debounceSearch(text.target.value);
              }}
            />
            {availableLanguages?.length > 1 && (
              <div className="language-select">
                <select
                  value={selectedLanguage.code}
                  onChange={(event) => {
                    const selectedValue = event.target.value;
                    const newlySelectedLanguage = availableLanguages.find(
                      (language) => language.code === selectedValue,
                    );

                    setSelectedLanguage(
                      newlySelectedLanguage ?? selectedLanguage,
                    );
                  }}
                >
                  {availableLanguages.map((language) => {
                    return (
                      <option value={language.code}>{language.name}</option>
                    );
                  })}
                </select>
              </div>
            )}
            <div className="article-list-title-tag">Helpcenter</div>
          </div>
          {renderList()}
          <div className="helpcenter-action-container-footer">
            <div className="hotkeys-list">
              <div className="hotkey-key">
                <i className="fa-regular fa-arrow-up"></i>
              </div>
              <div className="hotkey-key">
                <i className="fa-regular fa-arrow-down"></i>
              </div>
              <div className="hotkeys-list-label">to navigate</div>
            </div>
            <div className="hotkeys-list">
              <div className="hotkey-key">
                <i className="fa-regular fa-turn-down-left"></i>
              </div>
              <div className="hotkeys-list-label">to select</div>
            </div>
            <div className="hotkeys-list">
              <div className="hotkey-key">ESC</div>
              <div className="hotkeys-list-label">to close</div>
            </div>
          </div>
        </div>
      )}
      {!showArticleList && (
        <ReactTooltip
          id="helpcenterButtonTooltip"
          className="infoTooltip infoTooltipButton"
          delayHide={0}
          type="light"
          effect="solid"
          getContent={(content) => {
            return (
              <div className="send-key-tooltip">
                <span>{content}</span>
                <div className="hotkey-help">
                  {isMacintosh() ? <div>⌘</div> : <div>Ctrl</div>}
                  <div>Shift</div>
                  <div>H</div>
                </div>
              </div>
            );
          }}
        />
      )}
      <div
        data-for="helpcenterButtonTooltip"
        data-tip="Help center"
        className="helpcenter-container-item"
        onClick={() => {
          setShowArticleList(!showArticleList);
        }}
      >
        <HelpCenterIcon />
      </div>
    </div>
  );
};

export default inject('projectStore')(observer(PerformHelpcenterAction));
