import { useEffect, useState } from 'react';
import './UpdateProjectSLA.scss';
import { ProjectStore } from 'stores/private/ProjectStore';
import Switch from 'react-switch';
import { inject, observer } from 'mobx-react';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import PageContainer from 'components/PageContainer/PageContainer';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContent from 'components/PageContent/PageContent';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import TicketTypeFilter from 'components/TicketTypeFilter/TicketTypeFilter';
import TextInput from 'components/TextInput/TextInput';
import InfoBox from 'components/InfoBox/InfoBox';
import Gleap from 'gleap';

interface UpdateProjectSLAProps {
  projectStore?: ProjectStore;
  organisationStore?: OrganisationStore;
}

const UpdateProjectSLA = ({
  projectStore,
  organisationStore,
}: UpdateProjectSLAProps) => {
  const project = projectStore!.currentProject;
  const [filterTypes, setFilterTypes] = useState([] as string[]);
  const [filterTags, setFilterTags] = useState([] as string[]);
  const [loading, setLoading] = useState(false);
  const [callbackUrl, setCallbackUrl] = useState('');
  const [useAuth, setUseAuth] = useState(false);
  const [authType, setAuthType] = useState('');
  const [authToken, setAuthToken] = useState('');

  useEffect(() => {
    if (project) {
      const slaOptions = project?.slaOptions ?? {
        ticketTypes: [],
        restUrl: '',
        restAuth: null,
      };

      setFilterTypes(slaOptions.ticketTypes);
      setCallbackUrl(slaOptions.restUrl);
      setUseAuth(!!slaOptions.restAuth);
      setAuthType(slaOptions.restAuth?.type ?? '');
      setAuthToken(slaOptions.restAuth?.token ?? '');
    }
  }, [project]);

  if (!project) {
    return null;
  }

  return (
    <PageContainer>
      <PageHeadLine title="SLAs" />
      <PageContent hasTitle isMediumBoxed className="sharing-settings">
        <InfoBox className="mb-30">
          To start using Gleap SLA, simply add an SLA value to your contacts.
          You can do this by passing the parameter 'sla' as a number in seconds
          when calling 'Gleap.identify' or 'Gleap.updateContact'.
          <br />
          <PrimaryButton
            className="mt-10"
            onClick={() => {
              Gleap.openHelpCenterArticle('159', false);
            }}
            label="Learn more"
          />
        </InfoBox>
        <div className="options-group">
          <div className="options-group-header">Ticket types</div>
          <InfoBox className="mb-20">
            Choose which ticket types should be included in the SLA calculation.
          </InfoBox>
          <TicketTypeFilter
            filterTypes={filterTypes}
            setFilterTypes={setFilterTypes}
            selectedTags={filterTags}
            setSelectedTags={setFilterTags}
          />
        </div>
        <div className="options-group">
          <div className="options-group-header">API notification</div>
          <InfoBox>
            Configure a webhook to receive notifications when SLAs are breached.
          </InfoBox>
          <div className="configuration-container mt-30">
            <TextInput
              error=""
              className="mb-15"
              placeholder="https://example.com/webhooks"
              label="Webhook URL (POST)"
              value={callbackUrl}
              onChange={(val) => {
                setCallbackUrl(val);
              }}
            />
            <div className="switch-container mb-20">
              <Switch
                width={40}
                onColor="#2142E7"
                height={20}
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={(val) => {
                  setUseAuth(val);
                  setAuthToken('');
                  setAuthType('');
                }}
                checked={useAuth}
              />
              <span>Authentication</span>
            </div>
            {useAuth ? (
              <>
                <TextInput
                  className="mb-20"
                  error=""
                  placeholder="Bearer"
                  label="Type"
                  value={authType}
                  onChange={(val) => {
                    setAuthType(val);
                  }}
                />
                <TextInput
                  placeholder="secret_token_43134134"
                  error=""
                  label="Token"
                  value={authToken}
                  onChange={(val) => {
                    setAuthToken(val);
                  }}
                />
              </>
            ) : (
              <></>
            )}
          </div>
          <PrimaryButton
            isLoading={loading}
            onClick={async () => {
              setLoading(true);

              try {
                await projectStore!.updateProject(project!.id, {
                  slaOptions: {
                    ticketTypes: filterTypes,
                    restUrl: callbackUrl,
                    restAuth: {
                      type: authType,
                      token: authToken,
                    },
                  },
                });
                // eslint-disable-next-line no-empty
              } catch (exp) {}

              setLoading(false);
            }}
            className="mt-20"
            label="Save"
          />
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'projectStore',
  'organisationStore',
)(observer(UpdateProjectSLA));
