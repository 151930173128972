import './FeatureRequestSubscribeItem.scss';
import { Bug } from 'models/Bug';
import { inject, observer } from 'mobx-react';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import classNames from 'classnames';
import { getFormFieldValue } from 'helper/FormDataHelper';

interface FeatureRequestSubscribeItemProps {
  featureRequest: Bug;
  modalStore?: ModalStore;
}

const FeatureRequestSubscribeItem = ({
  featureRequest,
  modalStore,
}: FeatureRequestSubscribeItemProps) => {
  const featureRequestItemClass = classNames({
    'feature-request-card-subscribes': true,
  });

  return (
    <div
      className={featureRequestItemClass}
      onClick={async (e) => {
        e.stopPropagation();

        modalStore!.openModalSmart(MODALTYPE.SUBSCRIBE_USER, {
          bug: featureRequest,
          title: getFormFieldValue({ bug: featureRequest, key: 'title' }),
        });
      }}
    >
      <i className="fa-solid fa-caret-up" />
      <div className="number">{featureRequest.upvotesCount ?? 0}</div>
    </div>
  );
};

export default inject('modalStore')(observer(FeatureRequestSubscribeItem));
