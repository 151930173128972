import { FactItem } from 'models/Chart';
import React, { useEffect, useState } from 'react';
import FactChart from './FactChart';

interface FutureFactChartProps {
  title?: string;
  future?: Promise<FactItem | undefined | null>;
  width?: number | string;
  infoText?: string;
  futureWithAbortion?: (
    controller: AbortController,
  ) => Promise<FactItem | undefined | null>;
  isPositiveProgressGood?: boolean;
}

const FutureFactChart = ({
  title,
  future,
  width,
  infoText,
  futureWithAbortion,
  isPositiveProgressGood,
}: FutureFactChartProps) => {
  const [factData, setFactData] = useState<FactItem | undefined | null>({
    title: title,
  } as any);
  const [isLoading, setIsLoading] = useState(false);

  const initialize = async () => {
    try {
      setIsLoading(true);
      const data = await future;

      const overrideTitle = title ?? data?.title ?? '';
      setFactData({ ...data, title: overrideTitle } as any);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (!futureWithAbortion) return;
    const newAbortController = new AbortController();

    futureWithAbortion &&
      futureWithAbortion(newAbortController)
        .then((data) => {
          if (!newAbortController.signal.aborted) {
            const overrideTitle = title ?? data?.title ?? '';
            setFactData({ ...data, title: overrideTitle } as any);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          if (!newAbortController.signal.aborted) {
            console.error('Error fetching data:', err);
            setIsLoading(false);
          }
        });

    return () => {
      newAbortController.abort();
    };
  }, [futureWithAbortion]);

  useEffect(() => {
    future && initialize();
  }, [future]);

  return (
    <div style={{ width: width ?? '100%' }}>
      <FactChart
        fact={factData}
        isLoading={isLoading}
        infoText={infoText}
        isPositiveProgressGood={isPositiveProgressGood}
      />
    </div>
  );
};

export default FutureFactChart;
