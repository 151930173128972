import Gleap from 'gleap';
import { inject, observer } from 'mobx-react';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import './AITokenProgress.scss';
import { ModalStore } from 'stores/private/ModalStore';
import UsageLimitSettings from 'components/UsageLimitSettings/UsageLimitSettings';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';

interface AITokenProgressProps {
  organisationStore?: OrganisationStore;
  modalStore?: ModalStore;
}

export function formatNumber(num) {
  if (typeof num === 'string') {
    num = parseFloat(num);
  }

  return num.toLocaleString('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

const AITokenProgress = ({
  organisationStore,
  modalStore,
}: AITokenProgressProps) => {
  const currentOrganisation = organisationStore?.currentOrganisation;
  const mauUsage = currentOrganisation?.usageData?.tokens ?? 0;
  const includedMAU = currentOrganisation?.aiTokenLimit ?? 0;
  const maxScale = Math.max(mauUsage, includedMAU) * 1.2;
  const mauUsagePercentage = Math.ceil((mauUsage / maxScale) * 100);
  const mauUsageIncludedPercentage = Math.ceil((includedMAU / maxScale) * 100);
  const limitText = formatNumber(Math.round((includedMAU / 1000) * 0.03));

  return (
    <>
      <div className="mau-limit-cont">
        <div className="mau-limit-progress-bar">
          <div
            className="mau-limit-progress-bar-inner"
            style={{
              minWidth: `${mauUsagePercentage}%`,
            }}
          ></div>
          <div
            className="mau-limit-included"
            style={{
              left: `${mauUsageIncludedPercentage}%`,
            }}
          >
            <div className="mau-limit-included-label">
              ${limitText} usage limit
            </div>
          </div>
        </div>
        <div className="mau-limit-info">
          ${formatNumber(Math.round((mauUsage / 1000) * 0.03 * 100) / 100)} / $
          {limitText}
        </div>
      </div>
      {
        <div className="text mt-30">
          We charge a fee of $0.03 for every {1000?.toLocaleString()} AI tokens
          utilized. You currently used {Math.round(mauUsage)?.toLocaleString()}{' '}
          AI tokens.
          <br />
          <br />
          {currentOrganisation?.subscription?.subscriptionStatus ===
            'active' && (
            <PrimaryButton
              label="Update usage limit"
              onClick={() => {
                modalStore?.showModal(
                  <UsageLimitSettings
                    title="AI usage limit"
                    usageTopic="tokens"
                    value={includedMAU}
                    min={0}
                    max={333333333}
                    currencyFormatter={(value) =>
                      `$${formatNumber(
                        Math.round((value / 1000) * 0.03 * 100) / 100,
                      )} / mo`
                    }
                    onSave={async (value) => {
                      await organisationStore?.updateOrganisation({
                        aiTokenLimit: value,
                      });

                      modalStore?.closeModal();
                    }}
                  />,
                );
              }}
            />
          )}
          {currentOrganisation?.subscription?.subscriptionStatus !==
            'active' && (
            <>
              <a
                href="#"
                onClick={() => {
                  Gleap.startFeedbackFlow('z8gblk');
                }}
              >
                Contact us
              </a>{' '}
              to increase your AI token usage limit.
            </>
          )}
        </div>
      }
    </>
  );
};

export default inject(
  'modalStore',
  'organisationStore',
)(observer(AITokenProgress));
