
import SendInputEditor from 'components/SendInputEditor/SendInputEditor';
import { Title } from 'components/Title/Title';
import { inject, observer } from 'mobx-react';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { uploadFileToServer } from 'services/FileUpload';
import { BugStore } from 'stores/private/BugStore';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { UsersStore } from 'stores/private/UsersStore';
import './CloseBugMessageModal.scss';

interface CloseBugMessageModalProps {
  bugStore?: BugStore;
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
  usersStore?: UsersStore;
}

const CloseBugMessageModal = ({
  bugStore,
  modalStore,
  projectStore,
  usersStore,
}: CloseBugMessageModalProps) => {
  const attachmentsRef = useRef();
  const [attachments, setAttachments] = useState([] as File[]);
  const [isSending, setIsSending] = useState(false);

  const sendComment = async (json: any, shouldCloseConversation = false) => {
    const comment = json;
    if (!comment || comment.length === 0) {
      return;
    }

    setIsSending(true);

    if (bugStore?.currentBug?.id) {

      // Upload attachments.
      var uploadedAttachments = [] as any[];
      if (attachments) {
        for (let i = 0; i < attachments.length; i++) {
          const file = attachments[i];
          const uploadedAttachment = await uploadFileToServer(
            file,
            `${bugStore?.currentBug?.id}/attachments`,
          );
          if (uploadedAttachment) {
            uploadedAttachments.push({
              url: uploadedAttachment,
              name: file.name,
              type: file.type,
            });
          }
        }
      }

      // Send comment.
      await bugStore!.addCommentToBug(
        bugStore!.currentBug!.id,
        comment,
        false,
        uploadedAttachments,
      );

      if (attachmentsRef && attachmentsRef.current) {
        (attachmentsRef.current as any).clearFiles();
      }

      setAttachments([]);

      setIsSending(false);

      // Close modal.
      modalStore!.closeModal();
    } else {
      toast.error('Failed to send message.');
    }
  };

  return (
    <div className="compose-message-modal">
      <Title label="Great job 🎉 Now notify your user!" className="mb-20" />
      <div className="compose-message-container">
        <SendInputEditor
          isSending={isSending}
          disableEmailFallback={projectStore?.currentProject?.disableEmailFallback ?? false}
          inputTypes={['Reply']}
          currentInputType={'Reply'}
          onFilesSelected={(files) => {
            setAttachments([...files, ...attachments]);
          }}
          attachmentsUpdated={(files) => {
            setAttachments([...files]);
          }}
          attachmentsRef={attachmentsRef}
          attachments={attachments}
          onClickSend={sendComment}
          showAttachments={true}
          variables={{
            project: projectStore?.currentProject ?? {},
            session: bugStore?.currentBug?.session ?? {},
            user: usersStore?.currentUser ?? {},
          }}
          inputContentChanged={(json) => { }}
          hideTabButtons={true}
          inputPlaceholder={'Let the user know that you fixed the bug...'}
          mentions={
            projectStore?.currentProjectUsers
              ? projectStore?.currentProjectUsers.map((userItem) => {
                return {
                  label: `${userItem.firstName} ${userItem.lastName}`,
                  id: userItem.id,
                  email: userItem.email,
                  profileImageUrl: userItem.profileImageUrl,
                };
              })
              : []
          }
        />
      </div>
    </div>
  );
};

export default inject(
  'usersStore',
  'bugStore',
  'modalStore',
  'projectStore',
)(observer(CloseBugMessageModal));
