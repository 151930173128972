import FutureFactChart from 'components/Charts/FactChart/FutureFactChart';
import FutureBarChart from 'components/Charts/StatisicBarChart/FutureStatisticBarChart';
import Wrap from 'components/LayoutComponents/WrapComponent/WrapComponent';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import SmartDateRangePicker from 'components/SmartDateRangePicker/SmartDateRangePicker';
import { inject, observer } from 'mobx-react';
import { BarChartStatisticTypes, FactTypes } from 'models/Chart';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import {
  getBarChartStatisic,
  getFactStatistic,
} from 'services/StatisticsService';
import { ProjectStore } from 'stores/private/ProjectStore';

interface CustomersupportSLAProps {
  projectStore?: ProjectStore;
}

const CustomersupportSLA = ({ projectStore }: CustomersupportSLAProps) => {
  const projectId = projectStore?.currentProject?.id;

  const [startDate, setStartDate] = useState(
    moment().subtract(1, 'months').startOf('day') as Moment | null,
  );
  const [endDate, setEndDate] = useState(
    moment().endOf('day') as Moment | null,
  );

  return (
    <PageContainer>
      <PageHeadLine title="SLA" tag="BETA">
        <SmartDateRangePicker
          startIndex={1}
          onDateChange={(start, end) => {
            setStartDate(start);
            setEndDate(end);
          }}
        />
      </PageHeadLine>
      <PageContent hasTitle>
        <Wrap>
          <FutureFactChart
            infoText="Percentage of customer support tickets that were resolved within the defined service level agreement (SLA) time frame."
            width="50%"
            future={getFactStatistic({
              projectId: projectId,
              query: {
                chartType: FactTypes.SLA_COMPLIANCE,
                startDate: startDate?.toDate(),
                endDate: endDate?.toDate(),
              },
            })}
          />
          <FutureFactChart
            infoText="Percentage of customer support tickets that were not resolved within the defined service level agreement (SLA) time frame."
            width="50%"
            future={getFactStatistic({
              projectId: projectId,
              query: {
                chartType: FactTypes.SLA_NOT_COMPLIANCE,
                startDate: startDate?.toDate(),
                endDate: endDate?.toDate(),
              },
            })}
            isPositiveProgressGood={false}
          />
          <FutureFactChart
            infoText="The count of customer support tickets that were resolved within the defined service level agreement (SLA) time frame."
            width="50%"
            future={getFactStatistic({
              projectId: projectId,
              query: {
                chartType: FactTypes.SLA_NO_BREACHES_COUNT,
                startDate: startDate?.toDate(),
                endDate: endDate?.toDate(),
              },
            })}
            isPositiveProgressGood={false}
          />
          <FutureFactChart
            infoText="The count of customer support tickets that breached the defined service level agreement (SLA) time frame."
            width="50%"
            future={getFactStatistic({
              projectId: projectId,
              query: {
                chartType: FactTypes.SLA_BREACHES_COUNT,
                startDate: startDate?.toDate(),
                endDate: endDate?.toDate(),
              },
            })}
          />
        </Wrap>
        <FutureBarChart
          infoText="The count of customer support tickets that breached the defined service level agreement (SLA) time frame."
          future={getBarChartStatisic({
            projectId: projectId,
            query: {
              chartType: BarChartStatisticTypes.SLA_BREACHES_COUNT,
              startDate: startDate?.toDate(),
              endDate: endDate?.toDate(),
              timezone: moment.tz.guess(),
            },
          })}
        />
        <FutureBarChart
          infoText="The count of customer support tickets that did not breach the defined service level agreement (SLA) time frame."
          future={getBarChartStatisic({
            projectId: projectId,
            query: {
              chartType: BarChartStatisticTypes.SLA_STARTED_COUNT,
              startDate: startDate?.toDate(),
              endDate: endDate?.toDate(),
              timezone: moment.tz.guess(),
            },
          })}
        />
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore')(observer(CustomersupportSLA));
