import { generateTextFromContent } from 'pages/private/BotConfigurationPage/components/ActionEditor/ActionEditor';
import './Tooltip.scss';

interface TooltipProps {
    onClick?: any;
    className?: any;
    selected?: boolean;
    tooltip: any;
    onDelete?: any;
    currentLang?: string;
}

export const Tooltip = ({
    tooltip,
    onClick,
    selected,
    className,
    currentLang,
    onDelete,
}: TooltipProps) => {
    const renderDelete = () => {
        if (!onDelete) {
            return null;
        }

        return (<>
            <div className='tooltip-card-delete' onClick={(e) => {
                e.stopPropagation();
                onDelete();
            }}>
                <i className="fa-solid fa-trash" />
            </div>
        </>);
    }

    const renderType = () => {
        let message = {};
        if (tooltip?.message?.localized) {
            message = tooltip?.message?.localized[currentLang ?? 'en'] ?? {};
        }

        return (<div className={`tooltip-card-inner`}>
            <div className='tooltip-card-icon'>
                <i className="fa-solid fa-bullseye-pointer" />
            </div>
            <div className='tooltip-card-text'>
                {generateTextFromContent(message)}
            </div>
            <div className='tooltip-card-selector'>
                {tooltip.selector}
            </div>
            {renderDelete()}
        </div>);
    }

    return (
        <div className={`tooltip-card ${selected && 'tooltip-card--selected'}`} onClick={() => {
            onClick();
        }}>
            {renderType()}
        </div>
    );
};
