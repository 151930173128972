/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';
import moment from 'moment';
import './Replay.scss';
import { BugStore } from 'stores/private/BugStore';
import { inject } from 'mobx-react';
import useInterval from 'react-useinterval';
import Loading from 'components/Loading/Loading';
import playIcon from '../../../assets/icons/play.svg';
import playPrimaryIcon from '../../../assets/icons/playPrimary.svg';
import pauseIcon from '../../../assets/icons/pause.svg';
import { formatSeconds } from './AppReplay';
import ActivityTimeline from '../ActivityTimeline/ActivityTimeline';

interface ReplayProps {
    bugStore?: BugStore;
}

const WebReplayOld = ({ bugStore }: ReplayProps) => {
    const bug = bugStore!.currentBug;
    const [currentTime, setCurrentTime] = useState(0);
    const [totalTime, setTotalTime] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [playing, setPlaying] = useState(false);
    const iframeRef = useRef();

    useEffect(() => {
        const handler = (event) => {
            const { data } = event;
            if (data.event === 'initialized') {
                setStartDate(data.data.startDate);
                setTotalTime(data.data.duration / 10);
            }
            if (data.event === 'stopped') {
                setPlaying(false);
            }
            if (data.event === 'started') {
                setPlaying(true);
            }
            if (data.event === 'player') {
                setPlaying(data.data.playing);
            }
            if (data.event === 'seeked') {
                setCurrentTime(data.data.currentSeconds / 10);
            }
        };

        window.addEventListener('message', handler);

        return () => window.removeEventListener('message', handler);
    }, []);

    useInterval(() => {
        if (currentTime < totalTime && playing) {
            setCurrentTime(currentTime + 1);
        }
    }, 10);

    if (!bug) {
        return <></>;
    }

    const calculatedSizes = () => {
        if (
            !bug ||
            !bug.metaData ||
            !bug.metaData.innerWidth ||
            !bug.metaData.innerHeight
        ) {
            return {
                width: 100,
                height: 100,
                ratio: 1,
                offsetX: 0,
            };
        }

        let iframeWidth = bug.metaData.innerWidth;
        let iframeHeight = bug.metaData.innerHeight;
        const originaliframeWidth = iframeWidth;
        const originaliframeHeight = iframeHeight;
        const containerWidth = window.innerWidth * 0.73 - 40;
        const containerHeight = window.innerHeight - 180 - 40;
        let ratio = 1;

        if (iframeWidth > containerWidth) {
            ratio = containerWidth / iframeWidth;
            iframeWidth *= ratio;
            iframeHeight *= ratio;
        }

        if (iframeHeight > containerHeight) {
            const newRatio = containerHeight / iframeHeight;
            if (newRatio < ratio) {
                ratio = newRatio;
                iframeWidth *= newRatio;
                iframeHeight *= newRatio;
            }
        }

        ratio = iframeHeight / originaliframeHeight;

        const offsetX =
            ((containerWidth - originaliframeWidth * ratio) / 2) * (1 / ratio);

        return {
            width: iframeWidth,
            height: iframeHeight,
            ratio,
            offsetX,
        };
    };
    const sizes = calculatedSizes();

    const sendPlayPauseCommand = () => {
        if (iframeRef.current) {
            // @ts-ignore
            iframeRef.current!.contentWindow.postMessage(
                {
                    event: 'play',
                },
                '*',
            );
        }
    };

    return (
        <div className="web-replay-container">
            <div className="web-replays">
                <div className="web-replays-content-container">
                    <div
                        className="iframe-container"
                        style={{
                            height: sizes.height,
                        }}
                    >
                        <iframe
                            // @ts-ignore
                            ref={iframeRef}
                            style={{
                                width: bug.metaData.innerWidth,
                                height: bug.metaData.innerHeight,
                                transform: `scale(${sizes.ratio}) translate(${sizes.offsetX}px, 0px)`,
                            }}
                            title="Gleap Replay"
                            src={`${process.env.REACT_APP_BACKEND_URL}/bugs/${bug.shareToken}/replay`}
                        />
                        <div
                            onClick={() => {
                                if (totalTime === 0) {
                                    return;
                                }

                                sendPlayPauseCommand();
                            }}
                            className={`iframeoverlay ${playing ? 'iframeoverlay--playing' : 'iframeoverlay--paused'
                                }`}
                        >
                            {!playing && totalTime !== 0 && (
                                <img src={playPrimaryIcon} alt="play" className="play-icon" />
                            )}
                            {totalTime === 0 && <Loading />}
                        </div>
                    </div>
                </div>
                <div className="replay-container-bar">
                    <img
                        onClick={() => {
                            sendPlayPauseCommand();
                        }}
                        src={playing ? pauseIcon : playIcon}
                        alt="play"
                        className="play-icon"
                    />
                    <div className="replay-container-slider">
                        <Slider
                            min={0}
                            max={totalTime}
                            value={currentTime}
                            onChange={(value) => {
                                setCurrentTime(value);
                                if (iframeRef.current) {
                                    // @ts-ignore
                                    iframeRef.current!.contentWindow.postMessage(
                                        {
                                            event: 'playAtTime',
                                            data: {
                                                time: value * 10,
                                            },
                                        },
                                        '*',
                                    );
                                }
                            }}
                        />
                        <div className="replay-container-slider-timer">
                            <div>
                                {formatSeconds(currentTime / 100)} /{' '}
                                {formatSeconds(totalTime / 100 - 1)}
                            </div>
                            <div />
                            <div />
                        </div>
                    </div>
                </div>
            </div>
            <div className="replay-console-logs">
                <div className="replays-header">Replay details</div>
                {startDate && (
                    <ActivityTimeline
                        showAfterDate={moment(startDate)
                            .add(currentTime / 100, 'seconds')
                            .toDate()}
                    />
                )}
            </div>
        </div>
    );
};

export default inject('bugStore')(WebReplayOld);