import axios from '../Axios';

const getAuthCodeFromTrello = (projectID) => {
  return window.open(
    `https://trello.com/1/authorize?expiration=never&name=Gleap&scope=read,write&return_url=${process.env.REACT_APP_BASEURL}/projects/${projectID}/integrations/trello&response_type=token&key=${process.env.REACT_APP_TRELLO_CLIENT_ID}`,
    '_self',
  );
};

const setTrelloAuthToken = (projectID, authToken) => {
  return axios.post(`/projects/${projectID}/integrations/trello`, {
    authToken,
  });
};

const getBoards = ({ projectID, integrationID }) => {
  return axios.get(
    `/projects/${projectID}/integrations/trello/${integrationID}/boards`,
  );
};

const getBoardLists = ({ projectID, integrationID, boardID }) => {
  return axios.get(
    `/projects/${projectID}/integrations/trello/${integrationID}/columns?boardID=${boardID}`,
  );
};

const setTrelloActions = ({ projectID, integrationID, actions, settings }) => {
  return axios.put(
    `/projects/${projectID}/integrations/trello/${integrationID}`,
    {
      actions,
      settings,
    },
  );
};

const disconnectTrelloIntegration = ({ projectID, integrationID }) => {
  return axios.delete(
    `/projects/${projectID}/integrations/trello/${integrationID}`,
  );
};

export {
  getAuthCodeFromTrello,
  setTrelloAuthToken,
  getBoards,
  setTrelloActions,
  getBoardLists,
  disconnectTrelloIntegration,
};
