import React from 'react';
import './PrimaryButton.scss';
import classNames from 'classnames';
import LoadingAnimationSmall from 'components/LoadingAnimationSmall/LoadingAnimationSmall';

interface PrimaryButtonProps {
  label?: string;
  onClick?: any;
  submit?: any;
  form?: string;
  icon?: string;
  small?: boolean;
  fancy?: boolean;
  className?: any;
  fullWidth?: boolean;
  iconSideRight?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  iconOnly?: boolean;
  dataPlan?: string | null;
}

const PrimaryButton = ({
  label,
  onClick,
  submit,
  form,
  className,
  icon,
  small = false,
  fancy = false,
  fullWidth = false,
  disabled = false,
  isLoading = false,
  iconSideRight = false,
  iconOnly = false,
  dataPlan = null,
}: PrimaryButtonProps) => {
  const primaryButtonClassName = classNames(
    {
      'primary-button': true,
      'primary-button--full-width': fullWidth,
      'primary-button--disabled': disabled,
      'primary-button--loading': isLoading,
      'primary-button--icon-right': iconSideRight,
      'primary-button--small': small,
      'primary-button--fancy': fancy && !small,
      'primary-button--fancy primary-button--fancy--small': fancy && small,
      ...(dataPlan && { 'track-lead-button': true }),
    },
    className,
  );

  const renderLabel = () => {
    if (fancy) {
      return <span>{label}</span>;
    }

    return label;
  };

  if (isLoading) {
    return (
      <div className={primaryButtonClassName}>
        <LoadingAnimationSmall />
      </div>
    );
  }

  if (submit) {
    return (
      <button
        type="submit"
        data-plan={dataPlan}
        form={form}
        className={primaryButtonClassName}
      >
        {!iconSideRight && icon && <i className={`icon fa-solid fa-${icon}`} />}
        {renderLabel()}
        {iconSideRight && icon && <i className={`icon fa-solid fa-${icon}`} />}
      </button>
    );
  }

  if (iconOnly) {
    return (
      <button
        type="button"
        className={primaryButtonClassName}
        data-plan={dataPlan}
        onClick={() => {
          if (onClick && !disabled) {
            onClick();
          }
          return true;
        }}
      >
        {icon && <i className={`fa-solid fa-${icon}`} />}
      </button>
    );
  }

  return (
    <button
      type="button"
      className={primaryButtonClassName}
      onClick={() => {
        if (onClick && !disabled) {
          onClick();
        }
        return true;
      }}
      data-plan={dataPlan}
    >
      {!iconSideRight && icon && <i className={`icon fa-solid fa-${icon}`} />}
      {!iconOnly && renderLabel()}
      {iconSideRight && icon && <i className={`icon fa-solid fa-${icon}`} />}
    </button>
  );
};

export default PrimaryButton;
