import { inject, observer } from 'mobx-react';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import './SurveyPreviewModal.scss';

interface SurveyPreviewModalProps {
  modalStore?: ModalStore;
}

const SurveyPreviewModal = ({
  modalStore,
}: SurveyPreviewModalProps) => {
  const data = modalStore?.getCustomData(MODALTYPE.SURVEY_PREVIEW);

  return (
    <div className="survey-preview-modal">
      <iframe src={`https://forms.gleap.io/${data?.outboundId}`} frameBorder="0" className="frame" />
    </div>
  );
};

export default inject(
  'modalStore',
)(observer(SurveyPreviewModal));
