import { makeAutoObservable } from 'mobx';
import { Property } from 'models/Property';
import { toast } from 'react-toastify';
import { getPropertiesForSharedBug } from 'services/BugHttpService';
import { HttpPropertyService } from 'services/http.clients/http.property.client';

export class PropertyStore {
  currentProjectProperties: Property[] = [];
  currentEditingProperty: Property = {} as Property;
  stores: any;

  constructor() {
    makeAutoObservable(this);
  }

  setStores(stores) {
    this.stores = stores;
  }

  setCurrentProjectProperties(properties: Property[]) {
    this.currentProjectProperties = properties;
  }

  setCurrentEditingProperty(property: Property) {
    this.currentEditingProperty = property;
  }

  async createProperty(property: Property) {
    try {
      const createdProperty = await HttpPropertyService.getInstance().create({
        data: property,
      });

      if (!createdProperty) {
        return;
      }

      this.setCurrentProjectProperties([
        ...this.currentProjectProperties,
        createdProperty,
      ]);
      this.setCurrentEditingProperty(createdProperty);
      toast.success('Property created');
    } catch (err) {
      toast.error('Could not create property');
    }
  }

  async updateProperty(property: Property) {
    try {
      if (!property._id) {
        return;
      }

      const updatedProperty = await HttpPropertyService.getInstance().updateOne(
        {
          id: property._id,
          data: property,
        },
      );

      if (!updatedProperty) {
        return;
      }

      this.currentProjectProperties = this.currentProjectProperties.map((p) => {
        if (p._id === updatedProperty._id) {
          return updatedProperty;
        }
        return p;
      });
      this.setCurrentEditingProperty(updatedProperty);
      toast.success('Property updated');
    } catch (err) {
      toast.error('Could not update property');
    }
  }

  async deleteProperty(property: Property) {
    try {
      if (!property._id) {
        return;
      }

      await HttpPropertyService.getInstance().deleteOne({
        id: property._id,
      });

      this.currentProjectProperties = this.currentProjectProperties.filter(
        (p) => p._id !== property._id,
      );
      this.setCurrentEditingProperty({} as Property);
      toast.success('Property deleted');
    } catch (err) {
      toast.error('Could not delete property');
    }
  }

  async fetchAndSetPropertiesForSharedTicket(shareToken) {
    try {
      const response = await getPropertiesForSharedBug(shareToken);
      if (response.status === 200) {
        this.setCurrentProjectProperties(response.data);
      }
    } catch (_) {}
  }

  async fetchAndSetCurrentProjectProperties() {
    try {
      const properties = await HttpPropertyService.getInstance().find({});
      if (!properties) {
        return;
      }

      this.setCurrentProjectProperties(properties);
    } catch (err) {
      toast.error('Could not fetch properties');
    }
  }

  groupPropertiesByTargetSource = (
    properties: Property[],
    contactPropertiesFirst = false,
  ): any[] => {
    const grouped = properties.reduce((acc, property) => {
      const targetSource = property.targetSource;
      if (!acc[targetSource]) {
        acc[targetSource] = [];
      }
      acc[targetSource].push(property);
      return acc;
    }, {} as Record<string, Property[]>);

    return Object.entries(grouped)
      .sort(([key1], [key2]) => {
        if (contactPropertiesFirst) {
          if (key1 === 'SESSION') return -1;
          if (key2 === 'SESSION') return 1;
        }
        return 0;
      })
      .map(([targetSource, options]) => {
        var label = targetSource;
        var icon = 'ticket';

        if (targetSource === 'SESSION') {
          label = 'Contact';
          icon = 'user';
        } else {
          this.stores?.projectStore?.currentProject?.projectTypes?.forEach(
            (type) => {
              if (type.type === targetSource) {
                label = type.name;
              }
            },
          );
        }

        return {
          label,
          key: targetSource,
          icon,
          options,
        };
      });
  };

  getProjectProperties(contactPropertiesFirst = false) {
    return this.groupPropertiesByTargetSource(
      this.currentProjectProperties,
      contactPropertiesFirst,
    );
  }

  getProjectPropertiesForType(type: string) {
    return this.currentProjectProperties.filter((p) => p.targetSource === type);
  }

  getVisiblePropertiesForType(args: {
    feedbackType?: string;
    visabilityType: string;
  }) {
    return this.currentProjectProperties.filter(
      (p) =>
        p.targetSource === args.feedbackType &&
        p.visability[args.visabilityType],
    );
  }

  getPropertyByFieldId(args: { fieldId: string; feedbackType?: string }) {
    const { fieldId, feedbackType } = args;

    return this.currentProjectProperties.find(
      (p) =>
        p.fieldId === fieldId &&
        (!feedbackType || p.targetSource === feedbackType),
    );
  }

  getUnknownPropertiesForType(args: { ticket; feedbackType }) {
    const { ticket, feedbackType } = args;

    const propertiesOfType = this.getProjectPropertiesForType(feedbackType);
    const typePropertiesSet = new Set(propertiesOfType.map((p) => p.fieldId));

    // Convert ticket.dataFields to an array of keys
    const ticketDataFields = Object.keys(ticket?.formData ?? {});

    // Filter out keys that are included in the current type
    const nonIncludedProperties = ticketDataFields.filter(
      (key) => !typePropertiesSet.has(key),
    );

    // Convert back to an object with non-included properties
    const result = {};
    nonIncludedProperties.forEach((key) => {
      result[key] = ticket.formData[key];
    });

    const preparedProperties: any = [];

    // Convert to array of property
    for (const key of nonIncludedProperties) {
      if (key === 'reportedBy') {
        continue;
      }

      const existingProperty = this.currentProjectProperties.find(
        (p) => p.fieldId === key && p.path === 'formData',
      );

      if (!existingProperty) {
        let label;

        // Try to search in form object for label name
        if (ticket.form) {
          for (const [propKey, propValue] of Object.entries(ticket.form)) {
            if ((propValue as any).name === key) {
              label = (propValue as any).title;
              break;
            }
          }
        }

        const newProperty: any = {
          type: 'text',
          label: label ?? uppercaseFirstLetter(key),
          fieldId: key,
          path: 'formData',
        };

        preparedProperties.push(newProperty);

        continue;
      }

      preparedProperties.push(existingProperty);
    }

    return preparedProperties;
  }
}

const uppercaseFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
