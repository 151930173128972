import TicketDetailScreen from 'components/BugDetail/TicketDetailScreen';
import SimpleSidenavElement from 'components/Sidenav/SimpleSidenavElement/SimpleSidenavElement';
import SidenavContainer from 'components/SidenavContainer/SidenavContainer';
import SubSidenav from 'components/SubSidenav/SubSidenav';
import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { ProjectStore } from 'stores/private/ProjectStore';
import ConversationCSAT from './CustomerSupport/ConversationCSAT';
import CustomerSupportArticles from './CustomerSupport/CustomerSupportArticles';
import CustomerSupportConversations from './CustomerSupport/CustomerSupportConversations';
import CustomerSupportEffectiveness from './CustomerSupport/CustomerSupportEffectiveness';
import CustomersupportKai from './CustomerSupport/CustomersupportKai';
import CustomerSupportMessages from './CustomerSupport/CustomerSupportMessages';
import CustomerSupportOverview from './CustomerSupport/CustomerSupportOverview';
import CustomerSupportResponsiveness from './CustomerSupport/CustomerSupportResponsiveness';
import CustomersupportSLA from './CustomerSupport/CustomersupportSLA';
import CustomerSupportSnapshot from './CustomerSupport/CustomerSupportSnapshot';
import CustomerSupportTeam from './CustomerSupport/CustomerSupportTeam';
import CustomerSupportTeamAvailability from './CustomerSupport/CustomerSupportTeamAvailability';

interface ProjectReportsProps {
  projectStore?: ProjectStore;
}

const ProjectReports = ({ projectStore }: ProjectReportsProps) => {
  const { projectId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
  }, [projectId]);

  const isActive = (path, extraPaths: string[] = []) => {
    if (location.pathname) {
      const parts = location.pathname.split('/');

      if (parts.length >= 4) {
        if (parts[5] === path) {
          return true;
        }

        for (let i = 0; i < extraPaths.length; i++) {
          if (parts[5] === extraPaths[i]) {
            return true;
          }
        }
      }
    }

    return false;
  };

  return (
    <SidenavContainer className="widget-sidenav-container">
      <SubSidenav title="Reports">
        <SimpleSidenavElement
          name="Overview"
          onClick={() => {
            navigate(
              `/projects/${projectId}/reports/customer-support/overview`,
            );
          }}
          isActive={isActive('overview')}
        />
        <SimpleSidenavElement
          name="Tickets"
          onClick={() => {
            navigate(`/projects/${projectId}/reports/customer-support/tickets`);
          }}
          isActive={isActive('tickets')}
        />
        <SimpleSidenavElement
          name="Messages"
          onClick={() => {
            navigate(`/projects/${projectId}/reports/customer-support/messages`);
          }}
          isActive={isActive('messages')}
        />
        <SimpleSidenavElement
          name="Responsiveness"
          onClick={() => {
            navigate(
              `/projects/${projectId}/reports/customer-support/responsiveness`,
            );
          }}
          isActive={isActive('responsiveness')}
        />
        <SimpleSidenavElement
          name="Effectiveness"
          onClick={() => {
            navigate(
              `/projects/${projectId}/reports/customer-support/effectiveness`,
            );
          }}
          isActive={isActive('effectiveness')}
        />
        <SimpleSidenavElement
          name="Articles"
          onClick={() => {
            navigate(
              `/projects/${projectId}/reports/customer-support/articles`,
            );
          }}
          isActive={isActive('articles')}
        />
        <SimpleSidenavElement
          name="Kai"
          onClick={() => {
            navigate(`/projects/${projectId}/reports/customer-support/kai`);
          }}
          isActive={isActive('kai')}
        />
        <SimpleSidenavElement
          name="SLA"
          onClick={() => {
            navigate(`/projects/${projectId}/reports/customer-support/sla`);
          }}
          isActive={isActive('sla')}
        />
        <SimpleSidenavElement
          name="Conversation rating"
          onClick={() => {
            navigate(`/projects/${projectId}/reports/customer-support/csat`);
          }}
          isActive={isActive('csat')}
        />
        <SimpleSidenavElement
          name="Team performance"
          onClick={() => {
            navigate(`/projects/${projectId}/reports/customer-support/team`);
          }}
          isActive={isActive('team')}
        />
        <SimpleSidenavElement
          name="Team availability"
          onClick={() => {
            navigate(
              `/projects/${projectId}/reports/customer-support/teamavailability`,
            );
          }}
          isActive={isActive('teamavailability')}
        />
        <SimpleSidenavElement
          name="Snapshot"
          onClick={() => {
            navigate(
              `/projects/${projectId}/reports/customer-support/snapshot`,
            );
          }}
          isActive={isActive('snapshot')}
        />
      </SubSidenav>

      <Routes>
        <Route path="/customer-support/*">
          <Route index element={<CustomerSupportOverview />} />
          <Route path="overview" element={<CustomerSupportOverview />} />
          <Route path="snapshot" element={<CustomerSupportSnapshot />} />
          <Route path="teamavailability" element={<CustomerSupportTeamAvailability />} />
          <Route path="tickets" element={<CustomerSupportConversations />} />
          <Route path="messages" element={<CustomerSupportMessages />} />
          <Route
            path="responsiveness"
            element={<CustomerSupportResponsiveness />}
          />
          <Route path="csat" element={<ConversationCSAT />}>
            <Route path=":shareToken" element={<TicketDetailScreen />} />
          </Route>
          <Route
            path="effectiveness"
            element={<CustomerSupportEffectiveness />}
          />
          <Route path="team" element={<CustomerSupportTeam />} />
          <Route path="kai" element={<CustomersupportKai />} />
          <Route path="sla" element={<CustomersupportSLA />} />
          <Route path="articles" element={<CustomerSupportArticles />} />
        </Route>
      </Routes>
    </SidenavContainer>
  );
};

export default inject('projectStore')(observer(ProjectReports));
