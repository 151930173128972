import { convertTipTapToPlainText } from 'helper/TipTapHelper';
import { inject, observer } from 'mobx-react';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { aiTranslate } from 'services/ProjectHttpService';
import { BugStore } from 'stores/private/BugStore';
import './CommentTranslate.scss';
import { toast } from 'react-toastify';
import { CommentTypes } from 'models/Comment';

interface CommentTranslateProps {
  bugStore?: BugStore;
  comment: any;
}

const CommentTranslate = ({ bugStore, comment }: CommentTranslateProps) => {
  const [loading, setLoading] = useState(false);
  const userLanguage = navigator.language.split('-')[0] || 'en';
  const { projectId } = useParams();

  const translate = async () => {
    try {
      setLoading(true);
      const commentText = convertTipTapToPlainText(comment.data);
      const translatedText = await aiTranslate(
        projectId,
        { text: commentText },
        userLanguage,
      );
      if (translatedText?.data?.text && translatedText?.data?.text.length > 0) {
        await bugStore?.addCommentTranslate(comment.bug, comment.id, {
          [userLanguage]: translatedText?.data?.text,
        });
      } else {
        toast.error('Translation failed...');
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <div className="ai-comment-translate" onClick={translate}>
      {loading ? (
        <i className="fas fa-spinner-third fa-spin"></i>
      ) : (
        ' Translate ·'
      )}
    </div>
  );
};

export const canTranslateComment = (comment: any) => {
  if (
    comment?.type == CommentTypes.SHARED_COMMENT ||
    comment?.type == CommentTypes.BOT_REPLY
  ) {
    return true;
  }
  return false;
};

export default inject('bugStore')(observer(CommentTranslate));
