import Loading from 'components/Loading/Loading';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import ReactTooltip from 'react-tooltip';
import { useFilePicker } from 'use-file-picker';
import './SendInputAttachmentUpload.scss';

interface SendInputAttachmentUploadProps {
  onFilesSelected?: (any) => void;
}

const SendInputAttachmentUpload = forwardRef(
  (
    {
      onFilesSelected,
    }: SendInputAttachmentUploadProps,
    ref,
  ) => {
    const [openFileSelector, { plainFiles, loading, clear, errors }] =
      useFilePicker({
        readAs: 'DataURL',
        accept: [
          '.json',
          '.pdf',
          '.txt',
          '.xlsx',
          'image/*',
          '.mp4',
          '.mp3',
          '.mov',
          '.webp',
          '.webm',
        ],
        multiple: true,
        limitFilesConfig: { max: 5 },
        maxFileSize: 10,
      });

    useImperativeHandle(ref, () => ({
      clearFiles() {
        clear();
      },
    }));

    useEffect(() => {
      if (onFilesSelected) {
        onFilesSelected(plainFiles);
      }
    }, [plainFiles]);

    if (loading) {
      return <Loading />;
    }

    return (
      <>
        <ReactTooltip
          id="attachmentButtonTooltip"
          className="infoTooltip infoTooltipButton"
          delayHide={200}
          type="light"
          effect="solid"
          getContent={(content) => {
            return (
              <div className="send-key-tooltip">
                <span>{content}</span>
              </div>
            );
          }}
        />
        <div
          data-for="attachmentButtonTooltip"
          data-tip="Attach file"
          className="attachment-item"
          onClick={() => {
            openFileSelector();
          }}
        >
          <i className="fa-solid fa-paperclip" />
          {errors && errors.length > 0 && (
            <span className="attachment-item-error">
              File size limit exceeded (max. 10MB).
            </span>
          )}
        </div>
      </>
    );
  },
);

export default SendInputAttachmentUpload;
