import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import PropertySelect from 'components/PropertyComponents/PropertySelect/PropertySelect';
import TextInput from 'components/TextInput/TextInput';
import {
  getLanguageProperty,
  setLanguageProperty,
} from 'helper/AssignObjectKeysHelper';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Action } from 'models/Bot';
import Switch from 'react-switch';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './InputActionEditor.scss';
import { useEffect, useState } from 'react';
import { PropertyStore } from 'stores/private/PropertyStore';
import InfoBox from 'components/InfoBox/InfoBox';
import { getDefaultTranslation } from 'stores/Translations';

interface InputActionEditorProps {
  action: Action;
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
  propertyStore?: PropertyStore;
}

const InputActionEditor = ({
  action,
  modalStore,
  projectStore,
  propertyStore,
}: InputActionEditorProps) => {
  const currentLang = projectStore?.currentLanguage ?? 'en';
  const [properties, setProperties] = useState<any[]>([]);
  const [selectedProperty, setSelectedProperty] = useState<any>();

  useEffect(() => {
    const allProperties =
      propertyStore?.getProjectProperties().reduce((accumulator, current) => {
        return accumulator.concat(current.options);
      }, []) ?? [];
    setProperties(allProperties);
  }, []);

  return (
    <div className="input-action-editor">
      <PageContainer>
        <PageHeadLine title="Collect data">
          <div className="header-content-right">
            <PrimaryButton
              label="Close"
              iconSideRight={false}
              onClick={() => {
                modalStore!.closeModal();
              }}
            />
          </div>
        </PageHeadLine>
        <PageContent hasTitle>
          <TextInput
            name="survey-question"
            className="mb-30"
            label="Question"
            value={getLanguageProperty(action, 'title', currentLang)}
            localizedable
            translationObject={action?.title}
            placeholder=""
            type="text"
            error=""
            onChange={(val) => {
              runInAction(() => {
                setLanguageProperty(action, `title`, currentLang, val);
              });
            }}
          />
          <PropertySelect
            selectedItemId={action.attributeId}
            contactPropertiesFirst={true}
            useId
            onChange={(item) => {
              runInAction(() => {
                action.attributeId = item._id;
                
                const property = properties.find(
                  (p) => p._id === item._id,
                );
                if (property) {
                  setSelectedProperty(property);
                }
              });
            }}
          />
          <div className="switch-container mt-30">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={(checked) => {
                action.attributeSkip = checked;
              }}
              checked={action.attributeSkip ?? false}
            />
            <span>Skip question, when data already set.</span>
          </div>
          {selectedProperty && (
            <InfoBox className='mt-30'>
              The selected attribute ({selectedProperty.type}) is used to store the answer of the question.
              {(selectedProperty.options && selectedProperty.options.length > 0) && <>
                <br />
                <br />
                <b>Options:</b><br />
                {selectedProperty.options.map((option) => {
                  return <>{getDefaultTranslation(option?.label, "en")}<br /></>
                })}
              </>}
            </InfoBox>
          )}
        </PageContent>
      </PageContainer>
    </div>
  );
};

export default inject(
  'modalStore',
  'projectStore',
  'propertyStore',
)(observer(InputActionEditor));
