import LinkButton from 'components/LinkButton/LinkButton';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { inject, observer } from 'mobx-react';
import { BugStore } from 'stores/private/BugStore';
import './InboxBugStatusSelection.scss';

import ReactTooltip from 'react-tooltip';
import { isMacintosh, useHotkey } from 'services/Helper';
import { ProjectStore } from 'stores/private/ProjectStore';

interface InboxBugStatusSelectionProps {
  bugStore?: BugStore;
  projectStore?: ProjectStore;
  isInbox?: boolean;
  iconOnly?: boolean;
}

const InboxBugStatusSelection = ({
  bugStore,
  projectStore,
  isInbox = false,
  iconOnly,
}: InboxBugStatusSelectionProps) => {
  const bug = bugStore?.currentBug;
  const isFeatureRequest = bug?.type === 'FEATURE_REQUEST';

  const closeConversation = () => {
    if (!bug) {
      return;
    }

    const nextTicketId = JSON.parse(JSON.stringify(projectStore?.nextTicketId));

    bugStore!.updateBug(bug.id, {
      status: 'DONE',
    });
    bugStore!.clearCurrentBug();

    // Reopen next in list.
    if (isInbox) {
      setTimeout(() => {
        if (nextTicketId) {
          projectStore!.openFeedbackItem({ shareToken: nextTicketId });
        }
      }, 800);
    }
  };

  useHotkey(80, closeConversation);

  if (!bug) {
    return null;
  }

  if (isFeatureRequest) {
    return null;
  }

  return (
    <div className="ml-10">
      {bug.status !== 'DONE' ? (
        <>
          <ReactTooltip
            id="closeButtonTooltip"
            className="infoTooltip infoTooltipButton"
            delayHide={0}
            type="light"
            effect="solid"
            getContent={(content) => {
              return (
                <div className="send-key-tooltip">
                  <span>{content}</span>
                  <div className="hotkey-help">
                    {isMacintosh() ? <div>⌘</div> : <div>Ctrl</div>}
                    <div>Shift</div>
                    <div>P</div>
                  </div>
                </div>
              );
            }}
          />
          <div data-for="closeButtonTooltip" data-tip={`Close ${isInbox ? 'conversation' : 'ticket'}`}>
            <PrimaryButton
              className="bfw"
              label="Close"
              iconOnly={iconOnly}
              icon="check"
              onClick={() => {
                closeConversation();
              }}
            />
          </div>
        </>
      ) : (
        <LinkButton
          className="bfw"
          label="Open"
          iconSideRight={false}
          icon="inbox"
          onClick={() => {
            bugStore!.updateBug(bug.id, {
              status: 'OPEN',
            });
            bugStore!.clearCurrentBug();
          }}
        />
      )}
    </div>
  );
};

export default inject('bugStore', 'projectStore')(observer(InboxBugStatusSelection));
