/* eslint-disable @typescript-eslint/no-unused-expressions */
import AuthenticationForm from 'components/AuthenticationForm/AuthenticationForm';
import Loading from 'components/Loading/Loading';
import MeshBackground from 'components/MeshBackground/MeshBackground';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { UsersStore } from '../../../stores/private/UsersStore';
import './Register.scss';
import RegisterForm from './RegisterForm/RegisterForm';

interface RegisterProps {
  usersStore?: UsersStore;
}

const Register = ({ usersStore }: RegisterProps) => {
  const [isInvitation, setIsInvitation] = useState(false);

  useEffect(() => {
    usersStore?.setLoading(false);
    const parsedParams = new URLSearchParams(window.location.search);
    if (parsedParams) {
      const utmSource = parsedParams.get('utm_source');
      if (utmSource === 'invitation') {
        setIsInvitation(true);
      }
    }

    if (usersStore?.currentUser) {
      usersStore?.logout(true, false);
    }

    return () => { };
  }, []);

  if (usersStore?.loading) {
    return (
      <PageContainer>
        <PageContent isCentered>
          <Loading />
        </PageContent>
      </PageContainer>
    );
  }

  return (
    <div className="login-page">
      <MeshBackground selector='mesh-4' />
      <AuthenticationForm
        headline={
          isInvitation ? 'Join your team' : 'Start your 14-day free trial'
        }
        subtitle={
          isInvitation
            ? 'Welcome to the Gleap family'
            : 'Explore our #AI-first customer support platform'
        }
        form={<RegisterForm />}
      />
    </div>
  );
};
export default inject('usersStore')(observer(Register));
