import { GenericHttpClient } from './config/http.generic.client';

export class HttpMessageTemplateService extends GenericHttpClient<any> {
  static _instance: HttpMessageTemplateService;
  static getInstance(): HttpMessageTemplateService {
    if (this._instance == null) {
      this._instance = new HttpMessageTemplateService('/message-templates');
    }
    return this._instance;
  }
}
