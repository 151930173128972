import AggregationPicker from 'components/AggregationPicker/AggregationPicker';
import FutureStatisticDataTable, {
  StatisticListTypes,
} from 'components/Charts/StatisticDataTable/FutureStatisticDataTable';
import LinkButton from 'components/LinkButton/LinkButton';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import SmartDateRangePicker from 'components/SmartDateRangePicker/SmartDateRangePicker';
import { inject, observer } from 'mobx-react';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import {
  getListStatistic,
  getListStatisticExport,
} from 'services/StatisticsService';
import { ProjectStore } from 'stores/private/ProjectStore';

interface CustomersupportKaiProps {
  projectStore?: ProjectStore;
}

const CustomerSupportTeam = ({ projectStore }: CustomersupportKaiProps) => {
  const projectId = projectStore?.currentProject?.id;

  const [filter, setFilter] = useState('MEDIAN');

  const [useWorkingHours, setUseWorkingHours] = useState(false);

  const [exportIsLoading, setExportIsLoading] = useState(false);

  const teams = projectStore?.currentProjectTeams ?? [];

  const [startDate, setStartDate] = useState(
    moment().subtract(1, 'months').startOf('day') as Moment | null,
  );
  const [endDate, setEndDate] = useState(
    moment().endOf('day') as Moment | null,
  );

  const exportTeamPerformance = async (args: { additionalQuery?: any }) => {
    try {
      setExportIsLoading(true);

      const response = await getListStatisticExport({
        projectId: projectId,
        query: {
          chartType: StatisticListTypes.TEAM_PERFORMANCE_LIST,
          startDate: startDate?.toDate(),
          endDate: endDate?.toDate(),
          useWorkingHours: useWorkingHours,
          ...args.additionalQuery,
        },
      });

      const hiddenElement = document.createElement('a');
      hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(
        response.data,
      )}`;
      hiddenElement.target = '_blank';
      hiddenElement.download = `${StatisticListTypes.TEAM_PERFORMANCE_LIST.toLowerCase()
        .replace(/\s/g, '_')
        .toLowerCase()}.csv`;
      hiddenElement.click();

      setExportIsLoading(false);
    } catch (err) {
      toast.error('Export failed');
      setExportIsLoading(false);
    }
  };

  return (
    <PageContainer>
      <PageHeadLine title="Team performance" tag="BETA">
        <div className="header-content-right">
          <AggregationPicker
            className="border-margin-right"
            setFilter={setFilter}
            filter={filter}
          />
          <div className="switch-container mr-20">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={(checked) => {
                setUseWorkingHours(checked);
              }}
              checked={useWorkingHours}
            />
            <span>Respect working hours</span>
          </div>
          <SmartDateRangePicker
            startIndex={1}
            onDateChange={(start, end) => {
              setStartDate(start);
              setEndDate(end);
            }}
          />
        </div>
      </PageHeadLine>
      <PageContent hasTitle>
        <FutureStatisticDataTable
          infoText="The Team Performance List shows how well each team member is doing in handling tickets. It includes metrics like how many tickets they were assigned, how many replies they sent, and how quickly they closed tickets. It also displays their average conversation rating. The table compares these metrics for each team member and shows their progress."
          future={async () => {
            return getListStatistic({
              projectId: projectId,
              query: {
                chartType: StatisticListTypes.TEAM_PERFORMANCE_LIST,
                startDate: startDate?.toDate(),
                endDate: endDate?.toDate(),
                useWorkingHours: useWorkingHours,
                aggsType: filter,
              },
            });
          }}
          headerActions={
            <LinkButton
              icon="file-export"
              iconSideRight={false}
              className="mr-20"
              onClick={async () => {
                await exportTeamPerformance({});
              }}
              label="Export"
              isLoading={exportIsLoading}
            />
          }
        />
        {teams.map((team) => (
          <FutureStatisticDataTable
            key={team._id}
            title={`${team.name} Performance`}
            infoText="The Team Performance List shows how well each team member is doing in handling tickets. It includes metrics like how many tickets they were assigned, how many replies they sent, and how quickly they closed tickets. It also displays their average conversation rating. The table compares these metrics for each team member and shows their progress."
            future={async () => {
              return getListStatistic({
                projectId: projectId,
                query: {
                  chartType: StatisticListTypes.TEAM_PERFORMANCE_LIST,
                  startDate: startDate?.toDate(),
                  endDate: endDate?.toDate(),
                  useWorkingHours: useWorkingHours,
                  team: team._id,
                  aggsType: filter,
                },
              });
            }}
            headerActions={
              <LinkButton
                icon="file-export"
                iconSideRight={false}
                className="mr-20"
                onClick={async () => {
                  await exportTeamPerformance({
                    additionalQuery: { team: team._id },
                  });
                }}
                label="Export"
                isLoading={exportIsLoading}
              />
            }
          />
        ))}
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore')(observer(CustomerSupportTeam));
