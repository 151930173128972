import InfoBox from 'components/InfoBox/InfoBox';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import { Title } from 'components/Title/Title';
import { inject, observer } from 'mobx-react';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { HelpcenterStore } from 'stores/private/HelpcenterStore';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';

interface ImportFromIntercomModalProps {
  helpcenterStore?: HelpcenterStore;
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

const ImportFromIntercomModal = ({
  projectStore,
  modalStore,
}: ImportFromIntercomModalProps) => {
  const currentProject = projectStore?.currentProject;

  const onUpload = modalStore!.customData.onUpload as (
    data: any,
  ) => Promise<void>;

  const [apiKey, setApiKey] = useState('');
  const [endpoint, setEndpoint] = useState('api.intercom.io');
  const [isLoading, setIsLoading] = useState(false);

  const createSession = async () => {
    if (!currentProject) return;

    setIsLoading(true);

    await onUpload({ endpoint: endpoint, apiKey: apiKey });

    setIsLoading(false);

    modalStore?.closeModal();
  };

  return (
    <Column justifyContent="flex-start" alignItems="flex-start">
      <Title className="mb-15" label="Importer for Intercom" />
      <TextInput
        className="mb-10"
        label="Access token"
        placeholder=""
        onChange={(val) => {
          setApiKey(val);
        }}
      />
      <InfoBox className="mb-15 full-width">
        Our Intercom importer uses the Intercom API to import your collections &
        articles. In order to authenticate with the API, you need to provide an
        access token. You can find more information about this process{' '}
        <a
          href="https://help.gleap.io/en/articles/65-migrating-from-intercom-to-gleap"
          target="_blank"
        >
          here
        </a>
        .
      </InfoBox>
      <TextInput
        className="mb-10"
        label="Endpoint"
        value={endpoint}
        placeholder="api.intercom.io"
        onChange={(val) => {
          setEndpoint(val);
        }}
      />
      <InfoBox className="mb-15 full-width">
        US: api.intercom.io
        <br />
        EU: api.eu.intercom.io
        <br />
        AU: api.au.intercom.io
        <br />
      </InfoBox>
      <Row justifyContent="flex-end">
        <PrimaryButton
          label="Start importer"
          onClick={createSession}
          isLoading={isLoading}
          disabled={apiKey === '' || endpoint === ''}
        />
      </Row>
    </Column>
  );
};

export default inject(
  'projectStore',
  'modalStore',
)(observer(ImportFromIntercomModal));
