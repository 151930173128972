import { useState, useCallback } from 'react';
import _ from 'lodash';
import '../../../styles/scss-variable.scss';
import './TextFilterComponent.scss';

function TextFilterComponent({ searchPlaceholder, value, onValueChanged }) {
  const [inputValue, setInputValue] = useState(value);

  // Debounce the callback passed to the onChange event
  const debouncedOnValueChanged = useCallback(_.debounce(onValueChanged, 1000), []);

  const handleSearchInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    debouncedOnValueChanged(newValue);
  };

  return (
    <div>
      <input
        className="special-list-component-search"
        type="text"
        placeholder={searchPlaceholder}
        value={inputValue}
        onChange={handleSearchInputChange}
      />
    </div>
  );
}

export default TextFilterComponent;
