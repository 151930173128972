import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper';
import InfoBox from 'components/InfoBox/InfoBox';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import PropertyInput from 'components/PropertyComponents/PropertyInput/PropertyInput';
import { formatDate } from 'helper/DateHelper';
import { inject, observer } from 'mobx-react';
import { Session } from 'models/Session';
import moment from 'moment';
import TimeAgo from 'react-timeago';
import { getCountryByCode } from 'services/CountryHelper';
import { PropertyStore } from 'stores/private/PropertyStore';
import { SessionStore } from 'stores/private/SessionStore';
import './SessionDetails.scss';
import SessionPropertyItem from './components/SessionPropertyItem';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router';
import { getSchemaProperty } from 'helper/AssignObjectKeysHelper';

interface SessionDetailsProps {
  title: string;
  session?: Session;
  propertyStore?: PropertyStore;
  sessionStore?: SessionStore;
}

const SessionDetails = ({
  title,
  session,
  propertyStore,
  sessionStore,
}: SessionDetailsProps) => {
  const navigate = useNavigate();
  const properties = propertyStore?.getVisiblePropertiesForType({
    feedbackType: 'SESSION',
    visabilityType: 'detail',
  });

  if (!session) {
    return <></>;
  }

  const isValidHttpUrl = (string) => {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
  };

  const renderSessionPropertyItems = (data, currentPath = 'customData') => {
    if (!data) return null;

    return Object.keys(data).map((key) => {
      const newPath = currentPath ? `${currentPath}.${key}` : key;
      const value = data[key];

      // Check if value is an object but not an array
      if (
        value !== null &&
        typeof value === 'object' &&
        !Array.isArray(value)
      ) {
        return renderSessionPropertyItems(value, newPath);
      }
      // Check if value is an array
      else if (Array.isArray(value)) {
        return value.map((item, index) => {
          const arrayPath = `${newPath}[${index}]`;
          // Check if item inside array is an object or another array
          if (typeof item === 'object' && item !== null) {
            return renderSessionPropertyItems(item, arrayPath);
          } else {
            return (
              <SessionPropertyItem
                session={session}
                label={arrayPath.replace('customData.', '')}
                path={arrayPath}
              />
            );
          }
        });
      } else {
        return (
          <SessionPropertyItem
            session={session}
            label={newPath.replace('customData.', '')}
            path={newPath}
          />
        );
      }
    });
  };

  let location = 'Unknown';
  if (session.location) {
    location =
      session.location.region && session.location.region.length > 0
        ? `${session.location.region}, ${getCountryByCode(
            session.location.country,
          )}`
        : getCountryByCode(session.location.country);
  }

  let localTime;
  if (session.location && session.location.timezone) {
    localTime = moment().tz(session.location.timezone).format('MMM DD h:mm A');
  }

  return (
    <div className="contact-details-card card contact-details-container">
      {session.unsubscribed && session.unsubscribedAt && (
        <InfoBox icon="none" className="info-box--error mb-20">
          ⚠️ This contact unsubscribed from your emails on{' '}
          {formatDate(session.unsubscribedAt, 'later')}.
          {session.unsubscribeReason && (
            <>
              <br />
              <b>Reason:</b> {session.unsubscribeReason}
              <br />
            </>
          )}
          <PrimaryButton
            className="mt-10"
            label="Resubscribe"
            onClick={() => {
              Swal.fire({
                title: 'Are you sure?',
                text: 'This will resubscribe the contact to your emails. Abusing this feature may result in account suspension.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, resubscribe',
                cancelButtonText: 'Cancel',
              }).then((result) => {
                if (result.isConfirmed) {
                  sessionStore?.resubscribeSession(session.id);
                }
              });
            }}
          />
        </InfoBox>
      )}
      {session.blocked && (
        <InfoBox icon="none" className="info-box--error mb-20">
          <div>
            ⛔️ This contact is muted. Messages from muted contacts get moved to
            spam automatically.
          </div>
          <PrimaryButton
            className="mt-10"
            label="Unmute contact"
            icon="volume-up"
            onClick={() => {
              sessionStore?.updateSession(session?.id, {
                blocked: false,
              });
            }}
          />
        </InfoBox>
      )}
      <div className="contact-details-card-rows">
        <ComponentWrapper
          className="session-details-group"
          title="Details"
          noPadding
        >
          {properties?.map((property) => {
            const value = getSchemaProperty(session, property);
            const showLink = value && value.length > 0 && (property.label === 'Company ID' || property.label === 'Company')

            return (
              <div className="contact-details-property-row">
                <div
                  onClick={() => {
                    if (showLink) {
                      navigate(`/projects/${session.project}/sessions?companyId=${session.companyId}${session.companyName ? `&companyName=${session.companyName}` : ""}`);
                    }
                  }}
                  className={`contact-details-property-row-key ${showLink
                    ? 'contact-details-property-row-key-cursor-pointer'
                    : ''
                    }`}
                >
                  {property.labelIcon && (
                    <i className={`fa-solid ${property.labelIcon}`} />
                  )}
                  {property.label}
                  {showLink ? (
                    <div className="contact-details-property-row-link">
                      <i className={`fa-regular fa-arrow-right`} />
                    </div>
                  ) : <></>}
                </div>
                <div className="contact-details-property-row-value">
                  <PropertyInput
                    viewType="details"
                    data={session}
                    property={property}
                    onSetData={(data) => {
                      sessionStore?.updateSession(session.id, data);
                    }}
                  />
                </div>
              </div>
            );
          })}
          <div className="contact-details-property-row">
            <div className="contact-details-property-row-key">
              <i className="fa-solid fa-map-marker" />
              Location
            </div>
            <div className="contact-details-property-row-value">
              <div className="text-input-preview-container text-input-preview-container--read-only">
                {location}
              </div>
            </div>
          </div>
          {localTime && (
            <div className="contact-details-property-row">
              <div className="contact-details-property-row-key">
                <i className="fa-solid fa-clock" />
                Time
              </div>
              <div className="contact-details-property-row-value">
                <div className="text-input-preview-container text-input-preview-container--read-only">
                  {localTime}
                </div>
              </div>
            </div>
          )}
        </ComponentWrapper>
        <ComponentWrapper
          className="session-details-group"
          title="Activities"
          noPadding
        >
          <SessionPropertyItem
            session={session}
            label="Signed up"
            path="createdAt"
            valueFormatter={(value) => {
              return <TimeAgo date={value} />;
            }}
            readonly
          />
          <SessionPropertyItem
            session={session}
            label="Last activity"
            path="lastActivity"
            valueFormatter={(value) => {
              return <TimeAgo date={value} />;
            }}
            readonly
          />
          {session.unsubscribed && session.unsubscribedAt && (
            <div className="contact-details-card-value-row">
              <div className="contact-details-card-value-row-key">
                Unsubscribed
              </div>
              <div className="contact-details-card-value-row-value">
                {formatDate(session.unsubscribedAt, 'later')}
              </div>
            </div>
          )}
          {session.lastPageView && (
            <div className="contact-details-card-value-row">
              <div className="contact-details-card-value-row-key">
                Last pageview
              </div>
              <div className="contact-details-card-value-row-value">
                {isValidHttpUrl(session.lastPageView.page) ? (
                  <a
                    href={session.lastPageView.page}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {session.lastPageView.page}
                  </a>
                ) : (
                  session.lastPageView.page
                )}
              </div>
            </div>
          )}
          <div className="contact-details-card-value-row">
            <div className="contact-details-card-value-row-key">
              Last feedback
            </div>
            <div className="contact-details-card-value-row-value">
              {session.lastFeedbackReport ? (
                <TimeAgo date={session.lastFeedbackReport} />
              ) : (
                '--'
              )}
            </div>
          </div>
        </ComponentWrapper>
        {session.customData && (
          <ComponentWrapper
            className="session-details-group"
            title="Custom data"
            noPadding
          >
            {renderSessionPropertyItems(session.customData)}
          </ComponentWrapper>
        )}
      </div>
    </div>
  );
};

export default inject(
  'propertyStore',
  'sessionStore',
)(observer(SessionDetails));
