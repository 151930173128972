import { inject } from 'mobx-react';
import { BugStore } from 'stores/private/BugStore';
import './ScreenRecording.scss';

interface ScreenRecordingProps {
  bugStore?: BugStore;
}

const ScreenRecording = ({ bugStore }: ScreenRecordingProps) => {
  const bug = bugStore!.currentBug;
  if (!bug) {
    return null;
  }

  const renderVideo = () => {
    const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    const isWebM = bug.screenRecordingUrl && bug.screenRecordingUrl.includes(".webm");

    return (<>
      <video controls>
        <source src={bug.screenRecordingUrl} type={isWebM ? "video/webm" : "video/mp4"} />
      </video>
      {isWebM && !isChrome && <span className='video-playback-info'>⚠️ Video playback might only work on Chrome currently.</span>}
    </>);
  }

  return <div className="screenrecording-container">{renderVideo()}</div>;
};

export default inject('bugStore')(ScreenRecording);
