import ApiKey from 'components/ApiKey/ApiKey';
import InfoBox from 'components/InfoBox/InfoBox';
import LinkButton from 'components/LinkButton/LinkButton';
import ListTable, {
  CellText,
  CellTextCopy,
} from 'components/ListTable/ListTable';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import TextInput from 'components/TextInput/TextInput';
import { languages as constLanguages } from 'constants/Languages';
import { isEqual } from 'lodash';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Feature } from 'models/Enums';
import 'prismjs/components/prism-clike';
import 'prismjs/themes/prism.css';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Switch from 'react-switch';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import Swal from 'sweetalert2';
import './HelpcenterSettings.scss';
import { toast } from 'react-toastify';

interface HelpcenterSettingsProps {
  projectStore?: ProjectStore;
  organisationStore?: OrganisationStore;
}

const HelpcenterSettings = ({
  projectStore,
  organisationStore,
}: HelpcenterSettingsProps) => {
  const { flowConfig } = projectStore?.editingProject || {};
  const { projectId } = useParams();
  const navigate = useNavigate();
  const helpcenterConfig = flowConfig?.helpcenterConfig;
  const [customDomain, setCustomDomain] = useState(
    projectStore?.currentProject?.customDomainHelpCenter || '',
  );

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    projectStore?.setEditingProject();
  }, [projectStore?.currentProject, projectStore?.flowConfig]);

  const columns = useMemo(
    () => [
      {
        Header: 'Hostname',
        accessor: 'hostname',
        Cell: (row) => <CellTextCopy text={row.value} />,
      },
      {
        Header: 'Type',
        accessor: 'type',
        width: '50px',
        Cell: (row) => <CellText text={row.value} />,
      },
      {
        Header: 'Add this value',
        accessor: 'value',
        Cell: (row) => <CellTextCopy text={row.value} />,
      },
    ],
    [],
  );

  const _buildHelpcenterLocalizationForm = () => {
    return (
      <div className="options-group">
        <div className="options-group-header">Localization</div>
        {helpcenterConfig?.localization.map((item, index) => (
          <div className="helpcenter-localize-item mb-10" key={item.language}>
            <SelectDropDown
              showBorder
              items={constLanguages}
              selectedItem={constLanguages.find(
                (lang) => lang.code === item.language,
              )}
              labelPropertyName="name"
              valuePropertyName="code"
              placeholder="Select language"
              onChange={(value) => {
                runInAction(() => {
                  item.language = value.code;
                });
              }}
            />
            <TextInput
              className="ml-10 mr-10"
              placeholder="Page title"
              error=""
              value={item.title}
              onChange={(val) => {
                runInAction(() => {
                  item.title = val;
                });
              }}
            />
            <TextInput
              placeholder="Header text"
              error=""
              value={item.description}
              onChange={(val) => {
                runInAction(() => {
                  item.description = val;
                });
              }}
            />
            {helpcenterConfig?.localization.length > 1 && (
              <div
                className="delete-item cursor-focus ml-15"
                onClick={() => {
                  runInAction(() => {
                    helpcenterConfig?.localization.splice(index, 1);
                  });
                }}
              >
                <i className="fa-solid fa-trash" />
              </div>
            )}
          </div>
        ))}
        <PrimaryButton
          icon="plus"
          label="Add language"
          className="mr-10 mt-10"
          onClick={() => {
            runInAction(() => {
              helpcenterConfig?.localization.push({
                language: '',
                title: '',
                description: '',
              });
            });
          }}
        />
      </div>
    );
  };

  const renderCustomDomainSettings = () => {
    if (projectStore?.currentProject?.customDomainHelpCenter) {
      return (
        <>
          <InfoBox className="mt-10 mb-20">
            In order to use your custom domain, you need to add the following
            CNAME record to your DNS settings.
          </InfoBox>
          <ListTable
            data={[
              {
                id: 'CNAME',
                label: 'CNAME',
                hostname: projectStore?.currentProject?.customDomainHelpCenter,
                type: 'CNAME',
                value: 'help.gleap.io',
              },
            ]}
            columns={columns}
          />
          <LinkButton
            className="mt-20"
            label="Remove custom domain"
            onClick={() => {
              projectStore!.deleteCustomDomainHelpCenterSettings();
              setCustomDomain('');
            }}
          />
        </>
      );
    }

    return (
      <>
        <div className="fullwidth mt-30">
          <InfoBox className="mt-10 mb-10">
            Use your own custom domain for your Gleap help center.
          </InfoBox>
          <div className="custom-domain">
            <span>https://</span>
            <TextInput
              placeholder="help.yourdomain.com"
              type="text"
              className="mb-10"
              error=""
              initalValue={customDomain}
              onChange={(text) => {
                setCustomDomain(text);
              }}
            />
            <PrimaryButton
              label="Add domain"
              icon="arrow-right"
              iconSideRight={true}
              className="ml-10"
              disabled={customDomain.length === 0}
              onClick={() => {
                const planIsEntitled = projectStore?.isFeatureInPlan(
                  Feature.INTEGRATIONS,
                  ['some', 'all'],
                );

                if (!planIsEntitled) {
                  return Swal.fire({
                    title: 'Upgrade to Gleap Pro',
                    text: 'Upgrade to Gleap Pro to add a custom help center domain.',
                    showCancelButton: true,
                    confirmButtonText: `Upgrade now ✨`,
                    denyButtonText: `Cancel`,
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      navigate(
                        `/organization/${organisationStore?.currentOrganisation?.id}/billing?upgrade=true`,
                      );
                    }
                  });
                }
                const refactoredDomain = customDomain.replace(/https?:\/\/|\/$/g, '');
                projectStore?.updateCustomDomainHelpCenterSettings(
                  refactoredDomain,
                );
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const _buildHelpcenterCustomDomainForm = () => {
    const shareURL = projectStore?.currentProject?.customDomainHelpCenter
      ? `https://${projectStore?.currentProject?.customDomainHelpCenter}`
      : `https://${projectStore?.currentProject?.defaultDomainHelpCenter}.gleap.help`;

    return (
      <div className="options-group">
        <div className="options-group-header">Domain settings</div>
        <div className="share-url share-url-fullwidth">
          <ApiKey apiKey={shareURL} icon="copy" />
        </div>
        {renderCustomDomainSettings()}
      </div>
    );
  };

  const _buildHelpcenterKaiSettings = () => {
    return (
      <div className="options-group">
        <div className="options-group-header">Kai (AI bot)</div>
        <div>
          <div className="switch-container mt-20">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={true}
              uncheckedIcon={false}
              onChange={() => {
                const isEnabled = helpcenterConfig?.kaiAnswers ?? false;
                helpcenterConfig.kaiAnswers = !isEnabled;
              }}
              checked={helpcenterConfig?.kaiAnswers ?? false}
            />
            <span>Enable Kai answers</span>
          </div>
        </div>
      </div>
    );
  };

  const _buildHelpcenterAuthSettings = () => {
    return (
      <div className="options-group">
        <div className="options-group-header">Password protection</div>
        <div>
          <div className="switch-container mt-20">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={true}
              uncheckedIcon={false}
              onChange={() => {
                const isEnabled = helpcenterConfig?.enablePasswordProtect ?? false;
                if (isEnabled) {
                  helpcenterConfig.globalPassword = undefined;
                }
                helpcenterConfig.enablePasswordProtect = !isEnabled;
              }}
              checked={helpcenterConfig?.enablePasswordProtect ?? false}
            />
            <span>Enable password-only access.</span>
          </div>
          {helpcenterConfig?.enablePasswordProtect &&
            <div className="mt-30">
              <span>Enter a password for Help center access*</span>
              <TextInput
                placeholder="Enter password"
                type="text"
                className="mt-10"
                error=""
                initalValue={helpcenterConfig.globalPassword}
                onChange={(text) => {
                  helpcenterConfig.globalPassword = text;
                }}
              />
            </div>}
        </div>
      </div>
    );
  }

  const _buildHelpcenterAuthorSettings = () => {
    return (
      <div className="options-group">
        <div className="options-group-header">General settings</div>
        <div>
          <div className="switch-container mt-20">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={true}
              uncheckedIcon={false}
              onChange={() => {
                const isEnabled = helpcenterConfig?.showAuthor ?? true;
                helpcenterConfig.showAuthor = !isEnabled;
              }}
              checked={helpcenterConfig?.showAuthor ?? true}
            />
            <span>Show author in helpcenter articles.</span>
          </div>
          <div className="switch-container mt-20">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={true}
              uncheckedIcon={false}
              onChange={() => {
                const isEnabled = helpcenterConfig?.showHelpcenterLogo ?? true;
                helpcenterConfig.showHelpcenterLogo = !isEnabled;
              }}
              checked={helpcenterConfig?.showHelpcenterLogo ?? true}
            />
            <span>Show header logo</span>
          </div>
          <div className="switch-container mt-20">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={true}
              uncheckedIcon={false}
              onChange={() => {
                const isEnabled = helpcenterConfig?.showGleapWidget ?? true;
                helpcenterConfig.showGleapWidget = !isEnabled;
              }}
              checked={helpcenterConfig?.showGleapWidget ?? true}
            />
            <span>Show Gleap widget</span>
          </div>
          <div className="switch-container mt-20">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={true}
              uncheckedIcon={false}
              onChange={() => {
                const isEnabled = helpcenterConfig?.showSurveyArticle ?? true;
                helpcenterConfig.showSurveyArticle = !isEnabled;
              }}
              checked={helpcenterConfig?.showSurveyArticle ?? true}
            />
            <span>Show "Rate this article"</span>
          </div>
        </div>
      </div>
    );
  };

  const _buildHelpcenterCacheSettings = () => {
    return (
      <div className="options-group">
        <div className="options-group-header">Cache</div>
        <div>
          <div className="text mb-20">
            Gleap automatically caches your help center to ensure great
            performance. Changes invalidate the cache automatically. If you want
            to manually clear the cache, press the button below.
          </div>
          <PrimaryButton
            label="Clear cache"
            onClick={() => {
              projectStore?.clearHelpCenterCache();
            }}
          />
        </div>
      </div>
    );
  };

  const _buildHelpcenterAppWidgetSettings = () => {
    return (
      <div className="options-group">
        <div className="options-group-header">Widget app</div>
        <div>
          <div className="text mb-20">
            To answer questions even faster, you can include the help center
            directly within the Gleap widget.
          </div>
          <PrimaryButton
            label="Show widget app settings"
            onClick={() => {
              navigate(`/projects/${projectId}/widget/apps`);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <PageContainer>
      <PageHeadLine title="Settings">
        <div className="form-widget-buttons header-content-right">
          <PrimaryButton
            label="Save"
            disabled={isEqual(projectStore?.flowConfig, flowConfig)}
            onClick={() => {
              if (helpcenterConfig.enablePasswordProtect && (!helpcenterConfig.globalPassword || helpcenterConfig.globalPassword === '')) {
                toast.error('Please enter a password for Help Center access.');
                return;
              }
              projectStore?.saveEditingProject({
                flowConfig: {
                  helpcenterConfig: flowConfig.helpcenterConfig,
                },
              });
            }}
          />
        </div>
      </PageHeadLine>
      <PageContent hasTitle>
        <SizedContainer size={ContainerSizes.XXL}>
          {_buildHelpcenterKaiSettings()}
          {_buildHelpcenterLocalizationForm()}
          {_buildHelpcenterCustomDomainForm()}
          {_buildHelpcenterAuthSettings()}
          {_buildHelpcenterAuthorSettings()}
          {_buildHelpcenterCacheSettings()}
          {_buildHelpcenterAppWidgetSettings()}
        </SizedContainer>
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'projectStore',
  'organisationStore',
)(observer(HelpcenterSettings));
