import ApiKey from 'components/ApiKey/ApiKey';
import { HeadLine } from 'components/HeadLine/HeadLine';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { BugStore } from 'stores/private/BugStore';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './FeatureBoardShareModal.scss';

interface FeatureBoardShareModalProps {
  modalStore?: ModalStore;
  bugStore?: BugStore;
  projectStore?: ProjectStore;
}

const FeatureBoardShareModal = ({
  modalStore,
  projectStore,
}: FeatureBoardShareModalProps) => {
  const hasCustomDomain = projectStore?.currentProject?.customDomain && projectStore?.currentProject?.customDomain.length > 0;
  const shareDefaultURL = `${process.env.REACT_APP_BASEURL}/sharedboard/${projectStore?.currentProject?.apiKey}`;
  const customDomainShareURL = `https://${projectStore?.currentProject?.customDomain}`;
  const shareURL = hasCustomDomain ? customDomainShareURL : shareDefaultURL;
  const navigate = useNavigate();

  return (
    <div className="share-board-modal">
      <HeadLine
        title="Share options"
        subtitle="Share your feature requests & roadmap with your customers."
      />
      <div className="options-group mt-20">
        <div className="options-group-header">Roadmap portal URL</div>
        <div className="share-url">
          <ApiKey apiKey={shareURL} icon="copy" />
        </div>
        <div className='options-text-footer centered text mt-20'>
          <a href="#" className='mr-5' onClick={() => {
            modalStore?.closeModal();
            navigate(`/projects/${projectStore?.currentProject?.id}/featurerequests/settings`);
          }}>Configure a custom domain</a> for your roadmap portal.
        </div>
      </div>
    </div>
  );
};

export default inject('modalStore', 'projectStore')(observer(FeatureBoardShareModal));
