import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { UsersStore } from 'stores/private/UsersStore';

interface EditCommentProps {
  comment: any;
  modalStore?: ModalStore;
  usersStore?: UsersStore;
}

const EditComment = ({ comment, modalStore, usersStore }: EditCommentProps) => {
  const user = usersStore?.currentUser;
  if (
    comment.sessionNotificationsUnread &&
    (!comment.emailStatusHistory || comment.emailStatusHistory.length === 0) &&
    moment().diff(comment.createdAt, 'minutes') < 2 &&
    comment?.user?.id === user?.id
  ) {
    return (
      <i
        className="fa-light fa-pen cursor-focus"
        onClick={() => {
          modalStore?.openModal(MODALTYPE.EDIT_COMMENT, { comment });
        }}
      />
    );
  }

  return <></>;
};
export default inject('modalStore', 'usersStore')(observer(EditComment));
