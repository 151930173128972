export const COLORS = {
  BUGBATTLE_BLUE: '#0169ff',
  BUGBATTLE_ORANGE: '#e3b139',
  BUGBATTLE_RED: '#e50d00',
  BUGBATTLE_GREEN: '#5ab07c',
  BUGBATTLE_BACKGROUND_GRAY: '#1E2223',
  TEXT_WHITE: '#e3e3e3',
};

export const SHADOWS = {
  SHADOW: '0 1px 2px 0 rgba(0, 0, 0, 0.5)',
  INNER_SHADOW:
    'inset 0 2px 1px 0 rgba(0, 0, 0, 0.2),inset 0 1px 1px 0 rgba(0, 0, 0, 0.14), inset 0 1px 3px 0 rgba(0, 0, 0, 0.12)',
};
