import InfoBox from 'components/InfoBox/InfoBox';
import Loading from 'components/Loading/Loading';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TagInput from 'components/TagInput/TagInput';
import { isEqual } from 'lodash';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Switch from 'react-switch';
import { ProjectStore } from 'stores/private/ProjectStore';
import Swal from 'sweetalert2';
import './DeveloperOptionsWidgetSettings.scss';

interface DeveloperOptionsWidgetSettingsProps {
  projectStore?: ProjectStore;
}

function DeveloperOptionsWidgetSettings({
  projectStore,
}: DeveloperOptionsWidgetSettingsProps) {
  // @ts-ignore
  const { projectId } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const { flowConfig } = projectStore?.editingProject || {};

  const dataToUpdate = {
    enableWebReplays: flowConfig?.enableWebReplays,
    enableReplays: flowConfig?.enableReplays,
    replaysInterval: flowConfig?.replaysInterval,
    enableNetworkLogs: flowConfig?.enableNetworkLogs,
    networkLogPropsToIgnore: flowConfig?.networkLogPropsToIgnore,
    networkLogBlacklist: flowConfig?.networkLogBlacklist,
    sendNetworkResources: flowConfig?.sendNetworkResources,
    enableShortcuts: flowConfig?.enableShortcuts,
    activationMethodShake: flowConfig?.activationMethodShake,
    activationMethodScreenshotGesture:
      flowConfig?.activationMethodScreenshotGesture,
  };

  useEffect(() => {
    setIsLoading(true);
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
    setIsLoading(false);
  }, [projectId]);

  useEffect(() => {
    projectStore?.setEditingProject();
  }, [projectStore?.currentProject, projectStore?.flowConfig]);

  if (isLoading) {
    return <Loading />;
  }

  const renderReplaySettings = () => {
    return (
      <div className="options-group">
        <div className="options-group-header">Replays</div>
        <div className="checkbox-settings-content">
          <div className="checkbox-settings-container">
            <div className="switch-container">
              <Switch
                width={40}
                onColor="#2142E7"
                height={20}
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={() => {
                  if (!flowConfig?.enableWebReplays) {
                    Swal.fire({
                      text: 'Our web replay feature is still in BETA. Please test the feature in your staging environment, before activating.',
                      showCancelButton: true,
                      confirmButtonText: `Activate anyway`,
                      denyButtonText: `Cancel`,
                    }).then(async (result) => {
                      if (result.isConfirmed) {
                        flowConfig.enableWebReplays = true;
                      }
                    });
                  } else {
                    flowConfig.enableWebReplays = false;
                  }
                }}
                checked={flowConfig?.enableWebReplays ?? false}
              />
              <span>Enable 🎥 web replays</span>
            </div>
            {flowConfig?.enableWebReplays && (
              <InfoBox className="mt-20">
                Enabling replays might slow down the ticket submission process.
                Please test this feature before enabling it in production.
              </InfoBox>
            )}
            <div className="options-group-seperator" />
            <div className="switch-container">
              <Switch
                width={40}
                onColor="#2142E7"
                height={20}
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={() => {
                  if (!flowConfig?.enableReplays) {
                    Swal.fire({
                      text: "Our Replay feature is still in BETA. We don't recommend using it within production apps yet.",
                      showCancelButton: true,
                      confirmButtonText: `Activate anyway`,
                      denyButtonText: `Cancel`,
                    }).then(async (result) => {
                      if (result.isConfirmed) {
                        flowConfig.enableReplays = true;
                      }
                    });
                  } else {
                    flowConfig.enableReplays = false;
                  }
                }}
                checked={flowConfig?.enableReplays ?? false}
              />
              <span>
                Enable 🎥 app replays <i>(BETA)</i>
              </span>
            </div>
            {flowConfig?.enableReplays && (
              <div className="switch-container mt-20">
                <Switch
                  width={40}
                  onColor="#2142E7"
                  height={20}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={() => {
                    if (flowConfig?.replaysInterval === 1) {
                      flowConfig.replaysInterval = 5;
                    } else {
                      flowConfig.replaysInterval = 1;
                    }
                  }}
                  checked={flowConfig?.replaysInterval === 1}
                />
                <span>High quality mode (might impact performance)</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderNetworkingSettings = () => {
    return (
      <div className="options-group">
        <div className="options-group-header">Network logs</div>
        <div className="checkbox-settings-content">
          <div className="checkbox-settings-container">
            <div className="switch-container mb-20">
              <Switch
                width={40}
                onColor="#2142E7"
                height={20}
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={() => {
                  flowConfig.enableNetworkLogs = !flowConfig.enableNetworkLogs;
                }}
                checked={flowConfig?.enableNetworkLogs}
              />
              <span>Enable 🌎 Network Logs</span>
            </div>
            {flowConfig?.enableNetworkLogs && (
              <>
                <div className="options-group-seperator" />
                <div className="input-label">
                  Remove the following keys from all header fields and JSON
                  bodies.
                </div>
                <TagInput
                  tags={flowConfig?.networkLogPropsToIgnore}
                  onChange={(newTags) => {
                    flowConfig.networkLogPropsToIgnore = newTags;
                  }}
                />
                <InfoBox className="mt-20">
                  <>
                    Examples: token - username - password
                    <br />
                    Please note that only top-level keys are supported.
                  </>
                </InfoBox>
                <div className="options-group-seperator" />
                <div className="input-label">
                  Exclude all requests that contain the following domains.
                </div>
                <TagInput
                  tags={flowConfig?.networkLogBlacklist}
                  onChange={(newTags) => {
                    flowConfig.networkLogBlacklist = newTags;
                  }}
                />
                <InfoBox className="mt-20">
                  <>Examples: gleap.io - api.gleap.io/bugs</>
                </InfoBox>
                <div className="options-group-seperator" />
                <div className="switch-container">
                  <Switch
                    width={40}
                    onColor="#2142E7"
                    height={20}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={() => {
                      flowConfig.sendNetworkResources =
                        !flowConfig.sendNetworkResources;
                    }}
                    checked={flowConfig?.sendNetworkResources ?? false}
                  />
                  <span>Include static resources (Web SDK only)</span>
                </div>
                <div className="additional-info-networklogs mt-20">
                  Some platforms require additional setup. Please see the{' '}
                  <a href="https://docs.gleap.io" target="_blank">
                    documentation
                  </a>{' '}
                  for more information.
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderWebSDKOptions = () => {
    return (
      <>
        <div className="options-group">
          <div className="options-group-header">Shortcuts</div>
          <div className="checkbox-settings-content">
            <div className="checkbox-settings-container">
              <div className="switch-container">
                <Switch
                  width={40}
                  onColor="#2142E7"
                  height={20}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={() => {
                    flowConfig.enableShortcuts = !flowConfig.enableShortcuts;
                  }}
                  checked={flowConfig?.enableShortcuts}
                />
                <span>
                  Enable 🏋️‍♀️ <i>CTRL + u</i> shortcut
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderAppSettings = () => {
    return (
      <div className="options-group">
        <div className="options-group-header">Gesture trigger options</div>
        <div className="checkbox-settings-content">
          <div className="checkbox-settings-container">
            <div className="switch-container mb-20">
              <Switch
                width={40}
                onColor="#2142E7"
                height={20}
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={() => {
                  flowConfig.activationMethodShake =
                    !flowConfig.activationMethodShake;
                }}
                checked={flowConfig?.activationMethodShake}
              />
              <span>Enable 👋 Shake gesture</span>
            </div>
          </div>
          <div className="checkbox-settings-container">
            <div className="switch-container mt-20">
              <Switch
                width={40}
                onColor="#2142E7"
                height={20}
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={() => {
                  flowConfig.activationMethodScreenshotGesture =
                    !flowConfig.activationMethodScreenshotGesture;
                }}
                checked={flowConfig?.activationMethodScreenshotGesture}
              />
              <span>Enable 📲 Screenshot gesture</span>
            </div>
            {(flowConfig?.activationMethodShake ||
              flowConfig?.activationMethodScreenshotGesture) && (
              <>
                <InfoBox className="mt-20">
                  <b>Please note that Apple might reject your app</b> due to a
                  recent policy change, when enabling the screenshot or shake
                  gesture.
                </InfoBox>
                <InfoBox className="mt-20">
                  <b>Android permissions:</b> Please read the{' '}
                  <a
                    href="https://docs.gleap.io/android/trouble-shooting#screenshot-gesture"
                    target="_blank"
                  >
                    documentation
                  </a>{' '}
                  for more information.
                </InfoBox>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <PageContainer className="relativ-full-width-page-container">
      <PageHeadLine title="Developer options">
        <div className="form-widget-buttons header-content-right">
          <PrimaryButton
            label="Save"
            disabled={isEqual(projectStore?.flowConfig, flowConfig)}
            onClick={() => {
              projectStore?.saveEditingProject({ flowConfig: dataToUpdate });
            }}
          />
        </div>
      </PageHeadLine>
      <PageContent hasTitle>
        <div className="developer-settings">
          {flowConfig && (
            <>
              {renderReplaySettings()}
              {renderNetworkingSettings()}
              {renderWebSDKOptions()}
              {renderAppSettings()}
            </>
          )}
        </div>
      </PageContent>
    </PageContainer>
  );
}

export default inject('projectStore')(observer(DeveloperOptionsWidgetSettings));
