import axios from '../Axios';

const getAuthCodeFromCalendly = (projectID) => {
  return window.open(
    `https://auth.calendly.com/oauth/authorize?client_id=${process.env.REACT_APP_CALENDLY_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_BASEURL}/integrations/calendly?state=${projectID}`,
    '_self',
  );
};

const setCalendlyAuthToken = (code, projectID) => {
  return axios.post(`/projects/${projectID}/integrations/calendly`, {
    code,
  });
};

const getRepositories = ({ projectID, integrationID }) => {
  return axios.get(
    `/projects/${projectID}/integrations/calendly/${integrationID}/repositories`,
  );
};

const setCalendlyActions = ({
  projectID,
  integrationID,
  actions,
  settings,
}) => {
  return axios.put(
    `/projects/${projectID}/integrations/calendly/${integrationID}`,
    {
      actions,
      settings,
    },
  );
};

const disconnectCalendlyIntegration = ({ projectID, integrationID }) => {
  return axios.delete(
    `/projects/${projectID}/integrations/calendly/${integrationID}`,
  );
};

export {
  getAuthCodeFromCalendly,
  setCalendlyAuthToken,
  getRepositories,
  setCalendlyActions,
  disconnectCalendlyIntegration,
};
