import WidgetButtonPreview from 'components/WidgetButtonPreview/WidgetButtonPreview';
import { getLanguageProperty } from 'helper/AssignObjectKeysHelper';
import { inject, observer } from 'mobx-react';
import { Outbound } from 'models/Outbound';
import { useEffect, useState } from 'react';
import { ProjectStore } from 'stores/private/ProjectStore';
import './ChecklistPreview.scss';
import { convertTipTapToHtml } from 'helper/TipTapHelper';

interface ChecklistPreviewProps {
  outboundRule?: Outbound;
  projectStore?: ProjectStore;
  stepPreview?: number;
  onPreviewStepChange?: (step: number) => void;
}

const ChecklistPreview = ({
  outboundRule,
  projectStore,
  stepPreview = -1,
  onPreviewStepChange,
}: ChecklistPreviewProps) => {
  const [selectedUser, setSelectedUser] = useState(undefined as any);
  const lang = projectStore?.currentLanguage ?? 'en';
  const { flowConfig } = projectStore?.currentProject || {};

  useEffect(() => {
    if (outboundRule) {
      if (outboundRule.sender && projectStore?.currentProjectUsers) {
        const user = projectStore?.currentProjectUsers.find(
          (o) => o.id === outboundRule.sender,
        );
        setSelectedUser(user);
      } else {
        setSelectedUser(undefined);
      }
    }
  }, [outboundRule?.sender]);

  if (!outboundRule || !flowConfig) {
    return <></>;
  }

  const steps = outboundRule?.config?.steps ?? [];

  const getStepsDuration = () => {
    let duration = 0;
    steps.forEach((step) => {
      if (step.duration && step.duration > 0) {
        duration += step.duration;
      }
    });
    return duration;
  };

  const cornerRadius = flowConfig?.borderRadius ?? 20;

  return (
    <div className="checklist-app">
      <div className="checklist-widget">
        <div className="checklist-title">
          {getLanguageProperty(outboundRule, 'subject', lang)}
        </div>
        <div className="checklist-description">
          {getLanguageProperty(outboundRule, 'message', lang)}
        </div>
        {selectedUser && (
          <div className="checklist-author">
            <img
              src={selectedUser?.profileImageUrl}
              alt="avatar"
              className="checklist-author-img"
            />
            <div className="checklist-author-name">
              {selectedUser?.firstName}
            </div>
          </div>
        )}
        <div className="checklist-progress-labels">
          <div className="checklist-progress-label">{steps.length} steps</div>
          <div className="checklist-progress-label">
            About {getStepsDuration()} minutes
          </div>
        </div>
        <div className="checklist-progress-bar"></div>
        <div className="checklist-tasks">
          {steps.map((step, index) => {
            var htmlMessage = '';
            try {
              htmlMessage = convertTipTapToHtml({
                content: getLanguageProperty(step, 'description', lang),
              });
            } catch (exp) {}

            const actionTitle = getLanguageProperty(step, 'actionTitle', lang);

            return (
              <div
                className={`checklist-task ${
                  stepPreview === index ? 'checklist-task--active' : ''
                }`}
                key={index}
                style={{
                  borderRadius: `${cornerRadius * 0.6}px`,
                }}
              >
                <div
                  className="checklist-task-header"
                  onClick={() => {
                    if (onPreviewStepChange) {
                      onPreviewStepChange(index);
                    }
                  }}
                >
                  <div className="checklist-task-header--badge">
                    {index + 1}
                  </div>
                  <div className="checklist-task-header--title">
                    {getLanguageProperty(step, 'title', lang)}
                  </div>
                </div>
                {stepPreview === index && (
                  <>
                    <div className="checklist-task-body">
                      <div
                        className={`checklist-task-body-content`}
                        dangerouslySetInnerHTML={{ __html: htmlMessage }}
                      ></div>
                      {actionTitle &&
                        actionTitle.length > 0 &&
                        step.action !== 'none' && (
                          <div className="checklist-task-body-action">
                            <div className="action-button form-send-button">
                              {actionTitle}
                            </div>
                          </div>
                        )}
                    </div>
                    {step.allowMarkDone && (
                      <div className="checklist-task-body-markdone">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="checklist-task-body-markdone-icon"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M20.7071 5.29289C21.0976 5.68342 21.0976 6.31658 20.7071 6.70711L9.70711 17.7071C9.31658 18.0976 8.68342 18.0976 8.29289 17.7071L3.29289 12.7071C2.90237 12.3166 2.90237 11.6834 3.29289 11.2929C3.68342 10.9024 4.31658 10.9024 4.70711 11.2929L9 15.5858L19.2929 5.29289C19.6834 4.90237 20.3166 4.90237 20.7071 5.29289Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                        <div className="checklist-task-body-markdone-label">
                          Mark as done
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="checklist-widget-badge">
        <WidgetButtonPreview />
      </div>
    </div>
  );
};

export default inject('projectStore')(observer(ChecklistPreview));
