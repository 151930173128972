import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

const headers: Readonly<Record<string, string | boolean>> = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
  'Access-Control-Allow-Credentials': true,
};

export class HttpClient {
  private instance?: AxiosInstance;

  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }

  initHttp(): any {
    const http: AxiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_BACKEND_URL}/v3`,
      headers,
    });

    http.interceptors.request.use(this.injectToken);

    this.instance = http;

    return http;
  }

  // default generic http methods
  async get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<R | any> {
    return await this.http.get<T, R>(url, config);
  }

  async post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<R | any> {
    return await this.http.post<T, R>(url, data, config);
  }

  async put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<R | any> {
    return await this.http.put<T, R>(url, data, config);
  }

  async delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<R | any> {
    return await this.http.delete<T, R>(url, config);
  }

  async request<T = any, R = AxiosResponse<T>>(
    config: AxiosRequestConfig,
  ): Promise<R | any> {
    return await this.http.request<T, R>(config);
  }

  // interceptor for injecting token to the actual request
  injectToken = (
    config: AxiosRequestConfig,
  ): AxiosRequestConfig | undefined => {
    try {
      const token = localStorage.getItem('@bbtoken');
      if (token != null && config.headers != null) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      const projectId = localStorage.getItem('@gleap-project');
      if (projectId != null && config.headers != null) {
        config.headers.project = projectId;
      }
      return config;
    } catch (_) {}
  };
}

export const http = new HttpClient();
