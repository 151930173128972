import InfoBox from 'components/InfoBox/InfoBox';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { inject, observer } from 'mobx-react';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './AnswerBotActionCapabilityEditor.scss';
import TextInput from 'components/TextInput/TextInput';
import Switch from 'react-switch';
import {
  getLanguageProperty,
  setLanguageProperty,
} from 'helper/AssignObjectKeysHelper';
import { runInAction } from 'mobx';
import IconDropdown from 'components/IconDropdown/IconDropdown';
import { ReactComponent as BotIcon } from 'assets/icons/bot.svg';
import { ReactComponent as CustomActionIcon } from 'assets/icons/supportteam.svg';
import BotDropdown from 'components/BotDropdown/BotDropdown';
import { useEffect } from 'react';

export const customActions = [
  {
    label: 'Pass conversation to support team',
    value: 'PASS_TO_SUPPORT_TEAM',
    icon: CustomActionIcon,
  },
  {
    label: 'Pass conversation to workflow',
    value: 'BOT',
    icon: BotIcon,
  },
];

interface AnswerBotActionCapabilityEditorProps {
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
}

const AnswerBotActionCapabilityEditor = ({
  modalStore,
  projectStore,
}: AnswerBotActionCapabilityEditorProps) => {
  const currentLang = projectStore?.currentLanguage ?? 'en';
  const customData =
    modalStore?.getCustomData(MODALTYPE.ANSWERBOT_CAPABILITY_EDITOR) ?? {};
  const capability = customData?.capability ?? {};

  useEffect(() => {
    if (projectStore?.currentProject?.id && projectStore?.bots?.length === 0) {
      projectStore?.getBots(projectStore?.currentProject?.id);
    }
  }, [projectStore?.currentProject?.id]);

  return (
    <div className="answerbot-action-editor">
      <PageContainer>
        <PageHeadLine title={capability.name ?? ''}>
          <div className="header-content-right">
            <PrimaryButton
              label="Done"
              icon="circle-check"
              iconSideRight={false}
              onClick={() => {
                modalStore!.closeModal();
              }}
            />
          </div>
        </PageHeadLine>
        <PageContent hasTitle>
          <TextInput
            label="Capability name"
            value={capability?.name}
            onChange={(val) => {
              runInAction(() => {
                capability.name = val;
              });
            }}
            placeholder=""
            type="text"
            error=""
          />
          <div className="options-group mt-30" tabIndex={0}>
            <div className="options-group-header">⚡️ Action</div>
            <div className="form-action-type mb-10">
              <IconDropdown
                value={customActions.find((element) => {
                  return element.value === capability.actionType;
                })}
                options={customActions}
                onValueChanged={(selectedItem) => {
                  runInAction(() => {
                    if (capability) {
                      capability.actionType = selectedItem.value;
                    }
                  });
                }}
              />
            </div>
            {capability.actionType === 'PASS_TO_SUPPORT_TEAM' && (
              <div className="switch-container feedback-content-row-cell mr-10 mt-30">
                <Switch
                  width={40}
                  onColor="#2142E7"
                  height={20}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={(checked) => {
                    runInAction(() => {
                      if (capability) {
                        capability.showReplyTimes = checked;
                      }
                    });
                  }}
                  checked={capability.showReplyTimes ?? true}
                />
                <span>Send reply times when forwarding.</span>
              </div>
            )}
            {capability.actionType === 'BOT' && (
              <>
                <div className="input-label mt-20">Bot</div>
                <BotDropdown
                  placeholder="Select a bot to start"
                  projectId={projectStore?.currentProject?.id}
                  value={projectStore?.bots?.find((bot) => {
                    return bot.id === capability.customBot;
                  })}
                  options={projectStore?.bots ?? []}
                  onValueChanged={(selectedItem) => {
                    runInAction(() => {
                      capability.customBot = selectedItem.id;
                    });
                  }}
                />
                <div className="switch-container feedback-content-row-cell mt-30">
                  <Switch
                    width={40}
                    onColor="#2142E7"
                    height={20}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={(checked) => {
                      runInAction(() => {
                        if (capability) {
                          capability.trackRoutedToAgentRate = checked;
                        }
                      });
                    }}
                    checked={capability.trackRoutedToAgentRate ? true : false}
                  />
                  <span>
                    Track this action as a "routed to agent rate" for analysis
                    and reporting.
                  </span>
                </div>
              </>
            )}
          </div>
          <div className="options-group mt-30" tabIndex={0}>
            <div className="options-group-header">✨ AI activation</div>
            <div className="switch-container feedback-content-row-cell mb-30">
              <Switch
                width={40}
                onColor="#2142E7"
                height={20}
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={(checked) => {
                  runInAction(() => {
                    if (capability) {
                      capability.aiActivation = checked;
                    }
                  });
                }}
                checked={capability.aiActivation ? true : false}
              />
              <span>
                Allow Kai to automatically detect when to activate this
                capability.
              </span>
            </div>
            {capability.aiActivation && (
              <>
                <TextInput
                  label="What does this capability do?"
                  value={capability?.activationPhrase}
                  onChange={(val) => {
                    runInAction(() => {
                      capability.activationPhrase = val;
                    });
                  }}
                  placeholder=""
                  type="text"
                  error=""
                />
                <InfoBox className="mt-20">
                  Kai tries to detect when to activate this capability based on
                  the description you enter here.
                  <br />
                  <br />
                  <b>Examples:</b>
                  <br />
                  - Forward conversation to support team
                  <br />
                  - Request custom offer
                  <br />
                  - Subscribe to newsletter
                  <br />
                  <br />
                  <b>Tip:</b> Try to describe the action in a way like naming a
                  command / function.
                </InfoBox>
                <div className="switch-container mt-20">
                  <Switch
                    width={40}
                    onColor="#2142E7"
                    height={20}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={(checked) => {
                      runInAction(() => {
                        if (capability) {
                          capability.preventForwardingText = !checked;
                        }
                      });
                    }}
                    checked={
                      capability.preventForwardingText === true ? false : true
                    }
                  />
                  <span>Send message when the capability gets executed.</span>
                </div>
                {!capability.preventForwardingText && (
                  <>
                    <TextInput
                      className="mt-20"
                      label="Forwarding message"
                      value={getLanguageProperty(
                        capability,
                        'forwardingText',
                        currentLang,
                      )}
                      localizedable
                      translationObject={capability?.forwardingText}
                      onChange={(val) => {
                        runInAction(() => {
                          setLanguageProperty(
                            capability,
                            `forwardingText`,
                            currentLang,
                            val,
                          );
                        });
                      }}
                      description="Kai will send this message when the capability gets executed."
                      placeholder=""
                      type="text"
                      error=""
                    />
                  </>
                )}
              </>
            )}
          </div>
          <div className="options-group mt-30" tabIndex={0}>
            <div className="options-group-header">👇 Button activation</div>
            <div className="switch-container feedback-content-row-cell mb-20">
              <Switch
                width={40}
                onColor="#2142E7"
                height={20}
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={(checked) => {
                  runInAction(() => {
                    if (capability) {
                      capability.showButton = checked;
                    }
                  });
                }}
                checked={capability.showButton ? true : false}
              />
              <span>
                Show a button to activate this capability in the chat.
              </span>
            </div>
            {capability.showButton && (
              <TextInput
                label="Button text"
                value={getLanguageProperty(
                  capability,
                  'buttonText',
                  currentLang,
                )}
                localizedable
                translationObject={capability?.buttonText}
                onChange={(val) => {
                  runInAction(() => {
                    setLanguageProperty(
                      capability,
                      `buttonText`,
                      currentLang,
                      val,
                    );
                  });
                }}
                description="The button will be shown as action after Kai's answer."
                placeholder=""
                type="text"
                error=""
              />
            )}
          </div>
        </PageContent>
      </PageContainer>
    </div>
  );
};

export default inject(
  'modalStore',
  'projectStore',
)(observer(AnswerBotActionCapabilityEditor));
