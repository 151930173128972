import BotConditionTag from 'components/BotConditionTag/BotConditionTag';
import { runInAction } from 'mobx';
import { PredicateChild } from 'models/Bot';
import { useState } from 'react';
import Select from 'react-select';
import './BotConditionPicker.scss';

interface BotConditionPickerProps {
  predicate: PredicateChild;
  properties?: any[];
}

const BotConditionPicker = ({
  predicate,
  properties,
}: BotConditionPickerProps) => {
  const [toggle, setToggle] = useState(false);

  const dataTypeOptions = [
    {
      label: 'Office hours',
      value: 'office-hours',
      icon: 'calendar',
      isProperty: false,
      options: [
        {
          label: 'During office hours',
          id: 'during-office-hours',
        },
        {
          label: 'Outside office hours',
          id: 'outside-office-hours',
        },
      ],
    },
    ...(properties ?? []).map((property) => {
      let options = property.options ?? [];
      if (property?.label === 'Contact') {
        options = [
          ...options,
          {
            id: 'location.country',
            label: 'Country',
            fieldId: 'country',
            dataType: 'user',
          },
        ];
      } else {
        // Add status and priority option for project types
        options = [
          ...options,
          {
            id: 'status',
            label: 'Status',
            fieldId: 'status',
            dataType: 'status',
            ticketType: property.key,
          },
          {
            id: 'priority',
            label: 'Priority',
            fieldId: 'priority',
            dataType: 'priority',
            ticketType: property.key,
          },
        ];
      }

      return {
        label: property.label,
        value: property.key,
        icon: property.icon ?? 'ticket',
        isProperty: true,
        options: options,
      };
    }),
  ];

  const propertiesFlat: any[] = dataTypeOptions.reduce(
    (accumulator, current) => {
      return accumulator.concat(current.options);
    },
    [],
  );

  const renderCreateCondition = (currentPredicate, pushInArray = false) => {
    return (
      <Select
        className={`dropdown-selection bot-condition-picker ${
          pushInArray && 'bot-condition-picker-main'
        }`}
        classNamePrefix="dropdown-selection"
        getOptionLabel={(option: any) => option?.label}
        getOptionValue={(option: any) => option?.id}
        placeholder="+ Add condition"
        isDisabled={false}
        components={{
          SingleValue: (option: any) => {
            if (!option.data.id) {
              return (
                <div className="outbound-select-event">+ Add condition</div>
              );
            }
            return <>{option.data.label}</>;
          },
          Option: (option: any) => {
            return (
              <div
                className="option option-list-item"
                onClick={() => {
                  if (option.selectOption) {
                    option.selectOption(option.data);
                  }
                }}
              >
                <i className={`fa-solid fa-${option.data.icon}`} />
                {option.data.label}
              </div>
            );
          },
        }}
        onChange={(option: any) => {
          const value = option?.id;

          let type = 'attribute';
          let operator;
          let val;

          if (
            value === 'during-office-hours' ||
            value === 'outside-office-hours'
          ) {
            type = 'office-hours';
            operator = 'is';
            val = value as any;
          }
          if (value === 'location.country') {
            type = 'user';
          }

          if (value === 'status' || value === 'priority') {
            type = 'ticket';
          }

          runInAction(() => {
            if (pushInArray) {
              predicate.predicates?.push({
                type: 'and',
                predicates: [
                  {
                    dataType: type,
                    attribute: value,
                    operator,
                    value: val,
                    ticketType: option?.ticketType,
                  },
                ],
              } as any);
            } else {
              currentPredicate.attribute = value;
              currentPredicate.dataType = type;
              currentPredicate.operator = operator;
              currentPredicate.value = val;
              currentPredicate.ticketType = option?.ticketType;
            }

            setToggle(!toggle);
          });
        }}
        options={dataTypeOptions}
      />
    );
  };

  const _buildAndOrSelector = (currentPredicate) => {
    return (
      <Select
        className="dropdown-selection dropdown-selection-predicate"
        classNamePrefix="dropdown-selection"
        getOptionLabel={(option: any) => option?.label}
        getOptionValue={(option: any) => option?.value}
        value={{
          label: currentPredicate.type.toUpperCase(),
          value: currentPredicate.type,
        }}
        components={{
          SingleValue: (option: any) => {
            return <>{option.data.label}</>;
          },
          Option: (option: any) => {
            return (
              <div
                className="option option-list-item"
                onClick={() => {
                  if (option.selectOption) {
                    option.selectOption(option.data.value);
                  }
                }}
              >
                <i className={`fa-solid fa-${option.data.icon}`} />
                {option.data.label}
              </div>
            );
          },
        }}
        onChange={(value: any) => {
          runInAction(() => {
            currentPredicate.type = value;

            setToggle(!toggle);
          });
        }}
        options={[
          { label: 'AND', value: 'and' },
          { label: 'OR', value: 'or' },
        ]}
      />
    );
  };

  const buildChildConditions = (childPredicate, parentIndex) => {
    return childPredicate.predicates?.map((predicateChild, index) => {
      if (predicateChild?.dataType == null) {
        return renderCreateCondition(predicateChild);
      }

      return (
        <div
          className="child-condition-item"
          key={`bot-condition-tag-${index}`}
        >
          <BotConditionTag
            predicate={predicateChild}
            showAddCondition={index + 1 === childPredicate.predicates?.length}
            properties={propertiesFlat}
            onAddCondition={() => {
              runInAction(() => {
                childPredicate.predicates?.push({} as any);

                setToggle(!toggle);
              });
            }}
            onRemove={() => {
              runInAction(() => {
                if (predicate.predicates!.length > 1) {
                  childPredicate.predicates?.splice(index, 1);
                } else {
                  childPredicate.predicates[index] = {}; // Reset the predicate
                }

                // If predicate array is empty, remove it
                if (
                  childPredicate.predicates?.length === 0 &&
                  predicate.predicates!.length > 1
                ) {
                  predicate.predicates?.splice(parentIndex, 1);
                }

                setToggle(!toggle);
              });
            }}
          />
          {index + 1 !== childPredicate.predicates?.length &&
            _buildAndOrSelector(childPredicate)}
        </div>
      );
    });
  };

  const buildRootCondition = () => {
    return predicate.predicates?.map((predicateChild, index) => {
      return (
        <div className="bot-root-conditions mr-10" key={`root-child-${index}`}>
          <div className="bot-root-conditions-wrapper">
            <div className="bot-child-predicates">
              {buildChildConditions(predicateChild, index)}
            </div>
            {index + 1 !== predicate.predicates?.length && (
              <div className="ml-15"> {_buildAndOrSelector(predicate)}</div>
            )}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="bot-condition-picker">
      <div className="if-preview">if</div>
      <div className="condition-picker-conditions">
        {buildRootCondition()}
        {predicate.predicates != null &&
          predicate.predicates!.length > 0 &&
          predicate.predicates![0].predicates != null &&
          predicate.predicates![0].predicates!.length > 0 &&
          predicate.predicates![0].predicates![0].dataType != null &&
          renderCreateCondition(predicate, true)}
      </div>
    </div>
  );
};

export default BotConditionPicker;
