import Switch from 'react-switch';
import './AvailabilityToggle.scss';

interface AvailabilityToggleProps {
  availabilityChanged: (available: boolean) => void;
  userAvailable: boolean;
}

// eslint-disable-next-line no-empty-pattern
export const AvailabilityToggle = ({
  availabilityChanged,
  userAvailable,
}: AvailabilityToggleProps) => {
  return (
    <div className="available-container">
      <div className="available-title">{userAvailable ? 'Available' : '⛔️ Unavailable'}</div>
      <div className="available-toggle">
        <Switch
          width={40}
          onColor="#03C108"
          height={20}
          checkedIcon={false}
          uncheckedIcon={false}
          onChange={(checked) => {
            availabilityChanged(checked);
          }}
          checked={userAvailable}
        />
      </div>
    </div>
  );
};
