import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import './ActionPopover.scss';
import { useClickedOutside } from 'services/Helper';
import classNames from 'classnames';

interface ActionPopoverProps {
  child: React.ReactNode;
  children: React.ReactNode;
  position?: 'top' | 'bottom';
  onOpen?: () => void;
  className?: string;
  ignoreOnClick?: boolean;
}

const ActionPopover = forwardRef(
  (
    {
      child,
      children,
      position,
      onOpen,
      className,
      ignoreOnClick = false,
    }: ActionPopoverProps,
    ref,
  ) => {
    const [showActions, setShowActions] = useState(false);

    const wrapperRef = useRef(null);

    useClickedOutside(wrapperRef, () => {
      setShowActions(false);
    });

    useImperativeHandle(ref, () => ({
      close: () => {
        setShowActions(false);
      },
    }));

    useEffect(() => {
      if (showActions && onOpen) {
        onOpen();
      }
    }, [showActions]);

    const actionPopoverClassName = classNames(
      {
        'action-popover': true,
      },
      className,
    );

    const actionPopoverActionClassName = classNames({
      'action-popover-actions': true,
      'action-popover-actions--top': position === 'top',
      'action-popover-actions--bottom': position === 'bottom',
    });

    return (
      <div className={actionPopoverClassName} ref={wrapperRef}>
        {showActions && (
          <div className={actionPopoverActionClassName}>{children}</div>
        )}
        <div
          className="action-popover-child"
          onClick={() => {
            if (ignoreOnClick) return;

            setShowActions(!showActions);
          }}
        >
          {child}
        </div>
      </div>
    );
  },
);

export default ActionPopover;
