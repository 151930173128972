import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import TextInput from 'components/TextInput/TextInput';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ProjectStore } from 'stores/private/ProjectStore';
import ARROW from 'assets/icons/np_arrow_3542562_000000.svg';
import GLEAP_LOGO from 'assets/icons/Gleap.svg';
import SALESFORCE_LOGO from 'assets/icons/salesforce_logo.jpg';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContainer from 'components/PageContainer/PageContainer';
import { getAuthCodeFromSalesforce } from 'services/integrations/SalesforceHttpService';

interface SalesforceConnectConfigurationProps {
  projectStore?: ProjectStore;
}

const SalesforceConnectConfiguration = ({
  projectStore,
}: SalesforceConnectConfigurationProps) => {
  // @ts-ignore
  const { projectId } = useParams();
  const navigate = useNavigate();

  const [baseUrl, setBaseUrl] = useState('');

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
  }, []);

  const connectSalesforce = async () => {
    getAuthCodeFromSalesforce(baseUrl, projectId);
  };

  return (
    <PageContainer>
      <PageHeadLine
        title="Salesforce Integration"
        onActionBack={() => {
          navigate(`/projects/${projectId}/integrations`);
        }}
      />
      <PageContent hasTitle isMediumBoxed>
        <div className="choose-integation-action-container">
          <div className="integration-info-content-header">
            <img
              className="integration-preview-image"
              src={GLEAP_LOGO}
              alt="Gleap logo"
            />
            <img className="arrow-icon" src={ARROW} alt="Integrations" />
            <img
              className="integration-preview-image"
              src={SALESFORCE_LOGO}
              alt="Salesforce logo"
            />
          </div>
          <div className="row-container mb-15">
            <TextInput
              error=""
              placeholder="e.g. https://gleapgmbh-dev-ed.develop.lightning.force.com"
              label="Salesforce url"
              initalValue={baseUrl}
              onChange={(val) => {
                setBaseUrl(val);
              }}
            />
          </div>
          <SizedContainer size={ContainerSizes.M}>
            <PrimaryButton label="Connect" onClick={connectSalesforce} />
          </SizedContainer>
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore')(observer(SalesforceConnectConfiguration));
