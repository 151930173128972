/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useState } from 'react';
import './LoginForm.scss';
import { useNavigate } from 'react-router-dom';
import { inject } from 'mobx-react';
import { TextHref } from 'components/TextHref/TextHref';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { UsersStore } from 'stores/private/UsersStore';
import TextInput from 'components/TextInput/TextInput';
import SocialLoginButton from 'components/SocialLoginButton/SocialLoginButton';
import { SocialButtonType } from 'models/Enums';

interface LoginFormProps {
  usersStore?: UsersStore;
}

const LoginForm = ({ usersStore }: LoginFormProps) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showFullSignIn, setShowFullSignIn] = useState(false);
  const navigate = useNavigate();

  const login = () => {
    if (email && password) {
      usersStore?.login(email, password);
    }
  };

  const renderEmailForm = () => {
    return (<div className="login-form full-width">
      <TextInput
        placeholder="E-Mail*"
        type="text"
        name="email"
        autoComplete="email"
        className="mb-20"
        error=""
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            login();
          }
        }}
        onChange={(val) => {
          setEmail(val);
          setShowFullSignIn(true);
        }}
        initalValue={email}
        required
      />
      <TextInput
        name="password*"
        placeholder="Password"
        type="password"
        error=""
        autoComplete="current-password"
        initalValue={password}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            login();
          }
        }}
        onChange={(val) => {
          setPassword(val);
        }}
        required
      />
      <div className="btn-container mt-20">
        <PrimaryButton
          fullWidth
          className="login-button"
          disabled={email.length === 0 || password.length === 0}
          icon="arrow-right"
          iconSideRight
          onClick={() => {
            login();
          }}
          label="Login"
        />
      </div>
    </div>);
  }

  return (
    <div className="login-form-container">
      {showFullSignIn && <i className={`icon fa-solid fa-chevron-left back-button`} onClick={() => setShowFullSignIn(false)} />}
      {!showFullSignIn && (
        <>
          <div className="social-container-button">
            <SocialLoginButton
              title="Login with Google"
              type={SocialButtonType.GOOGLE}
            />
            <div className='signup-or'>
              <span>OR</span>
            </div>
            <PrimaryButton icon='at' iconSideRight={false} fullWidth className="mt-10" label="Login with email" onClick={() => {
              setShowFullSignIn(true);
            }} />
          </div>
        </>
      )}
      {showFullSignIn && renderEmailForm()}
      <div className="btn-container">
        <TextHref
          className="href mt-20 mb-0 text"
          label="Forgot password?"
          onClick={() => {
            navigate('/forgetpassword');
          }}
        />
      </div>
      <TextHref
        className="href mt-20 text"
        label="Create an account"
        onClick={() => {
          navigate('/register');
        }}
      />
    </div>
  );
};

export default inject('usersStore')(LoginForm);
