import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import { getLanguageProperty } from 'helper/AssignObjectKeysHelper';
import { inject, observer } from 'mobx-react';
import { Outbound } from 'models/Outbound';
import React, { useEffect, useState } from 'react';
import { ProjectStore } from 'stores/private/ProjectStore';
import gleapIcon from 'assets/icons/GleapIcon.svg';
import './PushNotificationConfiguration.scss';
import { convertTipTapToPlainText } from 'helper/TipTapHelper';

interface PushNotificationPreviewProps {
  outboundRule: Outbound;
  projectStore?: ProjectStore;
}

const PushNotificationPreview = ({
  outboundRule,
  projectStore,
}: PushNotificationPreviewProps) => {
  const [message, setMessage] = useState('');

  const currentProject = projectStore?.currentProject;
  const currentLang = projectStore?.currentLanguage ?? 'en';

  useEffect(() => {
    if (outboundRule?.message) {
      var textHtml = '';
      try {
        textHtml = convertTipTapToPlainText({
          content: getLanguageProperty(outboundRule, 'message', currentLang)
            .message,
        }) as string;
      } catch (exp) {}

      setMessage(textHtml);
    }
  }, [outboundRule, outboundRule?.message, currentLang]);

  return (
    <div className="iphone">
      <div className="iphone-14">
        <div className="operator-name">Gleap</div>
        <div className="dynamic-island"></div>
        <div className="dynamic-island-camera"></div>
        <div className="operator-icons">
          <i className="fa-solid fa-signal-bars" /> 5G
          <div className="battery" />
        </div>
        <div className="date-area">
          <span className="month">August Wednesday 23</span>
        </div>

        <div className="clock-area">12:10</div>
        <div className="widget-area">
          <span className="widget">
            <i className="fa-solid fa-cloud-moon" />
          </span>
          <span className="widget bell">
            <i className="fa-solid fa-bell" />
          </span>

          <span className="widget">
            <i className="fa-brands fa-spotify" />
          </span>
          <span className="widget">
            <i className="fa-solid fa-gas-pump" />
          </span>
        </div>

        <div className="notification">
          <Row alignItems="center">
            <img
              className="notification-image"
              src={currentProject?.picture ?? gleapIcon}
              alt="Logo"
            />
            <Column justifyContent="flex-start">
              <div className="notification-title">
                {getLanguageProperty(outboundRule, 'subject', currentLang)}
              </div>
              <div className="notification-text">{message}</div>
            </Column>
          </Row>
        </div>
        <div className="flash">
          <i className="fa-solid fa-flashlight fa-rotate-270" />
        </div>
        <div className="camera">
          <i className="fa-solid fa-camera" />
        </div>
      </div>
    </div>
  );
};

export default inject('projectStore')(observer(PushNotificationPreview));
