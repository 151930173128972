import FeedbackUserFilter from 'components/FeedbackUserFilter/FeedbackUserFilter';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './AssignUserActionEditor.scss';
import FeedbackTeamFilter from 'components/FeedbackTeamFilter/FeedbackTeamFilter';
import InfoBox from 'components/InfoBox/InfoBox';

interface AssignUserActionEditorProps {
  action: any;
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
}

const AssignUserActionEditor = ({
  action,
  modalStore,
  projectStore,
}: AssignUserActionEditorProps) => {
  return (
    <div className="edit-feature-request-modal">
      <PageContainer>
        <PageHeadLine title="Assign ticket">
          <div className="header-content-right">
            <PrimaryButton
              label="Close"
              iconSideRight={false}
              onClick={() => {
                modalStore!.closeModal();
              }}
            />
          </div>
        </PageHeadLine>
        <PageContent hasTitle>
          <InfoBox className="mb-12">You can set either a user or a team, but not both simultaneously. To set both, you will need to perform two separate actions.</InfoBox>
          <div className="tagcontainer pl-0 pr-0">
            <div className="input-label">
              Choose a user to assign the conversation to
            </div>
            <FeedbackUserFilter
              key={action.processingUser}
              value={action.processingUser ?? null}
              truncatePreview={50}
              truncate={50}
              hasBorder
              placeholder="Select user"
              onValueChanged={(value) => {
                runInAction(() => {
                  action.processingUser = value;
                  action.processingTeam = null;
                });
              }}
            />
            <div className="input-label mt-20">
              OR choose a team to assign the conversation to
            </div>
            <FeedbackTeamFilter
              key={action.processingTeam}
              value={action.processingTeam ?? null}
              truncatePreview={50}
              truncate={50}
              hasBorder
              placeholder="Select team"
              onValueChanged={(value) => {
                runInAction(() => {
                  action.processingTeam = value;
                  action.processingUser = null;
                });
              }}
            />
          </div>
        </PageContent>
      </PageContainer>
    </div>
  );
};

export default inject(
  'modalStore',
  'projectStore',
)(observer(AssignUserActionEditor));
