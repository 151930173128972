import React, { useEffect, useState } from 'react';
import './FeatureRequestUpvoteItem.scss';
import { Bug } from 'models/Bug';
import { inject, observer } from 'mobx-react';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import { BugStore } from 'stores/private/BugStore';
import ConfettiCanon from 'components/ConfettiCanon/ConfettiCanon';
import classNames from 'classnames';
import { getFormFieldValue } from 'helper/FormDataHelper';
import { SharedSessionStore } from 'stores/public/SharedSessionStore';
import { runInAction } from 'mobx';

interface FeatureRequestUpvoteItemProps {
  featureRequest: Bug;
  modalStore?: ModalStore;
  bugStore?: BugStore;
  sharedSessionStore?: SharedSessionStore;
}

const FeatureRequestUpvoteItem = ({
  featureRequest,
  modalStore,
  bugStore,
  sharedSessionStore,
}: FeatureRequestUpvoteItemProps) => {
  const [confetti, setConfetti] = useState(false);
  const [hasUpvoted, setHasUpvoted] = useState(false);

  useEffect(() => {
    if (sharedSessionStore?.upvotes && featureRequest && featureRequest.id) {
      if (sharedSessionStore?.upvotes.includes(featureRequest.id)) {
        setHasUpvoted(true);
      } else {
        setHasUpvoted(false);
      }
    }
  }, [featureRequest, sharedSessionStore?.upvotes]);

  const featureRequestItemClass = classNames({
    'feature-request-card-upvotes': true,
    'feature-request-card-upvotes-selected': hasUpvoted,
  });

  const upvoteCallback = (upvoted) => {
    try {
      if (upvoted) {
        setConfetti(true);
        setTimeout(() => {
          setConfetti(false);
        }, 2000);
      }

      // Upvote count.
      runInAction(() => {
        featureRequest.upvotesCount =
          (featureRequest.upvotesCount ?? 0) + (upvoted ? 1 : -1);
      });

      sharedSessionStore!.updateUpvotes(upvoted, featureRequest.id);
      // eslint-disable-next-line no-empty
    } catch (exp) {}
  };

  return (
    <div
      id={`feature-request-${featureRequest.id}`}
      className={featureRequestItemClass}
      onClick={async (e) => {
        e.stopPropagation();

        if (sharedSessionStore?.session && sharedSessionStore?.session.email) {
          const upvotedResult = await bugStore!.voteBug(featureRequest);
          upvoteCallback(upvotedResult);
          return;
        }

        modalStore!.openModal(MODALTYPE.VOTE, {
          featureRequest,
          title: getFormFieldValue({ bug: featureRequest, key: 'title' }),
          callback: upvoteCallback,
        });
      }}
    >
      <i className="fa-solid fa-caret-up" />
      <div className="number">{featureRequest.upvotesCount ?? 0}</div>
      <ConfettiCanon showConfetti={confetti} />
    </div>
  );
};

export default inject(
  'modalStore',
  'bugStore',
  'sharedSessionStore',
)(observer(FeatureRequestUpvoteItem));
