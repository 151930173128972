import { Title } from 'components/Title/Title';
import { inject, observer } from 'mobx-react';
import ReactJson from 'react-json-view';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import './ShowEventDataModal.scss';

interface ShowEventDataModalProps {
  modalStore?: ModalStore;
}

const ShowEventDataModal = ({ modalStore }: ShowEventDataModalProps) => {
  const customData = modalStore?.getCustomData(MODALTYPE.SHOW_EVENT_DATA);

  return (
    <div className="show-event-container">
      <Title label="Event details" className="mb-30" />
      <ReactJson
        theme={
          localStorage.getItem('theme') === 'dark'
            ? 'brewer'
            : 'shapeshifter:inverted'
        }
        src={{
          name: customData?.name,
          data: customData?.data,
        }}
        style={{
          background: 'transparent',
        }}
      />
    </div>
  );
};

export default inject('modalStore')(observer(ShowEventDataModal));
