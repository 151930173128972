import { inject, observer } from 'mobx-react';
import { BugStore } from 'stores/private/BugStore';
import './BugDetail.scss';
import Details from './Details/Details';

interface BugDetailProps {
  bugStore?: BugStore;
  shared?: boolean;
}

const BugDetail = ({
  bugStore,
  shared
}: BugDetailProps) => {
  const bug = bugStore?.currentBug;
  const isInbox = bug && bug.type === "INQUIRY";

  return (<div className='bug-detail-container'>
    <Details shared={shared} isInbox={isInbox} />
  </div>);
};

export default inject(
  'projectStore',
  'bugStore',
)(observer(BugDetail));
