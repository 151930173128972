import GleapBotAI from 'assets/GleapBotAi.png';
import { HeadLine } from 'components/HeadLine/HeadLine';
import InfoBox from 'components/InfoBox/InfoBox';
import LinkButton from 'components/LinkButton/LinkButton';
import LoadingAnimationMedium from 'components/LoadingAnimationMedium/LoadingAnimationMedium';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './ContentSuggestionsComponent.scss';
import { runInAction } from 'mobx';

interface ContentSuggestionsComponentProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

const ContentSuggestionsComponent = ({ projectStore, modalStore }: ContentSuggestionsComponentProps) => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const currentProject = projectStore?.currentProject;

  const sendAnswer = async (id, data) => {
    if (!projectStore?.currentProject?.id) {
      return null;
    }

    if (projectStore?.contentSuggestionsCount > 0) {
      runInAction(() => {
        projectStore!.contentSuggestionsCount--;
      });
    }

    await projectStore?.updateQAAnswer(projectStore.currentProject?.id, id, data);
    modalStore!.closeModal();
  };

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    if (currentProject) {
      projectStore!.getQAAnswers(currentProject.id, true, 0, 200, true);
    }
  }, [currentProject]);

  if (!currentProject) {
    return <></>;
  }

  const answers = projectStore?.currentQAAnswers.filter((answer) => {
    if (answer.skipped || answer.acknowledged) {
      return false;
    }

    return true;
  }) || [];

  const renderAnswers = () => {
    if (projectStore.loadingQAAnswers) {
      return <div className='loading'>
        <LoadingAnimationMedium />
      </div>;
    }

    if (answers.length === 0) {
      return (
        <div className="no-surveys-container mt-100">
          <img
            src={GleapBotAI}
            alt="No surveys created yet"
            className="no-surveys-image"
          />
          <>
            <HeadLine
              className="mt-20"
              title="No content suggestions yet"
              subtitle="As soon as Kai has learned from your conversations, he will suggest FAQs here."
            />
          </>
        </div>
      );
    }

    return (<>
      <InfoBox className="mb-30">
        Kai is scanning through your conversations and will suggest FAQs that are relevant to your users. Simply start by answering your users questions and Kai will learn from it.<br /><br />
        Please make sure that you have <a href="#" onClick={() => {
          navigate(`/projects/${projectId}/settings/aiassist`);
        }}>enabled AI based FAQ suggestions</a>.
      </InfoBox>
      {answers.map((answer) => {
        return (<div className='qanda-box' key={answer.id}>
          <div className='content'>
            <div className="question">
              {answer.question}
            </div>
            <div className="answer">
              {answer.answer}
            </div>
          </div>
          <div className="actions">
            <LinkButton
              onClick={() => {
                sendAnswer(answer.id, { skipped: true });
              }}
              className="mr-10"
              label="Skip"
            />
            <PrimaryButton
              small
              onClick={() => {
                modalStore!.openModal(MODALTYPE.EDIT_QA, {
                  answer: answer,
                });
              }}
              label="Add to FAQ"
            />
          </div>
        </div>);
      })}
    </>);
  }

  return renderAnswers();
};

export default inject('projectStore', 'modalStore')(observer(ContentSuggestionsComponent));
