import LinkButton from 'components/LinkButton/LinkButton';
import LoadingAnimation from 'components/LoadingAnimation/LoadingAnimation';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SizedContainer, {
  ContainerSizes
} from 'components/SizedContainer/SizedContainer';
import Gleap from 'gleap';
import { inject, observer } from 'mobx-react';
import PLATFORM from 'models/Platform';
import { useEffect } from 'react';
import { BugStore } from 'stores/private/BugStore';
import './VerifyInstallation.scss';
import { ProjectStore } from 'stores/private/ProjectStore';
import { ModalStore } from 'stores/private/ModalStore';

const VerifyInstallation = ({
  closeModal,
  bugStore,
  platform,
  showPrev,
  projectStore,
  modalStore,
}: {
  closeModal: () => void;
  bugStore?: BugStore;
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
  platform?: PLATFORM;
  showPrev?: () => void;
}) => {
  useEffect(() => {
    bugStore!.setReportedBug(false);
  }, []);

  const isWeb = () => {
    if (
      platform === PLATFORM.ANDROID ||
      platform === PLATFORM.IOS ||
      platform === PLATFORM.FLUTTER ||
      platform === PLATFORM.REACTNATIVE
    ) {
      return false;
    }

    return true;
  };

  return (
    <div className="but-report-waiting">
      <div className="but-report-waiting-info">
        <div className="title">Verify installation</div>
        <div className="verify-installation-side-by-side">
          <div>
            <div className="paragraph">
              To complete the setup, <b>build & run</b> your app or website with the SDK installed.<br />
              <br />
              <b>Need help?</b> <a href="#" onClick={() => {
                Gleap.open();
              }}>Chat with us</a>.
            </div>
          </div>
        </div>
        {(bugStore?.reportedBug || projectStore?.currentProject?.sdkInstalled) ? (
          <SizedContainer size={ContainerSizes.S} className="mt-15 mb-15">
            <PrimaryButton label="Done 🎉" onClick={() => {
              modalStore?.closeModal();
              closeModal();
            }} />
            <div className="mt-20">✅ Good job, well done</div>
          </SizedContainer>
        ) : (
          <div>
            <div className="waiting-for-report">
              <LoadingAnimation />
              <div className="waiting-title">
                Waiting for the SDK to say hello 👋
              </div>
            </div>
            <div>
              <LinkButton
                className="hide-on-mobile"
                label="Back"
                onClick={showPrev}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default inject('bugStore', 'projectStore', 'modalStore')(observer(VerifyInstallation));
