import BugTypeSelection from 'components/BugTypeSelection/BugTypeSelection';
import FeedbackActionQuestionEditor from 'components/FeedbackActionQuestionEditor/FeedbackActionQuestionEditor';
import Filter from 'components/Filter/Filter';
import InfoBox from 'components/InfoBox/InfoBox';
import LinkButton from 'components/LinkButton/LinkButton';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import {
  getLanguageProperty,
  setLanguageProperty,
} from 'helper/AssignObjectKeysHelper';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Switch from 'react-switch';
import { ProjectStore } from 'stores/private/ProjectStore';
import { SidenavStore } from 'stores/private/SidenavStore';
import Swal from 'sweetalert2';
import './FeedbackActionEditorItem.scss';
import FeedbackActionEditorItemSwitch from './FeedbackActionEditorItemSwitch';

const keysToExclude = [
  {
    key: 'customData',
    title: 'Custom data',
  },
  {
    key: 'metaData',
    title: 'Environment data',
  },
  {
    key: 'consoleLog',
    title: 'Console log',
  },
  {
    key: 'networkLogs',
    title: 'Network logs',
  },
  {
    key: 'customEventLog',
    title: 'Custom event log',
  },
  {
    key: 'screenshot',
    title: 'Screenshot',
  },
  {
    key: 'replays',
    title: 'Replays',
  },
  {
    key: 'attachments',
    title: 'Attachments',
  },
];

const FeedbackActionEditorItem = ({
  projectStore,
  sidenavStore,
}: {
  projectStore?: ProjectStore;
  sidenavStore?: SidenavStore;
}) => {
  const currentLang = projectStore?.currentLanguage ?? 'en';

  const navigate = useNavigate();
  const { projectId, feedbackFlowId } = useParams();
  const [toggle, setToggle] = useState(false);
  const [currentPage, setCurrentPage] = useState('questions');

  useEffect(() => {
    runInAction(() => {
      sidenavStore!.sidenavHidden = true;
      sidenavStore!.mainSidenavHidden = true;
    });

    return () => {
      runInAction(() => {
        sidenavStore!.sidenavHidden = false;
        sidenavStore!.mainSidenavHidden = false;
      });
    };
  }, []);

  const feedbackActions = projectStore?.currentProject?.projectActions;
  const feedbackType = (feedbackActions ?? {})[feedbackFlowId ?? ''];

  useEffect(() => {
    if (feedbackType) {
      for (let i = 0; i < feedbackType.form.length; i++) {
        feedbackType.form[i].key = Math.random().toString(36).substring(7);
      }
      setToggle(!toggle);
    }
  }, [feedbackType]);

  if (!feedbackActions || !feedbackFlowId) {
    return null;
  }

  const renderDataExclusionEditor = (feedbackTypeItem: any) => {
    if (!feedbackTypeItem.excludeData) {
      feedbackTypeItem.excludeData = {};
    }

    return (
      <div className="fulltabs-page">
        <div className="fulltabs-content-page">
          <div className="input-label">Exclude data</div>
          <div className="data-exclusion-form-container">
            <div className="mb-20 text">
              Enable all fields you want to strip off from the feedback
              submission for this action.
            </div>
            {keysToExclude.map((exclusionKey) => {
              return (
                <FeedbackActionEditorItemSwitch
                  key={exclusionKey.key}
                  exclusionKey={exclusionKey}
                  feedbackTypeItem={feedbackTypeItem}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderFormEditor = () => {
    return (
      <div className="form-options-editor">
        <FeedbackActionQuestionEditor feedbackAction={feedbackType} />
      </div>
    );
  };

  const renderGeneralEditor = () => {
    return (
      <div className="fulltabs-page">
        <div className="fulltabs-content-page">
          <div className="input-label">Feedback type</div>
          <div className="feedback-type-selection">
            <BugTypeSelection
              all
              value={feedbackType.feedbackType ?? 'BUG'}
              onValueChanged={(selected) => {
                feedbackType.feedbackType = selected.value;
              }}
            />
          </div>
          <div className="mt-20">
            <div className="input-label linespacerwithborder">
              Show conversation after submission
            </div>
            <div className="switch-container mb-20">
              <Switch
                  width={40}
                  onColor="#2142E7"
                  height={20}
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={(checked) => {
                  feedbackType.showConversation = checked;
                  setToggle(!toggle);
                }}
                checked={feedbackType.showConversation ?? false}
              />
              <span>Show conversation after submission</span>
            </div>
          </div>
          <div className="mt-20">
            <div className="input-label linespacerwithborder">
              Screenshot & screen recording
            </div>
            <div className="switch-container mb-20 mt-20">
              <Switch
                  width={40}
                  onColor="#2142E7"
                  height={20}
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={(checked) => {
                  feedbackType.disableUserScreenshot = !checked;
                  if (feedbackType.excludeData?.screenshot) {
                    feedbackType.excludeData.screenshot = false;
                  }

                  setToggle(!toggle);
                }}
                checked={!feedbackType.disableUserScreenshot}
              />
              <span>Enable screenshot editor</span>
            </div>
            {!feedbackType.disableUserScreenshot && (
              <div className="fullwidth mb-20">
                <TextInput
                  name="Screenshot button title"
                  localizedable
                  placeholder=""
                  type="text"
                  error=""
                  value={
                    getLanguageProperty(
                      feedbackType,
                      'screenshotTitle',
                      currentLang,
                    ) ?? 'Mark the bug'
                  }
                  translationObject={feedbackType?.screenshotTitle}
                  label="Screenshot button title"
                  onChange={(text) => {
                    setLanguageProperty(
                      feedbackType,
                      'screenshotTitle',
                      currentLang,
                      text,
                    );

                    setToggle(!toggle);
                  }}
                />
              </div>
            )}
            <div className="switch-container mb-20 mt-30">
              <Switch
                  width={40}
                  onColor="#2142E7"
                  height={20}
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={(checked) => {
                  feedbackType.enableUserScreenRecording = checked;
                  setToggle(!toggle);
                }}
                checked={!!feedbackType.enableUserScreenRecording}
              />
              <span>Enable screen recording</span>
            </div>
            {!!feedbackType.enableUserScreenRecording && (
              <>
                <div className="fullwidth">
                  <TextInput
                    name="Screen recording button title"
                    localizedable
                    placeholder=""
                    type="text"
                    error=""
                    value={
                      getLanguageProperty(
                        feedbackType,
                        'captureTitle',
                        currentLang,
                      ) ?? 'Record screen'
                    }
                    translationObject={feedbackType?.captureTitle}
                    label="Screen recording button title"
                    onChange={(text) => {
                      setLanguageProperty(
                        feedbackType,
                        'captureTitle',
                        currentLang,
                        text,
                      );

                      setToggle(!toggle);
                    }}
                  />
                </div>
                <InfoBox className="mt-20">
                  <>
                    The screen recording is only available for HTTPS websites.
                  </>
                </InfoBox>
              </>
            )}
          </div>
          <div className="mt-20">
            <div className="input-label linespacerwithborder">
              Collect email
            </div>
            <div className="switch-container mb-20">
              <Switch
                  width={40}
                  onColor="#2142E7"
                  height={20}
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={(checked) => {
                  feedbackType.collectEmail = checked;
                  setToggle(!toggle);
                }}
                checked={
                  feedbackType.collectEmail === undefined ||
                  feedbackType.collectEmail === true
                }
              />
              <span>Collect email addresses of users</span>
            </div>
          </div>
          {!feedbackType.chatStyle && (
            <>
              <div className="mt-20">
                <div className="input-label linespacerwithborder">
                  Single page form
                </div>
                <div className="switch-container mb-20">
                  <Switch
                  width={40}
                  onColor="#2142E7"
                  height={20}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={(checked) => {
                      feedbackType.singlePageForm = checked;
                      setToggle(!toggle);
                    }}
                    checked={feedbackType.singlePageForm ?? false}
                  />
                  <span>Show all questions on one page</span>
                </div>
              </div>
              <div className="input-label mt-20">Progress bar</div>
              <div className="switch-container mb-20">
                <Switch
                  width={40}
                  onColor="#2142E7"
                  height={20}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={(checked) => {
                    runInAction(() => {
                      feedbackType.showProgressBar = checked;
                      setToggle(!toggle);
                    });
                  }}
                  checked={
                    feedbackType.showProgressBar === undefined ||
                    feedbackType.showProgressBar === true
                  }
                />
                <span>Show a progress bar on multiple questions</span>
              </div>
            </>
          )}
          <div className="mt-20">
            <div className="input-label linespacerwithborder">
              Privacy policy
            </div>
            <div className="switch-container mb-20 mt-10">
              <Switch
                  width={40}
                  onColor="#2142E7"
                  height={20}
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={(checked) => {
                  feedbackType.privacyPolicyEnabled = checked;
                  setToggle(!toggle);
                }}
                checked={feedbackType.privacyPolicyEnabled ?? false}
              />
              <span>Enable privacy policy check</span>
            </div>
            {feedbackType.privacyPolicyEnabled && (
              <div className="fullwidth">
                <TextInput
                  name="Privacy policy URL"
                  placeholder="https://..."
                  type="text"
                  className="mb-20"
                  error=""
                  initalValue={feedbackType.privacyPolicyUrl}
                  label="Privacy policy URL"
                  onChange={(text) => {
                    feedbackType.privacyPolicyUrl = text;
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const showOverview = () => {
    navigate(`/projects/${projectId}/bots/forms`);
  };

  return (
    <PageContainer className="page-centered-main-tabs">
      <PageHeadLine
          title={getLanguageProperty(feedbackType, 'title', currentLang)}
          isEditable={true}
          onChangeTitle={(val) => {
            setLanguageProperty(feedbackType, 'title', currentLang, val);
          }}
        onActionBack={() => {
          showOverview();
        }}
      >
        <div className="centered-main-tabs">
          <div className="main-tabs">
            <Filter
              onValueChange={(value) => {
                setCurrentPage(value);
              }}
              value={currentPage}
              options={[
                {
                  name: 'Questions',
                  value: 'questions',
                },
                { name: 'Options', value: 'options' },
                {
                  name: 'Exclude data',
                  value: 'excludedata',
                },
              ]}
            />
          </div>
        </div>
        <div className="form-widget-buttons header-content-right">
          {!feedbackActions![feedbackFlowId].default && (
            <LinkButton
              className="danger mr-5"
              label="Delete"
              onClick={() => {
                Swal.fire({
                  text: 'Are you sure you want to delete this flow?',
                  showCancelButton: true,
                  confirmButtonText: `Delete`,
                  denyButtonText: `Cancel`,
                }).then(async (result) => {
                  if (feedbackActions && result.isConfirmed) {
                    showOverview();

                    delete feedbackActions[feedbackFlowId];

                    projectStore!.updateProject(
                      projectStore!.currentProject!.id,
                      {
                        projectActions: feedbackActions,
                      },
                    );
                  }
                });
              }}
            />
          )}
          <PrimaryButton
            label="Save"
            onClick={() => {
              projectStore!.updateProject(projectStore!.currentProject!.id, {
                projectActions: feedbackActions,
              });

              showOverview();
            }}
          />
        </div>
      </PageHeadLine>
      <PageContent className="page-content--fulltabs" hasTitle>
        {currentPage === 'options' && renderGeneralEditor()}
        {currentPage === 'questions' && renderFormEditor()}
        {currentPage === 'excludedata' &&
          renderDataExclusionEditor(feedbackType)}
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'projectStore',
  'sidenavStore',
)(observer(FeedbackActionEditorItem));
