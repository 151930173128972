export const languages = [
  { code: 'aa', name: 'Afar' },
  { code: 'ab', name: 'Abkhazian' },
  { code: 'ae', name: 'Avestan' },
  { code: 'af', name: 'Afrikaans' },
  { code: 'ak', name: 'Akan' },
  { code: 'am', name: 'Amharic' },
  { code: 'an', name: 'Aragonese' },
  { code: 'ar', name: 'Arabic' },
  { code: 'ar-ae', name: 'Arabic (U.A.E.)' },
  { code: 'ar-bh', name: 'Arabic (Bahrain)' },
  { code: 'ar-dz', name: 'Arabic (Algeria)' },
  { code: 'ar-eg', name: 'Arabic (Egypt)' },
  { code: 'ar-iq', name: 'Arabic (Iraq)' },
  { code: 'ar-jo', name: 'Arabic (Jordan)' },
  { code: 'ar-kw', name: 'Arabic (Kuwait)' },
  { code: 'ar-lb', name: 'Arabic (Lebanon)' },
  { code: 'ar-ly', name: 'Arabic (Libya)' },
  { code: 'ar-ma', name: 'Arabic (Morocco)' },
  { code: 'ar-om', name: 'Arabic (Oman)' },
  { code: 'ar-qa', name: 'Arabic (Qatar)' },
  { code: 'ar-sa', name: 'Arabic (Saudi Arabia)' },
  { code: 'ar-sy', name: 'Arabic (Syria)' },
  { code: 'ar-tn', name: 'Arabic (Tunisia)' },
  { code: 'ar-ye', name: 'Arabic (Yemen)' },
  { code: 'as', name: 'Assamese' },
  { code: 'av', name: 'Avaric' },
  { code: 'ay', name: 'Aymara' },
  { code: 'az', name: 'Azerbaijani' },
  { code: 'ba', name: 'Bashkir' },
  { code: 'be', name: 'Belarusian' },
  { code: 'bg', name: 'Bulgarian' },
  { code: 'bh', name: 'Bihari' },
  { code: 'bi', name: 'Bislama' },
  { code: 'bm', name: 'Bambara' },
  { code: 'bn', name: 'Bengali' },
  { code: 'bo', name: 'Tibetan' },
  { code: 'br', name: 'Breton' },
  { code: 'bs', name: 'Bosnian' },
  { code: 'ca', name: 'Catalan' },
  { code: 'ce', name: 'Chechen' },
  { code: 'ch', name: 'Chamorro' },
  { code: 'co', name: 'Corsican' },
  { code: 'cr', name: 'Cree' },
  { code: 'cs', name: 'Czech' },
  { code: 'cu', name: 'Church Slavic' },
  { code: 'cv', name: 'Chuvash' },
  { code: 'cy', name: 'Welsh' },
  { code: 'da', name: 'Danish' },
  { code: 'de', name: 'German' },
  { code: 'de-at', name: 'German (Austria)' },
  { code: 'de-ch', name: 'German (Switzerland)' },
  { code: 'de-de', name: 'German (Germany)' },
  { code: 'de-li', name: 'German (Liechtenstein)' },
  { code: 'de-lu', name: 'German (Luxembourg)' },
  { code: 'dv', name: 'Divehi' },
  { code: 'dz', name: 'Dzongkha' },
  { code: 'ee', name: 'Ewe' },
  { code: 'el', name: 'Greek' },
  { code: 'en', name: 'English' },
  { code: 'en-au', name: 'English (Australia)' },
  { code: 'en-bz', name: 'English (Belize)' },
  { code: 'en-ca', name: 'English (Canada)' },
  { code: 'en-cb', name: 'English (Caribbean)' },
  { code: 'en-gb', name: 'English (United Kingdom)' },
  { code: 'en-ie', name: 'English (Ireland)' },
  { code: 'en-jm', name: 'English (Jamaica)' },
  { code: 'en-nz', name: 'English (New Zealand)' },
  { code: 'en-ph', name: 'English (Philippines)' },
  { code: 'en-tt', name: 'English (Trinidad and Tobago)' },
  { code: 'en-us', name: 'English (United States)' },
  { code: 'en-za', name: 'English (South Africa)' },
  { code: 'en-zw', name: 'English (Zimbabwe)' },
  { code: 'eo', name: 'Esperanto' },
  { code: 'es', name: 'Spanish' },
  { code: 'es-ar', name: 'Spanish (Argentina)' },
  { code: 'es-bo', name: 'Spanish (Bolivia)' },
  { code: 'es-cl', name: 'Spanish (Chile)' },
  { code: 'es-co', name: 'Spanish (Colombia)' },
  { code: 'es-cr', name: 'Spanish (Costa Rica)' },
  { code: 'es-do', name: 'Spanish (Dominican Republic)' },
  { code: 'es-ec', name: 'Spanish (Ecuador)' },
  { code: 'es-es', name: 'Spanish (Spain)' },
  { code: 'es-gt', name: 'Spanish (Guatemala)' },
  { code: 'es-hn', name: 'Spanish (Honduras)' },
  { code: 'es-mx', name: 'Spanish (Mexico)' },
  { code: 'es-ni', name: 'Spanish (Nicaragua)' },
  { code: 'es-pa', name: 'Spanish (Panama)' },
  { code: 'es-pe', name: 'Spanish (Peru)' },
  { code: 'es-pr', name: 'Spanish (Puerto Rico)' },
  { code: 'es-py', name: 'Spanish (Paraguay)' },
  { code: 'es-sv', name: 'Spanish (El Salvador)' },
  { code: 'es-us', name: 'Spanish (United States)' },
  { code: 'es-uy', name: 'Spanish (Uruguay)' },
  { code: 'es-ve', name: 'Spanish (Venezuela)' },
  { code: 'et', name: 'Estonian' },
  { code: 'eu', name: 'Basque' },
  { code: 'fa', name: 'Persian' },
  { code: 'ff', name: 'Fulah' },
  { code: 'fi', name: 'Finnish' },
  { code: 'fj', name: 'Fijian' },
  { code: 'fo', name: 'Faroese' },
  { code: 'fr', name: 'French' },
  { code: 'fr-be', name: 'French (Belgium)' },
  { code: 'fr-ca', name: 'French (Canada)' },
  { code: 'fr-ch', name: 'French (Switzerland)' },
  { code: 'fr-fr', name: 'French (France)' },
  { code: 'fr-lu', name: 'French (Luxembourg)' },
  { code: 'fr-mc', name: 'French (Monaco)' },
  { code: 'fy', name: 'Western Frisian' },
  { code: 'ga', name: 'Irish' },
  { code: 'gd', name: 'Scottish Gaelic' },
  { code: 'gl', name: 'Galician' },
  { code: 'gn', name: 'Guarani' },
  { code: 'gu', name: 'Gujarati' },
  { code: 'gv', name: 'Manx' },
  { code: 'ha', name: 'Hausa' },
  { code: 'he', name: 'Hebrew' },
  { code: 'hi', name: 'Hindi' },
  { code: 'ho', name: 'Hiri Motu' },
  { code: 'hr', name: 'Croatian' },
  { code: 'hr-ba', name: 'Croatian (Bosnia and Herzegovina)' },
  { code: 'hr-hr', name: 'Croatian (Croatia)' },
  { code: 'ht', name: 'Haitian' },
  { code: 'hu', name: 'Hungarian' },
  { code: 'hy', name: 'Armenian' },
  { code: 'hz', name: 'Herero' },
  { code: 'ia', name: 'Interlingua' },
  { code: 'id', name: 'Indonesian' },
  { code: 'ie', name: 'Interlingue' },
  { code: 'ig', name: 'Igbo' },
  { code: 'ii', name: 'Sichuan Yi' },
  { code: 'ik', name: 'Inupiaq' },
  { code: 'io', name: 'Ido' },
  { code: 'is', name: 'Icelandic' },
  { code: 'it', name: 'Italian' },
  { code: 'it-ch', name: 'Italian (Switzerland)' },
  { code: 'it-it', name: 'Italian (Italy)' },
  { code: 'iu', name: 'Inuktitut' },
  { code: 'ja', name: 'Japanese' },
  { code: 'jv', name: 'Javanese' },
  { code: 'ka', name: 'Georgian' },
  { code: 'kk', name: 'Kazakh' },
  { code: 'kl', name: 'Kalaallisut' },
  { code: 'km', name: 'Khmer' },
  { code: 'kn', name: 'Kannada' },
  { code: 'ko', name: 'Korean' },
  { code: 'kr', name: 'Kanuri' },
  { code: 'ks', name: 'Kashmiri' },
  { code: 'ku', name: 'Kurdish' },
  { code: 'kv', name: 'Komi' },
  { code: 'kw', name: 'Cornish' },
  { code: 'ky', name: 'Kirghiz' },
  { code: 'la', name: 'Latin' },
  { code: 'lb', name: 'Luxembourgish' },
  { code: 'lg', name: 'Ganda' },
  { code: 'li', name: 'Limburgish' },
  { code: 'ln', name: 'Lingala' },
  { code: 'lo', name: 'Lao' },
  { code: 'lt', name: 'Lithuanian' },
  { code: 'lu', name: 'Luba-Katanga' },
  { code: 'lv', name: 'Latvian' },
  { code: 'mg', name: 'Malagasy' },
  { code: 'mh', name: 'Marshallese' },
  { code: 'mi', name: 'Maori' },
  { code: 'mk', name: 'Macedonian' },
  { code: 'ml', name: 'Malayalam' },
  { code: 'mn', name: 'Mongolian' },
  { code: 'mr', name: 'Marathi' },
  { code: 'ms', name: 'Malay' },
  { code: 'ms-bn', name: 'Malay (Brunei)' },
  { code: 'ms-my', name: 'Malay (Malaysia)' },
  { code: 'mt', name: 'Maltese' },
  { code: 'my', name: 'Burmese' },
  { code: 'na', name: 'Nauru' },
  { code: 'nb', name: 'Norwegian Bokmål' },
  { code: 'nd', name: 'North Ndebele' },
  { code: 'ne', name: 'Nepali' },
  { code: 'ng', name: 'Ndonga' },
  { code: 'nl', name: 'Dutch' },
  { code: 'nl-be', name: 'Dutch (Belgium)' },
  { code: 'nl-nl', name: 'Dutch (Netherlands)' },
  { code: 'nn', name: 'Norwegian Nynorsk' },
  { code: 'no', name: 'Norwegian' },
  { code: 'nr', name: 'South Ndebele' },
  { code: 'nv', name: 'Navajo' },
  { code: 'ny', name: 'Chichewa' },
  { code: 'oc', name: 'Occitan' },
  { code: 'oj', name: 'Ojibwa' },
  { code: 'om', name: 'Oromo' },
  { code: 'or', name: 'Oriya' },
  { code: 'os', name: 'Ossetian' },
  { code: 'pa', name: 'Panjabi' },
  { code: 'pi', name: 'Pali' },
  { code: 'pl', name: 'Polish' },
  { code: 'ps', name: 'Pushto' },
  { code: 'pt', name: 'Portuguese' },
  { code: 'pt-br', name: 'Portuguese (Brazil)' },
  { code: 'pt-pt', name: 'Portuguese (Portugal)' },
  { code: 'qu', name: 'Quechua' },
  { code: 'rm', name: 'Romansh' },
  { code: 'rn', name: 'Rundi' },
  { code: 'ro', name: 'Romanian' },
  { code: 'ru', name: 'Russian' },
  { code: 'rw', name: 'Kinyarwanda' },
  { code: 'sa', name: 'Sanskrit' },
  { code: 'sc', name: 'Sardinian' },
  { code: 'sd', name: 'Sindhi' },
  { code: 'se', name: 'Northern Sami' },
  { code: 'sg', name: 'Sango' },
  { code: 'si', name: 'Sinhala' },
  { code: 'sk', name: 'Slovak' },
  { code: 'sl', name: 'Slovenian' },
  { code: 'sm', name: 'Samoan' },
  { code: 'sn', name: 'Shona' },
  { code: 'so', name: 'Somali' },
  { code: 'sq', name: 'Albanian' },
  { code: 'sr', name: 'Serbian' },
  { code: 'ss', name: 'Swati' },
  { code: 'st', name: 'Southern Sotho' },
  { code: 'su', name: 'Sundanese' },
  { code: 'sv', name: 'Swedish' },
  { code: 'sw', name: 'Swahili' },
  { code: 'ta', name: 'Tamil' },
  { code: 'te', name: 'Telugu' },
  { code: 'tg', name: 'Tajik' },
  { code: 'th', name: 'Thai' },
  { code: 'ti', name: 'Tigrinya' },
  { code: 'tk', name: 'Turkmen' },
  { code: 'tl', name: 'Tagalog' },
  { code: 'tn', name: 'Tswana' },
  { code: 'to', name: 'Tonga' },
  { code: 'tr', name: 'Turkish' },
  { code: 'ts', name: 'Tsonga' },
  { code: 'tt', name: 'Tatar' },
  { code: 'tw', name: 'Twi' },
  { code: 'ty', name: 'Tahitian' },
  { code: 'ug', name: 'Uighur' },
  { code: 'uk', name: 'Ukrainian' },
  { code: 'ur', name: 'Urdu' },
  { code: 'uz', name: 'Uzbek' },
  { code: 've', name: 'Venda' },
  { code: 'vi', name: 'Vietnamese' },
  { code: 'vo', name: 'Volapük' },
  { code: 'wa', name: 'Walloon' },
  { code: 'wo', name: 'Wolof' },
  { code: 'xh', name: 'Xhosa' },
  { code: 'yi', name: 'Yiddish' },
  { code: 'yo', name: 'Yoruba' },
  { code: 'za', name: 'Zhuang' },
  { code: 'zh', name: 'Chinese' },
  { code: 'zh-cn', name: 'Chinese (China)' },
  { code: 'zh-hk', name: 'Chinese (Hong Kong SAR)' },
  { code: 'zh-mo', name: 'Chinese (Macau SAR)' },
  { code: 'zh-sg', name: 'Chinese (Singapore)' },
  { code: 'zh-tw', name: 'Chinese (Taiwan)' },
  {
    code: 'zh_TW',
    name: 'Chinese (Taiwan LEGACY)',
  },
  { code: 'zu', name: 'Zulu' },
];
