import AggregationPicker from 'components/AggregationPicker/AggregationPicker';
import FutureFactChart from 'components/Charts/FactChart/FutureFactChart';
import FutureBarChart from 'components/Charts/StatisicBarChart/FutureStatisticBarChart';
import { formatAxisLabelForSeconds } from 'components/Charts/StatisicBarChart/StatisticBarChart';
import Wrap from 'components/LayoutComponents/WrapComponent/WrapComponent';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import SmartDateRangePicker from 'components/SmartDateRangePicker/SmartDateRangePicker';
import TicketTypeFilter from 'components/TicketTypeFilter/TicketTypeFilter';
import { inject, observer } from 'mobx-react';
import { BarChartStatisticTypes, FactTypes } from 'models/Chart';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import {
  getBarChartStatisic,
  getFactStatistic,
} from 'services/StatisticsService';
import { ProjectStore } from 'stores/private/ProjectStore';
import './CustomerSupport.scss';

interface CustomerSupportConversationsProps {
  projectStore?: ProjectStore;
}

const CustomerSupportConversations = ({
  projectStore,
}: CustomerSupportConversationsProps) => {
  const projectId = projectStore?.currentProject?.id;
  const [filterTypes, setFilterTypes] = useState([] as string[]);
  const [filterTags, setFilterTags] = useState([] as string[]);

  const [filter, setFilter] = useState('MEDIAN');

  const [startDate, setStartDate] = useState(
    moment().subtract(1, 'months').startOf('day') as Moment | null,
  );
  const [endDate, setEndDate] = useState(
    moment().endOf('day') as Moment | null,
  );

  const tagColors = projectStore?.currentProject?.feedbackTags.reduce(
    (acc, tag) => {
      acc[tag.label] = tag.color;
      return acc;
    },
    {},
  );

  return (
    <PageContainer>
      <PageHeadLine title="Tickets" tag="BETA">
        <div className="filter-bar hide-on-mobile">
          <AggregationPicker
            className="border-margin-right"
            setFilter={setFilter}
            filter={filter}
          />
          <SmartDateRangePicker
            startIndex={1}
            onDateChange={(start, end) => {
              setStartDate(start);
              setEndDate(end);
            }}
          />
        </div>
      </PageHeadLine>
      <PageContent hasTitle>
        <TicketTypeFilter
          filterTypes={filterTypes}
          setFilterTypes={setFilterTypes}
          selectedTags={filterTags}
          setSelectedTags={setFilterTags}
        />
        <Wrap>
          <FutureFactChart
            infoText="The New Tickets Count provides an exact tally of newly created tickets within a specified timeframe. This metric is essential for gauging incoming ticket volume, enabling a clear understanding of demand and workload related to customer inquiries or issues."
            width="33.33%"
            futureWithAbortion={async (controller) => {
              const res = await getFactStatistic({
                projectId: projectId,
                query: {
                  chartType: FactTypes.NEW_TICKETS_COUNT,
                  startDate: startDate?.toDate(),
                  endDate: endDate?.toDate(),
                  'data.type_in': filterTypes,
                  'data.tags_in': filterTags,
                },
                signal: controller.signal,
              });
              return res;
            }}
          />
          <FutureFactChart
            infoText="The Ticket Replies Count provides a comprehensive summary of the total number of replies across all tickets. This count is an aggregation of the 'repliesCount' property for each ticket, reflecting the extent of engagement and interaction within the ticketing system. This metric is crucial for understanding the overall communicative effort involved in addressing customer inquiries and issues."
            width="33.33%"
            futureWithAbortion={async (controller) => {
              const res = await getFactStatistic({
                projectId: projectId,
                query: {
                  chartType: FactTypes.TICKET_REPLIES_COUNT,
                  startDate: startDate?.toDate(),
                  endDate: endDate?.toDate(),
                  'data.type_in': filterTypes,
                  'data.tags_in': filterTags,
                },
                signal: controller.signal,
              });
              return res;
            }}
          />
          <FutureFactChart
            infoText="Count of tickets closed within the specified timeframe. Multiple closes are counted only one."
            width="33.33%"
            futureWithAbortion={async (controller) => {
              const res = await getFactStatistic({
                projectId: projectId,
                query: {
                  chartType: FactTypes.TICKET_CLOSE_COUNT,
                  startDate: startDate?.toDate(),
                  endDate: endDate?.toDate(),
                  'data.type_in': filterTypes,
                  'data.tags_in': filterTags,
                },
                signal: controller.signal,
              });
              return res;
            }}
          />
        </Wrap>
        <FutureBarChart
          infoText="The New Conversations Count provides a day-by-day breakdown of new tickets created, offering a detailed view of the daily influx of customer inquiries or issues. This method leverages a time-based grouping to present a chronological analysis, enabling a clear understanding of trends and patterns in the creation of new tickets. Such insights are invaluable for managing workflow and anticipating customer service demands."
          futureWithAbortion={async (controller) => {
            const res = await getBarChartStatisic({
              projectId: projectId,
              query: {
                chartType: BarChartStatisticTypes.NEW_TICKETS_COUNT,
                startDate: startDate?.toDate(),
                endDate: endDate?.toDate(),
                timezone: moment.tz.guess(),
                'data.type_in': filterTypes,
                'data.tags_in': filterTags,
              },
              signal: controller.signal,
            });
            return res;
          }}
        />
        <FutureBarChart
          infoText="The Tag-Based Daily Ticket Breakdown bar chart provides a clear, day-by-day view of new tickets categorized by tags, offering insights into the types of issues or inquiries that arise over time. By grouping tickets by both date and tag, this chart helps identify trends and patterns specific to each category, enabling more effective resource allocation and targeted responses to customer needs."
          futureWithAbortion={async (controller) => {
            const res = await getBarChartStatisic({
              projectId: projectId,
              query: {
                chartType:
                  BarChartStatisticTypes.TICKET_CREATION_GROUPED_BY_DATE_AND_TAGS,
                startDate: startDate?.toDate(),
                endDate: endDate?.toDate(),
                timezone: moment.tz.guess(),
                type: filterTypes,
                tags: filterTags,
              },
              signal: controller.signal,
            });
            return res;
          }}
          colors={tagColors}
          stacked
        />
        <FutureBarChart
          infoText="The Replies Sent Count provides a daily breakdown of the total number of replies sent in response to tickets. This metric is crucial for understanding the volume and distribution of replies over time, offering insights into the team's communication activity and efficiency in addressing customer queries. By breaking down this data on a day-to-day basis, it helps in monitoring and managing the team's responsiveness and engagement levels with customers."
          futureWithAbortion={async (controller) => {
            const res = await getBarChartStatisic({
              projectId: projectId,
              query: {
                chartType: BarChartStatisticTypes.REPLIES_COUNT,
                startDate: startDate?.toDate(),
                endDate: endDate?.toDate(),
                timezone: moment.tz.guess(),
                'data.type_in': filterTypes,
                'data.tags_in': filterTags,
              },
              signal: controller.signal,
            });
            return res;
          }}
        />
        <FutureBarChart
          infoText="Count of tickets closed within the specified timeframe. Multiple closes are counted only one."
          futureWithAbortion={async (controller) => {
            const res = await getBarChartStatisic({
              projectId: projectId,
              query: {
                chartType: BarChartStatisticTypes.TICKET_CLOSE_COUNT,
                startDate: startDate?.toDate(),
                endDate: endDate?.toDate(),
                timezone: moment.tz.guess(),
                'data.type_in': filterTypes,
                'data.tags_in': filterTags,
              },
              signal: controller.signal,
            });
            return res;
          }}
        />
        <FutureBarChart
          infoText="The Median Time to First Assignment metric measures the median amount of time it takes for a ticket to be assigned to the first agent after it's created. This median value helps gauge the efficiency of the ticket assignment process and provides insights into how quickly customer inquiries or issues are attended to by the support team. Monitoring this metric can aid in improving response times and customer satisfaction by identifying and addressing bottlenecks in the assignment workflow."
          formatter={formatAxisLabelForSeconds}
          future={getBarChartStatisic({
            projectId: projectId,
            query: {
              chartType: BarChartStatisticTypes.MEDIAN_TIME_TO_FIRST_ASSIGNMENT,
              startDate: startDate?.toDate(),
              endDate: endDate?.toDate(),
              timezone: moment.tz.guess(),
              'data.type_in': filterTypes,
              'data.tags_in': filterTags,
              aggsType: filter,
            },
          })}
        />
        <FutureBarChart
          infoText="This metric measures the median time it takes for a ticket to be closed after it has been assigned to the first agent. It provides insights into the efficiency of resolving customer inquiries or issues from the moment they are assigned to an agent until they are successfully closed. This data helps in monitoring and optimizing the ticket resolution process to enhance customer satisfaction and streamline support operations."
          formatter={formatAxisLabelForSeconds}
          future={getBarChartStatisic({
            projectId: projectId,
            query: {
              chartType: BarChartStatisticTypes.MEDIAN_TIME_TO_CLOSE,
              startDate: startDate?.toDate(),
              endDate: endDate?.toDate(),
              timezone: moment.tz.guess(),
              'data.type_in': filterTypes,
              'data.tags_in': filterTags,
              aggsType: filter,
            },
          })}
        />
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore')(observer(CustomerSupportConversations));
