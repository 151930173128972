export const trackUser = (userId, email) => {
  if ((window as any).dataLayer) {
    (window as any).dataLayer.push({
      user: {
        id: userId,
        email,
      },
    });

    return true;
  }

  return false;
};

export const trackEvent = (eventName, data?) => {
  if ((window as any).dataLayer) {
    (window as any).dataLayer.push({
      event: eventName,
      ...data,
    });

    return true;
  }

  return false;
};
