export const checkIntegrationData = ({
  project,
  integrationName,
  integrationID,
  actionIndex,
}) => {
  if (
    !project ||
    !project?.integrations ||
    !project?.integrations[integrationName] ||
    !Object.prototype.hasOwnProperty.call(
      project?.integrations[integrationName],
      integrationID,
    )
  ) {
    return false;
  }

  const currentIntegration =
    project.integrations[integrationName][integrationID];
  if (
    !currentIntegration?.actions ||
    typeof currentIntegration?.actions[actionIndex] === 'undefined'
  ) {
    return false;
  }

  return true;
};

export const getValidIntegrations = ({ feedbackItem, project }): any[] => {
  if (!feedbackItem?.integrations || !project || !project?.integrations) {
    return [];
  }

  const validIntegrations: any = [];
  const integrations = Object.keys(feedbackItem.integrations);

  for (let i = 0; i < integrations.length; i++) {
    const integration = feedbackItem.integrations[integrations[i]];
    const integrationKeys = Object.keys(integration);

    for (let j = 0; j < integrationKeys.length; j++) {
      const integrationItem = integration[integrationKeys[j]];
      if (!integrationItem.data?.url) {
        continue;
      }

      const projectIntegration = project.integrations[integrations[i]];
      const customIntegrationName =
        projectIntegration &&
        projectIntegration[integrationKeys[j]] &&
        projectIntegration[integrationKeys[j]].settings &&
        projectIntegration[integrationKeys[j]].settings?.label
          ? projectIntegration[integrationKeys[j]].settings?.label
          : integrationKeys[j];

      validIntegrations.push({
        label: integrations[i],
        name: customIntegrationName ?? '',
        url: integrationItem.data.url,
      });
    }
  }

  return validIntegrations;
};
