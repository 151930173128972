import UserAvatar from 'components/UserAvatar/UserAvatar';
import { getDataDescription } from 'helper/FormDataHelper';
import TimeAgo from 'react-timeago';
import { Bug, BugPriorities } from '../../models/Bug';
import './FeatureRequestViewItem.scss';
import moment from 'moment';
import FeatureRequestSubscribeItem from 'components/FeatureRequestSubscribeItem/FeatureRequestSubscribeItem';
import { formatDateYearLater } from 'helper/DateHelper';
import { PropertyStore } from 'stores/private/PropertyStore';
import { inject, observer } from 'mobx-react';

export function stripHtml(html) {
  let tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
}

interface FeatureRequestViewItemProps {
  item: Bug;
  onClick?: any;
  active?: boolean;
  propertyStore?: PropertyStore;
}

const FeatureRequestViewItem = ({
  item,
  onClick,
  active,
  propertyStore,
}: FeatureRequestViewItemProps) => {
  const dueDate = item.dueDate ? moment(item.dueDate) : null;
  const isDue =
    item.status !== 'DONE' && dueDate
      ? dueDate.isBefore(moment(), 'date')
      : false;
  const isDueToday =
    item.status !== 'DONE' && dueDate
      ? dueDate.isSame(moment(), 'date')
      : false;

  const renderDueDate = () => {
    if (!dueDate) {
      return null;
    }

    return (
      <div
        className={`due-date ${isDue && 'due-date--isdue'} ${
          isDueToday && 'due-date--isduetoday'
        }`}
      >
        <i className="fa-solid fa-clock-three" />
      </div>
    );
  };

  const renderCommentPreview = () => {
    return (
      <div className="feature-request-item-body">
        <div className="feature-request-item__date">
          {formatDateYearLater(item.createdAt)}, #{item.bugId} {renderDueDate()}
          <div className="bug-report-card__time">
            <TimeAgo
              date={item.lastNotification ?? item.createdAt}
              formatter={(value, unit, suffix) => {
                return `${value}${unit.charAt(0)}`;
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const isSnoozed = item.status === 'SNOOZED' && item.snoozedUntil;
  const unreadSnoozed =
    !isSnoozed && item.notificationsUnread && item.snoozedUntil;
  const unread = !isSnoozed && item.notificationsUnread && !item.snoozedUntil;

  return (
    <div
      className={`feature-request-item ${
        active ? 'feature-request-item--active' : ''
      } ${unread ? 'feature-request-item--unread' : ''} ${
        unreadSnoozed ? 'feature-request-item--unread-snoozed' : ''
      } ${isSnoozed ? 'feature-request-item--snoozed' : ''}`}
      onClick={() => {
        if (onClick) {
          onClick(item);
        }
      }}
    >
      <div className="feature-request-item-header">
        <div className="feature-request-item-header-userinfo">
          <div className="static-feature-request-head" onClick={() => {}}>
            <FeatureRequestSubscribeItem featureRequest={item} />
          </div>
          <div className="userinfo">
            <div className="first-row">
              <div className="userinfo__title">
                {item.title
                  ? item.title
                  : getDataDescription({
                      data: item,
                      maxLength: 100,
                      propertyStore,
                      feedbackType: item.type,
                    })}
              </div>
              <div
                className="feature-request-card__header__info__dot"
                style={{
                  backgroundColor: `${BugPriorities[item.priority]?.color}20`,
                  color: `${BugPriorities[item.priority]?.color}`,
                }}
              >
                {BugPriorities[item.priority]?.title}
              </div>
              <div className="feature-request-item-header-userinfo__dot">
                {item.processingUser && (
                  <div className="user-processing">
                    <UserAvatar
                      small
                      email={item.processingUser.email}
                      imageUrl={item.processingUser.profileImageUrl}
                    />
                  </div>
                )}
              </div>
            </div>
            {renderCommentPreview()}
          </div>
          {(isSnoozed || unreadSnoozed) && (
            <div className="snoozed-badge">
              {isSnoozed && (
                <span className="time">
                  {moment(item.snoozedUntil).format(
                    moment(item.snoozedUntil).isSame(moment(), 'day')
                      ? 'h:mm A'
                      : 'MMM DD',
                  )}
                </span>
              )}
              <i className="unread-snoozed-icon fa-solid fa-moon" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default inject('propertyStore')(observer(FeatureRequestViewItem));
