import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import { checkIntegrationData } from 'helper/Integration';
import { inject, observer } from 'mobx-react';
import { INTEGRATION } from 'models/Integration';
import { useEffect, useState } from 'react';
import { ZendeskIntegrationStore } from 'stores/integrations/ZendeskIntegrationStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './ZendeskConfiguration.scss';

interface ZendeskConfigurationProps {
  integrationID: string;
  index: number;
  zendeskIntegrationStore?: ZendeskIntegrationStore;
  projectStore?: ProjectStore;
  onChange: (data: any) => any;
}

const ZendeskConfiguration = ({
  integrationID,
  index,
  zendeskIntegrationStore,
  projectStore,
  onChange,
}: ZendeskConfigurationProps) => {
  const project = projectStore?.currentProject;

  const [currentIntegrationInfo, setCurrentIntegrationInfo] = useState(
    {} as any,
  );

  const organizations = zendeskIntegrationStore?.organizations;
  const brands = zendeskIntegrationStore?.brands;

  const [organizationID, setOrganizationID] = useState('');
  const [brandID, setBrandID] = useState('');

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    if (project && isMounted) {
      initialize();
    }
  }, [project, isMounted]);

  useEffect(() => {
    saveIntegrationInfo();
  }, [organizationID, brandID]);

  const initialize = async () => {
    zendeskIntegrationStore!.setIntegrationID(integrationID);
    await zendeskIntegrationStore!.getOrganizations();
    await zendeskIntegrationStore!.getBrands();

    if (
      !checkIntegrationData({
        project,
        integrationName: INTEGRATION.ZENDESK,
        integrationID,
        actionIndex: index,
      })
    ) {
      return;
    }

    const integrationInfo =
      project?.integrations?.zendesk[integrationID]?.actions[index]
        ?.integrationInfo;

    setCurrentIntegrationInfo(integrationInfo);
  };

  const saveIntegrationInfo = () => {
    const integrationInfo = {
      organizationID,
      brandID,
    };

    onChange(integrationInfo);
  };

  return (
    <div className="configuration-container mt-30">
      <SelectDropDown
        className="mb-15"
        label="Brand (optional)"
        labelPropertyName="name"
        valuePropertyName="id"
        selectedItem={brands.find(
          (element) => element.id === currentIntegrationInfo?.brandID,
        )}
        items={brands}
        onChange={(option) => {
          setBrandID(option.id);
        }}
      />
      <SelectDropDown
        className="mb-15"
        label="Organization (optional)"
        labelPropertyName="name"
        valuePropertyName="id"
        selectedItem={organizations.find(
          (element) => element.id === currentIntegrationInfo?.organizationID,
        )}
        items={organizations}
        onChange={(option) => {
          setOrganizationID(option.id);
        }}
      />
    </div>
  );
};

export default inject(
  'zendeskIntegrationStore',
  'projectStore',
)(observer(ZendeskConfiguration));
