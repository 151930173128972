export enum SocialButtonType {
  GOOGLE,
  GITHUB,
}

export enum Feature {
  REMOVE_BRANDING = 'removebranding',
  CONSOLE_LOG = 'consolelogs',
  SESSION_DATA = 'sessiondata',
  CUSTOM_DATA = 'customdata',
  REPLAYS = 'replays',
  SURVEYS = 'surveys',
  INTEGRATIONS = 'integrations',
}

export enum EmailMessageStyle {
  CHAT = 'CHAT',
  PLAIN = 'PLAIN',
}
