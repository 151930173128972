import axios from './Axios';

const unsubscribeSession = (
  gleapId: string,
  gleapHash: string,
  apiToken: string,
  unsubscribe: boolean,
) => {
  return axios.post(
    `/sessions/unsubscribe`,
    { unsubscribed: unsubscribe },
    {
      headers: {
        'api-token': apiToken,
        'gleap-id': gleapId,
        'gleap-hash': gleapHash,
      },
    },
  );
};

export { unsubscribeSession };
