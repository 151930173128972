import React, { useState } from 'react';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import './CalendarRangePicker.scss';
import moment, { Moment } from 'moment';

interface CalendarRangePickerProps {
  startDate: Moment;
  endDate: Moment;
  onDatesChange: (arg: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => void;
}

const CalendarRangePicker = ({
  startDate,
  endDate,
  onDatesChange,
}: CalendarRangePickerProps) => {
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    null,
  );

  const startDateId = 'range-picker-start-date';
  const endDateId = 'range-picker-end-date';

  const handleFocusChange = (focusedInput: FocusedInputShape | null) => {
    setFocusedInput(focusedInput);
  };

  return (
    <DateRangePicker
      minDate={moment().subtract(4, 'years')}
      maxDate={moment()}
      startDate={startDate}
      endDate={endDate}
      onDatesChange={onDatesChange}
      startDateId={startDateId}
      endDateId={endDateId}
      focusedInput={focusedInput}
      isOutsideRange={() => false}
      onFocusChange={handleFocusChange}
      noBorder
      minimumNights={0}
      customArrowIcon={
        <i className="fa-regular fa-calendars calendar-range-picker-icon" />
      }
      displayFormat={'DD.MM.YYYY'}
      hideKeyboardShortcutsPanel
      firstDayOfWeek={1}
    />
  );
};

export default CalendarRangePicker;
