import InfoBox from 'components/InfoBox/InfoBox';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import DataSources from '../DataSources/DataSources';
import './AIBotContent.scss';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import DropDownButton from 'components/DropDownButton/DropDownButton';
import { SchemaField } from 'components/Modals/CSVImporterModal/CSVImporterModal';

export const aiContentSnippetSchemaFields: SchemaField[] = [
  { name: 'Question', key: 'question', required: true },
  { name: 'Answer', key: 'answer', required: true },
  { name: 'Source link', key: 'sourceLink', required: false },
  { name: 'Source name', key: 'sourceName', required: false },
];

interface AIBotContentProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
  organisationStore?: OrganisationStore;
}

const AIBotContent = ({
  projectStore,
  modalStore,
  organisationStore,
}: AIBotContentProps) => {
  const { projectId } = useParams();
  const currentProject = projectStore?.currentProject;
  const hasAIPlan = organisationStore?.hasAIPlan();

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    if (currentProject) {
      projectStore!.getProjectAnswerStats(currentProject.id);
    }
  }, [currentProject]);

  const importAiSnippets = async (data) => {
    if (!projectStore?.currentProject) {
      return;
    }

    for (let i = 0; i < data.length; i++) {
      const item = data[i];

      await projectStore?.createQAAnswer(
        projectStore.currentProject?.id,
        item.question,
        item.answer,
        item.sourceLink,
        item.sourceName,
      );
    }
  };

  if (!currentProject) {
    return <></>;
  }

  const renderKaiDashboard = () => {
    return (
      <>
        {organisationStore?.currentOrganisation && !hasAIPlan && (
          <InfoBox
            className="mb-10 info-box--error"
            icon="triangle-exclamation"
          >
            Your current plan <b>does not support</b> AI chatbot. Please contact
            us to subscribe to our AI features.
          </InfoBox>
        )}
        <DataSources />
      </>
    );
  };

  return (
    <PageContainer className="answer-bot">
      <PageHeadLine title="Content">
        <div className="header-content-right">
          <DropDownButton
            label="Import"
            items={[
              {
                label: 'Import from CSV',
                icon: 'file-csv',
                iconType: 'solid',
                onClick: () => {
                  modalStore!.openModal(MODALTYPE.CSV_IMPORTER, {
                    schema: aiContentSnippetSchemaFields,
                    onUpload: importAiSnippets,
                  });
                },
              },
            ]}
          />
          <PrimaryButton
            label="Add content"
            icon="plus"
            iconSideRight={false}
            onClick={() => {
              modalStore?.openModalSmart(MODALTYPE.ADDAICONTENT);
            }}
          />
        </div>
      </PageHeadLine>
      <PageContent hasTitle hasPadding>
        {renderKaiDashboard()}
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'projectStore',
  'modalStore',
  'organisationStore',
)(observer(AIBotContent));
