import classNames from 'classnames';
import SuggestSubscription from 'components/SuggestSubscription/SuggestSubscription';
import { inject } from 'mobx-react';
import { Feature } from 'models/Enums';
import ReactJson from 'react-json-view';
import { BugStore } from 'stores/private/BugStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import suggestSubscriptionIcon from '../../../assets/icons/database-duotone.svg';
import './CustomData.scss';

interface CustomDataProps {
  bugStore?: BugStore;
  projectStore?: ProjectStore;
}

const CustomData = ({ bugStore, projectStore }: CustomDataProps) => {
  const bug = bugStore!.currentBug;
  const planIsEntitled = bugStore?.isFeatureInPlan(Feature.CUSTOM_DATA);
  const darkMode = localStorage.getItem('theme') === 'dark';
  const suggestSubscriptionClass = classNames(
    {
      'custom-data-container--bg--light': !darkMode,
      'custom-data-container--bg--dark': darkMode,
    },
    'custom-data-container custom-data-container--bg',
  );

  if (!bug) {
    return null;
  }

  if (!planIsEntitled) {
    return (
      <div className={suggestSubscriptionClass}>
        <SuggestSubscription
          icon={suggestSubscriptionIcon}
          title="Custom data"
          description="Unlock Gleap Team to send your own custom data along with bug reports for $109/month."
        />
      </div>
    );
  }

  return (
    <div className="custom-data-container">
      <ReactJson
        theme={
          localStorage.getItem('theme') === 'dark'
            ? 'brewer'
            : 'shapeshifter:inverted'
        }
        src={bug.customData}
        style={{
          background: 'transparent',
        }}
      />
    </div>
  );
};

export default inject('bugStore', 'projectStore')(CustomData);
