import axios from '../Axios';

const getAuthCodeFromGitlab = (projectID) => {
  return window.open(
    `https://gitlab.com/oauth/authorize?client_id=${process.env.REACT_APP_GITLAB_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_BASEURL}/integrations/gitlab&response_type=code&state=${projectID}&scope=api&code_challenge=${process.env.REACT_APP_CODE_CHALLENGE}&code_challenge_method=S256`,
  );
};

const setGitlabAuthToken = (code, projectID) => {
  return axios.post(`/projects/${projectID}/integrations/gitlab`, {
    code,
  });
};

const getGitlabProjects = ({ projectID, integrationID }) => {
  return axios.get(
    `/projects/${projectID}/integrations/gitlab/${integrationID}/projects`,
  );
};

const setGitlabActions = ({ projectID, integrationID, actions, settings }) => {
  return axios.put(
    `/projects/${projectID}/integrations/gitlab/${integrationID}`,
    {
      actions,
      settings,
    },
  );
};

const disconnectGitlabIntegration = ({ projectID, integrationID }) => {
  return axios.delete(
    `/projects/${projectID}/integrations/gitlab/${integrationID}`,
  );
};

export {
  getAuthCodeFromGitlab,
  setGitlabAuthToken,
  getGitlabProjects,
  setGitlabActions,
  disconnectGitlabIntegration,
};
