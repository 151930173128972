import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper';
import Chart from 'react-apexcharts';
import React from 'react';
import { LineChartData } from 'models/Chart';
import Loading from 'components/Loading/Loading';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';

interface StatisticLineChartProps {
  chartData?: LineChartData | undefined | null;
  isLoading?: boolean;
  formatter?: (value: number) => string;
  infoText?: string;
}

const StatisticLineChart = ({
  chartData,
  isLoading,
  formatter,
  infoText,
}: StatisticLineChartProps) => {
  const chartHasData = () => {
    return (
      chartData?.dataSets &&
      chartData?.dataSets.length > 0 &&
      chartData?.dataSets[0].data &&
      chartData?.dataSets[0].data.length > 0
    );
  };

  const _buildNoData = () => {
    return (
      <Column>
        <div className="apexcharts-title-text">{chartData?.title}</div>
        <p>No data to display</p>
      </Column>
    );
  };

  return (
    <ComponentWrapper className="statistic-bar-chart" infoText={infoText}>
      {(isLoading || !chartData) && <Loading />}
      {!chartHasData() && !isLoading && _buildNoData()}
      {!isLoading && chartHasData() && (
        <Chart
          type="line"
          options={{
            title: {
              text: chartData?.title,
              margin: 25,
            },
            chart: {
              toolbar: {
                show: false,
              },
            },
            stroke: {
              curve: 'smooth',
              width: 3,
            },
            xaxis: {
              type: 'datetime',
            },
            yaxis: {
              labels: {
                formatter: formatter,
              },
            },
          }}
          series={chartData?.dataSets ?? []}
          height={360}
          width="100%"
        />
      )}
    </ComponentWrapper>
  );
};

export default StatisticLineChart;
