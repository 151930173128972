import { inject, observer } from 'mobx-react';
import { ProjectStore } from 'stores/private/ProjectStore';
import 'styles/swal-theme.scss';
import './TicketTypeFilter.scss';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import CreateableSelectDropdown from 'components/CreateableSelectDropdown/CreateableSelectDropdown';

interface TicketTypeFilterProps {
  projectStore?: ProjectStore;
  filterTypes: string[];
  setFilterTypes: (filterTypes: string[]) => void;
  selectedTags?: string[];
  setSelectedTags?: (selectedTags: string[]) => void;
}

const TicketTypeFilter = ({
  projectStore,
  filterTypes,
  setFilterTypes,
  selectedTags,
  setSelectedTags,
}: TicketTypeFilterProps) => {
  if (!projectStore?.currentProject) {
    return null;
  }

  const projectTypes = [
    ...projectStore?.currentProject?.projectTypes,
    {
      name: 'Workflows',
      type: 'BOT',
    },
  ];

  return (
    <Column className="mb-15">
      <div className="filter-container ticket-types">
        <div className="filter-row">
          <div className="border-margin-right mr-5 pr-10 ticket-type-item">
            <div
              onClick={() => {
                setFilterTypes([]);
              }}
              className={`tag-filter ${
                filterTypes.length === 0 ? 'tag-filter--active' : ''
              }`}
            >
              All tickets
            </div>
          </div>
          {projectTypes.map((type, key) => (
            <div
              key={key}
              onClick={() => {
                if (filterTypes.includes(type.type)) {
                  setFilterTypes(
                    filterTypes.filter(
                      (filterType) => filterType !== type.type,
                    ),
                  );
                } else {
                  setFilterTypes([...filterTypes, type.type]);
                }
              }}
              className={`tag-filter ticket-type-item ${
                filterTypes.includes(type.type) ? 'tag-filter--active' : ''
              }`}
            >
              {type.name}
            </div>
          ))}
        </div>
      </div>
      <CreateableSelectDropdown
        showBorder={false}
        selectedItem={
          selectedTags
            ? selectedTags.map((tagItem: string) => {
                return {
                  label: tagItem,
                  value: tagItem,
                  color:
                    projectStore!.currentProject?.feedbackTags.find(
                      (item) => item.label === tagItem,
                    )?.color ?? '#060d25',
                };
              })
            : []
        }
        items={
          projectStore?.currentProject?.feedbackTags
            ? projectStore?.currentProject?.feedbackTags!.map((tagItem) => {
                return {
                  label: tagItem.label,
                  value: tagItem.label,
                  color: tagItem.color ?? '#060d25',
                };
              })
            : []
        }
        onChange={(selectedItems: any[]) => {
          setSelectedTags!(selectedItems.map((item) => item.value));
        }}
      />
    </Column>
  );
};

export default inject('projectStore')(observer(TicketTypeFilter));
