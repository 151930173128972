import { convertCentsToUnits, convertTimestampToDate, getCurrencySymbol } from "components/ChargesList/ChargesList";
import LinkButton from "components/LinkButton/LinkButton";
import './SubscriptionList.scss';

function SubscriptionList({ subscriptions, customer }) {
    const getIcon = (status) => {
        let iconName = "fa-xmark";

        if (status === 'active') {
            iconName = 'fa-check';
        } else if (status === 'trialing') {
            iconName = 'fa-hourglass-half';
        }

        return iconName;
    }

    return (
        <div className="subscriptions-list">
            {subscriptions?.map((subscription, index) => {

                let productName = '';
                let totalAmountInCents = 0;
                let subscriptionType = '';
                let subscriptionInterval = '';

                subscription.items.data.forEach((item) => {
                    // Concatenate product names
                    if (productName.length > 0) productName += ', ';
                    productName += item.productName;

                    // Get subscription type
                    subscriptionType = item.price.type;

                    // Get subscription interval
                    subscriptionInterval = item.price.recurring.interval;

                    // Accumulate total price
                    totalAmountInCents += item.price.unit_amount;
                });

                return (
                    <div className="subscriptions-list-item" key={index}>
                        <div className={`charge-icon charge-icon-${subscription.status.toLowerCase()}`}>
                            <i className={`fa-regular ${getIcon(subscription.status)}`} />
                        </div>
                        <div>
                            <div className='charge-title'>{productName} {getCurrencySymbol(subscription.currency)}{convertCentsToUnits(totalAmountInCents)}</div>
                            <div className='charge-date'>{subscriptionType} {subscriptionInterval}</div>
                        </div>
                    </div>
                );
            })}
            {(!subscriptions || subscriptions?.length === 0) && <div className='text mt-20'>No active subscriptions</div>}
            <LinkButton
                className="bfw mt-15"
                icon="arrow-up-right"
                label="Show all subscriptions"
                iconSideRight={true}
                onClick={() => {
                    window.open(`https://dashboard.stripe.com/payments?customer=${customer.id}`, "_blank");
                }}
            />
        </div>
    );
}

export default SubscriptionList;
