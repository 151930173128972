import axios from '../Axios';

const setStripeAuthToken = (projectID, code) => {
  return axios.post(`/projects/${projectID}/integrations/stripe`, {
    code,
  });
};

const setStripeActions = ({ projectID, integrationID, actions, settings }) => {
  return axios.put(
    `/projects/${projectID}/integrations/stripe/${integrationID}`,
    {
      actions,
      settings,
    },
  );
};

const disconnectStripeIntegration = ({ projectID, integrationID }) => {
  return axios.delete(
    `/projects/${projectID}/integrations/stripe/${integrationID}`,
  );
};

export { setStripeAuthToken, setStripeActions, disconnectStripeIntegration };
