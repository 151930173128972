import NativeAxios from 'axios';
import { User } from 'models/User';
import axios, { injectHeader } from './Axios';

const register = (
  token: string,
  email: string,
  password: string,
  name: string,
  onboardingFlow: string,
) => {
  let referral = null;
  try {
    referral = (window as any).CelloAttribution('getReferral');
  } catch (e) {}

  return axios.post('/users', {
    token,
    email,
    password,
    name,
    onboardingFlow,
    ...(referral ? { referral } : {}),
  });
};

const loginWithEmailAndPassword = (args: {
  authData;
  twoFactorAuthentication?: any;
}): Promise<any> => {
  const { authData, twoFactorAuthentication } = args;

  return axios.post(
    '/auth',
    { twoFactorAuthentication },
    {
      auth: authData,
    },
  );
};

const loginWithGithub = (code: string): Promise<any> => {
  return NativeAxios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/github`, {
    access_token: code,
  });
};

const loginWithGoogle = (args: {
  code: string;
  twoFactorAuthentication?: any;
}): Promise<any> => {
  const { code, twoFactorAuthentication } = args;

  let referral = null;
  try {
    referral = (window as any).CelloAttribution('getReferral');
  } catch (e) {}

  return NativeAxios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/google`, {
    access_token: code,
    twoFactorAuthentication,
    utm_source: localStorage.getItem('utm_source'),
    utm_medium: localStorage.getItem('utm_medium'),
    utm_term: localStorage.getItem('utm_term'),
    utm_content: localStorage.getItem('utm_content'),
    utm_campaign: localStorage.getItem('utm_campaign'),
    ...(referral ? { referral } : {}),
  });
};

const reassignTicketsOnOffline = (): Promise<any> => {
  return axios.post(`/users/me/reassign-tickets`, {});
};

const updateUser = (id: string, user: any): Promise<any> => {
  return axios.put(`/users/${id}`, user);
};

const verifyAccount = (code: string, email: string): Promise<any> => {
  return axios.post('/users/verify', {
    email,
    code,
  });
};

const changePassword = (id: string, password: string, oldpassword: string) => {
  return axios.put(`/users/${id}/password`, { password, oldpassword });
};

const resend = (user: User): Promise<any> => {
  if (user) {
    return axios.post('/users/resend', { id: user.id });
  }
  return new Promise((resolve, reject) => {
    reject(new Error('Unauthorized'));
  });
};

const sendEmailNotification = (): Promise<any> => {
  injectHeader();
  return axios.post('/users/me/sendemailnotification', {});
};

const getCelloToken = (): Promise<any> => {
  injectHeader();
  return axios.get(`/users/me/cello-token`);
};

const me = (ga?: string): Promise<any> => {
  injectHeader();
  return axios.get(`/users/me?ga=${ga}`);
};

const deleteUser = () => {
  return axios.delete(`users/me`);
};

const registerFcmToken = (token: string) => {
  return axios.post('/users/me/fcm-token', { fcmToken: token });
};

const unregisterFcmToken = (token: string) => {
  return axios.delete(`/users/me/fcm-token`, { data: { fcmToken: token } });
};

const enable2FA = () => {
  return axios.post('/users/me/enable-2fa');
};

const verify2FA = (credentials: any) => {
  return axios.post('/users/me/verify-2fa', credentials);
};

const delete2FADevice = (deviceId: string) => {
  return axios.delete(`/users/me/2fa-devices/${deviceId}`);
};

export {
  changePassword,
  delete2FADevice,
  deleteUser,
  enable2FA,
  getCelloToken,
  loginWithEmailAndPassword,
  loginWithGithub,
  loginWithGoogle,
  me,
  register,
  registerFcmToken,
  resend,
  sendEmailNotification,
  unregisterFcmToken,
  updateUser,
  verify2FA,
  verifyAccount,
  reassignTicketsOnOffline,
};
