export const calculateNPS = (stats: any, formItem: any) => {
  var detractorsCount = 0;
  var passivesCount = 0;
  var promotersCount = 0;
  var values = {};

  // Determine the NPS type from the form item
  const npsType = formItem.npsType ? formItem.npsType : 'classic';

  // Define the NPS options based on type
  let npsOptions;
  if (npsType === 'classic') {
    npsOptions = Array.from({ length: 11 }, (_, i) => i);
  } else if (npsType === 'modern') {
    npsOptions = Array.from({ length: 6 }, (_, i) => i);
  } else if (npsType === 'emoji') {
    npsOptions = [0, 2, 4, 6, 8, 10];
  }

  const overallCount = stats.length;
  for (let i = 0; i < stats.length; i++) {
    const { formData } = stats[i];
    if (!formData || !formItem.name) {
      continue;
    }

    let currentValue = 0;
    try {
      currentValue = parseInt(formData[formItem.name]);
    } catch (e) {}

    if (npsType === 'modern') {
      currentValue = currentValue / 2;
    }

    switch (npsType) {
      case 'classic':
        if (currentValue >= 9) {
          promotersCount++;
        } else if (currentValue >= 7) {
          passivesCount++;
        } else {
          detractorsCount++;
        }
        break;
      case 'modern':
        if (currentValue === 5) {
          promotersCount++;
        } else if (currentValue >= 3) {
          passivesCount++;
        } else {
          detractorsCount++;
        }
        break;
      case 'emoji':
        if (currentValue === 10) {
          // 😍
          promotersCount++;
        } else if (currentValue === 8) {
          // 😊
          passivesCount++;
        } else {
          // 😡, 😢, 😐
          detractorsCount++;
        }
        break;
      default:
        if (currentValue >= 9) {
          promotersCount++;
        } else if (currentValue >= 7) {
          passivesCount++;
        } else {
          detractorsCount++;
        }
        break;
    }

    values[currentValue] = values[currentValue] + 1 || 1;
  }

  const npsScore = Math.round(
    ((promotersCount - detractorsCount) / overallCount) * 100,
  );

  return {
    npsScore,
    detractorsCount,
    passivesCount,
    promotersCount,
    npsType,
    npsOptions,
    values,
  };
};
