import Link from '@tiptap/extension-link';

export const CustomLink = Link.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      linktype: {
        default: 'link',
        renderHTML: (attributes) => {
          return {
            'data-linktype': attributes.linktype,
            class: `linktype-${attributes.linktype}`,
          };
        },
      },
    };
  },
}).configure({
  autolink: false,
  protocols: ['http', 'https', 'mailto', 'tel', 'gleap'],
});
