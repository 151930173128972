import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { OutboundCondition } from 'models/OutboundCondition';
import './ConditionTag.scss';
import DatePicker from 'react-date-picker-gleap/dist/entry';
import {
  getDefaultType,
  getTypeOfAttribute,
  isValidDate,
  useClickedOutside,
  useEscape,
} from 'services/Helper';
import LinkButton from 'components/LinkButton/LinkButton';
import moment from 'moment';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import { countries } from 'services/CountryHelper';
import { languages } from 'constants/Languages';
import ConditionValueSelect from 'components/ConditionValueSelect/ConditionValueSelect';
import { PropertyStore } from 'stores/private/PropertyStore';
import { inject, observer } from 'mobx-react';
import InfoBox from 'components/InfoBox/InfoBox';

export const conditionIsValid = (condition: OutboundCondition) => {
  if (
    condition.matchOperator === 'empty' ||
    condition.matchOperator === 'notempty'
  ) {
    return true;
  }

  if (condition.data != null && condition.type && condition.matchOperator) {
    return true;
  }
  return false;
};

export const timeIntervalOptions = [
  {
    id: 'second',
    label: 'seconds',
  },
  {
    id: 'minute',
    label: 'minutes',
  },
  {
    id: 'hour',
    label: 'hours',
  },
  {
    id: 'day',
    label: 'days',
  },
];

export const timeintervalMultiplier = {
  day: 3600 * 24,
  hour: 3600,
  minute: 60,
  second: 1,
};

interface ConditionTagProps {
  className?: string;
  streamedEventKeys?: {
    label: string;
    value: string;
    type: string;
    icon?: string;
  }[];
  userProperties?: any[];
  value: OutboundCondition;
  type?: 'audience' | 'trigger';
  onChange: (value: OutboundCondition) => void;
  onRemove: () => void;
  propertyStore?: PropertyStore;
  absoluteTriggerOptions?: any[];
  relativeTriggerOptions?: any[];
}

const ConditionTag = ({
  userProperties = [],
  streamedEventKeys,
  className,
  value,
  onChange,
  onRemove,
  type = 'audience',
  propertyStore,
  absoluteTriggerOptions,
  relativeTriggerOptions,
}: ConditionTagProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);
  const requiredType = getTypeOfAttribute(
    value,
    propertyStore?.currentProjectProperties,
  );

  useClickedOutside(wrapperRef, () => {
    setIsOpen(false);
  });
  useEscape(() => {
    setIsOpen(false);
  });

  const timeInterval = value.ti ?? 'day';

  useEffect(() => {
    if (value.initiallyOpened) {
      var newData = {
        ...value,
      };
      delete newData.initiallyOpened;
      onChange(newData);
      setIsOpen(true);
    }
  }, [value]);

  const calculateDays = (seconds, interval = 'day') => {
    const number = parseInt(seconds);
    const multiplier =
      timeintervalMultiplier[interval] ?? timeintervalMultiplier.day;
    if (!isNaN(number)) {
      const days = Math.round(value.data / multiplier);
      return days;
    }
    return '';
  };

  const calculateSeconds = (days, interval = 'day') => {
    const number = parseFloat(days);
    if (!isNaN(number)) {
      return (
        number *
        (timeintervalMultiplier[interval] ?? timeintervalMultiplier.day)
      );
    }
    return 0;
  };

  const getConditionDescription = () => {
    const resolveCountMatchOperator = (matchOperator) => {
      const matchOperatorMap = {
        is: 'is',
        isnot: 'is not',
        greaterthan: 'is greater than',
        lessthan: 'is less than',
        contains: 'contains',
        notcontains: 'does not contain',
        empty: 'is empty',
        notempty: 'is not empty',
      };
      return matchOperatorMap[matchOperator];
    };

    const resolveDateMatchOperator = (matchOperator) => {
      const matchOperatorMap = {
        after: 'after',
        before: 'before',
        on: 'on',
        greaterthan: 'more than',
        lessthan: 'less than',
        exactly: 'exactly',
      };
      return matchOperatorMap[matchOperator];
    };

    const isUserValue = value.event && value.event.includes('gleap-up-');

    if (
      value.type === 'latestdata' &&
      (value.matchOperator === 'empty' || value.matchOperator === 'notempty')
    ) {
      return `${resolveCountMatchOperator(value.matchOperator)}`;
    }

    if (value.matchOperator && value.data != null) {
      if (value.data && isValidDate(value.data)) {
        return `${resolveCountMatchOperator(value.matchOperator)} ${moment(
          value.data,
        ).format('LL')}`;
      }

      if (value.type === 'count') {
        return `${isUserValue ? 'value' : 'count'} ${resolveCountMatchOperator(
          value.matchOperator,
        )} ${value.data}`;
      }

      if (value.type === 'latestdata') {
        return `${resolveCountMatchOperator(value.matchOperator)} ${
          value.data
        }`;
      }

      if (value.dateOperator === 'relative') {
        const days = calculateDays(value.data, timeInterval);

        if (type === 'trigger' && !isUserValue) {
          if (days === 0) {
            return `as the event occurs`;
          } else {
            return `${days} ${timeInterval}${
              days === 1 ? '' : 's'
            } after the event occurs`;
          }
        } else if (type === 'trigger' && isUserValue && days === 0) {
          return `as the event occurs`;
        } else {
          const prefix = value.type === 'firstoccured' ? '' : 'last occured ';
          return `${prefix}${resolveDateMatchOperator(
            value.matchOperator,
          )} ${days} ${timeInterval}${days === 1 ? '' : 's'} ago`;
        }
      }

      if (value.dateOperator === 'absolute') {
        const prefix = value.type === 'firstoccured' ? '' : 'last occured ';
        return `${prefix}${resolveDateMatchOperator(
          value.matchOperator,
        )} ${moment(value.data).format('LL')}`;
      }
    }

    return '';
  };

  const getCurrentEventData = () => {
    var eventName = value.event;
    var eventIcon = 'flag';

    const selectedEvent = userProperties[eventName];
    if (selectedEvent) {
      eventName = selectedEvent.label;
      eventIcon = selectedEvent.icon ?? 'user';
    }

    if (streamedEventKeys) {
      const selectedEvent = streamedEventKeys.find(
        (event) => event.value === eventName,
      );
      if (selectedEvent) {
        eventName = selectedEvent.label;
        eventIcon = selectedEvent.icon ?? 'flag';
      }
    }

    return {
      eventName,
      eventIcon,
    };
  };

  const renderConditionName = () => {
    const { eventName, eventIcon } = getCurrentEventData();

    return (
      <div className="condition-name">
        <i className={`fa-solid fa-${eventIcon}`} />
        {eventName}
      </div>
    );
  };

  const renderRelativeDateContent = () => {
    const options = relativeTriggerOptions ?? [
      {
        label: 'more than',
        value: 'greaterthan',
      },
      {
        label: 'less than',
        value: 'lessthan',
      },
      {
        label: 'exactly',
        value: 'exactly',
      },
    ];

    return (
      <div className="count-editor-container">
        <div className="count-editor-container-title">Relative</div>
        <div className="count-editor-container-options">
          {options.map((option, index) => {
            return (
              <>
                <label
                  className="bb-feedback-multiplechoice-container"
                  key={`${option.value}-${index}`}
                >
                  <div className="text">{option.label}</div>
                  <input
                    type="radio"
                    name={`condition-${option.value}`}
                    checked={value.matchOperator === option.value}
                    onChange={() => {
                      var newVal = value.data;
                      if (!isNaN(parseInt(newVal)) || newVal instanceof Date) {
                        newVal = 0;
                      }
                      onChange({
                        ...value,
                        matchOperator: option.value as any,
                        dateOperator: 'relative',
                        data: newVal,
                      });
                    }}
                  />
                  <span className="bb-feedback-multiplechoice-checkmark" />
                </label>
                {value.matchOperator === option.value && (
                  <>
                    <div className="field-container">
                      <div className="input-wrapper">
                        <input
                          className="textinput-gray"
                          value={calculateDays(value.data, timeInterval)}
                          type="number"
                          onChange={(inputVal) => {
                            const number = parseInt(inputVal.target.value);
                            if (!isNaN(number) && number >= 0) {
                              onChange({
                                ...value,
                                data: calculateSeconds(number, timeInterval),
                              });
                            } else {
                              onChange({
                                ...value,
                                data: 0,
                              });
                            }
                          }}
                        />
                      </div>
                      <div className="interval-selector">
                        <SelectDropDown
                          isSearchable={false}
                          labelPropertyName="label"
                          valuePropertyName="id"
                          selectedItem={timeIntervalOptions.find(
                            (option) => option.id === timeInterval,
                          )}
                          items={timeIntervalOptions}
                          onChange={(option) => {
                            if (option.id && option.id.length > 0) {
                              onChange({
                                ...value,
                                ti: option.id,
                              });
                            }
                          }}
                        />
                        <span> ago</span>
                      </div>
                    </div>
                  </>
                )}
              </>
            );
          })}
        </div>
      </div>
    );
  };

  const renderRelativeTriggerDateContent = () => {
    const isUserValue = value.event && value.event.includes('gleap-up-');

    const options =
      relativeTriggerOptions ?? isUserValue
        ? [
            {
              label: 'more than',
              value: 'greaterthan',
            },
            {
              label: 'less than',
              value: 'lessthan',
            },
            {
              label: 'on the date',
              value: 'exactly',
            },
          ]
        : [
            {
              label: 'after the event occurs',
              value: 'greaterthan',
            },
            {
              label: 'as the event occurs',
              value: 'exactly',
            },
          ];

    return (
      <div className="count-editor-container">
        <div className="count-editor-container-options">
          {options.map((option, index) => {
            return (
              <>
                <label
                  className="bb-feedback-multiplechoice-container"
                  key={`${option.value}-${index}`}
                >
                  <div className="text">{option.label}</div>
                  <input
                    type="radio"
                    name={`condition-${option.value}`}
                    checked={value.matchOperator === option.value}
                    onChange={() => {
                      var newVal = value.data;
                      if (!isNaN(parseInt(newVal)) || newVal instanceof Date) {
                        newVal = 0;
                      }
                      if (option.value === 'exactly') {
                        newVal = 0;
                      }

                      onChange({
                        ...value,
                        matchOperator: option.value as any,
                        dateOperator: 'relative',
                        data: newVal,
                      });
                    }}
                  />
                  <span className="bb-feedback-multiplechoice-checkmark" />
                </label>
                {value.matchOperator === option.value &&
                  option.value !== 'exactly' && (
                    <>
                      <div className="field-container">
                        <div className="input-wrapper">
                          <input
                            className="textinput-gray"
                            value={calculateDays(value.data, timeInterval)}
                            type="number"
                            onChange={(inputVal) => {
                              const number = parseInt(inputVal.target.value);
                              if (!isNaN(number) && number >= 0) {
                                onChange({
                                  ...value,
                                  data: calculateSeconds(number, timeInterval),
                                });
                              } else {
                                onChange({
                                  ...value,
                                  data: 0,
                                });
                              }
                            }}
                          />
                        </div>
                        <div className="interval-selector">
                          <SelectDropDown
                            isSearchable={false}
                            labelPropertyName="label"
                            valuePropertyName="id"
                            selectedItem={timeIntervalOptions.find(
                              (option) => option.id === timeInterval,
                            )}
                            items={timeIntervalOptions}
                            onChange={(option) => {
                              if (option.id && option.id.length > 0) {
                                onChange({
                                  ...value,
                                  ti: option.id,
                                });
                              }
                            }}
                          />
                          {isUserValue ? ' ago' : ''}
                        </div>
                      </div>
                    </>
                  )}
              </>
            );
          })}
        </div>
      </div>
    );
  };

  const renderAbsoluteDateContent = () => {
    const options = [
      {
        label: 'after',
        value: 'after',
      },
      {
        label: 'on',
        value: 'on',
      },
      {
        label: 'before',
        value: 'before',
      },
    ];

    return (
      <div className="count-editor-container mt-30">
        <div className="count-editor-container-title">Absolute</div>
        <div className="count-editor-container-options">
          {options.map((option, index) => {
            return (
              <>
                <label
                  className="bb-feedback-multiplechoice-container"
                  key={`${option.value}-${index}`}
                >
                  <div className="text">{option.label}</div>
                  <input
                    type="radio"
                    name={`condition-${option.value}`}
                    checked={value.matchOperator === option.value}
                    onChange={() => {
                      var newVal = value.data;
                      if (!(newVal instanceof Date)) {
                        newVal = new Date();
                      }
                      onChange({
                        ...value,
                        matchOperator: option.value as any,
                        dateOperator: 'absolute',
                        data: newVal,
                      });
                    }}
                  />
                  <span className="bb-feedback-multiplechoice-checkmark" />
                </label>
                {value.matchOperator === option.value && (
                  <>
                    <DatePicker
                      placeholderText="Select date"
                      onChange={(selectedDate) => {
                        if (selectedDate && selectedDate instanceof Date) {
                          onChange({
                            ...value,
                            data: selectedDate,
                          });
                        }
                      }}
                      value={
                        value?.data instanceof Date
                          ? value.data
                          : new Date(value.data ?? new Date())
                      }
                      showLeadingZeros={true}
                      calendarIcon={<i className="fa-solid fa-calendar" />}
                    />
                  </>
                )}
              </>
            );
          })}
        </div>
      </div>
    );
  };

  const renderDateEditorContent = () => {
    if (type === 'trigger') {
      return <>{renderRelativeTriggerDateContent()}</>;
    }

    return (
      <>
        {renderRelativeDateContent()}
        {renderAbsoluteDateContent()}
      </>
    );
  };

  const renderLatestDataEditorContent = () => {
    // Special country dropdown.
    if (value.event && value.event.includes('location.country')) {
      return (
        <div className="count-editor-container">
          <div className="count-editor-container-title">Select a country</div>
          <div className="count-editor-container-options special-select-cont">
            <SelectDropDown
              isSearchable={true}
              labelPropertyName="name"
              valuePropertyName="code"
              selectedItem={countries.find(
                (option) => option.code === value.data,
              )}
              items={countries}
              onChange={(option) => {
                if (option?.code) {
                  onChange({
                    ...value,
                    data: option.code,
                    matchOperator: 'is',
                  });
                }
              }}
            />
          </div>
        </div>
      );
    }

    // Special language dropdown.
    if (value.event === 'gleap-up-lang') {
      return (
        <div className="count-editor-container">
          <div className="count-editor-container-title">Select a language</div>
          <div className="count-editor-container-options special-select-cont">
            <SelectDropDown
              isSearchable={true}
              labelPropertyName="name"
              valuePropertyName="code"
              selectedItem={languages.find(
                (option) => option.code === value.data,
              )}
              items={languages}
              onChange={(option) => {
                if (option?.code) {
                  onChange({
                    ...value,
                    data: option.code,
                    matchOperator: 'contains',
                  });
                }
              }}
            />
          </div>
        </div>
      );
    }

    const options = absoluteTriggerOptions ?? [
      {
        label: 'is',
        value: 'is',
      },
      {
        label: 'is not',
        value: 'isnot',
      },
      {
        label: 'greater than',
        value: 'greaterthan',
      },
      {
        label: 'less than',
        value: 'lessthan',
      },
      {
        label: 'contains',
        value: 'contains',
      },
      {
        label: 'does not contain',
        value: 'notcontains',
      },
      {
        label: 'is empty',
        value: 'empty',
        novalue: true,
      },
      {
        label: 'is not empty',
        value: 'notempty',
        novalue: true,
      },
    ];

    return (
      <div className="count-editor-container">
        <div className="count-editor-container-title">Value</div>
        <div className="count-editor-container-options">
          {options.map((option, index) => {
            const currentVal = value.data;
            const isCurrentValNumber = !isNaN(currentVal);
            const dataType =
              getDefaultType(value.data, value?.matchOperator) ?? 'string';

            return (
              <>
                <label
                  className="bb-feedback-multiplechoice-container"
                  key={`${value.matchOperator}-${option.value}-${index}`}
                >
                  <div className="text">{option.label}</div>
                  <input
                    type="radio"
                    name={`condition-${option.value}`}
                    checked={value.matchOperator === option.value}
                    onChange={() => {
                      onChange({
                        ...value,
                        matchOperator: option.value as any,
                      });
                    }}
                  />
                  <span className="bb-feedback-multiplechoice-checkmark" />
                </label>
                {!option.novalue && value.matchOperator === option.value && (
                  <>
                    <div className="field-container">
                      <div className="input-wrapper">
                        <ConditionValueSelect
                          defaultType={dataType}
                          attribute={value}
                          onValueChange={(val) => {
                            onChange(val);
                          }}
                          label={getCurrentEventData()?.eventName}
                          requiredType={requiredType}
                        />
                      </div>
                    </div>
                    {dataType === 'string' && isCurrentValNumber && (
                      <InfoBox className="info-box--warning mt-10">
                        Note: You entered a number, but the data type is set to
                        text.
                      </InfoBox>
                    )}
                  </>
                )}
              </>
            );
          })}
        </div>
      </div>
    );
  };

  const renderCountEditorContent = () => {
    const options = [
      {
        label: 'is',
        value: 'is',
      },
      {
        label: 'is not',
        value: 'isnot',
      },
      {
        label: 'greater than',
        value: 'greaterthan',
      },
      {
        label: 'less than',
        value: 'lessthan',
      },
    ];

    const isUserValue = value.event && value.event.includes('gleap-up-');

    return (
      <div className="count-editor-container">
        <div className="count-editor-container-title">
          {isUserValue ? 'Value' : 'Event count'}
        </div>
        <div className="count-editor-container-options">
          {options.map((option, index) => {
            return (
              <>
                <label
                  className="bb-feedback-multiplechoice-container"
                  key={`${option.value}-${index}`}
                >
                  <div className="text">{option.label}</div>
                  <input
                    type="radio"
                    name={`condition-${option.value}`}
                    checked={value.matchOperator === option.value}
                    onChange={() => {
                      onChange({
                        ...value,
                        matchOperator: option.value as any,
                      });
                    }}
                  />
                  <span className="bb-feedback-multiplechoice-checkmark" />
                </label>
                {value.matchOperator === option.value && (
                  <div className="field-container">
                    <div className="input-wrapper">
                      <ConditionValueSelect
                        defaultType={getDefaultType(value.data) ?? 'string'}
                        label={getCurrentEventData()?.eventName}
                        attribute={value}
                        onValueChange={(val) => {
                          onChange(val);
                        }}
                      />
                    </div>
                  </div>
                )}
              </>
            );
          })}
        </div>
      </div>
    );
  };

  const renderTypeEditorContent = () => {
    const types = [
      {
        label: 'Count',
        value: 'count',
      },
      {
        label: 'First occured',
        value: 'firstoccured',
      },
      {
        label: 'Last occured',
        value: 'lastoccured',
      },
    ];
    return (
      <div className="type-editor-container">
        <div className="type-editor-container-title">Choose the event type</div>
        <div className="type-editor-container-buttons">
          {types.map((eventType, index) => {
            return (
              <LinkButton
                key={index}
                label={eventType.label}
                onClick={() => {
                  onChange({
                    ...value,
                    type: eventType.value as any,
                  });
                }}
              />
            );
          })}
        </div>
      </div>
    );
  };

  const renderEditorModalContent = () => {
    const renderContent = () => {
      if (!value.type) {
        return renderTypeEditorContent();
      }
      if (value.type === 'latestdata') {
        return renderLatestDataEditorContent();
      }
      if (value.type === 'count') {
        return renderCountEditorContent();
      }
      if (value.type === 'firstoccured' || value.type === 'lastoccured') {
        return renderDateEditorContent();
      }
    };

    return <div className="condition-editor">{renderContent()}</div>;
  };

  const isValid = conditionIsValid(value);
  const cardClassName = classNames(
    {
      'condition-tag': true,
      'condition-tag--trigger': type === 'trigger',
      'condition-tag--invalid': !isOpen && !isValid,
    },
    className,
  );

  return (
    <div className={cardClassName} ref={wrapperRef}>
      <div
        className="condition-tag--inner"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {renderConditionName()}
        <div className="condition-description">{getConditionDescription()}</div>
        <div className="condition-remove">
          <i
            className="fa-sharp fa-solid fa-xmark"
            onClick={() => {
              onRemove();
            }}
          ></i>
        </div>
      </div>
      <div
        className={`condition-tag-overlay ${
          isOpen && 'condition-tag-overlay--open'
        }`}
      >
        {renderEditorModalContent()}
      </div>
    </div>
  );
};

export default inject('propertyStore')(observer(ConditionTag));
