import './ContentCard.scss';
import { Card } from 'components/Card/Card';

interface ContentCardProps {
  item: {
    title: string;
    description: string;
    icon: string;
    color?: string;
  };
  onClick?: () => void;
}

const ContentCard = ({ item, onClick }: ContentCardProps) => {
  return (
    <Card className="content-card" onClick={onClick}>
      <div className="link-header" style={{
        backgroundColor: item.color,
      }}>
        <i className={`fa fa-${item.icon}`} />
      </div>
      <div className="link-body">
        <span className="title">{item.title}</span>
        <div className="description">{item.description}</div>
      </div>
    </Card>
  );
};

export default ContentCard;
