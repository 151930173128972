import { cleanupName } from 'components/FeedbackUserFilter/FeedbackUserFilter';
import LoadingAnimationSmall from 'components/LoadingAnimationSmall/LoadingAnimationSmall';
import TextInput from 'components/TextInput/TextInput';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import React, { useCallback, useEffect, useState } from 'react';
import { getSessionName } from 'services/GuestNameHelper';
import { ProjectStore } from 'stores/private/ProjectStore';
import { inject, observer } from 'mobx-react';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import './SessionRecipientPicker.scss';
import { searchForSessions } from 'services/ProjectHttpService';
import { HttpSessionService } from 'services/http.clients/http.session.client';

interface SessionRecipientPickerProps {
  session: any;
  initialSessionId?: string;
  onChange: (session: any) => void;
  placeholder?: string;
  projectStore?: ProjectStore;
}

const SessionRecipientPicker = ({
  session,
  initialSessionId,
  onChange,
  placeholder,
  projectStore,
}: SessionRecipientPickerProps) => {
  const [fullTextFilter, setFullTextFilter] = useState('');
  const [sessionSearchResults, setSessionSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [sessionId, setSessionId] = useState(initialSessionId ?? '');

  const debounceSearch = useCallback(
    debounce(async (_fullTextFilter) => {
      setIsSearching(true);
      const response = await searchForSessions({
        projectId: projectStore?.currentProject?.id ?? '',
        query: {
          searchTerm: _fullTextFilter,
        },
      });
      if (response.status === 200) {
        const items = response.data;
        setSessionSearchResults(items);
      }
      setIsSearching(false);
    }, 500),
    [],
  );

  useEffect(() => {
    if (projectStore?.currentProject && fullTextFilter.length > 0) {
      debounceSearch(fullTextFilter);
    } else {
      setSessionSearchResults([]);
    }
  }, [fullTextFilter]);

  const loadSessionDetails = async () => {
    if (!(sessionId && sessionId.length > 0)) {
      return;
    }

    try {
      const session = await HttpSessionService.getInstance().findOne({
        id: sessionId,
      });
      if (session) {
        onChange(session);
      }
    } catch (exp) {
      toast.error('Could not load receiver contact.');
    }
  };

  useEffect(() => {
    if (sessionId) {
      loadSessionDetails();
    }
  }, [sessionId]);

  const createSessionContact = async () => {
    try {
      setIsSearching(true);

      const session = await HttpSessionService.getInstance().create({
        data: { email: fullTextFilter } as any,
      });

      setIsSearching(false);

      if (session) {
        setSessionId(session.id);
      }
      setSessionSearchResults([]);
    } catch (_) {
      setIsSearching(false);

      toast.error('Could not create contact.');
    }
  };

  if (!sessionId) {
    return (
      <>
        <div className="compose-conversation-session">
          <div className="session-search-container">
            <TextInput
              placeholder={
                placeholder ?? 'Choose a receiver (name, email or user ID)'
              }
              type="text"
              icon="magnifying-glass"
              error=""
              initalValue={fullTextFilter}
              onChange={(text) => {
                setFullTextFilter(text);
              }}
            />
            {((sessionSearchResults && sessionSearchResults.length > 0) ||
              isSearching ||
              fullTextFilter !== '') && (
              <div className="session-search">
                {!sessionSearchResults
                  .map((session: any) => session?.email)
                  .includes(fullTextFilter) &&
                  !isSearching && (
                    <div
                      className="session-search-session"
                      onClick={createSessionContact}
                    >
                      Create contact
                    </div>
                  )}
                {isSearching && (
                  <div className="session-search-loading">
                    <LoadingAnimationSmall />
                  </div>
                )}
                {sessionSearchResults.map((session: any) => {
                  if (!session) {
                    return null;
                  }

                  return (
                    <div
                      className="session-search-session"
                      onClick={() => {
                        setSessionId(session.id);
                        setSessionSearchResults([]);
                      }}
                    >
                      <UserAvatar
                        email={
                          session?.email ? `${session.email}` : session.gleapId
                        }
                        small
                      />
                      <div className="session-search-session-data">
                        <span className="session-search-session-name">
                          {cleanupName(getSessionName(session), 30)}
                        </span>
                        <span className="session-search-session-email">
                          {session?.email}
                          {session.userId ? ` · ${session.userId}` : ''}
                        </span>
                      </div>
                      {session.userId && (
                        <div className="type-guest-tag">USER</div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  if (!session) {
    return (
      <div className="compose-conversation-session">
        <LoadingAnimationSmall />
      </div>
    );
  }

  const isOnline = (Date.now() - Date.parse(session.lastActivity)) / 60000 < 2;

  return (
    <div className="compose-conversation-session">
      <UserAvatar
        isOnline={isOnline}
        email={session?.email ? session.email : session.gleapId}
        small
      />{' '}
      <div className={`name ${!session.userId ? 'guest-name' : ''}`}>
        {cleanupName(getSessionName(session), 20)}
        {session.userId && <div className="type-guest-tag">USER</div>}
      </div>
      <i
        className="fa-regular fa-circle-xmark ml-10 remove-session-icon"
        onClick={() => {
          setSessionId('');
          onChange(null);
        }}
      />
    </div>
  );
};

export default inject('projectStore')(observer(SessionRecipientPicker));
