import { inject, observer } from 'mobx-react';
import { Letter } from 'react-letter';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import './SourceEmailModal.scss';
import { Title } from 'components/Title/Title';

interface SourceEmailModalProps {
  modalStore?: ModalStore;
}

const SourceEmailModal = ({
  modalStore,
}: SourceEmailModalProps) => {
  const emailData = modalStore?.getCustomData(MODALTYPE.SOURCE_EMAIL);

  return (
    <div className="source-email-modal-container">
      <Title label="Original email" />
      <Letter className='source-email-modal-container-mail' useIframe html={emailData.originalEmailContent} />
    </div>
  );
};

export default inject(
  'modalStore',
)(observer(SourceEmailModal));
