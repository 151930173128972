import { useEffect } from 'react';
import 'react-image-lightbox/style.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-buttons/dist/react-buttons.css';
import '@trendmicro/react-dropdown/dist/react-dropdown.css';

// MobX Stores
import { onForegroundMessage } from './FirebaseConfig';
import Gleap from 'gleap';
import GleapAudioManager from 'helper/AudioManager';
import { Provider as MobxProvider } from 'mobx-react';
import { PostMessageAction } from 'models/PostMessageData';
import 'react-dates/initialize';
import { BrowserRouter } from 'react-router-dom';
import { AsanaIntegrationStore } from 'stores/integrations/AsanaIntegrationStore';
import { AworkIntegrationStore } from 'stores/integrations/AworkIntegrationStore';
import { AzureDevopsIntegrationStore } from 'stores/integrations/AzureDevopsIntegrationStore';
import { AzureIntegrationStore } from 'stores/integrations/AzureIntegrationStore';
import { ClickupIntegrationStore } from 'stores/integrations/ClickupIntegrationStore';
import { DiscordIntegrationStore } from 'stores/integrations/DiscordIntegrationStore';
import { GithubIntegrationStore } from 'stores/integrations/GithubIntegrationStore';
import { GitlabIntegrationStore } from 'stores/integrations/GitlabIntegrationStore';
import { HelpscoutIntegrationStore } from 'stores/integrations/HelpscoutIntegrationStore';
import { HubspotIntegrationStore } from 'stores/integrations/HubspotIntegrationStore';
import { JiraIntegrationStore } from 'stores/integrations/JiraIntegrationStore';
import { LinearIntegrationStore } from 'stores/integrations/LinearIntegrationStore';
import { PlutioIntegrationStore } from 'stores/integrations/PlutioIntegrationStore';
import { ShortcutIntegrationStore } from 'stores/integrations/ShortcutIntegrationStore';
import { SlackIntegrationStore } from 'stores/integrations/SlackIntegrationStore';
import { TrelloIntegrationStore } from 'stores/integrations/TrelloIntegrationsStore';
import { ZendeskIntegrationStore } from 'stores/integrations/ZendeskIntegrationStore';
import { HelpcenterStore } from 'stores/private/HelpcenterStore';
import { ModalStore } from 'stores/private/ModalStore';
import { OutboundStore } from 'stores/private/OutboundStore';
import { PaddleStore } from 'stores/private/PaddleStore';
import { PlanStore } from 'stores/private/PlanStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { PropertyStore } from 'stores/private/PropertyStore';
import { SessionStore } from 'stores/private/SessionStore';
import { SidenavStore } from 'stores/private/SidenavStore';
import { SharedBoardStorePublic } from 'stores/public/SharedBoardStorePublic';
import { SharedNewsStorePublic } from 'stores/public/SharedNewsStorePublic';
import { SharedSessionStore } from 'stores/public/SharedSessionStore';
import Routing from './routing/Routing';
import { NotionIntegrationStore } from './stores/integrations/NotionIntegrationStore';
import { BugStore } from './stores/private/BugStore';
import { OnBoardingStore } from './stores/private/OnBoardingStore';
import { OrganisationStore } from './stores/private/OrganisationStore';
import { UsersStore } from './stores/private/UsersStore';

export let isApp = false;

const usersStore = new UsersStore();
const onBoardingStore = new OnBoardingStore();
const organisationStore = new OrganisationStore();
const projectStore = new ProjectStore();
const paddleStore = new PaddleStore();
const bugStore = new BugStore();
const planStore = new PlanStore();
const modalStore = new ModalStore();
const sessionStore = new SessionStore();
const trelloIntegrationStore = new TrelloIntegrationStore();
const jiraIntegrationStore = new JiraIntegrationStore();
const azureIntegrationStore = new AzureIntegrationStore();
const aworkIntegrationStore = new AworkIntegrationStore();
const githubIntegrationStore = new GithubIntegrationStore();
const gitlabIntegrationStore = new GitlabIntegrationStore();
const asanaIntegrationStore = new AsanaIntegrationStore();
const clickupIntegrationStore = new ClickupIntegrationStore();
const notionIntegrationStore = new NotionIntegrationStore();
const zendeskIntegrationStore = new ZendeskIntegrationStore();
const hubspotIntegrationStore = new HubspotIntegrationStore();
const shortcutIntegrationStore = new ShortcutIntegrationStore();
const plutioIntegrationStore = new PlutioIntegrationStore();
const sharedSessionStore = new SharedSessionStore();
const outboundStore = new OutboundStore();
const helpcenterStore = new HelpcenterStore();
const slackIntegrationStore = new SlackIntegrationStore();
const helpscoutIntegrationStore = new HelpscoutIntegrationStore();
const sidenavStore = new SidenavStore();
const sharedBoardStorePublic = new SharedBoardStorePublic();
const sharedNewsStorePublic = new SharedNewsStorePublic();
const azureDevopsIntegrationStore = new AzureDevopsIntegrationStore();
const linearIntegrationStore = new LinearIntegrationStore();
const propertyStore = new PropertyStore();
const discordIntegrationStore = new DiscordIntegrationStore();

const stores = {
  usersStore,
  onBoardingStore,
  organisationStore,
  paddleStore,
  projectStore,
  bugStore,
  modalStore,
  planStore,
  sessionStore,
  trelloIntegrationStore,
  jiraIntegrationStore,
  azureIntegrationStore,
  aworkIntegrationStore,
  githubIntegrationStore,
  gitlabIntegrationStore,
  asanaIntegrationStore,
  clickupIntegrationStore,
  notionIntegrationStore,
  zendeskIntegrationStore,
  hubspotIntegrationStore,
  shortcutIntegrationStore,
  plutioIntegrationStore,
  outboundStore,
  sharedSessionStore,
  helpcenterStore,
  slackIntegrationStore,
  helpscoutIntegrationStore,
  sidenavStore,
  sharedBoardStorePublic,
  sharedNewsStorePublic,
  azureDevopsIntegrationStore,
  linearIntegrationStore,
  propertyStore,
  discordIntegrationStore,
};

const updateDynamicStyleVariable = () => {
  if (window.innerHeight < 250) {
    setTimeout(() => {
      updateDynamicStyleVariable();
    }, 100);

    return;
  }

  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;

  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  // eslint-disable-next-line no-empty
};

const cleanupWorker = () => {
  // Cleanup for local drafts.
  try {
    if (localStorage.length > 50) {
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key && key.includes('note_draft_')) {
          localStorage.removeItem(key);
        }
      }
    }

    updateDynamicStyleVariable();

    window.addEventListener('resize', (event) => {
      updateDynamicStyleVariable();
    });
  } catch (exp) {}
};

(window as any).fromMobileGleapApp = (messageData) => {
  try {
    const jsonData = JSON.parse(JSON.stringify(messageData));

    const action = jsonData['action'];
    const data = jsonData['data'];

    switch (action) {
      case PostMessageAction.INITIALIZE:
        updateDynamicStyleVariable();

        isApp = true;

        // Hide web Gleap widget.
        Gleap.destroy();

        // Hide social login buttons.
        const socialLoginButton = document.querySelector<HTMLElement>(
          '.social-container-button',
        );

        if (!socialLoginButton) {
          return;
        }

        socialLoginButton.style.display = 'none';

        break;

      case PostMessageAction.FCM_OPENED:
        if (!data || !data.path) {
          return;
        }

        (window as any).location = data.path;
        break;

      default:
        break;
    }
  } catch (err) {}
};

const App = () => {
  const initFirebaseMessaging = async () => {
    // Add listener for forground messages.
    try {
      onForegroundMessage((payload) => {
        GleapAudioManager.ping();
      });
    } catch (exp) {}
  };

  useEffect(() => {
    cleanupWorker();
    initFirebaseMessaging();
  }, []);

  return (
    <MobxProvider {...stores}>
      <BrowserRouter>
        <Routing />
      </BrowserRouter>
      <ToastContainer autoClose={1500} position="top-center" />
    </MobxProvider>
  );
};

export default App;
