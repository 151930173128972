import React, { useEffect, useState } from 'react';
import './TicketViewModal.scss';
import TextInput from 'components/TextInput/TextInput';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import LinkButton from 'components/LinkButton/LinkButton';
import Swal from 'sweetalert2';
import BoardSortFilter from 'components/BoardSortFilter/BoardSortFilter';
import BoardFilter from 'components/BoardFilter/BoardFilter';
import Switch from 'react-switch';
import { TicketView } from 'models/TicketView';
import { HttpTicketViewService } from 'services/http.clients/http.ticket.view.client';
import { UsersStore } from 'stores/private/UsersStore';
import { inject, observer } from 'mobx-react';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';

const viewTypeOptions = [
  {
    label: 'Board',
    value: 'board',
  },
  {
    label: 'List',
    value: 'list',
  },
];

interface TicketViewModalProps {
  ticketView?: TicketView;
  ticketType?: string;
  usersStore?: UsersStore;
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
}

const TicketViewModal = ({
  ticketView,
  ticketType,
  usersStore,
  modalStore,
  projectStore,
}: TicketViewModalProps) => {
  const [title, setTitle] = useState('');
  const [isPublic, setIsPublic] = useState(false);
  const [conditions, setConditions] = useState({});
  const [viewType, setViewType] = useState(
    ticketType !== 'INQUIRY' ? 'board' : 'list',
  );
  const [sortBy, setSortBy] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [initialFilters, setInitialFilters] = useState();
  const [initialSortFilter, setInitialSortFilter] = useState();
  const isUpdate = ticketView && ticketView !== null;

  useEffect(() => {
    if (ticketView) {
      setTitle(ticketView.name);
      setConditions(ticketView.conditions);
      setSortBy(ticketView.sortBy);
      setInitialSortFilter(ticketView.sortBy);
      setIsPublic(ticketView.isPublic);
      setInitialFilters(ticketView.conditions);
      setViewType(ticketView.viewType);
    }
  }, [ticketView]);

  const saveTicketView = async () => {
    setIsLoading(true);

    if (!title || title.length === 0) {
      setIsLoading(false);
      setSubmitted(true);
      return;
    }

    if (isUpdate) {
      const response = await HttpTicketViewService.getInstance().updateOne({
        id: ticketView._id,
        data: {
          name: title,
          conditions,
          sortBy,
          isPublic,
          viewType,
        },
      });
      projectStore?.setCurrentSelectedView(response)
    } else {
      await HttpTicketViewService.getInstance().create({
        data: {
          name: title,
          conditions,
          sortBy,
          isPublic,
          viewType: viewType,
          dataSources: [ticketType ?? 'BUG'],
          users: [usersStore!.currentUser!.id],
        },
      });
    }

    projectStore?.fetchAndSetTicketViews();
    setIsLoading(false);

    modalStore!.closeModal();
  };

  return (
    <div className="content">
      <span className="title">
        {isUpdate ? `Update ${title}` : 'Create a custom view'}
      </span>
      <TextInput
        placeholder="My tickets"
        label="View name"
        value={title}
        onChange={setTitle}
        error={submitted && title.length === 0 ? 'Please enter a title.' : ''}
        required
      />
      <div className="filters mb-20">
        <p className="label">Filter</p>
        <div className="filter-row">
          <BoardSortFilter
            onChange={(sort) => {
              setSortBy(sort);
            }}
            initialFilter={initialSortFilter}
            filterOptions={[
              {
                title: 'Sort manually',
                key: 'lexorank',
              },
              ...(ticketType === 'FEATURE_REQUEST'
                ? [
                    {
                      title: 'By upvotes',
                      key: 'upvotesCount',
                    },
                  ]
                : []),
              {
                title: 'By date',
                key: 'createdAt',
              },
              {
                title: 'By due date',
                key: 'dueDate',
              },
              {
                title: 'By read status',
                key: 'notificationsUnread',
              },
              {
                title: 'By priority',
                key: 'priority',
              },
            ]}
          />
          <BoardFilter
            initialFilters={initialFilters}
            filterUpdated={(conditions) => {
              setConditions(conditions);
            }}
            filterStorageKey={null}
          />
        </div>
      </div>
      {ticketType !== 'INQUIRY' && (
        <SelectDropDown
          className="mb-10"
          label="View type"
          labelPropertyName="label"
          valuePropertyName="value"
          selectedItem={viewTypeOptions.find(
            (option) => option.value === viewType,
          )}
          items={viewTypeOptions}
          onChange={(option) => {
            if (option?.value) {
              setViewType(option.value);
            }
          }}
        />
      )}
      <div className="switch-container mt-25">
        <Switch
          width={40}
          onColor="#2142E7"
          height={20}
          checkedIcon={false}
          uncheckedIcon={false}
          onChange={(checked) => {
            setIsPublic(checked);
          }}
          checked={isPublic}
        />
        <span>Visible to all users</span>
      </div>
      <div className="button-row">
        <PrimaryButton
          disabled={isLoading}
          label={isUpdate ? 'Update' : 'Create'}
          isLoading={isLoading}
          onClick={saveTicketView}
        />
        {isUpdate && (
          <LinkButton
            icon="trash"
            label="Delete"
            iconSideRight={false}
            onClick={async () => {
              Swal.fire({
                text: 'Do you really want to delete this custom view?',
                showCancelButton: true,
                confirmButtonText: `Yes`,
                denyButtonText: `No`,
                showLoaderOnConfirm: isLoading,
              }).then(async (result) => {
                if (result.isConfirmed) {
                  setIsLoading(true);
                  await HttpTicketViewService.getInstance().deleteOne({
                    id: ticketView!._id,
                  });
                  projectStore?.fetchAndSetTicketViews();
                  setIsLoading(false);
                  modalStore!.closeModal();
                }
              });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default inject(
  'usersStore',
  'modalStore',
  'projectStore',
)(observer(TicketViewModal));
