import { GenericHttpClient } from './config/http.generic.client';

export class HttpContactViewService extends GenericHttpClient<any> {
  static _instance: HttpContactViewService;
  static getInstance(): HttpContactViewService {
    if (this._instance == null) {
      this._instance = new HttpContactViewService('/contact-views');
    }
    return this._instance;
  }
}
