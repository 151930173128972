import FutureFactChart from 'components/Charts/FactChart/FutureFactChart';
import FutureBarChart from 'components/Charts/StatisicBarChart/FutureStatisticBarChart';
import Wrap from 'components/LayoutComponents/WrapComponent/WrapComponent';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import SmartDateRangePicker from 'components/SmartDateRangePicker/SmartDateRangePicker';
import { inject, observer } from 'mobx-react';
import { BarChartStatisticTypes, FactTypes } from 'models/Chart';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import {
  getBarChartStatisic,
  getFactStatistic,
} from 'services/StatisticsService';
import { ProjectStore } from 'stores/private/ProjectStore';

interface CustomersupportKaiProps {
  projectStore?: ProjectStore;
}

const CustomersupportKai = ({ projectStore }: CustomersupportKaiProps) => {
  const projectId = projectStore?.currentProject?.id;

  const [startDate, setStartDate] = useState(
    moment().subtract(1, 'months').startOf('day') as Moment | null,
  );
  const [endDate, setEndDate] = useState(
    moment().endOf('day') as Moment | null,
  );

  return (
    <PageContainer>
      <PageHeadLine title="Kai" tag="BETA">
        <SmartDateRangePicker
          startIndex={1}
          onDateChange={(start, end) => {
            setStartDate(start);
            setEndDate(end);
          }}
        />
      </PageHeadLine>
      <PageContent hasTitle>
        <Wrap>
          <FutureFactChart
            infoText="The KAI Deflection Rate metric measures the percentage of interactions involving KAI (your AI chatbot) that successfully deflect users away from needing additional customer support. A higher deflection rate indicates that KAI is effectively handling user inquiries without the need for further human intervention. This is a positive outcome as it reduces the workload on your customer support team."
            width="50%"
            future={getFactStatistic({
              projectId: projectId,
              query: {
                chartType: FactTypes.KAI_DEFLECTION_RATE,
                startDate: startDate?.toDate(),
                endDate: endDate?.toDate(),
              },
            })}
          />
          <FutureFactChart
            infoText="The KAI Routed to Agent Rate metric measures the percentage of interactions involving KAI (your AI chatbot) that are eventually routed to a human customer support agent. This rate reflects the proportion of interactions where KAI was unable to fully resolve the user's issue and required human intervention. A lower routed to agent rate indicates that KAI is effective in handling user inquiries without the need to involve human agents, which can be a cost-saving and efficiency improvement for your customer support operations."
            width="50%"
            future={getFactStatistic({
              projectId: projectId,
              query: {
                chartType: FactTypes.KAI_ROUTED_TO_AGENT_RATE,
                startDate: startDate?.toDate(),
                endDate: endDate?.toDate(),
              },
            })}
            isPositiveProgressGood={false}
          />
          <FutureFactChart
            infoText="The KAI Answered Count metric simply counts the total number of interactions where KAI (the AI chatbot) was involved and successfully provided an answer or resolved the user's query. It represents the instances where KAI effectively handled and responded to user inquiries. This metric can be used to track the overall engagement and performance of KAI in assisting users with their requests."
            width="50%"
            future={getFactStatistic({
              projectId: projectId,
              query: {
                chartType: FactTypes.KAI_ANSWERED_COUNT,
                startDate: startDate?.toDate(),
                endDate: endDate?.toDate(),
              },
            })}
          />
          <FutureFactChart
            infoText="The KAI No Answer Count metric tracks the number of interactions where KAI (the AI chatbot) was involved but did not provide an answer or resolution to the user's query. This metric represents instances where KAI was unable to assist the user effectively and required human intervention or further escalation. Monitoring the no answer count can help identify areas where KAI may need improvement or additional training to handle user inquiries more effectively."
            width="50%"
            future={getFactStatistic({
              projectId: projectId,
              query: {
                chartType: FactTypes.KAI_NO_ANSWER_COUNT,
                startDate: startDate?.toDate(),
                endDate: endDate?.toDate(),
              },
            })}
            isPositiveProgressGood={false}
          />
          <FutureFactChart
            infoText="The count of questions (to the LLM) asked by users."
            title="AI questions asked (new)"
            width="50%"
            futureWithAbortion={async (controller) => {
              const res = await getFactStatistic({
                projectId: projectId,
                query: {
                  chartType: FactTypes.AI_QUESTIONS_ASKED_COUNT,
                  startDate: startDate?.toDate(),
                  endDate: endDate?.toDate(),
                },
                signal: controller.signal,
              });
              return res;
            }}
          />
        </Wrap>
        <FutureBarChart
          infoText="The KAI Involved Data metric tracks instances where KAI (the AI chatbot) was involved in user interactions. It counts the number of times KAI participated in conversations, providing support, answers, or assistance to users. Monitoring KAI's involvement can help assess its performance and effectiveness in handling user inquiries over time."
          future={getBarChartStatisic({
            projectId: projectId,
            query: {
              chartType: BarChartStatisticTypes.KAI_INVOLVED,
              startDate: startDate?.toDate(),
              endDate: endDate?.toDate(),
              timezone: moment.tz.guess(),
            },
          })}
        />
        <FutureBarChart
          infoText="The KAI Involved Data metric tracks instances where KAI (the AI chatbot) was involved in user interactions. It counts the number of times KAI participated in conversations, providing support, answers, or assistance to users. Monitoring KAI's involvement can help assess its performance and effectiveness in handling user inquiries over time."
          future={getBarChartStatisic({
            projectId: projectId,
            query: {
              chartType: BarChartStatisticTypes.CUSTOMER_SUPPORT_REQUESTED,
              startDate: startDate?.toDate(),
              endDate: endDate?.toDate(),
              timezone: moment.tz.guess(),
            },
          })}
        />
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore')(observer(CustomersupportKai));
