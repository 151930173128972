import { inject, observer } from 'mobx-react';
import { ModalStore } from 'stores/private/ModalStore';
import './ActionEditorModal.scss';
import AnswerBotActionEditor from './AnswerBotActionEditor';
import AssignUserActionEditor from './AssignUserActionEditor';
import BotActionEditor from './BotActionEditor';
import ConditionActionEditor from './ConditionActionEditor';
import CreateTicketActionEditor from './CreateTicketActionEditor';
import FeedbackflowActionEditor from './FeedbackflowActionEditor';
import InputActionEditor from './InputActionEditor';
import LinkActionEditor from './LinkActionEditor';
import MessageActionEditor from './MessageActionEditor';
import RatingActionEditor from './RatingActionEditor';
import TagActionEditor from './TagActionEditor';
import APIActionEditor from './APIActionEditor';
import ExpectedReplyTimeModal from './ExpectedReplyTimeModal';
import CalendlyActionEditor from './CalendlyActionEditor';
import ChatInputActionEditor from './ChatInputActionEditor';
import CloseTicketActionEditor from './CloseTicketActionEditor';
import NoteActionEditor from './NoteActionEditor';
import CustomActionEditor from './CustomActionEditor';
import SetDataAttributeEditor from './SetDataAttributeEditor';
import { ActionTypes } from 'models/Bot';
import ChangeTicketStatusActionEditor from './ChangeTicketStatusActionEditor';
import AiTagActionEditor from './AiTagActionEditor';

interface ActionEditorModalProps {
  modalStore?: ModalStore;
}

const ActionEditorModal = ({ modalStore }: ActionEditorModalProps) => {
  const action = modalStore?.customData?.action;
  if (!action) {
    return null;
  }

  const renderActionEditor = () => {
    if (action.type === 'calendly') {
      return <CalendlyActionEditor action={action} />;
    }
    if (action.type === 'message') {
      return <MessageActionEditor action={action} />;
    }
    if (action.type === 'addnote') {
      return <NoteActionEditor action={action} />;
    }
    if (action.type === 'ticketupdate-tag') {
      return <TagActionEditor action={action} />;
    }
    if (action.type === 'ticketupdate-aitag') {
      return <AiTagActionEditor action={action} />;
    }
    if (action.type === 'ticketupdate-close') {
      return <CloseTicketActionEditor action={action} />;
    }
    if (action.type === 'ticketupdate-assign') {
      return <AssignUserActionEditor action={action} />;
    }
    if (action.type === 'ticketupdate-status') {
      return <ChangeTicketStatusActionEditor action={action} />;
    }
    if (action.type === 'createticket') {
      return <CreateTicketActionEditor action={action} />;
    }
    if (action.type === 'input') {
      return <InputActionEditor action={action} />;
    }
    if (action.type === 'chatinput') {
      return <ChatInputActionEditor action={action} />;
    }
    if (action.type === 'api') {
      return <APIActionEditor action={action} />;
    }
    if (action.type === 'custom-action') {
      return <CustomActionEditor action={action} />;
    }
    if (action.type === 'link') {
      return <LinkActionEditor action={action} />;
    }
    if (action.type === 'rateconversation') {
      return <RatingActionEditor action={action} />;
    }
    if (action.type === 'botflow') {
      return <BotActionEditor action={action} />;
    }
    if (action.type === 'answerbotflow') {
      return <AnswerBotActionEditor action={action} />;
    }
    if (action.type === 'feedbackflow') {
      return <FeedbackflowActionEditor action={action} />;
    }
    if (action.type === 'condition') {
      return <ConditionActionEditor condition={action} />;
    }
    if (action.type === 'replytimes') {
      return <ExpectedReplyTimeModal />;
    }
    if (action.type === ActionTypes.TICKETUPDATE_DATA) {
      return <SetDataAttributeEditor action={action} />;
    }
    return <>{JSON.stringify(action)}</>;
  };

  return <>{renderActionEditor()}</>;
};

export default inject('modalStore')(observer(ActionEditorModal));
