import { useState, useEffect } from 'react';

function useNotificationPermission() {
    const [permission, setPermission] = useState(typeof Notification === 'undefined' ? null : Notification.permission);
    const hasPermissionsNav = typeof navigator !== 'undefined' && typeof navigator.permissions !== 'undefined';

    useEffect(() => {
        const handlePermissionChange = () => {
            setPermission(Notification.permission);
        };

        if (hasPermissionsNav) {
            navigator.permissions.query({ name: 'notifications' }).then((notificationPerm) => {
                if (typeof Notification !== 'undefined') {
                    notificationPerm.onchange = handlePermissionChange;
                }
            });
        }

        return () => {
            if (hasPermissionsNav) {
                navigator.permissions.query({ name: 'notifications' }).then((notificationPerm) => {
                    notificationPerm.onchange = null;
                });
            }
        };
    }, []);

    return permission;
}

export default useNotificationPermission;