import classNames from 'classnames';
import './TabButton.scss';

interface TabButtonProps {
  label?: string;
  onClick: any;
  icon?: string;
  active?: boolean;
  className?: string;
  tagLabel?: string;
  primaryColor?: boolean;
  onEdit?: any;
}

const TabButton = ({
  label,
  onClick,
  icon,
  active,
  className,
  tagLabel,
  primaryColor,
  onEdit,
}: TabButtonProps) => {
  const TabButtonClassName = classNames(`tab-button ${className}`, {
    'tab-button--active': active,
    'tab-button--hastag': tagLabel && tagLabel.length > 0,
    'tab-button--primary': primaryColor,
  });

  return (
    <button type="button" className={TabButtonClassName} onClick={onClick}>
      {!label && icon && <i className={`fa fa-${icon}-only`} />}
      {icon && <i className={`fa fa-${icon}`} />}
      {label}
      {tagLabel && <span className="tab-button--tag">{tagLabel}</span>}
      {onEdit && active && (
        <i className="edit-icon ml-10 fa fa-pencil" onClick={onEdit} />
      )}
    </button>
  );
};

export default TabButton;
