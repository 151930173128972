import axios from '../Axios';

const getAuthCodeFromAzureDevops = (projectID) => {
  return window.open(
    `${process.env.REACT_APP_AZURE_DEVOPS_CONNECT_URL}?client_id=${process.env.REACT_APP_AZURE_DEVOPS_CLIENT_ID}&response_type=Assertion&state=${projectID}&redirect_uri=${process.env.REACT_APP_BASEURL}/integrations/azureDevops&scope=vso.identity vso.project_write vso.taskgroups_write vso.work_full`,
    '_self',
  );
};

const setAzureDevopsAuthToken = (code, projectID) => {
  return axios.post(`/projects/${projectID}/integrations/azureDevops`, {
    code,
  });
};

const setAzureDevopsActions = ({
  projectID,
  integrationID,
  actions,
  settings,
}) => {
  return axios.put(
    `/projects/${projectID}/integrations/azureDevops/${integrationID}`,
    {
      actions,
      settings,
    },
  );
};

const disconnectAzureDevopsIntegration = ({ projectID, integrationID }) => {
  return axios.delete(
    `/projects/${projectID}/integrations/azureDevops/${integrationID}`,
  );
};

const getOrganizations = ({ projectID, integrationID }) => {
  return axios.get(
    `/projects/${projectID}/integrations/azureDevops/${integrationID}/organizations`,
  );
};

const getProjects = ({ projectID, integrationID, organizationID }) => {
  return axios.get(
    `/projects/${projectID}/integrations/azureDevops/${integrationID}/organizations/${organizationID}/projects`,
  );
};

const getWorkItemTypesForProject = ({
  projectID,
  integrationID,
  organizationID,
  devopsProjectID,
}) => {
  return axios.get(
    `/projects/${projectID}/integrations/azureDevops/${integrationID}/organizations/${organizationID}/projects/${devopsProjectID}/workItemTypes`,
  );
};

export {
  setAzureDevopsAuthToken,
  getAuthCodeFromAzureDevops,
  setAzureDevopsActions,
  disconnectAzureDevopsIntegration,
  getOrganizations,
  getProjects,
  getWorkItemTypesForProject,
};
