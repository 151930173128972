import Comment from 'components/Comment/Comment';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import MobileCommunicationManager from 'helper/MobileCommunicationManager';
import React, { useEffect, useState } from 'react';
import './MobileComments.scss';
import TicketInfoComment from 'components/BugDetail/Details/TicketInfoComment';
import { CommentTypes } from 'models/Comment';
import { PropertyStore } from 'stores/private/PropertyStore';
import { inject, observer } from 'mobx-react';

interface MobileCommentsProps {
  propertyStore?: PropertyStore;
}

const MobileComments = ({ propertyStore }: MobileCommentsProps) => {
  const [ticket, setTicket] = useState(null as any);
  const [comments, setComments] = useState([]);
  const [propertiesInitialized, setPropertiesInitialized] = useState(false);

  useEffect(() => {
    MobileCommunicationManager.getInstance().registerCallback(
      'SET_TICKET',
      (data) => {
        setTicket(data);
      },
    );

    MobileCommunicationManager.getInstance().registerCallback(
      'SET_COMMENTS',
      (data) => {
        setComments(data);
      },
    );
  }, []);

  useEffect(() => {
    if (ticket?.shareToken && !propertiesInitialized) {
      setPropertiesInitialized(true);
      propertyStore?.fetchAndSetPropertiesForSharedTicket(ticket.shareToken);
    }
  }, [ticket]);

  return (
    <Column className="mobile-comments-wrapper">
      <TicketInfoComment
        ticket={ticket}
        allowActions={false}
        shared={false}
        loadingComments={false}
      />
      {comments
        .filter((comment: any) => {
          if (comment.type !== CommentTypes.FEEDBACK_UPDATED) {
            return comment;
          }

          return null;
        })
        .map((comment) => {
          return <Comment comment={comment} shared={false} />;
        })}
    </Column>
  );
};

export default inject('propertyStore')(observer(MobileComments));
