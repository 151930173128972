import React from 'react';
import LinkButton from 'components/LinkButton/LinkButton';
import ListTable, { CellText } from 'components/ListTable/ListTable';
import { inject, observer } from 'mobx-react';
import { useSearchParams } from 'react-router-dom';
import { SessionStore } from 'stores/private/SessionStore';
import '../../../components/ListTable/ListTable.scss';

interface OrganisationListProps {
    sessionStore?: SessionStore;
}

const OrganisationList = ({ sessionStore }: OrganisationListProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const columns = [
        {
            width: '30%',
            Header: 'Company name',
            accessor: 'name',
            Cell: (row) => {
                return (
                    <div className='cell-link' onClick={() => {
                        let params = {companyId: row?.cell?.row?.values?._id};
                        if (row?.value) {
                            params["companyName"] = row?.value;
                        }
                        setSearchParams(params)
                    }}>
                        <span>
                            {row.value || "Unknown"}
                        </span>
                    </div>
                )
            }
        },
        {
            className: 'hide-on-mobile',
            width: '15%',
            Header: 'Company id',
            accessor: '_id',
            Cell: (row) => <CellText text={row.value} />,
        },
    ];
    let organisations = sessionStore?.sessionsDataList.data ?? [];

    return (
        <div>
            <ListTable data={organisations} columns={columns} />
            {sessionStore?.sessionsDataList &&
                sessionStore?.sessionsDataList.data.length > 0 &&
                !sessionStore?.sessionsDataList.isLoading && (
                    <div className="mt-20">
                        {sessionStore?.sessionsDataList?.hasMore ? (
                            <LinkButton
                                isLoading={sessionStore?.sessionsDataList?.isLoading}
                                label="Load more"
                                onClick={() => {
                                    sessionStore!.getSessionItems({
                                        type: "organisations",
                                        withFeedback: false,
                                        loadMore: true,
                                    });
                                }}
                            />
                        ) : (
                            <div className="text">No more organisations to load</div>
                        )}
                    </div>
                )}
        </div>
    );
};

export default inject('sessionStore')(observer(OrganisationList));
