import FutureFactChart from 'components/Charts/FactChart/FutureFactChart';
import FutureStatisticDataTable, {
  StatisticListTypes,
} from 'components/Charts/StatisticDataTable/FutureStatisticDataTable';
import Wrap from 'components/LayoutComponents/WrapComponent/WrapComponent';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import { inject, observer } from 'mobx-react';
import { FactTypes } from 'models/Chart';
import { getFactStatistic, getListStatistic } from 'services/StatisticsService';
import { ProjectStore } from 'stores/private/ProjectStore';

interface CustomerSupportTeamAvailabilityProps {
  projectStore?: ProjectStore;
}

const CustomerSupportTeamAvailability = ({
  projectStore,
}: CustomerSupportTeamAvailabilityProps) => {
  const projectId = projectStore?.currentProject?.id;

  return (
    <PageContainer>
      <PageHeadLine title="Team availability" tag="Snapshot" />
      <PageContent hasTitle>
        {/*<Wrap>
          <FutureFactChart
            width="33.33%"
            futureWithAbortion={async (controller) => {
              const res = await getFactStatistic({
                projectId: projectId,
                query: {
                  chartType: FactTypes.SNAPSHOT_AGENTS_ONLINE_COUNT,
                },
                signal: controller.signal,
              });
              return res;
            }}
          />
        </Wrap>*/}
        <FutureStatisticDataTable
          infoText="Teammates online by team."
          showReloadButton
          future={async () => {
            return getListStatistic({
              projectId: projectId,
              query: {
                chartType: StatisticListTypes.LIVE_AGENTS_TEAM,
              },
            });
          }}
        />
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore')(
  observer(CustomerSupportTeamAvailability),
);
