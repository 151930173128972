import { COLORS } from '../styles/Constants';
import { Comment } from './Comment';
import { Session } from './Session';
import { User } from './User';

export type Bug = {
  priority: 'HIGH' | 'MEDIUM' | 'LOW';
  processingUser?: User;
  processingTeam?: string;
  mentions?: string[];
  status: string;
  hideDetailTabsOnSharedBugs?: boolean;
  dueDate?: Date;
  createdAt: any;
  updatedAt: any;
  type: string;
  isSpam: boolean;
  title: string;
  content: any;
  originalEmailContent?: string;
  description: string;
  session?: Session;
  slaBreached?: boolean;
  sessions?: Session[];
  reportedBy: string;
  latestComment?: Comment;
  reportedByName?: string;
  screenshotBroke?: Date;
  id: string;
  lexorank: string;
  shareToken: string;
  secretShareToken?: string;
  bugId: number;
  metaData: any;
  screenshotUrl: string;
  consoleLog: [];
  actionLog: [];
  parentTicket?: Bug;
  attachments: [
    {
      name: string;
      url: string;
      type: string;
    },
  ];
  customData: {};
  generatingScreenshot?: Boolean;
  screenshotLive?: Boolean;
  screenRecordingUrl?: string;
  formData: {
    [key: string]: any;
  };
  form?: any;
  upvotesCount?: number;
  upvotes?: [
    {
      email: string;
    },
  ];
  pagerating?: number;
  replay?: {
    frames: {
      url: string;
      date: Date;
      screenname: string;
    }[];
    interval: number;
  };
  replayDataUrl?: string;
  hasWebReplay: boolean;
  project: any;
  plan: any;
  notificationsUnread?: Boolean;
  snoozedUntil?: Date;
  lastNotification?: Date;
  duplicateOf?: string;
  duplicatesCount?: number;
  archived?: Boolean;
  archivedAt?: Date;
  tags?: string[];
  integrations?: any;
  plainContent?: string;
};

export const BugPriorities = {
  LOW: {
    title: 'Low',
    value: 'LOW',
    color: COLORS.BUGBATTLE_GREEN,
  },
  MEDIUM: {
    title: 'Medium',
    value: 'MEDIUM',
    color: COLORS.BUGBATTLE_ORANGE,
  },
  HIGH: {
    title: 'High',
    value: 'HIGH',
    color: COLORS.BUGBATTLE_RED,
  },
};
