import { inject, observer } from 'mobx-react';
import TimeAgo from 'react-timeago';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import englishShortStrings from 'react-timeago/lib/language-strings/en-short';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
const formatter = buildFormatter(englishShortStrings);

interface RecentEventsItemProps {
  event: any;
  modalStore?: ModalStore;
}

const RecentEventsItem = ({ event, modalStore }: RecentEventsItemProps) => {
  return (
    <>
      <div className="recent-events-item">
        <TimeAgo formatter={formatter} date={event.date ? event.date : event.createdAt} />
        <div className="recent-events-item-line"></div>
        <div
          className="recent-events-item-title"
          onClick={() => {
            modalStore!.openModalSmart(MODALTYPE.SHOW_EVENT_DATA, event);
          }}
        >
          <span className='title-block'>{event.name}</span>
          {event.name === "pageView" && event.data?.page && (typeof event.data?.page === 'string') && <span className='data-link'><a href={event.data.page} target="_blank" rel="noreferrer">{event.data.page}</a></span>}
        </div>
      </div>
    </>
  );
};

export default inject('modalStore')(observer(RecentEventsItem));
