import React, {
  ForwardedRef,
  TextareaHTMLAttributes,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import './TextAreaPreviewField.scss';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import { useClickedOutside } from 'services/Helper';
import { toast } from 'react-toastify';
import copyToClipboard from 'copy-to-clipboard';
import AddValue from 'components/AddValue/AddValue';
import Linkify from 'linkify-react';

interface TextAreaPreviewFieldProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {}

const TextAreaPreviewField = forwardRef(
  (
    {
      className,
      onBlur,
      readOnly,
      defaultValue,
      ...props
    }: TextAreaPreviewFieldProps,
    ref: ForwardedRef<HTMLTextAreaElement>,
  ): JSX.Element => {
    const [isEditing, setIsEditing] = useState(false);
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const wrapperRef = useRef(null);
    useClickedOutside(wrapperRef, () => {
      setIsEditing(false);
      onBlur && onBlur(null as any);
    });

    useEffect(() => {
      if (isEditing && textareaRef?.current) {
        setTimeout(() => {
          if (isEditing && textareaRef?.current) {
            try {
              textareaRef.current.focus();
              textareaRef.current.setSelectionRange(
                textareaRef.current.value.length,
                textareaRef.current.value.length,
              );
            } catch (e) {}
          }
        }, 0);
      }
    }, [isEditing]);

    const setIsEditingActive = () => {
      if (readOnly) {
        return;
      }

      setIsEditing(true);
    };

    if (!isEditing) {
      return (
        <div
          className="textarea-preview-container"
          onClick={(event) => {
            event.stopPropagation();
            if ((event?.target as any)?.tagName?.toLowerCase() === 'a') {
              return;
            }
            
            setIsEditingActive();
          }}
        >
          <Linkify
            tagName="span"
            options={{
              target: {
                url: '_blank',
              },
            }}
          >
            {defaultValue || <AddValue />}
          </Linkify>
          <Row className="hover-action-row">
            {!readOnly && (
              <div
                className="hover-action mr-5"
                onClick={(event) => {
                  event.stopPropagation();
                  setIsEditingActive();
                }}
              >
                <i className="fas fa-pencil-alt hover-action-icon"></i>
              </div>
            )}
            <div
              className="hover-action"
              onClick={(event) => {
                event.stopPropagation();
                copyToClipboard((defaultValue || '')?.toString());
                toast.success('Copied to clipboard');
              }}
            >
              <i className="fas fa-clipboard hover-action-icon"></i>
            </div>
          </Row>
        </div>
      );
    }

    return (
      <div ref={wrapperRef}>
        <textarea
          className="textarea-preview"
          ref={textareaRef}
          {...props}
          defaultValue={defaultValue}
        />
      </div>
    );
  },
);

export default TextAreaPreviewField;
