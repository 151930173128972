import ConditionTag from 'components/ConditionTag/ConditionTag';
import { inject, observer } from 'mobx-react';
import { OutboundCondition } from 'models/OutboundCondition';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { ProjectStore } from 'stores/private/ProjectStore';
import './ConditionPicker.scss';

interface ConditionPickerProps {
  projectStore?: ProjectStore;
  streamedEventKeys: {
    label: string;
    value: string;
    type: string;
    icon?: string;
  }[];
  conditions: OutboundCondition[];
  onChange?: any;
  type?: 'audience' | 'trigger';
  absoluteTriggerOptions?: any[];
  relativeTriggerOptions?: any[];
}

const ConditionPicker = ({
  projectStore,
  streamedEventKeys,
  conditions,
  onChange,
  type = 'trigger',
  absoluteTriggerOptions,
  relativeTriggerOptions,
}: ConditionPickerProps) => {
  const [internalConditions, setInternalConditions] = useState([] as any[]);
  const MAX_CONDITIONS = type === 'trigger' ? 1 : 10;
  const userProperties = projectStore?.userFilterProperties ?? [];

  useEffect(() => {
    if (projectStore?.userFilterProperties.length === 0) {
      projectStore?.updateUserFilterProperties();
    }
  }, []);

  useEffect(() => {
    setInternalConditions(conditions);
  }, [conditions]);

  const updateCondition = (index, condition: any) => {
    const newConditions = [...internalConditions];
    newConditions[index] = condition;
    setInternalConditions(newConditions);
    if (onChange) {
      onChange(newConditions);
    }
  };

  const addCondition = (eventName) => {
    var eventData = {};

    if (type === 'trigger') {
      eventData = {
        type: 'lastoccured',
      };
    }

    if (userProperties[eventName]) {
      eventData = {
        type: userProperties[eventName].eventType,
      };
    }

    const newConditions = [
      ...internalConditions,
      {
        event: eventName,
        initiallyOpened: true,
        ...eventData,
      },
    ];
    setInternalConditions(newConditions);
    if (onChange) {
      onChange(newConditions);
    }
  };

  const removeCondition = (index) => {
    var newArray = [...internalConditions];
    newArray.splice(index, 1);
    setInternalConditions(newArray);
    if (onChange) {
      onChange(newArray);
    }
  };

  const renderCreateCondition = () => {
    if (internalConditions.length > MAX_CONDITIONS - 1) {
      return null;
    }

    return (
      <Select
        className="dropdown-selection"
        classNamePrefix="dropdown-selection"
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        placeholder={
          internalConditions.length > 0
            ? '+'
            : type === 'trigger'
            ? '+ Add event'
            : '+ Add audience filter'
        }
        components={{
          SingleValue: (option: any) => {
            if (!option.data.value) {
              return (
                <div className="outbound-select-event">+ Select event</div>
              );
            }
            return <>{option.data.label}</>;
          },
          Option: (option: any) => {
            const getIcon = () => {
              if (option.data.icon) {
                return option.data.icon;
              } else if (option.data.type === 'user') {
                return 'user';
              } else if (option.data.type === 'calendar') {
                return 'calendar';
              } else {
                return 'bullseye-arrow';
              }
            };
            return (
              <div
                className="option option-list-item"
                onClick={() => {
                  if (option.selectOption) {
                    option.selectOption(option.data.value);
                  }
                }}
              >
                <i className={`fa-solid fa-${getIcon()}`} />
                {option.data.label}
              </div>
            );
          },
        }}
        onChange={(data: any) => {
          if (data) {
            addCondition(data);
          }
        }}
        options={[
          ...Object.keys(userProperties).map((key) => {
            return {
              ...userProperties[key],
              value: key,
            } as any;
          }),
          ...streamedEventKeys,
        ]}
      />
    );
  };

  return (
    <div className="condition-picker">
      <div className="condition-picker-conditions">
        {internalConditions.map((condition, index) => {
          const hasNext = internalConditions.length > index + 1;
          return (
            <>
              <ConditionTag
                userProperties={userProperties}
                type={type}
                streamedEventKeys={streamedEventKeys}
                key={`condition-${condition.event}-${index}`}
                className="mb-10"
                value={condition}
                onChange={(newValue) => {
                  updateCondition(index, newValue);
                }}
                onRemove={() => {
                  removeCondition(index);
                }}
                absoluteTriggerOptions={absoluteTriggerOptions}
                relativeTriggerOptions={relativeTriggerOptions}
              />
              {hasNext && (
                <div className="condition-picker-conditions-operator">and</div>
              )}
            </>
          );
        })}
        {renderCreateCondition()}
      </div>
    </div>
  );
};

export default inject('projectStore')(observer(ConditionPicker));
