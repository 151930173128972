import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import {
  getLanguageProperty,
  setLanguageProperty,
} from 'helper/AssignObjectKeysHelper';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Action } from 'models/Bot';
import Switch from 'react-switch';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './RatingActionEditor.scss';
import InfoBox from 'components/InfoBox/InfoBox';

interface RatingActionEditorProps {
  action: Action;
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
}

const RatingActionEditor = ({
  action,
  modalStore,
  projectStore,
}: RatingActionEditorProps) => {
  const currentLang = projectStore?.currentLanguage ?? 'en';

  return (
    <div className="rating-action-editor" key={currentLang}>
      <PageContainer>
        <PageHeadLine title="Rate conversation">
          <div className="header-content-right">
            <PrimaryButton
              label="Close"
              iconSideRight={false}
              onClick={() => {
                modalStore!.closeModal();
              }}
            />
          </div>
        </PageHeadLine>
        <PageContent hasTitle>
          <div className="text mb-20">
            The rate conversation action sends a CSAT (emoji) rating to the
            customer. The rating will then be applied to the conversation rating
            for this conversation.
          </div>
          <TextInput
            className="mr-10 mt-30"
            name="question-value"
            label="Question"
            initalValue={getLanguageProperty(action, 'title', currentLang)}
            localizedable
            translationObject={action?.title}
            placeholder=""
            type="text"
            error=""
            onChange={(val) => {
              runInAction(() => {
                setLanguageProperty(action, `title`, currentLang, val);
              });
            }}
          />
          <div className="switch-container mt-20">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={(checked) => {
                runInAction(() => {
                  action!.hideHistory = checked;
                });
              }}
              checked={action!.hideHistory ?? false}
            />
            <span>
              Hide conversation history in email.
            </span>
          </div>
          <TextInput
            className="mt-30"
            name="question-value"
            label="Additional message"
            initalValue={getLanguageProperty(action, 'emailInfoMessage', currentLang)}
            localizedable
            translationObject={action?.emailInfoMessage}
            placeholder=""
            type="text"
            error=""
            onChange={(val) => {
              runInAction(() => {
                setLanguageProperty(action, `emailInfoMessage`, currentLang, val);
              });
            }}
          />
          <div className="switch-container mt-30">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={(checked) => {
                runInAction(() => {
                  action!.overrideEmailTitle = checked;
                });
              }}
              checked={action!.overrideEmailTitle ?? false}
            />
            <span>
              Override the email subject.
            </span>
          </div>
          {action?.overrideEmailTitle && <>
            <TextInput
              className="mt-20"
              name="question-value"
              label="Email subject"
              initalValue={getLanguageProperty(action, 'emailTitleOverride', currentLang)}
              localizedable
              translationObject={action?.emailTitleOverride}
              placeholder=""
              type="text"
              error=""
              onChange={(val) => {
                runInAction(() => {
                  setLanguageProperty(action, `emailTitleOverride`, currentLang, val);
                });
              }}
            />
            <InfoBox className="mt-20">
              The email title override will be used as the email subject. You can use the placeholders <b>#ticketId</b> and <b>#ticketTitle</b>, which will be replaced with the ticket id and title (if set).
            </InfoBox>
          </>}
        </PageContent>
      </PageContainer>
    </div>
  );
};

export default inject(
  'modalStore',
  'projectStore',
)(observer(RatingActionEditor));
