import React, {
  ForwardedRef,
  InputHTMLAttributes,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import './TextInputPreviewField.scss';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import { useClickedOutside } from 'services/Helper';
import { toast } from 'react-toastify';
import copyToClipboard from 'copy-to-clipboard';
import AddValue from 'components/AddValue/AddValue';
import Linkify from 'linkify-react';

interface TextInputPreviewFieldProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'className'> {
  label?: string;
}

const TextInputPreviewField = forwardRef(
  (
    {
      label,
      defaultValue,
      onBlur,
      readOnly,
      ...props
    }: TextInputPreviewFieldProps,
    ref: ForwardedRef<HTMLInputElement>,
  ): JSX.Element => {
    const [isEditing, setIsEditing] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const wrapperRef = useRef(null);
    useClickedOutside(wrapperRef, () => {
      setIsEditing(false);
      onBlur && onBlur(null as any);
    });

    useEffect(() => {
      if (isEditing && inputRef?.current) {
        setTimeout(() => {
          if (isEditing && inputRef?.current) {
            try {
              inputRef.current.focus();
            } catch (e) {}
          }
        }, 0);
      }
    }, [isEditing]);

    const setIsEditingActive = () => {
      if (readOnly) {
        return;
      }

      setIsEditing(true);
    };

    if (!isEditing) {
      return (
        <div
          className={`text-input-preview-container ${readOnly && 'text-input-preview-container--read-only'
            }`}
          onClick={(event) => {
            event.stopPropagation();
            if ((event?.target as any)?.tagName?.toLowerCase() === 'a') {
              return;
            }

            setIsEditingActive();
          }}
        >
          <Linkify
            options={{
              target: {
                url: '_blank',
              },
            }}
          >
            {defaultValue !== null && defaultValue !== undefined ? defaultValue : (readOnly ? '--' : <AddValue />)}
          </Linkify>
          <Row className="hover-action-row">
            {!readOnly && (
              <div
                className="hover-action mr-5"
                onClick={(event) => {
                  event.stopPropagation();
                  setIsEditingActive();
                }}
              >
                <i className="fas fa-pencil-alt hover-action-icon"></i>
              </div>
            )}
            <div
              className="hover-action"
              onClick={(event) => {
                event.stopPropagation();
                copyToClipboard((defaultValue || '')?.toString());
                toast.success('Copied to clipboard');
              }}
            >
              <i className="fas fa-clipboard hover-action-icon"></i>
            </div>
          </Row>
        </div>
      );
    }

    return (
      <div ref={wrapperRef}>
        <input
          className="text-input-preview"
          ref={inputRef}
          {...props}
          defaultValue={defaultValue}
        />
      </div>
    );
  },
);

export default TextInputPreviewField;
