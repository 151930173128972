import { inject, observer } from 'mobx-react';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './MoveOutboundModal.scss';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import { HeadLine } from 'components/HeadLine/HeadLine';
import LinkButton from 'components/LinkButton/LinkButton';
import { OutboundStore } from 'stores/private/OutboundStore';
import { toast } from 'react-toastify';
import { useState } from 'react';

interface MoveOutboundModalProps {
    projectStore?: ProjectStore;
    modalStore?: ModalStore;
    outboundStore?: OutboundStore;
}

const MoveOutboundModal = ({
    modalStore,
    projectStore,
    outboundStore,
}: MoveOutboundModalProps) => {
    const [selectedProject, setSelectedProject] = useState(null);
    const data = modalStore?.customData;
    const isButtonDisabled = !selectedProject;

    return (
        <div className="move-article-modal">
            <div className="move-article-modal__header mb-10">
                <HeadLine
                    title={"Move this outreach into another project"}
                    subtitle={data.outbound.name}
                ></HeadLine>
            </div>
            <SelectDropDown
                label="Select project"
                items={(projectStore?.projects || [])
                    .filter((project) => project.id !== modalStore?.customData.outbound.project)
                    .map((item) => ({
                        id: item.id,
                        label: item.name,
                        organizationId: item.organisation?.id,
                    }))}
                onChange={(e) => {
                    const newProjectId = e.id;
                    const newOrganizationId = e.organizationId;

                    if (data) {
                        data.outbound.project = newProjectId;
                        data.outbound.organisation = newOrganizationId;
                        setSelectedProject(newProjectId);
                    }
                }}
            />
            <div className='mt-10'>
                <LinkButton
                    label="Move article"
                    onClick={() => {
                        outboundStore?.moveOutbound(data.outbound);
                        modalStore?.closeModal();
                        toast.success('Outreach moved 🎉');
                    }}
                    disabled={isButtonDisabled}
                />
            </div>
        </div>
    );
};

export default inject(
    'modalStore',
    'projectStore',
    'helpcenterStore',
    'outboundStore'
)(observer(MoveOutboundModal));
