import LoadingAnimationSmall from 'components/LoadingAnimationSmall/LoadingAnimationSmall';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { inject, observer } from 'mobx-react';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import React, { useCallback, useEffect, useState } from 'react';
import { ProjectStore } from 'stores/private/ProjectStore';
import { searchForSessions } from 'services/ProjectHttpService';
import { HttpSessionService } from 'services/http.clients/http.session.client';
import TextInput from 'components/TextInput/TextInput';
import { cleanupName } from 'components/FeedbackUserFilter/FeedbackUserFilter';
import { getSessionName } from 'services/GuestNameHelper';
import './RecipientPicker.scss';
import { Session } from 'models/Session';
import classNames from 'classnames';
import _ from 'lodash';

interface RecipientPickerProps {
    sessions?: Session[] | null;
    initialSessionId?: string[];
    onChange: (sessions: Session[] | null) => void;
    placeholder?: string;
    label?: string;
    multipleSessions?: boolean;
    projectStore?: ProjectStore;
    icon?: string;
    className?: string;
    smallText?: boolean;
    placeholderClassName?: string;
}

const RecipientPicker = ({
    projectStore,
    placeholder,
    onChange,
    sessions,
    multipleSessions,
    label,
    icon,
    className,
    initialSessionId,
    smallText,
    placeholderClassName
}: RecipientPickerProps) => {
    const [fullTextFilter, setFullTextFilter] = useState('');
    const [sessionSearchResults, setSessionSearchResults] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [isUsed, setIsUsed] = useState(false);
    const inputRef = React.useRef<HTMLInputElement>(null);

    const debounceSearch = useCallback(
        debounce(async (_fullTextFilter) => {
            setIsSearching(true);
            const response = await searchForSessions({
                projectId: projectStore?.currentProject?.id ?? '',
                query: {
                    searchTerm: _fullTextFilter,
                },
            });
            if (response.status === 200) {
                const items = response.data.filter(
                    (session) =>
                        !sessions?.map((s: any) => s._id).includes(session._id),
                ).filter((obj, index, self) => {
                    if (obj.userId) return true;
                    const firstIndex = self.findIndex(item => item.email === obj.email && !item.userId);
                    return firstIndex === index;
                });
                setSessionSearchResults(items);
            }
            setIsSearching(false);
        }, 500),
        [sessions],
    );

    useEffect(() => {
       !isUsed && loadInitialData();
    }, [initialSessionId])

    const loadInitialData = async () => {
        if (initialSessionId && initialSessionId.length > 0 && sessions?.length === 0) {

            if (multipleSessions) {
                await loadSessionDetails(undefined, initialSessionId);
                return;
            }
            if (initialSessionId[0]) {
                loadSessionDetails(initialSessionId[0]);
            }
        }
    }

    useEffect(() => {
        if (projectStore?.currentProject && fullTextFilter.length > 0) {
            debounceSearch(fullTextFilter);
        } else {
            setSessionSearchResults([]);
        }
    }, [fullTextFilter]);

    const loadSessionDetails = async (sessionId?: string, sessionList?: string[]) => {
        try {
            if (sessionList && sessionList.length > 0) {
                const initSessions = await Promise.all(
                    sessionList.map(async (id) => {
                        return await HttpSessionService.getInstance().findOne({
                            id: id,
                        });
                    }),
                );
                if (initSessions) {
                    // @ts-ignore
                    const filteredSessions: Session[] = initSessions.filter((session: any) => session);
                    const areArraysEqual = _.isEqual(filteredSessions, sessions);
                    console.log("filteredSessions", filteredSessions)
                    console.log("sessions", sessions)
                    if (!areArraysEqual) {
                        setIsUsed(true);
                        onChange([...(sessions ?? []), ...filteredSessions]);
                    }
                }
                return;
            }
            if (!(sessionId && sessionId.length > 0)) {
                return;
            }
            const session = await HttpSessionService.getInstance().findOne({
                id: sessionId,
            });

            if (session) {
                setIsUsed(true);
                if (multipleSessions) {
                    onChange([...(sessions ?? []), session]);
                } else {
                    onChange([session]);
                }
            }
        } catch (exp) {
            toast.error('Could not load receiver contact.');
        }
    };

    const createSessionContact = async () => {
        try {
            setIsSearching(true);

            const session = await HttpSessionService.getInstance().create({
                data: { email: fullTextFilter } as any,
            });

            setIsSearching(false);

            if (session) {
                if (multipleSessions) {
                    onChange([...(sessions ?? []), session]);
                } else {
                    onChange([session]);
                }
            }
            setSessionSearchResults([]);
        } catch (_) {
            setIsSearching(false);

            toast.error('Could not create contact.');
        }
    };

    const renderContactPreview = (session) => {
        const isOnline =
            (Date.now() - Date.parse(session.lastActivity)) / 60000 < 2;
        let name = cleanupName(getSessionName(session), 20);
        if (name === "Guest") {
            name = session?.email;
        }

        return (
            <div className="contact-preview">
                <UserAvatar
                    isOnline={isOnline}
                    email={session?.email ? session.email : session.gleapId}
                    xsmall
                />
                <div className={`name ${!session.userId ? 'guest-name' : ''} ${smallText ? "name--small" : ""}`}>
                    {name}
                </div>
                <i
                    className="fa-regular fa-xmark ml-10 remove-session-icon"
                    onClick={() => {
                        if (multipleSessions) {
                            sessions && sessions.length > 1
                                ? onChange(sessions?.filter((s) => s.id !== session.id))
                                : onChange([]);
                        } else {
                            onChange([]);
                        }
                    }}
                />
            </div>
        );
    };

    if (!multipleSessions && sessions && sessions[0]) {
        return (
            <>
                <div className="input-row">
                    {label?.length === 0 ? <></> : <p className='m-0'>{label || 'To:'}</p>}
                    {icon && <i className={`input-icon fa-solid fa-${icon}`} />}
                    {renderContactPreview(sessions[0])}
                </div>
            </>
        );
    }

    const cn = classNames("compose-conversation-session", className)
    const placeholderClass = classNames("session-search-input", placeholderClassName)
    return (
        <>
            <div className={cn}>
                <div className="session-search-container">
                    <div className="input-row">
                        {label?.length === 0 ? <></> : <p className='m-0'>{label || 'To:'}</p>}
                        {icon && <i className={`input-icon fa-solid fa-${icon}`} />}
                        {multipleSessions &&
                            sessions &&
                            sessions.map((session) => renderContactPreview(session))}
                        <TextInput
                            inputRef={inputRef}
                            className={placeholderClass}
                            inputClassName={placeholderClass}
                            placeholder={
                                (!sessions || sessions.length === 0)
                                    ? (placeholder || 'Choose a receiver')
                                    : '...'
                            }
                            type="text"
                            error=""
                            value={fullTextFilter}
                            onChange={(text) => {
                                setFullTextFilter(text);
                            }}
                        />
                    </div>
                    {((sessionSearchResults && sessionSearchResults.length > 0) ||
                        isSearching ||
                        fullTextFilter !== '') && (
                            <div className="session-search">
                                {!sessionSearchResults
                                    .map((session: any) => session?.email)
                                    .includes(fullTextFilter) &&
                                    !isSearching && (
                                        <div
                                            className="session-search-session"
                                            onClick={createSessionContact}
                                        >
                                            Create contact
                                        </div>
                                    )}
                                {isSearching && (
                                    <div className="session-search-loading">
                                        <LoadingAnimationSmall />
                                    </div>
                                )}
                                {sessionSearchResults.map((session: any) => {
                                    if (!session) {
                                        return null;
                                    }

                                    return (
                                        <div
                                            className="session-search-session"
                                            onClick={() => {
                                                setFullTextFilter('');
                                                loadSessionDetails(session.id);
                                                setSessionSearchResults([]);
                                                multipleSessions && inputRef?.current?.focus();
                                            }}
                                        >
                                            <UserAvatar
                                                email={
                                                    session?.email ? `${session.email}` : session.gleapId
                                                }
                                                small
                                            />
                                            <div className="session-search-session-data">
                                                <span className="session-search-session-name">
                                                    {cleanupName(getSessionName(session), 30)}
                                                </span>
                                                <span className="session-search-session-email">
                                                    {session?.email}
                                                    {session.userId ? ` · ${session.userId}` : ''}
                                                </span>
                                            </div>
                                            {session.userId && (
                                                <div className="type-guest-tag">USER</div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                </div>
            </div>
        </>
    );
};

export default inject('projectStore')(observer(RecipientPicker));
