import React, { forwardRef } from 'react';
import classNames from 'classnames';
import './PageContent.scss';

interface PageContentProps {
  className?: any;
  children: any;
  hasTitle?: boolean;
  hasPadding?: boolean;
  isBoxed?: boolean;
  isSmallBoxed?: boolean;
  isMediumBoxed?: boolean;
  isLargeBoxed?: boolean;
  isCentered?: boolean;
  ref?: any;
  onScroll?: (e: any) => void;
  style?: any;
}

const PageContent = forwardRef<HTMLDivElement, PageContentProps>(
  (
    {
      className,
      children,
      hasTitle,
      hasPadding = true,
      isBoxed = false,
      isSmallBoxed = false,
      isMediumBoxed = false,
      isLargeBoxed = false,
      isCentered = false,
      onScroll,
      style,
      ...props
    },
    ref,
  ) => {
    const PageContentClassNmae = classNames(
      {
        'page-content': true,
        'page-content--title': hasTitle,
        'page-content--padding': hasPadding,
        'page-content--boxed': isBoxed,
        'page-content--smallboxed': isSmallBoxed,
        'page-content--mediumboxed': isMediumBoxed,
        'page-content--largeboxed': isLargeBoxed,
        'page-content--centered': isCentered,
      },
      className,
    );

    return (
      <div className={PageContentClassNmae} onScroll={onScroll} ref={ref} style={style ?? {}}>
        <div className="page-content--inner">{children}</div>
      </div>
    );
  },
);

export default PageContent;
