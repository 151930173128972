import WorkflowImage from 'assets/workflowsimage.png';
import IntroCard from 'components/IntroCard/IntroCard';
import LinkButton from 'components/LinkButton/LinkButton';
import ListTable, {
  CellLinkInternal,
  CellTag,
  CellTextIcon,
} from 'components/ListTable/ListTable';
import LoadingAnimationMedium from 'components/LoadingAnimationMedium/LoadingAnimationMedium';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { iconList } from 'components/UserTypeDropDown/UserTypeDropDown';
import { inject, observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './CustomBots.scss';
import TimeAgo from 'react-timeago';
import Swal from 'sweetalert2';
import { botTriggerOptions } from 'components/Modals/TriggerActionEditorModal/TriggerActionEditorModal';

interface CustomBotsProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

const CustomBots = ({ projectStore, modalStore }: CustomBotsProps) => {
  const { projectId } = useParams();
  const [creatingBot, setCreatingBot] = useState(false);
  const currentProject = projectStore?.currentProject;

  const cloneBot = async (botId) => {
    try {
      setCreatingBot(true);
      await projectStore?.cloneBot(projectId, botId);
      setCreatingBot(false);
      toast.success('Bot duplicated successfully.');
    } catch (error) {
      toast.error('Failed to duplicate bot.');
      setCreatingBot(false);
    }
  };

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    if (currentProject) {
      projectStore!.getBots(currentProject.id);
    }
  }, [currentProject]);

  const columns = useMemo(
    () => [
      {
        width: '40%',
        Header: 'Name',
        accessor: (row) => row,
        Cell: (row) => (
          <CellLinkInternal
            link={`/projects/${projectStore?.currentProject?.id}/bots/custombots/${row.value.id}/`}
            text={row.value.name}
          />
        ),
      },
      {
        className: 'hide-on-mobile',
        width: '25%',
        Header: 'Trigger',
        accessor: 'trigger',
        Cell: (row) => {
          const trigger =
            botTriggerOptions.find((option) => option.value === row.value) ??
            botTriggerOptions[0];
          return (
            <CellTextIcon
              icon={`fa-solid ${iconList[trigger.value]}`}
              text={trigger.name}
            />
          );
        },
      },
      {
        width: '15%',
        Header: 'Last updated',
        accessor: 'updatedAt',
        Cell: (row) => <TimeAgo date={row.value} />,
      },
      {
        width: '10%',
        Header: 'Status',
        accessor: 'status',
        Cell: (row) => {
          if (row.value === 'live') {
            return <CellTag className="cell-tag--green" text="Live" />;
          }

          return <CellTag className="cell-tag--gray" text="Draft" />;
        },
      },
      {
        width: '10%',
        Header: 'Clone',
        accessor: 'id',
        Cell: (row) => {
          return (
            <LinkButton
              icon="copy"
              label="Clone"
              onClick={() =>
                Swal.fire({
                  title: 'Are you sure?',
                  text: 'Are you sure you want to duplicate this workflow?',
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Yes, duplicate it!',
                  cancelButtonText: 'No, cancel!',
                  reverseButtons: true,
                }).then((result) => {
                  if (result.isConfirmed) {
                    cloneBot(row.value);
                  }
                })
              }
            />
          );
        },
      },
    ],
    [],
  );

  if (!currentProject) {
    return <></>;
  }

  const renderBotsList = () => {
    if (projectStore.loadingBots) {
      return (
        <div className="loading mt-100">
          <LoadingAnimationMedium />
        </div>
      );
    }

    if (projectStore?.bots?.length === 0) {
      return <div className="text centered">No workflows created yet.</div>;
    }

    return <ListTable data={projectStore?.bots ?? []} columns={columns} />;
  };

  const createWorkflow = async () => {
    setCreatingBot(true);
    if (projectId) {
      await projectStore.createBot(projectId);
    }
    setCreatingBot(false);
  };

  return (
    <PageContainer className="custom-bots">
      <PageHeadLine title="Workflows" tag="NEW">
        <PrimaryButton
          onClick={async () => {
            await createWorkflow();
          }}
          isLoading={creatingBot}
          className="save-button"
          icon="plus"
          label="Create workflow"
        />
      </PageHeadLine>
      <PageContent hasTitle>
        <IntroCard
          headline="Your automation co-pilot: Workflows"
          image={WorkflowImage}
          content="Workflows are a powerful tool to automate your customer support. Get started by creating your first workflow now."
          buttonAction={() => createWorkflow()}
          buttonText="Create new workflow"
        />
        {renderBotsList()}
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore', 'modalStore')(observer(CustomBots));
