import UserAvatar from 'components/UserAvatar/UserAvatar';
import {
  getLanguageProperty
} from 'helper/AssignObjectKeysHelper';
import { useEffect, useState } from 'react';
import './BannerPreview.scss';

const BannerPreview = ({
  htmlMessage,
  outboundRule,
  format,
  config,
  senderUser,
  currentLang,
}) => {
  const [isMultiline, setIsMultiline] = useState(false);

  useEffect(() => {
    const element = document.querySelector('.gleap-banner-message');

    if (element) {
      const isMultiline =
        (format === 'floating' && element.clientWidth > 540) ||
        (format === 'inline' && element.clientWidth > 740) ||
        element.clientHeight > 65;

      setIsMultiline(isMultiline);
    }
  }, [htmlMessage, format]);

  const messageClass =
    isMultiline && format !== 'inline' ? 'oneline-inline-content' : '';

  return (
    <div className="banner-preview">
      <div className="banner-preview-browser">
        <svg
          width="100%"
          height="100%"
          xmlns="http://www.w3.org/2000/svg"
          tabIndex={0}
        >
          <rect width="100%" height="100%" fill="#FFFFFF" />

          <defs>
            <linearGradient id="fadeGradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#ECECEC" />
              <stop offset="100%" stopColor="#FFFFFF" />
            </linearGradient>
          </defs>

          <defs>
            <linearGradient id="fadeStrokes" x1="0%" y1="0%" x2="0%" y2="80%">
              <stop offset="5%" stopColor="#4285F4" />
              <stop offset="200%" stopColor="#4285F4" />
            </linearGradient>
          </defs>

          <rect width="100%" height="100%" fill="url(#fadeGradient)" rx="10" />

          <rect x="100" y="10" width="850" height="32" fill="#E8E8E8" rx="10" />
          <text x="120" y="32" font-size="14" fill="#000000">
            https://app.gleap.io
          </text>

          <circle cx="30" cy="25" r="7" fill="#FF5F57" />
          <circle cx="50" cy="25" r="7" fill="#FFBD2E" />
          <circle cx="70" cy="25" r="7" fill="#34C759" />
        </svg>
      </div>
      <div className="banner-preview-box">
        <div className="gleap-banner">
          <div
            className={`banner-styling ${
              format === 'floating' ? 'floating' : 'inline'
            }`}
            style={{
              backgroundColor: config.bannerColor,
              textAlign: 'center',
              paddingTop: '3px',
              paddingLeft: '20px',
              paddingRight: '20px',
              paddingBottom: '3px',
              border: '1px solid #ECECEC',
              color: 'white',
              borderRadius: format === 'inline' ? '0px' : '8px',
              maxWidth: format === 'floating' ? '80%' : '100%',
              margin: format === 'floating' ? '0 auto' : '0',
              boxShadow:
                format === 'floating'
                  ? '0 4px 8px rgba(0, 0, 0, 0.1)'
                  : '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
          >
            <div
              className={`gleap-banner-content ${
                isMultiline
                  ? 'multiline'
                  : format === 'inline'
                  ? 'oneline-inline-content'
                  : ''
              }`}
            >
              <div
                className={`gleap-banner-sender ${
                  config.showSender ? 'show' : 'hide'
                }`}
              >
                <UserAvatar
                  email={senderUser?.email}
                  imageUrl={senderUser?.profileImageUrl}
                />{' '}
              </div>
              <div className="gleap-banner-message-outer">
                <div
                  className={`gleap-banner-message ${messageClass}`}
                  dangerouslySetInnerHTML={{ __html: htmlMessage }}
                ></div>
                <div
                  className={`gleap-banner-action ${
                    isMultiline ? 'multiline-button' : 'oneline-button'
                  }`}
                >
                  {getLanguageProperty(
                    outboundRule.config,
                    'actionTitle',
                    currentLang,
                  )}
                </div>
              </div>
            </div>
            <div
              className={`banner-preview-close-icon ${
                isMultiline ? 'multiline-text' : 'oneline-text'
              }`}
            >
              <i
                className="fa-solid fa-xmark"
                style={{
                  display: config.canClose ? '' : 'none',
                  color: 'var(--gleap-font-color)',
                }}
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerPreview;
