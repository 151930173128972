import axios from '../Axios';

const getAuthCodeFromSlack = (projectID) => {
  const scopes =
    'channels:read,channels:manage,chat:write,chat:write.public,commands,groups:read,groups:write,groups:history,im:read,im:write,mpim:read,mpim:write,users:read,users:read.email,channels:history,metadata.message:read,users.profile:read';

  return window.open(
    `https://slack.com/oauth/v2/authorize?scope=${encodeURIComponent(
      scopes,
    )}&redirect_uri=${
      process.env.REACT_APP_BASEURL
    }/projects/${projectID}/integrations/slack&client_id=${
      process.env.REACT_APP_SLACK_CLIENT_ID
    }`,
    '_self',
  );
};

const setSlackAuthToken = (code, projectID) => {
  return axios.post(`/projects/${projectID}/integrations/slack`, {
    code,
  });
};

const setSlackActions = ({ projectID, integrationID, actions, settings }) => {
  return axios.put(
    `/projects/${projectID}/integrations/slack/${integrationID}`,
    {
      actions,
      settings,
    },
  );
};

const disconnectSlackIntegration = ({ projectID, integrationID }) => {
  return axios.delete(
    `/projects/${projectID}/integrations/slack/${integrationID}`,
  );
};

const getSlackChannels = ({ projectID, integrationID }) => {
  return axios.get(
    `/projects/${projectID}/integrations/slack/${integrationID}/channels`,
  );
};

export {
  getAuthCodeFromSlack,
  setSlackAuthToken,
  setSlackActions,
  disconnectSlackIntegration,
  getSlackChannels,
};
