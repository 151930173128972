import InfoBox from 'components/InfoBox/InfoBox';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import TextInput from 'components/TextInput/TextInput';
import { inject, observer } from 'mobx-react';
import { useState } from 'react';
import Switch from 'react-switch';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './CreateCrawlerTaskModal.scss';
import { Title } from 'components/Title/Title';
import TabButton from 'components/TabButton/TabButton';
import Swal from 'sweetalert2';

interface CreateCrawlerTaskModalProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

const CreateCrawlerTaskModal = ({
  modalStore,
  projectStore,
}: CreateCrawlerTaskModalProps) => {
  const [isSitemap, setIsSitemap] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [followLinks, setFollowLinks] = useState(true);
  const [followSubdomains, setFollowSubdomains] = useState(true);
  const [url, setUrl] = useState('');

  const create = async () => {
    if (!projectStore?.currentProject?.id) {
      return null;
    }

    if (!url || url === '') {
      return null;
    }

    if (isSitemap && !url.includes('.xml')) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid sitemap',
        text: 'The sitemap must be of type XML.',
      });
      return null;
    }

    setIsLoading(true);
    await projectStore?.createCrawlerTask(
      projectStore.currentProject?.id,
      url,
      followLinks,
      followSubdomains,
      isSitemap,
      true,
    );
    modalStore!.closeModal();
    setIsLoading(false);
  };

  const renderUrlOptions = () => {
    return (
      <>
        <div className="switch-container mt-20">
          <Switch
            width={40}
            onColor="#2142E7"
            height={20}
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={() => {
              setFollowLinks(!followLinks);
              setFollowSubdomains(!followLinks);
            }}
            checked={followLinks ?? false}
          />
          <span>Follow links</span>
        </div>
        {followLinks && (
          <div className="switch-container mt-10">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={() => {
                setFollowSubdomains(!followSubdomains);
              }}
              checked={followSubdomains ?? false}
            />
            <span>Follow subdomains</span>
          </div>
        )}
        {!isLoading && (
          <InfoBox className="mt-20">
            If activated, Kai will follow and crawl links with the same domain.
            There's no need to add them separately.
          </InfoBox>
        )}
      </>
    );
  };

  const renderSitemapOptions = () => {
    return (
      <>
        <InfoBox className="mt-20">
          Kai will crawl all URLs from the sitemap. The sitemap must be of type
          XML.
        </InfoBox>
      </>
    );
  };

  return (
    <div className="modal-with-title">
      <div className="modal-title-container">
        <Title label="Add new URL source" />
      </div>
      <div className="modal-body">
        <div className="edit-qa-modal">
          <div className="tab-buttons mb-30">
            <div className="tab-buttons--inner">
              <TabButton
                active={!isSitemap}
                label="🔗 URL"
                onClick={() => {
                  setIsSitemap(false);
                }}
              />
              <TabButton
                active={isSitemap}
                label="🗺 Sitemap"
                onClick={() => {
                  setIsSitemap(true);
                }}
              />
            </div>
          </div>
          <div>
            <TextInput
              name="url"
              placeholder={
                isSitemap
                  ? 'https://example.com/sitemap.xml'
                  : 'https://example.com'
              }
              type="text"
              required
              error=""
              initalValue={url ?? ''}
              label="URL"
              onChange={(text) => {
                setUrl(text);
              }}
            />
            {!isSitemap ? renderUrlOptions() : renderSitemapOptions()}
            <div className="mt-30">
              <PrimaryButton
                icon="plus"
                isLoading={isLoading}
                label={isSitemap ? 'Add sitemap' : 'Add URL'}
                onClick={() => {
                  create();
                }}
              />
              {isLoading && (
                <InfoBox className="mt-20">
                  Please wait while Kai is crawling your website. This may take
                  a few minutes.
                </InfoBox>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default inject(
  'modalStore',
  'projectStore',
)(observer(CreateCrawlerTaskModal));
