import LoadingAnimationSmall from 'components/LoadingAnimationSmall/LoadingAnimationSmall';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import TextInput from 'components/TextInput/TextInput';
import { truncate } from 'helper/FormDataHelper';
import { debounce } from 'lodash';
import { inject, observer } from 'mobx-react';
import { useCallback, useEffect, useState } from 'react';
import { BugStore } from 'stores/private/BugStore';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './DuplicateSearchModal.scss';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import Switch from 'react-switch';
import { markBugAsDuplicate } from 'services/BugHttpService';
import TabButton from 'components/TabButton/TabButton';
import { getTicketsForProject } from 'services/ProjectHttpService';

interface DuplicateSearchModalProps {
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
  bugStore?: BugStore;
}

const DuplicateSearchModal = ({
  modalStore,
  projectStore,
  bugStore,
}: DuplicateSearchModalProps) => {
  const currentTicket = bugStore?.currentBug;
  const ticketsSearchData = projectStore!.ticketSearchData;

  const [fullTextFilter, setFullTextFilter] = useState('');
  const [currentFilterTab, setCurrentFilterTab] = useState('all');
  const [latestContactTickets, setLatestContactTickets] = useState([]);

  const [ticketToMerge, setTicketToMerge] = useState(null as any);
  const [shouldCreateNewTicket, setShouldCreateNewTicket] = useState(true);
  const [shouldMergeTasks, setShouldMergeTasks] = useState(true);
  const [shouldMergeContacts, setShouldMergeContacts] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  if (!currentTicket) {
    modalStore!.closeModal();
  }

  useEffect(() => {
    // If ticket has more than one session, we can't create a new ticket by default
    if (
      (currentTicket &&
        currentTicket?.sessions &&
        currentTicket?.sessions.length > 1) ||
      currentTicket?.type === 'FEATURE_REQUEST'
    ) {
      setShouldCreateNewTicket(false);
    } else {
      setShouldCreateNewTicket(true);
    }

    initialize();
  }, [currentTicket]);

  const initialize = async () => {
    try {
      const contactTickets = await getTicketsForProject({
        projectId: currentTicket?.project,
        query: {
          session: currentTicket?.session?.id,
          limit: 15,
          sort: '-createdAt',
        },
      });

      setLatestContactTickets(contactTickets.data.tickets ?? []);
    } catch (_) {}
  };

  const debounceSearch = useCallback(
    debounce((nextValue) => {
      projectStore?.duplicateTicketSearch({ searchTerm: nextValue });
    }, 750),
    [],
  );

  const tickets =
    currentFilterTab === 'session'
      ? latestContactTickets
      : ticketsSearchData.data.map(
          (feedbackItem: any) => feedbackItem.document,
        );

  const filteredTickets = tickets
    .map((feedbackItem: any) => feedbackItem)
    .filter((feedbackItem) => {
      if (!feedbackItem) {
        return false;
      }

      if (feedbackItem.id === currentTicket?.id) {
        return false;
      }

      return true;
    });

  return (
    <SizedContainer size={ContainerSizes.XXL}>
      <Column justifyContent="flex-start">
        <div className="title">Merge ticket</div>
        <div className="text">Tickets get grouped into one ticket</div>
        <Row className="mt-20 mb-30" alignItems="center">
          <Column className="target-ticket-preview" alignItems="center">
            <i
              className="fa-solid fa-tickets mb-10"
              style={{ fontSize: '40px' }}
            />
            <div className="title">#{currentTicket?.bugId}</div>
            <div className="text">{currentTicket?.title ?? 'No title'}</div>
          </Column>
          <div className="ml-20 mr-20">
            <i className="fa-duotone fa-merge" style={{ fontSize: '40px' }} />
          </div>
          <Column className="source-ticket-preview">
            <div className="dup-search-container">
              <TextInput
                className="fulltext-filter mb-15"
                placeholder="Search"
                type="text"
                error=""
                icon="magnifying-glass"
                initalValue={fullTextFilter}
                onChange={(text) => {
                  setFullTextFilter(text);
                  setCurrentFilterTab('all');
                  debounceSearch(text);
                }}
              />
              {ticketsSearchData.isLoading && fullTextFilter.length > 0 && (
                <div className="dup-search-container-loading">
                  <LoadingAnimationSmall />
                </div>
              )}
            </div>
            <div className="tab-buttons">
              <div className="tab-buttons--inner">
                <TabButton
                  active={currentFilterTab === 'all'}
                  label="All"
                  onClick={() => {
                    setCurrentFilterTab('all');
                    debounceSearch(fullTextFilter);
                  }}
                />
                <TabButton
                  active={currentFilterTab === 'session'}
                  label="Current contact"
                  onClick={() => {
                    setCurrentFilterTab('session');
                  }}
                />
              </div>
            </div>
            <div className="list-table-wrapper merge-dups-list">
              {filteredTickets.length === 0 && !ticketsSearchData.isLoading && (
                <div className="text mt-20">No tickets found</div>
              )}
              {filteredTickets.map((ticket: any, index) => (
                <Column>
                  <Row
                    className="dups-list-item"
                    alignItems="center"
                    justifyContent="space-between"
                    onClick={() => {
                      setTicketToMerge(ticket.id);
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <input
                        className="dups-list-item-radio"
                        type="radio"
                        name={`ticket-to-merge-${ticket.id}`}
                        checked={ticketToMerge === ticket.id}
                        onChange={() => {
                          setTicketToMerge(ticket.id);
                        }}
                      />
                      <div className="ml-10 text dups-list-item-ticket-number">
                        #{ticket.bugId}
                      </div>
                    </div>
                    <div className="text dups-list-item-ticket-title">
                      {truncate(ticket.title, 150)}
                    </div>
                  </Row>
                  {index !== tickets.length - 1 && (
                    <div className="dups-list-item-divider" />
                  )}
                </Column>
              ))}
            </div>
          </Column>
        </Row>
        <div className="switch-container mb-10">
          <Switch
            width={40}
            onColor="#2142E7"
            height={20}
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={(checked) => {
              setShouldCreateNewTicket(checked);
            }}
            checked={shouldCreateNewTicket}
          />
          <span>Create new ticket</span>
        </div>
        <div className="switch-container mb-10">
          <Switch
            width={40}
            onColor="#2142E7"
            height={20}
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={(checked) => {
              setShouldMergeTasks(checked);
            }}
            checked={shouldMergeTasks}
          />
          <span>Merge all tasks</span>
        </div>
        <div className="switch-container">
          <Switch
            width={40}
            onColor="#2142E7"
            height={20}
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={(checked) => {
              setShouldMergeContacts(checked);
            }}
            checked={shouldMergeContacts}
          />
          <span>Merge contacts</span>
        </div>
      </Column>
      <Row justifyContent="flex-end">
        <PrimaryButton
          label="Merge ticket"
          disabled={!ticketToMerge}
          isLoading={isLoading}
          onClick={async () => {
            try {
              setIsLoading(true);

              const newTicketResponse = await markBugAsDuplicate({
                ticketToMergeId: currentTicket!.id,
                sourceTicketId: ticketToMerge,
                shouldCreateNewTicket,
                shouldMergeTasks,
                shouldMergeContacts,
              });

              projectStore!.openFeedbackItem({
                shareToken: newTicketResponse.data.shareToken,
              });
              projectStore?.refreshData();

              setIsLoading(false);
              modalStore?.closeModal();
            } catch (_) {
              setIsLoading(false);
            }
          }}
        />
      </Row>
    </SizedContainer>
  );
};

export default inject(
  'modalStore',
  'projectStore',
  'bugStore',
)(observer(DuplicateSearchModal));
