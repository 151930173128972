import LoadingAnimationMedium from 'components/LoadingAnimationMedium/LoadingAnimationMedium';
import { currencySymbols } from 'components/PaddlePriceTable/PaddlePriceTable';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { loadPriceTable } from 'services/PaddleHttpService';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { PaddleStore } from 'stores/private/PaddleStore';
import './PaddleNewPriceTable.scss';
import TabButton from 'components/TabButton/TabButton';
import { trackEvent } from 'services/GTagHelper';

function calculateMonthlyPrice(data, interval) {
  // Safely access nested properties
  const totalCents = data?.totals?.subtotal;
  const currencyCode = data?.price?.unit_price?.currency_code;

  // Calculate the monthly total in cents
  let monthlyTotalCents = parseInt(totalCents);

  if (interval === 'yearly') {
    // Divide by 12 to get the monthly total
    monthlyTotalCents = Math.floor(monthlyTotalCents / 12);
  }

  // Convert to dollars and cents for formatting
  const monthlyTotalDollars = Math.floor(monthlyTotalCents / 100);

  // Format using the currencyCode
  return `${
    currencySymbols[currencyCode] || currencyCode
  }${monthlyTotalDollars}`;
}

interface PaddleNewPriceTableProps {
  paddleStore?: PaddleStore;
  organisationStore?: OrganisationStore;
  showPlans?: PaddlePlantypes[];
  onPlanChange?: (priceId: string) => void;
  currentPriceId?: string;
}

export enum PaddlePlantypes {
  HOBBY = 'hobby',
  TEAM = 'team',
  PRO = 'pro',
  ENTERPRISE = 'enterprise',
}

export const paddleBasePlans = {
  hobby: {
    monthly: {
      trial: 'pri_01hwn37btx4777rjp0jetb29et',
      noTrial: 'pri_01hwn373hjnxet05zrkccs7csk',
    },
    yearly: {
      trial: 'pri_01hwn3n7jnh4a9scp7bkms30kp',
      noTrial: 'pri_01hwn3mh20tn7k3h6y9spxvbfn',
    },
    name: 'Gleap Hobby',
    nameSolo: 'Hobby',
    teammembers: '1 team member',
    featuresHeadline: null,
    features: [
      'In-app bug reporting',
      'Feature requests',
      'Live-chat',
      'Help center',
      'Kai, AI bot',
    ],
  },
  team: {
    monthly: {
      trial: 'pri_01hwn38e9sav9qcgx18edhp669',
      noTrial: 'pri_01hwn38182p90f6yh8ma6stmrw',
    },
    yearly: {
      trial: 'pri_01hwn3pw7zkpkpxtkbygmmqp84',
      noTrial: 'pri_01hwn3pe45swpeg5xgxx35bwxv',
    },
    name: 'Gleap Team',
    nameSolo: 'Team',
    teammembers: 'Unlimited team members',
    outreach: 'Outreach included',
    featuresHeadline: 'Everything in Hobby +',
    features: [
      'All integrations',
      'Console & network logs',
      'Custom domain',
      'Remove Gleap branding',
    ],
  },
  enterprise: {
    monthly: {
      trial: 'pri_01hwn39amcdhytb8wwjvrmbpg5',
      noTrial: 'pri_01hwn38xkycdqvt23kffyc6xwd',
    },
    yearly: {
      trial: 'pri_01hwn3rp05dcetn7yayzgxfkxc',
      noTrial: 'pri_01hwn3r5a9a1347jjgan1w9svr',
    },
    teammembers: 'Unlimited team members',
    name: 'Gleap Enterprise',
    nameSolo: 'Enterprise',
    outreach: 'Outreach included',
    featuresHeadline: 'Everything in Team +',
    features: ['SLAs', 'Priority support', 'API access'],
  },
};

const PaddleNewPriceTable = ({
  paddleStore,
  organisationStore,
  showPlans = [
    PaddlePlantypes.HOBBY,
    PaddlePlantypes.TEAM,
    PaddlePlantypes.ENTERPRISE,
  ],
  onPlanChange,
  currentPriceId,
}: PaddleNewPriceTableProps) => {
  const [priceInfo, setPriceInfo] = useState(null as any);
  const [interval, setInterval] = useState('yearly');
  const trialUsed = organisationStore?.currentOrganisation?.trialUsed
    ? true
    : false;

  useEffect(() => {
    if (
      organisationStore?.currentOrganisation?.subscription?.billingCycle
        ?.interval === 'month'
    ) {
      setInterval('monthly');
    }
  }, [
    organisationStore?.currentOrganisation?.subscription?.billingCycle
      ?.interval,
  ]);

  useEffect(() => {
    // Load price info.
    var prices: {
      price_id: string;
      quantity: number;
    }[] = [];

    for (const plan in paddleBasePlans) {
      prices.push({
        price_id: paddleBasePlans[plan].monthly.trial,
        quantity: 1,
      });
      prices.push({
        price_id: paddleBasePlans[plan].monthly.noTrial,
        quantity: 1,
      });
      prices.push({
        price_id: paddleBasePlans[plan].yearly.trial,
        quantity: 1,
      });
      prices.push({
        price_id: paddleBasePlans[plan].yearly.noTrial,
        quantity: 1,
      });
    }

    loadPriceTable(
      prices.filter((elem) => elem.price_id && elem.price_id.length > 0),
    )
      .then((data) => {
        setPriceInfo(data);
        trackEvent('view.subscription_plans');
      })
      .catch((err) => {});
  }, []);

  const planButton = (price, isSelectedPlan, name) => {
    const priceId = !trialUsed ? price.trial : price.noTrial;

    if (isSelectedPlan) {
      return <div className="current-plan-button">Current plan</div>;
    }

    return (
      <PrimaryButton
        fullWidth
        className="mt-20"
        dataPlan={name}
        onClick={() => {
          if (
            onPlanChange &&
            currentPriceId != null &&
            currentPriceId != undefined &&
            currentPriceId != '' &&
            currentPriceId !== priceId
          ) {
            onPlanChange(priceId);
          } else {
            paddleStore?.checkoutWithPrice(priceId);
          }
        }}
        label={
          organisationStore?.currentOrganisation?.trialUsed
            ? 'Subscribe now ✨'
            : 'Start free trial'
        }
      />
    );
  };

  function getPrice(price) {
    const priceId = !trialUsed ? price.trial : price.noTrial;
    for (let item of priceInfo) {
      if (item.price.id === priceId) {
        return item;
      }
    }
    return null;
  }

  if (!priceInfo) {
    return (
      <>
        <LoadingAnimationMedium />
      </>
    );
  }

  return (
    <>
      <div className="yearly-switch-cont">
        <div className="tab-buttons yearly-badge-rel mb-20">
          <div className="tab-buttons--inner">
            <TabButton
              active={interval === 'monthly'}
              label="Monthly"
              onClick={() => {
                setInterval('monthly');
              }}
            />
            <TabButton
              active={interval === 'yearly'}
              label="Yearly"
              onClick={() => {
                setInterval('yearly');
              }}
            />
            <div className="yearly-badge">Save 20%</div>
          </div>
        </div>
      </div>
      <div className="pricetable-container">
        {showPlans.map((plan) => {
          const planData = paddleBasePlans[plan];
          if (!planData) {
            return;
          }

          const localizedPrice = getPrice(planData[interval]);
          const isSelectedPlan =
            currentPriceId === planData[interval].noTrial ||
            currentPriceId === planData[interval].trial;

          let selectionClass = '';
          if (
            (!currentPriceId && plan == PaddlePlantypes.TEAM) ||
            (currentPriceId && isSelectedPlan)
          ) {
            selectionClass = 'pricing-card--colored';
          }

          return (
            <div className={`pricing-card ${selectionClass}`}>
              <div className="pricing-card-inner">
                <div>
                  <h2 className="pricingheadlinetop">{planData.nameSolo}</h2>
                  <h2 className="pricingheadline">
                    <div className="priceheadline-comp">
                      {interval === 'yearly' && (
                        <span className="price-regular">
                          {calculateMonthlyPrice(
                            getPrice(planData['monthly']),
                            'monthly',
                          )}
                        </span>
                      )}
                      <div className="price">
                        {calculateMonthlyPrice(localizedPrice, interval)}
                      </div>
                    </div>
                    <div className="planpost">per month</div>
                  </h2>
                </div>
                {planButton(
                  planData[interval],
                  isSelectedPlan,
                  planData.nameSolo,
                )}
                <div className="pricingfeatures">
                  <div className="pre-feature">
                    <div className="feature">{planData.teammembers}</div>
                    {planData.outreach && (
                      <div className="feature feature--second">
                        {planData.outreach}
                      </div>
                    )}
                  </div>
                  {planData.featuresHeadline && (
                    <h5>{planData.featuresHeadline}</h5>
                  )}
                  {planData.features.map((feature, index) => (
                    <React.Fragment key={index}>
                      {feature}
                      {index < planData.features.length - 1 && <br />}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default inject(
  'paddleStore',
  'organisationStore',
)(observer(PaddleNewPriceTable));
