import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import { Title } from 'components/Title/Title';
import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import CrawledPageLine from './CrawledPageLine';
import './CrawledPagesModal.scss';
import LoadingAnimationSmall from 'components/LoadingAnimationSmall/LoadingAnimationSmall';
import LoadingAnimationMedium from 'components/LoadingAnimationMedium/LoadingAnimationMedium';

interface CrawledPagesModalProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

const CrawledPagesModal = ({
  projectStore,
  modalStore,
}: CrawledPagesModalProps) => {
  const currentProject = projectStore?.currentProject;
  const modalData = modalStore?.getCustomData(MODALTYPE.CRAWLED_PAGES);

  useEffect(() => {
    if (modalData && modalData.crawlerTask && currentProject?.id) {
      projectStore?.getProjectCrawledPages(currentProject?.id, modalData.crawlerTask?.id);
    }
  }, [modalData, currentProject]);

  return (
    <div className="modal-with-title">
      <div className='modal-title-container'>
        <Title label="Page inspector" />
      </div>
      <div className='modal-body'>
        <div className="edit-qa-modal">
          <div className='url-inspector'>
            {projectStore?.loadingCrawlerPages && (
              <div className='loading-cont'>
                <LoadingAnimationMedium />
              </div>
            )}
            {projectStore?.crawlerPages?.map((page) => {
              return <CrawledPageLine crawledPage={page} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default inject(
  'projectStore',
  'modalStore',
)(observer(CrawledPagesModal));
