import React from 'react';
import './PaddleBillingDetails.scss';
import { countryTableAsKeyValue } from 'services/CountryHelper';

interface Props {
    customerData?: any;
}

const PaddleBillingDetails: React.FC<Props> = ({ customerData }) => {
    const renderDetails = (label, detail) => {
        if (label === "Country" && detail) {
            return (
                <div className="text">
                    {countryTableAsKeyValue[detail]}
                </div>
            );
        }

        if (detail) {
            return (
                <div className="text">
                    {detail}
                </div>
            );
        }
        return null;
    }

    if (!customerData) {
        return null;
    }

    const { customer, address, business } = customerData;

    return (
        <div className="billing-details">
            {renderDetails('Name', customer?.name)}
            {renderDetails('Email', customer?.email)}
            <div className='billing-details-columns'>
                <div className='left'>
                    <div className='detail-title'>Address</div>
                    {renderDetails('First Line', address?.first_line)}
                    {renderDetails('City', address?.city)}
                    {renderDetails('Postal Code', address?.postal_code)}
                    {renderDetails('Country', address?.country_code)}
                </div>
                <div className='right'>
                    <div className='detail-title'>Business</div>
                    {!business?.name && <div className="text">No business details added</div>}
                    {renderDetails('Name', business?.name)}
                    {!business?.tax_identifier && <div className="text">No tax identifier added</div>}
                    {renderDetails('Tax Identifier', business?.tax_identifier)}
                </div>
            </div>

        </div>
    );
}

export default PaddleBillingDetails;