import AvatarImage from 'components/AvatarImage/AvatarImage';
import { Md5 } from 'md5-typescript';
import { ReactComponent as GleapBot } from '../../assets/icons/gleapbot.svg';
import './UserAvatar.scss';
import classNames from 'classnames';

interface UserAvatarProps {
  email?: string;
  xsmall?: Boolean;
  small?: Boolean;
  big?: Boolean;
  imageUrl?: string;
  editable?: boolean;
  afterImageUpdload?: (imageUrl: string) => void;
  isOnline?: boolean;
  isOffline?: boolean;
  className?: string;
  collapsed?: boolean;
  onClick?: () => void;
}

const UserAvatar = ({
  email = '--',
  small = false,
  xsmall = false,
  big = false,
  imageUrl,
  editable = false,
  afterImageUpdload,
  isOnline = false,
  isOffline,
  className,
  collapsed,
  onClick,
}: UserAvatarProps) => {
  const hash = Md5.init(email);
  const isBot = email === 'bot';
  const gravatar = `https://www.gravatar.com/avatar/${hash}?d=retro`;

  const avatarClassName = classNames(
    {
      'user-avatar': true,
      'user-avatar--bot': isBot,
      'editable-gravatar': !isBot,
      'user-avatar--small': small,
      'user-avatar--xsmall': xsmall,
      'user-avatar--big': big,
      'user-avatar--collapsed': collapsed,
    },
    className,
  );

  if (editable) {
    return (
      <AvatarImage
        image={imageUrl && imageUrl !== '' ? imageUrl : gravatar}
        className={className}
        editable
        afterImageUpload={afterImageUpdload}
      />
    );
  }

  return (
    <div className={avatarClassName} onClick={onClick}>
      {isBot ? (
        <GleapBot />
      ) : (
        <img
          alt={`${email}'s avatar'`}
          src={imageUrl && imageUrl !== '' ? imageUrl : gravatar}
        />
      )}
      {isOnline && <div className="user-avatar-user-online" />}
      {isOffline && <div className="user-avatar-user-offline" />}
    </div>
  );
};

export default UserAvatar;
