import React from 'react';
import classNames from 'classnames';
import './Line.scss';

interface LineProps {
  className?: any;
}

export const Line = ({ className }: LineProps) => {
  const lineClassNmae = classNames(
    {
      line: true,
    },
    className,
  );

  return <div className={lineClassNmae} />;
};
