import { Editor } from '@tiptap/react';
import EmailEditor from 'components/Editors/EmailEditor/EmailEditor';
import Toolbar, { ToolbarItems } from 'components/Editors/ToolBar/Toolbar';
import EmailTemplate from 'components/EmailTemplate/EmailTemplate';
import FeedbackUserFilter from 'components/FeedbackUserFilter/FeedbackUserFilter';
import TextInput from 'components/TextInput/TextInput';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Outbound } from 'models/Outbound';
import { useEffect, useRef, useState } from 'react';
import Switch from 'react-switch';
import { ProjectStore } from 'stores/private/ProjectStore';
import Swal from 'sweetalert2';
import './EmailConfiguration.scss';
import {
  getLanguageProperty,
  setLanguageProperty,
} from 'helper/AssignObjectKeysHelper';
import LanguageDropdown from 'components/LanguageDropdown/LanguageDropdown';
import { languages } from 'constants/Languages';
import classNames from 'classnames';
import EmailTemplateSelection from 'components/EmailTemplateSelection/EmailTemplateSelection';
import { useNavigate } from 'react-router';
import { getPossibleEventDataKeysForTrigger } from 'services/OutboundHttpService';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';

interface EmailConfigurationProps {
  outboundRule: Outbound;
  content?: any;
  inputContentChanged?: (content: any) => void;
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

const EmailConfiguration = ({
  outboundRule,
  projectStore,
  modalStore,
}: EmailConfigurationProps) => {
  const editorRef: React.MutableRefObject<Editor | null> = useRef(null);
  const navigate = useNavigate();
  const [toggleState, setToggleState] = useState(false);
  const [eventData, setEventData] = useState<any[]>([]);

  const currentProject = projectStore?.currentProject;
  const currentLang = projectStore?.currentLanguage ?? 'en';
  const availableLanguages = languages.filter((language) =>
    currentProject?.localizations.find(
      (item) => item.language === language.code,
    ),
  );

  const loadTrigger = async (projectId, eventName) => {
    const possibleDataKeys = await getPossibleEventDataKeysForTrigger(
      projectId,
      eventName,
    );
    if (possibleDataKeys && possibleDataKeys.data) {
      setEventData(possibleDataKeys.data);
    }
  };

  useEffect(() => {
    if (
      outboundRule.trigger &&
      outboundRule.trigger?.event &&
      projectStore?.currentProject?.id
    ) {
      loadTrigger(
        projectStore?.currentProject?.id,
        outboundRule.trigger?.event,
      );
    }
  }, [outboundRule.trigger]);

  const translationTableSubject = outboundRule?.subject?.localized ?? {};
  const translationTableMessage = outboundRule?.message?.localized ?? {};

  const allTranslated = availableLanguages.every((item) => {
    return (
      translationTableSubject[item.code] &&
      translationTableSubject[item.code] !== '' &&
      translationTableMessage[item.code] &&
      Object.keys(translationTableMessage[item.code]).length !== 0
    );
  });

  const currentLanguage = availableLanguages.find(
    (item) => item.code === currentLang,
  );

  useEffect(() => {
    const message = getLanguageProperty(outboundRule, 'message', currentLang);

    editorRef?.current?.commands.setContent(message !== '' ? message : {});
  }, [editorRef, currentLang]);

  const emailContainerClassName = classNames({
    'email-container': true,
    'email-container--notalltranslated': !allTranslated,
    'email-container--alltranslated': allTranslated,
  });

  return (
    <div className={emailContainerClassName}>
      <div className="email-composer">
        <div className="widget-preview">
          <div className="widget-preview-browser">
            <div className="widget-preview-browser-head">
              <div className="widget-preview-browser-head-close" />
              <div className="widget-preview-browser-head-minimize" />
              <div className="widget-preview-browser-head-maximize" />
            </div>
            <div className="widget-preview-browser-body">
              <div className="widget-preview-browser-body-header">
                <TextInput
                  name="email-subject"
                  value={getLanguageProperty(
                    outboundRule,
                    'subject',
                    currentLang,
                  )}
                  placeholder="Email subject"
                  type="text"
                  error=""
                  onChange={(val) => {
                    runInAction(() => {
                      setLanguageProperty(
                        outboundRule,
                        'subject',
                        currentLang,
                        val,
                      );
                    });
                  }}
                />
                <PrimaryButton
                  fancy
                  small
                  className="button-container"
                  label="AI-translate"
                  onClick={() => {
                    const targetLanguage = availableLanguages.find(
                      (lang) => lang.code === currentLang,
                    );

                    modalStore?.openModalSmart(
                      MODALTYPE.DYNAMIC_AI_TRANSLATION,
                      {
                        baseLanguage: targetLanguage,
                        targetLanguage: availableLanguages,
                        languageList: availableLanguages,
                        getContent: (baseLang) => {
                          const content = getLanguageProperty(
                            outboundRule,
                            'message',
                            baseLang,
                          );
                          const title = getLanguageProperty(
                            outboundRule,
                            'subject',
                            baseLang,
                          );

                          return {
                            title: title,
                            content: content,
                          };
                        },
                        setContent: (translatedContent, lang) => {
                          console.log(translatedContent);
                          const { content, title } = translatedContent;
                          if (content) {
                            runInAction(() => {
                              setLanguageProperty(
                                outboundRule,
                                'message',
                                lang,
                                content,
                              );
                            });

                            if (lang === currentLang) {
                              editorRef?.current?.commands.setContent(
                                content !== '' ? content : {},
                              );
                            }
                          }
                          if (title) {
                            setLanguageProperty(
                              outboundRule,
                              'subject',
                              lang,
                              title,
                            );
                          }
                        },
                      },
                    );
                  }}
                />
                <LanguageDropdown
                  className="language-dropdown"
                  items={availableLanguages.map((lang) => {
                    return {
                      ...lang,
                      hasTranslation:
                        translationTableSubject[lang.code] &&
                        translationTableSubject[lang.code] !== '' &&
                        translationTableMessage[lang.code] &&
                        Object.keys(translationTableMessage[lang.code])
                          .length !== 0,
                    };
                  })}
                  selectedItem={currentLanguage}
                  onChange={(value) => {
                    runInAction(() => {
                      projectStore!.currentLanguage = value.code;
                    });
                  }}
                />
              </div>
              {editorRef?.current && (
                <>
                  <div className="toolbar-wrapper">
                    <Toolbar
                      editor={editorRef?.current}
                      additionalVariables={eventData}
                      aiStyle="email"
                      items={[
                        ToolbarItems.Basic,
                        ToolbarItems.Advanced,
                        ToolbarItems.Image,
                        ToolbarItems.AI,
                        ToolbarItems.VARIABLES,
                      ]}
                      language={currentLang}
                      disableSmartLinks
                      articleAsUrl
                    />
                  </div>
                  <Toolbar
                    editor={editorRef?.current}
                    aiStyle="email"
                    additionalVariables={eventData}
                    items={[
                      ToolbarItems.Basic,
                      ToolbarItems.Advanced,
                      ToolbarItems.Image,
                      ToolbarItems.AI,
                      ToolbarItems.VARIABLES,
                    ]}
                    language={currentLang}
                    floating
                    disableSmartLinks
                    articleAsUrl
                  />
                </>
              )}
              <EmailTemplate templateId={outboundRule.emailTemplate}>
                <EmailEditor
                  editorRef={editorRef}
                  onEditorReady={() => {
                    setToggleState(!toggleState);
                  }}
                  content={getLanguageProperty(
                    outboundRule,
                    'message',
                    currentLang,
                  )}
                  inputContentChanged={(content) => {
                    runInAction(() => {
                      setLanguageProperty(
                        outboundRule,
                        'message',
                        currentLang,
                        content,
                      );
                    });
                  }}
                />
              </EmailTemplate>
            </div>
          </div>
        </div>
      </div>
      <div className="settings-group">
        <div className="input-label mt-20">Sender</div>
        <FeedbackUserFilter
          hasBorder
          value={outboundRule.sender}
          truncatePreview={50}
          truncate={50}
          placeholder="Select user"
          onValueChanged={(value) => {
            runInAction(() => {
              if (value) {
                outboundRule.sender = value;
              }
            });
          }}
        />
        <div className="filter-form mt-30">
          <div className="side-by-side">
            <div className="input-label">Email template</div>
            {projectStore?.isProjectAdmin && (
              <a
                href="#"
                onClick={() => {
                  navigate(
                    `/projects/${projectStore?.currentProject?.id}/settings/email-templates`,
                  );
                }}
                className="info-link text-right"
              >
                Edit templates
              </a>
            )}
          </div>
          <EmailTemplateSelection
            templateId={outboundRule.emailTemplate}
            onTemplateSelected={(templateId) => {
              runInAction(() => {
                outboundRule.emailTemplate = templateId;
              });
            }}
          />
        </div>
        <div className="mt-30">
          <div className="input-label">Unsubscribe</div>
          <div className="switch-container mt-10">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={() => {
                Swal.fire(
                  'Spam prevention',
                  'You can not remove the unsubscribe link from outbound emails.',
                  'info',
                );
              }}
              checked={true}
            />
            <span>Show unsubscribe link</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default inject(
  'projectStore',
  'modalStore',
)(observer(EmailConfiguration));
