import { useState } from 'react';
import './CrawledPageLine.scss';

interface CrawledPageLineProps {
    crawledPage: {
        url: string;
        title: string;
        content: string;
    };
}

const CrawledPageLine = ({
    crawledPage
}: CrawledPageLineProps) => {
    const [showDetails, setShowDetails] = useState(false);

    return (
        <div className={`crawled-page ${showDetails && 'crawled-page--opened'}`} onClick={() => {
            setShowDetails(!showDetails);
        }}>
            <div className='crawled-page-title'>
                <i className={`fa-solid fa-chevron-${showDetails ? 'up' : 'down'}`} />
                <span className='url'>{crawledPage.url}</span>
            </div>
            {showDetails && (<div className='crawled-page-content'>
                <div className='crawled-page-content-title'>{crawledPage.title}</div>
                <div className='crawled-page-content-content'>{crawledPage.content}</div>
            </div>)}
        </div>
    );
};

export default CrawledPageLine;
