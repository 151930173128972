import Loading from 'components/Loading/Loading';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';

const ProjectOutboundLoading = () => {
  // @ts-ignore
  const { projectId, outboundId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    if (projectId && outboundId) {
      navigate(`/projects/${projectId}/outbound/${outboundId}`);
    }
  };

  const render = () => {
    return (
      <PageContainer>
        <PageHeadLine title="Outreach" />
        <PageContent hasTitle isCentered>
          <Loading />
        </PageContent>
      </PageContainer>
    );
  };

  return <div className="project-dashboard">{render()}</div>;
};

export default ProjectOutboundLoading;
