import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import { ModalStore } from 'stores/private/ModalStore';
import { inject, observer } from 'mobx-react';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { Title } from 'components/Title/Title';
import ReactLoading from 'react-loading';
import classNames from 'classnames';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import './CSVImporterModal.scss';
import ReactJson from 'react-json-view';
import { toast } from 'react-toastify';

const getFirst100Keys = (obj) => {
  // Get all keys of the object
  const allKeys = Object.keys(obj);

  // Slice the array to get only the first 100 keys
  const first100Keys = allKeys.slice(0, 100);

  // Create a new object with only the first 100 keys
  const newObj = {};
  first100Keys.forEach((key) => {
    newObj[key] = obj[key];
  });

  return newObj;
};

export const processAndSeparateData = (jsonData, extractKeys, uniqueKey) => {
  const mainData: any = [];
  const extractedData = new Map(); // To ensure uniqueness based on uniqueKey

  jsonData.forEach((item) => {
    // Extract main data by omitting keys specified in extractKeys, except the uniqueKey
    const mainItem = { ...item };
    extractKeys.forEach((key) => {
      if (key !== uniqueKey) {
        delete mainItem[key];
      }
    });
    mainData.push(mainItem);

    // Extract data specified by extractKeys and organize by uniqueKey
    const extractedItem = {};
    extractKeys.forEach((key) => {
      extractedItem[key] = item[key];
    });
    if (!extractedData.has(item[uniqueKey])) {
      extractedData.set(item[uniqueKey], extractedItem);
    }
  });

  return { mainData, extractedData: Array.from(extractedData.values()) };
};

export const addLanguageFieldsToSchema = (
  schema: SchemaField[],
  fieldsToAddLanguages: string[],
  localizations: { language: string }[],
) => {
  fieldsToAddLanguages.forEach((fieldKey) => {
    const field = schema.find((field) => field.key === fieldKey);
    if (field) {
      field.children = localizations.map((localization) => {
        return {
          name: `${field.name} (${localization.language})`,
          key: localization.language,
          required: true,
        };
      });
    }
  });
};

export interface SchemaField {
  name: string;
  key: string; // Use for the final JSON key path, e.g., "title.en"
  required: boolean;
  children?: SchemaField[]; // For nested structures
  csvKey?: string; // The CSV column name
}

const exampleSchema: SchemaField[] = [
  {
    name: 'Title',
    key: 'title',
    required: true,
    children: [
      {
        name: 'German Title',
        key: 'de',
        required: true,
      },
      {
        name: 'English Title',
        key: 'en',
        required: true,
      },
    ],
  },
];

interface CSVImporterModalProps {
  modalStore?: ModalStore;
}

const CSVImporterModal = ({ modalStore }: CSVImporterModalProps) => {
  const schema = modalStore!.customData.schema as SchemaField[];
  const onUpload = modalStore!.customData.onUpload as (
    data: any,
  ) => Promise<any>;

  const [showFieldMapping, setShowFieldMapping] = useState(false);
  const [currentJson, setCurrentJson] = useState(null);
  const [currentCsvFields, setCurrentCsvFields] = useState([] as any);
  const [updatedSchema, setUpdatedSchema] = useState(schema);
  const [isLoading, setIsLoading] = useState(false);

  const [uploadErrors, setUploadErrors] = useState();

  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length === 0) {
      return;
    }

    const file = acceptedFiles[0];
    parseCsvToJson(file);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'text/csv',
  });

  const parseCsvToJson = (file) => {
    Papa.parse(file, {
      complete: (result) => {
        const jsonData = result.data;
        if (jsonData.length === 0) {
          return;
        }

        const csvFields = Object.keys(jsonData[0]);

        setCurrentCsvFields(csvFields);
        setCurrentJson(jsonData);
        setShowFieldMapping(true);
      },
      skipEmptyLines: true,
      header: true,
    });
  };

  const updateSchemaField = (field, csvKey, path) => {
    const pathIndices = path.split('-').map(Number);
    let currentLevel: any = updatedSchema;

    for (let i = 0; i < pathIndices.length - 1; i++) {
      currentLevel = currentLevel[pathIndices[i]].children;
    }

    const fieldIndex = pathIndices[pathIndices.length - 1];
    currentLevel[fieldIndex] = { ...currentLevel[fieldIndex], csvKey: csvKey };

    setUpdatedSchema([...updatedSchema]);
  };

  const transformData = (parsedData, schema) => {
    const transformRow = (row, schema) => {
      let transformedRow = {};
      schema.forEach((field) => {
        if (field.children) {
          transformedRow[field.key] = transformRow(row, field.children);
        } else {
          transformedRow[field.key] = row[field.csvKey];
        }
      });
      return transformedRow;
    };

    return parsedData.map((row) => transformRow(row, schema));
  };

  const handleSubmitMapping = async () => {
    setIsLoading(true);
    const transformedData = transformData(currentJson, updatedSchema);
    const result = await onUpload(transformedData);

    if (result?.errors) {
      toast.error('There was an error uploading the data');
      setUploadErrors(getFirst100Keys(result?.errors?.fields ?? {}) as any);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      modalStore!.closeModal();
    }
  };

  const buildFieldMappingItem = (field, index) => {
    return (
      <Row key={field.key} alignItems="center" justifyContent="space-between">
        <p className="text bold">
          {field.name}
          {field.required ? '*' : ''}
        </p>
        <SizedContainer size={ContainerSizes.M}>
          <SelectDropDown
            labelPropertyName="label"
            valuePropertyName="key"
            items={currentCsvFields.map((csvField) => ({
              label: csvField,
              key: csvField,
            }))}
            onChange={(option) => updateSchemaField(field, option.key, index)}
          />
        </SizedContainer>
      </Row>
    );
  };

  const buildFieldMappingUI = (fields, path = '') => {
    return fields.map((field, index) => {
      const currentPath = path ? `${path}-${index}` : `${index}`;
      if (field.children && field.children.length > 0) {
        return buildFieldMappingUI(field.children, currentPath);
      }
      return buildFieldMappingItem(field, currentPath);
    });
  };

  const buildUpload = () => {
    const imageClassName = classNames('image-upload-component', {
      'image-upload-component--gragging': isDragActive,
    });

    return (
      <div className="image-upload-wrapper">
        <div className={imageClassName} {...getRootProps()}>
          {isLoading ? (
            <div className="loading-container">
              <ReactLoading
                className="react-loading-spinner"
                type="spinningBubbles"
                height={50}
                width={50}
              />
            </div>
          ) : (
            <div className="file-picker">
              <div className="draginfo-label">
                Drag and drop your file here
                <br />
                <span className="accepts-label">(.csv)</span>
              </div>
              <input className="image-file-picker" {...getInputProps()} />
            </div>
          )}
        </div>
        <span className="next-title">
          In the next step you will be able to define the columns ...
        </span>
      </div>
    );
  };

  const buildContent = () => {
    if (showFieldMapping) {
      return (
        <Column alignItems="flex-end">
          {buildFieldMappingUI(updatedSchema)}
          {uploadErrors && (
            <Column className="mt-20">
              <p className="text bold">Validation errors</p>
              <div className="csv-errors-container">
                <ReactJson
                  theme={
                    localStorage.getItem('theme') === 'dark'
                      ? 'brewer'
                      : 'shapeshifter:inverted'
                  }
                  src={uploadErrors}
                  style={{
                    background: 'transparent',
                  }}
                />
              </div>
            </Column>
          )}
          <PrimaryButton
            isLoading={isLoading}
            className="mt-30"
            label="Upload"
            onClick={handleSubmitMapping}
          />
        </Column>
      );
    }

    return buildUpload();
  };

  return (
    <div className="modal-with-title">
      <div className="modal-title-container">
        <Title label="Upload a CSV" />
      </div>
      <div className="modal-body">{buildContent()}</div>
    </div>
  );
};

export default inject('modalStore')(observer(CSVImporterModal));
