import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { uploadFileToServer } from 'services/FileUpload';
import ReactLoading from 'react-loading';
import './FileUpload.scss';
import Lightbox from 'react-image-lightbox';
import LoadingAnimationSmall from 'components/LoadingAnimationSmall/LoadingAnimationSmall';

interface FileUploadProps {
  url?: string;
  editable?: boolean;
  afterFileUpload?: (url: string) => void;
  label: string;
  formatInfo?: string;
  uploadPath?: string;
  accept?: string;
  canDelete?: boolean;
  shared?: boolean;
}

const FileUpload = ({
  url,
  editable,
  afterFileUpload,
  formatInfo,
  label,
  uploadPath,
  canDelete = true,
  accept = 'image/jpeg, image/png, image/svg+xml, application/pdf',
  shared,
}: FileUploadProps) => {
  const [loading, setLoading] = useState(false);
  const [showLightbox, setShowLightbox] = useState(false);
  const canEdit = editable && (!url || url.length === 0) && !shared;

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (afterFileUpload && acceptedFiles.length > 0) {
        setLoading(true);
        try {
          const uploadedFile = acceptedFiles[0];
          const uploadedUrl = await uploadFileToServer(
            uploadedFile,
            uploadPath,
          );
          afterFileUpload(uploadedUrl);
        } catch (exp) {
          afterFileUpload('');
        }
        setLoading(false);
      }
    },
    [afterFileUpload, uploadPath],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: accept,
  });

  const isImageUrl = (url: string) => {
    return /\.(jpeg|jpg|png|svg)$/i.test(url);
  };

  const buildPreview = () => {
    if (!url) return null;

    if (isImageUrl(url)) {
      return (
        <div
          className="file-upload-preview"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowLightbox(true);
          }}
        >
          <img
            className="file-upload-preview-image"
            src={url}
            alt="uploaded file"
          />
          {canDelete && url && !shared && (
            <div
              className="file-upload-preview-remove"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (afterFileUpload) afterFileUpload('');
              }}
            >
              <i className="fa-sharp fa-solid fa-circle-xmark file-upload-preview-remove-icon"></i>
            </div>
          )}
        </div>
      );
    } else {
      const fileName = url.split('/').pop();
      return (
        <div
          className="file-upload-preview-file-name"
          onClick={() => {
            window.open(url, '_blank');
          }}
        >
          <i className="fa-solid fa-circle-down"></i> Download file
          {canDelete && !shared && url && (
            <div
              className="file-upload-preview-file-name-remove"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (afterFileUpload) afterFileUpload('');
              }}
            >
              <i className="fa-sharp fa-solid fa-circle-xmark"></i>
            </div>
          )}
        </div>
      );
    }
  };

  const buildFilePicker = () => {
    if (!canEdit) return null;

    return (
      <div className="file-picker">
        <div className="draginfo-label">
          {(!url || url === '') && (
            <>
              <span className="accepts-label">
                {formatInfo ? formatInfo : `(JPG, PNG, SVG, PDF)`}
              </span>
            </>
          )}
        </div>
        <input className="file-input-picker" {...getInputProps()} />
      </div>
    );
  };

  return (
    <>
      <div className="file-upload-wrapper" {...(canEdit ? getRootProps() : {})}>
        {loading ? (
          <div className="loading-container">
            <LoadingAnimationSmall />
          </div>
        ) : (
          <>
            {buildPreview()}
            {buildFilePicker()}
          </>
        )}
      </div>
      {showLightbox && (
        <Lightbox
          mainSrc={url || ''}
          onCloseRequest={() => setShowLightbox(false)}
        />
      )}
    </>
  );
};

export default FileUpload;
