import './SharedFeatureRequestsList.scss';
import { inject, observer } from 'mobx-react';
import FeatureRequestCard, {
  FeatureRequestCardSkeleton,
} from 'components/FeatureRequestCard/FeatureRequestCard';
import { HeadLine } from 'components/HeadLine/HeadLine';
import { Title } from 'components/Title/Title';
import { SharedBoardStorePublic } from 'stores/public/SharedBoardStorePublic';
import { Bug } from 'models/Bug';

interface SharedFeatureRequestsListProps {
  sharedBoardStorePublic?: SharedBoardStorePublic;
  isShared?: boolean;
  boardShareToken?: string;
  showUnreadOnly?: boolean;
  widgetMode?: boolean;
  isSearchActive?: boolean;
  statusFilter?: string;
}

const SharedFeatureRequestsList = ({
  sharedBoardStorePublic,
  isShared = false,
  boardShareToken,
  showUnreadOnly = false,
  widgetMode = false,
  isSearchActive = false,
  statusFilter,
}: SharedFeatureRequestsListProps) => {
  const boardList = isSearchActive ? sharedBoardStorePublic?.featureRequestListSearch : sharedBoardStorePublic?.featureRequestList;
  let featureRequests = boardList?.data ?? [];
  featureRequests = featureRequests.filter((card: Bug) => {
    if (card.type !== sharedBoardStorePublic?.currentFeedbackType?.type) {
      return false;
    }

    if (showUnreadOnly && !card.notificationsUnread) {
      return false;
    }

    return true;
  });

  const handleScroll = (e) => {
    const bottom =
      Math.abs(
        e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop,
      ) < 1;
    if (bottom) {
      sharedBoardStorePublic?.fetchAndSetFeatureRequestList({
        loadMore: true,
      });
    }
  };

  const renderEmptyState = () => {
    if (!featureRequests || featureRequests?.length > 0) {
      return null;
    }
    var title = sharedBoardStorePublic?.translateText('noFeatureRequestsTitle');
    var subTitle = sharedBoardStorePublic?.translateText(
      'noFeatureRequestsDescription',
    );
    if (statusFilter) {
      title = sharedBoardStorePublic?.translateText('nothingFoundTitle');
      subTitle = sharedBoardStorePublic?.translateText(
        'nothingFoundDescription',
      );

      if (!widgetMode) {
        return (
          <div className="feature-request-list-empty">
            <Title className="mb-3" label={title} />
            <div className="text subtitle">
              {sharedBoardStorePublic?.translateText('resetFiltersOr')}{' '}
              <a
                href="#"
                onClick={() => {
                  (window as any).Gleap.startFeedbackFlow('featurerequests');
                }}
              >
                {sharedBoardStorePublic?.translateText(
                  'submitNewFeatureRequrest',
                )}
              </a>
              .
            </div>
          </div>
        );
      }
    }

    if (showUnreadOnly) {
      title = sharedBoardStorePublic?.translateText('nothingUnseenTitle');
      subTitle = sharedBoardStorePublic?.translateText(
        'nothingUnseenDescription',
      );
    }

    return (
      <div className="feature-request-list-empty">
        <HeadLine title={title} subtitle={subTitle} />
      </div>
    );
  };

  const isLoading =
    (!boardList ||
      boardList.isLoading) &&
    boardList?.data?.length === 0;

  const loadMoreIsOngoing =
    (!boardList ||
      !boardList?.data ||
      boardList.isLoading) &&
    sharedBoardStorePublic!.featureRequestList?.data?.length > 0;

  if (loadMoreIsOngoing) {
    featureRequests = [
      ...featureRequests,
      { isLoading: true, id: Math.random().toString(36).substring(7) } as any,
    ];
  }

  return (
    <div
      className="feature-request-list-wrapper"
      onScroll={(e) => {
        handleScroll(e);
      }}
    >
      <div className="feature-request-list">
        {isLoading && (
          <>
            {Array.from(Array(10).keys()).map((val, index) => (
              <FeatureRequestCardSkeleton height={130} key={index} />
            ))}
          </>
        )}
        {!isLoading && featureRequests.length > 0 && renderEmptyState()}
        {boardList?.data.map((featureRequest) => {
          if ((featureRequest as any).isLoading) {
            return (
              <FeatureRequestCardSkeleton
                height={130}
                key={featureRequest.id}
              />
            );
          }

          return (
            <div
              className="feature-request-card-container"
              key={featureRequest.id}
            >
              <FeatureRequestCard
                key={featureRequest.id}
                isShared={isShared}
                boardShareToken={boardShareToken}
                featureRequest={featureRequest}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default inject('sharedBoardStorePublic')(
  observer(SharedFeatureRequestsList),
);
