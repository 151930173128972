import { inject, observer } from 'mobx-react';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import SDKTutorial from '../SDKTutorial/SDKTutorial';
import './SDKInstallationModal.scss';

interface SDKInstallationModalProps {
  modalStore?: ModalStore;
}

const SDKInstallationModal = ({ modalStore }: SDKInstallationModalProps) => {
  const selectedPlatform = modalStore?.getCustomData(MODALTYPE.SDK_INSTALLATION_TUTORIAL);

  if (!selectedPlatform) {
    return null;
  }

  return (
    <div className="sdk-install-modal">
      <SDKTutorial
        platform={selectedPlatform.key}
        showOverview={() => {
          modalStore?.closeModal();
        }}
      />
    </div>
  );
};

export default inject('modalStore')(observer(SDKInstallationModal));
