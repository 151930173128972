import classNames from 'classnames';
import { OutboundCondition } from 'models/OutboundCondition';
import moment from 'moment';
import './ConditionTagStatic.scss';
import { timeintervalMultiplier } from 'components/ConditionTag/ConditionTag';

export const conditionIsValid = (condition: OutboundCondition) => {
  if (condition.data != null && condition.type && condition.matchOperator) {
    return true;
  }
  return false;
}

export const ConditionTagStatic = ({ className, value, userProperties = [], streamedEventKeys = [] }: {
  className?: string;
  streamedEventKeys?: any[];
  userProperties?: any[];
  value: OutboundCondition;
}) => {
  const isValid = conditionIsValid(value);
  const timeInterval = value.ti ?? 'day';
  const cardClassName = classNames(
    {
      'condition-tag-static': true,
      'condition-tag-static--invalid': (!isValid),
    },
    className,
  );

  const renderConditionName = () => {
    var eventName = value.event;
    var eventIcon = 'flag';

    const selectedEvent = userProperties[eventName];
    if (selectedEvent) {
      eventName = selectedEvent.label;
      eventIcon = selectedEvent.icon ?? "user";
    }

    if (streamedEventKeys) {
      const selectedEvent = streamedEventKeys.find(
        (event) => event.value === eventName,
      );
      if (selectedEvent) {
        eventName = selectedEvent.label;
        eventIcon = selectedEvent.icon ?? "flag";
      }
    }

    return (
      <div className="condition-name">
        <i className={`fa-solid fa-${eventIcon}`} />
        {eventName}
      </div>
    );
  }

  const calculateDays = (seconds, interval = 'day') => {
    const number = parseInt(seconds);
    const multiplier = (timeintervalMultiplier[interval] ?? timeintervalMultiplier.day);
    if (!isNaN(number)) {
      const days = value.data / multiplier;
      return days;
    }
    return '';
  };

  const getConditionDescription = () => {
    const resolveCountMatchOperator = (matchOperator) => {
      const matchOperatorMap = {
        'is': 'is',
        'isnot': 'is not',
        'greaterthan': 'is greater than',
        'lessthan': 'is less than',
        'contains': 'contains',
        'doesnotcontain': 'does not contain',
        'empty': 'is empty',
        'notempty': 'is not empty',
      }
      return matchOperatorMap[matchOperator];
    }

    const resolveDateMatchOperator = (matchOperator) => {
      const matchOperatorMap = {
        'after': 'after',
        'before': 'before',
        'on': 'on',
        'greaterthan': 'more than',
        'lessthan': 'less than',
        'exactly': 'exactly',
      }
      return matchOperatorMap[matchOperator];
    }

    if (value.type === 'latestdata' && (value.matchOperator === 'empty' || value.matchOperator === 'notempty')) {
      return `${resolveCountMatchOperator(value.matchOperator)}`;
    }

    if (value.matchOperator && value.data != null) {
      if (value.type === 'count') {
        return `count ${resolveCountMatchOperator(value.matchOperator)} ${value.data}`;
      }

      if (value.type === 'latestdata') {
        return `${resolveCountMatchOperator(value.matchOperator)} ${value.data}`;
      }

      if (value.dateOperator === 'relative') {
        const prefix = value.type === 'firstoccured' ? '' : 'last occured ';
        const days = calculateDays(value.data, timeInterval);
        return `${prefix}${resolveDateMatchOperator(value.matchOperator)} ${days} ${timeInterval}${days === 1 ? '' : 's'} ago`;
      }

      if (value.dateOperator === 'absolute') {
        const prefix = value.type === 'firstoccured' ? '' : 'last occured ';
        return `${prefix}${resolveDateMatchOperator(value.matchOperator)} ${moment(value.data).format('LL')}`;
      }
    }

    return '';
  }

  return <>
    <div
      className={cardClassName}>
      <div
        className='condition-tag-static--inner'
      >
        {renderConditionName()}
        <div className='condition-description'>{getConditionDescription()}</div>
      </div>
    </div>
  </>;
};
