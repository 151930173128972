/* eslint-disable jsx-a11y/media-has-caption */
import TabButton from 'components/TabButton/TabButton';
import { inject, observer } from 'mobx-react';
import { useState } from 'react';
import { BugStore } from 'stores/private/BugStore';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import './BugScreenContent.scss';
import BugScreenshot from './BugScreenshot';
import { Bug } from 'models/Bug';

interface BugScreenContentProps {
  ticket: Bug | undefined;
  allowActions?: boolean;
  modalStore?: ModalStore;
  shared?: boolean;
}

const BugScreenContent = ({
  ticket,
  allowActions = true,
  modalStore,
  shared,
}: BugScreenContentProps) => {
  const hideTabs = (!ticket || ticket.hideDetailTabsOnSharedBugs) && shared;
  const isFeatureRequest = ticket && ticket.type === 'FEATURE_REQUEST';
  const isInbox = ticket && ticket.type === 'INQUIRY';

  if (!ticket || hideTabs) {
    return <></>;
  }

  const showSubscriberList = () => {
    if (!ticket) {
      return false;
    }

    if (ticket?.type === 'FEATURE_REQUEST') {
      return true;
    }

    return false;
  };

  const showTechnicalInfoDataTabs = () => {
    if (!ticket) {
      return false;
    }

    return true;
  };

  const showScreenRecording =
    ticket.screenRecordingUrl && ticket.screenRecordingUrl.length > 0;

  return (
    <div className="meta-information">
      {allowActions && (
        <div className="tab-buttons tab-buttons--individual">
          <div className="tab-buttons--inner">
            <TabButton
              active={false}
              label="Ticket options"
              icon="bolt"
              className="hide-on-desktop"
              onClick={() => {
                modalStore!.openModalSmart(MODALTYPE.MOBILE_BUG_ACTIONS, {
                  shared,
                });
              }}
            />
            {showScreenRecording && (
              <TabButton
                active={false}
                primaryColor
                label="Screen recording"
                icon="film"
                onClick={() => {
                  modalStore!.openModalSmart(MODALTYPE.SCREEN_RECORDING, {
                    shared,
                  });
                }}
              />
            )}
            {showSubscriberList() && !shared && (
              <TabButton
                active={false}
                label={`${ticket?.upvotes?.length ?? 0} Upvotes`}
                icon="user-check"
                onClick={() => {
                  modalStore!.openModalSmart(MODALTYPE.SUBSCRIBER_LIST, {
                    shared,
                  });
                }}
              />
            )}
            {!isFeatureRequest && !isInbox && (
              <TabButton
                active={false}
                label="Replay"
                icon="play"
                onClick={() => {
                  modalStore!.openModalSmart(MODALTYPE.REPLAYS, {
                    shared,
                  });
                }}
              />
            )}
            {showTechnicalInfoDataTabs() && !isFeatureRequest && (
              <>
                <TabButton
                  active={false}
                  label="Environment data"
                  icon="globe"
                  onClick={() => {
                    modalStore!.openModalSmart(MODALTYPE.ENVIROMENT_DATA, {
                      shared,
                    });
                  }}
                />
                <TabButton
                  active={false}
                  label="Activity log"
                  icon="square-terminal"
                  onClick={() => {
                    modalStore!.openModalSmart(MODALTYPE.ACTIVITY_LOG, {
                      shared,
                    });
                  }}
                />
                <TabButton
                  active={false}
                  label="Custom data"
                  icon="table"
                  onClick={() => {
                    modalStore!.openModalSmart(MODALTYPE.CUSTOM_DATA, {
                      shared,
                    });
                  }}
                />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default inject('modalStore')(observer(BugScreenContent));
