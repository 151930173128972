import AggregationPicker from 'components/AggregationPicker/AggregationPicker';
import FutureFactChart from 'components/Charts/FactChart/FutureFactChart';
import FutureBarChart from 'components/Charts/StatisicBarChart/FutureStatisticBarChart';
import { formatAxisLabelForCSAT } from 'components/Charts/StatisicBarChart/StatisticBarChart';
import FutureStatisticDataTable, {
  StatisticListTypes,
} from 'components/Charts/StatisticDataTable/FutureStatisticDataTable';
import FeedbackUserFilter from 'components/FeedbackUserFilter/FeedbackUserFilter';
import Wrap from 'components/LayoutComponents/WrapComponent/WrapComponent';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import SmartDateRangePicker from 'components/SmartDateRangePicker/SmartDateRangePicker';
import { inject, observer } from 'mobx-react';
import { BarChartStatisticTypes, FactTypes } from 'models/Chart';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { Outlet } from 'react-router';
import {
  getBarChartStatisic,
  getFactStatistic,
  getListStatistic,
} from 'services/StatisticsService';
import { ProjectStore } from 'stores/private/ProjectStore';

interface ConversationCSATProps {
  projectStore?: ProjectStore;
}

const ConversationCSAT = ({ projectStore }: ConversationCSATProps) => {
  const projectId = projectStore?.currentProject?.id;

  const [startDate, setStartDate] = useState(
    moment().subtract(1, 'months').startOf('day') as Moment | null,
  );
  const [endDate, setEndDate] = useState(
    moment().endOf('day') as Moment | null,
  );

  const [filter, setFilter] = useState('MEDIAN');

  const [selectedUserFilter, setSelectedUserFilter] = useState(null as any);

  const ticketRatingListFilter = {};

  if (selectedUserFilter) {
    ticketRatingListFilter['data.processingUser'] = selectedUserFilter;
  }

  return (
    <>
      <PageContainer>
        <PageHeadLine title="Conversation CSAT" tag="BETA">
          <div className="filter-bar hide-on-mobile">
            <AggregationPicker
              className="border-margin-right"
              setFilter={setFilter}
              filter={filter}
            />
            <SmartDateRangePicker
              startIndex={1}
              onDateChange={(start, end) => {
                setStartDate(start);
                setEndDate(end);
              }}
            />
          </div>
        </PageHeadLine>
        <PageContent hasTitle>
          <Wrap>
            <FutureFactChart
              infoText="The Average Conversation Rating metric measures customer satisfaction with conversations. It's displayed as a percentage and rounded to the nearest whole number. The progress value reflects changes in conversation ratings over time."
              width="33.33%"
              future={getFactStatistic({
                projectId: projectId,
                query: {
                  chartType: FactTypes.MEDIAN_CONVERSATION_RATING,
                  startDate: startDate?.toDate(),
                  endDate: endDate?.toDate(),
                  aggsType: filter,
                },
              })}
            />
          </Wrap>
          <FutureBarChart
            infoText="The Median Conversation Rating metric shows the average customer satisfaction rating for conversations. It helps track how satisfied customers are with support interactions over time."
            formatter={formatAxisLabelForCSAT}
            future={getBarChartStatisic({
              projectId: projectId,
              query: {
                chartType: BarChartStatisticTypes.MEDIAN_CONVERSATION_RATING,
                startDate: startDate?.toDate(),
                endDate: endDate?.toDate(),
                timezone: moment.tz.guess(),
                aggsType: filter,
              },
            })}
          />
          <FutureStatisticDataTable
            infoText="The Average Rating per User Result List metric shows the average conversation ratings for different users involved in ticket processing. This helps evaluate user performance based on customer satisfaction. The table displays user names and their corresponding average ratings."
            future={async () => {
              return getListStatistic({
                projectId: projectId,
                query: {
                  chartType:
                    StatisticListTypes.AVERAGE_RATING_PER_USER_RESULT_LIST,
                  startDate: startDate?.toDate(),
                  endDate: endDate?.toDate(),
                },
              });
            }}
          />
          <FutureStatisticDataTable
            infoText="The Tickets with Rating List metric shows the number of tickets with ratings. It helps track the number of tickets that customers have rated, providing insights into customer satisfaction levels. The table displays ticket IDs and their corresponding ratings."
            future={async () => {
              return getListStatistic({
                projectId: projectId,
                query: {
                  chartType: StatisticListTypes.TICKETS_WITH_RATING_LIST,
                  startDate: startDate?.toDate(),
                  endDate: endDate?.toDate(),
                  ...ticketRatingListFilter,
                },
              });
            }}
            headerActions={
              <FeedbackUserFilter
                value={selectedUserFilter}
                truncatePreview={50}
                truncate={50}
                hasBorder
                placeholder="Select user"
                onValueChanged={(value) => {
                  setSelectedUserFilter(value);
                }}
              />
            }
          />
        </PageContent>
      </PageContainer>
      <Outlet />
    </>
  );
};

export default inject('projectStore')(observer(ConversationCSAT));
