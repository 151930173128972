import Comment, { CommentSkeleton } from 'components/Comment/Comment';
import { HeadLine } from 'components/HeadLine/HeadLine';
import LoadingAnimation from 'components/LoadingAnimation/LoadingAnimation';
import PublicSkeleton from 'components/Skeletons/PublicSkeleton';
import TextInput from 'components/TextInput/TextInput';
import { getFormFieldValue } from 'helper/FormDataHelper';
import Linkify from 'linkify-react';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { BugStore } from 'stores/private/BugStore';
import { SharedBoardStorePublic } from 'stores/public/SharedBoardStorePublic';
import { SharedSessionStore } from 'stores/public/SharedSessionStore';
import './Details.scss';
import FeatureRequestComment from './FeatureRequestComment';
import './FeatureRequestComments.scss';
import TipTapPreview from 'components/Editors/RichTextEditor/TipTapPreview';

interface FeatureRequestCommentsProps {
  bugStore?: BugStore;
  sharedBoardStorePublic?: SharedBoardStorePublic;
  sharedSessionStore?: SharedSessionStore;
  color?: string;
}

const FeatureRequestComments = ({
  bugStore,
  sharedBoardStorePublic,
  sharedSessionStore,
  color,
}: FeatureRequestCommentsProps) => {
  const [commentSenderEmail, setCommentSenderEmail] = useState('');
  const [commentText, setCommentText] = useState('');
  const [sendingComment, setSendingComment] = useState(false);
  const commentsRef = useRef(null as any);
  const bug = bugStore!.currentBug;
  const comments = bugStore!.currentComments;
  const enablePublicComments =
    sharedBoardStorePublic?.currentProject?.flowConfig?.enablePublicComments ??
    true;
  const enableWriteComments =
    sharedBoardStorePublic?.currentProject?.flowConfig?.enableUserComm ?? true;

  const writeCommentLabel =
    sharedBoardStorePublic?.translateText('writeComment');
  const writeCommentPlaceholder = sharedBoardStorePublic?.translateText(
    'writeCommentPlaceholder',
  );
  const sendButtonLabel = sharedBoardStorePublic?.translateText('send');
  const noCommentsYetLabel =
    sharedBoardStorePublic?.translateText('noComments');
  const noUpdatesYetLabel = sharedBoardStorePublic?.translateText('noUpdates');
  const kickstartConversationLabel = sharedBoardStorePublic?.translateText(
    'kickstartTheConversation',
  );
  const weWillPostUpdatesLabel =
    sharedBoardStorePublic?.translateText('weWillPostUpdates');
  const yourEmailLabel = sharedBoardStorePublic?.translateText('yourEmail');
  const commentsLabel = sharedBoardStorePublic?.translateText('comments');
  const updatesLabel = sharedBoardStorePublic?.translateText('updates');

  const sendSharedComment = async () => {
    if (!commentText || commentText.length === 0 || !bug) {
      return;
    }

    setSendingComment(true);

    if (
      !(sharedSessionStore?.session && sharedSessionStore?.session.email) &&
      commentSenderEmail &&
      commentSenderEmail.length > 0
    ) {
      // Update session.
      await sharedSessionStore?.updateSession({
        email: commentSenderEmail,
      });
    }

    await bugStore!.addSharedCommentWithSession(
      bug.shareToken,
      sharedSessionStore?.session.gleapId,
      sharedSessionStore?.session.gleapHash,
      commentText,
    );

    runInAction(() => {
      // Upvote count.
      bug.upvotesCount = (bug.upvotesCount ?? 0) + 1;
    });

    // Add upvote.
    sharedSessionStore!.updateUpvotes(true, bug.id);

    // Reload comments.
    bugStore!.getSharedComments(bug.shareToken);

    // Reset comment text.
    setCommentText('');
    setSendingComment(false);
  };

  const renderEmailInput = () => {
    if (sharedSessionStore?.session && sharedSessionStore?.session.email) {
      return;
    }

    return (
      <TextInput
        className="mb-5 shared-email-input"
        error=""
        placeholder={yourEmailLabel}
        initalValue={commentSenderEmail}
        onChange={(value) => {
          setCommentSenderEmail(value);
        }}
      />
    );
  };

  const renderCommentComposerShared = () => {
    if (!bug || bugStore?.loadingComments) {
      return null;
    }

    if (!enablePublicComments) {
      return null;
    }

    return (
      <div className="comment-composer comment-composer-shared">
        <div className="comments-title">{writeCommentLabel}</div>
        {renderEmailInput()}
        <div className="send-input-container">
          <textarea
            value={commentText}
            onChange={(e) => {
              setCommentText(e.target.value);
            }}
            onInput={(e: any) => {
              e.target.style.height = 'inherit';
              e.target.style.height = `${e.target.scrollHeight}px`;
            }}
            name="comment"
            className="send-input"
            placeholder={writeCommentPlaceholder}
          />
          {sendingComment ? (
            <LoadingAnimation />
          ) : (
            <button
              onClick={() => {
                if (sendingComment) {
                  return;
                }

                sendSharedComment();
              }}
              type="submit"
              className={`send-button ${
                (commentText.length === 0 ||
                  sendingComment ||
                  !(
                    commentSenderEmail.length > 0 ||
                    (sharedSessionStore?.session &&
                      sharedSessionStore?.session.email)
                  )) &&
                'send-button--disabled'
              }`}
              style={{
                ...(color
                  ? {
                      backgroundColor: `${color}`,
                      borderColor: `${color}`,
                    }
                  : null),
              }}
            >
              {sendButtonLabel}
            </button>
          )}
        </div>
      </div>
    );
  };

  const renderComments = () => {
    if (bugStore?.loadingComments) {
      return (
        <div className="loading-comments-container">
          <CommentSkeleton />
        </div>
      );
    }

    if (!comments) {
      return <></>;
    }

    if (comments.length === 0) {
      return (
        <div className="no-comments">
          <HeadLine
            title={
              sharedBoardStorePublic?.showPublicCommentsWriter
                ? noCommentsYetLabel
                : noUpdatesYetLabel
            }
            subtitle={
              sharedBoardStorePublic?.showPublicCommentsWriter
                ? kickstartConversationLabel
                : weWillPostUpdatesLabel
            }
          />
        </div>
      );
    }

    return [...comments]
      .sort((a, b) => {
        return (
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      })
      .map((comment, index) => {
        return <Comment shared comment={comment} key={comment.id} />;
      });
  };

  const featureRequestState = sharedBoardStorePublic
    ?.getFeatureRequestStates(false)
    .find((x) => {
      return x.key === bug?.status;
    });

  const renderFeatureRequestContent = () => {
    if (!bug) {
      return null;
    }

    if (bug?.form?.description) {
      return (
        <div className="static-comments-description-content">
          <TipTapPreview
            content={bug?.formData?.description}
            fallbackValue=""
          />
        </div>
      );
    }

    return (
      <Linkify
        tagName="span"
        options={{
          target: {
            url: '_blank',
          },
        }}
      >
        {getFormFieldValue({
          bug,
          key: 'description',
          fallback: 'formText',
        })}
      </Linkify>
    );
  };

  return (
    <div className="feature-request-container-comments">
      <div className="comments-container" ref={commentsRef}>
        <div className="static-comments">
          <FeatureRequestComment shared />
          {bug ? (
            <div className="static-comments-description">
              {renderFeatureRequestContent()}
            </div>
          ) : (
            <div
              style={{
                marginTop: '16px',
                marginBottom: '16px',
              }}
            >
              <PublicSkeleton
                width="70%"
                height={14}
                style={{
                  borderRadius: '8px',
                }}
              />
              <PublicSkeleton
                width="60%"
                height={14}
                style={{
                  borderRadius: '8px',
                  marginTop: '4px',
                }}
              />
            </div>
          )}
          {bug?.dueDate && (
            <div className="static-comments-eta">
              ETA: <span>{moment(bug?.dueDate).format('MMMM Do, YYYY')}</span>
            </div>
          )}
          <div className="feature-request-card-body-statustags">
            {bug && (
              <div
                className="feature-request-card-body-status"
                style={{
                  borderColor: featureRequestState?.color,
                  backgroundColor: featureRequestState?.color + '11',
                  color: featureRequestState?.color,
                }}
              >
                {featureRequestState?.title}
              </div>
            )}
            {bug ? (
              bug?.tags?.map((tag, index) => {
                return (
                  <div className="feature-request-card-body-tag" key={index}>
                    #{tag}
                  </div>
                );
              })
            ) : (
              <PublicSkeleton
                width={80}
                height={24}
                style={{
                  borderRadius: '5px',
                  marginTop: '0px',
                }}
              />
            )}
          </div>
        </div>
        {sharedBoardStorePublic?.showPublicComments && (
          <>
            <div className="comments-title">
              {enablePublicComments ? commentsLabel : updatesLabel}
            </div>
            <div className="feature-request-comments-container">
              {renderComments()}
            </div>
          </>
        )}
        {sharedBoardStorePublic?.showPublicCommentsWriter &&
          renderCommentComposerShared()}
      </div>
      <ReactTooltip
        id="infoTooltip"
        className="infoTooltip"
        delayHide={1000}
        type="light"
        effect="solid"
        html
      />
    </div>
  );
};

export default inject(
  'bugStore',
  'sharedBoardStorePublic',
  'sharedSessionStore',
)(observer(FeatureRequestComments));
