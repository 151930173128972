import { Editor } from '@tiptap/react';
import ActionPopover from 'components/ActionPopover/ActionPopover';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import TextInput from 'components/TextInput/TextInput';
import { useRef, useState } from 'react';

export const defaultVariables = [
  {
    label: 'User',
    options: [
      {
        label: 'Full name',
        value: 'session.name',
      },
      {
        label: 'First name',
        value: 'session.firstName',
      },
      {
        label: 'Email',
        value: 'session.email',
      },
      {
        label: 'User ID',
        value: 'session.userId',
      },
    ],
  },
  {
    label: 'Project',
    options: [
      {
        label: 'Project name',
        value: 'project.name',
      },
    ],
  },
];

interface VariableToolbarItemProps {
  editor: Editor | null;
  additionalVariables?: any[];
  position?: 'top' | 'bottom';
}

const VariableToolbarItem = ({
  editor,
  additionalVariables,
  position = 'bottom',
}: VariableToolbarItemProps) => {
  const [variable, setVariable] = useState(null as any);
  const [fallback, setFallback] = useState('');

  const popoverRef = useRef(null as any);

  const selectedItem = defaultVariables.flatMap((group) =>
    group.options.find((option) => option.value === variable),
  );

  return (
    <ActionPopover
      ref={popoverRef}
      position={position}
      child={
        <div
          className="bubble-menu-item"
          data-for="toolbarTooltip"
          data-tip="Variable"
        >
          <i className="fa-regular fa-input-text" />
        </div>
      }
    >
      <Column>
        <SelectDropDown
          showBorder
          grouped
          createable
          items={[...defaultVariables, ...(additionalVariables ?? [])]}
          selectedItem={selectedItem}
          placeholder="Type variable name or select from list"
          onChange={(value) => {
            setVariable(value);
          }}
        />
        <TextInput
          className="mt-10"
          placeholder="Fallback (used when variable is not set)"
          value={fallback}
          onChange={(val) => {
            setFallback(val);
          }}
        />
        <PrimaryButton
          className="mt-20"
          label="Save variable"
          disabled={variable == null || variable === '' || fallback === ''}
          onClick={() => {
            if (!editor) {
              return;
            }

            editor.commands.insertContent({
              type: 'serverVariable',
              attrs: {
                variableName: variable!.value,
                variableLabel: variable!.label,
                fallbackValue: fallback,
              },
            });

            popoverRef?.current.close();

            // Reset fallback.
            setFallback('');
          }}
        />
      </Column>
    </ActionPopover>
  );
};

export default VariableToolbarItem;
