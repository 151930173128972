import { makeAutoObservable, runInAction } from 'mobx';
import { Bug } from 'models/Bug';
import {
  getSkipAndLimitFromPage,
  PaginationDataList,
} from 'models/PaginationDataList';
import { Session } from 'models/Session';
import { toast } from 'react-toastify';
import WebSocketMessage from 'services/WebSocketMessage';
import { HttpSessionService } from 'services/http.clients/http.session.client';

// eslint-disable-next-line import/prefer-default-export
export class SessionStore implements WebSocketMessage {
  sessionFeedbackActivities: Bug[] = [];
  session?: Session = undefined;
  sessionEvents: any[] = [];
  loadingSession = true;
  loadingSessionEvents = false;
  hasMoreSessionEvents = true;
  stores: any = {};

  recentConversationList: PaginationDataList<any> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 5,
    isLoading: false,
    hasMore: true,
  };

  sessionsDataList: PaginationDataList<Session> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 50,
    isLoading: false,
    hasMore: true,
  };

  constructor() {
    makeAutoObservable(this);
  }

  setStores(stores) {
    this.stores = stores;
  }

  downloadBugsAsJSON = async () => {
    const json = JSON.stringify({
      session: this.session,
      feedbackItems: this.sessionFeedbackActivities,
    });
    const blob = new Blob([json], { type: 'application/json' });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = `${this.session?.id}_session.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  setLoadingSession(loadingSession) {
    this.loadingSession = loadingSession;
  }

  setSessionFeedbackActivities(sessionFeedbackActivities) {
    this.sessionFeedbackActivities = sessionFeedbackActivities;
  }

  setSession(session) {
    this.session = session;
  }

  resubscribeSession = async (sessionId) => {
    try {
      await HttpSessionService.getInstance().resubscribeSession({
        id: sessionId,
      });

      this.loadSession(sessionId, false);

      toast.success('Contact resubscribed ✅');
    } catch (err: any) {}
  };

  async loadSession(id?: string, hard = true, force = true) {
    if (
      !this.stores.projectStore ||
      !this.stores.projectStore.currentProject ||
      !id
    ) {
      return;
    }

    if (!force && this.session?.id === id) {
      return;
    }

    if (hard) {
      this.setSessionFeedbackActivities([]);
      this.setSession(undefined);
      this.setLoadingSession(true);
    }

    try {
      const response = await HttpSessionService.getInstance().findOne({
        id,
      });

      this.setSession(response);

      const responseActivities =
        await HttpSessionService.getInstance().getSessionActivities({ id });
      this.setSessionFeedbackActivities(responseActivities);
    } catch (exp) {}

    this.setLoadingSession(false);
  }

  async loadEventsOfSession(
    sessionId: string,
    limit: number,
    silent: boolean = false,
  ) {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    if (!silent) {
      this.sessionEvents = [];
      this.loadingSessionEvents = true;
    }
    try {
      const response = await HttpSessionService.getInstance().getSessionEvents({
        id: sessionId,
        query: { limit },
      });

      if (response.length === 0 || response.length < limit) {
        this.hasMoreSessionEvents = false;
      }

      runInAction(() => {
        if (!silent) {
          this.loadingSessionEvents = false;
        }
        this.sessionEvents = response;
      });
    } catch (err: any) {}
  }

  searchForSessionItems = async (args: {
    type: string;
    withFeedback: boolean;
    searchTerm: string;
  }) => {
    const currentProject = this.stores.projectStore.currentProject;

    if (!this.stores.projectStore || !currentProject) {
      return;
    }

    if (args.searchTerm === '') {
      this.getSessionItems({
        type: args.type,
        withFeedback: args.withFeedback,
        loadMore: false,
      });
      return;
    }

    try {
      this.sessionsDataList.isLoading = true;

      const response = await HttpSessionService.getInstance().searchForSessions(
        { searchTerm: args.searchTerm },
      );
      this.sessionsDataList.data = response ?? [];
      this.sessionsDataList.isLoading = false;
      this.sessionsDataList.hasMore =
        (response?.length ?? 0) === this.sessionsDataList.itemsInPage;
    } catch (_) {
      this.sessionsDataList.isLoading = false;
    }
  };

  getSessionItems = async (args: {
    filter?: any;
    type: string;
    withFeedback: boolean;
    loadMore?: boolean;
  }) => {
    const currentProject = this.stores.projectStore.currentProject;

    if (
      this.sessionsDataList.isLoading ||
      !this.stores.projectStore ||
      !currentProject
    ) {
      return;
    }

    try {
      runInAction(() => {
        this.sessionsDataList.isLoading = true;
      });

      if (args.loadMore) {
        this.sessionsDataList.pageIndex += 1;
      } else {
        this.sessionsDataList.pageIndex = 0;
        this.sessionsDataList.data = [];
      }

      const response = await HttpSessionService.getInstance().find({
        query: {
          filter: args.filter,
          type: args.type,
          withFeedback: args.withFeedback,
          ...getSkipAndLimitFromPage({
            pageIndex: this.sessionsDataList.pageIndex,
            itemsInPage: this.sessionsDataList.itemsInPage,
          }),
          sort: '-lastActivity',
        },
      });

      if (response) {
        runInAction(() => {
          this.sessionsDataList.data = [
            ...this.sessionsDataList.data,
            ...response,
          ];
        });
      }

      this.sessionsDataList.isLoading = false;
      this.sessionsDataList.hasMore =
        (response?.length ?? 0) === this.sessionsDataList.itemsInPage;
    } catch (_) {
      this.sessionsDataList.isLoading = false;
    }
  };

  onEvent = (event: string, data: any) => {};

  removeSession = async (sessionId) => {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    await HttpSessionService.getInstance().deleteOne({ id: sessionId });

    this.sessionsDataList = {
      data: [],
      pageIndex: 0,
      itemsInPage: 50,
      isLoading: false,
    };
    this.session = undefined;
    this.stores.projectStore.refreshBugsForCurrentProject();

    toast.success('Contact removed ✅');
  };

  createSession = async (data) => {
    try {
      if (
        !this.stores.projectStore ||
        !this.stores.projectStore.currentProject
      ) {
        return;
      }

      const createdSessionResponse =
        await HttpSessionService.getInstance().create({ data });

      runInAction(() => {
        this.session = createdSessionResponse;
      });

      toast.success('Contact created ✅');

      return createdSessionResponse;
    } catch (err: any) {
      toast.error('Contact could not be created.');
    }
  };

  updateSession = async (
    sessionId,
    data,
    originalObject?: any,
    hideToast?: boolean,
  ) => {
    try {
      if (
        !this.stores.projectStore ||
        !this.stores.projectStore.currentProject
      ) {
        return;
      }

      if (originalObject) {
        runInAction(() => {
          // Update the original object with the new data
          Object.assign(originalObject, data);
        });
      }

      const updatedSessionResponse =
        await HttpSessionService.getInstance().updateOne({
          id: sessionId,
          data,
        });

      if (!updatedSessionResponse) {
        return;
      }

      this.setSession(updatedSessionResponse);

      const responseActivities =
        await HttpSessionService.getInstance().getSessionActivities({
          id: updatedSessionResponse?.id,
        });
      this.setSessionFeedbackActivities(responseActivities);

      if (!hideToast) {
        toast.success('Contact updated ✅');
      }
    } catch (err: any) {
      if (!hideToast) {
        toast.error('Contact could not be updated.');
      }
    }
  };

  loadLastConversations = async (id: string, loadMore?: boolean) => {
    if (loadMore) {
      this.recentConversationList.pageIndex += 1;
    }

    this.recentConversationList = {
      ...this.recentConversationList,
      hasMore: true,
      isLoading: true,
    };

    const responseActivities =
      await HttpSessionService.getInstance().getSessionActivities({
        id: id,
        query: {
          ...getSkipAndLimitFromPage({
            pageIndex: this.recentConversationList.pageIndex,
            itemsInPage: this.recentConversationList.itemsInPage,
          }),
        },
      });

    if (responseActivities && responseActivities.length > 0) {
      if (responseActivities?.length < this.recentConversationList.itemsInPage) {
        this.recentConversationList.hasMore = false;
      }

      const data = loadMore
        ? [...this.recentConversationList.data, ...responseActivities]
        : responseActivities;

      this.recentConversationList = {
        ...this.recentConversationList,
        isLoading: false,
        data: data,
      };
    } else {
      this.recentConversationList = {
        ...this.recentConversationList,
        isLoading: false,
        hasMore: false,
      };
    }
    return;
  };

  refreshData = () => {};
}
