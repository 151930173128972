import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import TabButton from 'components/TabButton/TabButton';
import { useState } from 'react';
import ContentSuggestionsComponent from '../ContentSuggestionsComponent/ContentSuggestionsComponent';
import UnansweredQuestions from '../UnansweredQuestions/UnansweredQuestions';
import './ContentSuggestions.scss';

interface ContentSuggestionsProps { }

const ContentSuggestions = ({ }: ContentSuggestionsProps) => {
  const [currentSource, setCurrentSource] = useState('suggestions');

  const renderSuggestions = () => {
    return (
      <div className="table-view-card">
        <div className="table-view-card-header">
          <div className="table-view-card-header-title">
            <i className="fa fa-sparkles" /> Content suggestions
          </div>
        </div>
        <ContentSuggestionsComponent />
      </div>
    );
  };

  const renderUnansweredQuestions = () => {
    return (
      <div className="table-view-card">
        <div className="table-view-card-header">
          <div className="table-view-card-header-title">
            <i className="fa fa-messages-question" /> Unanswered questions
          </div>
        </div>
        <UnansweredQuestions />
      </div>
    );
  };

  const renderSwitch = () => {
    return (
      <>
        <div className="tab-buttons page-content-tabs">
          <div className="tab-buttons--inner">
            <TabButton
              active={currentSource === 'suggestions'}
              label="Suggestions"
              icon="sparkles"
              onClick={() => {
                setCurrentSource('suggestions');
              }}
            />
            <TabButton
              active={currentSource === 'unansweredquestions'}
              icon="messages-question"
              label="Unanswered questions"
              onClick={() => {
                setCurrentSource('unansweredquestions');
              }}
            />
          </div>
        </div>
        {currentSource === 'suggestions' && renderSuggestions()}
        {currentSource === 'unansweredquestions' && renderUnansweredQuestions()}
      </>
    );
  };

  return (
    <PageContainer className="answer-bot">
      <PageHeadLine title="Content suggestions" />
      <PageContent hasTitle hasPadding>
        {renderSwitch()}
      </PageContent>
    </PageContainer >
  );
};

export default ContentSuggestions;
