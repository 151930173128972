import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useClickedOutside } from 'services/Helper';
import './SelectPreviewField.scss';
import SelectDropDown, {
  SelectDropDownProps,
} from 'components/SelectDropDown/SelectDropDown';
import copyToClipboard from 'copy-to-clipboard';
import {
  getLanguageProperty,
  getProperty,
} from 'helper/AssignObjectKeysHelper';

const SelectPreviewField = ({
  items,
  onChange,
  selectedItem,
  isMulti = false,
  label,
  isSearchable = true,
  placeholder = 'Select',
  disabled = false,
  isEditingForced = false,
  labelPropertyName = 'label',
  valuePropertyName = 'value',
  className,
  isLoading = false,
  getOptionlabel = (option) => getProperty(option, labelPropertyName),
  getOptionValue = (option) => getProperty(option, valuePropertyName),
  createable = false,
  grouped = false,
}: SelectDropDownProps) => {
  const [isEditing, setIsEditing] = useState(false);

  const wrapperRef = useRef(null);
  useClickedOutside(wrapperRef, () => {
    setIsEditing(false);
  });

  const setIsEditingActive = () => {
    if (disabled) {
      return;
    }

    setIsEditing(true);
  };

  if (!isEditing && !isEditingForced) {
    return (
      <div
        className="select-preview-container"
        onClick={(event) => {
          event.stopPropagation();
          setIsEditingActive();
        }}
      >
        {selectedItem?.icon && (
          <i className={`mr-5 fa-solid ${selectedItem.icon}`} />
        )}
        {Array.isArray(selectedItem) ? (
          selectedItem.map((item) => (
            <div key={item.value} className="selected-item">
              {getLanguageProperty(item, 'label', 'en')}
            </div>
          ))
        ) : (
          <div className="selected-item">
            {getLanguageProperty(selectedItem, 'label', 'en')}
          </div>
        )}

        <Row className="hover-action-row">
          {!disabled && (
            <div
              className="hover-action mr-5"
              onClick={(event) => {
                event.stopPropagation();
                setIsEditingActive();
              }}
            >
              <i className="fas fa-pencil-alt hover-action-icon"></i>
            </div>
          )}
          <div
            className="hover-action"
            onClick={(event) => {
              event.stopPropagation();
              copyToClipboard(selectedItem?.value);
              toast.success('Copied to clipboard');
            }}
          >
            <i className="fas fa-clipboard hover-action-icon"></i>
          </div>
        </Row>
      </div>
    );
  }

  return (
    <div ref={wrapperRef}>
      <SelectDropDown
        className="select-input-preview"
        labelPropertyName="value"
        valuePropertyName="key"
        items={items}
        isMulti={isMulti}
        selectedItem={selectedItem}
        label={label}
        onChange={onChange}
        isSearchable={isSearchable}
        placeholder={placeholder}
        disabled={disabled}
        isLoading={isLoading}
        getOptionlabel={getOptionlabel}
        getOptionValue={getOptionValue}
        createable={createable}
        grouped={grouped}
        components={{
          SingleValue: (option: any) => {
            return (
              <div className="property-option-item">
                {option.data.icon && (
                  <i className={`mr-5 fa-solid ${option.data.icon}`} />
                )}
                <span>{getOptionlabel(option.data)}</span>
              </div>
            );
          },
          Option: (option: any) => {
            return (
              <div
                className="option option-list-item property-option-item"
                onClick={() => {
                  if (option.selectOption) {
                    option.selectOption(option.data);
                  }
                }}
              >
                <>
                  {option.data.icon && (
                    <i className={`mr-5 fa-solid ${option.data.icon}`} />
                  )}
                  <span>{getOptionlabel(option.data)}</span>
                </>
              </div>
            );
          },
        }}
      />
    </div>
  );
};

export default SelectPreviewField;
