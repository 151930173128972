const getProperty = (obj: any, path: string): any => {
    return path.split('.').reduce((obj, key) => {
        if (obj?.[key]) {
            return obj[key];
        }

        return null;
    }, obj);
};

export const replaceServerVariables = ({ contentJson, variables = {} }) => {
    if (!contentJson || typeof contentJson !== 'object') {
        return contentJson;
    }

    // Check if the current node is a serverVariable node
    if (contentJson.type === 'serverVariable' && contentJson.attrs) {
        const variableName = contentJson.attrs?.variableName;
        const fallbackValue = contentJson.attrs?.fallbackValue ?? '';

        // This is a special case for the session.firstName variable.
        if (variableName === 'session.firstName') {
            let actualValue = (getProperty(variables, 'session.name') || fallbackValue).split(' ')[0];
            if (actualValue === '') {
                actualValue = fallbackValue;
            }

            return {
                type: 'text',
                text: actualValue,
            };
        } else {
            let actualValue = getProperty(variables, variableName) || fallbackValue;

            if (actualValue === '') {
                actualValue = fallbackValue;
            }

            return {
                type: 'text',
                text: actualValue,
            };
        }
    }

    // Recursively process nested content items
    if (contentJson.content && Array.isArray(contentJson.content)) {
        return {
            ...contentJson,
            content: contentJson.content.map((node) => replaceServerVariables({ contentJson: node, variables })),
        };
    }

    return contentJson;
};
