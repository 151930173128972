import React, { useEffect, useState } from 'react';
import TextInput from 'components/TextInput/TextInput';
import './ContactViewModal.scss';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import ConditionPicker from 'components/ConditionPicker/ConditionPicker';
import { ProjectStore } from 'stores/private/ProjectStore';
import { inject, observer } from 'mobx-react';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import UserTypeDropDown from 'components/UserTypeDropDown/UserTypeDropDown';
import { audienceOptions } from 'pages/private/ProjectOutbounds/ProjectOutboundConfiguration/components/OutboundTriggerConfiguration/OutboundTriggerConfiguration';
import { SessionStore } from 'stores/private/SessionStore';
import LinkButton from 'components/LinkButton/LinkButton';
import Swal from 'sweetalert2';

interface ContactViewModalProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
  sessionStore?: SessionStore;
}

const ContactViewModal = ({
  projectStore,
  sessionStore,
  modalStore,
}: ContactViewModalProps) => {
  const customView = modalStore?.getCustomData(MODALTYPE.CONTACT_VIEW_EDIT);
  const isUpdate = customView?.id;
  const [audience, setAudience] = useState('all');
  const [conditions, setConditions] = useState([]);
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTitle(customView?.name ?? '');
    setAudience(customView?.targetAudience ?? '');
    setConditions(customView?.conditions ?? []);
  }, [customView]);

  useEffect(() => {
    if (projectStore?.currentProject) {
      projectStore.getStreamedEventKeys();
    }
  }, [projectStore?.currentProject]);

  const submitList = async () => {
    if (!conditions || conditions.length === 0) {
      Swal.fire({
        text: 'Please add at least one condition',
        icon: 'error',
      });
      return;
    }

    setLoading(true);
    if (isUpdate) {
      await projectStore?.updateContactView(customView?.id, {
        name: title,
        conditions,
        targetAudience: audience,
      });
      await sessionStore!.getSessionItems({
        filter: { contactViewId: customView.id },
        type: 'custom-lists',
        withFeedback: false,
      });
    } else {
      await projectStore?.createContactView({
        name: title,
        conditions,
        targetAudience: audience,
      });
    }
    await projectStore?.findAllContactViews();
    modalStore!.closeModal();
    setLoading(false);
  };

  return (
    <div className="content">
      <span className="title">
        {isUpdate ? `Update ${customView.name}` : 'Create a custom list'}
      </span>
      <TextInput
        placeholder={'Premium users'}
        label="List name"
        value={title}
        onChange={setTitle}
      />
      <div className="filters">
        <p className="label">Filter</p>
        <div className="filter-row">
          <UserTypeDropDown
            onValueChanged={(value) => {
              setAudience(value.value);
            }}
            value={
              audienceOptions.find(({ value }) => value === audience) ||
              audienceOptions[0]
            }
            options={audienceOptions}
          />
          <ConditionPicker
            streamedEventKeys={projectStore?.streamedEventKeys ?? []}
            conditions={conditions}
            onChange={setConditions}
            type="audience"
          />
        </div>
      </div>
      <div className="button-row">
        <PrimaryButton
          disabled={loading}
          label={isUpdate ? 'Update' : 'Create'}
          isLoading={loading}
          onClick={submitList}
        />
        {isUpdate && (
          <LinkButton
            icon="trash"
            iconSideRight={false}
            onClick={async () => {
              Swal.fire({
                text: 'Do you really want to delete this custom list?',
                showCancelButton: true,
                confirmButtonText: `Yes`,
                denyButtonText: `No`,
                showLoaderOnConfirm: loading,
              }).then(async (result) => {
                if (result.isConfirmed) {
                  setLoading(true);
                  await projectStore?.deleteContactView(customView.id);
                  setLoading(false);
                  modalStore!.closeModal();
                }
              });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default inject(
  'modalStore',
  'projectStore',
  'sessionStore',
)(observer(ContactViewModal));
