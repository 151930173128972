import axios from '../Axios';

const getAuthCodeFromAsana = (projectID) => {
  const redirectUri = `${process.env.REACT_APP_BASEURL}/integrations/asana`;

  return window.open(
    `https://app.asana.com/-/oauth_authorize?response_type=code&client_id=${
      process.env.REACT_APP_ASANA_CLIENT_ID
    }&redirect_uri=${encodeURIComponent(redirectUri)}&state=${projectID}`,
    '_self',
  );
};

const setAsanaAuthToken = (code, projectID) => {
  return axios.post(`/projects/${projectID}/integrations/asana`, {
    code,
  });
};

const getBoards = ({ projectID, integrationID }) => {
  return axios.get(
    `/projects/${projectID}/integrations/asana/${integrationID}/boards`,
  );
};

const getSections = ({ projectID, integrationID, boardID }) => {
  return axios.get(
    `/projects/${projectID}/integrations/asana/${integrationID}/sections?boardID=${boardID}`,
  );
};

const setAsanaActions = ({ projectID, integrationID, actions, settings }) => {
  return axios.put(
    `/projects/${projectID}/integrations/asana/${integrationID}`,
    {
      actions,
      settings,
    },
  );
};

const disconnectAsanaIntegration = ({ projectID, integrationID }) => {
  return axios.delete(
    `/projects/${projectID}/integrations/asana/${integrationID}`,
  );
};

export {
  disconnectAsanaIntegration,
  getAuthCodeFromAsana,
  setAsanaAuthToken,
  getBoards,
  getSections,
  setAsanaActions,
};
