import { Editor } from '@tiptap/react';
import ActionPopover from 'components/ActionPopover/ActionPopover';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import LinkButton from 'components/LinkButton/LinkButton';
import LoadingAnimationSmall from 'components/LoadingAnimationSmall/LoadingAnimationSmall';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import TextInput from 'components/TextInput/TextInput';
import { languages } from 'constants/Languages';
import { debounce } from 'lodash';
import { inject, observer } from 'mobx-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { searchSmartLinks } from 'services/ProjectHttpService';
import { ProjectStore } from 'stores/private/ProjectStore';
import './LinkToolbarItem.scss';

interface LinkToolbarItemProps {
  editor: Editor | null;
  projectStore?: ProjectStore;
  additionalVariables?: any[];
  articleAsUrl?: boolean;
  disableSmartLinks?: boolean;
  position?: 'top' | 'bottom';
  language?: string;
}

const LinkToolbarItem = ({
  editor,
  projectStore,
  additionalVariables,
  articleAsUrl = false,
  position = 'bottom',
  language,
  disableSmartLinks = false,
}: LinkToolbarItemProps) => {
  const [link, setLink] = useState('');
  const [links, setLinks] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [loadingArticles, setLoadingArticles] = useState(false);

  const currentProject = projectStore?.currentProject;
  const isEmbeddedMode =
    currentProject?.id === null || currentProject?.id === undefined;
  const helpcenterConfig = projectStore?.flowConfig?.helpcenterConfig;
  const availableLanguages = languages.filter((language) =>
    helpcenterConfig?.localization.find(
      (item) => item.language === language.code,
    ),
  );
  const selectedLanguage =
    language || availableLanguages[0]?.code || languages[0].code;

  const debounceSearch = useCallback(
    debounce((nextValue) => searchForArticles(nextValue), 750),
    [selectedLanguage],
  );

  const popoverRef = useRef(null as any);

  useEffect(() => {
    if (editor) {
      const updateIsActive = () => {
        const attributes = editor.getAttributes('image');
        if (attributes?.title && attributes?.title?.startsWith('http')) {
          setIsActive(true);
          return;
        }
        setIsActive(editor.isActive('link', { linktype: 'link' }));
      };

      const sectionUpdated = () => {
        updateIsActive();
        setLink('');
      };

      editor.on('selectionUpdate', sectionUpdated);
      editor.on('transaction', updateIsActive);

      updateIsActive();

      return () => {
        editor.off('selectionUpdate', sectionUpdated);
        editor.off('transaction', updateIsActive);
      };
    }
  }, [editor]);

  const sendMessage = (data: any) => {
    if (window.parent) {
      window.parent.postMessage(
        JSON.stringify({
          ...data,
          type: 'tourbuilder',
        }),
        '*',
      );
    }
  };

  // This function handles received messages
  const receiveMessage = (event) => {
    try {
      const data = JSON.parse(event.data);
      if (data.type === 'tourbuilder') {
        if (data.name === 'smartlink-search-result') {
          setLinks(data.data?.links);
          setLoadingArticles(false);
        }
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (isEmbeddedMode) {
      // Listen for message events
      window.addEventListener('message', receiveMessage, false);

      // Cleanup listener when the component is unmounted
      return () => {
        window.removeEventListener('message', receiveMessage);
      };
    }
  }, []);

  const searchForArticles = async (searchTerm: string) => {
    setLoadingArticles(true);

    try {
      if (isEmbeddedMode) {
        sendMessage({
          name: 'smartlink-search',
          data: {
            searchTerm,
            lang: 'en',
          },
        });
      } else {
        const response = await searchSmartLinks({
          projectId: currentProject!.id,
          query: {
            searchTerm,
            includeSDKLinks: !disableSmartLinks,
            lang: selectedLanguage,
            articleAsUrl,
          },
        });

        setLinks(response?.data);
        setLoadingArticles(false);
      }
    } catch (error) {
      console.error(error);
      setLoadingArticles(false);
    }
  };

  const setLinkInEditor = (linkToSet, newTab, text?) => {
    if (!editor) return;

    const { view, state } = editor;
    const { from, to } = view.state.selection;
    const selectedText = state.doc.textBetween(from, to, '');
    const selection = editor.state.selection;
    // @ts-ignore
    const selectedType = selection?.node?.type?.name;

    if (selectedType && selectedType === 'image') {
      // Update attribute in image
      editor
        .chain()
        .focus()
        .updateAttributes(
          'image',
          linkToSet?.length > 0 ? { title: linkToSet } : { title: undefined },
        )
        .run();
      popoverRef?.current.close();
      return;
    }

    // Empty
    if (linkToSet === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();
      popoverRef?.current.close();
      return;
    }

    if (selectedText && selectedText.length > 0) {
      editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: linkToSet, target: newTab ? '_blank' : '_self' })
        .updateAttributes('link', { linktype: 'link' })
        .run();
    } else {
      editor
        .chain()
        .focus()
        .insertContent({
          type: 'text',
          text: text || linkToSet,
          marks: [
            {
              type: 'link',
              attrs: {
                href: linkToSet,
                target: newTab ? '_blank' : '_self',
                linktype: 'link',
              },
            },
          ],
        })
        .run();
    }

    popoverRef?.current.close();
  };

  const handleArticleSelect = (link: any) => {
    setLink(link.url);
    setLinkInEditor(link.url, false, link.title);
  };

  const renderIcon = (item) => {
    if (disableSmartLinks) {
      return <i className="fa-solid fa-book-bookmark"></i>;
    }

    switch (item.type) {
      case 'helpcenter':
        return <i className="fa-solid fa-book-bookmark" />;
      case 'bot':
        return <i className="fa-solid fa-bolt" />;
      case 'collection':
        return <i className="fa-solid fa-books" />;
      case 'form':
        return <i className="fa-solid fa-pen-field" />;
      case 'survey':
        return <i className="fa-solid fa-square-poll-vertical" />;
      case 'news':
        return <i className="fa-solid fa-bullhorn" />;
      case 'checklist':
        return <i className="fa-solid fa-list-check" />;
      default:
        return <i className="fa-solid fa-book-bookmark" />;
    }
  };

  const renderList = () => {
    if (!links || links.length === 0) {
      return null;
    }

    return (
      <div className="link-article-wrappter mt-10" onClick={() => {}}>
        {links.map((link: any, index: number) => {
          return (
            <div
              className={`link-article-item`}
              onClick={() => {
                handleArticleSelect(link);
              }}
            >
              {renderIcon(link)}
              {link.title}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <ActionPopover
      ref={popoverRef}
      position={position}
      ignoreOnClick={false}
      onOpen={() => {
        const previousUrl = editor!.getAttributes('link').href;
        if (previousUrl) {
          setLink(previousUrl);
        }
      }}
      child={
        <div
          className={`bubble-menu-item ${isActive ? 'is-active' : ''}`}
          data-for="toolbarTooltip"
          data-tip={isActive ? 'Edit link' : 'Insert link'}
        >
          <i className="fa-solid fa-link" />
        </div>
      }
    >
      <Column>
        <div className="input-loading">
          <TextInput
            placeholder={
              disableSmartLinks
                ? 'Enter url or search for help article'
                : 'Enter url or search for smart links'
            }
            value={link}
            onChange={(val) => {
              setLink(val);
              debounceSearch(val);
            }}
          />
          {additionalVariables && additionalVariables?.length > 0 && (
            <SelectDropDown
              className="mt-10"
              showBorder
              grouped
              createable
              items={additionalVariables}
              placeholder="Append variable to link"
              onChange={(value) => {
                setLink(`${link}{{${value.value}}}`);
              }}
            />
          )}
          {loadingArticles && <LoadingAnimationSmall />}
        </div>
        {renderList()}
        {isActive && (
          <LinkButton
            label="Unlink"
            className="mt-10"
            onClick={() => {
              setLinkInEditor('', true);
            }}
          />
        )}
        <PrimaryButton
          className="mt-10"
          label="Save"
          disabled={link === ''}
          onClick={() => {
            setLinkInEditor(link, true);
          }}
        />
      </Column>
    </ActionPopover>
  );
};

export default inject('projectStore')(observer(LinkToolbarItem));
