import ListDataTable from 'components/ListDataTable/ListDataTable';
import {
  CellAction,
  CellLinkInternal,
  CellText,
} from 'components/ListTable/ListTable';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TabButton from 'components/TabButton/TabButton';
import { inject, observer } from 'mobx-react';
import { Property } from 'models/Property';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { PropertyStore } from 'stores/private/PropertyStore';
import Swal from 'sweetalert2';

interface FeedbackTypesPageProps {
  modalStore?: ModalStore;
  propertyStore?: PropertyStore;
  projectStore?: ProjectStore;
}

const FeedbackTypesPage = ({
  modalStore,
  propertyStore,
  projectStore,
}: FeedbackTypesPageProps) => {
  // @ts-ignore
  const { projectId } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const { flowConfig } = projectStore?.editingProject || {};

  useEffect(() => {
    setIsLoading(true);
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
    setIsLoading(false);
  }, [projectId]);

  const prepareColumns = () => {
    const baseColumns = [
      {
        child: <div>Label</div>,
        id: 'label',
      },
    ];

    return baseColumns;
  };

  const addBoard = (boardName) => {
    const baseName = boardName.toLowerCase().replace(/[^a-z]/g, '');

    if (!baseName || baseName === '') {
      toast.error('Board name must be at least contain one character.');
      return;
    }

    if (
      projectStore!.currentProject!.projectTypes.find(
        (board) => board.name.toLowerCase().replaceAll(" ", "") === baseName.toLowerCase().replaceAll(" ", ""),
      )
    ) {
      toast.error('Board name characters must be unique.');
      return;
    }

    projectStore!.addProjectType(boardName, baseName);
  };

  const dataItemBuilder = (dataItem: any, index: number) => {
    return {
      key: index.toString(),
      children: prepareColumns().map((column) => {
        return {
          child: (
            <CellLinkInternal
              link={`/projects/${projectId}/settings/feedbacktypes/${dataItem.path}`}
              text={dataItem.name}
            />
          ),
        };
      }),
    };
  };

  return (
    <PageContainer>
      <PageHeadLine title="Boards">
        <PrimaryButton
          label="Add new board"
          onClick={() => {
            Swal.fire({
              title: 'Add a new board',
              input: 'text',
              inputPlaceholder: 'Board name',
              showCancelButton: true,
              confirmButtonText: 'Add',
              showLoaderOnConfirm: true,
              preConfirm: (boardName) => {
                addBoard(boardName);
              },
            });
          }}
        />
      </PageHeadLine>
      <PageContent hasTitle>
        <ListDataTable
          columns={prepareColumns()}
          data={projectStore?.currentProject?.projectTypes ?? []}
          dataTableItemBuilder={dataItemBuilder}
          noDataMessage="No data attributes yet."
        />
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'modalStore',
  'propertyStore',
  'projectStore',
)(observer(FeedbackTypesPage));
