import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import Gleap from 'gleap';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { ActionFlowNode } from 'models/Bot';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import ReactFlow, { Background, Controls, MiniMap, Node } from 'reactflow';
import 'reactflow/dist/style.css';
import { ProjectStore } from 'stores/private/ProjectStore';
import { SidenavStore } from 'stores/private/SidenavStore';
import ActionEditor from './components/ActionEditor/ActionEditor';
import './FlowChart.scss';
import Loading from 'components/Loading/Loading';
import Swal from 'sweetalert2';
import LinkButton from 'components/LinkButton/LinkButton';
import LanguageDropdown from 'components/LanguageDropdown/LanguageDropdown';
import { hasTranslationForLanguage } from 'helper/AssignObjectKeysHelper';
import { languages } from 'constants/Languages';
import classNames from 'classnames';
import '../../../styles/scss-variable.scss';

const nodeTypes: any = {
  defaultAction: ActionEditor,
};

interface FlowChartProps {
  projectStore?: ProjectStore;
  sidenavStore?: SidenavStore;
}

const FlowChart = ({ projectStore, sidenavStore }: FlowChartProps) => {
  const navigate = useNavigate();
  const currentFlows = projectStore?.bot?.actionFlows ?? [];
  const { projectId, botId } = useParams();

  const nodesDragable = projectStore?.botNodesDraggable ?? true;

  const currentLang = projectStore?.currentLanguage ?? 'en';
  const availableLanguages = languages.filter((language) =>
    projectStore?.currentProject?.localizations.find(
      (item) => item.language === language.code,
    ),
  );

  const currentLanguage = availableLanguages.find(
    (item) => item.code === currentLang,
  );

  useEffect(() => {
    runInAction(() => {
      sidenavStore!.sidenavHidden = true;
      sidenavStore!.mainSidenavHidden = true;
    });

    return () => {
      runInAction(() => {
        sidenavStore!.sidenavHidden = false;
        sidenavStore!.mainSidenavHidden = false;
      });
    };
  }, []);

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    if (botId && projectId) {
      projectStore!.getBot(projectId, botId);
    }
  }, [botId, projectId]);

  useEffect(() => {
    if (projectStore?.currentProject) {
      projectStore.getStreamedEventKeys();
    }
  }, [projectStore?.currentProject]);

  const [nodes, setNodes] = useState([] as any);
  const [edges, setEdges] = useState([] as any);

  useEffect(() => {
    Gleap.showFeedbackButton(false);

    return () => {
      Gleap.showFeedbackButton(true);
    };
  }, []);

  useEffect(() => {
    if (currentFlows && currentFlows.length > 0) {
      setNodes(prepareNodes(currentFlows));
      setEdges(prepareEdges(currentFlows));
    }
  }, [currentFlows, nodesDragable]);

  const prepareNodes = (actionFlows: ActionFlowNode[]) => {
    return actionFlows.map((actionFlow) => {
      const { id, position } = actionFlow;

      let conditionalData = {};

      if (!nodesDragable) {
        conditionalData = {
          ...conditionalData,
          dragHandle: '.custom-drag-handle',
        };
      }

      return {
        ...conditionalData,
        id,
        type: 'defaultAction',
        position: position
          ? {
              x: position.x,
              y: position.y,
            }
          : {
              x: 0,
              y: 0,
            },
        deletable: true,
        data: actionFlow,
      };
    });
  };

  const prepareEdges = (
    obj,
    preparedEdges: any[] = [],
    currentActionFlowId = '',
    currentIndex = 0,
    actionType = '',
  ) => {
    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        if (Array.isArray(obj[key])) {
          obj[key].forEach((item, index) => {
            // Directly using key to infer actionType if it's relevant
            const newActionType = ['actions', 'conditions', 'buttons'].includes(
              key,
            )
              ? key
              : actionType;
            prepareEdges(
              item,
              preparedEdges,
              currentActionFlowId,
              index,
              newActionType,
            );
          });
        } else {
          // Special case for source handle else
          const newActionType = ['fallbackAction'].includes(key)
            ? key
            : actionType;

          prepareEdges(
            obj[key],
            preparedEdges,
            currentActionFlowId,
            currentIndex,
            newActionType,
          );
        }
      } else if (key === 'id') {
        currentActionFlowId = obj[key];
      } else if (key === 'type' && obj[key] === 'actionflow') {
        const { actionFlow: targetActionFlow } = obj;
        const source = currentActionFlowId;
        const target = targetActionFlow;

        // Constructing sourceHandle with actionType and currentIndex, appending '-else' for else cases
        let sourceHandle = `${source}-${actionType}-${currentIndex}`;

        // Special case for source handle
        if (actionType === 'fallbackAction') {
          sourceHandle = `${source}-conditions-else`;
        }

        preparedEdges.push({
          id: `e-${sourceHandle}-${target}`,
          source,
          target,
          sourceHandle: sourceHandle,
          animated: false,
          type: 'smoothstep',
          markerEnd: { type: 'arrow' },
          deletable: true,
          focusable: true,
        });
      }
    }

    return preparedEdges;
  };

  const onNodesDelete = useCallback(
    (nodesToDelete: Node[]) => {
      runInAction(() => {
        for (const node of nodesToDelete) {
          projectStore!.bot.actionFlows = projectStore!.bot.actionFlows.filter(
            (actionFlow) => actionFlow.id !== node.id,
          );
        }
      });
    },
    [projectStore?.bot],
  );

  const onNodesChange = useCallback(
    (changes) => {
      runInAction(() => {
        projectStore!.bot.actionFlows = changes.reduce((prevNodes, change) => {
          return prevNodes.map((node) => {
            if (node.id === change.id) {
              // if the node ID matches the ID in the changes array, check if position is defined before updating the x and y properties
              if (change.position) {
                const { x = node.position.x, y = node.position.y } =
                  change.position;
                return {
                  ...node,
                  position: { x, y },
                };
              } else {
                // if position is undefined, return the original node object
                return node;
              }
            } else {
              // otherwise, return the original node object
              return node;
            }
          });
        }, projectStore!.bot.actionFlows);
      });
    },
    [projectStore?.bot],
  );

  const onConnect = useCallback(
    (params) => {
      const { target, sourceHandle } = params;

      // Parsing the sourceHandle to extract detailed information
      const parts = sourceHandle.match(
        /(af-?\d+)-(actions|buttons|conditions)(?:-(\d+))?(-else)?/,
      );

      if (!parts) {
        return;
      }

      const [, sourceId, elementType, elementIndex, elsePart] = parts;
      const parsedElementIndex = isNaN(parseInt(elementIndex, 10))
        ? null
        : parseInt(elementIndex, 10);

      // Update the action flows with the new connection
      const updatedActionFlows = projectStore!.bot.actionFlows.map(
        (actionFlow) => {
          // Only update the action flow that matches the source of the connection
          if (actionFlow.id === sourceId) {
            const updatedActions = actionFlow.actions.map((action, idx) => {
              // Found the action from which the connection originates
              if (elementType === 'buttons' && action.buttons) {
                // For button connections, update the targeted button's action
                const updatedButtons = action.buttons.map(
                  (button, buttonIdx) => {
                    // Update a specific button, identified by the index
                    if (buttonIdx === parsedElementIndex) {
                      return {
                        ...button,
                        action: {
                          type: 'actionflow',
                          actionFlow: target,
                        },
                      };
                    }
                    return button;
                  },
                );

                return { ...action, buttons: updatedButtons };
              } else if (elementType === 'conditions' && action.conditions) {
                // Handling the else part specifically
                if (elsePart) {
                  return {
                    ...action,
                    fallbackAction: {
                      type: 'actionflow',
                      actionFlow: target,
                    },
                  };
                } else if (parsedElementIndex !== null) {
                  // For condition connections, update or add the condition
                  const updatedConditions = action.conditions.map(
                    (condition, conditionIdx) => {
                      // If you need to target a specific condition by index, check conditionIdx here
                      if (conditionIdx === parsedElementIndex) {
                        return {
                          ...condition,
                          actionFlow: target, // Update the targeted action flow
                        };
                      }
                      return condition;
                    },
                  );

                  return { ...action, conditions: updatedConditions };
                }
              }

              return action;
            });

            return { ...actionFlow, actions: updatedActions };
          }
          return actionFlow;
        },
      );

      runInAction(() => {
        projectStore!.bot.actionFlows = updatedActionFlows;
      });
    },
    [projectStore, setEdges],
  );

  const onDeleteConnection = useCallback(
    (edgesToDelete) => {
      const selectedEdges = edgesToDelete.filter((edge) => edge.selected);

      selectedEdges.forEach((edge) => {
        const { sourceHandle } = edge;

        // Parsing the sourceHandle to extract detailed information
        const parts = sourceHandle.match(
          /(af-?\d+)-(actions|buttons|conditions)(?:-(\d+))?(-else)?/,
        );

        if (!parts) {
          return;
        }

        const [, sourceId, elementType, elementIndex, elsePart] = parts;
        const parsedElementIndex = isNaN(parseInt(elementIndex, 10))
          ? null
          : parseInt(elementIndex, 10);

        // Update the action flows to reflect the deletion of the connection
        const updatedActionFlows = projectStore!.bot.actionFlows.map(
          (actionFlow) => {
            if (actionFlow.id === sourceId) {
              const updatedActions = actionFlow.actions.map((action) => {
                if (
                  elementType === 'buttons' &&
                  action.buttons &&
                  parsedElementIndex !== null
                ) {
                  // For button deletions, remove the target from the specified button's action
                  const updatedButtons = action.buttons.map(
                    (button, buttonIdx) => {
                      if (buttonIdx === parsedElementIndex) {
                        // Assume we simply clear the action; adjust logic as necessary
                        return { ...button, action: null };
                      }
                      return button;
                    },
                  );

                  return { ...action, buttons: updatedButtons };
                } else if (elementType === 'conditions' && action.conditions) {
                  // Handling deletion of a regular or else condition connection
                  if (elsePart) {
                    // Clear the fallbackAction for else conditions
                    return { ...action, fallbackAction: null };
                  } else if (parsedElementIndex !== null) {
                    // For regular condition connection deletions, clear the target actionFlow
                    const updatedConditions = action.conditions.map(
                      (condition, conditionIdx) => {
                        if (conditionIdx === parsedElementIndex) {
                          return { ...condition, actionFlow: null };
                        }
                        return condition;
                      },
                    );

                    return { ...action, conditions: updatedConditions };
                  }
                }

                return action;
              });

              return { ...actionFlow, actions: updatedActions };
            }
            return actionFlow;
          },
        );

        runInAction(() => {
          projectStore!.bot.actionFlows = updatedActionFlows;
        });
      });
    },
    [projectStore],
  );

  const renderBotDesigner = () => {
    return (
      <ReactFlow
        nodes={nodes}
        onNodesChange={onNodesChange}
        onNodesDelete={onNodesDelete}
        onConnect={onConnect}
        onEdgesDelete={onDeleteConnection}
        edges={edges}
        nodeTypes={nodeTypes}
        fitView
        maxZoom={1}
      >
        <Background />
        <Controls />
        <MiniMap nodeStrokeWidth={3} zoomable pannable />
      </ReactFlow>
    );
  };

  const flowChartBotClassName = classNames({
    'page-centered-main-tabs': true,
    'flow-chart-bot': true,
    'flow-chart-bot--notalltranslated': !hasTranslationForLanguage(
      currentFlows,
      currentLang,
    ),
    'flow-chart-bot--alltranslated': hasTranslationForLanguage(
      currentFlows,
      currentLang,
    ),
  });

  return (
    <PageContainer className={flowChartBotClassName}>
      <PageHeadLine
        isEditable
        onChangeTitle={(newTitle) => {
          projectStore!.bot.name = newTitle;
        }}
        onActionBack={() => {
          navigate(`/projects/${projectId}/bots`);
        }}
        title={projectStore?.bot?.name}
      >
        <div className="header-content-left">
          <LanguageDropdown
            className="flow-chart-bot-language-dropdown"
            items={availableLanguages.map((lang) => {
              return {
                ...lang,
                hasTranslation: hasTranslationForLanguage(
                  currentFlows,
                  lang.code,
                ),
              };
            })}
            selectedItem={currentLanguage}
            onChange={(value) => {
              runInAction(() => {
                projectStore!.currentLanguage = value.code;
              });
            }}
          />
        </div>
        <div className="header-content-right">
          {projectStore?.bot?.status === 'draft' && (
            <LinkButton
              className="danger"
              label="Delete"
              onClick={() => {
                Swal.fire({
                  text: 'Are you sure you want to delete this bot?',
                  showCancelButton: true,
                  confirmButtonText: `Delete`,
                  confirmButtonColor: '#e50d00',
                  denyButtonText: `No`,
                }).then(async (result) => {
                  if (result.isConfirmed && botId) {
                    await projectStore?.deleteBot(botId);
                    navigate(`/projects/${projectId}/bots`);
                  }
                });
              }}
            />
          )}
          <PrimaryButton
            onClick={() => {
              if (projectId && botId) {
                projectStore!.updateBot(projectId, botId, {
                  status: projectStore?.bot?.status,
                  name: projectStore?.bot?.name,
                  actionFlows: projectStore?.bot?.actionFlows,
                  triggerPriority: projectStore?.bot?.triggerPriority,
                  trigger: projectStore?.bot?.trigger,
                  triggerFilter: projectStore?.bot?.triggerFilter,
                  triggerDelaySettings: projectStore?.bot?.triggerDelaySettings,
                  triggerType: projectStore?.bot?.triggerType,
                  targetAudience: projectStore?.bot?.targetAudience,
                  conditions: projectStore?.bot?.conditions,
                });
              }
            }}
            className="save-button ml-10"
            icon="check-circle"
            label="Save"
          />
          {projectStore?.bot?.status === 'draft' && (
            <PrimaryButton
              onClick={async () => {
                if (projectId && botId) {
                  projectStore!.updateBot(projectId, botId, {
                    status: 'live',
                    name: projectStore?.bot?.name,
                    actionFlows: projectStore?.bot?.actionFlows,
                    triggerPriority: projectStore?.bot?.triggerPriority,
                    trigger: projectStore?.bot?.trigger,
                    triggerType: projectStore?.bot?.triggerType,
                    targetAudience: projectStore?.bot?.targetAudience,
                    conditions: projectStore?.bot?.conditions,
                  });
                }
              }}
              className="save-button live ml-10"
              icon="play"
              label="Publish"
            />
          )}
          {projectStore?.bot?.status === 'live' && (
            <PrimaryButton
              className="save-button danger ml-10"
              icon="pause"
              label="Unpublish"
              onClick={async () => {
                if (projectId && botId) {
                  projectStore!.updateBot(projectId, botId, {
                    status: 'draft',
                    name: projectStore?.bot?.name,
                    actionFlows: projectStore?.bot?.actionFlows,
                    triggerPriority: projectStore?.bot?.triggerPriority,
                    trigger: projectStore?.bot?.trigger,
                    triggerType: projectStore?.bot?.triggerType,
                    targetAudience: projectStore?.bot?.targetAudience,
                    conditions: projectStore?.bot?.conditions,
                  });
                }
              }}
            />
          )}
        </div>
      </PageHeadLine>
      {!projectStore?.bot && (
        <PageContent hasTitle>
          <Loading />
        </PageContent>
      )}
      {projectStore?.bot && (
        <PageContent hasTitle>{renderBotDesigner()}</PageContent>
      )}
    </PageContainer>
  );
};

export default inject('projectStore', 'sidenavStore')(observer(FlowChart));
