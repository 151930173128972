import { arrayMoveImmutable } from 'array-move';
import { ReactComponent as DragIcon } from 'assets/icons/dragicon.svg';
import NoArticleImage from 'assets/illustrations/HelpArticle.svg';
import { HeadLine } from 'components/HeadLine/HeadLine';
import LinkButton from 'components/LinkButton/LinkButton';
import { CellGestureDetector, CellTag } from 'components/ListTable/ListTable';
import Loading from 'components/Loading/Loading';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import * as CodeBlock from 'react-code-blocks';
import Collapsible from 'react-collapsible';
import { useNavigate, useParams } from 'react-router';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { toast } from 'react-toastify';
import makeUrlFriendly from 'services/UrlHelper';
import { HelpcenterStore } from 'stores/private/HelpcenterStore';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import Swal from 'sweetalert2';
import './HelpcenterCollectionDetail.scss';
import ReactTooltip from 'react-tooltip';
import DropDownButton from 'components/DropDownButton/DropDownButton';
import { languages } from 'constants/Languages';
import { hasTranslation } from 'services/Helper';

export const calculateTotalArticleVotes = (votes: any) => {
  const upvotes = (votes ?? {}).count ?? {};

  var total = {
    bad: 0,
    good: 0,
    neutral: 0,
  };

  const keys = Object.keys(upvotes);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const value = upvotes[key];
    if (value) {
      const moodKeys = Object.keys(value);
      for (let j = 0; j < moodKeys.length; j++) {
        const moodKey = moodKeys[j];
        const moodValue = value[moodKey];
        total[moodKey] += moodValue;
      }
    }
  }

  return total;
};

interface HelpcenterCollectionDetailProps {
  projectStore?: ProjectStore;
  helpcenterStore?: HelpcenterStore;
  modalStore?: ModalStore;
}

const HelpcenterCollectionDetail = ({
  projectStore,
  helpcenterStore,
  modalStore,
}: HelpcenterCollectionDetailProps) => {
  const { projectId, collectionId } = useParams();
  const navigate = useNavigate();

  const projectUsers = projectStore?.currentProjectUsers;
  const project = projectStore?.currentProject;
  const collection = helpcenterStore?.currentCollection;
  const articles = helpcenterStore?.currentArticels ?? [];
  const subCollections = helpcenterStore?.collections ?? [];
  const helpcenterConfig = projectStore?.flowConfig?.helpcenterConfig;
  const previewLanguageCode = helpcenterConfig?.localization[0].language;

  const [isLoading, setIsLoading] = useState(true);

  const availableLanguages = languages.filter((language) =>
    helpcenterConfig?.localization.find(
      (item) => item.language === language.code,
    ),
  );

  const cloneArticle = async (articleId, collectionId) => {
    try {
      await helpcenterStore?.cloneArticle(projectId, articleId, collectionId);
      toast.success('Article successfully cloned.');
      initialize();
    } catch (error) {
      toast.error('Error while cloning article.');
    }
  };

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    initialize();
  }, [collectionId, project]);

  const initialize = async () => {
    if (collectionId && project) {
      setIsLoading(true);
      await helpcenterStore?.getAndSetCollection(collectionId);

      // Load sub collections.
      await helpcenterStore?.getCollections(false, collectionId);
      setIsLoading(false);
    }
  };

  const onSortEnd = (event) => {
    runInAction(() => {
      helpcenterStore!.currentArticels = arrayMoveImmutable(
        articles!,
        event.oldIndex,
        event.newIndex,
      );

      let currentArticle = helpcenterStore!.currentArticels[event.newIndex];

      const newLexoRank = helpcenterStore!.calculateNewLexorank(
        articles!,
        event.newIndex,
        currentArticle.id,
      );

      currentArticle.lexorank = newLexoRank.toString();

      helpcenterStore!.updateArticle(currentArticle, collectionId!);
    });
  };

  const onSortEndSubCollections = (event) => {
    runInAction(() => {
      helpcenterStore!.collections = arrayMoveImmutable(
        subCollections!,
        event.oldIndex,
        event.newIndex,
      );

      let currentCollection = helpcenterStore!.collections[event.newIndex];

      const newLexoRank = helpcenterStore!.calculateNewLexorank(
        subCollections!,
        event.newIndex,
        currentCollection.id,
      );

      currentCollection.lexorank = newLexoRank.toString();
      helpcenterStore!.updateCollection(currentCollection);
    });
  };

  const DragHandle = SortableHandle(() => (
    <DragIcon className="lanes-drag-icon cursor-focus" />
  ));

  const translationCompleted = (item) => {
    try {
      const availableLanguages = helpcenterConfig?.localization?.length;

      const titleKeys = Object.keys(item.title);
      const contentKeys = Object.keys(item.content);
      const descriptionKeys = Object.keys(item.description);

      if (
        titleKeys.length !== availableLanguages ||
        contentKeys.length !== availableLanguages ||
        descriptionKeys.length !== availableLanguages
      ) {
        return false;
      }

      return true;
    } catch (error) {
      return false;
    }
  };

  const SortableItem = SortableElement((value) => {
    const isCollection = value.value.parent;
    const rowItem = value.value;

    const isTranslationCompleted = translationCompleted(rowItem);

    const title =
      (previewLanguageCode &&
        rowItem.title &&
        Object.keys(rowItem.title).length > 0 &&
        rowItem.title[previewLanguageCode] !== '' &&
        rowItem.title[previewLanguageCode]) ??
      'No title set for default language';

    var user: any = null;
    if (rowItem.author && projectUsers) {
      user = projectUsers.find((o) => o.id === rowItem.author);
    }

    const upvotes = calculateTotalArticleVotes(rowItem.upvotes);
    const score = Math.round(
      (upvotes.good / (upvotes.bad + upvotes.neutral + upvotes.good)) * 100,
    );

    return (
      <div className="article-list-item">
        <div
          className={`article-list-item-details ${
            isCollection && 'article-list-item-details--col'
          }`}
        >
          <DragHandle />
          <CellGestureDetector
            onClick={() => {
              if (isCollection) {
                navigate(
                  `/projects/${projectId}/helpcenter/collections/${rowItem.id}`,
                );
              } else {
                navigate(
                  `/projects/${projectId}/helpcenter/collections/${collectionId}/articles/${rowItem.id}`,
                );
              }
            }}
            bubbleTooltip={
              isCollection
                ? undefined
                : isTranslationCompleted
                ? 'Translation completed'
                : 'Translation not completed'
            }
            bubbleColor={
              isCollection
                ? undefined
                : isTranslationCompleted
                ? '#4fc47e'
                : '#ffb606'
            }
            className="table-items-name"
            label={title}
          />
          {!isCollection && (
            <>
              <div className="table-items-status hide-on-mobile">
                {!rowItem.isDraft ? (
                  <CellTag className="cell-tag--green" text="Live" />
                ) : (
                  <CellTag className="cell-tag--gray" text="Draft" />
                )}
              </div>
              <div className="table-items-author collection-list-item-details-author hide-on-mobile">
                {user ? (
                  <>
                    <UserAvatar
                      small
                      email={user.email}
                      imageUrl={user.profileImageUrl}
                    />
                    <div className="collection-list-item-details-author-name">{`${user.firstName}`}</div>
                  </>
                ) : (
                  <>--</>
                )}
              </div>
            </>
          )}
          {isCollection && (
            <>
              <span>
                {rowItem.articlesCount} article
                {rowItem.articlesCount !== 1 && 's'}
              </span>
              <span>
                {rowItem.subCollectionsCount} sub-collection
                {rowItem.subCollectionsCount !== 1 && 's'}
              </span>
            </>
          )}
          <div className="table-items-createdAt collection-list-item-details-date hide-on-mobile">
            {moment(rowItem.createdAt).format('MMM Do, h:mm a')}
          </div>
          {!isCollection && (
            <>
              <div className="table-items-rating article-ratings hide-on-mobile">
                <div className="article-ratings--inner">
                  <div className="article-ratings-item">
                    <span>😞</span> {upvotes.bad}
                  </div>
                  <div className="article-ratings-item">
                    <span>😐</span> {upvotes.neutral}
                  </div>
                  <div className="article-ratings-item">
                    <span>😁</span> {upvotes.good}
                  </div>
                </div>
                <div
                  className={`article-ratings-score ${
                    score > 75 && 'article-ratings-score--good'
                  } ${score < 25 && 'article-ratings-score--bad'}`}
                >
                  {isNaN(score) ? `--%` : `${score}%`}
                </div>
              </div>
              <DropDownButton
                label="Options"
                items={[
                  {
                    label: 'Clone',
                    icon: 'copy',
                    iconType: 'solid',
                    onClick: () => {
                      Swal.fire({
                        title: 'Are you sure?',
                        text: 'Are you sure you want to duplicate this article?',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, duplicate it!',
                        cancelButtonText: 'No, cancel!',
                        reverseButtons: true,
                      }).then((result) => {
                        if (result.isConfirmed) {
                          cloneArticle(rowItem.id, collectionId);
                        }
                      });
                    },
                  },
                  {
                    label: 'Move',
                    icon: 'file-export',
                    iconType: 'solid',
                    onClick: () => {
                      const article = helpcenterStore!.currentArticels.find(
                        (o) => o.id === rowItem.id,
                      );
                      modalStore!.openModal(
                        MODALTYPE.MOVE_HELPCENTER_ARTICLE,
                        article,
                      );
                    },
                  },
                  {
                    label: 'Translate',
                    icon: 'globe',
                    iconType: 'solid',
                    onClick: () => {
                      const article = helpcenterStore!.currentArticels.find(
                        (o) => o.id === rowItem.id,
                      );
                      let defaultSelectedLanguages = availableLanguages.map(
                        (lang) => {
                          const isTranslationCompleted = hasTranslation(
                            lang.code,
                            article,
                          );
                          return {
                            ...lang,
                            status: isTranslationCompleted,
                          };
                        },
                      );
                      const baseLanguage =
                        defaultSelectedLanguages.filter(
                          (lang) => lang.status,
                        )[0] || defaultSelectedLanguages[0];
                      defaultSelectedLanguages =
                        defaultSelectedLanguages.filter((lang) => !lang.status);

                      if (article) {
                        modalStore?.openModalSmart(
                          MODALTYPE.DYNAMIC_AI_TRANSLATION,
                          {
                            baseLanguage: baseLanguage,
                            targetLanguage: defaultSelectedLanguages,
                            languageList: availableLanguages,
                            showStatus: true,
                            article: article,
                            getContent: (baseLang) => {
                              const translationData = {
                                title: article?.title[baseLang],
                                description: article?.description[baseLang],
                                content: article?.content[baseLang],
                              };

                              return translationData;
                            },
                            setContent: (translatedContent, lang) => {
                              article.title[lang] = translatedContent.title;
                              article.description[lang] =
                                translatedContent.description;
                              article.content[lang] = translatedContent.content;

                              helpcenterStore?.updateArticle(
                                article,
                                collectionId,
                              );
                            },
                          },
                        );
                      }
                    },
                  },
                  {
                    label: 'Delete',
                    icon: 'trash',
                    iconType: 'solid',
                    onClick: () => {
                      Swal.fire({
                        title: 'Are you sure?',
                        text: 'Are you sure you want to delete this article?',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, delete it!',
                        cancelButtonText: 'No, cancel!',
                        reverseButtons: true,
                      }).then((result) => {
                        if (result.isConfirmed) {
                          // Delete article
                          helpcenterStore!.deleteHelpcenterArticle(rowItem.id);
                        }
                      });
                    },
                  },
                ]}
              />
            </>
          )}
        </div>
      </div>
    );
  });

  const SortableList = SortableContainer(({ itemsList }) => {
    return (
      <div>
        {itemsList.map((value, index) => (
          <SortableItem key={value.id} index={index} value={value} />
        ))}
      </div>
    );
  });

  if (isLoading) {
    return (
      <PageContainer>
        <PageHeadLine
          onActionBack={() => navigate(-1)}
          title={
            (previewLanguageCode &&
              collection?.title &&
              Object.keys(collection.title).length > 0 &&
              collection.title[previewLanguageCode] !== '' &&
              collection.title[previewLanguageCode]) ??
            '...'
          }
        />
        <PageContent isCentered hasTitle>
          <Loading />
        </PageContent>
      </PageContainer>
    );
  }

  const helpCenterTitle =
    (previewLanguageCode &&
      collection?.title &&
      Object.keys(collection?.title).length > 0 &&
      collection.title[previewLanguageCode] !== '' &&
      collection.title[previewLanguageCode]) ??
    'No title set for default language';

  const shareBaseURL = projectStore?.currentProject?.customDomainHelpCenter
    ? `https://${projectStore?.currentProject?.customDomainHelpCenter}`
    : `https://${projectStore?.currentProject?.defaultDomainHelpCenter}.gleap.help`;
  const collectionURL = `${shareBaseURL}/${previewLanguageCode}/collections/${
    collection?.docId
  }-${makeUrlFriendly(helpCenterTitle)}`;
  const hasContent = articles?.length > 0 || subCollections?.length > 0;

  const renderArticles = () => {
    return (
      <div className="card-table">
        <div className="card-table-header">
          <div className="card-table-header-title">Articles</div>
          <PrimaryButton
            label="Add article"
            icon="plus"
            iconSideRight={false}
            onClick={() => {
              navigate(
                `/projects/${projectId}/helpcenter/collections/${collectionId}/articles/new`,
              );
            }}
          />
        </div>
        {articles.length === 0 && (
          <div className="card-table-empty">No articles created yet.</div>
        )}
        <SortableList
          pressDelay={100}
          itemsList={articles}
          onSortEnd={onSortEnd}
          useDragHandle
          helperClass="sortable-helper"
        />
        <ReactTooltip
          id="bubbleTooltip"
          className="infoTooltip"
          delayHide={0}
          type="light"
          offset={{
            top: 14,
          }}
          place={'top'}
          effect="solid"
        />
      </div>
    );
  };

  const renderSubCollections = () => {
    return (
      <div className="card-table mt-30">
        <div className="card-table-header">
          <div className="card-table-header-title">Sub-collections</div>
          <PrimaryButton
            label="Add sub-collection"
            icon="plus"
            className="mr-10"
            iconSideRight={false}
            onClick={() => {
              modalStore!.openModal(MODALTYPE.CREATE_HELPCENTER_COLLECTION, {
                parent: collection?.id,
              });
            }}
          />
        </div>
        {subCollections.length === 0 && (
          <div className="card-table-empty">
            No sub-collections created yet.
          </div>
        )}
        <SortableList
          pressDelay={100}
          itemsList={subCollections}
          onSortEnd={onSortEndSubCollections}
          useDragHandle
          helperClass="sortable-helper"
        />
      </div>
    );
  };

  return (
    <PageContainer>
      <PageHeadLine onActionBack={() => navigate(-1)} title={helpCenterTitle}>
        <div className="header-content-right">
          <LinkButton
            label="Open collection"
            className="mr-10"
            iconSideRight={false}
            icon="arrow-up-right-from-square"
            onClick={() => {
              (window as any).open(collectionURL, '_blank').focus();
            }}
          />
          <LinkButton
            label="Edit collection"
            iconSideRight={false}
            icon="pen"
            className="mr-10"
            onClick={() => {
              modalStore!.openModal(MODALTYPE.CREATE_HELPCENTER_COLLECTION, {
                ...collection,
                isEditing: true,
              });
            }}
          />
        </div>
      </PageHeadLine>
      <PageContent hasTitle isCentered={!hasContent}>
        {hasContent ? (
          <>
            {renderArticles()}
            {renderSubCollections()}
            <div className="share-article-container">
              <Collapsible
                className="Collapsible-big"
                trigger={
                  <div className="collapsible-header">
                    <div className="collapsible-header-title">
                      Share this collection
                    </div>
                    <div className="collapsible-header-sub">
                      <div>
                        <i className="fa-regular fa-code"></i> Share by code
                        <br />
                        <i className="fa-regular fa-link-horizontal"></i> Share
                        by link
                      </div>
                    </div>
                  </div>
                }
                transitionTime={200}
                overflowWhenOpen="initial"
                openedClassName="Collapsible-big Collapsible--opened"
                onClose={() => {}}
              >
                <div className="collapsible-options-group">
                  <div className="collapsible-options-group-header">
                    Share the collection by code
                  </div>
                  <div>
                    Offer <b>contextual help</b> to your customers by opening
                    this collection directly within the Gleap widget.
                    <div className="mt-10 mb-10 code-container">
                      <CodeBlock.CodeBlock
                        text={`Gleap.openHelpCenterCollection("${collection?.docId}", false);`}
                        language={'js'}
                        showLineNumbers={false}
                      />
                    </div>
                    <a
                      href="https://docs.gleap.io/javascript/helpcenter"
                      target={'_blank'}
                      className="mt-10"
                      rel="noreferrer"
                    >
                      Open documentation
                    </a>
                  </div>
                </div>
                <div className="collapsible-options-group">
                  <div className="collapsible-options-group-header">
                    Share the collection by link
                  </div>
                  <div>
                    <CodeBlock.CodeBlock
                      text={collectionURL}
                      language={'js'}
                      showLineNumbers={false}
                    />
                  </div>
                </div>
              </Collapsible>
            </div>
          </>
        ) : (
          <div className="no-surveys-container">
            <img
              src={NoArticleImage}
              alt="No articles created yet"
              className="no-surveys-image"
            />
            <>
              <HeadLine
                title="No articles created yet"
                subtitle="Provide your users with the help they need with articles."
              />
              <div>
                <PrimaryButton
                  icon="plus"
                  className="mr-10"
                  iconSideRight={false}
                  onClick={async () => {
                    navigate(
                      `/projects/${projectId}/helpcenter/collections/${collectionId}/articles/new`,
                    );
                  }}
                  label={'Create article'}
                />
                <PrimaryButton
                  label="Add sub-collection"
                  icon="plus"
                  iconSideRight={false}
                  onClick={() => {
                    modalStore!.openModal(
                      MODALTYPE.CREATE_HELPCENTER_COLLECTION,
                      {
                        parent: collection?.id,
                      },
                    );
                  }}
                />
              </div>
            </>
          </div>
        )}
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'helpcenterStore',
  'projectStore',
  'modalStore',
)(observer(HelpcenterCollectionDetail));
