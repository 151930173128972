import Heading from '@tiptap/extension-heading';
import Image from '@tiptap/extension-image';
import Placeholder from '@tiptap/extension-placeholder';
import TextStyle from '@tiptap/extension-text-style';
import Youtube from '@tiptap/extension-youtube';
import { Editor, EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { PastePlugin } from 'helper/TipTapHelper';
import { MutableRefObject, useEffect, useState } from 'react';
import { Callout } from 'services/TipTapCallout';
import ImageResize from 'tiptap-extension-resize-image';
import Iframe from '../../../services/Iframe';
import { CustomLink } from '../RichTextEditor/extensions/CustomLink';
import { ReactHelpCenterArticleExtension } from '../RichTextEditor/extensions/ReactHelpcenterExtension';
import { ServerVariableNode } from '../RichTextEditor/extensions/VariableExtension';
import './EmailEditor.scss';

interface EmailEditorProps {
  editorRef?: MutableRefObject<Editor | null>;
  onEditorReady?: () => void;
  content?: any;
  inputContentChanged?: any;
  emptyStateChanged?: any;
  placeholder?: string;
  className?: string;
}

const EmailEditor = ({
  editorRef,
  onEditorReady,
  content,
  inputContentChanged,
  emptyStateChanged,
  placeholder,
  className = '',
}: EmailEditorProps) => {
  const [editorContent, setEditorContent] = useState(content as any);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (inputContentChanged) {
      inputContentChanged(editorContent);
    }
  }, [editorContent]);

  useEffect(() => {
    if (editorRef?.current && !initialized) {
      setInitialized(true);

      if (onEditorReady) {
        onEditorReady();
      }
    }
  }, [editorRef?.current, initialized]);

  const editor = useEditor(
    {
      editable: true,
      extensions: [
        StarterKit.configure({
          heading: false,
          codeBlock: {},
        }),
        Heading.configure({
          levels: [1, 2, 3],
        }),
        Placeholder.configure({
          placeholder: placeholder,
        }),
        CustomLink.configure({
          openOnClick: false,
          HTMLAttributes: {
            class: 'editor-custom-link',
          },
        }),
        Image,
        PastePlugin,
        TextStyle,
        ImageResize,
        Youtube.configure({ controls: true }),
        Iframe,
        Callout,
        ServerVariableNode,
        ReactHelpCenterArticleExtension,
      ],
      content,
      onSelectionUpdate({ editor }) {
        setEditorContent(editor?.getJSON());
      },
      onUpdate({ editor }) {
        try {
          if (emptyStateChanged) {
            emptyStateChanged(editor?.isEmpty);
          }
          setEditorContent(editor?.getJSON());
        } catch (error) {
          console.error(error);
        }
      },
    },
    [],
  );

  if (!editor) return null;

  if (editorRef) {
    editorRef.current = editor;
  }

  return (
    <div className={className}>
      <EditorContent editor={editor} />
    </div>
  );
};

export default EmailEditor;
