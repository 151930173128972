import classNames from 'classnames';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { ProjectStore } from 'stores/private/ProjectStore';

interface EmailTemplateSelectionProps {
  projectStore?: ProjectStore;
  templateId?: string;
  onTemplateSelected?: (templateId: string) => void;
  className?: any;
}

const EmailTemplateSelection = ({
  projectStore,
  templateId,
  onTemplateSelected,
  className,
}: EmailTemplateSelectionProps) => {
  const emailTemplates = projectStore?.emailTemplatesDataList.data ?? [];

  useEffect(() => {
    if (emailTemplates?.length === 0) {
      projectStore?.fetchAndSetEmailTemplates();
    }
  }, []);

  const emailTemplateClassName = classNames(className);

  return (
    <SelectDropDown
      className={emailTemplateClassName}
      labelPropertyName="title"
      valuePropertyName="_id"
      placeholder="Select template"
      items={emailTemplates}
      selectedItem={emailTemplates.find((item) => item._id === templateId)}
      onChange={(option) => {
        onTemplateSelected?.(option?._id);
      }}
    />
  );
};

export default inject('projectStore')(observer(EmailTemplateSelection));
