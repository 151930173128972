import React, {
  ForwardedRef,
  TextareaHTMLAttributes,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import './ObjectInputPreviewField.scss';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import { useClickedOutside } from 'services/Helper';
import { toast } from 'react-toastify';
import copyToClipboard from 'copy-to-clipboard';
import AddValue from 'components/AddValue/AddValue';

interface ObjectInputPreviewFieldProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> { }

const safeStringify = (value) => {
  const seen = new WeakSet(); // Keeps track of already visited objects

  return JSON.stringify(value, (key, val) => {
    if (typeof val === "object" && val !== null) {
      if (seen.has(val)) {
        // It's a circular reference, replace with a custom message or omit it
        return "[Circular]";
      }
      seen.add(val);
    }
    // If the value is a DOM element, omit it, or replace it with a custom message
    if (val instanceof HTMLElement) {
      return "[HTMLElement]";
    }
    return val; // Return the value itself for non-circular and non-DOM elements
  });
};

const ObjectInputPreviewField = forwardRef(
  (
    {
      className,
      onBlur,
      readOnly,
      defaultValue,
      ...props
    }: ObjectInputPreviewFieldProps,
    ref: ForwardedRef<HTMLTextAreaElement>,
  ): JSX.Element => {
    const [isEditing, setIsEditing] = useState(false);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const stringDefaultValue = safeStringify(defaultValue);

    const wrapperRef = useRef(null);
    useClickedOutside(wrapperRef, () => {
      setIsEditing(false);
      onBlur && onBlur(null as any);
    });

    useEffect(() => {
      if (isEditing && textareaRef?.current) {
        setTimeout(() => {
          if (isEditing && textareaRef?.current) {
            try {
              textareaRef.current.focus();
              textareaRef.current.setSelectionRange(textareaRef.current.value.length, textareaRef.current.value.length);
            } catch (e) { }
          }
        }, 0);
      }
    }, [isEditing]);

    const setIsEditingActive = () => {
      if (readOnly) {
        return;
      }

      setIsEditing(true);
    };

    if (!isEditing) {
      return (
        <div
          className="textarea-preview-container"
          onClick={(event) => {
            event.stopPropagation();
            setIsEditingActive();
          }}
        >
          {stringDefaultValue || <AddValue />}
          <Row className="hover-action-row">
            {!readOnly && (
              <div
                className="hover-action mr-5"
                onClick={(event) => {
                  event.stopPropagation();
                  setIsEditingActive();
                }}
              >
                <i className="fas fa-pencil-alt hover-action-icon"></i>
              </div>
            )}
            <div
              className="hover-action"
              onClick={(event) => {
                event.stopPropagation();
                copyToClipboard((stringDefaultValue || '')?.toString());
                toast.success('Copied to clipboard');
              }}
            >
              <i className="fas fa-clipboard hover-action-icon"></i>
            </div>
          </Row>
        </div>
      );
    }

    return (
      <div ref={wrapperRef}>
        <textarea
          className="textarea-preview"
          ref={textareaRef}
          {...props}
          defaultValue={stringDefaultValue}
        />
      </div>
    );
  },
);

export default ObjectInputPreviewField;

