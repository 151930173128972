import InfoBox from 'components/InfoBox/InfoBox';
import LinkButton from 'components/LinkButton/LinkButton';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import { Title } from 'components/Title/Title';
import { inject, observer } from 'mobx-react';
import { useState } from 'react';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './CreateQAModal.scss';

interface CreateQAModalProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

const CreateQAModal = ({
  modalStore,
  projectStore,
}: CreateQAModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [sourceLink, setSourceLink] = useState("");
  const [sourceName, setSourceName] = useState("");

  const create = async () => {
    if (!projectStore?.currentProject?.id) {
      return null;
    }

    setIsLoading(true);
    await projectStore?.createQAAnswer(projectStore.currentProject?.id, question, answer, sourceLink, sourceName);
    modalStore!.closeModal();
    setIsLoading(false);
  };

  return (
    <div className="modal-with-title">
      <div className='modal-title-container'>
        <Title label="Create new snippet" />
      </div>
      <div className='modal-body'>
        <div className="edit-qa-modal">
          <TextInput
            name="question"
            placeholder="i.e. What is Gleap?"
            type="text"
            className="mb-20"
            required
            error=""
            initalValue={question ?? ''}
            label="Question"
            onChange={(text) => {
              setQuestion(text);
            }}
          />
          <div className="input-label">
            Answer <span className="input-required">*</span>
          </div>
          <textarea
            className="default-textarea mb-10"
            placeholder="The more details the better!"
            value={answer ?? ''}
            onChange={(e) => {
              setAnswer(e.target.value);
            }}
          />
          {!showReadMore ?
            <LinkButton label="Add 'read more' link" icon="link" iconSideRight={false} onClick={() => {
              setShowReadMore(true);
            }} /> : <>
              <InfoBox className='mt-10'>
                The following link will be provided to the user, if Kai uses the context of this answer to reply.
              </InfoBox>
              <TextInput
                name="question"
                placeholder="Provide a name for the resource"
                type="text"
                className="mb-20 mt-20"
                error=""
                value={sourceName ?? ''}
                label="Read more title"
                onChange={(text) => {
                  setSourceName(text);
                }}
              />
              <TextInput
                name="question"
                placeholder="Link that provides more informations on the answer"
                type="text"
                className="mb-20"
                error=""
                value={sourceLink ?? ''}
                label="Read more URL"
                onChange={(text) => {
                  setSourceLink(text);
                }}
              />
            </>}
          <div className='mt-20'>
            <PrimaryButton isLoading={isLoading} label='Create answer' onClick={() => {
              create();
            }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default inject(
  'modalStore',
  'projectStore',
)(observer(CreateQAModal));
