import classNames from 'classnames';
import InfoBox from 'components/InfoBox/InfoBox';
import LinkButton from 'components/LinkButton/LinkButton';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { getProperty, setProperty } from 'helper/AssignObjectKeysHelper';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ProjectStore } from 'stores/private/ProjectStore';

interface PushNotificationSettingsProps {
  projectStore?: ProjectStore;
}

const PushNotificationSettings = ({
  projectStore,
}: PushNotificationSettingsProps) => {
  const currentProject = projectStore?.currentProject;

  const configFile = getProperty(currentProject, 'fcmConfig.configFile');

  const [isLoading, setIsLoading] = useState(false);
  const [saved, setSaved] = useState(true);

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const reader = new FileReader();

      reader.onload = (event) => {
        try {
          if (event?.target?.result) {
            const jsonObj = JSON.parse(event.target.result as string);
            runInAction(() => {
              setProperty(currentProject, 'fcmConfig.configFile', jsonObj);
            });

            setSaved(false);
          }
        } catch (_) {}
      };

      reader.readAsText(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.json',
  });

  const imageClassName = classNames('image-upload-component', {
    'image-upload-component--gragging': isDragActive,
  });

  const onSubmit = async () => {
    setIsLoading(true);

    await projectStore!.updateProject(currentProject!.id, {
      fcmConfig: currentProject!.fcmConfig,
    });

    setSaved(true);

    setTimeout(() => {
      projectStore!.refreshBugsForCurrentProject();
    }, 500);

    setIsLoading(false);
  };

  const renderFilePicker = () => {
    return (
      <>
      <div className='text mb-20'>Please upload your <b>serviceAccountKey.json</b> file below.</div>
      <div className="image-upload-wrapper">
        <div className={imageClassName} {...getRootProps()}>
          <div className="file-picker">
            <div className="draginfo-label">
              <span className="accepts-label">{`serviceAccountKey.json`}</span>
            </div>
            <input className="image-file-picker" {...getInputProps()} />
          </div>
        </div>
      </div>
      </>
    );
  };

  return (
    <PageContainer>
      <PageHeadLine title="Push Notifications" />
      <PageContent hasTitle isMediumBoxed>
        <div className="options-group">
          <div className="options-group-header">Service account</div>
          {(!configFile ||
            configFile.length === 0 ||
            Object.keys(configFile).length === 0) && <>{renderFilePicker()}</>}
          {configFile && Object.keys(configFile).length > 0 && (
            <>
              <p>✅ Successfully added config file</p>
              {!saved && (
                <>
                  <PrimaryButton
                    onClick={onSubmit}
                    isLoading={isLoading}
                    className="save-button mt-20"
                    label="Save config file"
                  />
                </>
              )}
              {saved && (
                <LinkButton
                  className="danger"
                  label="Remove config file"
                  onClick={() => {
                    runInAction(() => {
                      setProperty(
                        currentProject,
                        'fcmConfig.configFile',
                        undefined,
                      );
                    });
                  }}
                />
              )}
            </>
          )}
          <InfoBox className="mt-30">
            Learn how to setup push notifications for your project in{' '}
            <a href="https://docs.gleap.io/javascript/push-notifications">
              our docs
            </a>
            .
          </InfoBox>
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore')(observer(PushNotificationSettings));
