import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

// @ts-ignore
instance.defaults.headers.Accept = 'application/json';

export const injectHeader = () => {
  const token = localStorage.getItem('@bbtoken');
  injectCustomHeader(token);
};

export const injectCustomHeader = (token) => {
  if (token) {
    // @ts-ignore
    instance.defaults.headers.Authorization = `Bearer ${token}`;
  }
};

export default instance;
