import { cleanupName } from 'components/FeedbackUserFilter/FeedbackUserFilter';
import { getDescription } from 'components/InboxItem/InboxItem';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { inject, observer } from 'mobx-react';
import { Bug } from 'models/Bug';
import TimeAgo from 'react-timeago';
import { getSessionName } from 'services/GuestNameHelper';
import { ProjectStore } from 'stores/private/ProjectStore';
import './FeedbackItem.scss';
import { PropertyStore } from 'stores/private/PropertyStore';

interface FeedbackItemProps {
  onClick?: () => void;
  date?: string;
  feedbackItem: Bug;
  projectStore?: ProjectStore;
  propertyStore?: PropertyStore;
}

const FeedbackItem = ({
  feedbackItem,
  projectStore,
  onClick,
  date,
  propertyStore,
}: FeedbackItemProps) => {
  return (
    <div
      className="feedback-item card"
      onClick={() => {
        if (onClick) {
          onClick();
          return;
        }
        projectStore!.openFeedbackItem({ shareToken: feedbackItem.shareToken });
      }}
    >
      <div className="feedback-item-body">
        {feedbackItem.title && (
          <div className="feedback-item-body-title">{feedbackItem.title}</div>
        )}
        <div className="feedback-item-body-desc">
          {getDescription({ item: feedbackItem, propertyStore })}
        </div>
      </div>
      <div className="feedback-item-header">
        <div className="feedback-item-header-user">
          <UserAvatar
            email={
              feedbackItem.session?.email
                ? feedbackItem.session?.email
                : feedbackItem.session?.gleapId
            }
          />{' '}
          <span className="capitalize">
            {cleanupName(getSessionName(feedbackItem.session), 20)}
          </span>
        </div>
        <TimeAgo date={date || feedbackItem.createdAt} />
      </div>
    </div>
  );
};

export default inject('projectStore', 'propertyStore')(observer(FeedbackItem));
