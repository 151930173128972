export interface BarChartData {
  title: string;
  dataSets: [
    {
      label: string;
      data: {
        label: string;
        value: number;
      }[];
    },
  ];
}

export interface LineChartData {
  title: string;
  dataSets: [
    {
      label: string;
      data: {
        x: string;
        y: number;
      }[];
    },
  ];
}

export interface PieChartData {
  title: string;
  data: [
    {
      label: string;
      value: number;
    },
  ];
}

export interface FactItem {
  title: string;
  subTitle?: string;
  value: any;
  valueUnit: string;
  progressLabel?: string;
  progressUnit?: string;
  progressValue?: any;
}

export const BarChartStatisticTypes = {
  NEW_TICKETS_COUNT: 'NEW_TICKETS_COUNT',
  TICKET_CLOSE_COUNT: 'TICKET_CLOSE_COUNT',
  MEDIAN_FIRST_RESPONSE_TIME: 'MEDIAN_FIRST_RESPONSE_TIME',
  MEDIAN_CONVERSATION_RATING: 'MEDIAN_CONVERSATION_RATING',
  MEDIAN_TIME_TO_CLOSE: 'MEDIAN_TIME_TO_CLOSE',
  REPLIES_COUNT: 'REPLIES_COUNT',
  MEDIAN_REPLY_TIME: 'MEDIAN_REPLY_TIME',
  MEDIAN_TIME_TO_FIRST_ASSIGNMENT: 'MEDIAN_TIME_TO_FIRST_ASSIGNMENT',
  HELP_CENTER_ARTICLE_VIEW_COUNT: 'HELP_CENTER_ARTICLE_VIEW_COUNT',
  KAI_INVOLVED: 'KAI_INVOLVED',
  NEWS_VIEW_COUNT: 'NEWS_VIEW_COUNT',
  AI_QUESTIONS_ASKED_COUNT: 'AI_QUESTIONS_ASKED_COUNT',
  MESSAGE_COUNT: 'MESSAGE_COUNT',
  CUSTOMER_SUPPORT_REQUESTED: 'CUSTOMER_SUPPORT_REQUESTED',
  SLA_BREACHES_COUNT: 'SLA_BREACHES_COUNT',
  SLA_STARTED_COUNT: 'SLA_STARTED_COUNT',
  TICKET_CREATION_GROUPED_BY_DATE_AND_TAGS:
    'TICKET_CREATION_GROUPED_BY_DATE_AND_TAGS',
};

export const FactTypes = {
  MEDIAN_CONVERSATION_RATING: 'MEDIAN_CONVERSATION_RATING',
  MEDIAN_FIRST_RESPONSE_TIME: 'MEDIAN_FIRST_RESPONSE_TIME',
  MEDIAN_TIME_TO_CLOSE: 'MEDIAN_TIME_TO_CLOSE',
  NEW_TICKETS_COUNT: 'NEW_TICKETS_COUNT',
  TICKET_CLOSE_COUNT: 'TICKET_CLOSE_COUNT',
  NEWS_VIEW_COUNT: 'NEWS_VIEW_COUNT',
  TICKET_COUNT_BY_QUERY: 'TICKET_COUNT_BY_QUERY',
  TICKET_REPLIES_COUNT: 'TICKET_REPLIES_COUNT',
  TICKET_MEDIAN_REPLY_TIME: 'TICKET_MEDIAN_REPLY_TIME',
  MEDIAN_FIRST_ASSIGNMENT_TIME: 'MEDIAN_FIRST_ASSIGNMENT_TIME',
  HELP_CENTER_ARTICLE_VIEW_COUNT: 'HELP_CENTER_ARTICLE_VIEW_COUNT',
  KAI_ANSWERED_COUNT: 'KAI_ANSWERED_COUNT',
  KAI_NO_ANSWER_COUNT: 'KAI_NO_ANSWER_COUNT',
  KAI_ROUTED_TO_AGENT_RATE_COUNT: 'KAI_ROUTED_TO_AGENT_RATE_COUNT',
  TICKETS_WITH_OTHER_STATUS_THEN_OPEN_OR_CLOSED_COUNT:
    'TICKETS_WITH_OTHER_STATUS_THEN_OPEN_OR_CLOSED_COUNT',
  KAI_DEFLECTION_RATE: 'KAI_DEFLECTION_RATE',
  KAI_ROUTED_TO_AGENT_RATE: 'KAI_ROUTED_TO_AGENT_RATE',
  SNAPSHOT_AGENTS_ONLINE_COUNT: 'SNAPSHOT_AGENTS_ONLINE_COUNT',
  SNAPSHOT_OPEN_TICKETS: 'SNAPSHOT_OPEN_TICKETS',
  AI_QUESTIONS_ASKED_COUNT: 'AI_QUESTIONS_ASKED_COUNT',
  MESSAGE_COUNT: 'MESSAGE_COUNT',
  SLA_COMPLIANCE: 'SLA_COMPLIANCE',
  SLA_NOT_COMPLIANCE: 'SLA_NOT_COMPLIANCE',
  SLA_BREACHES_COUNT: 'SLA_BREACHES_COUNT',
  SLA_NO_BREACHES_COUNT: 'SLA_NO_BREACHES_COUNT',
};
