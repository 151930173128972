import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import {
  getEpics,
  getProjects,
  getTeams,
  getWorkflows,
  setShortcutAuthToken,
} from 'services/integrations/ShortcutHttpService';

export class ShortcutIntegrationStore {
  stores: any = {};
  integrationID: string = '';
  projects: any = [];
  teams: any = [];
  epics: any = [];
  workflows: any = [];

  constructor() {
    makeAutoObservable(this);
  }

  setStores(stores) {
    this.stores = stores;
  }

  setIntegrationID = (integrationID) => {
    this.integrationID = integrationID;
  };

  setAuth = async (accessToken) => {
    try {
      const response = await setShortcutAuthToken(
        this.stores.projectStore.currentProject.id,
        accessToken,
      );
      if (response.status === 201) {
        this.setIntegrationID(response.data.integrationID);
      }
    } catch (err: any) {
      toast.error('Something went wrong!');
    }
  };

  setProjects = (projects) => {
    this.projects = projects;
  };

  getProjects = async () => {
    try {
      const response = await getProjects({
        projectID: this.stores.projectStore.currentProject.id,
        integrationID: this.integrationID,
      });
      if (response.status === 200) {
        this.setProjects(response.data);
      }
    } catch (err: any) {
      toast.error('Something went wrong!');
    }
  };

  setTeams = (teams) => {
    this.teams = teams;
  };

  getTeams = async () => {
    try {
      const response = await getTeams({
        projectID: this.stores.projectStore.currentProject.id,
        integrationID: this.integrationID,
      });
      if (response.status === 200) {
        this.setTeams(response.data);
      }
    } catch (err: any) {
      toast.error('Something went wrong!');
    }
  };

  setEpics = (epics) => {
    this.epics = epics;
  };

  getEpics = async () => {
    try {
      const response = await getEpics({
        projectID: this.stores.projectStore.currentProject.id,
        integrationID: this.integrationID,
      });
      if (response.status === 200) {
        this.setEpics(response.data);
      }
    } catch (err: any) {
      toast.error('Something went wrong!');
    }
  };

  setWorkflows = (workflows) => {
    this.workflows = workflows;
  };

  getWorkflows = async () => {
    try {
      const response = await getWorkflows({
        projectID: this.stores.projectStore.currentProject.id,
        integrationID: this.integrationID,
      });
      if (response.status === 200) {
        this.setWorkflows(response.data);
      }
    } catch (err: any) {
      toast.error('Something went wrong!');
    }
  };
}
