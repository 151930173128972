import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import { languages } from 'constants/Languages';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import Switch from 'react-switch';
import { ProjectStore } from 'stores/private/ProjectStore';
import './LocalizationSettings.scss';
import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper';

interface LocalizationSettingsProps {
  projectStore?: ProjectStore;
}

const LocalizationSettings = ({ projectStore }: LocalizationSettingsProps) => {
  const currentProject = projectStore?.editingProject;
  const localizations = currentProject?.localizations;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    projectStore?.setEditingProject();
  }, [projectStore?.currentProject, projectStore?.flowConfig]);

  const saveLocalizations = async () => {
    setIsLoading(true);

    await projectStore?.updateProject(currentProject!.id, {
      localizations: localizations,
    });

    setIsLoading(false);
  };

  if (!localizations) {
    return <></>;
  }

  const localizationCodes = localizations.map(item => item.language);

  return (
    <PageContainer>
      <PageHeadLine title="Translations">
        <PrimaryButton
          label="Save"
          isLoading={isLoading}
          onClick={saveLocalizations}
        />
      </PageHeadLine>
      <PageContent hasTitle isMediumBoxed>
        <SizedContainer size={ContainerSizes.XL}>
          {localizations.map((item, index) => {
            const selectedLang = languages.find(
              (lang) => lang.code === item.language,
            );

            return (
              <ComponentWrapper title={`${selectedLang?.name ?? "No language selected"}${item.language ? ` (${item.language})` : ''}`}>
                <Column className="mb-20">
                  <Row
                    key={item.language}
                    alignItems="center"
                    className="mb-15"
                  >
                    <SelectDropDown
                      disabled={item.primary}
                      showBorder
                      items={languages.filter(item => !localizationCodes.includes(item.code))}
                      selectedItem={selectedLang}
                      labelPropertyName="name"
                      valuePropertyName="code"
                      placeholder="Select language"
                      onChange={(value) => {
                        runInAction(() => {
                          item.language = value.code;
                        });
                      }}
                    />
                    {localizations.length > 1 && !item.primary && (
                      <i
                        className="fa-regular fa-trash ml-10"
                        onClick={() => {
                          runInAction(() => {
                            localizations.splice(index, 1);
                          });
                        }}
                      />
                    )}
                  </Row>
                  <div className="switch-container">
                    <Switch
                  width={40}
                  onColor="#2142E7"
                  height={20}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      onChange={(checked) => {
                        runInAction(() => {
                          item.rtl = checked;
                        });
                      }}
                      checked={item.rtl}
                    />
                    <span>
                      Enable RTL (right-to-left) layout for this language.
                    </span>
                  </div>
                </Column>
              </ComponentWrapper>
            );
          })}
          <PrimaryButton
            label="Add language"
            onClick={() => {
              runInAction(() => {
                localizations.push({
                  language: '',
                  primary: false,
                  rtl: false,
                });
              });
            }}
          />
        </SizedContainer>
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore')(observer(LocalizationSettings));
