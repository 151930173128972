import './BugReportCard.scss';
import { DraggableProvided } from 'react-beautiful-dnd';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { ReactComponent as DuplicateIcon } from 'assets/icons/noun-duplicate-3366922-333333.svg';
import { ProjectStore } from 'stores/private/ProjectStore';
import { getDataDescription } from 'helper/FormDataHelper';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { Bug, BugPriorities } from '../../models/Bug';
import { ToolTip } from './ToolTip/ToolTip';
import PublicSkeleton from 'components/Skeletons/PublicSkeleton';
import { formatDateYearLater } from 'helper/DateHelper';
import { PropertyStore } from 'stores/private/PropertyStore';

interface BugReportCardProps {
  bug: Bug;
  provided: DraggableProvided;
  isDragging: boolean;
  style: any;
  projectStore?: ProjectStore;
  propertyStore?: PropertyStore;
}

const getStyle = (provided: DraggableProvided, style: Object | null) => {
  if (!provided) {
    return;
  }

  if (!style) {
    return provided.draggableProps.style;
  }

  return {
    ...provided.draggableProps.style,
    ...style,
  };
};

const BugReportCard = ({
  bug,
  provided,
  style,
  projectStore,
  propertyStore,
}: BugReportCardProps) => {
  const dueDate = bug.dueDate ? moment(bug.dueDate) : null;
  const isDue =
    bug.status !== 'DONE' && dueDate
      ? dueDate.isBefore(moment(), 'date')
      : false;
  const isDueToday =
    bug.status !== 'DONE' && dueDate ? dueDate.isSame(moment(), 'date') : false;

  const slaBreached = bug?.slaBreached ?? false;

  const renderDueDate = () => {
    if (!dueDate) {
      return null;
    }

    return (
      <div
        className={`due-date ${isDue && 'due-date--isdue'} ${
          isDueToday && 'due-date--isduetoday'
        }`}
      >
        ·
        <i className="fa-solid fa-clock-three" />
        {dueDate?.format('MMM Do')}
      </div>
    );
  };

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      key={`bug${bug.bugId}`}
      id={`bug${bug.bugId}`}
      onClick={() => {
        projectStore!.openFeedbackItem({ shareToken: bug.shareToken });
      }}
      style={getStyle(provided, style)}
    >
      <div
        className={`bug-report-card ${
          slaBreached ? 'bug-report-card--slabreached' : ''
        } ${bug.notificationsUnread ? 'bug-report-card--unread' : ''} ${
          isDue && 'bug-report-card--isdue'
        } ${isDueToday && 'bug-report-card--isduetoday'}`}
      >
        <div className="bug-report-card__body">
          {slaBreached && (
            <span className="sla-escalated">
              <i className="fa-solid fa-triangle-exclamation" />
            </span>
          )}
          {getDataDescription({
            data: bug,
            maxLength: 200,
            propertyStore,
            feedbackType: bug.type,
          })}
        </div>
        <div className="bug-report-card__date">
          {formatDateYearLater(bug.createdAt)}, #{bug.bugId} {renderDueDate()}
        </div>
        <div className="bug-report-card__foot">
          <div
            className="bug-report-card__header__info__dot"
            style={{
              backgroundColor: `${BugPriorities[bug.priority]?.color}20`,
              color: `${BugPriorities[bug.priority]?.color}`,
            }}
          >
            {BugPriorities[bug.priority]?.title}
          </div>
          <div className="bug-card-tags">
            {bug.tags &&
              bug.tags.map((tagItem) => (
                <div
                  key={
                    // eslint-disable-next-line no-underscore-dangle
                    projectStore!.currentProject?.feedbackTags.find(
                      (item) => item.label === tagItem,
                    )?._id
                  }
                  className="bug-report-card__header__info__dot"
                  style={{
                    backgroundColor: `${
                      projectStore!.currentProject?.feedbackTags.find(
                        (item) => item.label === tagItem,
                      )?.color ?? '#060d25'
                    }33`,
                    color: `${
                      projectStore!.currentProject?.feedbackTags.find(
                        (item) => item.label === tagItem,
                      )?.color
                    }`,
                  }}
                >
                  {tagItem}
                </div>
              ))}
          </div>
          {bug.duplicatesCount && bug.duplicatesCount > 0 ? (
            <div className="bug-report-card__header__info__count">
              <DuplicateIcon />
              {bug.duplicatesCount}
            </div>
          ) : null}
          <div className="filler" />
          {bug.processingUser && (
            <>
              <UserAvatar
                small
                email={bug.processingUser?.email}
                imageUrl={bug.processingUser?.profileImageUrl}
              />
              <ToolTip
                label={
                  bug.processingUser.firstName
                    ? bug.processingUser.firstName
                    : 'unknown'
                }
              />
            </>
          )}
          {!bug.processingUser && (
            <div className="bug-report-card__foot__nouser" />
          )}
        </div>
      </div>
    </div>
  );
};

export default inject('projectStore', 'propertyStore')(observer(BugReportCard));

interface TicketCardSkeletonProps {
  width: number | string;
  height?: number;
  provided?: DraggableProvided;
  id?: string;
  style?: any;
}

export const TicketCardSkeleton = ({
  width,
  height,
  provided,
  id,
  style,
}: TicketCardSkeletonProps) => {
  return (
    <div
      ref={provided?.innerRef}
      {...provided?.draggableProps}
      {...provided?.dragHandleProps}
      key={`bug${id}`}
      id={`bug${id}`}
      style={getStyle(provided, style)}
    >
      <div
        className="bug-report-card"
        style={{ width, height, marginBottom: '10px' }}
      >
        <div className="bug-report-card__body">
          <PublicSkeleton height="18px" width="160px" />
          <PublicSkeleton height="18px" width="140px" />
        </div>
        <div className="bug-report-card__date"></div>
        <div className="bug-report-card__foot">
          <PublicSkeleton height="18px" width="100px" />
          <div className="filler" />
          <PublicSkeleton
            width="26px"
            height="26px"
            style={{
              borderRadius: '100%',
            }}
          />
        </div>
      </div>
    </div>
  );
};
