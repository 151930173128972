import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import useNotificationPermission from 'helper/UseNotificationPermission';
import { inject, observer } from 'mobx-react';
import { useState } from 'react';
import { UsersStore } from 'stores/private/UsersStore';
import './NotificationToast.scss';

interface NotificationToastProps {
    usersStore?: UsersStore;
}

const NotificationToast = ({
    usersStore,
}: NotificationToastProps) => {
    const permission = useNotificationPermission();
    const [hidden, setHidden] = useState(false);
    var hideNotificationToast = "false";
    try {
        hideNotificationToast = localStorage.getItem('hideNotificationToast') ?? "false";
    } catch (error) { }

    if (!usersStore?.currentUser) {
        return null;
    }

    if (hidden) {
        return null;
    }

    if (hideNotificationToast === 'true') {
        return null;
    }

    if (!permission || permission === 'granted') {
        return null;
    }

    return (
        <div className='notification-toast'>
            <div className='notification-toast__content'>
                Enable notifications to receive updates on new tickets, assignments, and comments. Stay in the loop!<br />
                <PrimaryButton className="mt-15" label='Enable notifications' onClick={() => {
                    usersStore!.registerFcmToken();
                    setHidden(true);
                }} />
                <i className="fa-sharp fa-solid fa-xmark" onClick={() => {
                    localStorage.setItem('hideNotificationToast', 'true');
                    setHidden(true);
                }} />
            </div>
        </div>
    );
};

export default inject(
    'usersStore',
)(observer(NotificationToast));
