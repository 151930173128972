import { calculateContrast } from 'helper/Color';
import { inject, observer } from 'mobx-react';
import { ProjectStore } from 'stores/private/ProjectStore';
import './WidgetButtonPreview.scss';

interface WidgetButtonPreviewProps {
  projectStore?: ProjectStore;
}

const WidgetButtonPreview = ({ projectStore }: WidgetButtonPreviewProps) => {
  const flowConfig = projectStore?.flowConfig;
  if (!flowConfig) {
    return <></>;
  }

  return (
    <>
      {flowConfig?.feedbackButtonPosition &&
        flowConfig?.feedbackButtonPosition !== 'BUTTON_NONE' &&
        !flowConfig?.feedbackButtonPosition.includes('CLASSIC') && (
          <div
            className={`widget-bugbattle--feedback-new-button ${flowConfig?.feedbackButtonPosition === 'BOTTOM_LEFT'
                ? 'widget-bugbattle--feedback-new-button--left'
                : ''
              }`}
          >
            <div
              className="widget-bugbattle--feedback-new-button-button"
              style={{
                backgroundColor: flowConfig?.buttonColor ?? '#2142E7',
                color: calculateContrast(flowConfig?.buttonColor ?? '#2142E7'),
              }}
              onClick={() => { }}
            >
              {flowConfig?.buttonLogo && flowConfig?.buttonLogo.length > 0 ? (
                <img src={flowConfig?.buttonLogo} />
              ) : (
                <svg className="widget-default-icon" width="145" height="144" viewBox="0 0 145 144" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M38.9534 15H105.047C113.857 15 121 22.1426 121 30.9534L121 89.5238L121 96.015L121 125.541C121 128.759 117.393 130.66 114.739 128.84L90.1188 111.968H38.9534C30.1426 111.968 23 104.826 23 96.015V30.9534C23 22.1426 30.1426 15 38.9534 15Z" fill={calculateContrast(
                    flowConfig?.buttonColor ?? '#2142E7',
                  )}></path>
                </svg>
              )}
            </div>
          </div>
        )}
    </>
  );
};

export default inject('projectStore')(observer(WidgetButtonPreview));
