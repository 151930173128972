import ListDataTable from 'components/ListDataTable/ListDataTable';
import { CellAction, CellText } from 'components/ListTable/ListTable';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TabButton from 'components/TabButton/TabButton';
import { inject, observer } from 'mobx-react';
import { Property } from 'models/Property';
import React, { useEffect, useState } from 'react';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { PropertyStore } from 'stores/private/PropertyStore';

interface CustomPropertyPageProps {
  modalStore?: ModalStore;
  propertyStore?: PropertyStore;
  projectStore?: ProjectStore;
}

const CustomPropertyPage = ({
  modalStore,
  propertyStore,
  projectStore,
}: CustomPropertyPageProps) => {
  const projectProperties = propertyStore?.currentProjectProperties;
  let projectTypes = projectStore?.currentProject?.projectTypes ?? [];

  projectTypes = [...projectTypes, { name: 'Contact', type: 'SESSION' } as any];

  const [currentActiveFilter, setCurrentActiveFilter] = useState(
    projectTypes[0].type,
  );

  const filteredProperties = projectProperties?.filter(
    (item) => item.targetSource === currentActiveFilter,
  );

  useEffect(() => {
    propertyStore?.fetchAndSetCurrentProjectProperties();
  }, []);

  const buildFilters = () => {
    return (
      <div className="tab-buttons mb-30">
        <div className="tab-buttons--inner">
          {projectTypes.map((filter) => {
            return (
              <TabButton
                active={currentActiveFilter === filter.type}
                label={filter.name}
                onClick={() => {
                  setCurrentActiveFilter(filter.type);
                }}
              />
            );
          })}
        </div>
      </div>
    );
  };

  const prepareColumns = () => {
    const baseColumns = [
      {
        child: <div>Label</div>,
        id: 'label',
      },
      {
        child: <div>Field ID</div>,
        id: 'fieldId',
      },
      {
        child: <div>Type</div>,
        id: 'type',
      },
    ];

    return baseColumns;
  };

  const dataItemBuilder = (dataItem: Property, index: number) => {
    return {
      key: index.toString(),
      children: prepareColumns().map((column) => {
        if (column.id === 'label') {
          return {
            child: (
              <CellAction
                text={dataItem.label}
                action={() => {
                  propertyStore?.setCurrentEditingProperty(dataItem);
                  modalStore?.openModal(MODALTYPE.PROPERTY_CONFIGURATION);
                }}
              />
            ),
          };
        }

        if (column.id === 'fieldId') {
          return {
            child: <CellText text={`${(dataItem.path && dataItem.path !== "formData") ? `${dataItem.path}.` : ""}${dataItem.fieldId}`} />,
          };
        }

        if (column.id === 'type') {
          return {
            child: <CellText text={dataItem.type} />,
          };
        }

        return {
          child: <></>,
        };
      }),
    };
  };

  return (
    <PageContainer>
      <PageHeadLine title="Data attributes">
        <PrimaryButton
          label="Add attribute"
          onClick={() => {
            propertyStore?.setCurrentEditingProperty({
              visability: {
                sidebar: true,
                detail: true,
                create: true,
                card: false,
                sharedSidebar: false,
                sharedDetail: false,
              },
            } as any);
            modalStore?.openModal(MODALTYPE.PROPERTY_CONFIGURATION, {
              targetSource: currentActiveFilter,
            });
          }}
        />
      </PageHeadLine>
      <PageContent hasTitle>
        {buildFilters()}
        <ListDataTable
          columns={prepareColumns()}
          data={filteredProperties ?? []}
          dataTableItemBuilder={dataItemBuilder}
          noDataMessage="No data attributes yet."
        />
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'modalStore',
  'propertyStore',
  'projectStore',
)(observer(CustomPropertyPage));
