import { inject } from 'mobx-react';
import moment from 'moment';
import 'rc-slider/assets/index.css';
import { BugStore } from 'stores/private/BugStore';
import ActivityTimeline from '../ActivityTimeline/ActivityTimeline';
import './Replay.scss';
import WebPlayer from './WebPlayer';
import { useEffect, useState } from 'react';
import WebReplaysOld from './WebReplaysOld';

interface ReplayProps {
  bugStore?: BugStore;
}

const WebReplay = ({ bugStore }: ReplayProps) => {
  const bug = bugStore!.currentBug;
  const [currentTime, setCurrentTime] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [data, setData] = useState(null as any);

  const fetchData = async (url: string) => {
    try {
      const response = await fetch(url);
      const jsonData = await response.json();

      setData(jsonData);

      if (jsonData.startDate) {
        setStartDate(jsonData.startDate);
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (bug?.replayDataUrl) {
      fetchData(bug?.replayDataUrl);
    }
  }, [bug?.replayDataUrl]);

  if (data && data?.type !== 'rrweb') {
    return (<WebReplaysOld />);
  }

  return (
    <div className="web-replay-container">
      <div className="web-replays">
        <WebPlayer bug={bug} data={data} onTimeChanged={(updatedTime) => {
          if (!updatedTime) {
            setCurrentTime(0);
            return;
          }

          const newTime = Math.round(updatedTime / 1000);
          if (currentTime !== newTime) {
            setCurrentTime(newTime);
          }
        }} />
      </div>
      <div className="replay-console-logs">
        <div className="replays-header">Replay details</div>
        {startDate && (
          <ActivityTimeline
            showAfterDate={moment(startDate)
              .add(currentTime, 'seconds')
              .toDate()}
          />
        )}
      </div>
    </div>
  );
};

export default inject('bugStore')(WebReplay);
