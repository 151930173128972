import Loading from 'components/Loading/Loading';
import OrganisationDropdown from 'components/OrganisationDropdown/OrganisationDropdown';
import SimpleSidenavElement from 'components/Sidenav/SimpleSidenavElement/SimpleSidenavElement';
import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';
import {
  Route, Routes, useLocation, useNavigate, useParams
} from 'react-router';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './Organisation.scss';
import OrganisationDangerZone from './OrganisationDangerZone/OrganisationDangerZone';
import OrganisationPrivacy from './OrganisationPrivacy/OrganisationPrivacy';
import UpdateOrganisation from './UpdateOrganisation/UpdateOrganisation';

import { ReactComponent as PrivacyIcon } from '../../../assets/icons/np_privacy_2399381_FFFFFF.svg';
import { ReactComponent as DangerIcon } from '../../../assets/icons/np_warning_462862_FFFFFF.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/icons/settings.svg';
import { ReactComponent as TeamIcon } from '../../../assets/icons/team.svg';

import LinkButton from 'components/LinkButton/LinkButton';
import SidenavContainer from 'components/SidenavContainer/SidenavContainer';
import SubSidenav from 'components/SubSidenav/SubSidenav';
import Billing from '../Billing/Billing';
import Team from '../Team/Team';

interface OrganisationProps {
  organisationStore?: OrganisationStore;
  projectStore?: ProjectStore;
}

const OrganisationPage = ({
  organisationStore,
  projectStore,
}: OrganisationProps) => {
  const location = useLocation();
  const { organizationId } = useParams();
  const navigate = useNavigate();
  const { currentOrganisation } = organisationStore!;

  useEffect(() => {
    projectStore!.clearCurrentProject();
    if (organizationId && organizationId.length === 24) {
      organisationStore!.getOrganisation(organizationId);
    } else {
      navigate('/dashboard');
    }
  }, [organizationId]);

  if (!currentOrganisation) {
    return <Loading />;
  }

  const isActive = (path) => {
    if (location.pathname) {
      const parts = location.pathname.split('/');

      if (parts.length >= 4 && parts[3] === path) {
        return true;
      }

      if (path === '' && parts.length === 3 && parts[1] === 'organization') {
        return true;
      }
    }

    return false;
  };

  const renderSideNav = () => {
    return (
      <>
        <div className="subnav-spacer-top">
          <SimpleSidenavElement
            name="General"
            onClick={() => {
              navigate(`/organization/${currentOrganisation.id}`);
            }}
            Icon={SettingsIcon}
            isActive={isActive('')}
          />
          <SimpleSidenavElement
            name="Teammates"
            onClick={() => {
              navigate(`/organization/${currentOrganisation.id}/team`);
            }}
            Icon={TeamIcon}
            isActive={isActive('team')}
          />
          <SimpleSidenavElement
            name="Billing"
            onClick={() => {
              navigate(`/organization/${currentOrganisation.id}/billing`);
            }}
            isActive={isActive('billing')}
            faIcon='credit-card'
          />
          <SimpleSidenavElement
            name="Privacy documents"
            onClick={() => {
              navigate(`/organization/${currentOrganisation.id}/privacy`);
            }}
            Icon={PrivacyIcon}
            isActive={isActive('privacy')}
          />
          <SimpleSidenavElement
            name="Danger zone"
            onClick={() => {
              navigate(`/organization/${currentOrganisation.id}/dangerzone`);
            }}
            Icon={DangerIcon}
            isActive={isActive('dangerzone')}
          />
        </div>
        <div className="subnav-spacer-top mt-20">
          <LinkButton
            icon="plus"
            iconSideRight={false}
            label="Add organization"
            onClick={() => {
              navigate('/organization/create');
            }}
          />
        </div>
      </>
    );
  };

  return (
    <SidenavContainer>
      <SubSidenav title="Organization">
        <div className="organisation-selector-container">
          <OrganisationDropdown redirect />
        </div>
        {renderSideNav()}
      </SubSidenav>
      <Routes>
        <Route path="/" element={<UpdateOrganisation />} />
        <Route path="/privacy" element={<OrganisationPrivacy />} />
        <Route path="/dangerzone" element={<OrganisationDangerZone />} />
        <Route path="/billing" element={<Billing />} />
        <Route path="/team" element={<Team />} />
      </Routes>
    </SidenavContainer>
  );
};

export default inject(
  'organisationStore',
  'projectStore',
)(observer(OrganisationPage));
