import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import { inject, observer } from 'mobx-react';
import { BugStore } from 'stores/private/BugStore';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import { Title } from 'components/Title/Title';
import './FeatureRequestSubscribeUserModal.scss';
import InfoBox from 'components/InfoBox/InfoBox';
import { Bug } from 'models/Bug';
import { useState } from 'react';

interface FeatureRequestSubscribeUserModalProps {
  modalStore?: ModalStore;
  bugStore?: BugStore;
}

const FeatureRequestSubscribeUserModal = ({
  modalStore,
  bugStore,
}: FeatureRequestSubscribeUserModalProps) => {
  const [email, setEmail] = useState('');
  const customData = modalStore?.getCustomData(MODALTYPE.SUBSCRIBE_USER);

  const subscribeNewUser = async () => {
    const featureRequest = customData.bug as Bug;
    if (!featureRequest) {
      modalStore!.closeModal();
      return;
    }

    const existingUpvotes = (featureRequest.upvotes ?? []).filter(
      (e) => e.email === email,
    );
    if (existingUpvotes?.length === 0) {
      try {
        await bugStore!.subscribeUser(featureRequest, email);
      } catch (exp) { }
    }

    modalStore!.closeModal();
  };

  return (
    <div className="feature-request-subscribe-user-modal-container">
      <div className="vote-headline">
        <Title label="Subscribe a user to" />
        <Title label={customData.title} className="vote-headline-title" />
      </div>
      <div className='text mt-10'>
        Subscribed users will receive the updates you post within a
        feature-request.
      </div>
      <TextInput
        placeholder="Email to subscribe"
        type="text"
        error=""
        className="mt-20"
        initalValue={email}
        onChange={(value) => {
          setEmail(value);
        }}
      />
      <PrimaryButton
        disabled={email === ''}
        className="mt-20"
        label="Subscribe"
        onClick={subscribeNewUser}
      />
    </div>
  );
};

export default inject(
  'modalStore',
  'bugStore',
)(observer(FeatureRequestSubscribeUserModal));
