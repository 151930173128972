import TextInput from 'components/TextInput/TextInput';
import { Title } from 'components/Title/Title';
import { checkIntegrationData } from 'helper/Integration';
import { inject, observer } from 'mobx-react';
import { INTEGRATION } from 'models/Integration';
import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';
import { ProjectStore } from 'stores/private/ProjectStore';

interface WebhookConfigurationProps {
  integrationID: string;
  index: number;
  projectStore?: ProjectStore;
  onChange: (data: any) => any;
}

const WebhookConfiguration = ({
  integrationID,
  index,
  projectStore,
  onChange,
}: WebhookConfigurationProps) => {
  const project = projectStore?.currentProject;

  const [currentIntegrationInfo, setCurrentIntegrationInfo] = useState(
    {} as any,
  );

  const [callbackUrl, setCallbackUrl] = useState('');
  const [useAuth, setUseAuth] = useState(false);
  const [authType, setAuthType] = useState('');
  const [authToken, setAuthToken] = useState('');

  useEffect(() => {
    if (project) {
      initialize();
    }
  }, [project]);

  useEffect(() => {
    saveIntegrationInfo();
  }, [callbackUrl, useAuth, authType, authToken]);

  const initialize = async () => {
    if (
      !checkIntegrationData({
        project,
        integrationName: INTEGRATION.WEBHOOK,
        integrationID,
        actionIndex: index,
      })
    ) {
      return;
    }

    const integrationInfo =
      project?.integrations?.webhook[integrationID]?.actions[index]
        ?.integrationInfo;

    if (integrationInfo?.useAuth) {
      setUseAuth(integrationInfo?.useAuth);
    }

    setCurrentIntegrationInfo(integrationInfo);

    // Update the callback URL on init.
    if (integrationInfo.callbackUrl) {
      setCallbackUrl(integrationInfo.callbackUrl);
    }
  };

  const saveIntegrationInfo = () => {
    const integrationInfo = {
      callbackUrl,
      useAuth,
      authType,
      authToken,
    };

    onChange(integrationInfo);
  };

  return (
    <div className="configuration-container mt-30">
      <TextInput
        error=""
        className="mb-15"
        placeholder="https://example.com/webhooks"
        label="Webhook URL"
        value={callbackUrl}
        onChange={(val) => {
          setCallbackUrl(val);
        }}
      />
      <div className="switch-container mb-20 mt-30">
        <Switch
                  width={40}
                  onColor="#2142E7"
                  height={20}
          checkedIcon={false}
          uncheckedIcon={false}
          onChange={(val) => {
            setUseAuth(val);
          }}
          checked={useAuth}
        />
        <span>Authentication</span>
      </div>
      {useAuth ? (
        <>
          <TextInput
            className="mb-20"
            error=""
            placeholder="Bearer"
            label="Type"
            value={authType}
            onChange={(val) => {
              setAuthType(val);
            }}
          />
          <TextInput
            placeholder="secret_token_43134134"
            error=""
            label="Token"
            value={authToken}
            onChange={(val) => {
              setAuthToken(val);
            }}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default inject('projectStore')(observer(WebhookConfiguration));
