import { inject, observer } from 'mobx-react';
import { OrganisationStore } from 'stores/private/OrganisationStore';

interface OutreachUsageProgressProps {
  organisationStore?: OrganisationStore;
}

const OutreachUsageProgress = ({ organisationStore }: OutreachUsageProgressProps) => {
  const mailsUsage = organisationStore?.currentOrganisation?.usageData?.outreach ?? 0;
  const maxScale = Math.max(mailsUsage * 1.2, 100000);
  const mailsUsagePercentage = Math.ceil((mailsUsage / maxScale) * 100);
  let includedOutreaches = organisationStore?.currentOrganisation?.includedOutreaches ?? 0;

  var additionalEmails = mailsUsage - includedOutreaches;
  if (additionalEmails < 0) {
    additionalEmails = 0;
  }

  return (
    <>
      <div className='mau-limit-cont'>
        <div className='mau-limit-progress-bar'>
          <div className='mau-limit-progress-bar-inner' style={{
            minWidth: `${mailsUsagePercentage}%`,
          }}>
          </div>
        </div>
        <div className='mau-limit-info'>{mailsUsage?.toLocaleString()} / ∞</div>
      </div>
      {<div className='text mt-30'>
        Outreach messages are priced at $0.001 / message. You currently sent {Math.round(additionalEmails)?.toLocaleString()} outreach messages.
      </div>}
    </>
  );
};

export default inject('organisationStore')(observer(OutreachUsageProgress));
