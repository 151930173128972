import './InfoBox.scss';

interface InfoBoxProps {
  children: any;
  className?: string;
  icon?: string;
  title?: string;
  simple?: boolean;
}

const InfoBox = ({
  children,
  simple = false,
  className,
  icon = 'circle-info',
  title = '',
}: InfoBoxProps) => {
  return (
    <div className={`info-box ${simple && 'info-box--simple'} ${className}`}>
      {icon !== "none" && (
        <div className="info-box-icon">
          {!simple && <i className={`fa-solid fa-${icon}`} />}
          <span>{title}</span>
        </div>
      )}
      <div className="info-box-content">{children}</div>
    </div>
  );
};

export default InfoBox;
