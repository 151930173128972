import { useEffect, useState } from 'react';
import './UpdateProjectSharing.scss';
import { ProjectStore } from 'stores/private/ProjectStore';
import Switch from 'react-switch';
import { inject, observer } from 'mobx-react';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import PageContainer from 'components/PageContainer/PageContainer';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContent from 'components/PageContent/PageContent';
import { useNavigate } from 'react-router';
import { OrganisationStore } from 'stores/private/OrganisationStore';

interface UpdateProjectSharingProps {
  projectStore?: ProjectStore;
  organisationStore?: OrganisationStore;
}

const UpdateProjectSharing = ({
  projectStore,
  organisationStore,
}: UpdateProjectSharingProps) => {
  const project = projectStore!.currentProject;
  const [loading, setLoading] = useState(false);
  const [
    hideDetailTabsOnSharedBugs,
    setHideDetailTabsOnSharedBugs,
  ] = useState(false);

  useEffect(() => {
    if (project) {
      setHideDetailTabsOnSharedBugs(
        project!.hideDetailTabsOnSharedBugs,
      );
    }
  }, [project]);

  if (!project) {
    return null;
  }

  return (
    <PageContainer>
      <PageHeadLine title="Ticket sharing" />
      <PageContent hasTitle isMediumBoxed className="sharing-settings">
        <div className="options-group">
          <div className="options-group-header">Preferences</div>
          <div className="checkbox-settings-content mt-20">
            <div className="checkbox-settings-container">
              <div className="switch-container mb-20 mt-20">
                <Switch
                  width={40}
                  onColor="#2142E7"
                  height={20}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={(checked) => {
                    setHideDetailTabsOnSharedBugs(checked);
                  }}
                  checked={hideDetailTabsOnSharedBugs}
                />
                <span>
                  Hide technical details on shared feedback view.
                </span>
              </div>
            </div>
          </div>
          <PrimaryButton
            isLoading={loading}
            onClick={async () => {
              setLoading(true);

              try {
                await projectStore!.updateProject(project!.id, {
                  hideDetailTabsOnSharedBugs,
                });
                // eslint-disable-next-line no-empty
              } catch (exp) {}

              setLoading(false);
            }}
            className="mt-20"
            label="Save"
          />
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'projectStore',
  'organisationStore',
)(observer(UpdateProjectSharing));
