import { Editor } from '@tiptap/react';
import ActionPopover from 'components/ActionPopover/ActionPopover';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import React, { useRef, useState } from 'react';

interface VideoToolbarItemProps {
  editor: Editor | null;
  position?: 'top' | 'bottom';
}

const VideoToolbarItem = ({ editor, position = "bottom" }: VideoToolbarItemProps) => {
  const [link, setlink] = useState('');

  const popoverRef = useRef(null as any);

  const setLink = () => {
    if (!editor) return;

    // empty
    if (link === '') {
      editor!.chain().focus().extendMarkRange('link').unsetLink().run();
      return;
    }

    // update link
    editor!.commands.setYoutubeVideo({
      src: link,
    });

    popoverRef?.current.close();
  };

  return (
    <ActionPopover
      ref={popoverRef}
      position={position}
      onOpen={() => {
        const previousUrl = editor!.getAttributes('link').href;
        if (previousUrl) {
          setlink(previousUrl);
        }
      }}
      child={
        <div
          className="bubble-menu-item"
          data-for="toolbarTooltip"
          data-tip="Insert YouTube video"
        >
          <i className="fa-brands fa-youtube" />
        </div>
      }
    >
      <Column>
        <TextInput
          className="mt-10"
          placeholder="Enter a video link"
          value={link}
          onChange={(val) => {
            setlink(val);
          }}
        />
        <PrimaryButton
          className="mt-20"
          label="Save"
          disabled={link === ''}
          onClick={setLink}
        />
      </Column>
    </ActionPopover>
  );
};

export default VideoToolbarItem;
