import { stripHtml } from 'components/InboxItem/InboxItem';
import { get } from 'lodash';
import { formatCSATScore, getEmojiForVal } from './ScoreFormater';
import { convertTipTapToPlainText } from './TipTapHelper';
import { PropertyStore } from 'stores/private/PropertyStore';
import { getLanguageProperty } from './AssignObjectKeysHelper';

export const getDataDescription = (args: {
  data;
  maxLength;
  ignoreLastComment?;
  ignoreTitle?;
  propertyStore?: PropertyStore;
  feedbackType?: string;
}) => {
  const {
    data,
    maxLength = 50,
    ignoreLastComment = false,
    ignoreTitle = false,
    propertyStore,
    feedbackType,
  } = args;

  if (!data) {
    return '--';
  }

  // AI generated title.
  if (data.title && !ignoreTitle) {
    return truncate(data.title, maxLength);
  }

  let descriptionData = '';
  if (data.form) {
    const formFields = Object.values(data.form);

    let description = '';

    const textFormFields: any = formFields.filter(
      (item: any) => item.type === 'textarea' || item.type === 'text',
    );
    for (let i = 0; i < textFormFields.length; i++) {
      description += textFormFields[i].value;
      description += ' • ';
    }

    const otherFields: any = formFields.filter(
      (item: any) => item.type !== 'textarea' && item.type !== 'text',
    );
    for (let i = 0; i < otherFields.length; i++) {
      if (otherFields[i].type === 'rating') {
        description += `${getEmojiForVal(
          Math.round(otherFields[i].value * 10),
        )} Rating: ${formatCSATScore(otherFields[i].value)}`;
      }
      if (otherFields[i].type === 'multiplechoice') {
        const choosenProperty = propertyStore?.getPropertyByFieldId({
          fieldId: otherFields[i].name,
          feedbackType,
        });

        if (choosenProperty) {
          const choosenProperty = propertyStore?.getPropertyByFieldId({
            fieldId: otherFields[i].name,
            feedbackType,
          });

          // Find choosen option
          if (choosenProperty?.options) {
            for (let j = 0; j < choosenProperty?.options?.length; j++) {
              if (choosenProperty?.options[j].value === otherFields[i].value) {
                description += getLanguageProperty(
                  choosenProperty?.options[j],
                  'label',
                );
              }
            }
          }
        } else {
          // For backward compatibility

          const option = otherFields[i]?.choices?.find(
            (choice: any) => choice.id === otherFields[i].value,
          );

          if (option) {
            description += option.label;
          }
        }
      } else if (
        otherFields[i].type === 'multiple-choice-multi-select' &&
        Array.isArray(otherFields[i].value)
      ) {
        const choosenProperty = propertyStore?.getPropertyByFieldId({
          fieldId: otherFields[i].name,
          feedbackType,
        });

        if (choosenProperty) {
          // Find choosen option
          const labels = otherFields[i].value
            .map((val) => {
              const choice = choosenProperty?.options?.find(
                (choice: any) => choice && choice.value === val,
              );
              return choice ? getLanguageProperty(choice, 'label') : null;
            })
            .filter(Boolean);

          description += labels.join(', ');
        } else {
          // For backward compatibility
          const labels = otherFields[i].value
            .map((val) => {
              const choice = otherFields[i]?.choices?.find(
                (choice: any) => choice && choice.id === val,
              );
              return choice ? choice.label : null;
            })
            .filter(Boolean);

          description += labels.join(', ');
        }
      } else {
        description += otherFields[i].value;
      }

      description += ' • ';
    }

    if (description !== '') {
      descriptionData = description.slice(0, -3);
    } else {
      descriptionData = description;
    }
  } else if (data.formData && data.formData.title) {
    descriptionData = data.formData.title;
  } else if (data.description) {
    descriptionData = data.description;
  } else if (data.formData && data.formData.description) {
    descriptionData = data.formData.description;
  } else if (data.data && data.data.text) {
    descriptionData = data.data.text;
  } else if (
    !ignoreLastComment &&
    data.latestComment &&
    data.latestComment?.data?.content
  ) {
    if (typeof data.latestComment?.data?.content === 'string') {
      descriptionData = stripHtml(data.latestComment?.data?.content);
    } else {
      try {
        descriptionData = convertTipTapToPlainText({
          content: data.latestComment?.data?.content,
        }) as string;
      } catch (exp) {}
    }
  }

  if (typeof descriptionData === 'object') {
    try {
      descriptionData = convertTipTapToPlainText({
        content: descriptionData,
      }) as string;
    } catch (exp) {}
  }

  // Remove line breaks
  descriptionData = descriptionData?.replace(/(\r\n|\n|\r)/gm, ' ');

  if (descriptionData === '') {
    return '--';
  }

  return truncate(descriptionData, maxLength);
};

export const truncate = (str, n) => {
  return str && str.length > n ? `${str.substr(0, n - 1)}...` : str;
};

export const getFormFieldValueInternal = (args: {
  bug;
  key;
  fallback;
  propertyStore?: PropertyStore;
  feedbackType?: string;
}) => {
  const { bug, key, fallback, propertyStore, feedbackType } = args;

  if (key === 'title' && bug && bug[key]) {
    return bug[key];
  }

  if (key === 'plainContent' && bug && bug[key]) {
    return bug[key];
  }

  if (bug && bug.formData && bug.formData[key]) {
    return bug.formData[key];
  }

  if (bug && bug.form && bug.form[key] && bug.form[key].value) {
    return bug.form?.[key]?.value;
  }

  if (fallback) {
    const val = get(bug, fallback, undefined);
    if (val) {
      return val;
    }

    if (fallback === 'formText') {
      return getDataDescription({
        data: bug,
        maxLength: 200,
        ignoreLastComment: true,
        propertyStore,
        feedbackType,
      });
    }
  }

  if (key === 'title') {
    return 'Untitled';
  }

  return '--';
};

export const getFormFieldValue = (args: {
  bug;
  key;
  fallback?: string;
  propertyStore?: PropertyStore;
  feedbackType?: string;
}): any => {
  const { bug, key, fallback, propertyStore, feedbackType } = args;
  const value = getFormFieldValueInternal({
    bug,
    key,
    fallback,
    propertyStore,
    feedbackType,
  });

  // Check if value is a string
  if (typeof value === 'string') {
    return value;
  }

  // If value is an object, transform it to tiptap
  if (typeof value === 'object' && value !== null) {
    return convertTipTapToPlainText({ content: value });
  }

  // Return fallback
  return fallback;
};
