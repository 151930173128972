import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import { FactItem } from 'models/Chart';
import React from 'react';
import './FactChart.scss';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import Loading from 'components/Loading/Loading';

export const formatNumber = (value: number) => {
  try {
    let num;
    // Attempt to convert input to a float number
    if (typeof value === 'string') {
      num = parseFloat(value);
    } else if (typeof value === 'number') {
      num = value;
    }

    // Check if num is a number after the conversion attempt
    if (isNaN(num)) {
      return value;
    }

    // If num is a number, return it rounded to 2 decimal places
    return Math.round(Number(num));
  } catch (err) {
    return value;
  }
};

interface FactChartProps {
  fact: FactItem | undefined | null;
  isLoading?: boolean;
  infoText?: string;
  isPositiveProgressGood?: boolean;
}

const FactChart = ({
  fact,
  isLoading,
  infoText,
  isPositiveProgressGood = true,
}: FactChartProps) => {
  const _buildProgress = () => {
    if (fact?.progressValue == null || fact?.progressValue === 0) return <></>;

    const isDown = fact.progressValue < 0;
    const isUp = fact.progressValue > 0;

    const progressClass =
      (isPositiveProgressGood && isUp) || (!isPositiveProgressGood && isDown)
        ? 'fact-chart-progress--positive-good'
        : 'fact-chart-progress--positive-bad';

    return (
      <div className={`fact-chart-progress ${progressClass}`}>
        {isDown && <i className="fa-solid fa-circle-arrow-down arrow-down" />}
        {isUp && <i className="fa-solid fa-circle-arrow-up arrow-up" />}
        {formatNumber(fact?.progressValue)} {fact?.progressUnit}
      </div>
    );
  };

  return (
    <ComponentWrapper className="fact-chart" infoText={infoText}>
      <Column>
        <div className="fact-chart-title">{fact?.title}</div>
        <Row>
          {isLoading && <Loading />}
          {!isLoading && (
            <>
              <div className="fact-chart-value">
                {formatNumber(fact?.value) || 0}
                {fact?.valueUnit}
              </div>
              {_buildProgress()}
            </>
          )}
        </Row>
      </Column>
    </ComponentWrapper>
  );
};

export default FactChart;
