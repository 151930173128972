import { ReactComponent as BotIcon } from 'assets/icons/bot.svg';
import { ReactComponent as CustomActionIcon } from 'assets/icons/customaction.svg';
import { ReactComponent as FeedbackFlowIcon } from 'assets/icons/feedbackflow.svg';
import { ReactComponent as LinkIcon } from 'assets/icons/link.svg';
import BotDropdown from 'components/BotDropdown/BotDropdown';
import FeedbackFlowDropdown from 'components/FeedbackFlowDropdown/FeedbackFlowDropdown';
import IconDropdown from 'components/IconDropdown/IconDropdown';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import LinkButton from 'components/LinkButton/LinkButton';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import UserTypeDropDown from 'components/UserTypeDropDown/UserTypeDropDown';
import {
  getLanguageProperty,
  setLanguageProperty,
} from 'helper/AssignObjectKeysHelper';
import { isEqual } from 'lodash';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { audienceOptions } from 'pages/private/ProjectOutbounds/ProjectOutboundConfiguration/components/OutboundTriggerConfiguration/OutboundTriggerConfiguration';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Switch from 'react-switch';
import { ProjectStore } from 'stores/private/ProjectStore';
import './FormWidgetItem.scss';
import './FormWidgetSettings.scss';
import ConditionPicker from 'components/ConditionPicker/ConditionPicker';

export const formWidgetActionTypes = [
  {
    label: 'Start conversation',
    value: 'BOT',
    icon: BotIcon,
  },
  {
    label: 'Show feedback form',
    value: 'FEEDBACK_FLOW',
    icon: FeedbackFlowIcon,
  },
  {
    label: 'Start custom action',
    value: 'CUSTOM_ACTION',
    icon: CustomActionIcon,
  },
  {
    label: 'Open link',
    value: 'REDIRECT_URL',
    icon: LinkIcon,
  },
];

interface FormWidgetItemProps {
  projectStore?: ProjectStore;
  homeWidget: any;
  possibleActions: any;
  bots: any;
  projectId: any;
  onSave(menuItem): any;
  onDelete(): any;
}

const FormWidgetItem = ({
  projectStore,
  homeWidget,
  possibleActions,
  bots,
  projectId,
  onSave,
  onDelete,
}: FormWidgetItemProps) => {
  const navigate = useNavigate();

  const currentLang = projectStore?.currentLanguage ?? 'en';
  const { flowConfig } = projectStore?.editingProject || {};
  const [initalMenuItem, setInitalMenuItem] = useState(null as any);
  const [initialAudience, setInitialAudience] = useState(null as any);
  const [initialConditions, setInitialConditions] = useState([] as any[]);

  useEffect(() => {
    setInitalMenuItem(JSON.parse(JSON.stringify(homeWidget?.config ?? {})));
    setInitialAudience(homeWidget?.targetAudience);
    setInitialConditions(homeWidget?.conditions ?? []);
  }, []);

  if (!initalMenuItem) {
    return <></>;
  }

  const selectedType = initalMenuItem.actionType || 'BOT';
  const isFeedbackFlow =
    selectedType !== 'BOT' &&
    selectedType !== 'CUSTOM_ACTION' &&
    selectedType !== 'REDIRECT_URL';

  return (
    <>
      <div>
        <TextInput
          localizedable
          name="Title"
          placeholder=""
          type="text"
          className="mb-20"
          error=""
          value={getLanguageProperty(initalMenuItem, 'title', currentLang)}
          label="Title"
          translationObject={initalMenuItem?.title}
          onChange={(text) => {
            setLanguageProperty(initalMenuItem, 'title', currentLang, text);
            setInitalMenuItem({ ...initalMenuItem });
          }}
        />
      </div>
      {flowConfig?.v !== 2 && (<div className="mt-30">
        <TextInput
          localizedable
          name="Description"
          placeholder=""
          type="text"
          error=""
          translationObject={initalMenuItem?.description}
          value={getLanguageProperty(
            initalMenuItem,
            'description',
            currentLang,
          )}
          label="Description"
          onChange={(text) => {
            setLanguageProperty(
              initalMenuItem,
              'description',
              currentLang,
              text,
            );
            setInitalMenuItem({ ...initalMenuItem });
          }}
        />
      </div>)}
      <div className="widget-logo-upload mt-30">
        <div className="input-label mb-10">Icon</div>
        <ImageUpload
          image={initalMenuItem.icon}
          label="Upload an icon"
          editable
          formatInfo='(SVG)'
          accept='image/svg+xml'
          uploadPath="feedback_widget_icons"
          afterImageUpload={(imageURL) => {
            initalMenuItem.icon = imageURL;
            setInitalMenuItem({ ...initalMenuItem });
          }}
        />
      </div>
      <div className="input-label mb-5 mt-30">Action</div>
      <div className="form-action-type mb-10">
        <IconDropdown
          value={formWidgetActionTypes.find((element) => {
            if (isFeedbackFlow && element.value === 'FEEDBACK_FLOW') {
              return true;
            }

            return element.value === selectedType;
          })}
          options={formWidgetActionTypes}
          onValueChanged={(selectedItem) => {
            runInAction(() => {
              if (
                selectedItem.value === 'FEEDBACK_FLOW' &&
                possibleActions.length > 0
              ) {
                initalMenuItem.actionType = possibleActions[0].value;
                setInitalMenuItem({ ...initalMenuItem });
              } else {
                initalMenuItem.actionType = selectedItem.value;
                setInitalMenuItem({ ...initalMenuItem });
              }
            });
          }}
        />
      </div>
      {isFeedbackFlow && (
        <>
          <FeedbackFlowDropdown
            projectId={projectId}
            value={
              possibleActions.find((action) => {
                return action.value === initalMenuItem.actionType;
              }) ?? possibleActions[3]
            }
            options={possibleActions}
            onValueChanged={(selectedItem) => {
              runInAction(() => {
                initalMenuItem.actionType = selectedItem.value;
                setInitalMenuItem({ ...initalMenuItem });
              });
            }}
          />
          <div className="hint mt-5">
            {`Forms allow you to collect customer feedback. Manage your project's forms `}
            <a
              href="#"
              onClick={() => {
                navigate(`/projects/${projectId}/widget/flows`);
              }}
            >
              here
            </a>
            .
          </div>
        </>
      )}
      {initalMenuItem.actionType === 'BOT' && (
        <>
          <BotDropdown
            projectId={projectId}
            value={bots.find((action) => {
              return action.id === initalMenuItem.botId;
            })}
            options={bots.filter((bot) => bot.status === 'live')}
            onValueChanged={(selectedItem) => {
              runInAction(() => {
                initalMenuItem.botId = selectedItem?.id;
                setInitalMenuItem({ ...initalMenuItem });
              });
            }}
          />
        </>
      )}
      {initalMenuItem.actionType === 'REDIRECT_URL' && (
        <>
          <div className="mt-30">
            <TextInput
              name="URL"
              placeholder="https://..."
              type="text"
              className="mb-20"
              error=""
              initalValue={initalMenuItem.actionBody}
              label="URL to open"
              onChange={(text) => {
                initalMenuItem.actionBody = text;
                setInitalMenuItem({ ...initalMenuItem });
              }}
            />
          </div>
          <div className="switch-container mb-20 mt-0">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={(checked) => {
                runInAction(() => {
                  initalMenuItem.actionOpenInNewTab = checked;
                  setInitalMenuItem({ ...initalMenuItem });
                });
              }}
              checked={initalMenuItem?.actionOpenInNewTab ?? false}
            />
            <span>Open in new tab</span>
          </div>
        </>
      )}
      {(initalMenuItem.actionType === 'CUSTOM' ||
        initalMenuItem.actionType === 'CUSTOM_ACTION') && (
          <>
            <div className="mt-30">
              <TextInput
                name="Custom action name"
                placeholder="Name the custom action"
                type="text"
                error=""
                value={initalMenuItem.actionBody}
                label="Custom action name"
                onChange={(text) => {
                  initalMenuItem.actionBody = text;
                  setInitalMenuItem({ ...initalMenuItem });
                }}
              />
            </div>
            <div className="hint mb-20">
              Learn more about custom actions{' '}
              <a
                target="_blank"
                href="https://docs.gleap.io/javascript/custom-actions"
                rel="noreferrer"
              >
                here
              </a>
              .
            </div>
          </>
        )}
      <div className="input-label mb-10 mt-30">Audience rules</div>
      <div className="audience-selection">
        <div className='audience-dropdown'>
          <UserTypeDropDown
            onValueChanged={(value) => {
              runInAction(() => {
                setInitialAudience(value.value);
              });
            }}
            value={audienceOptions.find(({ value }) => value === initialAudience)}
            options={audienceOptions}
          />
        </div>
        <ConditionPicker
          type='audience'
          conditions={initialConditions}
          onChange={(conditions) => {
            runInAction(() => {
              setInitialConditions(conditions);
            });
          }}
          streamedEventKeys={projectStore?.streamedEventKeys ?? []}
        />
      </div>
      <div className="form-widget-buttons mt-30">
        <LinkButton
          icon="trash"
          className="danger"
          iconSideRight={false}
          label="Delete"
          onClick={() => {
            onDelete();
          }}
        />
        <PrimaryButton
          label="Save"
          disabled={isEqual(initalMenuItem, homeWidget.config) && isEqual(initialAudience, homeWidget.targetAudience) && isEqual(initialConditions, homeWidget.conditions)}
          onClick={() => {
            if (initalMenuItem.actionType === 'REDIRECT_URL') {
              runInAction(() => {
                initalMenuItem.actionBody =
                  initalMenuItem.actionBody.indexOf('://') === -1 &&
                    initalMenuItem.actionBody.indexOf('mailto:') === -1 &&
                    initalMenuItem.actionBody.indexOf('tel:') === -1
                    ? 'http://' + initalMenuItem.actionBody
                    : initalMenuItem.actionBody;
                setInitalMenuItem({ ...initalMenuItem });
              });
            }

            onSave({
              type: 'menu',
              conditions: initialConditions,
              targetAudience: initialAudience,
              config: initalMenuItem,
            });
          }}
        />
      </div>
    </>
  );
};

export default inject('projectStore')(observer(FormWidgetItem));
