import PublicSkeleton from 'components/Skeletons/PublicSkeleton';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { SharedBoardStorePublic } from 'stores/public/SharedBoardStorePublic';
import { SharedNewsStorePublic } from 'stores/public/SharedNewsStorePublic';
import SharedNewsDetail from '../SharedNewsDetail/SharedNewsDetail';
import { SharedPoweredBy } from '../SharedPoweredBy/SharedPoweredBy';
import { convertTipTapToPlainText } from 'helper/TipTapHelper';
import './SharedNewsList.scss';

interface SharedNewsListProps {
  sharedNewsStorePublic?: SharedNewsStorePublic;
  sharedBoardStorePublic?: SharedBoardStorePublic;
}

const SharedNewsList = ({
  sharedNewsStorePublic,
  sharedBoardStorePublic,
}: SharedNewsListProps) => {
  const navigate = useNavigate();
  const { newsId } = useParams();

  const loadNews = () => {
    if (
      sharedBoardStorePublic?.projectSharedToken &&
      sharedBoardStorePublic?.langInfo &&
      !newsId
    ) {
      sharedNewsStorePublic?.loadNews(
        sharedBoardStorePublic.projectSharedToken,
        sharedBoardStorePublic.gleapId ?? '',
        sharedBoardStorePublic.gleapHash ?? '',
        sharedBoardStorePublic?.langInfo?.language,
      );
    }
  };

  useEffect(() => {
    loadNews();
  }, [
    sharedBoardStorePublic?.projectSharedToken,
    sharedBoardStorePublic?.langInfo,
    newsId,
  ]);

  if (newsId) {
    return <SharedNewsDetail />;
  }

  const renderFakeItem = (index) => {
    return (
      <div className="news-item" key={index}>
        <div className="news-fake-image-loading">
          <PublicSkeleton
            width="100%"
            height="180px"
            style={{
              borderRadius: '12px',
            }}
          />
        </div>
        <div className="news-item--content">
          <div className="news-item--title">
            <PublicSkeleton width="220px" height="20px" />
          </div>
          <div className="news-item--subtitle">
            <PublicSkeleton width="160px" height="15px" />
            <PublicSkeleton width="190px" height="15px" />
          </div>
          <div className="news-item--meta">
            <PublicSkeleton
              width="24px"
              height="24px"
              style={{
                borderRadius: '100%',
                marginRight: '8px',
              }}
            />
            <PublicSkeleton
              width="76px"
              height="16px"
              style={{
                marginRight: '16px',
              }}
            />
            <PublicSkeleton
              width="46px"
              height="16px"
              style={{
                marginRight: '8px',
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderNewsList = () => {
    if (
      !sharedNewsStorePublic?.news ||
      (sharedNewsStorePublic?.news.length === 0 &&
        sharedNewsStorePublic?.loadingNews)
    ) {
      return [0, 1].map((_, index) => {
        return renderFakeItem(index);
      });
    }

    return sharedNewsStorePublic?.news.map((news) => {
      var content = '';
      try {
        if (news.message) {
          content = convertTipTapToPlainText({
            content: news.message,
          }) as string;

          content = content.replaceAll('\n', ' ');
        }
      } catch (exp) {}

      return (
        <div
          className="news-item"
          key={news.id}
          onClick={() => {
            navigate(
              `/sharedboard/${sharedBoardStorePublic?.projectSharedToken}/news/${news.id}`,
            );
          }}
        >
          {news.coverImageUrl && news.coverImageUrl.length > 0 && (
            <img
              className="news-item--image"
              src={news.coverImageUrl}
              alt={news.subject}
            />
          )}
          <div className="news-item--content">
            <div className="news-item--title">
              {news.config?.pinned && '📌 '}
              {news.subject}
            </div>
            <div className="news-item--subtitle">{content}</div>
            <div className="news-item--meta">
              {news.sender && (
                <>
                  <img src={news.sender.profileImageUrl} />
                  <span>
                    {news.sender.firstName} •{' '}
                    {news.sentAt
                      ? moment(news.sentAt).fromNow()
                      : moment(news.createdAt).fromNow()}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      );
    });
  };

  const handleScroll = (e) => {
    const bottom =
      Math.abs(
        e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop,
      ) < 1;
    if (bottom) {
      loadNews();
    }
  };

  return (
    <div
      className="shared-news-details-container"
      onScroll={(e) => {
        handleScroll(e);
      }}
    >
      <div className="shared-news-list">
        {renderNewsList()}
        {sharedNewsStorePublic?.loadingNews && renderFakeItem(9999)}
      </div>
      <div className="news-footer">
        {!sharedBoardStorePublic?.currentProject?.flowConfig?.hideBranding && (
          <SharedPoweredBy />
        )}
      </div>
    </div>
  );
};
export default inject(
  'sharedNewsStorePublic',
  'sharedBoardStorePublic',
)(observer(SharedNewsList));
