import FactChart from 'components/Charts/FactChart/FactChart';
import StatisticLineChart from 'components/Charts/StatisticLineChart/StatisticLineChart';
import InfoBox from 'components/InfoBox/InfoBox';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import LoadingAnimationSmall from 'components/LoadingAnimationSmall/LoadingAnimationSmall';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { OutboundStore } from 'stores/private/OutboundStore';

interface ProjectEmailStatisticsProps {
  outboundStore?: OutboundStore;
  startDate?: Date | null;
  endDate?: Date | null;
}

export const isOutboundStillSending = (outboundRule) => {
  return (
    outboundRule?.type === 'EMAIL' &&
    outboundRule.frequencyType === 'fixed' &&
    outboundRule.sentAt &&
    moment().diff(moment(outboundRule.sentAt), 'minutes') <= 5
  );
};

const ProjectEmailStatistics = ({
  outboundStore,
  startDate,
  endDate,
}: ProjectEmailStatisticsProps) => {
  const emailOverviewData = outboundStore?.emailOverviewData;
  const emailClientUsageData = outboundStore?.emailClientUsageData;
  const outboundRule = outboundStore?.outboundRule;
  const stillSending = isOutboundStillSending(outboundRule);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    initialize(startDate, endDate);
  }, [startDate, endDate]);

  const initialize = async (start, end) => {
    setIsLoading(true);

    const formatDate = (date) => date.toISOString().split('T')[0];

    // Calculate fallback dates (30 days ago to now)
    const fallbackEndDate = new Date();
    const fallbackStartDate = new Date();
    fallbackStartDate.setDate(fallbackEndDate.getDate() - 30);

    // Use provided dates or fallbacks
    const formattedStartDate = start
      ? formatDate(start)
      : formatDate(fallbackStartDate);
    const formattedEndDate = end
      ? formatDate(end)
      : formatDate(fallbackEndDate);

    await Promise.all([
      outboundStore?.fetchAndSetEmailOverviewData({
        query: {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        },
      }),
      outboundStore?.fetchAndSetEmailClientUsageData({
        query: {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        },
      }),
    ]);
    setIsLoading(false);
  };

  return (
    <Column>
      {stillSending && (
        <Row className="mb-20">
          <InfoBox>
            <Row>
              <LoadingAnimationSmall />
              <div style={{ marginLeft: 10 }}>
                Emails are being sent right now. Stats might take a moment to update.
              </div>
            </Row>
          </InfoBox>
        </Row>
      )}
      <Row>
        <FactChart
          isLoading={isLoading}
          fact={{
            title: 'Emails sent',
            value: emailOverviewData?.Sent ?? 0,
            valueUnit: '',
          }}
        />
        <FactChart
          isLoading={isLoading}
          fact={{
            title: 'Bounced',
            value: emailOverviewData?.Bounced ?? 0,
            valueUnit: '',
          }}
        />
        <FactChart
          isLoading={isLoading}
          fact={{
            title: 'Spam complaints',
            value: emailOverviewData?.SpamComplaints ?? 0,
            valueUnit: '',
          }}
        />
      </Row>
      <Row>
        <FactChart
          isLoading={isLoading}
          fact={{
            title: 'Opens',
            value: emailOverviewData?.UniqueOpens ?? 0,
            valueUnit: '',
          }}
        />
        <FactChart
          isLoading={isLoading}
          fact={{
            title: 'Total clicks',
            value: emailOverviewData?.TotalClicks ?? 0,
            valueUnit: '',
          }}
        />
      </Row>
      <StatisticLineChart
        isLoading={isLoading}
        chartData={emailClientUsageData}
      />
    </Column>
  );
};

export default inject('outboundStore')(observer(ProjectEmailStatistics));
