import { HeadLine } from 'components/HeadLine/HeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { TextHref } from 'components/TextHref/TextHref';
import TextInput from 'components/TextInput/TextInput';
import { inject } from 'mobx-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { OutboundStore } from 'stores/private/OutboundStore';
import './EmailPreviewModal.scss';

interface EmailPreviewModalProps {
  modalStore?: ModalStore;
  outboundStore?: OutboundStore;
}

const EmailPreviewModal = ({
  outboundStore,
  modalStore,
}: EmailPreviewModalProps) => {
  const MAX_INVITATIONS = 4;
  const [inputFormValues, setInputFormValues] = useState(['']);
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, formState, watch, } = useForm();
  const watchAllFields = watch();

  const modalData = modalStore?.getCustomData(MODALTYPE.EMAIL_PREVIEW);
  const outboundId = modalData?.outboundId ?? null;

  if (!outboundId) {
    return null;
  }

  const onSubmit = async (data: any) => {
    setIsLoading(true);

    const emails: any = [];
    for (const key in data) {
      if (data[key] !== '') {
        emails.push(data[key]);
      }
    }

    await outboundStore?.sendEmailPreview(outboundId, emails);

    modalStore!.closeModal();

    setIsLoading(false);
  };

  const renderInviteForm = () => {
    return (
      <div>
        <HeadLine
          title="Send email preview"
          subtitle={`Add up to ${MAX_INVITATIONS} email addresses to send an email preview to.`}
        />
        <form onSubmit={handleSubmit(onSubmit)} className="invite-people">
          {inputFormValues.map((item, key) => {
            return (
              <div className="textinput content-width" key={key}>
                <TextInput
                  inputRef={register(`input${key}`, {
                    pattern: /^\S+@\S+\.\S+$/,
                  })}
                  placeholder="name@company.com"
                  type="text"
                  error={
                    formState.errors[`input${key}`] ? 'E-Mail address is not valid' : ''
                  }
                  name={`input${key}`}
                  initalValue={item}
                  className="mb-10"
                />
              </div>
            );
          })}
          {inputFormValues.length < MAX_INVITATIONS && (
            <TextHref
              className="mb-20"
              onClick={() => {
                const arr = inputFormValues;
                arr.push('');
                setInputFormValues([...arr]);
              }}
              label="+ Add more"
            />
          )}
          <PrimaryButton
            label="Send preview"
            icon="paper-plane-top"
            isLoading={isLoading}
            iconSideRight
            onClick={() => { }}
            disabled={!(watchAllFields && watchAllFields.input0 && watchAllFields.input0.length > 0)}
            submit
          />
        </form>
      </div>
    );
  };

  return (
    <div className="invite-modal">
      {renderInviteForm()}
    </div>
  );
};

export default inject(
  'outboundStore',
  'modalStore',
)(EmailPreviewModal);

