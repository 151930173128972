
import { Title } from 'components/Title/Title';
import { inject, observer } from 'mobx-react';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import './CreateAIContent.scss';
import ContentCard from 'components/ContentCard/ContentCard';

interface CreateAIContentProps {
  modalStore?: ModalStore;
}

const CreateAIContent = ({
  modalStore,
}: CreateAIContentProps) => {
  const options = [{
    title: "Snippet",
    description: "Text content specific for Kai, not publicly available.",
    icon: "note-sticky",
    color: "#f76c6c",
    action: () => {
      modalStore!.openModal(MODALTYPE.CREATE_QA);
    }
  }, {
    title: "PDF upload",
    description: "Provide a PDF file and we'll convert it to a text snippet.",
    icon: "file-pdf",
    color: "#f5b51d",
    action: () => {
      modalStore!.openModal(MODALTYPE.UPLOAD_QA_PDF);
    }
  }, {
    title: "Website",
    description: "We will fetch the content from any public website.",
    icon: "link",
    color: '#90be6d',
    action: () => {
      modalStore!.openModal(MODALTYPE.CREATE_CRAWLER_TASK);
    }
  }, {
    title: "API sources",
    description: "Kai will fetch content from your API.",
    icon: "cloud",
    color: '#4a47a3',
    action: () => {
      modalStore!.openModal(MODALTYPE.CREATE_AI_FUNCTION_CALL);
    }
  }];

  return (
    <div className="modal-with-title">
      <div className='modal-title-container'>
        <Title label="Add new Kai content" />
      </div>
      <div className='modal-body'>
        <div className='content-options'>
          {options.map((option, index) => {
            return (<ContentCard key={index} onClick={() => {
              modalStore!.closeModal();
              option.action();
            }} item={option} />)
          })}
        </div>
      </div>
    </div>
  );
};

export default inject(
  'modalStore',
)(observer(CreateAIContent));
