import { SchemaField } from 'components/Modals/CSVImporterModal/CSVImporterModal';

export type HelpcenterCollection = {
  id: string;
  title: any;
  description: any;
  iconUrl: string;
  lexorank: string;
  docId?: string;
  parent?: string;
};

export type HelpcenterArticle = {
  id: string;
  title: any;
  description: any;
  content: any;
  helpcenterCollection: string;
  author: any;
  createdAt?: any;
  lexorank: string;
  docId?: string;
  isDraft?: boolean;
};

export const helpcenterArticleSchema: SchemaField[] = [
  {
    name: 'Title',
    key: 'title',
    required: true,
    children: [{ name: 'English Title', key: 'en', required: false }],
  },
  {
    name: 'Description',
    key: 'description',
    required: true,
    children: [{ name: 'English Description', key: 'en', required: false }],
  },
  {
    name: 'HTML Content',
    key: 'htmlContent',
    required: true,
    children: [{ name: 'English Content', key: 'en', required: false }],
  },
  {
    name: 'Creation Date',
    key: 'createdAt',
    required: false,
  },
  {
    name: 'Draft Status',
    key: 'isDraft',
    required: false,
  },
  {
    name: 'Collection ID',
    key: 'collectionID',
    required: true,
  },
  {
    name: 'Collection Title',
    key: 'collectionTitle',
    required: true,
    children: [{ name: 'English Title', key: 'en', required: false }],
  },
  {
    name: 'Collection Description',
    key: 'collectionDescription',
    required: false,
    children: [{ name: 'English Description', key: 'en', required: false }],
  },
  {
    name: 'Parent Collection',
    key: 'parentCollectionID',
    required: false,
  },
  {
    name: 'Collection Icon URL',
    key: 'collectionIconUrl',
    required: false,
  },
];
