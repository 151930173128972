import { inject, observer } from 'mobx-react';
import { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { generateAIResponse } from 'services/BugHttpService';
import { BugStore } from 'stores/private/BugStore';
import './PerformAIAction.scss';
import Swal from 'sweetalert2';
import { ProjectStore } from 'stores/private/ProjectStore';
import { Feature } from 'models/Enums';
import { useNavigate } from 'react-router';
import { OrganisationStore } from 'stores/private/OrganisationStore';

export function replaceMarkdown(inputString: string): string {
  try {
    // Replace Markdown links with HTML anchors, supporting http, https, mailto, and gleap://bot
    const linkRegex = /\[([^\]]+)\]\(((https?|mailto|gleap):\/\/[^\)]+)\)/g;
    let replacedText = inputString.replace(
      linkRegex,
      '<a href="$2" target="_blank">$1</a>',
    );

    // Replace bold text (**text** or __text__) with <strong> tags
    const boldRegex = /(\*\*|__)(.*?)\1/g;
    replacedText = replacedText.replace(boldRegex, '<strong>$2</strong>');

    // Replace italic text (*text* or _text_) with <em> tags
    const italicRegex = /(\*|_)(.*?)\1/g;
    replacedText = replacedText.replace(italicRegex, '<em>$2</em>');

    return replacedText;
  } catch (e) {
    // Return the input string unchanged in case of an error
    return inputString;
  }
}

const PerformAIAction = ({
  onTextPaste,
  bugStore,
  projectStore,
  organisationStore,
}: {
  onTextPaste: (text: string) => void;
  bugStore?: BugStore;
  projectStore?: ProjectStore;
  organisationStore?: OrganisationStore;
}) => {
  const [generating, setGenerating] = useState(false);
  const navigate = useNavigate();

  const performAction = () => {
    const planIsEntitled = projectStore?.isFeatureInPlan(Feature.INTEGRATIONS, [
      'some',
      'all',
    ]);

    if (!planIsEntitled) {
      return Swal.fire({
        title: 'Upgrade to Gleap Pro',
        text: 'Upgrade to Gleap Pro to use AI reply.',
        showCancelButton: true,
        confirmButtonText: `Upgrade now ✨`,
        denyButtonText: `Cancel`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate(
            `/organization/${organisationStore?.currentOrganisation?.id}/billing?upgrade=true`,
          );
        }
      });
    }

    if (generating) {
      return;
    }

    const bugId = bugStore?.currentBug?.id;
    if (!bugId) {
      setGenerating(false);
      return;
    }

    setGenerating(true);

    generateAIResponse(bugId)
      .then((response) => {
        if (response.status === 200 && response.data && response.data.text) {
          const text = replaceMarkdown(
            response.data.text.replace(/(?:\r\n|\r|\n)/g, '<br>'),
          );

          onTextPaste(text);
          setGenerating(false);
        } else {
          Swal.fire({
            text: 'Your current plan does not support Kai. Please contact us to subscribe to our AI features.',
            showCancelButton: false,
            confirmButtonText: `Ok`,
          });

          setGenerating(false);
        }
      })
      .catch(() => {
        setGenerating(false);
      });
  };

  return (
    <div className="ai-container">
      <ReactTooltip
        id="templatesButtonTooltip"
        className="infoTooltip infoTooltipButton"
        delayHide={0}
        type="light"
        effect="solid"
        getContent={(content) => {
          return (
            <div className="send-key-tooltip">
              <span>{content}</span>
            </div>
          );
        }}
      />
      <div
        data-for="templatesButtonTooltip"
        data-tip="Let Kai generate a response for you."
        className={`ai-container-item ${
          generating ? 'ai-container-item--loading' : ''
        }`}
        onClick={() => {
          performAction();
        }}
      >
        <div className="ai-container-item--inner">
          {generating ? (
            <>
              <i className="fa-sharp fa-spin fa-regular fa-loader" />{' '}
              Generating...
            </>
          ) : (
            'AI'
          )}
        </div>
      </div>
    </div>
  );
};

export default inject(
  'bugStore',
  'projectStore',
  'organisationStore',
)(observer(PerformAIAction));
