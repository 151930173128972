import { ReactComponent as BugIcon } from 'assets/svgs/bug.svg';
import { ReactComponent as BookIcon } from 'assets/svgs/circle-info-sharp-regular.svg';
import { ReactComponent as KaiIcon } from 'assets/svgs/gleapbot.svg';
import { ReactComponent as MapIcon } from 'assets/svgs/map.svg';
import { ReactComponent as PlaneIcon } from 'assets/svgs/plane.svg';
import OnboardingProjectOptionCard from 'components/OnboardingProjectOptionCard/OnboardingProjectOptionCard';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import WidgetPreview from 'pages/private/ProjectWidget/WidgetPreview/WidgetPreview';
import { useEffect, useState } from 'react';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { ProjectStore, menuOptions } from 'stores/private/ProjectStore';
import './ProjectSetup.scss';

interface ProjectSetupProps {
  organisationStore?: OrganisationStore;
  projectStore?: ProjectStore;
}

export const ProjectSetup = ({
  organisationStore,
  projectStore,
}: ProjectSetupProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([
    'bug',
    'support',
    'roadmap',
  ]);

  const { flowConfig } = projectStore?.editingProject || {};

  const dataToUpdate = {
    hideFeatureRequests: flowConfig?.hideFeatureRequests,
    hideKnowledgeBase: flowConfig?.hideKnowledgeBase,
    hideAllConversations: flowConfig?.hideAllConversations,
    hideNews: flowConfig?.hideNews,
    menuItems: flowConfig?.menuItems,
  };

  const options = [
    {
      icon: BugIcon,
      title: 'Bug reporting',
      description:
        'Equip your team with visual feedback, including console and network logs, environment data and event tracking.',
      key: 'bug',
    },
    {
      icon: KaiIcon,
      title: 'Customer support',
      description:
        'Upgrade your customer service with our live chat, AI bot and custom chatbots designed to reduce support volume.',
      key: 'support',
    },
    {
      icon: MapIcon,
      title: 'Product roadmap',
      description:
        'Manage incoming feature requests and let users vote on their suggestions.',
      key: 'roadmap',
    },
    {
      icon: PlaneIcon,
      title: 'Outreach',
      description:
        'Reach customers where they’re most likely to react to drive activation, retention and conversion.',
      key: 'outbound',
    },
    {
      icon: BookIcon,
      title: 'Knowledge base',
      description:
        'Make it easy for users to access information by building a help center within minutes.',
      key: 'knowledgebase',
    },
  ];

  useEffect(() => {
    if (projectStore?.currentProject) {
      projectStore?.setEditingProject();
    }
  }, [projectStore?.currentProject, projectStore?.flowConfig]);

  useEffect(() => {
    runInAction(() => {
      if (selectedOptions && projectStore && projectStore.editingProject) {
        var menuItems: any[] = [];

        if (selectedOptions.includes('bug')) {
          menuItems.push(menuOptions[0]);
        }

        if (selectedOptions.includes('roadmap')) {
          menuItems.push(menuOptions[1]);
          projectStore.editingProject.flowConfig.hideFeatureRequests = false;
        } else {
          projectStore.editingProject.flowConfig.hideFeatureRequests = true;
        }

        if (selectedOptions.includes('knowledgebase')) {
          projectStore.editingProject.flowConfig.hideKnowledgeBase = false;
        } else {
          projectStore.editingProject.flowConfig.hideKnowledgeBase = true;
        }

        if (selectedOptions.includes('support')) {
          menuItems.push(menuOptions[2]);
          projectStore.editingProject.flowConfig.hideAllConversations = false;
        } else {
          projectStore.editingProject.flowConfig.hideAllConversations = true;
        }

        if (selectedOptions.includes('outbound')) {
          projectStore.editingProject.flowConfig.hideNews = false;
        } else {
          projectStore.editingProject.flowConfig.hideNews = true;
        }

        projectStore.editingProject.flowConfig.menuItems = menuItems;
      }
    });
  }, [selectedOptions]);

  const saveEdits = async () => {
    setIsLoading(true);

    runInAction(async () => {
      if (projectStore && projectStore.editingProject) {
        projectStore.editingProject.flowConfig.hideNews = false;
        projectStore.editingProject.flowConfig.hideFeatureRequests = false;
        projectStore.editingProject.flowConfig.hideAllConversations = false;
      }

      await projectStore?.saveEditingProject(
        { flowConfig: dataToUpdate },
        false,
      );

      await projectStore?.getProjects();

      setIsLoading(false);
    });
  };

  if (!organisationStore) {
    return <></>;
  }

  return (
    <div className="onboarding-cont onboarding-setup">
      <div className="onboarding-cont-main">
        <div className="onboarding-title">
          What do you plan on using Gleap for?
        </div>
        <div className="onboarding-cards-container mt-10">
          <div className="onboarding-cards">
            {options.map((option) => (
              <OnboardingProjectOptionCard
                title={option.title}
                description={option.description}
                Icon={option.icon}
                value={selectedOptions.includes(option.key)}
                onValueChanged={(newValue) => {
                  if (newValue) {
                    setSelectedOptions([...selectedOptions, option.key]);
                  } else {
                    setSelectedOptions(
                      selectedOptions.filter((key) => key !== option.key),
                    );
                  }
                }}
              />
            ))}
          </div>
        </div>
        <div className="onboarding-cont-buttons mt-0">
          <PrimaryButton
            icon="arrow-right"
            iconSideRight={true}
            label="Next"
            disabled={selectedOptions.length === 0}
            isLoading={isLoading}
            onClick={() => {
              saveEdits();
            }}
          />
        </div>
      </div>
      <div className="onboarding-cont-side">
        {projectStore?.projects && projectStore?.projects.length > 0 && (
          <WidgetPreview projectIdExternal={projectStore?.projects[0].id} />
        )}
      </div>
    </div>
  );
};

export default inject(
  'organisationStore',
  'projectStore',
)(observer(ProjectSetup));
