import { useState } from 'react';
import { useForm } from 'react-hook-form';
import TextInput from 'components/TextInput/TextInput';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { UsersStore } from 'stores/private/UsersStore';
import { inject, observer } from 'mobx-react';
import ChangePasswordButton from 'components/ChangePasswordButton/ChangePasswordButton';
import './EditProfil.scss';
import { Md5 } from 'md5-typescript';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import { toast } from 'react-toastify';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import PageContainer from 'components/PageContainer/PageContainer';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContent from 'components/PageContent/PageContent';

interface EditProfilProps {
  usersStore?: UsersStore;
  modalStore?: ModalStore;
}

const EditProfil = ({ usersStore, modalStore }: EditProfilProps) => {
  const { register, handleSubmit, formState } = useForm();
  const [isLoading, setisLoading] = useState(false);

  const user = usersStore!.currentUser;
  if (!user) {
    return <></>;
  }

  const hasRegisteredWithService = () => {
    return (
      user.services != null && (user.services.github || user.services.google)
    );
  };

  const onSubmit = (data: any) => {
    setisLoading(true);

    const toUpdateUser: any = {
      lastName: data.lname,
      firstName: data.fname,
    };
    usersStore!
      .updateUser(user!.id, toUpdateUser)
      .then(() => {
        setisLoading(false);
      })
      .catch((err) => {
        toast.error('Profile update failed');
        setisLoading(false);
      });
  };

  const hasOwnProfileImage =
    !usersStore?.currentUser?.profileImageUrl ||
    !usersStore?.currentUser?.profileImageUrl?.includes('gravatar.com');

  return (
    <PageContainer>
      <PageHeadLine title="General" />
      <PageContent hasTitle isSmallBoxed>
        <form onSubmit={handleSubmit(onSubmit)} className="update-profile">
          <UserAvatar
            big
            editable
            email={usersStore?.currentUser?.email}
            imageUrl={usersStore?.currentUser?.profileImageUrl}
            afterImageUpdload={async (imageUrl: string) => {
              await usersStore!.updateUser(user!.id, {
                profileImageUrl: imageUrl,
              });
            }}
          />
          {hasOwnProfileImage && (
            <div className="mt-10">
              <a
                className="cursor-focus"
                onClick={async () => {
                  const hash = Md5.init(usersStore?.currentUser?.email ?? '--');
                  const gravatar = `https://www.gravatar.com/avatar/${hash}?d=retro`;

                  await usersStore!.updateUser(user!.id, {
                    profileImageUrl: gravatar,
                  });
                }}
              >
                Use Gravatar as profile picture
              </a>
            </div>
          )}
          <TextInput
            inputRef={register('fname', {
              required: true,
              maxLength: 25,
            })}
            error={
              formState.errors.fname
                ? 'Please fill in all the required fields.'
                : ''
            }
            name="fname"
            placeholder="First Name"
            type="text"
            className="mt-30"
            initalValue={user.firstName}
            label="Firstname"
          />
          <TextInput
            inputRef={register('lname', {
              required: true,
              maxLength: 25,
            })}
            error={
              formState.errors.lname
                ? 'Please fill in all the required fields.'
                : ''
            }
            placeholder="Last Name"
            type="text"
            name="lname"
            className="mt-20"
            initalValue={user.lastName}
            label="Lastname"
          />

          <TextInput
            placeholder="E-Mail"
            type="text"
            name="email"
            className="mt-20 mb-20"
            initalValue={user.email}
            label="E-Mail"
            readOnly
            error=""
          />
          {!hasRegisteredWithService() && (
            <ChangePasswordButton
              label="Password"
              changePassword={() => {
                modalStore!.openModal(MODALTYPE.CHANGE_PASSWORD);
              }}
              className="mb-30"
            />
          )}
          <PrimaryButton
            submit
            label="Save"
            isLoading={isLoading}
            onClick={() => {}}
          />
        </form>
      </PageContent>
    </PageContainer>
  );
};

export default inject('usersStore', 'modalStore')(observer(EditProfil));
