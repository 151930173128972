import React, { useEffect, useRef, useState } from 'react';
import './ProjectOutboundActivityMonitor.scss';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import TabButton from 'components/TabButton/TabButton';
import { OutboundStore } from 'stores/private/OutboundStore';
import { inject, observer } from 'mobx-react';
import LoadingAnimation from 'components/LoadingAnimation/LoadingAnimation';
import { CellSession, CellText } from 'components/ListTable/ListTable';
import moment from 'moment';
import ListDataTable from 'components/ListDataTable/ListDataTable';
import { useParams } from 'react-router';
import InfoBox from 'components/InfoBox/InfoBox';

const activityFilters = [
  {
    name: 'awaiting-trigger-date',
    label: 'Awaiting trigger date',
    query: {
      filter: {
        sent: false,
        $or: [
          { sendingFailed: { $exists: false } },
          {
            sendingFailed: false,
          },
        ],
        sendAt: { $gte: new Date().toISOString() },
      },
    },
    count: 0,
  },
  {
    name: 'trigger-not-sent',
    label: 'Waiting to be sent',
    query: {
      filter: {
        sent: false,
        $or: [
          { sendingFailed: { $exists: false } },
          {
            sendingFailed: false,
          },
        ],
        sendAt: { $lte: new Date().toISOString() },
      },
    },
    count: 0,
  },
  {
    name: 'successfully-sent',
    label: 'Successfully sent',
    query: {
      filter: {
        sent: true,
        $or: [
          { sendingFailed: { $exists: false } },
          {
            sendingFailed: false,
          },
        ],
      },
    },
    count: 0,
  },
  {
    name: 'failed-to-send',
    label: 'Failed to send',
    query: {
      sendingFailed: true,
    },
    count: 0,
  },
];

interface ProjectOutboundActivityMonitorProps {
  outboundStore?: OutboundStore;
  parentRef?: any;
}

const ProjectOutboundActivityMonitor = ({
  outboundStore,
  parentRef,
}: ProjectOutboundActivityMonitorProps) => {
  const { projectId } = useParams();

  const [currentActiveTab, setCurrentActiveTab] = useState(
    'awaiting-trigger-date',
  );
  const currentActiveTabRef = useRef(currentActiveTab);
  currentActiveTabRef.current = currentActiveTab;
  const [tabs, setTabs] = useState(activityFilters);

  const currentActivities = outboundStore?.outboundActivityDataList.data;
  const isLoading = outboundStore?.outboundActivityDataList.isLoading;

  useEffect(() => {
    getCountForeachFilter();
    setCurrentActiveTab('awaiting-trigger-date');
  }, []);

  useEffect(() => {
    const currentFilter = activityFilters.find(
      (filter) => filter.name === currentActiveTab,
    );

    if (currentFilter) {
      outboundStore?.fetchAndSetOutboundActivities({
        query: currentFilter.query,
      });
    }
  }, [currentActiveTab]);

  useEffect(() => {
    const currentRef = parentRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [parentRef]);

  const handleScroll = async (e) => {
    const offset = 200;

    const bottom =
      Math.abs(
        e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop,
      ) < offset;

    if (bottom) {
      await outboundStore?.fetchAndSetOutboundActivities({
        query: activityFilters.find(
          (filter) => filter.name === currentActiveTabRef.current,
        )?.query,
        loadMore: true,
      });
    }
  };

  const getCountForeachFilter = async () => {
    const promises = activityFilters.map(async (filter) => {
      const count = await outboundStore?.getOutboundActivitiesCount({
        query: filter.query,
      });

      return {
        ...filter,
        count,
      };
    });

    const results = await Promise.all(promises);

    setTabs(results);
  };

  const buildFilters = () => {
    return (
      <div className="tab-buttons tab-buttons--individual mb-30">
        <div className="tab-buttons--inner">
          {tabs.map((filter) => {
            return (
              <TabButton
                active={currentActiveTab === filter.name}
                label={`${filter.label} (${filter.count})`}
                onClick={() => {
                  setCurrentActiveTab(filter.name);
                }}
              />
            );
          })}
        </div>
      </div>
    );
  };

  const prepareColumns = () => {
    const baseColumns = [
      {
        child: <div className="bold">Session</div>,
        id: 'session',
      },
    ];

    if (currentActiveTab === 'awaiting-trigger-date') {
      baseColumns.push({
        child: <div className="bold">Trigger date</div>,
        id: 'tiggerDate',
      });
    }

    if (currentActiveTab === 'successfully-sent') {
      baseColumns.push({
        child: <div className="bold">Sent at</div>,
        id: 'sentAt',
      });
    }

    if (currentActiveTab === 'failed-to-send') {
      baseColumns.push({
        child: <div className="bold">Failed reason</div>,
        id: 'sendingFailedReason',
      });
    }

    if (currentActiveTab === 'trigger-not-sent') {
      baseColumns.push({
        child: <div className="bold">User last activity</div>,
        id: 'sessionLastActivity',
      });

      baseColumns.push({
        child: <div className="bold">Start sending after</div>,
        id: 'startSendingAfter',
      });

      baseColumns.push({
        child: <div className="bold">Reason</div>,
        id: 'notSentReason',
      });
    }

    if (currentActiveTab !== 'trigger-not-sent') {
      baseColumns.push({
        child: <div className="bold">Created at</div>,
        id: 'createdAt',
      });
    }

    return baseColumns;
  };

  const dataItemBuilder = (dataItem: any, index: number) => {
    return {
      key: index.toString(),
      children: prepareColumns().map((column) => {
        if (column.id === 'session') {
          return {
            child: (
              <CellSession
                session={dataItem.session}
                url={`/projects/${projectId}/sessions/${dataItem.session?.id}`}
              />
            ),
          };
        }

        if (column.id === 'tiggerDate') {
          return {
            child: <CellText text={moment(dataItem.sendAt).format('LLL')} />,
          };
        }

        if (column.id === 'sentAt') {
          return {
            child: <CellText text={moment(dataItem.sentAt).format('LLL')} />,
          };
        }

        if (column.id === 'sendingFailedReason') {
          return {
            child: <CellText text={dataItem.sendingFailedReason} />,
          };
        }

        if (column.id === 'sessionLastActivity') {
          return {
            child: (
              <CellText
                text={moment(dataItem.session?.lastActivity).format('LLL')}
              />
            ),
          };
        }

        if (column.id === 'startSendingAfter') {
          return {
            child: <CellText text={moment(dataItem.sendAt).format('LLL')} />,
          };
        }

        if (column.id === 'notSentReason') {
          const lastActivityBeforeTriggerDate = moment(
            dataItem.session?.lastActivity,
          ).isBefore(moment(dataItem.sendAt));

          return {
            child: (
              <CellText
                text={
                  lastActivityBeforeTriggerDate
                    ? 'Was not online since trigger date'
                    : 'Page rule not matched yet'
                }
              />
            ),
          };
        }

        return {
          child: <CellText text={moment(dataItem.createdAt).format('LLL')} />,
        };
      }),
    };
  };

  const buildResults = () => {
    if (isLoading && currentActivities?.length === 0) {
      return (
        <div className="responses-loading">
          <LoadingAnimation />
        </div>
      );
    }

    return (
      <ListDataTable
        columns={prepareColumns()}
        data={currentActivities ?? []}
        dataTableItemBuilder={dataItemBuilder}
        noDataMessage="No data to display"
      />
    );
  };

  return (
    <Column>
      <InfoBox className='mb-20'>
        Activity metrics are based on the last 24 hours.
      </InfoBox>
      {buildFilters()}
      {buildResults()}
    </Column>
  );
};

export default inject('outboundStore')(
  observer(ProjectOutboundActivityMonitor),
);
