import { HeadLine } from 'components/HeadLine/HeadLine';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import { checkIntegrationData } from 'helper/Integration';
import { inject, observer } from 'mobx-react';
import { INTEGRATION } from 'models/Integration';
import React, { useEffect, useState } from 'react';
import { NotionIntegrationStore } from 'stores/integrations/NotionIntegrationStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import Switch from 'react-switch';
import './NotionConfiguration.scss';

interface NotionConfigurationProps {
  integrationID: string;
  index: number;
  notionIntegrationStore?: NotionIntegrationStore;
  projectStore?: ProjectStore;
  onChange: (data: any) => any;
}

const NotionConfiguration = ({
  integrationID,
  index,
  notionIntegrationStore,
  projectStore,
  onChange,
}: NotionConfigurationProps) => {
  const project = projectStore?.currentProject;

  const [currentIntegrationInfo, setCurrentIntegrationInfo] = useState(
    {} as any,
  );

  const [databaseID, setDatabaseID] = useState('');
  const accessibleDatabases = notionIntegrationStore?.accessibleDatabases;

  const [isMounted, setIsMounted] = useState(false);
  const [databasesLoaded, setDatabasesLoaded] = useState(false);
  const [notifyOnly, setNotifyOnly] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    if (project && isMounted) {
      initialize();
    }
  }, [project, isMounted]);

  useEffect(() => {
    saveIntegrationInfo();
  }, [databaseID, notifyOnly]);

  const initialize = async () => {
    notionIntegrationStore!.setIntegrationID(integrationID);
    await notionIntegrationStore!.getAccessibleDatabases();
    setDatabasesLoaded(true);

    if (
      !checkIntegrationData({
        project,
        integrationName: INTEGRATION.NOTION,
        integrationID,
        actionIndex: index,
      })
    ) {
      return;
    }

    const integrationInfo =
      project?.integrations?.notion[integrationID]?.actions[index]
        ?.integrationInfo;

    setCurrentIntegrationInfo(integrationInfo);
    setNotifyOnly(integrationInfo.notifyOnly ?? false);
  };

  const chooseDatabase = async (databaseId) => {
    setDatabaseID(databaseId);
  };

  const saveIntegrationInfo = () => {
    const integrationInfo = {
      databaseID,
      notifyOnly,
    };

    onChange(integrationInfo);
  };

  return (
    <div className="configuration-container mt-30">
      {(!databasesLoaded || accessibleDatabases.length > 0) && (
        <SelectDropDown
          className="mb-15"
          label="Select database"
          labelPropertyName="name"
          valuePropertyName="id"
          selectedItem={accessibleDatabases.find(
            (element) => element.id === currentIntegrationInfo?.databaseID,
          )}
          isLoading={!databasesLoaded}
          items={accessibleDatabases}
          onChange={(option) => {
            chooseDatabase(option.id);
          }}
        />
      )}
      {databasesLoaded && accessibleDatabases.length === 0 && (
        <>
          <div className="no-database-info mb-5">
            No databases shared with Gleap
          </div>
          <a
            className="mb-15"
            rel="noreferrer"
            target="_blank"
            href="https://www.notion.so/help/sharing-and-permissions"
          >
            How can I share a database in Notion?
          </a>
        </>
      )}
      <div className="switch-container mt-10 mb--10">
        <Switch
                  width={40}
                  onColor="#2142E7"
                  height={20}
          checkedIcon={false}
          uncheckedIcon={false}
          onChange={(checked) => {
            setNotifyOnly(checked);
          }}
          checked={notifyOnly ? true : false}
        />
        <span>Send fewer details only (Email, bug type & form data).</span>
      </div>
    </div>
  );
};

export default inject(
  'notionIntegrationStore',
  'projectStore',
)(observer(NotionConfiguration));
