import axios from '../Axios';

const getAuthCodeFromSalesforce = (salesforceURL, projectID) => {
  const baseURL = encodeURIComponent(process.env.REACT_APP_BASEURL ?? '');
  const redirectURI = `${baseURL}/integrations/salesforce`;
  const state = encodeURIComponent(projectID);
  const url = `${salesforceURL}/services/oauth2/authorize?client_id=${process.env.REACT_APP_SALESFORCE_INTEGRATION_CLIENT_ID}&redirect_uri=${redirectURI}&state=${state}&response_type=code`;

  return window.open(url, '_self');
};

const setSalesforceAuthToken = (code, projectID) => {
  return axios.post(`/projects/${projectID}/integrations/salesforce`, {
    code,
  });
};

const disconnectSalesforceIntegration = ({ projectID, integrationID }) => {
  return axios.delete(
    `/projects/${projectID}/integrations/salesforce/${integrationID}`,
  );
};

const setSalesforceActions = ({
  projectID,
  integrationID,
  actions,
  settings,
}) => {
  return axios.put(
    `/projects/${projectID}/integrations/salesforce/${integrationID}`,
    {
      actions,
      settings,
    },
  );
};

export {
  getAuthCodeFromSalesforce,
  setSalesforceAuthToken,
  disconnectSalesforceIntegration,
  setSalesforceActions,
};
