import React from 'react';
import './SidenavElement.scss';

interface SimpleSidenavElementProps {
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  IconComponent?: any;
  faIcon?: string;
  name: string;
  onClick: () => void;
  isActive?: boolean;
  visible?: boolean;
  notificationCount?: number;
  tag?: string;
  className?: string;
  iconEnd?: boolean;
  iconClick?: () => void;
}
const SimpleSidenavElement = ({
  Icon,
  IconComponent,
  faIcon,
  name,
  onClick,
  isActive,
  visible = true,
  notificationCount,
  tag,
  className,
  iconEnd = false,
  iconClick,
}: SimpleSidenavElementProps) => {
  if (!visible) {
    return <></>;
  }

  let notificationBadge;
  if (notificationCount && notificationCount > 0) {
    notificationBadge = (
      <div className="notifications-badge">{notificationCount}</div>
    );
  }

  return (
    <div
      className={`simple-sidenav-element ${className} ${
        isActive ? 'active' : ''
      } ${iconEnd ? 'simple-sidenav-element-icon-end' : ''}`}
      onClick={() => {
        onClick();
      }}
    >
      {Icon && !iconEnd && <Icon />}
      {IconComponent && !iconEnd && IconComponent}
      {faIcon && !iconEnd && <i className={`fa-solid fa-${faIcon}`} />}
      <span className="name">{name}</span>
      {Icon && iconEnd && <Icon />}
      {IconComponent && iconEnd && IconComponent}
      {faIcon && iconEnd && (
        <i onClick={iconClick} className={`fa-solid fa-${faIcon}`} />
      )}
      {notificationBadge}
      {tag && (
        <div className="simple-sidenav-element-tag-outer">
          <div className="simple-sidenav-element-tag">{tag}</div>
        </div>
      )}
      <span className="simple-sidenav-tooltip">{name}</span>
    </div>
  );
};

export default SimpleSidenavElement;
