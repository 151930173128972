import React from 'react';
import classNames from 'classnames';
import './grid.component.scss';

interface GridProps {
  children: React.ReactNode;
  templateColumns?: string;
  templateRows?: string;
  gap?: number;
  columnGap?: number;
  rowGap?: number;
  justifyContent?: string;
  alignItems?: string;
  className?: string;
  onClick?: () => void;
}

const Grid = ({
  children,
  templateColumns,
  templateRows,
  gap,
  columnGap,
  rowGap,
  justifyContent,
  alignItems,
  className,
  onClick,
}: GridProps): JSX.Element => {
  const gridContainerClassName = classNames('grid-container', className);

  const style = {
    gridTemplateColumns: templateColumns,
    gridTemplateRows: templateRows,
    gap: gap,
    gridColumnGap: columnGap || gap,
    gridRowGap: rowGap || gap,
    justifyContent: justifyContent,
    alignItems: alignItems,
  };

  return (
    <div className={gridContainerClassName} style={style} onClick={onClick}>
      {children}
    </div>
  );
};

export default Grid;
