import { SchemaField } from 'components/Modals/CSVImporterModal/CSVImporterModal';

export type Session = {
  id: string;
  gleapId: string;
  gleapHash: string;
  userId: string;
  userHash: string;
  lastActivity: string;
  lastFeedbackReport: string;
  location: any;
  project: any;
  unsubscribed?: boolean;
  unsubscribedAt?: Date;
  unsubscribeReason?: string;
  createdAt?: Date;
  organisation: any;
  type: string;
  name: string;
  phone: string;
  email: string;
  lang: string;
  blocked?: boolean;
  value: number;
  plan: string;
  companyId: string;
  companyName: string;
  sessions: number;
  customData: any;
  lastEvent?: {
    name: string;
    date: Date;
    data: any;
  };
  lastPageView?: {
    page: string;
    date: Date;
  };
};

export const sessionSchemaFields: SchemaField[] = [
  { name: 'Last Activity', key: 'lastActivity', required: false },
  { name: 'Last Feedback Report', key: 'lastFeedbackReport', required: false },
  {
    name: 'Location',
    key: 'location',
    required: false,
    children: [
      {
        name: 'Location Country',
        key: 'country',
        required: false,
      },
      {
        name: 'Location Region',
        key: 'region',
        required: false,
      },
      {
        name: 'Location City',
        key: 'city',
        required: false,
      },
      {
        name: 'Location Timezone',
        key: 'timezone',
        required: false,
      },
    ],
  },
  { name: 'Unsubscribed', key: 'unsubscribed', required: false },
  { name: 'Unsubscribed At', key: 'unsubscribedAt', required: false },
  { name: 'Created At', key: 'createdAt', required: false },
  { name: 'Sessions Count', key: 'sessions', required: false },
  {
    name: 'Last Event',
    key: 'lastEvent',
    required: false,
    children: [
      { name: 'Last Event Name', key: 'name', required: false },
      { name: 'Last Event Date', key: 'date', required: false },
      { name: 'Last Event Data', key: 'data', required: false },
    ],
  },
  {
    name: 'Last Page View',
    key: 'lastPageView',
    required: false,
    children: [
      { name: 'Last Page View Page', key: 'page', required: false },
      { name: 'Last Page View Date', key: 'date', required: false },
    ],
  },
];

export const unsubscribeSchemaFields: SchemaField[] = [
  { name: 'User Id', key: 'userId', required: false },
  { name: 'Email', key: 'email', required: false },
];
