import { Node } from '@tiptap/core';

export const ServerVariableNode = Node.create({
  name: 'serverVariable',

  group: 'inline',

  inline: true,

  addAttributes() {
    return {
      variableName: {
        default: this.options.variableName,
      },
      variableLabel: {
        default: this.options.variableName,
      },
      fallbackValue: {
        default: this.options.fallbackValue,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span.server-variable',
        getAttrs: (domNode) => {
          if (domNode instanceof HTMLElement) {
            const variableName = domNode.getAttribute('data-variable');
            const fallbackValue = domNode.getAttribute('data-fallback-value');
            const variableLabel = domNode.textContent;
            return {
              variableName,
              fallbackValue,
              variableLabel,
            };
          }
          return null;
        },
      },
    ];
  },

  renderHTML({ node }) {
    const variableName = node.attrs.variableName;
    const variableLabel = node.attrs.variableLabel;
    const fallbackValue = node.attrs.fallbackValue;
    return [
      'span',
      {
        class: 'server-variable',
        'data-variable': variableName,
        'data-fallback-value': fallbackValue,
      },
      variableLabel ?? variableName,
    ];
  },

  renderText({ node }) {
    const variableName = node.attrs.variableName;
    const variableLabel = node.attrs.variableLabel;
    const fallbackValue = node.attrs.fallbackValue;

    return fallbackValue ?? variableLabel ?? variableName;
  },
});
