import AggregationPicker from 'components/AggregationPicker/AggregationPicker';
import FutureFactChart from 'components/Charts/FactChart/FutureFactChart';
import FutureBarChart from 'components/Charts/StatisicBarChart/FutureStatisticBarChart';
import Wrap from 'components/LayoutComponents/WrapComponent/WrapComponent';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import SmartDateRangePicker from 'components/SmartDateRangePicker/SmartDateRangePicker';
import { inject, observer } from 'mobx-react';
import { BarChartStatisticTypes, FactTypes } from 'models/Chart';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import {
  getBarChartStatisic,
  getFactStatistic,
} from 'services/StatisticsService';
import { ProjectStore } from 'stores/private/ProjectStore';
import './CustomerSupport.scss';

interface CustomerSupportMessagesProps {
  projectStore?: ProjectStore;
}

const CustomerSupportMessages = ({
  projectStore,
}: CustomerSupportMessagesProps) => {
  const projectId = projectStore?.currentProject?.id;

  const [filter, setFilter] = useState('MEDIAN');

  const [startDate, setStartDate] = useState(
    moment().subtract(1, 'months').startOf('day') as Moment | null,
  );
  const [endDate, setEndDate] = useState(
    moment().endOf('day') as Moment | null,
  );

  return (
    <PageContainer>
      <PageHeadLine title="Messages" tag="NEW">
        <div className="filter-bar hide-on-mobile">
          <AggregationPicker
            className="border-margin-right"
            setFilter={setFilter}
            filter={filter}
          />
          <SmartDateRangePicker
            startIndex={1}
            onDateChange={(start, end) => {
              setStartDate(start);
              setEndDate(end);
            }}
          />
        </div>
      </PageHeadLine>
      <PageContent hasTitle>
        <Wrap>
          <FutureFactChart
            infoText="The count of questions (to the LLM) asked by users."
            width="50%"
            futureWithAbortion={async (controller) => {
              const res = await getFactStatistic({
                projectId: projectId,
                query: {
                  chartType: FactTypes.AI_QUESTIONS_ASKED_COUNT,
                  startDate: startDate?.toDate(),
                  endDate: endDate?.toDate(),
                },
                signal: controller.signal,
              });
              return res;
            }}
          />
          <FutureFactChart
            infoText="The count of customer messages written outside workflows / bots."
            width="50%"
            title="Messages from customers (in tickets / conversations)"
            futureWithAbortion={async (controller) => {
              const res = await getFactStatistic({
                projectId: projectId,
                query: {
                  chartType: FactTypes.MESSAGE_COUNT,
                  startDate: startDate?.toDate(),
                  endDate: endDate?.toDate(),
                  'data.type_in': ['SHARED_COMMENT', 'USER_TEXT'],
                },
                signal: controller.signal,
              });
              return res;
            }}
          />
          <FutureFactChart
            infoText="The count of customer messages written in workflows / bots."
            width="50%"
            title="Messages from customers (in workflows)"
            futureWithAbortion={async (controller) => {
              const res = await getFactStatistic({
                projectId: projectId,
                query: {
                  chartType: FactTypes.MESSAGE_COUNT,
                  startDate: startDate?.toDate(),
                  endDate: endDate?.toDate(),
                  'data.type_in': ['BOT_REPLY'],
                },
                signal: controller.signal,
              });
              return res;
            }}
          />
          <FutureFactChart
            infoText="The count of bot messages written in workflows / bots."
            width="50%"
            title="Messages from bots (in workflows)"
            futureWithAbortion={async (controller) => {
              const res = await getFactStatistic({
                projectId: projectId,
                query: {
                  chartType: FactTypes.MESSAGE_COUNT,
                  startDate: startDate?.toDate(),
                  endDate: endDate?.toDate(),
                  'data.type_in': ['BOT'],
                },
                signal: controller.signal,
              });
              return res;
            }}
          />
        </Wrap>
        <FutureBarChart
          infoText="The count of questions (to the LLM) asked by users."
          futureWithAbortion={async (controller) => {
            const res = await getBarChartStatisic({
              projectId: projectId,
              query: {
                chartType: BarChartStatisticTypes.AI_QUESTIONS_ASKED_COUNT,
                startDate: startDate?.toDate(),
                endDate: endDate?.toDate(),
                timezone: moment.tz.guess(),
              },
              signal: controller.signal,
            });
            return res;
          }}
        />
        <FutureBarChart
          infoText="The count of customer messages written outside workflows / bots."
          title="Messages from customers (in tickets / conversations)"
          futureWithAbortion={async (controller) => {
            const res = await getBarChartStatisic({
              projectId: projectId,
              query: {
                chartType: BarChartStatisticTypes.MESSAGE_COUNT,
                startDate: startDate?.toDate(),
                endDate: endDate?.toDate(),
                timezone: moment.tz.guess(),
                'data.type_in': ['SHARED_COMMENT', 'USER_TEXT'],
              },
              signal: controller.signal,
            });
            return res;
          }}
        />
        <FutureBarChart
          infoText="The count of messages written by agents to customers."
          title="Messages from agent to customer"
          futureWithAbortion={async (controller) => {
            const res = await getBarChartStatisic({
              projectId: projectId,
              query: {
                chartType: BarChartStatisticTypes.REPLIES_COUNT,
                startDate: startDate?.toDate(),
                endDate: endDate?.toDate(),
                timezone: moment.tz.guess(),
              },
              signal: controller.signal,
            });
            return res;
          }}
        />
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore')(observer(CustomerSupportMessages));
