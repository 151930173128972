import Gleap from 'gleap';
import { inject, observer } from 'mobx-react';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import './EmailUsageProgress.scss';

interface EmailUsageProgressProps {
  organisationStore?: OrganisationStore;
}

export function formatNumber(num) {
  if (typeof num === 'string') {
    num = parseFloat(num);
  }

  return num.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

const EmailUsageProgress = ({ organisationStore }: EmailUsageProgressProps) => {
  const mailsUsage = organisationStore?.currentOrganisation?.usageData?.emails ?? 0;
  const emailSendingLimit = organisationStore?.currentOrganisation?.emailSendingLimit ?? 0;
  const maxScale = Math.max(mailsUsage, emailSendingLimit) * 1.2;
  const mailsUsagePercentage = Math.ceil((mailsUsage / maxScale) * 100);
  let includedMails = organisationStore?.currentOrganisation?.includedEmails ?? 0;

  if (includedMails > emailSendingLimit) {
    includedMails = emailSendingLimit;
  }

  const mailsUsageIncludedPercentage = Math.min(100, Math.ceil((emailSendingLimit / maxScale) * 100));
  var additionalEmails = mailsUsage - includedMails;
  if (additionalEmails < 0) {
    additionalEmails = 0;
  }

  return (
    <>
      <div className='mau-limit-cont'>
        <div className='mau-limit-progress-bar'>
          <div className='mau-limit-progress-bar-inner' style={{
            minWidth: `${mailsUsagePercentage}%`,
          }}>
          </div>
          <div className='mau-limit-included' style={{
            left: `${mailsUsageIncludedPercentage}%`,
          }}>
            <div className='mau-limit-included-label'>{emailSendingLimit?.toLocaleString()} usage limit</div>
          </div>
        </div>
        <div className='mau-limit-info'>{mailsUsage?.toLocaleString()} / {emailSendingLimit?.toLocaleString()}</div>
      </div>
      <div className='text mt-30'>
        Emails are priced at $0.002 / email. You currently sent {Math.round(additionalEmails)?.toLocaleString()} emails.
        <br />
        <a href="#" onClick={() => {
          Gleap.startFeedbackFlow("acwu3");
        }}>Contact us</a> to increase your email sending limit.
      </div>
    </>
  );
};

export default inject('organisationStore')(observer(EmailUsageProgress));
