import { hashToColor } from 'helper/Color';
import { useEffect, useRef } from 'react';

function capitalizeFirstLetter(string) {
  if (string && string.length > 0) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  return '';
}

function useClickedOutside(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
}

const useHotkey = (key: number, onKeyPress, useShift = true) => {
  useEffect(() => {
    const handleEsc = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.keyCode === key) {
        if (event.shiftKey || !useShift) {
          onKeyPress();
          event.preventDefault();
        }
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);
};

const useEscape = (onEscape) => {
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) onEscape();
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);
};

const useBackRouter = (
  navigate,
  boardShareToken?,
  collectionId?,
  articleId?,
) => {
  useEffect(() => {
    const initialData = {
      boardShareToken: boardShareToken,
      collectionId: collectionId,
      articleId: articleId,
    };

    const handleBackRoute = (e) => {
      try {
        const data = JSON.parse(e.data);
        if (data && data.name === 'goback') {
          if (
            initialData.boardShareToken &&
            initialData.collectionId &&
            initialData.articleId
          ) {
            return navigate(
              `/sharedboard/${boardShareToken}/helpcenter/${collectionId}`,
            );
          }
          if (initialData.boardShareToken && initialData.collectionId) {
            return navigate(`/sharedboard/${boardShareToken}/helpcenter`);
          }
          if (initialData.boardShareToken) {
            return navigate(`/sharedboard/${boardShareToken}/helpcenter`);
          }
        }
      } catch (exp) {}
    };
    window.addEventListener('message', handleBackRoute);

    return () => {
      window.removeEventListener('message', handleBackRoute);
    };
  }, []);
};

function isMacintosh() {
  return navigator.platform.indexOf('Mac') > -1;
}

function sum(obj) {
  var sum = 0;
  for (var el in obj) {
    if (obj.hasOwnProperty(el)) {
      sum += parseInt(obj[el]);
    }
  }
  return sum;
}

function sendPostMessage(data) {
  var parentWindow = (window as any).top;
  if ((window as any).parent != (window as any).top) {
    parentWindow = (window as any).parent;
  }

  if (parentWindow) {
    parentWindow.postMessage(data, '*');
  }
}

function ticketStatusToColor(status) {
  var color = '';
  switch (status) {
    case 'OPEN':
      color = '#85B5B3';
      break;

    case 'PLANNED':
      color = '#1FA0FF';
      break;

    case 'INPROGRESS':
      color = '#9B59B6';
      break;

    case 'CLOSED':
      color = '#e50d00';
      break;

    case 'DONE':
      color = '#30CB83';
      break;

    default:
      color = hashToColor(status);
      break;
  }

  return color;
}

function isSharedSite() {
  if (
    window.location.href.includes('/sharedboard') ||
    window.location.href.includes('/share') ||
    window.location.href.includes('/unsubscribe') ||
    window.location.href.includes('/app-comments')
  ) {
    return true;
  }
  return false;
}

function getTranslatedDefaultObject(obj) {
  if (obj && obj.localized) {
    if ('en' in obj.localized) {
      return obj.localized.en;
    } else {
      var keys = Object.keys(obj.localized);
      if (keys.length > 0) {
        return obj.localized[keys[0]];
      }
    }
  }
  return null;
}

function getDefaultType(value, matchOperator?: string) {
  
  if (value === null || value === undefined || value === '') {
    if (matchOperator === 'lessthan' || matchOperator === 'greaterthan') {
      return 'number';
    }

    return 'string';
  }

  if (isValidDate(value)) {
    return 'date';
  }

  if (typeof value === 'number') {
    return 'number';
  }

  if (typeof value === 'boolean') {
    return 'boolean';
  }

  return 'string';
}

const typeMapping = {
  TEXT: 'string',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
  DATE: 'date',
  EMAIL: 'string',
};

const getTypeOfAttribute = (attribute, allAttributes) => {
  try {
    let tagId = attribute?.event?.split('.')[1];
    if (!tagId) {
      tagId = attribute?.event?.split('gleap-up-')[1];
      if (tagId && tagId.includes('.')) {
        tagId = tagId.split('.')[1];
      }
    }

    if (tagId && allAttributes?.length > 0) {
      const tag = allAttributes.find((tag) => tag.fieldId === tagId);

      if (tag) {
        const type = typeMapping[tag.type];
        return type;
      }
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

const hasTranslation = (langCode: string, article: any) => {
  try {
    if (
      article?.title[langCode] ||
      article?.content[langCode] ||
      article?.description[langCode]
    ) {
      return true;
    }
  
    return false;
  } catch (error) {
    return false;
  }
};

const isValidDate = (dateString) => {
  try {
    if (typeof dateString !== 'string') return false;

    // Regular expression to match the common date formats (YYYY-MM-DD, YYYY/MM/DD, DD-MM-YYYY, etc.)
    const dateRegex = /^\d{4}[-/]\d{2}[-/]\d{2}$|^\d{2}[-/]\d{2}[-/]\d{4}$/;

    if (!dateRegex.test(dateString)) return false;

    const date = new Date(dateString);
    return !isNaN(date.getTime());
  } catch (_) {
    return false;
  }
};

export {
  capitalizeFirstLetter,
  isSharedSite,
  useClickedOutside,
  useEscape,
  useHotkey,
  isMacintosh,
  sum,
  sendPostMessage,
  useBackRouter,
  ticketStatusToColor,
  getTranslatedDefaultObject,
  getDefaultType,
  isValidDate,
  getTypeOfAttribute,
  hasTranslation,
};
