import { useRef } from 'react';
import { useClickedOutside } from 'services/Helper';
import './BotActionAddOptions.scss';
import { ActionTypes } from 'models/Bot';

export const botActionTypes = [
  {
    label: 'Write a message',
    value: 'message',
    icon: 'message',
    color: '#95F1E7',
  },
  {
    label: 'Show expected reply time',
    value: 'replytimes',
    icon: 'clock',
    color: '#95F1E7',
  },
  {
    label: 'Collect customer reply',
    value: 'chatinput',
    icon: 'messages',
    color: '#95F1E7',
  },
  {
    label: 'Collect data',
    value: 'input',
    icon: 'messages',
    color: '#95F1E7',
  },
  {
    label: 'Set data attribute',
    value: ActionTypes.TICKETUPDATE_DATA,
    icon: 'pen-to-square',
    color: '#95F1E7',
  },
  {
    label: 'Schedule a meeting',
    value: 'calendly',
    icon: 'calendar',
    color: '#95F1E7',
  },
  {
    label: 'Rate conversation (CSAT)',
    value: 'rateconversation',
    icon: 'message-smile',
    color: '#95F1E7',
  },
  {
    label: 'Tag ticket',
    value: 'ticketupdate-tag',
    icon: 'tag',
    color: '#CACEFF',
  },
  {
    label: 'AI ticket tagging 🪄',
    value: 'ticketupdate-aitag',
    icon: 'tag',
    color: '#CACEFF',
  },
  {
    label: 'Assign ticket',
    value: 'ticketupdate-assign',
    icon: 'user',
    color: '#CACEFF',
  },
  {
    label: 'Change ticket status',
    value: 'ticketupdate-status',
    icon: 'badge-check',
    color: '#CACEFF',
  },
  {
    label: 'Run custom action',
    value: 'custom-action',
    icon: 'bolt',
    color: '#CACEFF',
  },
  {
    label: 'Custom API action',
    value: 'api',
    icon: 'webhook',
    color: '#CACEFF',
  },
  {
    label: 'Pass to Kai 🪄 (AI-answer bot)',
    value: 'answerbotflow',
    icon: 'message-bot',
    color: '#ffd200',
    endOption: true,
  },
  {
    label: 'Pass to workflow',
    value: 'botflow',
    icon: 'message-bot',
    color: '#ffd200',
    endOption: true,
  },
  {
    label: 'Close ticket',
    value: 'ticketupdate-close',
    icon: 'circle-check',
    color: '#ffd200',
    endOption: true,
  },
  {
    label: 'Open link',
    value: 'link',
    icon: 'square-up-right',
    color: '#ffd200',
    endOption: true,
  },
  {
    label: 'Show form',
    value: 'feedbackflow',
    icon: 'pen-field',
    color: '#ffd200',
    endOption: true,
  },
  {
    label: 'Create ticket',
    value: 'createticket',
    icon: 'ticket-simple',
    color: '#ffd200',
    endOption: true,
  },
  {
    label: 'Condition',
    value: 'condition',
    icon: 'option',
    color: '#ffd200',
    endOption: true,
  },
  {
    label: 'Buttons',
    value: 'buttons',
    icon: 'chart-simple-horizontal',
    color: '#ffd200',
    endOption: true,
  },
];

interface BotActionAddOptionsProps {
  onBotActionAdded: (type: string) => void;
  closeAddOptions: () => void;
  canShowEndOptions?: boolean;
}

const BotActionAddOptions = ({
  onBotActionAdded,
  closeAddOptions,
  canShowEndOptions = true,
}: BotActionAddOptionsProps) => {
  const wrapperRef = useRef(null);
  useClickedOutside(wrapperRef, () => {
    closeAddOptions();
  });

  return (
    <div className="bot-action-add-options nowheel" ref={wrapperRef}>
      {botActionTypes
        .filter((botAction) => {
          if (canShowEndOptions) return true;
          return !botAction.endOption;
        })
        .map((botAction) => {
          return (
            <div
              key={botAction.value}
              className="bot-action-add-options-item"
              onClick={() => {
                onBotActionAdded(botAction.value);
                closeAddOptions();
              }}
            >
              <div
                className="bot-action-add-options-item-icon"
                style={{
                  backgroundColor: botAction.color,
                }}
              >
                <i className={`fa-solid fa-${botAction.icon}`}></i>
              </div>
              <div className="bot-action-add-options-item-title">
                {botAction.label}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default BotActionAddOptions;
