import { Editor } from '@tiptap/react';
import classNames from 'classnames';
import EmailEditor from 'components/Editors/EmailEditor/EmailEditor';
import Toolbar, { ToolbarItems } from 'components/Editors/ToolBar/Toolbar';
import LanguageDropdown from 'components/LanguageDropdown/LanguageDropdown';
import LinkButton from 'components/LinkButton/LinkButton';
import TabButton from 'components/TabButton/TabButton';
import {
  getLanguageProperty,
  setLanguageProperty,
} from 'helper/AssignObjectKeysHelper';
import { inject, observer } from 'mobx-react';
import Switch from 'react-switch';
import { useRef, useState } from 'react';
import { TwitterPicker } from 'react-color';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';

interface TooltipEditorProps {
  availableLanguages?: any[];
  currentStep?: any;
  updateStepData: (data: any) => void;
  pickSelector: () => void;
  currentLang: string;
  setCurrentLang: (lang: string) => void;
  modalStore?: ModalStore;
  projectId?: string;
}

const TooltipEditor = ({
  availableLanguages,
  currentStep,
  updateStepData,
  pickSelector,
  currentLang,
  setCurrentLang,
  modalStore,
  projectId,
}: TooltipEditorProps) => {
  const editorRef: React.MutableRefObject<Editor | null> = useRef(null);
  const [toggleState, setToggleState] = useState(false);

  const currentLanguage = availableLanguages?.find(
    (item) => item.code === currentLang,
  );

  if (!currentStep) {
    return null;
  }

  const renderOptions = () => {
    return (
      <div className="step-options">
        <div className="tab-buttons">
          <div className="tab-buttons--inner">
            <TabButton
              active={currentStep.mode === 'hotspot'}
              icon="bullseye-pointer"
              label="Hover hotspot"
              onClick={() => {
                updateStepData({
                  mode: 'hotspot',
                });
              }}
            />
            <TabButton
              active={currentStep.mode === 'element'}
              icon="arrow-pointer"
              label="Hover element"
              onClick={() => {
                updateStepData({
                  mode: 'element',
                });
              }}
            />
          </div>
        </div>
        {!availableLanguages || availableLanguages.length < 2 ? null : (
          <PrimaryButton
            className={'tooltip-editor-translate-button'}
            fancy
            small
            label="AI-translate"
            onClick={() => {
              modalStore?.openModalSmart(MODALTYPE.DYNAMIC_AI_TRANSLATION, {
                projectId: projectId,
                baseLanguage: currentLanguage,
                targetLanguage: availableLanguages,
                languageList: availableLanguages,
                getContent: (baseLang) => {
                  const content = getLanguageProperty(
                    currentStep,
                    'message',
                    baseLang,
                  );
                  return {
                    content: content,
                  };
                },
                setContent: (translatedContent, lang) => {
                  const { content } = translatedContent;
                  if (content) {
                    setLanguageProperty(currentStep, 'message', lang, content);
                    if (lang === currentLang) {
                      updateStepData({
                        message: currentStep.message,
                      });
                      editorRef?.current?.commands.setContent(
                        content !== '' ? content : {},
                      );
                    }
                  }
                },
              });
            }}
          />
        )}
        <LanguageDropdown
          className="flow-chart-bot-language-dropdown"
          items={availableLanguages ?? []}
          selectedItem={currentLanguage}
          onChange={(value) => {
            setCurrentLang(value.code);
          }}
        />
      </div>
    );
  };

  const renderEditor = () => {
    return (
      <>
        <div className="tooltip-editor-container">
          <div className="tooltip-wrapper">
            <Toolbar
              editor={editorRef?.current}
              aiStyle="news"
              items={[
                ToolbarItems.Basic,
                ToolbarItems.Advanced,
                ToolbarItems.Image,
                ToolbarItems.Embedded,
              ]}
              language={currentLang}
            />
          </div>
          <div className="editor-wrapper">
            <EmailEditor
              key={currentLang}
              editorRef={editorRef}
              onEditorReady={() => {
                setToggleState(!toggleState);
              }}
              content={getLanguageProperty(currentStep, 'message', currentLang)}
              inputContentChanged={(content) => {
                try {
                  const isEmpty =
                    editorRef?.current?.getText()?.replaceAll(' ', '')
                      ?.length === 0;

                  if (isEmpty) {
                    delete currentStep.message.localized[currentLang];
                    return;
                  }
                } catch (error) {
                  console.error(error);
                }

                setLanguageProperty(
                  currentStep,
                  'message',
                  currentLang,
                  content,
                );
                updateStepData({
                  message: currentStep.message,
                });
              }}
            />
          </div>
        </div>
        {renderOptions()}
        {currentStep.mode === 'hotspot' && (
          <div className="tooltip-editor-hotspotconfig">
            <div className="tooltip-editor-hotspotconfig-column">
              <div className="tab-buttons">
                <div className="tab-buttons--inner">
                  {[
                    'circle-dot',
                    'circle-question',
                    'circle-info',
                    'circle-exclamation',
                    'circle-up',
                    'circle-right',
                  ].map((icon) => {
                    const isActive = currentStep.icon === icon;
                    const TabButtonClassName = classNames(`tab-button`, {
                      'tab-button--active': isActive,
                    });

                    return (
                      <button
                        key={icon}
                        type="button"
                        className={TabButtonClassName}
                        onClick={() => {
                          updateStepData({
                            icon,
                          });
                        }}
                      >
                        {icon && (
                          <i
                            className={`fa fa-${icon}`}
                            style={
                              isActive
                                ? {
                                    color: currentStep.color,
                                  }
                                : {}
                            }
                          />
                        )}
                      </button>
                    );
                  })}
                </div>
              </div>
              <div className="widget-color-picker mt-10">
                <div className="color-picker">
                  <TwitterPicker
                    colors={[]}
                    color={currentStep.color}
                    onChangeComplete={(color) => {
                      updateStepData({
                        color: color.hex,
                      });
                    }}
                  />
                  <div
                    className="color-preview"
                    style={{
                      backgroundColor: currentStep.color,
                    }}
                  />
                </div>
              </div>
              <div className="switch-container mt-20">
                <Switch
                  width={40}
                  onColor="#2142E7"
                  height={20}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={(checked) => {
                    updateStepData({
                      animated: checked,
                    });
                  }}
                  checked={currentStep.animated}
                />
                <span>Animated hotspot</span>
              </div>
            </div>
            <div className="tooltip-editor-hotspotconfig-column">
              <div className="tab-buttons tab-buttons--alignment">
                <div className="tab-buttons--inner">
                  {[
                    {
                      icon: 'arrow-left-from-line',
                      value: 'left',
                    },
                    {
                      icon: 'arrow-right-from-line',
                      value: 'right',
                    },
                  ].map((option) => {
                    const isActive = currentStep.posX === option.value;
                    const TabButtonClassName = classNames(`tab-button`, {
                      'tab-button--active': isActive,
                    });

                    return (
                      <button
                        key={option.icon}
                        type="button"
                        className={TabButtonClassName}
                        onClick={() => {
                          updateStepData({
                            posX: option.value,
                          });
                        }}
                      >
                        {option.icon && (
                          <i
                            className={`fa fa-${option.icon}`}
                            style={
                              isActive
                                ? {
                                    color: '#000',
                                  }
                                : {}
                            }
                          />
                        )}
                      </button>
                    );
                  })}
                </div>
              </div>
              <div className="tab-buttons tab-buttons--alignment mt-10">
                <div className="tab-buttons--inner">
                  {[
                    {
                      icon: 'arrow-up-to-line',
                      value: 'top',
                    },
                    {
                      icon: 'arrows-to-line',
                      value: 'center',
                    },
                    {
                      icon: 'arrow-down-from-line',
                      value: 'bottom',
                    },
                  ].map((option) => {
                    const isActive = currentStep.posY === option.value;
                    const TabButtonClassName = classNames(`tab-button`, {
                      'tab-button--active': isActive,
                    });

                    return (
                      <button
                        key={option.icon}
                        type="button"
                        className={TabButtonClassName}
                        onClick={() => {
                          updateStepData({
                            posY: option.value,
                          });
                        }}
                      >
                        {option.icon && (
                          <i
                            className={`fa fa-${option.icon}`}
                            style={
                              isActive
                                ? {
                                    color: '#000',
                                  }
                                : {}
                            }
                          />
                        )}
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="tooltip-editor">
      <div className={`tooltip-editor-inner tooltip-editor-hastail`}>
        {renderEditor()}
      </div>
      <div className="selector-tag-container">
        <div className="selector-tag">
          <input
            type="text"
            value={currentStep.selector}
            onChange={(e) => {
              updateStepData({
                selector: e.target.value,
              });
            }}
          />
        </div>
        {pickSelector && (
          <LinkButton
            icon="crosshairs"
            onClick={() => {
              pickSelector();
            }}
          />
        )}
      </div>
    </div>
  );
};

export default inject('projectStore', 'modalStore')(observer(TooltipEditor));
