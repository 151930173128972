import ProductTourBuilder from 'components/ProductTourBuilder/ProductTourBuilder';
import ProductTourEditorPage from 'components/ProductTourEditorPage/ProductTourEditorPage';
import AIBot from 'pages/private/AIBot/AIBot';
import Bots from 'pages/private/Automation/Bots';
import CreateOrganisation from 'pages/private/CreateOrganisation/CreateOrganisation';
import CreateProjectPage from 'pages/private/CreateProjectPage/CreateProjectPage';
import Dashboard from 'pages/private/Dashboard/Dashboard';
import Organisation from 'pages/private/Organisation/Organisation';
import Profile from 'pages/private/Profile/Profile';
import ProjectContactDetails from 'pages/private/ProjectContactDetails/ProjectContactDetails';
import ProjectContacts from 'pages/private/ProjectContacts/ProjectContacts';
import Helpcenter from 'pages/private/ProjectHelpcenter/Helpcenter/Helpcenter';
import ProjectInbound from 'pages/private/ProjectInbound/ProjectInbound';
import ProjectInbox, {
  getInboxBody,
} from 'pages/private/ProjectInbox/ProjectInbox';
import ChooseIntegration from 'pages/private/ProjectIntegrations/ChooseIntegration/ChooseIntegration';
import AworkConnectConfiguration from 'pages/private/ProjectIntegrations/ConfigureIntegration/ConfigurationComponents/AworkConfiguration/AworkConnectConfiguration';
import ShortcutConnectionConfiguration from 'pages/private/ProjectIntegrations/ConfigureIntegration/ConfigurationComponents/ShortcutConfiguration/ShortcutConnectionConfiguration';
import ZendeskConnectConfiguration from 'pages/private/ProjectIntegrations/ConfigureIntegration/ConfigurationComponents/ZendeskConfiguration/ZendeskConnectConfiguration';
import ConfigureIntegration from 'pages/private/ProjectIntegrations/ConfigureIntegration/ConfigureIntegration';
import ProjectOverview from 'pages/private/ProjectIntegrations/IntegationsOverview/IntegrationsOverview';
import IntegrationCallback from 'pages/private/ProjectIntegrations/IntegrationCallback/IntegrationCallback';
import ProjectOutboundConfiguration from 'pages/private/ProjectOutbounds/ProjectOutboundConfiguration/ProjectOutboundConfiguration';
import ProjectOutboundDetails from 'pages/private/ProjectOutbounds/ProjectOutboundDetails/ProjectOutboundDetails';
import ProjectOutboundLoading from 'pages/private/ProjectOutbounds/ProjectOutboundLoading/ProjectOutboundLoading';
import ProjectOutboundOverview from 'pages/private/ProjectOutbounds/ProjectOutboundsOverview/ProjectOutboundOverview';
import ProjectReports from 'pages/private/ProjectReports/ProjectReports';
import ProjectSettings from 'pages/private/ProjectSettings/ProjectSettings';
import ProjectWidget from 'pages/private/ProjectWidget/ProjectWidget';
import VerifyCode from 'pages/private/tutorial/VerifyCode/VerifyCode';
import ForgetPassword from 'pages/public/ForgetPasword/ForgetPassword';
import MobileComments from 'pages/public/MobileComments/MobileComments';
import PaddleDefaultCheckoutPage from 'pages/public/PaddleDefaultCheckoutPage/PaddleDefaultCheckoutPage';
import RateConversation from 'pages/public/RateConversation/RateConversation';
import ResetPassword from 'pages/public/ResetPassword/ResetPassword';
import SharedBugReport from 'pages/public/SharedBugReport/SharedBugReport';
import SharedFeatureRequests from 'pages/public/SharedFeatureRequests/SharedFeatureRequests';
import Unsubscribe from 'pages/public/Unsubscribe/Unsubscribe';
import { Route, Routes } from 'react-router-dom';
import Login from '../pages/public/login/Login';
import Register from '../pages/public/register/Register';
import MainProjectRedirect from './MainProjectRedirect';
import './MainRouting.scss';
import PublicRoute from './PublicRoute';
import ProjectEmailEditor from 'pages/private/EmailTemplatePage/ProjectEmailEditor/ProjectEmailEditor';
import TicketDetailScreen from 'components/BugDetail/TicketDetailScreen';
import SalesforceConnectConfiguration from 'pages/private/ProjectIntegrations/ConfigureIntegration/ConfigurationComponents/SalesforceConfiguration/SalesforceConnectConfiguration';
import TooltipBuilder from 'components/TooltipBuilder/TooltipBuilder';
import AgentReport from 'pages/private/AgentReport/AgentReport';

const ProjectRoute = () => {
  return (
    <Routes>
      <Route path="/:projectId/bots" element={<Bots />} />
      <Route path="/:projectId/bots/*" element={<Bots />} />
      <Route path="/:projectId/aibot" element={<AIBot />} />
      <Route path="/:projectId/aibot/*" element={<AIBot />} />
      <Route path="/:projectId/reports/*" element={<ProjectReports />} />
      <Route
        path="/:projectId/outbound/:outboundId/startproducttour"
        element={<ProductTourEditorPage type="tours" />}
      />
      <Route
        path="/:projectId/outbound/:outboundId/starttooltips"
        element={<ProductTourEditorPage type="tooltips" />}
      />
      <Route
        path="/:projectId/outbound/:outboundId/edit"
        element={<ProjectOutboundConfiguration />}
      />
      <Route
        path="/:projectId/outbound"
        element={<ProjectOutboundOverview />}
      />
      <Route
        path="/:projectId/:outboundId/loading"
        element={<ProjectOutboundLoading />}
      />
      <Route
        path="/:projectId/outbound/:outboundId"
        element={<ProjectOutboundDetails />}
      >
        <Route path=":shareToken" element={<TicketDetailScreen />} />
      </Route>
      <Route path="/:projectId/widget/*" element={<ProjectWidget />} />
      <Route path="/:projectId/sessions" element={<ProjectContacts />} />
      <Route
        path="/:projectId/sessions/:contactId"
        element={<ProjectContactDetails />}
      >
        <Route path=":shareToken" element={<TicketDetailScreen />} />
      </Route>
      <Route path="/:projectId/integrations" element={<ProjectOverview />} />
      <Route
        path="/:projectId/integrations/awork"
        element={<AworkConnectConfiguration />}
      />
      <Route path="/:projectId/reports/user/:userId" element={<AgentReport />} />
      <Route
        path="/:projectId/integrations/shortcut"
        element={<ShortcutConnectionConfiguration />}
      />
      <Route
        path="/:projectId/integrations/connect"
        element={<ChooseIntegration />}
      />
      <Route
        path="/:projectId/integrations/trello"
        element={<IntegrationCallback />}
      />
      <Route
        path="/:projectId/integrations/webhook"
        element={<IntegrationCallback />}
      />
      <Route
        path="/:projectId/integrations/email"
        element={<IntegrationCallback />}
      />
      <Route
        path="/:projectId/integrations/stripe"
        element={<IntegrationCallback />}
      />
      <Route
        path="/:projectId/integrations/slack"
        element={<IntegrationCallback />}
      />
      <Route
        path="/:projectId/integrations/clickup"
        element={<IntegrationCallback />}
      />
      <Route
        path="/:projectId/integrations/zendesk"
        element={<ZendeskConnectConfiguration />}
      />
      <Route
        path="/:projectId/integrations/salesforce"
        element={<SalesforceConnectConfiguration />}
      />
      <Route
        path="/:projectId/integrations/:integration/:integrationID"
        element={<ConfigureIntegration />}
      />
      <Route path="/:projectId/settings/*" element={<ProjectSettings />} />
      <Route path="/:projectId/helpcenter/*" element={<Helpcenter />} />
      <Route path="/:projectId/inquiries" element={<ProjectInbox />}>
        <Route path=":shareToken" element={getInboxBody()} />
      </Route>
      <Route
        path="/:projectId/inquiries/:shareToken"
        element={<ProjectInbox />}
      />
      <Route path="/:projectId/*" element={<ProjectInbound />} />
      <Route path="/:projectId" element={<MainProjectRedirect />} />
      <Route
        path="/:projectId/settings/email-templates/:templateId"
        element={<ProjectEmailEditor />}
      />
    </Routes>
  );
};

export const PrivateRouting = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route path="/producttourbuilder" element={<ProductTourBuilder />} />
      <Route path="/tooltipbuilder" element={<TooltipBuilder />} />
      <Route path="/app-comments" element={<MobileComments />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgetpassword" element={<ForgetPassword />} />
      <Route path="/resetpassword" element={<ResetPassword />} />
      <Route path="/resetpassword/:token" element={<ResetPassword />} />
      <Route path="/share/:shareToken" element={<SharedBugReport />} />
      <Route path="/unsubscribe" element={<Unsubscribe />} />
      <Route path="/pay" element={<PaddleDefaultCheckoutPage />} />
      <Route path="/rateconversation" element={<RateConversation />} />
      <Route
        path="/sharedboard/:boardShareToken"
        element={<SharedFeatureRequests />}
      />
      <Route
        path="/sharedboard/:boardShareToken/news"
        element={<SharedFeatureRequests />}
      />
      <Route
        path="/sharedboard/:boardShareToken/news/:newsId"
        element={<SharedFeatureRequests />}
      />
      <Route
        path="/sharedboard/:boardShareToken/roadmap"
        element={<SharedFeatureRequests />}
      />
      <Route
        path="/sharedboard/:boardShareToken/roadmap/:featureRequestShareToken"
        element={<SharedFeatureRequests />}
      />
      <Route
        path="/sharedboard/:boardShareToken/featurerequests/:featureRequestShareToken"
        element={<SharedFeatureRequests />}
      />
      <Route path="/verify" element={<VerifyCode />} />
      <Route path="/organization/create" element={<CreateOrganisation />} />
      <Route
        path="/organization/:organizationId/*"
        element={<Organisation />}
      />
      <Route path="/organization/*" element={<Organisation />} />
      <Route path="/profile/*" element={<Profile />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route
        path="/integrations/:integrationName"
        element={<IntegrationCallback />}
      />
      <Route path="/projects/create" element={<CreateProjectPage />} />
      <Route path="/projects/*" element={<ProjectRoute />} />
    </Routes>
  );
};

export default PrivateRouting;
