import { Card } from 'components/Card/Card';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { isEqual } from 'lodash';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import Switch from 'react-switch';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './WidgetAppSettingsModal.scss';
import TextInput from 'components/TextInput/TextInput';
import {
  getLanguageProperty,
  getProperty,
  setLanguageProperty,
} from 'helper/AssignObjectKeysHelper';

interface WidgetAppSettingsModalProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

const WidgetAppSettingsModal = ({
  modalStore,
  projectStore,
}: WidgetAppSettingsModalProps) => {
  const currentLang = projectStore?.currentLanguage ?? 'en';

  var { flowConfig } = projectStore?.editingProject || {};

  const dataToUpdate = {
    showNoFeatureSuggestions: flowConfig?.showNoFeatureSuggestions,
    showNewsOnHome: flowConfig?.showNewsOnHome,
    showHelpSearchOnHome: flowConfig?.showHelpSearchOnHome,
    helpSearch: flowConfig?.helpSearch,
    hideNews: flowConfig?.hideNews,
    hideFeatureRequests: flowConfig?.hideFeatureRequests,
    hideKnowledgeBase: flowConfig?.hideKnowledgeBase,
    hideChecklists: flowConfig?.hideChecklists,
    hideAllConversations: flowConfig?.hideAllConversations,
    newsMenuLabel: flowConfig?.newsMenuLabel,
    featureRequestsMenuLabel: flowConfig?.featureRequestsMenuLabel,
    knowledgeBaseMenuLabel: flowConfig?.knowledgeBaseMenuLabel,
    checklistMenuLabel: flowConfig?.checklistMenuLabel,
    allConversationsMenuLabel: flowConfig?.allConversationsMenuLabel,
    showMsgBtnOnConvTab: flowConfig?.showMsgBtnOnConvTab,
  };

  const data = modalStore?.customData;
  const selector = () => {
    if (data?.type === 'news') {
      return 'hideNews';
    }
    if (data?.type === 'featureRequests') {
      return 'hideFeatureRequests';
    }
    if (data?.type === 'knowledgeBase') {
      return 'hideKnowledgeBase';
    }
    if (data?.type === 'checklist') {
      return 'hideChecklists';
    }
    return 'hideAllConversations';
  };

  const labelSelector = () => {
    if (data?.type === 'news') {
      return 'newsMenuLabel';
    }

    if (data?.type === 'featureRequests') {
      return 'featureRequestsMenuLabel';
    }

    if (data?.type === 'knowledgeBase') {
      return 'knowledgeBaseMenuLabel';
    }

    if (data?.type === 'checklist') {
      return 'checklistMenuLabel';
    }

    return 'allConversationsMenuLabel';
  };

  const mainSwitchSelector = selector();
  const menuLabelSelector = labelSelector();

  const renderCustomConfig = (type: string) => {
    if (type === 'featureRequests') {
      return (
        <>
          <div className="switch-container feedback-content-row-cell mr-10 mt-30 mb-20">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={(checked) => {
                runInAction(() => {
                  flowConfig.showNoFeatureSuggestions = !checked;
                });
              }}
              checked={flowConfig?.showNoFeatureSuggestions ? false : true}
            />
            <span>
              Suggest feature requests before submitting a new feature request
            </span>
          </div>
        </>
      );
    }

    if (type === 'conversations') {
      return (
        <div className="switch-container feedback-content-row-cell mr-10 mt-30 mb-20">
          <Switch
            width={40}
            onColor="#2142E7"
            height={20}
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={(checked) => {
              runInAction(() => {
                flowConfig.showMsgBtnOnConvTab = checked;
              });
            }}
            checked={flowConfig?.showMsgBtnOnConvTab ?? true}
          />
          <span>Show send message button on conversations tab</span>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="widget-app-settings-modal">
      <Card className="widget-app-card">
        <div className="link-header">
          {data.image && <data.image className="image" />}
        </div>
        <div className="link-body">
          <span className="title">{data.title}</span>
          <div className="description">{data.description}</div>
        </div>
      </Card>
      <TextInput
        name="Menu label"
        className="mb-20"
        localizedable
        placeholder=""
        type="text"
        error=""
        value={getLanguageProperty(flowConfig, menuLabelSelector, currentLang)}
        translationObject={getProperty(flowConfig, menuLabelSelector)}
        label="Menu label"
        onChange={(text) => {
          setLanguageProperty(flowConfig, menuLabelSelector, currentLang, text);
        }}
      />
      <div className="switch-container feedback-content-row-cell mr-10">
        <Switch
          width={40}
          onColor="#2142E7"
          height={20}
          checkedIcon={false}
          uncheckedIcon={false}
          onChange={(checked) => {
            runInAction(() => {
              flowConfig[mainSwitchSelector] = !checked;
            });
          }}
          checked={flowConfig[mainSwitchSelector] ? false : true}
        />
        <span>Show in tab bar</span>
      </div>
      {renderCustomConfig(data.type)}
      <PrimaryButton
        label="Save"
        className="mt-20"
        disabled={isEqual(projectStore?.flowConfig, flowConfig)}
        onClick={() => {
          projectStore?.saveEditingProject({ flowConfig: dataToUpdate });
          modalStore?.closeModal();
        }}
      />
    </div>
  );
};

export default inject(
  'modalStore',
  'projectStore',
)(observer(WidgetAppSettingsModal));
