import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import { Title } from 'components/Title/Title';
import { checkIntegrationData } from 'helper/Integration';
import { inject, observer } from 'mobx-react';
import { INTEGRATION } from 'models/Integration';
import React, { useEffect, useState } from 'react';
import { ShortcutIntegrationStore } from 'stores/integrations/ShortcutIntegrationStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './ShortcutConfiguration.scss';

interface ShortcutConfigurationProps {
  integrationID: string;
  index: number;
  shortcutIntegrationStore?: ShortcutIntegrationStore;
  projectStore?: ProjectStore;
  onChange: (data: any) => any;
}

const ShortcutConfiguration = ({
  integrationID,
  index,
  shortcutIntegrationStore,
  projectStore,
  onChange,
}: ShortcutConfigurationProps) => {
  const project = projectStore?.currentProject;

  const [currentIntegrationInfo, setCurrentIntegrationInfo] = useState(
    {} as any,
  );

  const projects = shortcutIntegrationStore?.projects;
  const teams = shortcutIntegrationStore?.teams;
  const epics = shortcutIntegrationStore?.epics;
  const workflows = shortcutIntegrationStore?.workflows;
  const [workflowStates, setWorkFlowStates] = useState([] as any);

  const storyTypes = [
    { name: 'Bug', value: 'bug' },
    { name: 'Chore', value: 'chore' },
    { name: 'Feature', value: 'feature' },
  ];

  const [projectID, setProjectID] = useState('');
  const [teamID, setTeamID] = useState('');
  const [epicID, setEpicID] = useState('');
  const [workflowID, setWorkflowID] = useState('');
  const [workflowStateID, setWorkflowStateID] = useState('');
  const [storyType, setStoryType] = useState('');

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    if (project && isMounted) {
      initialize();
    }
  }, [project, isMounted]);

  useEffect(() => {
    saveIntegrationInfo();
  }, [projectID, teamID, epicID, workflowID, workflowStateID, storyType]);

  const initialize = async () => {
    shortcutIntegrationStore!.setIntegrationID(integrationID);
    shortcutIntegrationStore!.getProjects();
    shortcutIntegrationStore!.getTeams();
    shortcutIntegrationStore!.getEpics();
    shortcutIntegrationStore!.getWorkflows();

    if (
      !checkIntegrationData({
        project,
        integrationName: INTEGRATION.SHORTCUT,
        integrationID,
        actionIndex: index,
      })
    ) {
      return;
    }

    const integrationInfo =
      project?.integrations?.shortcut[integrationID]?.actions[index]
        ?.integrationInfo;

    setCurrentIntegrationInfo(integrationInfo);
  };

  const saveIntegrationInfo = () => {
    const integrationInfo = {
      projectID,
      teamID,
      epicID,
      workflowID,
      workflowStateID,
      storyType,
    };

    onChange(integrationInfo);
  };

  return (
    <div className="configuration-container mt-30">
      <SelectDropDown
        className="mb-15"
        label="Project (Legacy feature)"
        labelPropertyName="name"
        valuePropertyName="id"
        selectedItem={projects.find(
          (element) => element.id === currentIntegrationInfo?.projectID,
        )}
        items={projects}
        onChange={(option) => {
          setProjectID(option.id);
        }}
      />
      <SelectDropDown
        className="mb-15"
        label="Team"
        labelPropertyName="name"
        valuePropertyName="id"
        selectedItem={projects.find(
          (element) => element.id === currentIntegrationInfo?.teamID,
        )}
        items={teams}
        onChange={(option) => {
          setTeamID(option.id);
        }}
      />
      <SelectDropDown
        className="mb-15"
        label="Epic"
        labelPropertyName="name"
        valuePropertyName="id"
        selectedItem={epics.find(
          (element) => element.id === currentIntegrationInfo?.epicID,
        )}
        items={epics}
        onChange={(option) => {
          setEpicID(option.id);
        }}
      />
      <SelectDropDown
        className="mb-15"
        label="Story type (optional)"
        labelPropertyName="name"
        valuePropertyName="value"
        selectedItem={storyTypes.find(
          (element) => element.value === currentIntegrationInfo?.storyType,
        )}
        items={storyTypes}
        onChange={(option) => {
          setStoryType(option.value);
        }}
      />
      {(epicID !== '' || projectID !== '') && (
        <SelectDropDown
          className="mb-15"
          label="Workflows"
          labelPropertyName="name"
          valuePropertyName="id"
          selectedItem={workflows.find(
            (element) => element.id === currentIntegrationInfo?.workflowID,
          )}
          items={workflows}
          onChange={(option) => {
            setWorkflowID(option.id);
            setWorkFlowStates(
              workflows.find((element) => element.id === option.id).states,
            );
          }}
        />
      )}
      {workflowID !== '' && (
        <SelectDropDown
          className="mb-15"
          label="Workflow State"
          labelPropertyName="name"
          valuePropertyName="id"
          selectedItem={workflowStates.find(
            (element) => element.id === currentIntegrationInfo?.workflowStateID,
          )}
          items={workflowStates}
          onChange={(option) => {
            setWorkflowStateID(option.id);
          }}
        />
      )}
    </div>
  );
};

export default inject(
  'shortcutIntegrationStore',
  'projectStore',
)(observer(ShortcutConfiguration));
