import React from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import './ComponentWrapper.scss';
import ReactTooltip from 'react-tooltip';
import { Md5 } from 'md5-typescript';

interface ComponentWrapperProps {
  title?: string;
  children: JSX.Element | JSX.Element[] | any;
  className?: string;
  noPadding?: boolean;
  infoText?: string;
}

const ComponentWrapper = ({
  title,
  children,
  className,
  noPadding,
  infoText,
}: ComponentWrapperProps): JSX.Element => {
  const hash = Md5.init(`${title}${infoText}`);

  const optionsGroupClassName = classNames(
    {
      'component-wrapper': true,
      'component-wrapper--no-padding': noPadding,
    },
    className,
  );

  return (
    <div className={optionsGroupClassName}>
      {title && <div className="component-wrapper-header">{title}</div>}
      <div className="component-wrapper-children">{children}</div>
      {infoText && (
        <i
          className="fa-regular fa-info-circle component-wrapper-info-box"
          data-for={`wrapper-info-tooltip-${hash}`}
          data-tip={infoText}
        />
      )}
      <ReactTooltip
        id={`wrapper-info-tooltip-${hash}`}
        className="infoTooltip component-wrapper-tooltip"
        delayHide={300}
        type="light"
        place={'bottom'}
        effect="solid"
        html
      />
    </div>
  );
};

export default inject('modalStore')(observer(ComponentWrapper));
