import React from 'react';
import './SocialLoginButton.scss';

import { GoogleLoginButton } from 'react-social-login-buttons';
import GoogleLogin from 'react-google-login';
import { inject } from 'mobx-react';
import { UsersStore } from 'stores/private/UsersStore';
import { SocialButtonType } from 'models/Enums';
import { isApp } from 'App';
import { trackEvent } from 'services/GTagHelper';

interface SocialLoginButtonProps {
  type: SocialButtonType;
  className?: any;
  style?: any;
  title?: string;
  children?: any;
  usersStore?: UsersStore;
}

const SocialLoginButton = ({
  type,
  className,
  style,
  title,
  children,
  usersStore,
}: SocialLoginButtonProps) => {
  if (isApp) {
    return <></>;
  }

  if (SocialButtonType.GOOGLE === type) {
    return (
      <GoogleLogin
        className="mt-15"
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ''}
        render={(renderProps) => (
          <GoogleLoginButton
            onClick={() => {
              if (renderProps.disabled) {
                alert('Google login is still loading. Please try again in a few seconds.');
                return;
              }
              renderProps.onClick();
            }}
            style={style}
            text={title}
            className={`social-button shadow ${className || ''}`}
          >
            {children}
          </GoogleLoginButton>
        )}
        onSuccess={async (responseGoogle: any) => {
          await usersStore?.loginGoogle(responseGoogle.accessToken);
        }}
        onFailure={(responseGoogle) => { }}
      />
    );
  }

  return <div />;
};

export default inject('usersStore')(SocialLoginButton);
