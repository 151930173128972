import './SharedBoard.scss';
import 'react-virtualized/styles.css';
import { inject, observer } from 'mobx-react';
import { Bug } from 'models/Bug';
import FeatureRequestCard, {
  FeatureRequestCardSkeleton,
} from 'components/FeatureRequestCard/FeatureRequestCard';
import SharedBoardHeader from './SharedBoardHeader';
import { useParams } from 'react-router';
import { SharedBoardStorePublic } from 'stores/public/SharedBoardStorePublic';
import { randomIntFromInterval } from 'helper/RandomNumberHelper';

interface SharedBoardProps {
  sharedBoardStorePublic?: SharedBoardStorePublic;
  items?: Bug[];
  embedMode?: boolean;
}

const SharedBoard = ({ sharedBoardStorePublic, embedMode }: SharedBoardProps) => {
  const { boardShareToken } = useParams();
  const currentLanes = sharedBoardStorePublic!.getLanesForFeedbackType();

  const handleScroll = (e, laneKey) => {
    const bottom =
      Math.abs(
        e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop,
      ) < 1;
    if (bottom) {
      sharedBoardStorePublic?.fetchAndSetTicketsDataForLane({
        laneKey,
        loadMore: true,
      });
    }
  };

  return (
    <div className="shared-board">
      {currentLanes.map((lane, index) => {
        const laneKey = lane.key;
        if (
          sharedBoardStorePublic?.currentFeedbackType?.type ===
          'FEATURE_REQUEST' &&
          (laneKey === 'OPEN' || laneKey === 'CLOSED')
        ) {
          return null;
        }

        if (lane?.title && lane?.title === 'Parked') {
          return null;
        }

        let cards =
          sharedBoardStorePublic?.currentTicketsData &&
            sharedBoardStorePublic?.currentTicketsData[laneKey]
            ? sharedBoardStorePublic?.currentTicketsData[laneKey].data ?? []
            : [];
        const count =
          sharedBoardStorePublic?.currentTicketsData &&
            sharedBoardStorePublic?.currentTicketsData[laneKey]
            ? sharedBoardStorePublic?.currentTicketsData[laneKey].count ?? 0
            : 0;

        var laneTitle = lane?.title;
        if (laneKey === 'OPEN') {
          laneTitle = 'Under review';
        }
        if (laneKey === 'DONE') {
          laneTitle = sharedBoardStorePublic?.translateText('released');
        }

        const isLoading =
          (!sharedBoardStorePublic?.currentTicketsData ||
            !sharedBoardStorePublic?.currentTicketsData[laneKey] ||
            sharedBoardStorePublic?.currentTicketsData[laneKey].isLoading) &&
          sharedBoardStorePublic?.currentTicketsData[laneKey]?.data?.length ===
          0;

        const loadMoreIsOngoing =
          (!sharedBoardStorePublic?.currentTicketsData ||
            !sharedBoardStorePublic?.currentTicketsData[laneKey] ||
            sharedBoardStorePublic?.currentTicketsData[laneKey].isLoading) &&
          sharedBoardStorePublic?.currentTicketsData[laneKey]?.data?.length > 0;

        if (loadMoreIsOngoing) {
          cards = [
            ...cards,
            { isLoading: true, id: Math.random().toString(36).substring(7) },
          ];
        }

        const getPercentage = () => {
          const lanes = currentLanes.filter((lane) => lane.key !== 'OPEN' && lane.key !== 'CLOSED');
          if (lanes.length === 1) {
            return '100%';
          }
          return `${100 / lanes.length}%`;
        }

        return (
          <div
            key={laneKey}
            className={`shared-column ${embedMode && "fill-width"}`}
            style={embedMode ? { width: getPercentage()} : {}}>
            <SharedBoardHeader
              title={laneTitle}
              count={count}
              status={laneKey}
            />
            <div
              className="shared-column-cards"
              onScroll={(e) => {
                handleScroll(e, laneKey);
              }}
            >
              {isLoading && (
                <>
                  {Array.from(Array(randomIntFromInterval(3, 7)).keys()).map(
                    (val, index) => (
                      <FeatureRequestCardSkeleton height={130} key={index} />
                    ),
                  )}
                </>
              )}
              {cards.map((bug) => {
                if (bug.isLoading) {
                  return (
                    <FeatureRequestCardSkeleton height={130} key={bug.id} />
                  );
                }

                return (
                  <FeatureRequestCard
                    key={bug.id}
                    isRoadmap={true}
                    showTag={false}
                    isShared={true}
                    featureRequest={bug}
                    boardShareToken={boardShareToken}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default inject('sharedBoardStorePublic')(observer(SharedBoard));
