import './ChecklistDetails.scss';

const ChecklistDetails = ({ checklists }: {
    checklists?: any;
}) => {
    const renderChecklistItem = (checklist, index) => {
        const progress = Math.round((checklist.step / checklist.outbound.config.steps.length) * 100);
        const currentStep = checklist.outbound.config.steps[checklist.step];

        return (
            <div className="checklist-card" key={checklist.id}>
                <div className="checklist-card-content">
                    <div className="checklist-card-content-title">{checklist.outbound.name}</div>
                    <div className="checklist-progress-bar">
                        <div className="checklist-progress-bar-progress" style={{
                            width: `${progress}%`
                        }}></div>
                    </div>
                    <div className="checklist-progress-labels">
                        <div className="checklist-progress-label">{currentStep ? '⌛️' : '✅'} {checklist.step} of {checklist.outbound.config.steps.length} completed</div>
                    </div>
                </div>
            </div>
        );
    }

    if (!checklists || checklists.length === 0) {
        return (<div className="checklist-details">
            <div className="text">No checklists started yet</div>
        </div>);
    }

    return (<div className="checklist-details">
        {checklists?.map((checklist, index) => {
            return renderChecklistItem(checklist, index);
        })}
    </div>);
};

export default ChecklistDetails;
