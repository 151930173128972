import ImageUpload from 'components/ImageUpload/ImageUpload';
import LinkButton from 'components/LinkButton/LinkButton';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import TextInput from 'components/TextInput/TextInput';
import { languages } from 'constants/Languages';
import { getProperty, setProperty } from 'helper/AssignObjectKeysHelper';
import { inject, observer } from 'mobx-react';
import { HelpcenterCollection } from 'models/Helpcenter';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { HelpcenterStore } from 'stores/private/HelpcenterStore';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import Swal from 'sweetalert2';
import './CreateHelpcenterCollectionModal.scss';

interface CreateHelpcenterCollectionModalProps {
  helpcenterStore?: HelpcenterStore;
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

const CreateHelpcenterCollectionModal = ({
  helpcenterStore,
  projectStore,
  modalStore,
}: CreateHelpcenterCollectionModalProps) => {
  const helpcenterConfig = projectStore?.flowConfig?.helpcenterConfig;
  const { flowConfig } = projectStore?.editingProject || {};
  const navigate = useNavigate();

  useEffect(() => {
    projectStore?.setEditingProject();
  }, [projectStore?.currentProject, projectStore?.flowConfig]);

  const availableLanguages = languages.filter((language) =>
    helpcenterConfig?.localization.find(
      (item) => item.language === language.code,
    ),
  );

  const languageOrder =
    helpcenterConfig?.localization.map((item) => item.language) || [];

  const reorderedLanguages = availableLanguages.sort(
    (a, b) => languageOrder.indexOf(a.code) - languageOrder.indexOf(b.code),
  );

  const [selectedLanguage, setSelectedLanguage] = useState(
    reorderedLanguages[0],
  );

  const [isLoading, setIsLoading] = useState(false);
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [collection, setCollection] = useState({
    title: {},
    description: {},
    iconUrl: '',
  } as HelpcenterCollection);

  useEffect(() => {
    if (modalStore?.customData && modalStore?.customData?.isEditing) {
      setIsEditing(true);
      setCollection({
        ...{
          title: {},
          description: {},
        },
        ...modalStore?.customData,
      });
    } else {
      setIsEditing(false);
      setCollection({
        title: {},
        description: {},
        iconUrl: '',
        parent: modalStore?.customData?.parent ?? undefined,
      } as HelpcenterCollection);
    }
  }, [modalStore?.customData]);

  const createCollection = async () => {
    setIsLoading(true);
    if (!isEditing) {
      await helpcenterStore?.createCollection(collection);
    } else {
      await helpcenterStore?.updateCollection(collection);
    }

    // Auto display helpcenter in widget
    if (flowConfig?.hideKnowledgeBase) {
      flowConfig.hideKnowledgeBase = false;
      await projectStore?.saveEditingProject(
        {
          flowConfig: {
            helpcenterConfig: flowConfig.helpcenterConfig,
            hideKnowledgeBase: false,
          },
        },
        false,
      );
    }

    setIsLoading(false);
    modalStore?.closeModal();
  };

  const deleteCollection = async () => {
    setDeleteIsLoading(true);

    await helpcenterStore?.deleteCollection(collection.id);

    setDeleteIsLoading(false);
    modalStore?.closeModal();
    navigate(`/projects/${projectStore?.currentProject?.id}/helpcenter`);
  };

  const _buildHelpcenterCollectionForm = () => {
    return (
      <div className="options-group helpcenter-form-group">
        <div className="options-group-header helpcenter-form-header">
          <SizedContainer size={ContainerSizes.S} className="mr-30">
            <SelectDropDown
              showBorder
              items={reorderedLanguages}
              selectedItem={languages.find(
                (lang) => lang.code === selectedLanguage.code,
              )}
              labelPropertyName="name"
              valuePropertyName="code"
              placeholder="Select language"
              onChange={(value) => {
                setSelectedLanguage(value);
              }}
            />
          </SizedContainer>
        </div>
        <ImageUpload
          customBgColor={collection.iconUrl}
          image={collection.iconUrl}
          label="Icon"
          editable
          className="helpcenter-collection-icon"
          uploadPath="feedback_widgets"
          afterImageUpload={(imageURL) => {
            collection.iconUrl = imageURL;
            setCollection({ ...collection });
          }}
        />
        <TextInput
          className="mb-20 mt-20"
          label="Title"
          placeholder="Title"
          error=""
          value={getProperty(collection, `title.${selectedLanguage.code}`)}
          translationObject={collection?.title}
          onChange={(val) => {
            setProperty(collection, `title.${selectedLanguage.code}`, val);
            setCollection({ ...collection });
          }}
        />
        <TextInput
          label="Description"
          className="mb-20"
          placeholder="Description"
          error=""
          value={getProperty(
            collection,
            `description.${selectedLanguage.code}`,
          )}
          translationObject={collection?.description}
          onChange={(val) => {
            setProperty(
              collection,
              `description.${selectedLanguage.code}`,
              val,
            );
            setCollection({ ...collection });
          }}
        />
        <div className="buttons-side-by-side">
          <PrimaryButton
            label={!isEditing ? 'Create collection' : 'Update collection'}
            onClick={createCollection}
            isLoading={isLoading}
          />
          {isEditing && (
            <LinkButton
              className="danger ml-15"
              label="Delete"
              isLoading={deleteIsLoading}
              iconSideRight={false}
              onClick={async () => {
                modalStore?.closeModal();
                Swal.fire({
                  text: 'Do you really want to delete this collection?',
                  showCancelButton: true,
                  confirmButtonText: `Yes`,
                  denyButtonText: `No`,
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    deleteCollection();
                    return;
                  }
                  modalStore!.openModal(
                    MODALTYPE.CREATE_HELPCENTER_COLLECTION,
                    {
                      ...collection,
                      isEditing: true,
                    },
                  );
                });
              }}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="helpcenter-collection-modal">
      {_buildHelpcenterCollectionForm()}
    </div>
  );
};

export default inject(
  'helpcenterStore',
  'projectStore',
  'modalStore',
)(observer(CreateHelpcenterCollectionModal));
