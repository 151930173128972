import SimpleSidenavElement from 'components/Sidenav/SimpleSidenavElement/SimpleSidenavElement';
import SidenavContainer from 'components/SidenavContainer/SidenavContainer';
import SubSidenav from 'components/SubSidenav/SubSidenav';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import { ReactComponent as DangerIcon } from '../../../assets/icons/np_warning_462862_FFFFFF.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/icons/settings.svg';
import { ReactComponent as VerifiedIcon } from '../../../assets/icons/np_arroba_436046_000000.svg';
import EditProfil from './EditProfil/EditProfil';
import './Profile.scss';
import ProfileDangerZone from './ProfileDangerZone/ProfileDangerZone';
import ProfileNotificationSettings from './ProfileNotificationSettings/ProfileNotificationSettings';
import Profile2FA from './Profile2FA/Profile2FA';

const Profile = () => {
  // @ts-ignore
  const location = useLocation();
  const navigate = useNavigate();

  const isActive = (path) => {
    if (location.pathname) {
      const parts = location.pathname.split('/');

      if (parts.length >= 3 && parts[2] === path) {
        return true;
      }

      if (path === '' && parts.length === 2 && parts[1] === 'profile') {
        return true;
      }
    }

    return false;
  };

  return (
    <SidenavContainer>
      <SubSidenav title="Profile">
        <SimpleSidenavElement
          name="General"
          onClick={() => {
            navigate(`/profile`);
          }}
          Icon={SettingsIcon}
          isActive={isActive('')}
        />
        <SimpleSidenavElement
          name="2FA"
          onClick={() => {
            navigate(`/profile/2fa`);
          }}
          faIcon='shield-halved'
          isActive={isActive('2fa')}
        />
        <SimpleSidenavElement
          name="Notifications"
          onClick={() => {
            navigate(`/profile/notifications`);
          }}
          Icon={VerifiedIcon}
          isActive={isActive('notifications')}
        />
        <SimpleSidenavElement
          name="Danger zone"
          onClick={() => {
            navigate(`/profile/dangerzone`);
          }}
          Icon={DangerIcon}
          isActive={isActive('dangerzone')}
        />
      </SubSidenav>
      <Routes>
        <Route path="/" element={<EditProfil />} />
        <Route path="/2fa" element={<Profile2FA />} />
        <Route
          path="/notifications"
          element={<ProfileNotificationSettings />}
        />
        <Route path="/dangerzone" element={<ProfileDangerZone />} />
      </Routes>
    </SidenavContainer>
  );
};

export default Profile;
