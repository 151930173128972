import { Card } from 'components/Card/Card';
import LinkButton from 'components/LinkButton/LinkButton';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import connectedIcon from '../../assets/icons/connect_icon.png';
import './IntegrationCard.scss';
import { useNavigate } from 'react-router';

interface IntegrationCardProps {
  image: string;
  title?: string;
  description?: string;
  onClick: any;
  connect: boolean;
  readOnly?: boolean;
  organisationStore?: OrganisationStore;
  modalStore?: ModalStore;
  entitled?: boolean;
  integrationId?: string;
}

const IntegrationCard = ({
  image,
  onClick,
  connect,
  readOnly,
  organisationStore,
  modalStore,
  title,
  description,
  entitled = false,
  integrationId,
}: IntegrationCardProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!connect) {
      organisationStore!.setOnboardingForKey('addedintegration');
    }
  }, [connect]);

  const cardClassName = classNames({
    'integration-card': true,
    entitled: !entitled,
  });

  const getButtonLabel = () => {
    if (!entitled) {
      return 'Upgrade';
    }
    if (connect) {
      return 'Connect';
    }

    return 'Edit';
  };

  return (
    <Card className={cardClassName}>
      <div className="link-header">
        <img className="image" src={image} alt="integration_image" />
        <div>
          <div className="link-header-title">{title}</div>
          {integrationId && (
            <div className="link-header-subtitle">{integrationId}</div>
          )}
        </div>
        {!connect && (
          <div className="connected-icon">
            <img className="icon" src={connectedIcon} alt="connected_icon" />
          </div>
        )}
      </div>
      <div className="link-body">
        <div className="description">{description}</div>
      </div>
      {!readOnly && (
        <div className="link-footer">
          <LinkButton
            data-name={title}
            icon="arrow-right"
            label={getButtonLabel()}
            onClick={() => {
              if (entitled) {
                onClick();
              } else {
                navigate(
                  `/organization/${organisationStore?.currentOrganisation?.id}/billing`,
                );
              }
            }}
          />
        </div>
      )}
    </Card>
  );
};

export default inject(
  'organisationStore',
  'modalStore',
)(observer(IntegrationCard));
