import CalendarRangePicker from 'components/CalendarRangePicker/CalendarRangePicker';
import FutureFactChart from 'components/Charts/FactChart/FutureFactChart';
import FutureBarChart from 'components/Charts/StatisicBarChart/FutureStatisticBarChart';
import FutureStatisticDataTable, {
  StatisticListTypes,
} from 'components/Charts/StatisticDataTable/FutureStatisticDataTable';
import Wrap from 'components/LayoutComponents/WrapComponent/WrapComponent';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import SmartDateRangePicker from 'components/SmartDateRangePicker/SmartDateRangePicker';
import { inject, observer } from 'mobx-react';
import { BarChartStatisticTypes, FactTypes } from 'models/Chart';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import {
  getBarChartStatisic,
  getFactStatistic,
  getListStatistic,
} from 'services/StatisticsService';
import { ProjectStore } from 'stores/private/ProjectStore';

interface CustomerSupportArticlesProps {
  projectStore?: ProjectStore;
}

const CustomerSupportArticles = ({
  projectStore,
}: CustomerSupportArticlesProps) => {
  const projectId = projectStore?.currentProject?.id;

  const [startDate, setStartDate] = useState(
    moment().subtract(1, 'months').startOf('day') as Moment | null,
  );
  const [endDate, setEndDate] = useState(
    moment().endOf('day') as Moment | null,
  );

  return (
    <PageContainer>
      <PageHeadLine title="Article" tag="BETA">
        <SmartDateRangePicker
          startIndex={1}
          onDateChange={(start, end) => {
            setStartDate(start);
            setEndDate(end);
          }}
        />
      </PageHeadLine>
      <PageContent hasTitle>
        <Wrap>
          <FutureFactChart
            infoText="This metric tracks the number of people who have viewed articles in your help center. It provides insights into the popularity and relevance of your help center content. By analyzing this data, you can identify which articles are frequently accessed and which may need improvement or further promotion. This information is valuable for optimizing your help center to better serve your users' needs and inquiries."
            future={getFactStatistic({
              projectId: projectId,
              query: {
                chartType: FactTypes.HELP_CENTER_ARTICLE_VIEW_COUNT,
                startDate: startDate?.toDate(),
                endDate: endDate?.toDate(),
              },
            })}
          />
        </Wrap>
        <FutureBarChart
          infoText="The People who viewed an article metric measures the daily count of individuals who have accessed articles in your help center. This data is crucial for assessing the level of engagement with your help center content over time. By tracking views on a daily basis, you can identify trends in user interest and see when specific articles are more frequently visited. Analyzing this information can help you determine the effectiveness of your help center content and make data-driven decisions to improve your knowledge base."
          future={getBarChartStatisic({
            projectId: projectId,
            query: {
              chartType: BarChartStatisticTypes.HELP_CENTER_ARTICLE_VIEW_COUNT,
              startDate: startDate?.toDate(),
              endDate: endDate?.toDate(),
              timezone: moment.tz.guess(),
            },
          })}
        />
        <FutureStatisticDataTable
          future={async () => {
            return getListStatistic({
              projectId: projectId,
              query: {
                chartType: StatisticListTypes.ARTICLE_VIEW_STATISTICS_LIST,
                startDate: startDate?.toDate(),
                endDate: endDate?.toDate(),
              },
            });
          }}
        />
        <FutureStatisticDataTable
          future={async () => {
            return getListStatistic({
              projectId: projectId,
              query: {
                chartType: StatisticListTypes.ARTICLE_SEARCH_RESULT_LIST,
                startDate: startDate?.toDate(),
                endDate: endDate?.toDate(),
              },
            });
          }}
        />
        <FutureStatisticDataTable
          future={async () => {
            return getListStatistic({
              projectId: projectId,
              query: {
                chartType:
                  StatisticListTypes.ARTICLE_SEARCH_WITH_NO_RESULT_LIST,
                startDate: startDate?.toDate(),
                endDate: endDate?.toDate(),
              },
            });
          }}
        />
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore')(observer(CustomerSupportArticles));
