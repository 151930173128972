import { HeadLine } from 'components/HeadLine/HeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextArea from 'components/TextArea/TextArea';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import './ManuallyAddBugModal.scss';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import BugPrioritySelection from 'components/BugPrioritySelection/BugPrioritySelection';
import { BugPriorities } from 'models/Bug';
import { inject, observer } from 'mobx-react';
import { BugStore } from 'stores/private/BugStore';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import SessionRecipientPicker from 'components/SessionRecipientPicker/SessionRecipientPicker';

interface ManuallyAddBugModalProps {
  bugStore?: BugStore;
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

const ManuallyAddBugModal = ({
  bugStore,
  modalStore,
  projectStore,
}: ManuallyAddBugModalProps) => {
  const { register, handleSubmit, formState } = useForm({
    mode: 'onChange',
  });
  const [bugImage, setBugImage] = useState('');
  const [priority, setPriority] = useState(BugPriorities.MEDIUM.value);
  const [session, setSession] = useState(undefined as any);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    const { description } = data;
    await bugStore?.createBug({
      formData: {
        description,
      },
      screenshotUrl: bugImage,
      priority,
      session: session,
    });
    modalStore!.closeModal();
    setIsLoading(false);
  };

  const typeName =
    projectStore?.currentFeedbackType?.name?.toLocaleLowerCase() ?? 'bug';

  return (
    <SizedContainer size={ContainerSizes.L}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="manually-create-bug centered"
      >
        <HeadLine title={`Add a ${typeName}`} />
        <BugPrioritySelection
          value={priority}
          onValueChange={(selectedPriority) => {
            setPriority(selectedPriority);
          }}
          className="mt-20 mb-20"
        />
        <TextArea
          inputRef={register('description', {
            required: true,
          })}
          name="description"
          placeholder="What went wrong?"
          className="mb-15"
          rows={5}
          error={formState.errors.description ? 'error' : ''}
          label="Description"
          readonly={false}
        />
        <ImageUpload
          className="manually-add-screenshot mb-20"
          label="Add screenshot"
          image={bugImage}
          editable
          uploadPath="manuallyuploaded"
          afterImageUpload={(imageURL) => {
            setBugImage(imageURL);
          }}
        />
        <SessionRecipientPicker
          placeholder="Assign reporter (optional)"
          session={session}
          onChange={(selectedSession) => {
            setSession(selectedSession);
          }}
        />
        <PrimaryButton
          submit
          className="mt-20"
          disabled={!formState.isValid}
          label="Create"
          isLoading={isLoading}
          onClick={() => {}}
        />
      </form>
    </SizedContainer>
  );
};

export default inject(
  'bugStore',
  'modalStore',
  'projectStore',
)(observer(ManuallyAddBugModal));
