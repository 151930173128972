import OutboundEmail from 'assets/icons/emailmessages.png';
import IntroCard from 'components/IntroCard/IntroCard';
import ListDataTable from 'components/ListDataTable/ListDataTable';
import { CellLink, CellLinkInternal, CellText } from 'components/ListTable/ListTable';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ProjectStore } from 'stores/private/ProjectStore';

interface EmailTemplateOverviewProps {
  projectStore?: ProjectStore;
}

const EmailTemplateOverview = ({
  projectStore,
}: EmailTemplateOverviewProps) => {
  const { projectId } = useParams();
  const navigate = useNavigate();

  const emailTemplates = projectStore?.emailTemplatesDataList.data;
  const isLoading = projectStore?.emailTemplatesDataList.isLoading;

  useEffect(() => {
    projectStore?.fetchAndSetEmailTemplates();
  }, []);

  const prepareColumns = () => {
    const baseColumns = [
      {
        child: <div>Name</div>,
        id: 'name',
      },
      {
        child: <div>Created at</div>,
        id: 'createdAt',
      },
    ];

    return baseColumns;
  };

  const dataItemBuilder = (dataItem: any, index: number) => {
    return {
      key: index.toString(),
      children: prepareColumns().map((column) => {
        if (column.id === 'name') {
          return {
            child: <CellLinkInternal link={`/projects/${projectId}/settings/email-templates/${dataItem._id}`} text={dataItem.title} />,
          };
        }

        return {
          child: <CellText text={moment(dataItem.createdAt).format('LLL')} />,
        };
      }),
    };
  };

  return (
    <PageContainer>
      <PageHeadLine title="Email templates" />
      <PageContent hasTitle>
        <IntroCard
          altBg={false}
          image={OutboundEmail}
          imagePosition="bottom center"
          headline="Email templates"
          content="Create or edit your HTML email templates. Email templates can be used in your outreach campaigns or customer support emails."
          buttonAction={() => {
            navigate(`/projects/${projectId}/settings/email-templates/new`);
          }}
          buttonText="Create email template"
        />
        <ListDataTable
          columns={prepareColumns()}
          data={emailTemplates ?? []}
          dataTableItemBuilder={dataItemBuilder}
          isLoading={isLoading}
          noDataMessage="No email templates yet."
        />
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore')(observer(EmailTemplateOverview));
