import CalendarRangePicker from 'components/CalendarRangePicker/CalendarRangePicker';
import '../../../styles/scss-variable.scss';
import './DaterangeFilterComponent.scss';
import { useEffect, useState } from 'react';
import moment from 'moment';

function DaterangeFilterComponent({ value, onValueChanged }: any) {
  const [startDate, setStartDate] = useState(null as any);
  const [endDate, setEndDate] = useState(null as any);

  useEffect(() => {
    if (value && value.gte && value.lte) {
      setStartDate(moment(value?.gte ?? new Date()));
      setEndDate(moment(value?.lte ?? new Date()));
    }
  }, []);

  useEffect(() => {
    if (!startDate || !endDate) {
      onValueChanged(null);
      return;
    }

    onValueChanged({
      gte: startDate ? startDate.startOf('day').toDate() : null,
      lte: endDate ? endDate.endOf('day').toDate() : null,
    });
  }, [startDate, endDate]);

  return (
    <div>
      <CalendarRangePicker
        startDate={startDate ?? moment()}
        endDate={endDate ?? moment()}
        onDatesChange={({ startDate, endDate }) => {
          setStartDate(startDate);
          setEndDate(endDate);
        }}
      />
    </div>
  );
}

export default DaterangeFilterComponent;
