import VideoPlaceholder from 'assets/VideoPlaceholder.png';
import './TourStep.scss';
import { generateTextFromContent } from 'pages/private/BotConfigurationPage/components/ActionEditor/ActionEditor';

interface TourStepProps {
    onClick?: any;
    className?: any;
    selected?: boolean;
    tourStep: any;
    onDelete?: any;
    currentLang?: string;
    draggableProps?: any;
}

export const TourStep = ({
    tourStep,
    onClick,
    selected,
    className,
    currentLang,
    onDelete,
    draggableProps,
}: TourStepProps) => {
    const renderDelete = () => {
        if (!onDelete) {
            return null;
        }

        return (<>
            {draggableProps && <div className='tour-drag' {...draggableProps}>
                <i className=" fa-solid fa-grip-dots-vertical"></i>
            </div>}
            <div className='tour-delete' onClick={(e) => {
                e.stopPropagation();
                onDelete();
            }}>
                <i className="fa-solid fa-trash" />
            </div>
        </>);
    }
    const renderType = () => {
        if (tourStep.type === 'video-pointer') {
            var video = tourStep.videoUrl;
            if (video && video?.localized && video?.localized[currentLang ?? 'en']) {
                video = video?.localized[currentLang ?? 'en'];
            }
            if (video && video.length > 0) {
                return <div className='tour-step-video-pointer'>
                    <video onClick={() => {
                        onClick();
                    }} src={video} autoPlay loop muted />
                    {renderDelete()}
                </div>;
            }

            return (<div className='tour-step-video-pointer'><img onClick={() => {
                onClick();
            }} src={VideoPlaceholder} alt="Video pointer" />
                {renderDelete()}
            </div>);
        }

        const message = tourStep?.message?.localized[currentLang ?? 'en'] ?? {};

        return (<div className={`tour-step-${tourStep.type}`} onClick={() => {
            onClick();
        }}>
            <div className='tour-step-text'>
                {generateTextFromContent(message)}
            </div>
            {renderDelete()}
        </div>);
    }

    return (
        <div className={`tour-step ${selected && 'tour-step--selected'}`}>
            {renderType()}
            <div className='next-badge'>
                <i className="fa-solid fa-arrow-right" />
            </div>
        </div>
    );
};
