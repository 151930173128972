import { useEffect, useState } from 'react';
import './FeatureStatusRequestFilter.scss';
import Select from 'react-select';
import { SharedBoardStorePublic } from 'stores/public/SharedBoardStorePublic';
import { inject, observer } from 'mobx-react';

interface FeatureStatusRequestFilterProps {
  value?: any;
  onValueChanged?: (value?: string) => void;
  showInternalFilter?: boolean;
  featureRequestStates: any[];
  sharedBoardStorePublic?: SharedBoardStorePublic;
}

const FeatureStatusRequestFilter = ({
  value,
  onValueChanged,
  showInternalFilter = false,
  featureRequestStates,
  sharedBoardStorePublic,
}: FeatureStatusRequestFilterProps) => {
  const [data, setData] = useState(undefined as any);

  const notDoneLabel = sharedBoardStorePublic?.translateText('notDone');
  const allLabel = sharedBoardStorePublic?.translateText('all');

  featureRequestStates = [
    ...featureRequestStates.filter((featureRequestState) => {
      if (
        (featureRequestState.key === 'OPEN' ||
          featureRequestState.key === 'CLOSED') &&
        !showInternalFilter
      ) {
        return false;
      }
      return true;
    }),
    {
      title: notDoneLabel,
      key: 'NOTDONE',
      color: '#666666',
    },
  ];
  if (showInternalFilter) {
    featureRequestStates.push({
      title: allLabel,
      key: 'ALL',
      color: '#333333',
    });
  }

  useEffect(() => {
    const filterStatus = featureRequestStates.find((x) => x.key === value);
    setData(filterStatus);
  }, [value]);

  return (
    <Select
      className="dropdown-selection feature-requests-status-filter"
      classNamePrefix="dropdown-selection"
      defaultValue={data}
      value={data}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
      menuPortalTarget={document.body}
      menuPosition="fixed"
      isSearchable={false}
      components={{
        SingleValue: (option: any) => {
          return (
            <div className="bug-prio-item">
              <div
                className="bug-prio-item-dot"
                style={{
                  backgroundColor: option.data.color,
                }}
              />
              <span>{option.data.title}</span>
            </div>
          );
        },
        Option: (option: any) => {
          return (
            <div
              className="option option-list-item bug-prio-item"
              onClick={() => {
                if (option.selectOption) {
                  option.selectOption(option.data);
                }
              }}
            >
              <div
                className="bug-prio-item-dot"
                style={{
                  backgroundColor: option.data.color,
                }}
              />
              <span>{option.data.title}</span>
            </div>
          );
        },
      }}
      onChange={(selected: any) => {
        setData(selected);
        if (onValueChanged) {
          onValueChanged(selected ? selected.key : undefined);
        }
      }}
      options={featureRequestStates}
    />
  );
};

export default inject('sharedBoardStorePublic')(
  observer(FeatureStatusRequestFilter),
);
