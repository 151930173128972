import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import { inject, observer } from 'mobx-react';
import React, { useState } from 'react';
import { UsersStore } from 'stores/private/UsersStore';
import Switch from 'react-switch';
import { runInAction } from 'mobx';
import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper';
import './Profile2FA.scss';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import TextInput from 'components/TextInput/TextInput';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import Swal from 'sweetalert2';
import InfoBox from 'components/InfoBox/InfoBox';

interface Profile2FAProps {
  usersStore?: UsersStore;
}

const Profile2FA = ({ usersStore }: Profile2FAProps) => {
  const [isAddingWebAuthn, setIsAddingWebAuthn] = useState(false);
  const [currentwebAuthnName, setCurrentwebAuthnName] = useState('');

  const currentUser = usersStore?.currentUser;
  if (!currentUser) {
    return <></>;
  }

  const webAuthn = currentUser.twoFactorAuthentication?.methods?.webAuthn ?? [];

  const _buildAddWebAuthn = () => {
    if (!isAddingWebAuthn) {
      return (
        <PrimaryButton
          icon='plus'
          className="mt-20"
          label="Add passkey"
          onClick={() => {
            setIsAddingWebAuthn(true);
          }}
        />
      );
    }

    return (
      <Row className="mt-20">
        <TextInput
          className="mr-10"
          placeholder="Name (e.g. My iPhone)"
          onChange={(val) => {
            setCurrentwebAuthnName(val);
          }}
        />

        <PrimaryButton
          label="Add"
          disabled={currentwebAuthnName.length === 0}
          onClick={async () => {
            setIsAddingWebAuthn(false);
            await usersStore?.enable2FA({ name: currentwebAuthnName });
            setCurrentwebAuthnName('');
          }}
        />
      </Row>
    );
  };

  return (
    <PageContainer>
      <PageHeadLine title="2FA" />
      <PageContent hasTitle isSmallBoxed>
        {webAuthn.length > 0 ? <InfoBox icon='shield-check' className='info-box--success'>
          🔐 2FA with passkeys has been enabled for your account. Thank you for prioritizing your account safety.
        </InfoBox> : <InfoBox>
          Boost your account's security with Passkeys! Now supported on iPhones, Android phones, and contemporary browsers.<br /><b>🔐 Add your passkeys below to secure your account.</b>
        </InfoBox>}
        <ComponentWrapper
          title="Passkeys"
          className="security-keys-wrapper mt-30"
        >
          {webAuthn.map((item, index) => {
            return (
              <Column>
                <Row justifyContent="space-between" alignItems="center">
                  <p key={item.name}>{item.name ?? 'No name set'}</p>
                  <div
                    className="delete-item"
                    onClick={() => {
                      Swal.fire({
                        title: 'Are you sure?',
                        text: 'You will not be able to recover this key once deleted!',
                        showCancelButton: true,
                        confirmButtonText: `Yes`,
                        denyButtonText: `No`,
                      }).then(async (result) => {
                        if (result.isConfirmed) {
                          await usersStore?.delete2FADevice(item._id);
                        }
                      });
                    }}
                  >
                    <i className="fa-solid fa-trash" />
                  </div>
                </Row>
                {index < webAuthn.length - 1 && <div className="spacer-line" />}
              </Column>
            );
          })}
          {webAuthn.length === 0 && (<div className='text'>
            <p>You have no security keys added yet. <b>2FA is currently disabled.</b></p>
          </div>)}
          {_buildAddWebAuthn()}
        </ComponentWrapper>
      </PageContent>
    </PageContainer>
  );
};

export default inject('usersStore')(observer(Profile2FA));
