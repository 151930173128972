import React from 'react';
import './SubTicketList.scss';
import SidebarFoldable from 'components/SidebarFoldable/SidebarFoldable';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import { inject, observer } from 'mobx-react';
import { Bug } from 'models/Bug';
import { unlinkTicket, updateBug } from 'services/BugHttpService';
import { ProjectStore } from 'stores/private/ProjectStore';
import { BugStore } from 'stores/private/BugStore';

interface SubTicketListProps {
  ticket: Bug;
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
  bugStore?: BugStore;
}

const SubTicketList = ({
  ticket,
  modalStore,
  projectStore,
  bugStore,
}: SubTicketListProps) => {
  const tasks = bugStore?.currentTicketTasks ?? [];

  const refreshCurrentTicketTasks = async () => {
    bugStore?.loadTasksForTicket(ticket.id, ticket.project);
  };

  return (
    <SidebarFoldable
      className={`sub-ticket-list ${!ticket.parentTicket && 'no-border-top'}`}
      title="Tasks"
      infoKey="tasks"
      tag={tasks.length.toString()}
      isOpen={tasks.length > 0}
    >
      {tasks.map((subTicket, index) => {
        return (
          <>
            <Row className="sub-task-list-item">
              <Row>
                {subTicket.status === 'DONE' ? (
                  <i className="done-icon fa-regular fa-circle-check"></i>
                ) : (
                  <i
                    className="not-done-icon fa-regular fa-circle-check"
                    onClick={async () => {
                      const updatedBug: any = await updateBug(subTicket.id, {
                        status: 'DONE',
                      });

                      tasks[index] = updatedBug.data;

                      projectStore?.locallyUpdateBug(subTicket.id, {
                        status: 'DONE',
                      });
                    }}
                  ></i>
                )}
                <div
                  className="task-title ml-10"
                  onClick={() => {
                    projectStore?.openFeedbackItem({
                      shareToken: subTicket.shareToken,
                    });
                  }}
                >
                  {subTicket.title ?? `#${subTicket.bugId}`}
                </div>
              </Row>
              <i
                onClick={async () => {
                  await unlinkTicket({ ticketToLinkId: subTicket.id });
                  refreshCurrentTicketTasks();
                  projectStore?.refreshBugsForCurrentProject();
                }}
                className="delete-icon fa-regular fa-xmark"
              />
            </Row>
            {index !== tasks.length - 1 && <div className="sub-task-divider" />}
          </>
        );
      })}
      <Row>
        <div
          className="add-task-button mt-5"
          onClick={() => {
            modalStore?.openModal(MODALTYPE.CREATE_TICKET, {
              parentTicket: ticket.id,
              sessionId: ticket.session?.id,
            });
          }}
        >
          <i className="fa-solid fa-plus" /> Create task
        </div>
        <div
          className="add-task-button mt-5 ml-10"
          onClick={() => {
            modalStore?.openModal(MODALTYPE.LINK_TASK, {
              onFinished: refreshCurrentTicketTasks,
            });
          }}
        >
          <i className="fa-solid fa-link" /> Link task
        </div>
      </Row>
    </SidebarFoldable>
  );
};

export default inject(
  'modalStore',
  'projectStore',
  'bugStore',
)(observer(SubTicketList));
