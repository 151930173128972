import FutureFactChart from 'components/Charts/FactChart/FutureFactChart';
import FutureBarChart from 'components/Charts/StatisicBarChart/FutureStatisticBarChart';
import Wrap from 'components/LayoutComponents/WrapComponent/WrapComponent';
import NewsCard from 'components/NewsCardPreview/NewsCardPreview';
import { getLanguageProperty } from 'helper/AssignObjectKeysHelper';
import { inject, observer } from 'mobx-react';
import { BarChartStatisticTypes, FactTypes } from 'models/Chart';
import moment from 'moment';
import { useNavigate } from 'react-router';
import {
  getBarChartStatisic,
  getFactStatistic,
} from 'services/StatisticsService';
import { ProjectStore } from 'stores/private/ProjectStore';
import './ProjectOutboundNewsOverview.scss';

interface ProjectOutboundNewsOverviewProps {
  projectStore?: ProjectStore;
  outboundRule: any;
  startDate?: Date | null;
  endDate?: Date | null;
}

const ProjectOutboundNewsOverview = ({
  projectStore,
  outboundRule,
  startDate,
  endDate,
}: ProjectOutboundNewsOverviewProps) => {
  const navigate = useNavigate();
  const currentLang = projectStore?.currentLanguage ?? 'en';
  const projectId = projectStore?.currentProject?.id;

  return (
    <div>
      <div className="widget-preview widget-preview--outbound-news">
        <div className="widget-preview-browser">
          <div className="widget-preview-browser-head">
            <div className="widget-preview-browser-head-close" />
            <div className="widget-preview-browser-head-minimize" />
            <div className="widget-preview-browser-head-maximize" />
          </div>
          <div className="widget-preview-browser-body">
            <NewsCard
              onClick={() => {
                navigate(
                  `/projects/${projectId}/outbound/${outboundRule.id}/edit`,
                );
              }}
              news={{
                coverImageUrl: outboundRule.coverImageUrl,
                message: getLanguageProperty(
                  outboundRule,
                  'message',
                  currentLang,
                ),
                subject: getLanguageProperty(
                  outboundRule,
                  'subject',
                  currentLang,
                ),
                config: {
                  pinned: outboundRule.pinned,
                },
              }}
            />
          </div>
        </div>
      </div>
      {startDate && endDate && (
        <>
          <Wrap className="mt-30">
            <FutureFactChart
              infoText="This metric shows the number of news article views during the selected date range."
              future={getFactStatistic({
                projectId: projectId,
                query: {
                  chartType: FactTypes.NEWS_VIEW_COUNT,
                  startDate: moment(startDate).toDate(),
                  endDate: moment(endDate).toDate(),
                  timezone: moment.tz.guess(),
                  outbound: outboundRule.id,
                },
              })}
            />
          </Wrap>
          <FutureBarChart
            infoText="This metric shows the number of news article views over time."
            future={getBarChartStatisic({
              projectId: projectId,
              query: {
                chartType: BarChartStatisticTypes.NEWS_VIEW_COUNT,
                startDate: moment(startDate).toDate(),
                endDate: moment(endDate).toDate(),
                timezone: moment.tz.guess(),
                outbound: outboundRule.id,
              },
            })}
          />
        </>
      )}
    </div>
  );
};

export default inject(
  'projectStore',
  'modalStore',
)(observer(ProjectOutboundNewsOverview));
