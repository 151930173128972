import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { PropertyStore } from 'stores/private/PropertyStore';

interface PropertySelectProps {
  propertyStore?: PropertyStore;
  feedbackType?: string;
  selectedItemId: string;
  onChange: (item: any) => void;
  className?: any;
  label?: string;
  excludeScreenshot?: boolean;
  useId?: boolean;
  contactPropertiesFirst?: boolean;
  isClearable?: boolean;
}

const PropertySelect = ({
  propertyStore,
  feedbackType,
  selectedItemId,
  onChange,
  className,
  label = 'Attribute',
  useId = false,
  excludeScreenshot = false,
  contactPropertiesFirst = false,
  isClearable,
}: PropertySelectProps) => {
  const [properties, setProperties] = useState<any[]>([]);
  const isGrouped = feedbackType === undefined;
  const propertiesFlat = isGrouped
    ? properties.reduce((accumulator, current) => {
        return accumulator.concat(current.options);
      }, [])
    : properties;

  useEffect(() => {
    if (feedbackType) {
      const identifierProperties =
        propertyStore?.getProjectPropertiesForType(feedbackType) ?? [];
      const filteredIdentifierProperties = identifierProperties.filter(
        (item) => {
          return !excludeScreenshot || item.fieldId !== 'screenshotUrl';
        },
      );

      setProperties(JSON.parse(JSON.stringify(filteredIdentifierProperties)));
    } else {
      const allProperties =
        propertyStore?.getProjectProperties(contactPropertiesFirst) ?? [];

      setProperties(JSON.parse(JSON.stringify(allProperties)));
    }
  }, [feedbackType, propertyStore?.currentProjectProperties]);

  const identifier = useId ? '_id' : 'fieldId';
  const selectedItem = propertiesFlat?.find((item) => {
    return item[identifier] === selectedItemId;
  });

  return (
    <SelectDropDown
      className={className}
      label={label}
      placeholder="Select attribute"
      labelPropertyName="label"
      valuePropertyName={identifier}
      grouped={isGrouped}
      items={properties ?? []}
      selectedItem={selectedItem}
      onChange={onChange}
      isClearable={isClearable}
    />
  );
};

export default inject('propertyStore')(observer(PropertySelect));
