import UserAvatar from 'components/UserAvatar/UserAvatar';
import { getDataDescription } from 'helper/FormDataHelper';
import TimeAgo from 'react-timeago';
import { Bug, BugPriorities } from '../../models/Bug';
import './BoardListViewItem.scss';
import moment from 'moment';
import PublicSkeleton from 'components/Skeletons/PublicSkeleton';
import { formatDateYearLater } from 'helper/DateHelper';
import { PropertyStore } from 'stores/private/PropertyStore';
import { inject, observer } from 'mobx-react';

export function stripHtml(html) {
  let tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
}

interface BoardListViewItemProps {
  item: Bug;
  onClick?: any;
  active?: boolean;
  propertyStore?: PropertyStore;
}

const BoardListViewItem = ({
  item,
  onClick,
  active,
  propertyStore,
}: BoardListViewItemProps) => {
  const dueDate = item.dueDate ? moment(item.dueDate) : null;
  const isDue =
    item.status !== 'DONE' && dueDate
      ? dueDate.isBefore(moment(), 'date')
      : false;
  const isDueToday =
    item.status !== 'DONE' && dueDate
      ? dueDate.isSame(moment(), 'date')
      : false;

  const renderDueDate = () => {
    if (!dueDate) {
      return null;
    }

    return (
      <div
        className={`due-date ${isDue && 'due-date--isdue'} ${
          isDueToday && 'due-date--isduetoday'
        }`}
      >
        <i className="fa-solid fa-clock-three" />
      </div>
    );
  };

  const renderCommentPreview = () => {
    return (
      <div className="inbox-item-body">
        <div className="bug-report-card__date">
          {formatDateYearLater(item.createdAt)}, #{item.bugId} {renderDueDate()}
        </div>
        <div className="bug-report-card__time">
          <TimeAgo
            date={item.lastNotification ?? item.createdAt}
            formatter={(value, unit, suffix) => {
              return `${value}${unit.charAt(0)}`;
            }}
          />
        </div>
      </div>
    );
  };

  const isOnline = item?.session?.lastActivity
    ? (Date.now() - Date.parse(item?.session?.lastActivity)) / 60000 < 2
    : false;

  const isSnoozed = item.status === 'SNOOZED' && item.snoozedUntil;
  const unreadSnoozed =
    !isSnoozed && item.notificationsUnread && item.snoozedUntil;
  const unread = !isSnoozed && item.notificationsUnread && !item.snoozedUntil;

  return (
    <div
      className={`inbox-item ${active ? 'inbox-item--active' : ''} ${
        unread ? 'inbox-item--unread' : ''
      } ${unreadSnoozed ? 'inbox-item--unread-snoozed' : ''} ${
        isSnoozed ? 'inbox-item--snoozed' : ''
      }`}
      onClick={() => {
        if (onClick) {
          onClick(item);
        }
      }}
    >
      <div className="inbox-item-header">
        <div className="inbox-item-header-userinfo">
          <UserAvatar
            isOnline={isOnline}
            email={
              item.session?.email ? item.session?.email : item.session?.gleapId
            }
          />
          <div className="userinfo">
            <div className="userinfo__title">
              {item.title
                ? item.title
                : getDataDescription({
                    data: item,
                    maxLength: 100,
                    propertyStore,
                    feedbackType: item.type,
                  })}
            </div>
          </div>
          <div
            className="bug-report-card__header__info__dot"
            style={{
              backgroundColor: `${BugPriorities[item.priority]?.color}20`,
              color: `${BugPriorities[item.priority]?.color}`,
            }}
          >
            {BugPriorities[item.priority]?.title}
          </div>
          {item.processingUser && (
            <div className="user-processing">
              <UserAvatar
                small
                email={item.processingUser.email}
                imageUrl={item.processingUser.profileImageUrl}
              />
            </div>
          )}
          {(isSnoozed || unreadSnoozed) && (
            <div className="snoozed-badge">
              {isSnoozed && (
                <span className="time">
                  {moment(item.snoozedUntil).format(
                    moment(item.snoozedUntil).isSame(moment(), 'day')
                      ? 'h:mm A'
                      : 'MMM DD',
                  )}
                </span>
              )}
              <i className="unread-snoozed-icon fa-solid fa-moon" />
            </div>
          )}
        </div>
      </div>
      {renderCommentPreview()}
    </div>
  );
};

export default inject('propertyStore')(observer(BoardListViewItem));

export const InboxItemSkeleton = () => {
  return (
    <div className="inbox-item inbox-item--skeleton">
      <div className="inbox-item-header">
        <div className="inbox-item-header-userinfo">
          <PublicSkeleton
            width="26px"
            height="26px"
            style={{
              borderRadius: '100%',
            }}
          />
          <div className="userinfo">
            <PublicSkeleton height="18px" width="160px" />
          </div>
        </div>
      </div>
      <div className="inbox-item-body">
        <PublicSkeleton
          height="14px"
          width="200px"
          style={{ marginLeft: '5px' }}
        />
      </div>
    </div>
  );
};
