import classNames from 'classnames';
import {
  Action,
  ActionFlow,
  ActionFlowNode,
  ActionTypes,
  Button,
  ButtonAction,
  Condition,
  ConditionChild,
} from 'models/Bot';
import React, { useState } from 'react';
import { generateTextFromContent } from '../ActionEditor/ActionEditor';
import { runInAction } from 'mobx';
import { getDefaultTranslation } from 'stores/Translations';
import { ProjectStore } from 'stores/private/ProjectStore';
import { inject, observer } from 'mobx-react';
import {
  getLanguageProperty,
  setLanguageProperty,
} from 'helper/AssignObjectKeysHelper';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { convertTipTapToPlainText } from 'helper/TipTapHelper';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { Handle, Position } from 'reactflow';
import { botActionTypes } from '../BotActionAddOptions/BotActionAddOptions';
import { PropertyStore } from 'stores/private/PropertyStore';
import { operatorOptions } from 'components/BotConditionTag/BotConditionTag';

interface ActionEditorItemProps {
  data: ActionFlowNode;
  action: Action;
  currentActionIndex: number;
  projectStore?: ProjectStore;
  propertyStore?: PropertyStore;
  modalStore?: ModalStore;
}

const ActionEditorItem = ({
  data,
  action,
  currentActionIndex,
  projectStore,
  propertyStore,
  modalStore,
}: ActionEditorItemProps) => {
  const [toggleState, setToggleState] = useState(false);

  const currentLang = projectStore?.currentLanguage ?? 'en';

  const actionItemClass = classNames(
    {
      'action-item-container--buttons': action.type === ActionTypes.BUTTONS,
      'action-item-container--condition': action.type === ActionTypes.CONDITION,
    },
    'action-item-container',
  );

  const properties = propertyStore?.getProjectProperties();
  const dataTypeOptions = [
    ...(properties ?? []).map((property) => {
      return {
        label: property.label,
        value: property.key,
        icon: property.icon ?? 'ticket',
        isProperty: true,
        options: property.options ?? [],
      };
    }),
    {
      label: 'Office hours',
      value: 'office-hours',
      icon: 'calendar',
      isProperty: false,
      options: [
        {
          label: 'During office hours',
          id: 'during-office-hours',
        },
        {
          label: 'Outside office hours',
          id: 'outside-office-hours',
        },
      ],
    },
  ];

  const propertiesFlat: any[] = dataTypeOptions.reduce(
    (accumulator, current) => {
      return accumulator.concat(current.options);
    },
    [],
  );

  const openActionEditor = (action: Action) => {
    modalStore?.openModal(MODALTYPE.ACTIONEDITOR, {
      action,
    });
  };

  const _buildTypeButtons = (action: Action) => {
    return (
      <div className="button-actions">
        {(action as Button).buttons?.map(
          (button: ButtonAction, index: number) => {
            const currentButtonActionFlow =
              projectStore?.bot?.actionFlows?.find(
                (af) => af.id === button.action?.actionFlow,
              );

            return (
              <div className="button-action" key={index}>
                <input
                  key={index}
                  className="button-input"
                  value={getLanguageProperty(button, 'text', currentLang)}
                  onChange={(event) => {
                    runInAction(() => {
                      setLanguageProperty(
                        button,
                        'text',
                        currentLang,
                        event.target.value,
                      );
                    });
                  }}
                />
                <div
                  className="delete-button ml-5 mr-5"
                  onClick={() => {
                    runInAction(() => {
                      const buttonIndex = (action as Button).buttons?.indexOf(
                        button,
                      );
                      if (buttonIndex !== undefined && buttonIndex !== -1) {
                        (action as Button).buttons?.splice(buttonIndex, 1);
                      }
                    });
                    setToggleState(!toggleState);
                  }}
                >
                  <i className="fa-solid fa-trash"></i>
                </div>
                <Handle
                  className="button-handle"
                  type="source"
                  position={Position.Right}
                  id={`${data.id}-buttons-${index}`}
                  onClick={() => {
                    // Check if the button action flow still exists
                    if (
                      button.action &&
                      button.action.actionFlow &&
                      currentButtonActionFlow
                    ) {
                      return;
                    }

                    const actionFlowNode: ActionFlowNode = {
                      id: `af-${Math.floor(Date.now() * 1000)}`,
                      type: 'action',
                      nodeType: 'defaultAction',
                      actions: [],
                      position: {
                        x: data?.position?.x ? data.position.x + 500 : 500,
                        y: data?.position?.y ?? 0,
                      },
                    };

                    (button.action as ActionFlow) = {
                      type: ActionTypes.ACTIONFLOW,
                      actionFlow: actionFlowNode.id,
                      action: 0,
                    };

                    projectStore?.addActionFlowNode(actionFlowNode);
                  }}
                >
                  {button.action &&
                  button.action.actionFlow &&
                  currentButtonActionFlow ? (
                    <i className="fa-sharp fa-solid fa-arrow-right" />
                  ) : (
                    <i className="icon fa-sharp fa-solid fa-plus" />
                  )}
                </Handle>
              </div>
            );
          },
        )}
        <div className="add-button-action">{_buildAddButtonAction(action)}</div>
      </div>
    );
  };

  const _buildTypeCondition = (action: Action) => {
    if (!action.conditions) {
      action.conditions = [];
    }
    return (
      <>
        <div className="conditions-header">
          <div className="icon-container">
            <i className="fa-solid fa-option" />
          </div>
          <div className="conditions-header-title ml-5">Conditions</div>
        </div>
        <div className="condition-actions">
          {(action as Condition).conditions.map(
            (condition: ConditionChild, index: number) => {
              return (
                <div
                  key={index}
                  className="condition-item"
                  onClick={() => {
                    openActionEditor({
                      ...condition,
                      type: ActionTypes.CONDITION,
                    });
                  }}
                >
                  <div className="text">
                    {_getConditionLabel(condition)}
                    <div
                      className="condition-hover-button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        runInAction(() => {
                          (action as Condition).conditions.splice(index, 1);
                        });
                      }}
                    >
                      <i className="fa-solid fa-trash" />
                    </div>
                  </div>
                  <Handle
                    className="condition-handle"
                    type="source"
                    position={Position.Right}
                    id={`${data.id}-conditions-${index}`}
                    onClick={() => {
                      const actionFlowNode: ActionFlowNode = {
                        id: `af-${Math.floor(Date.now() * 1000)}`,
                        type: 'action',
                        nodeType: 'defaultAction',
                        actions: [],
                        position: {
                          x: data?.position?.x ? data.position.x + 500 : 500,
                          y: data?.position?.y ?? 0,
                        },
                      };

                      condition.actionFlow = actionFlowNode.id;
                      condition.action = 0;

                      projectStore?.addActionFlowNode(actionFlowNode);
                    }}
                  >
                    <i className="fa-sharp fa-solid fa-arrow-right" />
                  </Handle>
                </div>
              );
            },
          )}
          {_buildAddConditionAction(action)}
        </div>
        <div className="condition-item-else">
          ELSE
          <div className="else-stroke" />
          <Handle
            className="condition-handle"
            type="source"
            position={Position.Right}
            id={`${data.id}-conditions-else`}
            onClick={() => {
              const actionFlowNode: ActionFlowNode = {
                id: `af-${Math.floor(Date.now() * 1000)}`,
                type: 'action',
                nodeType: 'defaultAction',
                actions: [],
                position: {
                  x: data?.position?.x ? data.position.x + 500 : 500,
                  y: data?.position?.y ?? 0,
                },
              };

              (action as Condition).fallbackAction = {
                type: ActionTypes.ACTIONFLOW,
                actionFlow: actionFlowNode.id,
                action: 0,
              };

              projectStore?.addActionFlowNode(actionFlowNode);
            }}
          >
            <i className="fa-sharp fa-solid fa-arrow-right" />
          </Handle>
        </div>
      </>
    );
  };

  const _buildDefaultAction = (action: Action) => {
    const botAction = botActionTypes.find((type) => type.value === action.type);
    if (!botAction) return <div>{JSON.stringify(action)}</div>;

    return (
      <div
        className="bot-action-option-item"
        onClick={() => {
          openActionEditor(action);
        }}
      >
        <div
          className="bot-action-option-item-icon"
          style={{
            backgroundColor: botAction.color,
          }}
        >
          <i className={`fa-solid fa-${botAction.icon}`}></i>
        </div>
        <div className="bot-action-option-item-title">
          {getContentForType(action) || botAction.label}
          {action.title && (
            <span className="bot-action-option-item-subtitle">
              {getDefaultTranslation(action.title, currentLang)}
            </span>
          )}
          {action?.data?.content && (
            <span className="bot-action-option-item-subtitle">
              {generateTextFromContent(
                getDefaultTranslation(action?.data?.content, currentLang),
              )}
            </span>
          )}
          {action?.actionName && (
            <span className="bot-action-option-item-subtitle">
              {action.actionName}
            </span>
          )}
          {action?.url && (
            <span className="bot-action-option-item-subtitle">
              {action.url}
            </span>
          )}
        </div>
      </div>
    );
  };

  const _buildAddButtonAction = (action) => {
    return (
      <div className="add-action-container-outer">
        <div
          className="add-action-container mt-10 mb-20"
          onClick={() => {
            runInAction(() => {
              if (!(action as Button).buttons) {
                (action as Button).buttons = [];
              }
              (action as Button).buttons.push({
                text: {
                  localized: { [currentLang]: 'New button option' },
                },
                action: {} as any,
              });
            });
            setToggleState(!toggleState);
          }}
        >
          <div className="line" />
          <i className="icon fa-solid fa-circle-plus" />
          <div className="line" />
        </div>
        <div
          className="add-action-container mt-10 mb-20"
          onClick={() => {
            runInAction(() => {
              if (
                (action as Button).buttons &&
                (action as Button).buttons.length > 0
              ) {
                (action as Button).buttons.pop();
              }
            });
            setToggleState(!toggleState);
          }}
        ></div>
      </div>
    );
  };

  const _buildAddConditionAction = (action) => {
    return (
      <div className="add-action-container-outer ml-20">
        <div
          className="add-action-container mt-10 mb-20"
          onClick={() => {
            runInAction(() => {
              if (!(action as Condition).conditions) {
                (action as Condition).conditions = [];
              }
              (action as Condition).conditions.push({
                type: ActionTypes.ACTIONFLOW,
                actionFlow: `af-${Math.floor(Date.now() * 1000)}`,
                action: 0,
                predicates: {
                  type: 'and',
                  predicates: [
                    {
                      type: 'and',
                      predicates: [{}],
                    } as any,
                  ],
                },
              });
            });
            setToggleState(!toggleState);
          }}
        >
          <div className="line" />
          <i className="icon fa-solid fa-circle-plus" />
          <div className="line" />
        </div>
      </div>
    );
  };

  const _getConditionLabel = (condition: ConditionChild) => {
    let label: any[] = [];

    if (
      condition.predicates == null ||
      condition.predicates.predicates?.length === 0
    ) {
      return 'Edit condition';
    }

    condition.predicates!.predicates!.forEach((predicate, index) => {
      if (predicate.predicates == null || predicate.predicates.length === 0) {
        return;
      }

      predicate.predicates.forEach((childPredicate, childIndex) => {
        const selectedProperty = propertiesFlat.find((property) => {
          return property.id === childPredicate.attribute;
        });
        if (selectedProperty) {
          label.push(selectedProperty.label);
        } else {
          // Data type
          if (childPredicate.dataType != null) {
            label.push(childPredicate.dataType);
          }

          // Attribute
          if (childPredicate.attribute != null) {
            if (childPredicate.attribute.includes('.')) {
              const attribute = childPredicate.attribute.split('.');
              label.push(` ${attribute[1]}`);
            } else {
              label.push(` ${childPredicate.attribute}`);
            }
          }
        }

        // Operator
        if (childPredicate.operator != null) {
          const operatorLabel = operatorOptions.find(
            (option) => option.value === childPredicate.operator,
          )?.label;

          if (operatorLabel != null) {
            label.push(
              <span key="operatorLabel" className="operator-label">
                {' '}
                {operatorLabel}{' '}
              </span>,
            );
          }
        }

        // Value
        if (
          childPredicate.value != null &&
          childPredicate.dataType !== 'office-hours'
        ) {
          label.push(` ${childPredicate.value}`);
        }

        // And/Or
        if (childIndex < predicate.predicates!.length - 1 && predicate.type) {
          label.push(` ${predicate.type} `);
        }

        // Wrap the array of elements in a parent span element
        return <span>{label}</span>;
      });

      // And/Or
      if (
        index < condition.predicates!.predicates!.length - 1 &&
        condition.predicates.type
      ) {
        label.push(` ${condition.predicates.type}`);
      }
    });

    if (label.length === 0) {
      return 'Edit condition';
    }

    return label;
  };

  const getContentForType = (action: Action) => {
    if (action.type === ActionTypes.MESSAGE) {
      try {
        var content = convertTipTapToPlainText({
          content: action?.data?.content,
        });

        return content;
      } catch (exp) {}
    }

    if (action.type === ActionTypes.TICKETUPDATE_ASSIGN) {
      const user = projectStore?.currentProjectUsers.find(
        (o) => o.id === action?.processingUser,
      );
      if (user) {
        return (
          <>
            <div className="bot-action-option-item-title">Assign ticket to</div>
            <div className="action-ticket-assign">
              <UserAvatar
                small
                email={user.email}
                imageUrl={user.profileImageUrl}
              />
              {user.firstName} {user.lastName}
            </div>
          </>
        );
      }
    }

    if (action.type === ActionTypes.TICKETUPDATE_TAG) {
      return (
        <>
          <div className="bot-action-option-item-title">Tag ticket with</div>
          <div className="action-ticket-tags">
            {(action?.tags ?? []).map((tag, index) => {
              return (
                <span className="tag" key={index}>
                  {tag}
                </span>
              );
            })}
          </div>
        </>
      );
    }

    if (action.type === ActionTypes.TICKETUPDATE_AITAG) {
      return (
        <>
          <div className="bot-action-option-item-title">
            Tag ticket based on content
          </div>
        </>
      );
    }

    if (action.type === ActionTypes.ANSWERBOTFLOW) {
      return (
        <>
          <div className="action-answerbot">Pass to Kai with question</div>
          <span className="bot-action-option-item-subtitle">
            {getLanguageProperty(action, 'question', currentLang)}
          </span>
        </>
      );
    }

    return null;
  };

  const _getActionComponent = (action: Action) => {
    switch (action.type) {
      case ActionTypes.BUTTONS:
        return _buildTypeButtons(action);

      case ActionTypes.CONDITION:
        return _buildTypeCondition(action);

      default:
        return _buildDefaultAction(action);
    }
  };

  return (
    <div className={actionItemClass}>
      {_getActionComponent(action)}
      <div
        className="hover-button"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          runInAction(() => {
            data.actions.splice(currentActionIndex, 1);
          });
        }}
      >
        <i className="fa-solid fa-trash" />
      </div>
    </div>
  );
};

export default inject(
  'projectStore',
  'propertyStore',
  'modalStore',
)(observer(ActionEditorItem));
