import slugify from 'slugify';

export default function makeUrlFriendly(value: string) {
  if (!value) {
    return ''
  }

  return slugify(value, {
    remove: /[*+~.()'"!:@/&$%#=,?^{}[\]|<>\\]/g,
    lower: true,
  });
}
