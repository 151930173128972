import SendInputEditor from 'components/SendInputEditor/SendInputEditor';
import { Title } from 'components/Title/Title';
import { inject, observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { uploadFileToServer } from 'services/FileUpload';
import { BugStore } from 'stores/private/BugStore';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './ComposeMessageModal.scss';
import { UsersStore } from 'stores/private/UsersStore';
import RecipientPicker from 'components/RecipientPicker/RecipientPicker';
import { Session } from 'models/Session';
import TextInput from 'components/TextInput/TextInput';

interface ComposeMessageModalProps {
  bugStore?: BugStore;
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
  usersStore?: UsersStore;
}

const ComposeMessageModal = ({
  bugStore,
  modalStore,
  projectStore,
  usersStore,
}: ComposeMessageModalProps) => {
  const attachmentsRef = useRef();
  const [attachments, setAttachments] = useState([] as File[]);
  const [isSending, setIsSending] = useState(false);
  const [mainSession, setMainSession] = useState(null as Session | null);
  const [ccSessions, setCCSessions] = useState([] as Session[] | null);
  const [subject, setSubject] = useState('');
  const mainSessionRef = useRef<Session | null>(null);
  const ccSessionsRef = useRef<Session[] | null>([]);
  const navigate = useNavigate();

  useEffect(() => {
    mainSessionRef.current = mainSession;
  }, [mainSession]);

  useEffect(() => {
    ccSessionsRef.current = ccSessions;
  }, [ccSessions]);

  const sendComment = async (json: any, shouldCloseConversation = false) => {
    const comment = json;
    if (!comment || comment.length === 0) {
      return;
    }

    if (!mainSessionRef.current?.id || mainSessionRef.current.id.length === 0) {
      console.error('Main session is empty.');
      return;
    }

    setIsSending(true);

    // Create new conversation.
    const bug = await projectStore?.createConversation(
      mainSessionRef.current.id,
      subject,
    );
    if (bug) {
      // Upload attachments.
      var uploadedAttachments = [] as any[];
      if (attachments) {
        for (let i = 0; i < attachments.length; i++) {
          const file = attachments[i];
          const uploadedAttachment = await uploadFileToServer(
            file,
            `${bug.id}/attachments`,
          );
          if (uploadedAttachment) {
            uploadedAttachments.push({
              url: uploadedAttachment,
              name: file.name,
              type: file.type,
            });
          }
        }
      }

      // Send comment.
      await bugStore!.addCommentToBug(
        bug.id,
        comment,
        false,
        uploadedAttachments,
      );

      if (attachmentsRef && attachmentsRef.current) {
        (attachmentsRef.current as any).clearFiles();
      }

      // Add CCs.
      const ccSessions = ccSessionsRef.current;
      if (ccSessions && ccSessions.length > 0) {
        await bugStore?.updateBug(bug?.id!, {
          sessions: ccSessions.map((s) => s.id),
        });
      }

      setAttachments([]);

      setIsSending(false);

      // Close modal.
      modalStore!.closeModal();

      // Navigate to conversation.
      navigate(
        `/projects/${projectStore?.currentProject?.id}/inquiries/${bug.shareToken}`,
      );
    } else {
      toast.error('Failed to create conversation.');
    }
  };

  return (
    <div className="compose-message-modal">
      <Title label="Start a conversation" className="mb-20" />
      <div className="compose-message-container">
        <RecipientPicker
          sessions={mainSession ? [mainSession] : []}
          initialSessionId={[modalStore?.customData?.sessionId]}
          onChange={(sessions) => {
            if (sessions && sessions[0]) {
              setMainSession(sessions[0]);
            } else {
              setMainSession(null);
            }
          }}
        />
        <RecipientPicker
          sessions={ccSessions}
          onChange={(sessions) => {
            setCCSessions(sessions);
          }}
          label="CC:"
          multipleSessions
        />
        <div className="input-row">
          {<p className="m-0">Subject:</p>}
          <TextInput
            className="subject-input"
            inputClassName="subject-input"
            placeholder="Enter subject"
            type="text"
            error=""
            value={subject}
            onChange={(text) => {
              setSubject(text);
            }}
          />
        </div>
        <SendInputEditor
          isSending={isSending}
          inputTypes={['Reply']}
          currentInputType={'Reply'}
          disableEmailFallback={
            projectStore?.currentProject?.disableEmailFallback ?? false
          }
          onFilesSelected={(files) => {
            setAttachments([...files, ...attachments]);
          }}
          attachmentsUpdated={(files) => {
            setAttachments([...files]);
          }}
          variables={{
            project: projectStore?.currentProject ?? {},
            session: bugStore?.currentBug?.session ?? mainSession ?? {},
            user: usersStore?.currentUser ?? {},
          }}
          attachmentsRef={attachmentsRef}
          attachments={attachments}
          onClickSend={sendComment}
          showAttachments={true}
          inputContentChanged={(json) => {}}
          hideTabButtons={true}
          inputPlaceholder={'Write a message...'}
          mentions={
            projectStore?.currentProjectUsers
              ? projectStore?.currentProjectUsers.map((userItem) => {
                  return {
                    label: `${userItem.firstName} ${userItem.lastName}`,
                    id: userItem.id,
                    email: userItem.email,
                    profileImageUrl: userItem.profileImageUrl,
                  };
                })
              : []
          }
        />
      </div>
    </div>
  );
};

export default inject(
  'bugStore',
  'modalStore',
  'projectStore',
  'usersStore',
)(observer(ComposeMessageModal));
