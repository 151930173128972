import classNames from 'classnames';
import InfoBox from 'components/InfoBox/InfoBox';
import Grid from 'components/LayoutComponents/GridComponent/grid.component';
import LinkButton from 'components/LinkButton/LinkButton';
import PropertyInput from 'components/PropertyComponents/PropertyInput/PropertyInput';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { inject, observer } from 'mobx-react';
import { Bug } from 'models/Bug';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { getCountryByCode } from 'services/CountryHelper';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { PropertyStore } from 'stores/private/PropertyStore';
import { SessionStore } from 'stores/private/SessionStore';
import Swal from 'sweetalert2';
import './SessionDetailsShort.scss';
import { useNavigate } from 'react-router';
import SessionMuteButton from 'components/SessionMuteButton/SessionMuteButton';

interface SessionDetailsShortProps {
  ticket?: Bug;
  shared?: boolean;
  removeSession?: (sessionId) => void;
  propertyStore?: PropertyStore;
  sessionStore?: SessionStore;
  modalStore?: ModalStore;
  onContactChanged?: (contact) => void;
}

const SessionDetailsShort = ({
  ticket,
  shared,
  removeSession,
  propertyStore,
  sessionStore,
  modalStore,
  onContactChanged,
}: SessionDetailsShortProps) => {
  const mainSession = ticket?.session;
  const navigate = useNavigate();

  const sessions = mainSession
    ? [mainSession, ...(ticket?.sessions || [])]
    : ticket?.sessions || [];

  const [currenSelectedSession, setCurrenSelectedSession] = useState(
    mainSession as any,
  );

  useEffect(() => {
    if (currenSelectedSession && onContactChanged) {
      onContactChanged(currenSelectedSession);
    }
  }, [currenSelectedSession]);

  const properties = propertyStore?.getVisiblePropertiesForType({
    feedbackType: 'SESSION',
    visabilityType: 'sidebar',
  });

  if (!currenSelectedSession) {
    return <></>;
  }

  let location = 'Unknown';
  if (currenSelectedSession.location) {
    location =
      currenSelectedSession.location.region &&
      currenSelectedSession.location.region.length > 0
        ? `${currenSelectedSession.location.region}, ${getCountryByCode(
            currenSelectedSession.location.country,
          )}`
        : getCountryByCode(currenSelectedSession.location.country);
  }

  let localTime;
  if (
    currenSelectedSession.location &&
    currenSelectedSession.location.timezone
  ) {
    localTime = moment()
      .tz(currenSelectedSession.location.timezone)
      .format('MMM DD h:mm A');
  }

  const showSessionDetails = () => {
    navigate(
      `/projects/${ticket?.project}/sessions/${currenSelectedSession?.id}`,
    );
    modalStore!.closeModal(false);
  };

  return (
    <>
      <div className="sessions-preview-wrapper">
        {sessions.map((session, index) => {
          const isSelected = currenSelectedSession?.id === session?.id;

          const overlayClassname = classNames({
            'session-preview': true,
            'session-preview--selected': isSelected,
          });

          return (
            <div className="session-preview-outer">
              <UserAvatar
                className={overlayClassname}
                email={session?.email}
                onClick={() => {
                  setCurrenSelectedSession(session);
                }}
              />
              {!shared && mainSession?.id !== session?.id && (
                <div
                  className="session-preview-remove"
                  onClick={() => {
                    Swal.fire({
                      title:
                        'Do you really want to remove this participant from the ticket?',
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonText: 'Yes',
                      cancelButtonText: 'Cancel',
                    }).then((result) => {
                      if (result.isConfirmed) {
                        if (removeSession) {
                          removeSession(session.id);

                          // Set main session as selected
                          setCurrenSelectedSession(mainSession);
                        }
                      }
                    });
                  }}
                >
                  <i className="fa-sharp fa-solid fa-xmark" />
                </div>
              )}
            </div>
          );
        })}
        <div className="session-preview-outer">
          <div
            className="session-preview session-preview--add"
            onClick={() => {
              modalStore?.openModal(MODALTYPE.ADD_SESSION_TO_TICKET);
            }}
          >
            <i className="fa-solid fa-plus" />
          </div>
        </div>
      </div>
      {!shared &&
        currenSelectedSession.unsubscribed &&
        currenSelectedSession.unsubscribedAt && (
          <InfoBox className="info-box--error mb-10 mt-10">
            This contact unsubscribed from your emails:
            {currenSelectedSession.unsubscribeReason && (
              <>
                <b> {currenSelectedSession.unsubscribeReason}</b>
              </>
            )}
            <div className="sidebar-more-button" onClick={() => {}}>
              Open contact to resubscribe
            </div>
          </InfoBox>
        )}
      <Grid className="mb-10" templateColumns="1fr 2fr">
        {properties?.map((property) => {
          if (shared && !property.visability.sharedSidebar) {
            return <></>;
          }

          return (
            <>
              <div className="session-data-field-label">
                {property.labelIcon && (
                  <i className={`mr-5 fa-solid ${property.labelIcon}`} />
                )}
                {property.label}
              </div>
              <div className="session-data-field-value">
                <PropertyInput
                  shared={shared}
                  data={currenSelectedSession}
                  property={property}
                  onSetData={(data) => {
                    sessionStore?.updateSession(currenSelectedSession.id, data);
                  }}
                />
              </div>
            </>
          );
        })}
        {!shared && (
          <>
            <>
              <div className="session-data-field-label">
                <i className="mr-5 fa-solid fa-map-marker" />
                Location
              </div>
              <div className="session-data-field-value">
                <div className="text-input-preview-container">{location}</div>
              </div>
            </>
            {localTime && (
              <>
                <div className="session-data-field-label">
                  <i className="mr-5 fa-solid fa-clock" />
                  Time
                </div>
                <div className="session-data-field-value">
                  <div className="text-input-preview-container">
                    {localTime}
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {!shared && currenSelectedSession?.blocked && (
          <>
            <div className="session-data-field-label">
              <i className="mr-5 fa-solid fa-volume-mute" />
              Muted
            </div>
            <div className="session-data-field-value">
              <div className="text-input-preview-container">⛔️</div>
            </div>
          </>
        )}
      </Grid>
      <div className="contact-details-card card session-details-short">
        <div className="contact-details-card-rows">
          {!shared && (
            <>
              <SessionMuteButton session={currenSelectedSession} />
              <LinkButton
                icon="id-card-clip"
                className="mt-10 bfw"
                iconSideRight={false}
                label="Contact details"
                onClick={() => {
                  showSessionDetails();
                }}
              />
            </>
          )}
        </div>
      </div>
      <ReactTooltip
        id="sessionDetailsTooltip"
        className="infoTooltip"
        delayHide={300}
        type="light"
        effect="solid"
        afterHide={() => {
          const tooltipNode = document.getElementById('sessionDetailsTooltip');
          if (tooltipNode) {
            const domNode = ReactDOM.findDOMNode(tooltipNode) as HTMLElement;
            if (domNode) {
              domNode.style.left = '';
              domNode.style.top = '';
            }
          }
        }}
        getContent={(content) => {
          return (
            <div className="copy-tooltip">
              <span>{content}</span>
              <CopyToClipboard
                text={content}
                onCopy={() => {
                  toast.success('Successfully copied ✓');
                }}
              >
                <i className="fa-solid fa-clone" />
              </CopyToClipboard>
            </div>
          );
        }}
      />
    </>
  );
};

export default inject(
  'propertyStore',
  'sessionStore',
  'modalStore',
)(observer(SessionDetailsShort));
