import { inject, observer } from 'mobx-react';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { UsersStore } from 'stores/private/UsersStore';
import './OnboardingProjectOptionCard.scss';

interface OnboardingProjectOptionCardProps {
    Icon: any;
    title: string;
    description: string;
    onValueChanged: (value: boolean) => void;
    value: boolean;
}

const OnboardingProjectOptionCard = ({
    Icon,
    title,
    description,
    onValueChanged,
    value,
}: OnboardingProjectOptionCardProps) => {
    return (
        <div className={`onboarding-project-option-card ${value && 'onboarding-project-option-card--checked'}`} onClick={() => {
            onValueChanged(!value);
        }}>
            <div className='onboarding-project-option-card-header'>
                <div className='onboarding-project-option-card-icon'>
                    <Icon />
                </div>
                <div className='onboarding-project-option-card-title'>{title}</div>
                <div className='onboarding-project-option-card-checkbox'>
                    <i className={`${value ? 'fa-solid fa-square-check' : 'fa-regular fa-square'}`}></i>
                </div>
            </div>
            <div className='onboarding-project-option-card-description'>
                {description}
            </div>
        </div>
    );
};

export default OnboardingProjectOptionCard;