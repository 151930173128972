import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { inject, observer } from 'mobx-react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useEffect, useState } from 'react';
import Switch from 'react-switch';
import { ProjectStore } from 'stores/private/ProjectStore';
import './EmailSpamFilter.scss';
import InfoBox from 'components/InfoBox/InfoBox';

interface EmailSpamFilterProps {
  projectStore?: ProjectStore;
}

const EmailSpamFilter = ({
  projectStore,
}: EmailSpamFilterProps) => {
  const project = projectStore!.currentProject;
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [spamFilter, setSpamFilter] = useState(0);

  useEffect(() => {
    projectStore!.getProjectTeams();
  }, []);

  useEffect(() => {
    if (project) {
      setSpamFilter(project!.spamFilter ?? 0);
    }
  }, [project]);

  if (!project) {
    return null;
  }

  return (
    <PageContainer>
      <PageHeadLine title="Email spam filter" />
      <PageContent hasTitle isMediumBoxed className="email-settings">
        <div className="options-group">
          <div className="options-group-header">Spam filter</div>
          <div className="checkbox-settings-content">
            <div className="checkbox-settings-container mt-10">
              <div className="switch-container mb-20">
                <Switch
                  width={40}
                  onColor="#2142E7"
                  height={20}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={(checked) => {
                    if (!checked) {
                      setSpamFilter(0);
                    } else {
                      setSpamFilter(5);
                    }

                    setDirty(true);
                  }}
                  checked={spamFilter > 0}
                />
                <span className='text'>
                  Spam filter (SpamAssassin)
                </span>
              </div>
            </div>
          </div>
          {spamFilter > 0 && (
            <>
              <div className="input-label mb-10">SpamAssassin threshold</div>
              <div className="corner-radius">
                <Slider
                marks={{
                  1: '1',
                  5: '5',
                  10: '10',
                  15: '15',
                  20: '20',
                  25: '25',
                  30: '30',
                }}
                  min={1}
                  max={30}
                  step={1}
                  value={spamFilter}
                  onChange={(value) => {
                    setSpamFilter(value);
                    setDirty(true);
                  }}
                />
              </div>
              <InfoBox className='mt-20'>
                You can choose to block known spam from being posted by setting a preferred spam threshold. We recommend starting high (15+) and lowering it as you go.
                <br />
                Messages that do not meet this threshold will be moved to the spam folder.
              </InfoBox>
            </>
          )}
          <PrimaryButton
            isLoading={loading}

            onClick={async () => {
              setLoading(true);

              try {
                await projectStore!.updateProject(project!.id, {
                  spamFilter,
                });
                // eslint-disable-next-line no-empty
              } catch (exp) { }

              setLoading(false);
            }}
            className="mt-20"
            label="Save"
          />
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'projectStore',
)(observer(EmailSpamFilter));
