import React, { useEffect, useState } from 'react';
import './SetupProject.scss';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import AvatarImage from 'components/AvatarImage/AvatarImage';
import TextInput from 'components/TextInput/TextInput';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import { ProjectStore } from 'stores/private/ProjectStore';
import { useForm } from 'react-hook-form';
import { inject, observer } from 'mobx-react';
import { HeadLine } from 'components/HeadLine/HeadLine';
import OrganisationDropdown from 'components/OrganisationDropdown/OrganisationDropdown';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { useNavigate } from 'react-router';
import LoadingAnimation from 'components/LoadingAnimation/LoadingAnimation';
import cameraIcon from '../../../assets/icons/cam.svg';
import ProjectDropdown from 'components/Sidenav/ProjectDropdown/ProjectDropdown';

interface SetupProjectProps {
  organisationStore?: OrganisationStore;
  projectStore?: ProjectStore;
  next?: any;
  isOnboarding?: boolean;
  autocreateProject?: boolean;
}

const SetupProject = ({
  organisationStore,
  projectStore,
  next,
  isOnboarding,
  autocreateProject,
}: SetupProjectProps) => {
  const navigate = useNavigate();
  const [projectImage, setProjectImage] = useState('');
  const [projectTemplate, setProjectTemplate] = useState(undefined);
  const { register, handleSubmit, formState } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const projects = projectStore?.projects;

  const onSubmit = async (data: any) => {
    setIsLoading(true);

    try {
      const success = await projectStore!.createProject(
        data.title,
        '',
        projectImage,
        projectTemplate,
        isOnboarding,
      );
      if (next && success) {
        setIsLoading(false);
        next();
      }
      // eslint-disable-next-line no-empty
    } catch (exp) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (autocreateProject && organisationStore?.currentOrganisation) {
      onSubmit({
        title: 'First project',
      });
    }
  }, [organisationStore?.currentOrganisation]);

  if (
    !organisationStore?.isLoadingOrganisation &&
    !organisationStore?.currentOrganisation
  ) {
    return (
      <div className="setup-project">
        <SizedContainer size={ContainerSizes.XML} className="centered">
          <HeadLine
            title="Create an organization first"
            subtitle="Projects belong to organizations, this is why we encourage you to create an organization first."
            className="mb-20"
          />
          <PrimaryButton
            label="Create an organization"
            onClick={() => {
              navigate('/organization/create');
            }}
          />
        </SizedContainer>
      </div>
    );
  }

  if (autocreateProject) {
    return (
      <div className="setup-project">
        <SizedContainer size={ContainerSizes.XML} className="centered">
          <LoadingAnimation />
        </SizedContainer>
      </div>
    );
  }

  return (
    <div className="setup-project">
      <SizedContainer size={ContainerSizes.L} className="centered">
        <HeadLine
          title={
            isOnboarding ? 'Create your first project' : 'Create a new project'
          }
          subtitle={
            isOnboarding
              ? 'Your journey starts here.'
              : 'The future of bug reporting starts here.'
          }
          className="mb-20"
        />
        {organisationStore?.organisations &&
          organisationStore?.organisations.length > 1 && (
            <div className="mb-20">
              <OrganisationDropdown redirect={false} />
            </div>
          )}
        <AvatarImage
          image={!projectImage ? cameraIcon : projectImage}
          className={`mb-45 ${!projectImage && 'placeholder-img'}`}
          editable
          afterImageUpload={(url) => {
            setProjectImage(url);
          }}
        />
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="full-width-centered mb-20"
        >
          <TextInput
            inputRef={register('title', { required: true })}
            name="title"
            placeholder="Name"
            type="text"
            className="mb-15"
            error={formState.errors.title ? 'Please enter a title.' : ''}
            initalValue=""
            label="Project name"
            required
          />
          {projects && projects.length > 0 && (
            <ProjectDropdown
              placeholder="Copy configuration from existing project"
              onChooseProject={(project) => {
                setProjectTemplate(project.id);
              }}
            />
          )}
          <PrimaryButton
            icon="arrow-right"
            iconSideRight
            label="Create"
            isLoading={isLoading}
            className="grow mt-15"
            submit
          />
        </form>
      </SizedContainer>
    </div>
  );
};

export default inject(
  'projectStore',
  'organisationStore',
)(observer(SetupProject));
