import {
  disconnectAsanaIntegration,
  getAuthCodeFromAsana,
  setAsanaActions,
  setAsanaAuthToken,
} from 'services/integrations/AsanaHttpService';
import {
  disconnectClickupIntegration,
  getAuthCodeFromClickup,
  setClickupActions,
  setClickupAuthToken,
} from 'services/integrations/ClickupHttpService';
import {
  disconnectGithubIntegration,
  getAuthCodeFromGithub,
  setGithubActions,
  setGithubAuthToken,
} from 'services/integrations/GithubHttpService';
import {
  disconnectJiraIntegration,
  getAuthCodeFromJira,
  setJiraActions,
  setJiraAuthToken,
} from 'services/integrations/JiraHttpService';
import {
  disconnectNotionIntegration,
  getAuthCodeFromNotion,
  setNotionActions,
  setNotionAuthToken,
} from 'services/integrations/NotionHttpService';
import {
  disconnectSlackIntegration,
  getAuthCodeFromSlack,
  setSlackActions,
  setSlackAuthToken,
} from 'services/integrations/SlackHttpService';
import {
  disconnectTrelloIntegration,
  getAuthCodeFromTrello,
  setTrelloActions,
  setTrelloAuthToken,
} from 'services/integrations/TrelloHttpService';
import {
  disconnectZendeskIntegration,
  setZendeskActions,
  setZendeskAuthToken,
} from 'services/integrations/ZendeskHttpService';
import {
  disconnectAzureIntegration,
  getAuthCodeFromAzure,
  setAzureActions,
  setAzureAuthToken,
} from 'services/integrations/AzureHttpService';
import {
  disconnectWebhooksIntegration,
  setWebhookActions,
  setWebhookAuthToken,
} from 'services/integrations/WebhooksHttpService';
import {
  disconnectAworkIntegration,
  setAworkActions,
  setAworkAuthToken,
} from 'services/integrations/AworkHttpService';
import {
  disconnectZapierIntegration,
  setZapierActions,
  setZapierAuth,
} from 'services/integrations/ZapierHttpService';
import {
  disconnectHubspotIntegration,
  getAuthCodeFromHubspot,
  setHubspotActions,
  setHubspotAuthToken,
} from 'services/integrations/HubSpotHttpService';
import {
  disconnectShortcutIntegration,
  setShortcutActions,
  setShortcutAuthToken,
} from 'services/integrations/ShortcutHttpService';
import {
  disconnectPlutioIntegration,
  getAuthCodeFromPlutio,
  setPlutioActions,
  setPlutioAuthToken,
} from 'services/integrations/PlutioHttpService';
import slackIcon from '../assets/icons/slack-light.png';
import trelloIcon from '../assets/icons/trello-light.png';
import jiraIcon from '../assets/icons/jira-light.png';
import stripeIcon from '../assets/icons/stripelogo.svg';
import githubIcon from '../assets/icons/github-light.png';
import calendlyIcon from '../assets/icons/calendlyIcon.png';
import hubspotIcon from '../assets/icons/hubspot_logo.svg';
import asanaIcon from '../assets/icons/asana-light.png';
import zapierIcon from '../assets/icons/zapier-light.png';
import aworkIcon from '../assets/icons/awork.svg';
import webhookIcon from '../assets/icons/webhooks-light.svg';
import clickupIcon from '../assets/icons/clickup_icon.svg';
import notionIcon from '../assets/icons/notion-light.png';
import teamsIcon from '../assets/icons/ms_teams_icon.png';
import zendeskIcon from '../assets/icons/zendesk_light_logo.svg';
import shortcutIcon from '../assets/icons/shortcut_logo.svg';
import plutioIcon from '../assets/icons/plutio_logo.png';
import helpscoutIcon from '../assets/icons/helpscout_icon.png';
import gitlabLogo from '../assets/icons/gitlab_logo.png';
import azureDevopsLogo from '../assets/icons/azure.png';
import linearLogo from '../assets/icons/linear_logo.svg';
import salesforceLogo from '../assets/icons/salesforce_logo.jpg';
import discordLogo from '../assets/icons/discord_logo.svg';

import {
  disconnectHelpscoutIntegration,
  getAuthCodeFromHelpscout,
  setHelpscoutActions,
  setHelpscoutAuthToken,
} from 'services/integrations/HelpscoutHttpService';
import {
  disconnectGitlabIntegration,
  getAuthCodeFromGitlab,
  setGitlabActions,
  setGitlabAuthToken,
} from 'services/integrations/GitlabHttpService';
import {
  disconnectAzureDevopsIntegration,
  getAuthCodeFromAzureDevops,
  setAzureDevopsActions,
  setAzureDevopsAuthToken,
} from 'services/integrations/AzureDevopsHttpService';
import {
  disconnectLinearIntegration,
  getAuthCodeFromLinear,
  setLinearActions,
  setLinearAuthToken,
} from 'services/integrations/LinearHttpService';
import Swal from 'sweetalert2';
import {
  disconnectEmailIntegration,
  setEmailActions,
  setEmailAuthToken,
} from 'services/integrations/EmailHttpService';
import {
  disconnectStripeIntegration,
  setStripeActions,
  setStripeAuthToken,
} from 'services/integrations/StripeHttpService';
import {
  disconnectCalendlyIntegration,
  getAuthCodeFromCalendly,
  setCalendlyActions,
  setCalendlyAuthToken,
} from 'services/integrations/CalendlyHttpService';
import {
  disconnectSalesforceIntegration,
  setSalesforceActions,
  setSalesforceAuthToken,
} from 'services/integrations/SalesforceHttpService';
import {
  disconnectDiscordIntegration,
  getAuthCodeFromDiscord,
  setDiscordActions,
  setDiscordAuthToken,
} from 'services/integrations/DiscordHttpService';

export enum INTEGRATION {
  ASANA = 'asana',
  AWORK = 'awork',
  AZURE = 'azure',
  CLICKUP = 'clickup',
  GITHUB = 'github',
  GITLAB = 'gitlab',
  CALENDLY = 'calendly',
  HUBSPOT = 'hubspot',
  HELPSCOUT = 'helpscout',
  JIRA = 'jira',
  STRIPE = 'stripe',
  NOTION = 'notion',
  SLACK = 'slack',
  TRELLO = 'trello',
  WEBHOOK = 'webhook',
  ZAPIER = 'zapier',
  ZENDESK = 'zendesk',
  SHORTCUT = 'shortcut',
  PLUTIO = 'plutio',
  AZURE_DEVOPS = 'azureDevops',
  LINEAR = 'linear',
  EMAIL = 'email',
  SALESFORCE = 'salesforce',
  DISCORD = 'discord',
}

export const localActions = [
  {
    label: 'Created',
    value: 'CREATED',
  },
  {
    label: 'Updated',
    value: 'UPDATED',
  },
  {
    label: 'Deleted',
    value: 'DELETED',
  },
  {
    label: 'Message sent',
    value: 'MESSAGE_SENT',
  },
];

export const integrations = {
  stripe: {
    title: 'Stripe',
    description: 'Sync your Stripe customers with Gleap.',
    icon: stripeIcon,
    name: INTEGRATION.STRIPE,
    handleConnection: (projectID) => {
      window.location.href = `/integrations/stripe?state=${projectID}&code=gleapcode`;
    },
    initAuth: async (code, projectID) => {
      return setStripeAuthToken(projectID, code);
    },
    validate: (integrationInfo) => {
      return integrationInfo.apiKey && integrationInfo.apiKey !== '';
    },
    save: async (projectID, integrationID, actions, settings) => {
      return setStripeActions({
        projectID,
        integrationID,
        actions,
        settings,
      });
    },
    disconnect: async (projectID, integrationID) => {
      return disconnectStripeIntegration({ projectID, integrationID });
    },
    actions: [
      {
        label: 'Do nothing',
        value: 'NONE',
      },
    ],
  },
  calendly: {
    title: 'Calendly',
    description: 'Schedule meetings within Gleap.',
    icon: calendlyIcon,
    name: INTEGRATION.CALENDLY,
    handleConnection: (projectID) => {
      getAuthCodeFromCalendly(projectID);
    },
    initAuth: async (code, projectID) => {
      return setCalendlyAuthToken(code, projectID);
    },
    validate: (integrationInfo) => {
      return true;
    },
    save: async (projectID, integrationID, actions, settings) => {
      return setCalendlyActions({
        projectID,
        integrationID,
        actions,
        settings,
      });
    },
    disconnect: async (projectID, integrationID) => {
      return disconnectCalendlyIntegration({ projectID, integrationID });
    },
    actions: [
      {
        label: 'Do nothing',
        value: 'NONE',
      },
    ],
  },
  azureDevops: {
    title: 'Azure DevOps',
    description: 'Create work items in your Azure DevOps account.',
    name: INTEGRATION.AZURE_DEVOPS,
    icon: azureDevopsLogo,
    handleConnection: (projectID) => {
      Swal.fire({
        text: 'Before Gleap can integrate with your Azure DevOps projects, you will need to ensure you have allowed third-party application access in your organization policies.',
        showDenyButton: true,
        confirmButtonText: `All done, continue 👏`,
        denyButtonText: `Show me how`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          getAuthCodeFromAzureDevops(projectID);
        } else {
          window.location.href =
            'https://help.gleap.io/en/articles/51-azure-devops-integration';
        }
      });
    },
    initAuth: async (code, projectID) => {
      return setAzureDevopsAuthToken(code, projectID);
    },
    validate: (integrationInfo) => {
      return (
        integrationInfo.organizationID &&
        integrationInfo.organizationID !== '' &&
        integrationInfo.projectID &&
        integrationInfo.projectID !== '' &&
        integrationInfo.workItemType &&
        integrationInfo.workItemType !== ''
      );
    },
    save: async (projectID, integrationID, actions, settings) => {
      return setAzureDevopsActions({
        projectID,
        integrationID,
        actions,
        settings,
      });
    },
    disconnect: async (projectID, integrationID) => {
      return disconnectAzureDevopsIntegration({ projectID, integrationID });
    },
    actions: [
      {
        label: 'Create work item',
        value: 'CREATE_WORK_ITEM',
      },
      {
        label: 'Update work item',
        value: 'UPDATE_WORK_ITEM',
      },
      {
        label: 'Delete work item',
        value: 'DELETE_WORK_ITEM',
      },
      {
        label: 'Send message to work item',
        value: 'SEND_MESSAGE_TO_WORK_ITEM',
      },
    ],
  },
  jira: {
    title: 'Jira',
    description: 'Send tickets  to your Jira projects.',
    icon: jiraIcon,
    name: INTEGRATION.JIRA,
    handleConnection: (projectID) => {
      getAuthCodeFromJira(projectID);
    },
    initAuth: async (code, projectID) => {
      return setJiraAuthToken(code, projectID);
    },
    validate: (integrationInfo) => {
      return (
        integrationInfo.boardID &&
        integrationInfo.boardID !== '' &&
        integrationInfo.issueType &&
        integrationInfo.issueType !== ''
      );
    },
    save: async (projectID, integrationID, actions, settings) => {
      return setJiraActions({
        projectID,
        integrationID,
        actions,
        settings,
      });
    },
    disconnect: async (projectID, integrationID) => {
      return disconnectJiraIntegration({ projectID, integrationID });
    },
    actions: [
      {
        label: 'Create task',
        value: 'CREATE_TASK',
      },
      {
        label: 'Update task',
        value: 'UPDATE_TASK',
      },
      {
        label: 'Delete task',
        value: 'DELETE_TASK',
      },
      {
        label: 'Send message to task',
        value: 'SEND_MESSAGE_TO_TASK',
      },
    ],
  },
  trello: {
    title: 'Trello',
    description: 'Send tickets  to your Trello boards.',
    icon: trelloIcon,
    name: INTEGRATION.TRELLO,
    handleConnection: (projectID) => {
      getAuthCodeFromTrello(projectID);
    },
    initAuth: async (projectID, authToken) => {
      return setTrelloAuthToken(projectID, authToken);
    },
    validate: (integrationInfo) => {
      return (
        integrationInfo.boardID &&
        integrationInfo.boardID !== '' &&
        integrationInfo.listID &&
        integrationInfo.listID !== ''
      );
    },
    save: async (projectID, integrationID, actions, settings) => {
      return setTrelloActions({
        projectID,
        integrationID,
        actions,
        settings,
      });
    },
    disconnect: async (projectID, integrationID) => {
      return disconnectTrelloIntegration({ projectID, integrationID });
    },
    actions: [
      {
        label: 'Create task',
        value: 'CREATE_TASK',
      },
      {
        label: 'Update task',
        value: 'UPDATE_TASK',
      },
      {
        label: 'Delete task',
        value: 'DELETE_TASK',
      },
      {
        label: 'Send message to task',
        value: 'SEND_MESSAGE_TO_TASK',
      },
    ],
  },
  slack: {
    title: 'Slack',
    description: 'Send notifications to your Slack channels.',
    icon: slackIcon,
    name: INTEGRATION.SLACK,
    handleConnection: (projectID) => {
      getAuthCodeFromSlack(projectID);
    },
    initAuth: async (code, projectID) => {
      return setSlackAuthToken(code, projectID);
    },
    validate: (integrationInfo) => {
      return (
        integrationInfo &&
        integrationInfo.channelID &&
        integrationInfo.channelID !== ''
      );
    },
    save: async (projectID, integrationID, actions, settings) => {
      return setSlackActions({
        projectID,
        integrationID,
        actions,
        settings,
      });
    },
    disconnect: async (projectID, integrationID) => {
      return disconnectSlackIntegration({ projectID, integrationID });
    },
    actions: [
      {
        label: 'Send message to channel',
        value: 'SEND_MESSAGE_TO_CHANNEL',
      },
      {
        label: 'Send message to thread',
        value: 'SEND_MESSAGE_TO_THREAD',
      },
      {
        label: 'Processing user changed',
        value: 'PROCESSING_USER_CHANGED',
      },
    ],
  },
  asana: {
    title: 'Asana',
    description: 'Work anytime, anywhere with Asana.',
    name: INTEGRATION.ASANA,
    icon: asanaIcon,
    handleConnection: (projectID) => {
      getAuthCodeFromAsana(projectID);
    },
    initAuth: async (code, projectID) => {
      return setAsanaAuthToken(code, projectID);
    },
    validate: (integrationInfo) => {
      return (
        integrationInfo.boardID &&
        integrationInfo.boardID !== '' &&
        integrationInfo.sectionID &&
        integrationInfo.sectionID !== ''
      );
    },
    save: async (projectID, integrationID, actions, settings) => {
      return setAsanaActions({
        projectID,
        integrationID,
        actions,
        settings,
      });
    },
    disconnect: async (projectID, integrationID) => {
      return disconnectAsanaIntegration({ projectID, integrationID });
    },
    actions: [
      {
        label: 'Create task',
        value: 'CREATE_TASK',
      },
      {
        label: 'Update task',
        value: 'UPDATE_TASK',
      },
      {
        label: 'Delete task',
        value: 'DELETE_TASK',
      },
      {
        label: 'Send message to task',
        value: 'SEND_MESSAGE_TO_TASK',
      },
    ],
  },
  awork: {
    title: 'Awork',
    description: 'Send tickets  to your awork projects.',
    name: INTEGRATION.AWORK,
    icon: aworkIcon,
    handleConnection: async (projectID) => {
      window.location.href = `/projects/${projectID}/integrations/awork`;
    },
    initAuth: async (code, projectID) => {
      return setAworkAuthToken(projectID, code);
    },
    validate: (integrationInfo) => {
      return (
        integrationInfo.typeOfWorkID &&
        integrationInfo.typeOfWorkID !== '' &&
        integrationInfo.boardID &&
        integrationInfo.boardID !== '' &&
        integrationInfo.statusID &&
        integrationInfo.statusID !== ''
      );
    },
    save: async (projectID, integrationID, actions, settings) => {
      return setAworkActions({
        projectID,
        integrationID,
        actions,
        settings,
      });
    },
    disconnect: async (projectID, integrationID) => {
      return disconnectAworkIntegration({ projectID, integrationID });
    },
    actions: [
      {
        label: 'Create task',
        value: 'CREATE_TASK',
      },
      {
        label: 'Delete task',
        value: 'DELETE_TASK',
      },
      {
        label: 'Send message to task',
        value: 'SEND_MESSAGE_TO_TASK',
      },
    ],
  },
  azure: {
    title: 'MS Teams',
    description: 'Send notifications to your MS Teams channels.',
    icon: teamsIcon,
    name: INTEGRATION.AZURE,
    handleConnection: (projectID) => {
      getAuthCodeFromAzure(projectID);
    },
    initAuth: async (code, projectID) => {
      return setAzureAuthToken(code, projectID);
    },
    validate: (integrationInfo) => {
      return (
        integrationInfo.teamID &&
        integrationInfo.teamID !== '' &&
        integrationInfo.channelID &&
        integrationInfo.channelID !== ''
      );
    },
    save: async (projectID, integrationID, actions, settings) => {
      return setAzureActions({
        projectID,
        integrationID,
        actions,
        settings,
      });
    },
    disconnect: async (projectID, integrationID) => {
      return disconnectAzureIntegration({ projectID, integrationID });
    },
    actions: [
      {
        label: 'Send message to channel',
        value: 'SEND_MESSAGE_TO_CHANNEL',
      },
      {
        label: 'Send message to thread',
        value: 'SEND_MESSAGE_TO_THREAD',
      },
    ],
  },
  clickup: {
    title: 'ClickUp',
    description: 'Send tickets  to your ClickUp boards.',
    name: INTEGRATION.CLICKUP,
    icon: clickupIcon,
    handleConnection: (projectID) => {
      getAuthCodeFromClickup(projectID);
    },
    initAuth: async (code, projectID) => {
      return setClickupAuthToken(code, projectID);
    },
    validate: (integrationInfo) => {
      return (
        integrationInfo.teamID &&
        integrationInfo.teamID !== '' &&
        integrationInfo.spaceID &&
        integrationInfo.spaceID !== '' &&
        integrationInfo.listID &&
        integrationInfo.listID !== ''
      );
    },
    save: async (projectID, integrationID, actions, settings) => {
      return setClickupActions({
        projectID,
        integrationID,
        actions,
        settings,
      });
    },
    disconnect: async (projectID, integrationID) => {
      return disconnectClickupIntegration({ projectID, integrationID });
    },
    actions: [
      {
        label: 'Create task',
        value: 'CREATE_TASK',
      },
      {
        label: 'Update task',
        value: 'UPDATE_TASK',
      },
      {
        label: 'Delete task',
        value: 'DELETE_TASK',
      },
      {
        label: 'Send message to task',
        value: 'SEND_MESSAGE_TO_TASK',
      },
    ],
  },
  github: {
    title: 'Github',
    description: 'Open issues for every bug report.',
    icon: githubIcon,
    name: INTEGRATION.GITHUB,
    handleConnection: (projectID) => {
      getAuthCodeFromGithub(projectID);
    },
    initAuth: async (code, projectID) => {
      return setGithubAuthToken(code, projectID);
    },
    validate: (integrationInfo) => {
      return (
        integrationInfo.repositoryID && integrationInfo.repositoryID !== ''
      );
    },
    save: async (projectID, integrationID, actions, settings) => {
      return setGithubActions({
        projectID,
        integrationID,
        actions,
        settings,
      });
    },
    disconnect: async (projectID, integrationID) => {
      return disconnectGithubIntegration({ projectID, integrationID });
    },
    actions: [
      {
        label: 'Create issue',
        value: 'CREATE_ISSUE',
      },
      {
        label: 'Update issue',
        value: 'UPDATE_ISSUE',
      },
      {
        label: 'Send message to issue',
        value: 'SEND_MESSAGE_TO_ISSUE',
      },
    ],
  },
  gitlab: {
    title: 'Gitlab',
    description: 'Open issues for every bug report.',
    icon: gitlabLogo,
    name: INTEGRATION.GITLAB,
    handleConnection: (projectID) => {
      getAuthCodeFromGitlab(projectID);
    },
    initAuth: async (code, projectID) => {
      return setGitlabAuthToken(code, projectID);
    },
    validate: (integrationInfo) => {
      return integrationInfo.projectID && integrationInfo.projectID !== '';
    },
    save: async (projectID, integrationID, actions, settings) => {
      return setGitlabActions({
        projectID,
        integrationID,
        actions,
        settings,
      });
    },
    disconnect: async (projectID, integrationID) => {
      return disconnectGitlabIntegration({ projectID, integrationID });
    },
    actions: [
      {
        label: 'Create issue',
        value: 'CREATE_ISSUE',
      },
      {
        label: 'Update issue',
        value: 'UPDATE_ISSUE',
      },
      {
        label: 'Send message to issue',
        value: 'SEND_MESSAGE_TO_ISSUE',
      },
    ],
  },
  hubspot: {
    title: 'HubSpot',
    description: 'Create tickets in your HubSpot account.',
    icon: hubspotIcon,
    name: INTEGRATION.HUBSPOT,
    handleConnection: (projectID) => {
      getAuthCodeFromHubspot(projectID);
    },
    initAuth: async (code, projectID, baseUrl) => {
      return setHubspotAuthToken(code, projectID);
    },
    validate: (integrationInfo) => {
      return true;
    },
    save: async (projectID, integrationID, actions, settings) => {
      return setHubspotActions({
        projectID,
        integrationID,
        actions,
        settings,
      });
    },
    disconnect: async (projectID, integrationID) => {
      return disconnectHubspotIntegration({ projectID, integrationID });
    },
    actions: [
      {
        label: 'Create ticket',
        value: 'CREATE_TICKET',
      },
      {
        label: 'Update ticket',
        value: 'UPDATE_TICKET',
      },
      {
        label: 'Delete ticket',
        value: 'DELETE_TICKET',
      },
      {
        label: 'Send message',
        value: 'SEND_MESSAGE_TO_TASK',
      },
    ],
  },
  helpscout: {
    title: 'Helpscout',
    description: 'Create inquiries in your Helpscout account.',
    icon: helpscoutIcon,
    handleConnection: (projectID) => {
      getAuthCodeFromHelpscout(projectID);
    },
    initAuth: async (code, projectID, baseUrl) => {
      return setHelpscoutAuthToken(code, projectID);
    },
    validate: (integrationInfo) => {
      return integrationInfo.mailboxID && integrationInfo.mailboxID !== '';
    },
    save: async (projectID, integrationID, actions, settings) => {
      return setHelpscoutActions({
        projectID,
        integrationID,
        actions,
        settings,
      });
    },
    disconnect: async (projectID, integrationID) => {
      return disconnectHelpscoutIntegration({ projectID, integrationID });
    },
    actions: [
      {
        label: 'Create conversation',
        value: 'CREATE_CONVERSATION',
      },
    ],
  },
  notion: {
    title: 'Notion (Beta)',
    description: 'Send tickets  to your Notion projects.',
    icon: notionIcon,
    name: INTEGRATION.NOTION,
    handleConnection: (projectID) => {
      getAuthCodeFromNotion(projectID);
    },
    initAuth: async (code, projectID) => {
      return setNotionAuthToken(code, projectID);
    },
    validate: (integrationInfo) => {
      return integrationInfo.databaseID && integrationInfo.databaseID !== '';
    },
    save: async (projectID, integrationID, actions, settings) => {
      return setNotionActions({
        projectID,
        integrationID,
        actions,
        settings,
      });
    },
    disconnect: async (projectID, integrationID) => {
      return disconnectNotionIntegration({ projectID, integrationID });
    },
    actions: [
      {
        label: 'Create task',
        value: 'CREATE_TASK',
      },
    ],
  },

  webhook: {
    title: 'Webhooks',
    description: 'Send tickets  to any REST api.',
    icon: webhookIcon,
    name: INTEGRATION.WEBHOOK,
    handleConnection: (projectID) => {
      window.location.href = `/integrations/webhook?state=${projectID}&code=gleapcode`;
    },
    initAuth: async (code, projectID) => {
      return setWebhookAuthToken(projectID, code);
    },
    validate: (integrationInfo) => {
      return integrationInfo.callbackUrl && integrationInfo.callbackUrl !== '';
    },
    save: async (projectID, integrationID, actions, settings) => {
      return setWebhookActions({
        projectID,
        integrationID,
        actions,
        settings,
      });
    },
    disconnect: async (projectID, integrationID) => {
      return disconnectWebhooksIntegration({ projectID, integrationID });
    },
    actions: [
      {
        label: 'Create',
        value: 'CREATE',
      },
      {
        label: 'Update',
        value: 'UPDATE',
      },
      {
        label: 'Delete',
        value: 'DELETE',
      },
      {
        label: 'Send message',
        value: 'SEND_MESSAGE',
      },
      {
        label: 'Processing user changed',
        value: 'PROCESSING_USER_CHANGED',
      },
    ],
  },
  email: {
    title: 'Email',
    description: 'Send tickets to your email.',
    icon: webhookIcon,
    name: INTEGRATION.EMAIL,
    handleConnection: (projectID) => {
      window.location.href = `/integrations/email?state=${projectID}&code=gleapcode`;
    },
    initAuth: async (code, projectID) => {
      return setEmailAuthToken(projectID, code);
    },
    validate: (integrationInfo) => {
      return integrationInfo.email && integrationInfo.email !== '';
    },
    save: async (projectID, integrationID, actions, settings) => {
      return setEmailActions({
        projectID,
        integrationID,
        actions,
        settings,
      });
    },
    disconnect: async (projectID, integrationID) => {
      return disconnectEmailIntegration({ projectID, integrationID });
    },
    actions: [
      {
        label: 'Create',
        value: 'CREATE',
      },
    ],
  },
  zapier: {
    title: 'Zapier',
    description: 'Connect with millions of integrations.',
    icon: zapierIcon,
    name: INTEGRATION.ZAPIER,
    handleConnection: (projectID) => {
      window.location.href = `/integrations/zapier?state=${projectID}&code=gleapcode`;
    },
    initAuth: async (code, projectID) => {
      return setZapierAuth(code, projectID);
    },
    validate: (integrationInfo) => {
      return true;
    },
    save: async (projectID, integrationID, actions, settings) => {
      return setZapierActions({
        projectID,
        integrationID,
        actions,
        settings,
      });
    },
    disconnect: async (projectID, integrationID) => {
      return disconnectZapierIntegration({ projectID, integrationID });
    },
    actions: [
      {
        label: 'Send',
        value: 'SEND',
      },
    ],
  },
  zendesk: {
    title: 'Zendesk',
    description: 'Create tickets in your zendesk account.',
    icon: zendeskIcon,
    name: INTEGRATION.ZENDESK,
    handleConnection: (projectID) => {
      window.location.href = `/projects/${projectID}/integrations/zendesk`;
    },
    initAuth: async (code, projectID, baseUrl) => {
      return setZendeskAuthToken(code, projectID, baseUrl);
    },
    validate: (integrationInfo) => {
      return true;
    },
    save: async (projectID, integrationID, actions, settings) => {
      return setZendeskActions({
        projectID,
        integrationID,
        actions,
        settings,
      });
    },
    disconnect: async (projectID, integrationID) => {
      return disconnectZendeskIntegration({ projectID, integrationID });
    },
    actions: [
      {
        label: 'Create ticket',
        value: 'CREATE_TICKET',
      },
      {
        label: 'Delete ticket',
        value: 'DELETE_TICKET',
      },
      {
        label: 'Send message to ticket',
        value: 'SEND_MESSAGE_TO_TICKET',
      },
    ],
  },
  shortcut: {
    title: 'Shortcut',
    description: 'Send tickets  to your Shortcut boards.',
    name: INTEGRATION.SHORTCUT,
    icon: shortcutIcon,
    handleConnection: (projectID) => {
      window.location.href = `/projects/${projectID}/integrations/shortcut`;
    },
    initAuth: async (code, projectID) => {
      return setShortcutAuthToken(code, projectID);
    },
    validate: (integrationInfo) => {
      return (
        integrationInfo.projectID !== '' ||
        integrationInfo.workflowStateID !== ''
      );
    },
    save: async (projectID, integrationID, actions, settings) => {
      return setShortcutActions({
        projectID,
        integrationID,
        actions,
        settings,
      });
    },
    disconnect: async (projectID, integrationID) => {
      return disconnectShortcutIntegration({ projectID, integrationID });
    },
    actions: [
      {
        label: 'Create story',
        value: 'CREATE_STORY',
      },
      {
        label: 'Update story',
        value: 'UPDATE_STORY',
      },
      {
        label: 'Delete story',
        value: 'DELETE_STORY',
      },
      {
        label: 'Send message to story',
        value: 'SEND_MESSAGE_TO_STORY',
      },
    ],
  },
  plutio: {
    title: 'Plutio',
    description: 'Create tasks in your Plutio workspace.',
    name: INTEGRATION.PLUTIO,
    icon: plutioIcon,
    handleConnection: (projectID) => {
      getAuthCodeFromPlutio(projectID);
    },
    initAuth: async (code, projectID) => {
      return setPlutioAuthToken(code, projectID);
    },
    validate: (integrationInfo) => {
      return integrationInfo.businessID && integrationInfo.businessID !== '';
    },
    save: async (projectID, integrationID, actions, settings) => {
      return setPlutioActions({
        projectID,
        integrationID,
        actions,
        settings,
      });
    },
    disconnect: async (projectID, integrationID) => {
      return disconnectPlutioIntegration({ projectID, integrationID });
    },
    actions: [
      {
        label: 'Create task',
        value: 'CREATE_TASK',
      },
      {
        label: 'Update task',
        value: 'UPDATE_TASK',
      },
      {
        label: 'Delete task',
        value: 'DELETE_TASK',
      },
      {
        label: 'Send message to task',
        value: 'SEND_MESSAGE_TO_TASK',
      },
    ],
  },
  linear: {
    title: 'Linear',
    description: 'Create issues in your Linear workspace.',
    name: INTEGRATION.LINEAR,
    icon: linearLogo,
    handleConnection: (projectID) => {
      getAuthCodeFromLinear(projectID);
    },
    initAuth: async (code, projectID) => {
      return setLinearAuthToken(code, projectID);
    },
    validate: (integrationInfo) => {
      return integrationInfo.teamID && integrationInfo.teamID !== '';
    },
    save: async (projectID, integrationID, actions, settings) => {
      return setLinearActions({
        projectID,
        integrationID,
        actions,
        settings,
      });
    },
    disconnect: async (projectID, integrationID) => {
      return disconnectLinearIntegration({ projectID, integrationID });
    },
    actions: [
      {
        label: 'Create issue',
        value: 'CREATE_ISSUE',
      },
      {
        label: 'Update issue',
        value: 'UPDATE_ISSUE',
      },
      {
        label: 'Delete issue',
        value: 'DELETE_ISSUE',
      },
      {
        label: 'Send message to issue',
        value: 'SEND_MESSAGE_TO_ISSUE',
      },
    ],
  },
  salesforce: {
    title: 'Salesforce',
    description: 'Create cases in your Salesforce account.',
    name: INTEGRATION.SALESFORCE,
    icon: salesforceLogo,
    handleConnection: (projectID) => {
      window.location.href = `/projects/${projectID}/integrations/salesforce`;
    },
    initAuth: async (code, projectID) => {
      return setSalesforceAuthToken(code, projectID);
    },
    validate: (integrationInfo) => {
      return true;
    },
    save: async (projectID, integrationID, actions, settings) => {
      return setSalesforceActions({
        projectID,
        integrationID,
        actions,
        settings,
      });
    },
    disconnect: async (projectID, integrationID) => {
      return disconnectSalesforceIntegration({ projectID, integrationID });
    },
    actions: [
      {
        label: 'Create ticket',
        value: 'CREATE_TICKET',
      },
      {
        label: 'Update ticket',
        value: 'UPDATE_TICKET',
      },
      {
        label: 'Delete ticket',
        value: 'DELETE_TICKET',
      },
      {
        label: 'Send message to issue',
        value: 'SEND_MESSAGE_TO_ISSUE',
      },
    ],
  },
  discord: {
    title: 'Discord',
    description: 'Send notifications to your Discord channels.',
    icon: discordLogo,
    name: INTEGRATION.DISCORD,
    handleConnection: (projectID) => {
      getAuthCodeFromDiscord(projectID);
    },
    initAuth: async (code, projectID) => {
      return setDiscordAuthToken(code, projectID);
    },
    validate: (integrationInfo) => {
      return (
        integrationInfo &&
        integrationInfo.channelID &&
        integrationInfo.channelID !== ''
      );
    },
    save: async (projectID, integrationID, actions, settings) => {
      return setDiscordActions({
        projectID,
        integrationID,
        actions,
        settings,
      });
    },
    disconnect: async (projectID, integrationID) => {
      return disconnectDiscordIntegration({ projectID, integrationID });
    },
    actions: [
      {
        label: 'Send message to channel',
        value: 'SEND_MESSAGE_TO_CHANNEL',
      },
      {
        label: 'Send message to thread',
        value: 'SEND_MESSAGE_TO_THREAD',
      },
    ],
  },
};
