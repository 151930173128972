import React from 'react';
import './ChangeTicketStatusActionEditor.scss';
import { Action } from 'models/Bot';
import { ModalStore } from 'stores/private/ModalStore';
import PageContainer from 'components/PageContainer/PageContainer';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import PageContent from 'components/PageContent/PageContent';
import InfoBox from 'components/InfoBox/InfoBox';
import BugTypeSelection from 'components/BugTypeSelection/BugTypeSelection';
import StatusSelection from 'components/BugStatusSelection/StatusSelection';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';

interface ChangeTicketStatusActionEditorProps {
  action: Action;
  modalStore?: ModalStore;
}

const ChangeTicketStatusActionEditor = ({
  action,
  modalStore,
}: ChangeTicketStatusActionEditorProps) => {
  return (
    <div className="bot-action-editor">
      <PageContainer>
        <PageHeadLine title="Change ticket status">
          <div className="header-content-right">
            <PrimaryButton
              label="Close"
              iconSideRight={false}
              onClick={() => {
                modalStore!.closeModal();
              }}
            />
          </div>
        </PageHeadLine>
        <PageContent hasTitle>
          <InfoBox>
            Ticket type and status change will not automatically trigger another
            workflow. If you want to trigger another workflow, you need to add a
            new action.
          </InfoBox>
          <div className="input-label mt-20">Ticket type</div>
          <div className="feedback-type-selection">
            <BugTypeSelection
              value={action.ticketType ?? 'INQUIRY'}
              onValueChanged={(selected) => {
                runInAction(() => {
                  action.ticketType = selected.value;
                });
              }}
            />
          </div>
          {action.ticketType && (
            <>
              <div className="input-label mt-20">Ticket status</div>
              <StatusSelection
                status={action.ticketStatus}
                feedbackType={action.ticketType}
                onChange={(status) => {
                  runInAction(() => {
                    action.ticketStatus = status;
                  });
                }}
              />
            </>
          )}
        </PageContent>
      </PageContainer>
    </div>
  );
};

export default inject('modalStore')(observer(ChangeTicketStatusActionEditor));
