import UserAvatar from 'components/UserAvatar/UserAvatar';
import { inject, observer } from 'mobx-react';
import Select from 'react-select';
import { ProjectStore } from 'stores/private/ProjectStore';
import './ProjectUserSelection.scss';

interface ProjectUserSelectionProps {
  onSelectedUser: (user: any) => void;
  projectStore?: ProjectStore;
  placeholder?: string;
  excludeUsers?: string[];
}

const ProjectUserSelection = ({
  onSelectedUser,
  projectStore,
  placeholder = "Add user",
  excludeUsers = [],
}: ProjectUserSelectionProps) => {
  if (!projectStore?.currentProjectUsers) {
    return <></>;
  }

  return (
    <div className="project-user-selection" style={{ width: '100%' }}>
      <Select
        className="dropdown-selection"
        classNamePrefix="dropdown-selection"
        getOptionLabel={(option) => option.firstName}
        getOptionValue={(option) => option.id}
        placeholder={
          <div className="user-selection-placeholder">
            <div className="user-selection-placeholder-avatar" />
            <div className="user-selection-placeholder-label">{placeholder}</div>
          </div>
        }
        isClearable
        value={null}
        components={{
          Option: (option: any) => {
            const name = `${option.data.firstName} ${option.data.lastName}`;
            return (
              <div
                className="option-list-item user-option-list-item"
                onClick={() => {
                  if (option.selectOption) {
                    option.selectOption(option.data);
                  }
                }}
              >
                <UserAvatar
                  small
                  email={option.data.email}
                  imageUrl={option.data.profileImageUrl}
                />
                {name}
              </div>
            );
          },
        }}
        onChange={onSelectedUser}
        options={projectStore?.currentProjectUsers.filter((user) => {
          return !excludeUsers.includes(user.id);
        })}
      />
    </div>
  );
};

export default inject('projectStore')(observer(ProjectUserSelection));
