import { useEffect, useState } from 'react';
import '../../../styles/scss-variable.scss';
import './SpecialListComponent.scss';

function SpecialListComponent({
  singleSelect = false,
  valueKey,
  value,
  options,
  cellRenderer,
  onValueChanged,
  searchbar,
  placeholder,
  labelKey,
}: any) {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const currentSelections = value && value.length ? value : [];
  const currentValueKey = valueKey || 'value';

  useEffect(() => {
    if (options) {
      setFilteredOptions(options);
    }
  }, [options]);

  useEffect(() => {
    if (searchbar && options) {
      const filteredOptions = options.filter((item) =>
        labelKey(item).toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setFilteredOptions(filteredOptions);
    }
  }, [options, searchTerm, searchbar, labelKey]);

  const handleItemClick = (item) => {
    if (!item) {
      return;
    }

    let newSelectedItems = [...currentSelections];

    if (singleSelect) {
      newSelectedItems = [item[currentValueKey]];
    } else {
      const itemIndex = newSelectedItems.indexOf(item[currentValueKey]);
      if (itemIndex === -1) {
        newSelectedItems.push(item[currentValueKey]);
      } else {
        newSelectedItems.splice(itemIndex, 1);
      }
    }

    onValueChanged(newSelectedItems);
  };

  return (
    <div>
      {searchbar ? (
        <input
          className="special-list-component-searchbar"
          type="text"
          placeholder={placeholder}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      ) : null}
      <div className="special-list-container">
        {options &&
          filteredOptions.map((item: any, index) => {
            const isItemSelected = currentSelections?.includes(
              item[currentValueKey],
            );

            return (
              <div
                className={`special-list-component-options ${
                  isItemSelected && 'special-list-component-options--selected'
                }`}
                key={index}
                onClick={() => handleItemClick(item)}
              >
                <div className="special-list-component-options-cell">
                  {cellRenderer(item)}
                </div>
                {isItemSelected && (
                  <div className="special-list-component-options-check">
                    <i className="fa-solid fa-check" />
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default SpecialListComponent;
