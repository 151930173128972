import React, { useEffect, useState } from 'react';
import StatisticBarChart from './StatisticBarChart';
import { BarChartData } from 'models/Chart';

interface FutureBarChartProps {
  future?: Promise<BarChartData | undefined | null>;
  width?: number | string;
  title?: string;
  formatter?: (value: number) => string;
  infoText?: string;
  futureWithAbortion?: (
    controller: AbortController,
  ) => Promise<BarChartData | undefined | null>;
  colors?: any;
  stacked?: boolean;
}

const FutureBarChart: React.FC<FutureBarChartProps> = ({
  future,
  width,
  title,
  formatter,
  infoText,
  futureWithAbortion,
  colors,
  stacked,
}) => {
  const [chartData, setChartData] = useState<BarChartData | undefined | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState(true);

  const initialize = async () => {
    try {
      setIsLoading(true);
      const data = await future;

      setChartData(data);

      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    future && initialize();
  }, [future]);

  useEffect(() => {
    setIsLoading(true);
    if (!futureWithAbortion) return;
    const newAbortController = new AbortController();

    futureWithAbortion &&
      futureWithAbortion(newAbortController)
        .then((data) => {
          if (!newAbortController.signal.aborted) {
            setChartData(data);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          if (!newAbortController.signal.aborted) {
            console.error('Error fetching data:', err);
            setIsLoading(false);
          }
        });

    return () => {
      newAbortController.abort();
    };
  }, [futureWithAbortion]);

  return (
    <div className="mb-15" style={{ width: width ?? '100%' }}>
      <StatisticBarChart
        title={title}
        chartData={chartData}
        isLoading={isLoading}
        formatter={formatter}
        infoText={infoText}
        colors={colors}
        stacked={stacked}
      />
    </div>
  );
};

export default FutureBarChart;
