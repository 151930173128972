import { Card } from 'components/Card/Card';
import LinkButton from 'components/LinkButton/LinkButton';
import classNames from 'classnames';
import connectedIcon from '../../assets/icons/connect_icon.png';
import './IntegrationReSendCard.scss';

interface IntegrationReSendCardProps {
  image: string;
  title?: string;
  onClick: any;
  connect: boolean;
  identifier?: string;
  readOnly?: boolean;
  entitled?: boolean;
  loading?: boolean;
}

const IntegrationReSendCard = ({
  image,
  onClick,
  connect,
  title,
  identifier,
  entitled = false,
  loading = false,
}: IntegrationReSendCardProps) => {
  const cardClassName = classNames({
    'integration-resend-card': true,
    entitled: !entitled,
  });

  return (
    <Card className={cardClassName}>
      <div className="link-header">
        <img className="image" src={image} alt="integration_image" />
        <div>
          <div className="link-header-title">{title}</div>
          {identifier && (
            <div className="link-header-subtitle">{identifier}</div>
          )}
        </div>
        {!connect && (
          <div className="connected-icon">
            <img className="icon" src={connectedIcon} alt="connected_icon" />
          </div>
        )}
      </div>
      <div className="link-body">
        <div className="description">Send this ticket to {title}.</div>
      </div>
      <div className="link-footer">
        <LinkButton
          isLoading={loading}
          data-name={title}
          icon="arrow-right"
          label="Send"
          onClick={() => {
            if (!connect && !loading) {
              onClick();
            }
          }}
        />
      </div>
    </Card>
  );
};

export default IntegrationReSendCard;
