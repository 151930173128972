import axios from '../Axios';

const getAuthToken = ({ projectID, baseUrl }) => {
  const redirectUri = `${process.env.REACT_APP_BASEURL}/integrations/zendesk`;
  const state = `${projectID}:${baseUrl}`;

  return window.open(
    `https://${baseUrl}.zendesk.com/oauth/authorizations/new?response_type=code&redirect_uri=${encodeURIComponent(
      redirectUri,
    )}&client_id=${encodeURIComponent(
      `${process.env.REACT_APP_ZENDESK_CLIENT_ID}`,
    )}&scope=read%20write&state=${encodeURIComponent(state)}`,
    '_self',
  );
};

const setZendeskAuthToken = (code, projectID, baseUrl) => {
  return axios.post(`/projects/${projectID}/integrations/zendesk`, {
    code,
    baseUrl,
  });
};

const setZendeskActions = ({ projectID, integrationID, actions, settings }) => {
  return axios.put(
    `/projects/${projectID}/integrations/zendesk/${integrationID}`,
    {
      actions,
      settings,
    },
  );
};

const getOrganizations = ({ projectID, integrationID }) => {
  return axios.get(
    `/projects/${projectID}/integrations/zendesk/${integrationID}/organizations`,
  );
};

const getBrands = ({ projectID, integrationID }) => {
  return axios.get(
    `/projects/${projectID}/integrations/zendesk/${integrationID}/brands`,
  );
};

const disconnectZendeskIntegration = ({ projectID, integrationID }) => {
  return axios.delete(
    `/projects/${projectID}/integrations/zendesk/${integrationID}`,
  );
};

export {
  disconnectZendeskIntegration,
  getAuthToken,
  setZendeskActions,
  getOrganizations,
  setZendeskAuthToken,
  getBrands,
};
