import { useEffect } from 'react';
import { Gradient } from 'whatamesh';
import './MeshBackground.scss';

interface MeshBackgroundProps {
    selector: string;
}

const MeshBackground: React.FC<MeshBackgroundProps> = ({ selector }) => {
    useEffect(() => {
        const gradient = new Gradient();
        gradient.initGradient(`#${selector}`);
    }, []);

    return (
        <canvas id={selector} className='gradient-canvas' data-js-darken-top={true}></canvas>
    );
};

export default MeshBackground;