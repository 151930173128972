import InstructionCard from 'components/InstructionCard/InstructionCard';
import LinkButton from 'components/LinkButton/LinkButton';
import TabButton from 'components/TabButton/TabButton';
import Gleap from 'gleap';
import { inject, observer } from 'mobx-react';
import { Platforms } from 'models/Platform';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { trackEvent } from 'services/GTagHelper';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { UsersStore } from 'stores/private/UsersStore';
import './CompleteOnboarding.scss';

interface CompleteOnboardingProps {
  usersStore?: UsersStore;
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
  organisationStore?: OrganisationStore;
}

const CompleteOnboarding = ({
  usersStore,
  modalStore,
  projectStore,
  organisationStore,
}: CompleteOnboardingProps) => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState('all');
  const currentProject = projectStore?.currentProject;

  const completeOnboarding = () => {
    trackEvent('skip.tutorial', {
      tutorial_name: 'onboarding',
    });

    usersStore!.updateUser(
      usersStore?.currentUser?.id!,
      {
        completedOnboarding: true,
      },
      true,
    );

    organisationStore!.getMyOrganisations();
    modalStore!.closeModal();

    if (projectStore?.projects && projectStore?.projects.length > 0) {
      navigate(`/projects/${projectStore?.projects[0].id}/`);
    }
  };

  useEffect(() => {
    if (
      projectStore &&
      projectStore.projects &&
      projectStore.projects.length > 0
    ) {
      projectStore!.loadProjectById(projectStore.projects[0].id, false, true);
    }
  }, [projectStore?.projects]);

  const openInstruction = (platform: any) => {
    modalStore?.openModalSmart(MODALTYPE.SDK_INSTALLATION_TUTORIAL, platform);
  };

  if (!currentProject) {
    return (
      <>
        <div className="onboarding-cont-fancy onboarding-cont onboarding-cont--autoheight">
          <div className="onboarding-cont-main">
            <div className="onboarding-title">Almost there! 🎉</div>
            <div className="mt-20">
              <LinkButton
                label="Complete onboarding"
                onClick={() => {
                  completeOnboarding();
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  }

  const renderPlatforms = () => {
    const items = Platforms.filter((item) => {
      if (filter === 'all') {
        return true;
      }
      return item.type.includes(filter);
    });

    return items.map((item, key) => {
      return (
        <InstructionCard
          data-name={item.title}
          item={item}
          key={key}
          buttonTitle="Start installation"
          onClick={openInstruction}
        />
      );
    });
  };

  return (
    <>
      <div className="onboarding-cont-fancy onboarding-cont onboarding-cont--autoheight">
        <div className="onboarding-cont-main">
          <div className="onboarding-title">Install the Gleap messenger 🎉</div>
          <div className="onboarding-text">
            Only one step to go: install the Gleap messenger. If you need help
            with the installation, feel free to{' '}
            <a
              href="#"
              onClick={() => {
                Gleap.open();
              }}
            >
              reach out to us
            </a>
            .<br />
            <br />
            Not installing the SDK yourself?{' '}
            <a
              href="#"
              onClick={() => {
                modalStore!.openModalSmart(MODALTYPE.INVITE_PEOPLE);
              }}
            >
              Invite your team members
            </a>{' '}
            and let them install the SDK.
          </div>
          <div className="mt-20">
            <LinkButton
              label="Skip installation"
              onClick={() => {
                trackEvent('skip.installation');
                completeOnboarding();
              }}
            />
          </div>
        </div>
      </div>
      <div className="onboarding-main-sdks">
        <div className="onboarding-main-sdks-cont">
          <div className="onboarding-cards-header">
            <div className="text bold mb-15">
              <b>Choose your platform to get started</b>
            </div>
            <div className="tab-buttons">
              <div className="tab-buttons--inner">
                <TabButton
                  active={filter === 'all'}
                  label="All"
                  onClick={() => {
                    setFilter('all');
                  }}
                />
                <TabButton
                  active={filter === 'websites'}
                  label="Website"
                  onClick={() => {
                    setFilter('websites');
                  }}
                />
                <TabButton
                  active={filter === 'webapps'}
                  label="Web app"
                  onClick={() => {
                    setFilter('webapps');
                  }}
                />
                <TabButton
                  active={filter === 'mobileapps'}
                  label="App"
                  onClick={() => {
                    setFilter('mobileapps');
                  }}
                />
              </div>
            </div>
          </div>
          <div className="platforms mb-30 mt-15">{renderPlatforms()}</div>
        </div>
      </div>
    </>
  );
};

export default inject(
  'usersStore',
  'modalStore',
  'projectStore',
  'organisationStore',
)(observer(CompleteOnboarding));
