export default class MobileCommunicationManager {
  private static _instance: MobileCommunicationManager;
  private callbacks: any = [];

  static getInstance() {
    if (!this._instance) {
      this._instance = new MobileCommunicationManager();
    }
    return this._instance;
  }

  constructor() {
    this.startMessageListener();
  }

  registerCallback(action: string, callback: any) {
    this.callbacks.push({ action, callback });
  }

  startMessageListener() {
    (window as any).fromMobileGleapApp = (messageData) => {
      try {
        const jsonData = JSON.parse(JSON.stringify(messageData));

        const action = jsonData['action'];
        const data = jsonData['data'];

        this.callbacks.forEach((callback: any) => {
          if (callback.action === action) {
            callback.callback(data);
          }
        });
      } catch (err) {}
    };

    // Send initialize ping
    (window as any).messageHandler?.postMessage(
      JSON.stringify({
        action: 'INITIALIZE',
      }),
    );
  }
}
