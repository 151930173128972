import './FeatureRequestCard.scss';
import { DraggableProvided } from 'react-beautiful-dnd';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { ReactComponent as DuplicateIcon } from 'assets/icons/noun-duplicate-3366922-333333.svg';
import { ProjectStore } from 'stores/private/ProjectStore';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { Bug, BugPriorities } from '../../models/Bug';
import { ToolTip } from './ToolTip/ToolTip';
import { getFormFieldValue } from 'helper/FormDataHelper';
import FeatureRequestSubscribeItem from 'components/FeatureRequestSubscribeItem/FeatureRequestSubscribeItem';
import PublicSkeleton from 'components/Skeletons/PublicSkeleton';
import { formatDateYearLater } from 'helper/DateHelper';

interface FeatureRequestCardProps {
  bug: Bug;
  provided: DraggableProvided;
  isDragging: boolean;
  style: any;
  projectStore?: ProjectStore;
}

const getStyle = (provided: DraggableProvided, style: Object | null) => {
  if (!style) {
    return provided.draggableProps.style;
  }

  return {
    ...provided.draggableProps.style,
    ...style,
  };
};

const FeatureRequestCard = ({
  bug,
  provided,
  style,
  projectStore,
}: FeatureRequestCardProps) => {
  const dueDate = bug.dueDate ? moment(bug.dueDate) : null;
  const isDue =
    bug.status !== 'DONE' && dueDate
      ? dueDate.isBefore(moment(), 'date')
      : false;
  const isDueToday =
    bug.status !== 'DONE' && dueDate ? dueDate.isSame(moment(), 'date') : false;

  const renderDueDate = () => {
    if (!dueDate) {
      return null;
    }

    return (
      <div
        className={`due-date ${isDue && 'due-date--isdue'} ${
          isDueToday && 'due-date--isduetoday'
        }`}
      >
        ·
        <i className="fa-solid fa-clock-three" />
        {dueDate?.format('MMM Do')}
      </div>
    );
  };

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      key={`bug${bug.bugId}`}
      id={`bug${bug.bugId}`}
      onClick={() => {
        projectStore!.openFeedbackItem({ shareToken: bug.shareToken });
      }}
      style={getStyle(provided, style)}
    >
      <div
        className={`board-feature-request-card ${
          bug.notificationsUnread ? 'board-feature-request-card--unread' : ''
        } ${isDue && 'board-feature-request-card--isdue'} ${
          isDueToday && 'board-feature-request-card--isduetoday'
        }`}
      >
        <FeatureRequestSubscribeItem featureRequest={bug} />
        <div className="board-feature-request-card-outer">
          <div className="board-feature-request-card__body">
            <div className="board-feature-request-card__body-title">
              {getFormFieldValue({ bug, key: 'title', fallback: 'formText' })}
            </div>
            <div className="board-feature-request-card__body-date">
              {formatDateYearLater(bug.createdAt)}, #{bug.bugId}{' '}
              {renderDueDate()}
            </div>
          </div>
          <div className="board-feature-request-card__foot">
            <div
              className="board-feature-request-card__header__info__dot"
              style={{
                backgroundColor: `${BugPriorities[bug.priority]?.color}20`,
                color: `${BugPriorities[bug.priority]?.color}`,
              }}
            >
              {BugPriorities[bug.priority]?.title}
            </div>
            <div className="board-feature-request-card-tags">
              {bug.tags &&
                bug.tags.map((tagItem) => (
                  <div
                    key={
                      // eslint-disable-next-line no-underscore-dangle
                      projectStore!.currentProject?.feedbackTags.find(
                        (item) => item.label === tagItem,
                      )?._id
                    }
                    className="board-feature-request-card__header__info__dot"
                    style={{
                      backgroundColor: `${
                        projectStore!.currentProject?.feedbackTags.find(
                          (item) => item.label === tagItem,
                        )?.color ?? '#060d25'
                      }33`,
                      color: `${
                        projectStore!.currentProject?.feedbackTags.find(
                          (item) => item.label === tagItem,
                        )?.color
                      }`,
                    }}
                  >
                    {tagItem}
                  </div>
                ))}
            </div>
            {bug.duplicatesCount && bug.duplicatesCount > 0 ? (
              <div className="board-feature-request-card__header__info__count">
                <DuplicateIcon />
                {bug.duplicatesCount}
              </div>
            ) : null}
            <div className="filler" />
            {bug.processingUser && (
              <>
                <UserAvatar
                  small
                  email={bug.processingUser?.email}
                  imageUrl={bug.processingUser?.profileImageUrl}
                />
                <ToolTip
                  label={
                    bug.processingUser.firstName
                      ? bug.processingUser.firstName
                      : 'unknown'
                  }
                />
              </>
            )}
            {!bug.processingUser && (
              <div className="board-feature-request-card__foot__nouser" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default inject('projectStore')(observer(FeatureRequestCard));

interface BoardFeatureRequestCardSkeletonProps {
  width: number | string;
  height?: number;
  provided?: DraggableProvided;
  id?: string;
  style?: any;
}

export const BoardFeatureRequestCardSkeleton = ({
  width,
  height,
  provided,
  id,
  style,
}: BoardFeatureRequestCardSkeletonProps) => {
  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      key={`bug${id}`}
      id={`bug${id}`}
      style={getStyle(provided, style)}
    >
      <div className="board-feature-request-card">
        <PublicSkeleton
          height="48px"
          width="48px"
          style={{
            borderRadius: '8px',
          }}
        />
        <div className="board-feature-request-card-outer">
          <div className="board-feature-request-card__body">
            <div className="board-feature-request-card__body-title">
              <PublicSkeleton height="16px" width="200px" />
            </div>
            <div className="board-feature-request-card__body-date">
              <PublicSkeleton height="14px" width="200px" />
            </div>
          </div>
          <div className="board-feature-request-card__foot">
            <PublicSkeleton height="14px" width="200px" />
            <div className="filler" />
          </div>
        </div>
      </div>
    </div>
  );
};
