import LinkButton from 'components/LinkButton/LinkButton';
import Loading from 'components/Loading/Loading';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import TextInput from 'components/TextInput/TextInput';
import { Title } from 'components/Title/Title';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { INTEGRATION, integrations, localActions } from 'models/Integration';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import { ProjectStore } from 'stores/private/ProjectStore';
import Swal from 'sweetalert2';
import GLEAP_LOGO from '../../../../assets/icons/Gleap.svg';
import ARROW from '../../../../assets/icons/np_arrow_3542562_000000.svg';
import AsanaConfiguration from './ConfigurationComponents/AsanaConfiguration/AsanaConfiguration';
import AworkConfiguration from './ConfigurationComponents/AworkConfiguration/AworkConfiguration';
import AzureConfiguration from './ConfigurationComponents/AzureConfiguration/AzureConfiguration';
import AzureDevopsConfiguration from './ConfigurationComponents/AzureDevopsConfiguration/AzureDevopsConfiguration';
import ClickupConfiguration from './ConfigurationComponents/ClickupConfiguration/ClickupConfiguration';
import EmailConfiguration from './ConfigurationComponents/EmailConfiguration/EmailConfiguration';
import GithubConfiguration from './ConfigurationComponents/GithubConfiguration/GithubConfiguration';
import GitlabConfiguration from './ConfigurationComponents/GitlabConfiguration/GitlabConfiguration';
import HelpscoutConfiguration from './ConfigurationComponents/HelpscoutConfiguration/HelpscoutConfiguration';
import HubSpotConfiguration from './ConfigurationComponents/HubSpotConfiguration/HubSpotConfiguration';
import JiraConfiguration from './ConfigurationComponents/JiraConfiguration/JiraConfiguration';
import LinearConfiguration from './ConfigurationComponents/LinearConfiguration/LinearConfiguration';
import NotionConfiguration from './ConfigurationComponents/NotionConfiguration/NotionConfiguration';
import PlutioConfiguration from './ConfigurationComponents/PlutioConfiguration/PlutioConfiguration';
import ShortcutConfiguration from './ConfigurationComponents/ShortcutConfiguration/ShortcutConfiguration';
import SlackConfiguration from './ConfigurationComponents/SlackConfiguration/SlackConfiguration';
import TrelloConfiguration from './ConfigurationComponents/TrelloConfiguration/TrelloConfiguration';
import WebhookConfiguration from './ConfigurationComponents/WebhookConfiguration/WebhookConfiguration';
import ZapierConfiguration from './ConfigurationComponents/ZapierConfiguration/ZapierConfiguration';
import ZendeskConfiguration from './ConfigurationComponents/ZendeskConfiguration/ZendeskConfiguration';
import './ConfigureIntegration.scss';
import StripeConfiguration from './ConfigurationComponents/StripeConfiguration/StripeConfiguration';
import { setProperty } from 'helper/AssignObjectKeysHelper';
import DiscordConfiguration from './ConfigurationComponents/DiscordConfiguration/DiscordConfiguration';

const defaultActionTypes = ['BUG', 'FEATURE_REQUEST', 'INQUIRY'];

interface ConfigureIntegrationProps {
  projectStore?: ProjectStore;
}

const ConfigureIntegration = ({ projectStore }: ConfigureIntegrationProps) => {
  const navigate = useNavigate();
  const { projectId, integration, integrationID }: any = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentIntegration, setCurrentIntegration] = useState({} as any);
  const [integrationIsValid, setIntegrationIsValid] = useState(false);
  const [actions, setActions] = useState([
    { local: null, integration: null, actionTypes: [], integrationInfo: {} },
  ]);
  const [toggle, setToggle] = useState(false);
  const currentProject = projectStore?.currentProject;
  const projectTypes = currentProject?.projectTypes
    ? [
        ...JSON.parse(JSON.stringify(currentProject?.projectTypes)),
        ...[{ name: 'Survey', type: 'SURVEY' }],
      ]
    : [];

  if (!integrationID) {
    navigate(`/projects/${projectId}/integrations`, { replace: true });
  }

  let currentProjectIntegration: any = {};

  if (
    currentProject?.integrations &&
    integration != null &&
    integrationID != null &&
    currentProject.integrations.hasOwnProperty(integration) &&
    currentProject.integrations[integration]?.hasOwnProperty(integrationID)
  ) {
    currentProjectIntegration =
      currentProject.integrations[integration][integrationID];
  } else {
    currentProjectIntegration = {};
  }

  useEffect(() => {
    projectStore!.loadProjectById(projectId, true);

    const generalActions: any = getPreparedGeneralActions();
    setActions(generalActions);
    validate();
  }, []);

  useEffect(() => {
    initialize();
  }, [currentProject]);

  // @ts-ignore
  const initialize = async () => {
    setIsLoading(true);

    if (!integration) {
      toast.error('Something went wrong!');
      navigate(`/projects/${projectId}/integrations`, { replace: true });
      return;
    }
    const integrationsData = integrations[integration];
    if (!integrationsData) {
      toast.error('Something went wrong!');
      navigate(`/projects/${projectId}/integrations`, { replace: true });
      return;
    }

    setCurrentIntegration(integrationsData);

    if (
      !currentProject?.integrations ||
      !currentProject?.integrations[integration]
    ) {
      return;
    }

    const integrationData = {
      ...currentProject?.integrations[integration][integrationID],
    };

    if (integrationData?.actions) {
      setActions(JSON.parse(JSON.stringify(integrationData.actions)));
    }

    setIsLoading(false);
    validate();
  };

  const getPreparedGeneralActions = () => {
    switch (integration) {
      case INTEGRATION.STRIPE:
        return [
          {
            local: 'NONE',
            integration: 'NONE',
            actionTypes: [],
            integrationInfo: {},
          },
        ];

      case INTEGRATION.ASANA:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE_TASK',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'UPDATED',
            integration: 'UPDATE_TASK',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'DELETED',
            integration: 'DELETE_TASK',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND_MESSAGE_TO_TASK',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
        ];

      case INTEGRATION.AWORK:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE_TASK',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'DELETED',
            integration: 'DELETE_TASK',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND_MESSAGE_TO_TASK',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
        ];

      case INTEGRATION.AZURE:
        return [
          {
            local: 'CREATED',
            integration: 'SEND_MESSAGE_TO_CHANNEL',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND_MESSAGE_TO_THREAD',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
        ];

      case INTEGRATION.CLICKUP:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE_TASK',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'UPDATED',
            integration: 'UPDATE_TASK',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'DELETED',
            integration: 'DELETE_TASK',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND_MESSAGE_TO_TASK',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
        ];

      case INTEGRATION.GITHUB:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE_ISSUE',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'UPDATED',
            integration: 'UPDATE_ISSUE',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND_MESSAGE_TO_ISSUE',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
        ];
      case INTEGRATION.GITLAB:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE_ISSUE',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'UPDATED',
            integration: 'UPDATE_ISSUE',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND_MESSAGE_TO_ISSUE',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
        ];
      case INTEGRATION.HUBSPOT:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE_TICKET',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'UPDATED',
            integration: 'UPDATE_TICKET',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'DELETED',
            integration: 'DELETE_TICKET',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND_MESSAGE_TO_TASK',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
        ];
      case INTEGRATION.HELPSCOUT:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE_CONVERSATION',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
        ];
      case INTEGRATION.JIRA:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE_TASK',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'UPDATED',
            integration: 'UPDATE_TASK',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'DELETED',
            integration: 'DELETE_TASK',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND_MESSAGE_TO_TASK',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
        ];
      case INTEGRATION.NOTION:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE_TASK',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
        ];
      case INTEGRATION.PLUTIO:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE_TASK',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'UPDATED',
            integration: 'UPDATE_TASK',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'DELETED',
            integration: 'DELETE_TASK',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND_MESSAGE_TO_TASK',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
        ];
      case INTEGRATION.SHORTCUT:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE_STORY',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'UPDATED',
            integration: 'UPDATE_STORY',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'DELETED',
            integration: 'DELETE_STORY',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND_MESSAGE_TO_STORY',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
        ];
      case INTEGRATION.SLACK:
        return [
          {
            local: 'CREATED',
            integration: 'SEND_MESSAGE_TO_CHANNEL',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND_MESSAGE_TO_THREAD',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'PROCESSING_USER_CHANGED',
            integration: 'PROCESSING_USER_CHANGED',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
        ];
      case INTEGRATION.TRELLO:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE_TASK',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'UPDATED',
            integration: 'UPDATE_TASK',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'DELETED',
            integration: 'DELETE_TASK',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND_MESSAGE_TO_TASK',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
        ];
      case INTEGRATION.WEBHOOK:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'UPDATED',
            integration: 'UPDATE',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'DELETED',
            integration: 'DELETE',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND_MESSAGE',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'PROCESSING_USER_CHANGED',
            integration: 'UPDATE',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
        ];
      case INTEGRATION.EMAIL:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
        ];
      case INTEGRATION.ZENDESK:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE_TICKET',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'DELETED',
            integration: 'DELETE_TICKET',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND_MESSAGE_TO_TICKET',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
        ];
      case INTEGRATION.ZAPIER:
        return [
          {
            local: 'CREATED',
            integration: 'SEND',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'UPDATED',
            integration: 'SEND',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'DELETED',
            integration: 'SEND',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
        ];
      case INTEGRATION.AZURE_DEVOPS:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE_WORK_ITEM',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'UPDATED',
            integration: 'UPDATE_WORK_ITEM',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'DELETED',
            integration: 'DELETE_WORK_ITEM',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND_MESSAGE_TO_WORK_ITEM',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
        ];
      case INTEGRATION.LINEAR:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE_ISSUE',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'UPDATED',
            integration: 'UPDATE_ISSUE',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'DELETED',
            integration: 'DELETE_ISSUE',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND_MESSAGE_TO_ISSUE',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
        ];
      case INTEGRATION.SALESFORCE:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE_TICKET',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'UPDATED',
            integration: 'UPDATE_TICKET',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'DELETED',
            integration: 'DELETE_TICKET',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND_MESSAGE_TO_TICKET',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
        ];

      case INTEGRATION.DISCORD:
        return [
          {
            local: 'CREATED',
            integration: 'SEND_MESSAGE_TO_CHANNEL',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND_MESSAGE_TO_THREAD',
            actionTypes: defaultActionTypes,
            integrationInfo: {},
          },
        ];
      default:
        return [
          {
            local: null,
            integration: null,
            actionTypes: [],
            integrationInfo: {},
          },
        ];
    }
  };

  const handleGeneralIntegrationInfo = (integrationInfo) => {
    for (let i = 0; i < actions.length; i++) {
      actions[i].integrationInfo = integrationInfo;
    }
  };

  const handleGeneralLocalAction = (actionTypes: any) => {
    for (let i = 0; i < actions.length; i++) {
      actions[i].actionTypes = actionTypes;
    }
  };

  const validate = () => {
    let currentIntegrationIsValid = true;
    for (let i = 0; i < actions.length; i++) {
      const action = actions[i];

      if (
        !action.local ||
        action.local === '' ||
        !action.integration ||
        action.integration === ''
      ) {
        currentIntegrationIsValid = false;
        break;
      }

      if (!currentIntegration.validate(action.integrationInfo)) {
        currentIntegrationIsValid = false;
        break;
      }
    }

    setIntegrationIsValid(currentIntegrationIsValid);
  };

  const saveIntegration = async () => {
    setIsSaving(true);

    try {
      await integrations[integration].save(
        projectId,
        integrationID,
        actions,
        currentProject?.integrations[integration][integrationID].settings,
      );
      await projectStore?.loadProjectById(projectId, true);
      toast.success('Sucessfully saved integration');
      navigate(`/projects/${projectId}/integrations`, { replace: true });
    } catch (err: any) {
      toast.error('Could not save integration');
    }

    setIsSaving(false);
  };

  const getIntegrationConfiguarionComponent = (integrationName, index) => {
    switch (integrationName) {
      case INTEGRATION.ASANA:
        return (
          <AsanaConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.AWORK:
        return (
          <AworkConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.AZURE:
        return (
          <AzureConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.CLICKUP:
        return (
          <ClickupConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.GITHUB:
        return (
          <GithubConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.GITLAB:
        return (
          <GitlabConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.HUBSPOT:
        return (
          <HubSpotConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.HELPSCOUT:
        return (
          <HelpscoutConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.JIRA:
        return (
          <JiraConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.NOTION:
        return (
          <NotionConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.PLUTIO:
        return (
          <PlutioConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.SHORTCUT:
        return (
          <ShortcutConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.SLACK:
        return (
          <SlackConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.TRELLO:
        return (
          <TrelloConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.WEBHOOK:
        return (
          <WebhookConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.ZAPIER:
        return (
          <ZapierConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.ZENDESK:
        return (
          <ZendeskConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.AZURE_DEVOPS:
        return (
          <AzureDevopsConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.LINEAR:
        return (
          <LinearConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.SALESFORCE:
        return (
          <div className="text">
            To ensure our integration functions correctly, please add a
            Gleap_Content custom field as a Rich Text Area to your Salesforce
            Case object, essential for storing integration-specific data.
          </div>
        );

      case INTEGRATION.CALENDLY:
        return (
          <div className="text">
            ✅ You are connected to your Calendly account. Happy scheduling 🎉
          </div>
        );

      case INTEGRATION.STRIPE:
        return (
          <StripeConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              runInAction(() => {
                handleGeneralIntegrationInfo(integrationInfo);
                validate();
              });
            }}
          />
        );

      case INTEGRATION.EMAIL:
        return (
          <EmailConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.DISCORD:
        return (
          <DiscordConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      default:
        return <></>;
    }
  };

  const buildIntegrationActionItem = (action, index) => {
    return (
      <div className="integration-action-row">
        <div className="action-item-wrapper">
          <Title className="mb-5" label="When this happens" />
          <div className="action-item">
            <SelectDropDown
              label="Type"
              labelPropertyName="name"
              valuePropertyName="type"
              selectedItem={projectTypes.filter((element) => {
                const actionIndex = action.actionTypes.findIndex(
                  (item) => item === element.type,
                );

                if (actionIndex <= -1) {
                  return false;
                }

                return true;
              })}
              items={projectTypes}
              onChange={(selectedItems: any[]) => {
                const values: any[] = [];
                if (!selectedItems) {
                  action.actionTypes = [];
                  validate();
                  setToggle(!toggle);
                  return;
                }

                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < selectedItems.length; i++) {
                  values.push(selectedItems[i].type);
                }
                action.actionTypes = values;
                validate();
                setToggle(!toggle);
              }}
              isMulti
            />
            <div className="mr-15" />
            <SelectDropDown
              label="Action"
              selectedItem={localActions.find(
                (element) => element.value === action.local,
              )}
              items={localActions}
              onChange={(selectedItem) => {
                action.local = selectedItem.value;
                validate();
                setToggle(!toggle);
              }}
            />
          </div>
        </div>
        <div className="dotted-line" />
        <div className="action-item-wrapper">
          <Title className="mb-5" label="Then do this" />
          <div className="action-item">
            <SelectDropDown
              label="Action"
              selectedItem={
                currentIntegration?.actions
                  ? currentIntegration.actions.find(
                      (element) => element.value === action.integration,
                    )
                  : null
              }
              items={currentIntegration.actions}
              onChange={(selectedItem) => {
                action.integration = selectedItem.value;
                validate();
                setToggle(!toggle);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const buildGeneralIntegrationActionContent = () => {
    if (getIntegrationConfiguarionComponent(integration, 0) == null) {
      return <></>;
    }

    return (
      <div className="options-group" style={{ width: '100%' }}>
        <div className="options-group-header">
          {currentIntegration.title} configuration
        </div>
        {getIntegrationConfiguarionComponent(integration, 0)}
      </div>
    );
  };

  const buildCooseIntegrationActionContent = () => {
    return (
      <PageContainer>
        <PageHeadLine
          title={`${currentIntegration.title} Integration`}
          onActionBack={() => {
            navigate(`/projects/${projectId}/integrations`);
          }}
        />
        <PageContent hasTitle isMediumBoxed>
          <div className="choose-integation-action-container">
            <div className="integration-info-content-header">
              <img
                className="integration-preview-image"
                src={GLEAP_LOGO}
                alt="Gleap logo"
              />
              <img className="arrow-icon" src={ARROW} alt="Integrations" />
              <img
                className="integration-preview-image"
                src={currentIntegration.icon}
                alt="Integration logo"
              />
            </div>
            {buildGeneralIntegrationActionContent()}
            <div className="options-group" style={{ width: '100%' }}>
              <div className="options-group-header">Advanced</div>
              <>
                <TextInput
                  className="mb-30"
                  error=""
                  placeholder="Name your integration"
                  label="Name"
                  initalValue={currentProjectIntegration?.settings?.label}
                  onChange={(val) => {
                    setProperty(
                      currentProjectIntegration,
                      'settings.label',
                      val,
                    );
                    validate();
                  }}
                />
                {integration !== INTEGRATION.ZAPIER &&
                  integration !== INTEGRATION.STRIPE &&
                  integration !== INTEGRATION.CALENDLY && (
                    <>
                      <div className="switch-container mb-20">
                        <Switch
                          width={40}
                          onColor="#2142E7"
                          height={20}
                          checkedIcon={false}
                          uncheckedIcon={false}
                          onChange={(checked) => {
                            runInAction(() => {
                              if (!checked) {
                                handleGeneralLocalAction([]);
                              } else {
                                handleGeneralLocalAction(
                                  projectTypes.map((item) => item.type),
                                );
                              }

                              validate();
                              setToggle(!toggle);
                            });
                          }}
                          checked={
                            actions && actions[0]?.actionTypes.length > 0
                          }
                        />
                        <span>
                          Automatically send feedback to{' '}
                          {currentIntegration.title}
                        </span>
                      </div>
                      {(!actions || actions[0]?.actionTypes.length === 0) && (
                        <div className="switch-container">
                          <Switch
                            width={40}
                            onColor="#2142E7"
                            height={20}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            onChange={(checked) => {
                              runInAction(() => {
                                setProperty(
                                  currentProjectIntegration,
                                  'settings.forwardNewCommentsAfterSubmission',
                                  checked,
                                );
                              });
                            }}
                            checked={
                              currentProjectIntegration?.settings
                                ?.forwardNewCommentsAfterSubmission
                                ? true
                                : false
                            }
                          />
                          <span>
                            After manually submitting a ticket, automatically
                            forward new comments to the integration.
                          </span>
                        </div>
                      )}
                    </>
                  )}
                {actions &&
                  actions.length > 0 &&
                  actions[0]?.actionTypes.length > 0 && (
                    <SelectDropDown
                      labelPropertyName="name"
                      valuePropertyName="type"
                      className="mt-20"
                      label={`Send following types to ${currentIntegration.title} Integration`}
                      selectedItem={projectTypes.filter((element) => {
                        const actionIndex = actions[0]?.actionTypes.findIndex(
                          (item) => item === element.type,
                        );
                        if (actionIndex <= -1) {
                          return false;
                        }
                        return true;
                      })}
                      items={projectTypes}
                      onChange={(selectedItems: any[]) => {
                        runInAction(() => {
                          const values: any[] = [];
                          if (!selectedItems) {
                            handleGeneralLocalAction([]);
                            validate();
                            setToggle(!toggle);
                            return;
                          }
                          // eslint-disable-next-line no-plusplus
                          for (let i = 0; i < selectedItems.length; i++) {
                            values.push(selectedItems[i].type);
                          }
                          handleGeneralLocalAction(values);
                          validate();
                          setToggle(!toggle);
                        });
                      }}
                      isMulti
                    />
                  )}
                {((actions && actions[0]?.actionTypes.length > 0) ||
                  integration === INTEGRATION.ZAPIER) && (
                  <>
                    <div className="switch-container mb-20 mt-40">
                      <Switch
                        width={40}
                        onColor="#2142E7"
                        height={20}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        onChange={(checked) => {
                          runInAction(() => {
                            setProperty(
                              currentProjectIntegration,
                              'settings.autoChangeStatus',
                              checked,
                            );
                          });
                        }}
                        checked={
                          currentProjectIntegration?.settings?.autoChangeStatus
                            ? true
                            : false
                        }
                      />
                      <span>
                        Change feedback status to done when sent to{' '}
                        {currentIntegration.title}.
                      </span>
                    </div>
                    <div className="switch-container mb-20">
                      <Switch
                        width={40}
                        onColor="#2142E7"
                        height={20}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        onChange={(checked) => {
                          runInAction(() => {
                            setProperty(
                              currentProjectIntegration,
                              'settings.sendDuplicates',
                              checked,
                            );
                          });
                        }}
                        checked={
                          currentProjectIntegration?.settings?.sendDuplicates
                            ? true
                            : false
                        }
                      />
                      <span>Send duplicates to this integration as well.</span>
                    </div>
                    <div className="switch-container mb-20">
                      <Switch
                        width={40}
                        onColor="#2142E7"
                        height={20}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        onChange={(checked) => {
                          runInAction(() => {
                            setProperty(
                              currentProjectIntegration,
                              'settings.hideSessionData',
                              checked,
                            );
                          });
                        }}
                        checked={
                          currentProjectIntegration?.settings?.hideSessionData
                            ? true
                            : false
                        }
                      />
                      <span>Don't send personal data</span>
                    </div>
                    <div className="switch-container">
                      <Switch
                        width={40}
                        onColor="#2142E7"
                        height={20}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        onChange={(checked) => {
                          runInAction(() => {
                            setProperty(
                              currentProjectIntegration,
                              'settings.disableInternalNotes',
                              checked,
                            );
                          });
                        }}
                        checked={
                          currentProjectIntegration?.settings
                            ?.disableInternalNotes
                            ? true
                            : false
                        }
                      />
                      <span>Don't send internal notes</span>
                    </div>
                  </>
                )}
              </>
            </div>

            <div className="integration-buttons mt-20">
              <PrimaryButton
                className="mb-30"
                label="Save integration"
                onClick={saveIntegration}
                isLoading={isSaving}
                disabled={!integrationIsValid || isSaving}
              />
              <LinkButton
                className="danger-button mb-30"
                label="Delete"
                onClick={() => {
                  Swal.fire({
                    text: 'Do you really want to delete this integration?',
                    showCancelButton: true,
                    confirmButtonText: `Yes`,
                    denyButtonText: `No`,
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      await currentIntegration.disconnect(
                        projectId,
                        integrationID,
                      );
                      toast.success('Sucessfully deleted integration');
                      navigate(`/projects/${projectId}/integrations`, {
                        replace: true,
                      });
                      projectStore?.loadProjectById(projectId, true);
                    }
                  });
                }}
              />
            </div>
          </div>
        </PageContent>
      </PageContainer>
    );
  };

  if (isLoading) {
    return <Loading className="loading-item" />;
  }

  return buildCooseIntegrationActionContent();
};

export default inject('projectStore')(observer(ConfigureIntegration));
