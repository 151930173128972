import axios from './Axios';

const getPublicNews = (
  apiKey: string,
  page: number,
  gleapId?: string,
  gleapHash?: string,
  lang?: string,
) => {
  return axios.get(`/outbound/news/shared?gleapId=${gleapId}&gleapHash=${gleapHash}&lang=${lang}&page=${page}`, {
    headers: {
      'api-token': apiKey,
      'gleap-id': gleapId ?? '',
      'gleap-hash': gleapHash ?? '',
    },
  });
};

const getPublicNewsDetails = (
  apiKey: string,
  newsId: string,
  gleapId?: string,
  gleapHash?: string,
  lang?: string,
) => {
  return axios.get(`/outbound/news/shared/${newsId}?gleapId=${gleapId}&gleapHash=${gleapHash}&lang=${lang}`, {
    headers: {
      'api-token': apiKey,
      'gleap-id': gleapId ?? '',
      'gleap-hash': gleapHash ?? '',
    },
  });
};

export { getPublicNews, getPublicNewsDetails };
