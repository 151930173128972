import { getFirebaseToken } from 'FirebaseConfig';
import Gleap from 'gleap';
import { makeAutoObservable, runInAction } from 'mobx';
import { SocialButtonType } from 'models/Enums';
import { Invitation } from 'models/Invitations';
import {
  FCM_TOPIC,
  PostMessageAction,
  PostMessageData,
} from 'models/PostMessageData';
import { User } from 'models/User';
import { toast } from 'react-toastify';
import { trackUser, trackEvent } from 'services/GTagHelper';
import {
  answerInvitation,
  getMyInvitations,
  getOrganisation,
} from 'services/OrganisationHttpService';
import { resetPassword } from 'services/ResetPasswordHttpService';
import {
  changePassword,
  delete2FADevice,
  deleteUser,
  enable2FA,
  getCelloToken,
  loginWithEmailAndPassword,
  loginWithGithub,
  loginWithGoogle,
  me,
  register,
  registerFcmToken,
  resend,
  unregisterFcmToken,
  updateUser,
  verify2FA,
  verifyAccount,
} from 'services/UserHttpService';
import WebSocketHelper, { WEBSOCKET_EVENTS } from 'services/WebSocketService';
import { injectHeader } from '../../services/Axios';
import moment from 'moment';

export const getRandomStep = () => {
  const items = ['flow1', 'flow2'];
  return items[Math.floor(Math.random() * items.length)];
};

export class UsersStore {
  currentUser: User | undefined;
  isUpdatingCurrentUser = false;
  isLoadingCurrentUser = false;
  pusherState?: string = undefined;
  loading = true;
  celloLoaded = false;
  provider: SocialButtonType | undefined = undefined;
  stores: any = {};
  skippedInvitations = false;
  invitations: Invitation[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setStores(stores) {
    this.stores = stores;
  }

  onEvent = (event: string, data: any) => {
    if (event === WEBSOCKET_EVENTS.USER_INVITATION) {
      this.findInvitations();
    }
    if (event === WEBSOCKET_EVENTS.USER_ONBOARDING) {
      trackEvent('complete.tutorial', {
        tutorial_name: 'onboarding',
      });

      setTimeout(() => {
        this.refreshUser();
        this.stores.navigate('/dashboard');
        window.location.reload();
      }, 1000);
    }
  };

  skipInvitations() {
    this.skippedInvitations = true;
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  setInvitations = (invitations) => {
    this.invitations = invitations;
  };

  findInvitations = async () => {
    const response = await getMyInvitations();
    this.setInvitations(response.data as Invitation[]);
  };

  acceptInvitation = async (id: string, accpet: boolean) => {
    const response = await answerInvitation(id, accpet);
    if (response.status === 200) {
      this.stores.projectStore.getProjects();
      this.stores.organisationStore.getMyOrganisations();
      this.findInvitations();

      // Refresh user if he hasn't completed the onboarding yet.
      if (!this.currentUser?.completedOnboarding) {
        this.refreshUser();
      }

      toast.success(
        accpet ? 'Invitation accepted 🎉' : 'Invitation declined 🎉',
      );
      this.stores.navigate('/dashboard');
    } else {
      toast.error('Something went wrong 🤕');
    }
  };

  isTokenAvailable = () => {
    try {
      const token = localStorage.getItem('@bbtoken');
      if (!token || token === '') {
        return false;
      }
      return true;
    } catch (exp) {}
  };

  initializePusher = (user: any) => {
    try {
      if (
        !(
          window.location.href &&
          (window.location.href.includes('sharedboard') ||
            window.location.href.includes('share') ||
            window.location.href.includes('unsubscribe'))
        )
      ) {
        // Initialize websockets
        const token = localStorage.getItem('@bbtoken');
        WebSocketHelper.getInstance().setOnStatusChange((state) => {
          runInAction(() => {
            this.pusherState = state;
          });
        });
        WebSocketHelper.getInstance().connect(token ?? '', user?.id ?? '');
      }
    } catch (exp) {}
  };

  unloadCello = () => {
    this.celloLoaded = false;

    try {
      if ((window as any).Cello) {
        (window as any).Cello('shutdown');
      }
    } catch (exp) {}
  };

  loadCello = async (user: User) => {
    const self = this;

    // Initialize Cello.
    getCelloToken().then((resp) => {
      try {
        if (resp.data.success) {
          runInAction(() => {
            self.celloLoaded = true;
          });

          const { token, productId } = resp.data;
          (window as any).cello = (window as any).cello || { cmd: [] };
          (window as any).cello.cmd.push((cello) =>
            cello.boot({
              productId: productId,
              token: token,
              customLauncherSelector: '.cello-launcher',
              hideDefaultLauncher: true,
              announcement: {
                selector: '.cello-launcher',
                position: 'right',
              },
              productUserDetails: {
                firstName: user.firstName,
                lastName: user.lastName,
                fullName: `${user.firstName} ${user.lastName}`,
                email: user.email,
              },
              onTokenExpiring: () => {
                self.loadCello(user);
              },
            }),
          );
        }
      } catch (exp) {}
    });
  };

  checkUserAuthState = async () => {
    if (!this.isTokenAvailable()) {
      return null;
    }

    injectHeader();

    const user = await this.refreshUser();
    if (user) {
      this.initializePusher(user);

      const token = localStorage.getItem('@bbtoken');
      if (!token || token === '') {
        this.logout();
        return null;
      }
    }

    return user;
  };

  setIsLoadingCurrentUser = (isLoadingCurrentUser: boolean) => {
    this.isLoadingCurrentUser = isLoadingCurrentUser;
  };

  refreshUser = async () => {
    this.setIsLoadingCurrentUser(true);
    try {
      const response = await me();
      if (response.status === 200) {
        const user = response.data as User;
        this.setCurrentUser(user);
        this.stores.organisationStore.getMyOrganisations();
        this.stores.projectStore.getProjects();
        this.setIsLoadingCurrentUser(false);
        return user;
      }
    } catch (err: any) {
      if (
        err &&
        err.response &&
        err.response.status &&
        err.response.status === 401
      ) {
        Gleap.clearIdentity();
        localStorage.clear();
        window.location.reload();
      }
    }
    this.setIsLoadingCurrentUser(false);
    return null;
  };

  login = async (email: string, password: string) => {
    this.setLoading(true);
    try {
      const auth: any = {
        username: email,
        password: encodeURIComponent(password),
      };
      const { data } = await loginWithEmailAndPassword({ authData: auth });

      let loginData = data;

      // Check if requries 2FA
      if (data.requires2FA) {
        const credentials = await this.loginWith2FA({ data: data.credentials });

        const twoFactorResponse = await loginWithEmailAndPassword({
          authData: auth,
          twoFactorAuthentication: credentials,
        });
        loginData = twoFactorResponse.data;
      }

      const user = loginData.user as User;
      this.setCurrentUser(user);

      this.afterLoginAction(loginData.token);

      trackEvent('login', {
        method: 'email',
      });

      this.setLoading(false);
    } catch (err: any) {
      if (err?.response?.status === 401) {
        toast.error('E-Mail or password incorrect. Please try again.');
      } else {
        toast.error('Something went wrong. Please try it again.');
      }
      this.setLoading(false);
    }
  };

  loginGithub = async (code: string) => {
    this.setLoading(true);
    try {
      const response = await loginWithGithub(code);
      const { data } = response;
      await this.afterLoginAction(data.token);
      this.setLoading(false);
      this.setLoading(false);
    } catch (err: any) {
      this.stores.navigate('/login');
      this.setLoading(false);
    }
  };

  loginGoogle = async (code: string) => {
    this.setLoading(true);
    try {
      const { data } = await loginWithGoogle({ code });

      let loginData = data;

      // Check if requries 2FA
      if (data.requires2FA) {
        const credentials = await this.loginWith2FA({ data: data.credentials });

        const twoFactorResponse = await loginWithGoogle({
          code,
          twoFactorAuthentication: credentials,
        });
        loginData = twoFactorResponse.data;
      }

      const user = loginData.user as User;

      this.setCurrentUser(user);

      this.afterLoginAction(loginData.token);

      // Check if this was a signup.
      try {
        const createdAt = moment(user.createdAt);
        if (user.confirmed && moment().diff(createdAt, 'minutes') <= 2) {
          trackEvent('sign_up', {
            method: 'google',
          });
        } else {
          trackEvent('login', {
            method: 'google',
          });
        }
      } catch (exp) {}

      this.setLoading(false);
    } catch (err: any) {
      this.stores.navigate('/login');
      this.setLoading(false);
    }
  };

  register = async (
    token: string,
    email: string,
    password: string,
    name: string,
  ) => {
    this.setLoading(true);
    try {
      const response: any = await register(
        token,
        email,
        password,
        name,
        getRandomStep(),
      );
      if (response.status === 201) {
        const { data } = response;

        this.setCurrentUser(data.user);
        this.setLoading(false);

        await this.afterLoginAction(data.token, '/dashboard');
      }
    } catch (err: any) {
      if (err && err.response && err.response.status === 409) {
        this.setLoading(false);

        if (
          err.response.data &&
          err.response.data.param &&
          err.response.data.param === 'code'
        ) {
          toast.error('Code already used 😳');
        } else if (
          err.response.data &&
          err.response.data.name &&
          err.response.data.name === 'ValidationError'
        ) {
          toast.error('Email or password invalid 😳');
        } else if (
          err.response.data &&
          err.response.data.param &&
          err.response.data.param === 'user'
        ) {
          toast.error('Email or password invalid 😳');
        } else {
          toast.error('User already exists!');
        }
      } else {
        toast.error('Registration failed');
      }
      this.setLoading(false);
    }
  };

  identifyUser = async (currentUser, name) => {
    let userData = {
      name: name,
      email: currentUser.email,
    };
    try {
      const user = Gleap.getIdentity();

      if (!user?.companyId && currentUser?.organisation) {
        const orga = await getOrganisation(currentUser.organisation);
        if (orga?.data?.id) {
          userData = Object.assign(userData, {
            companyId: orga.data.id,
            companyName: orga.data.name,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }

    Gleap.identify(currentUser.id, userData);
  };

  setCurrentUser = async (currentUser: any) => {
    this.currentUser = currentUser;

    if (currentUser) {
      (window as any).messageHandler?.postMessage(
        JSON.stringify({
          action: PostMessageAction.SUBSCRIBE_TO_TOPIC,
          data: {
            topic: `${FCM_TOPIC.USER}-${currentUser.id}`,
          },
        } as PostMessageData),
      );

      const name = `${currentUser.firstName} ${currentUser.lastName}`;

      this.identifyUser(currentUser, name);

      trackUser(currentUser.id, currentUser.email);

      this.stores.paddleStore.initializePaddleSignedIn({
        userEmail: currentUser.email,
      });
    }
  };

  setIsUpdatingCurrentUser = (isUpdatingCurrentUser: boolean) => {
    this.isUpdatingCurrentUser = isUpdatingCurrentUser;
  };

  updateUser = async (id: string, data: any, hideToast?: boolean) => {
    if (this.currentUser) {
      this.setIsUpdatingCurrentUser(true);
      try {
        const response = await updateUser(id, data);
        if (response && response.status === 200) {
          this.setCurrentUser(response.data as User);
          if (!hideToast) {
            toast.success('Profile updated ✓');
          }
        }
      } catch (exp) {
        if (!hideToast) {
          toast.error('Upps. Something went wrong.');
        }
      }
      this.setIsUpdatingCurrentUser(false);
    }
  };

  deleteUser = async () => {
    try {
      await deleteUser();
      this.logout();
    } catch (err: any) {
      if (err.response.status === 404) {
        toast.error('User not found in organisation.');
      } else if (err.response.status === 403) {
        toast.error(
          'User could not be deleted. Please delete your company first.',
        );
      } else {
        toast.error('Could not delete user. Please try it again later.');
      }
    }
  };

  changeUserPassword = async (
    id: string,
    oldPassword: string,
    newPassword: string,
  ) => {
    await changePassword(id, newPassword, oldPassword);
  };

  oauthLogin = (type: SocialButtonType) => {
    this.provider = type;
  };

  resend = async () => {
    if (this.currentUser) {
      try {
        await resend(this.currentUser);
        toast.success('Verification code sent 📬.');
      } catch (exp) {
        toast.error('Too many attemps. Please try again in 5 minutes.');
      }
    }
  };

  verifyCode = async (code: string) => {
    this.setLoading(true);
    try {
      const response = await verifyAccount(code, this.currentUser!.email);
      if (!response) {
        return false;
      }

      if (response.status === 200) {
        await this.refreshUser();

        // Track signup after verification.
        trackEvent('sign_up', {
          method: 'email',
        });

        this.setLoading(false);
        return true;
      }
    } catch (err: any) {
      if (err.response.status === 304) {
        toast.error('Your code expired.');
      } else {
        toast.error('Your code is invalid. Please try again.');
      }
      this.setLoading(false);
    }

    return false;
  };

  logout = (ignoreRedirect: boolean = false, redirect = true) => {
    (window as any).messageHandler?.postMessage(
      JSON.stringify({
        action: PostMessageAction.UNSUBSCRIBE_FROM_TOPIC,
        data: {
          topic: `${FCM_TOPIC.USER}-${this.currentUser?.id}`,
        },
      } as PostMessageData),
    );

    this.unRegisterFcmToken();

    localStorage.removeItem('@bbtoken');
    localStorage.removeItem('@verifyTime');
    this.setCurrentUser(undefined);
    Gleap.clearIdentity();

    if (redirect) {
      const currentPath = window.location.pathname;
      if (currentPath && !ignoreRedirect) {
        this.stores.navigate(`/?redirect=${currentPath}`, { replace: true });
      } else {
        this.stores.navigate('/', { replace: true });
      }
    }

    window.location.reload();
  };

  afterLoginAction = async (token, route?: string) => {
    if (token) {
      localStorage.setItem('@bbtoken', token);
      injectHeader();
      const user = await this.refreshUser();
      if (user) {
        this.initializePusher(user);

        try {
          if (Notification?.permission === 'granted') {
            this.registerFcmToken();
          }
        } catch (exp) {}
      }

      let defaultRoute = '/dashboard';
      const params = new URLSearchParams(window.location.search);
      const redirectUrl = params.get('redirect');

      if (
        redirectUrl &&
        redirectUrl.length > 0 &&
        redirectUrl.startsWith('/')
      ) {
        defaultRoute = redirectUrl;
      }

      this.stores.navigate(route ?? defaultRoute);
    }
  };

  resetPasswordWithToken = async (token: string, password: string) => {
    try {
      await resetPassword(token, password);
      toast.success('Successfully reseted password');
      return true;
    } catch (err: any) {
      toast.error('Token is already used.');
      return false;
    }
  };

  registerFcmToken = async () => {
    try {
      if (!this.currentUser) return;

      const messagingToken = await getFirebaseToken();
      if (!messagingToken) {
        return;
      }

      await registerFcmToken(messagingToken);
    } catch (err: any) {}
  };

  unRegisterFcmToken = async () => {
    try {
      if (!this.currentUser) return;

      const messagingToken = await getFirebaseToken();
      if (!messagingToken) {
        return;
      }

      await unregisterFcmToken(messagingToken);
    } catch (err: any) {}
  };

  enable2FA = async ({ name }) => {
    try {
      const generatedOptionsResponse = await enable2FA();

      const { user, challenge } = generatedOptionsResponse.data;

      const publicKeyCredentialCreationOptions: any = {
        challenge: base64ToArrayBuffer(challenge),
        rp: {
          name: 'Gleap',
        },
        user: {
          ...user,
          id: base64ToArrayBuffer(user.id),
        },
        pubKeyCredParams: [
          { alg: -7, type: 'public-key' },
          { alg: -257, type: 'public-key' },
        ],
        authenticatorSelection: {
          requireResidentKey: true,
        },
      };

      const credential = await navigator.credentials.create({
        publicKey: publicKeyCredentialCreationOptions,
      });

      const sendableCredential = makeSendableCredential(credential);

      await verify2FA({ ...sendableCredential, name });

      this.refreshUser();
      toast.success('2FA enabled');
    } catch (err) {
      toast.error('2FA could not be enabled');
      console.log(err);
    }
  };

  loginWith2FA = async ({ data }) => {
    try {
      const credential = await navigator.credentials.get({
        publicKey: {
          challenge: base64ToArrayBuffer(data.challenge),
          allowCredentials: data.allowCredentials.map((credential) => {
            return {
              ...credential,
              id: base64ToArrayBuffer(credential.id),
            };
          }),
        },
      });

      const sendableCredential = makeSendableLoginCredential(credential);

      return sendableCredential;
    } catch (err) {
      console.log(err);
    }
  };

  delete2FADevice = async (deviceId: string) => {
    try {
      await delete2FADevice(deviceId);
      this.refreshUser();
      toast.success('Device removed');
    } catch (err) {
      toast.error('Device could not be removed');
    }
  };

  refreshData = () => {
    // this.refreshUser();
  };
}

const base64UrlToBase64 = (base64Url) => {
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const padding = base64.length % 4;
  if (padding) {
    if (padding === 2) base64 += '==';
    else if (padding === 3) base64 += '=';
  }
  return base64;
};

const base64ToArrayBuffer = (base64Input) => {
  const base64 = base64UrlToBase64(base64Input);
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

const arrayBufferToBase64Url = (buffer) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  const base64 = window.btoa(binary);
  return base64.replace('+', '-').replace('/', '_').replace(/=+$/, '');
};

const makeSendableCredential = (credential) => {
  return {
    id: credential.id,
    type: credential.type,
    rawId: arrayBufferToBase64Url(credential.rawId),
    response: {
      clientDataJSON: arrayBufferToBase64Url(
        credential.response.clientDataJSON,
      ),
      attestationObject: arrayBufferToBase64Url(
        credential.response.attestationObject,
      ),
    },
  };
};

const makeSendableLoginCredential = (credential) => {
  return {
    id: credential.id,
    type: credential.type,
    rawId: arrayBufferToBase64Url(credential.rawId),
    response: {
      clientDataJSON: arrayBufferToBase64Url(
        credential.response.clientDataJSON,
      ),
      authenticatorData: arrayBufferToBase64Url(
        credential.response.authenticatorData,
      ),
      signature: arrayBufferToBase64Url(credential.response.signature),
      userHandle: arrayBufferToBase64Url(credential.response.userHandle),
    },
  };
};
