import { makeAutoObservable, runInAction } from 'mobx';
import { Outbound } from 'models/Outbound';
import {
  getSkipAndLimitFromPage,
  PaginationDataList,
} from 'models/PaginationDataList';
import { toast } from 'react-toastify';
import {
  askOutboundAIQuestion,
  createOutboundRule,
  exportOutboundResponses,
  getFixedAudience,
  getOutboundActivities,
  getOutboundActivitiesCount,
  getOutboundRule,
  getOutboundRuleRecipients,
  getOutboundRuleResponses,
  getOutboundRuleResponseSamples,
  getOutboundRules,
  getOutboundRuleStats,
  markOutboundRuleResponsesAsRead,
  moveOutboundRule,
  removeOutboundRule,
  sendEmailPreview,
  updateOutboundRule,
} from 'services/OutboundHttpService';
import { getProjectSurveyUnreadStatus } from 'services/ProjectHttpService';
import {
  getEmailClientUsageStatistic,
  getEmailOverviewStatistic,
} from 'services/StatisticsService';
import WebSocketMessage from 'services/WebSocketMessage';
import { WEBSOCKET_EVENTS } from 'services/WebSocketService';
import { HttpEmailTemplateClientService } from 'services/http.clients/http.email.template.client';
import Swal from 'sweetalert2';

const itemsInPage = 50;

// eslint-disable-next-line import/prefer-default-export
export class OutboundStore implements WebSocketMessage {
  outboundRules: Outbound[] = [];
  outboundRule?: Outbound = undefined;
  currentTemplate?: any = undefined;
  listOfFixedRecievers: any[] = [];
  fixedRecieversCount: number = 0;
  fixedRecieversLimitCount: number = 0;
  loadingFixedRecievers = false;
  fixedLimitReached = false;
  loadingOutboundRules = false;
  loadingOutboundRule = false;
  loadingAiResponse = false;
  aiPlanFailed = false;
  aiResponse: any = {};
  currentProjectSurveyUnreadCount: any = {};
  outboundRuleStats: any = {
    sent: 0,
    responsesCount: 0,
  };
  unreadResponses = 0;
  outboundRuleResponses: PaginationDataList<any> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 50,
    isLoading: false,
  };
  outboundRuleResponseSamples: any = {};
  outboundRecipientsDataList: PaginationDataList<any> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 50,
    isLoading: false,
  };
  loadingOutboundRuleResponsesUnreadUpdate = false;
  stores: any = {};

  outboundActivityDataList: PaginationDataList<any> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 50,
    isLoading: false,
  };

  emailOverviewData: any = {};
  emailClientUsageData: any = {};

  constructor() {
    makeAutoObservable(this);
  }

  setStores(stores) {
    this.stores = stores;
  }

  resetOutboundFixedRecievers = () => {
    this.listOfFixedRecievers = [];
    this.fixedRecieversCount = 0;
    this.fixedRecieversLimitCount = 0;
    this.loadingFixedRecievers = false;
    this.fixedLimitReached = false;
  };

  loadingOutboundRecipients = (loading: boolean) => {
    this.loadingFixedRecievers = loading;
  };

  setOutboundRule = (outboundRule) => {
    this.outboundRule = outboundRule;
    this.resetOutboundFixedRecievers();
  };

  setLoadingOutboundRule = (loadingOutboundRule) => {
    this.loadingOutboundRule = loadingOutboundRule;
  };

  async loadOutboundRule(id: string) {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    this.setOutboundRule(undefined);
    this.setLoadingOutboundRule(true);

    try {
      const response = await getOutboundRule(
        this.stores.projectStore.currentProject.id,
        id,
      );
      this.setOutboundRule(response.data);
    } catch (exp) {
      toast.error('Could not load outbound rule.');
    }

    this.setLoadingOutboundRule(false);
  }

  setLoadingOutboundRuleResponsesUnreadUpdate = (
    loadingOutboundRuleResponsesUnreadUpdate,
  ) => {
    this.loadingOutboundRuleResponsesUnreadUpdate =
      loadingOutboundRuleResponsesUnreadUpdate;
  };

  setOutboundRuleResponseSamples = (outboundRuleResponseSamples) => {
    this.outboundRuleResponseSamples = outboundRuleResponseSamples;
  };

  setOutboundRuleStats = (outboundRuleStats) => {
    this.outboundRuleStats = outboundRuleStats;
  };

  async loadOutboundRuleStats(args: { id: string, query?: any}) {
    const { id, query } = args;
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    try {
      const response = await getOutboundRuleStats(
        this.stores.projectStore.currentProject.id,
        id,
        query,
      );
      this.setOutboundRuleStats(response.data);
    } catch (exp) {
      toast.error('Could not load outbound rule.');
    }
  }

  async markOutboundRuleResponsesAsRead(id: string) {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    this.setLoadingOutboundRuleResponsesUnreadUpdate(true);

    try {
      await markOutboundRuleResponsesAsRead(
        this.stores.projectStore.currentProject.id,
        id,
      );

      runInAction(() => {
        this.unreadResponses = 0;
        if (
          this.currentProjectSurveyUnreadCount &&
          this.currentProjectSurveyUnreadCount[id]
        ) {
          this.currentProjectSurveyUnreadCount[id] = 0;
        }

        for (var i = 0; i < this.outboundRuleResponses.data.length; i++) {
          this.outboundRuleResponses.data[i].notificationsUnread = false;
        }
        this.outboundRuleResponses.data = [...this.outboundRuleResponses.data];
      });
    } catch (exp) {
      toast.error('Could not load outbound rule.');
    }

    this.setLoadingOutboundRuleResponsesUnreadUpdate(false);
  }

  async queryFixedRecievers(
    outboundId: string,
    conditions: any[],
    targetAudience: string,
  ) {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    this.listOfFixedRecievers = [];
    this.fixedRecieversCount = 0;
    this.fixedRecieversLimitCount = 0;
    this.loadingFixedRecievers = true;
    this.fixedLimitReached = false;

    try {
      const response = await getFixedAudience(
        this.stores.projectStore.currentProject.id,
        outboundId,
        conditions,
        targetAudience,
      );
      runInAction(() => {
        if (response.data) {
          this.listOfFixedRecievers = response.data.sample;
          this.fixedRecieversCount = response.data.count;
          this.fixedRecieversLimitCount = response.data.limit;
          this.fixedLimitReached = response.data.limitReached;
          this.loadingFixedRecievers = false;
        }
      });
    } catch (error) {
      this.loadingFixedRecievers = false;
    }
  }

  async askOutboundAIQuestion(outboundId: string, question: string) {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    this.aiPlanFailed = false;
    this.loadingAiResponse = true;

    try {
      const response = await askOutboundAIQuestion(
        this.stores.projectStore.currentProject.id,
        outboundId,
        question,
      );
      runInAction(() => {
        this.aiResponse = response.data;
      });
    } catch (error) {
      if ((error as any)?.response.status === 408) {
        runInAction(() => {
          this.aiPlanFailed = true;
        });
      } else {
        if ((error as any)?.response?.data?.errors?.length > 0) {
          Swal.fire({
            text: (error as any)?.response.data.errors[0].message,
            showCancelButton: false,
            confirmButtonText: `Ok`,
          });
        } else {
          toast.error(
            'Could not summarize survey results. Please try again later.',
          );
        }
      }
    }

    runInAction(() => {
      this.loadingAiResponse = false;
    });
  }

  loadOutboundRuleResponses = async (args: {
    id: string;
    query?: any;
    loadMore?: boolean;
  }) => {
    const { id, query } = args;

    if (
      this.outboundRuleResponses.isLoading ||
      !this.stores.projectStore.currentProject
    ) {
      return;
    }

    try {
      this.outboundRuleResponses.isLoading = true;

      if (args.loadMore) {
        this.outboundRuleResponses.pageIndex += 1;
      } else {
        this.outboundRuleResponses.pageIndex = 0;
        this.outboundRuleResponses.data = [];
        this.outboundRuleResponses.totalItems = 0;
      }

      const response = await getOutboundRuleResponses(
        this.stores.projectStore.currentProject.id,
        id,
        {
          ...getSkipAndLimitFromPage({
            pageIndex: this.outboundRuleResponses.pageIndex,
            itemsInPage,
          }),
          ...query,
        },
      );

      if (response.status === 200) {
        runInAction(() => {
          this.outboundRuleResponses.totalItems = response.data?.count ?? 0;
          this.outboundRuleResponses.data = [
            ...this.outboundRuleResponses.data,
            ...response.data,
          ];

          var notificationsUnreadCount = 0;
          if (
            this.outboundRuleResponses.data &&
            this.outboundRuleResponses.data.length > 0
          ) {
            for (var i = 0; i < this.outboundRuleResponses.data.length; i++) {
              if (this.outboundRuleResponses.data[i].notificationsUnread) {
                notificationsUnreadCount++;
              }
            }
          }
          this.unreadResponses = notificationsUnreadCount;
        });
      }

      this.outboundRuleResponses.hasMore =
        (response?.data.length ?? 0) === this.outboundRuleResponses.itemsInPage;

      this.outboundRuleResponses.isLoading = false;
    } catch (_) {
      this.outboundRuleResponses.isLoading = false;
    }
  };

  async loadOutboundRuleResponseSamples(args: { id: string; query?: any }) {
    const { id, query } = args;
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    this.setOutboundRuleResponseSamples({});

    try {
      const response = await getOutboundRuleResponseSamples(
        this.stores.projectStore.currentProject.id,
        id,
        query,
      );
      this.setOutboundRuleResponseSamples(response.data);
    } catch (exp) {
      console.log(exp);
      toast.error('Could not load outbound rule.');
    }
  }

  /**
   * Sets the outbound rules
   * @param outboundRules
   */
  setOutboundRules = (outboundRules) => {
    this.outboundRules = outboundRules;
  };

  /**
   * Sets the loading outbound rules flag
   * @param loadingOutboundRules
   */
  setLoadingOutboundRules = (loadingOutboundRules) => {
    this.loadingOutboundRules = loadingOutboundRules;
  };

  async loadOutboundRules() {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    this.setLoadingOutboundRules(true);

    try {
      const response = await getOutboundRules(
        this.stores.projectStore.currentProject.id,
      );
      this.setOutboundRules(response.data);
    } catch (exp) {
      toast.error('Could not load outbound rules.');
    }

    this.setLoadingOutboundRules(false);
  }

  onEvent = (event: string, data: any) => {
    if (!data) {
      return;
    }

    if (event === WEBSOCKET_EVENTS.BUG_UPDATED) {
      this.locallyUpdateBug(data.id, data);
    }
  };

  locallyUpdateBug(bugId, data) {
    if (
      this.outboundRuleResponses &&
      this.outboundRuleResponses.data.length > 0
    ) {
      const bugIndex = this.outboundRuleResponses.data.findIndex(
        (x) => x.id === bugId,
      );
      if (
        bugIndex >= 0 &&
        this.outboundRuleResponses.data[bugIndex] &&
        this.outboundRuleResponses.data[bugIndex].outbound
      ) {
        runInAction(() => {
          this.outboundRuleResponses.data[bugIndex] = {
            ...this.outboundRuleResponses.data[bugIndex],
            ...data,
          };
        });

        // Decrement.
        var outboundUnreadCountCopy = JSON.parse(
          JSON.stringify(this.currentProjectSurveyUnreadCount),
        );
        if (
          outboundUnreadCountCopy &&
          outboundUnreadCountCopy[
          this.outboundRuleResponses.data[bugIndex].outbound
          ]
        ) {
          outboundUnreadCountCopy[
            this.outboundRuleResponses.data[bugIndex].outbound
          ] -= 1;

          runInAction(() => {
            this.currentProjectSurveyUnreadCount = outboundUnreadCountCopy;
          });
        }

        runInAction(() => {
          this.unreadResponses = this.unreadResponses - 1;
          this.outboundRuleResponses = JSON.parse(
            JSON.stringify(this.outboundRuleResponses),
          );
        });
      }
    }
  }

  removeOutboundRule = async (id) => {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    await removeOutboundRule(this.stores.projectStore.currentProject.id, id);

    this.setOutboundRules([]);
    this.setOutboundRule(undefined);
  };

  getProjectSurveyUnreadStatus = async (id: string) => {
    try {
      const response = await getProjectSurveyUnreadStatus(id);
      if (response.status === 200) {
        runInAction(() => {
          this.currentProjectSurveyUnreadCount = response.data;
        });
      }
    } catch (exp) { }
  };

  getTemplate = async (id: string) => {
    try {
      const emailTemplate =
        await HttpEmailTemplateClientService.getInstance().findOne({ id });

      if (emailTemplate) {
        runInAction(() => {
          this.currentTemplate = emailTemplate;
        });
      }
    } catch (exp) {
      toast.error('Could not load template.');
    }
  };

  createEmail = async (templateItem: any) => {
    if (this.stores?.projectStore?.currentProject) {
      await this.createOutboundRule({
        type: 'EMAIL',
        sender: this.stores?.usersStore?.currentUser?.id,
        actionType: templateItem.actionType ?? 'email',
        sound: true,
        subject: templateItem.subject ?? {},
        message: templateItem.message ?? {},
        frequencyType: templateItem.frequencyType ?? 'dynamic',
        name: templateItem.title,
        format: templateItem.format ?? 'personal',
        emailTemplate:
          this.stores?.projectStore?.emailTemplatesDataList?.data?.length > 0
            ? this.stores?.projectStore?.emailTemplatesDataList?.data[0]._id
            : undefined,
        trigger: templateItem.trigger,
        pageFilterDelay: templateItem.pageFilterDelay ?? 0,
      });

      this.stores?.modalStore!.closeModal();
    }
  };

  createOutboundRule = async (data) => {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    const outboundRule = await createOutboundRule(
      this.stores.projectStore.currentProject.id,
      data,
    );

    this.setOutboundRule(outboundRule.data);

    this.stores.navigate(
      `/projects/${this.stores.projectStore.currentProject.id}/outbound/${outboundRule.data.id
      }/edit${outboundRule.data?.type === 'EMAIL' ? '?tab=email' : ''}`,
    );

    this.loadOutboundRules();
  };

  sendEmailPreview = async (id, emails) => {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    const currentLang = this.stores.projectStore?.currentLanguage ?? 'en';

    try {
      const updatedOutboundRule = await sendEmailPreview(
        this.stores.projectStore.currentProject.id,
        id,
        {
          emails,
          lang: currentLang,
        },
      );

      if (updatedOutboundRule.data?.sent) {
        toast.success('Sent 📬 It might take 1-2 minutes to arrives.');
      } else {
        toast.success('Sending failed 😬');
      }
    } catch (exp) {
      toast.error('Sending failed 😬');
    }
  };

  updateOutboundRule = async (id, data) => {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    try {
      const updatedOutboundRule = await updateOutboundRule(
        this.stores.projectStore.currentProject.id,
        id,
        data,
      );
      this.setOutboundRule(updatedOutboundRule.data);
      this.loadOutboundRules();

      if (updatedOutboundRule.data?.sent) {
        toast.success('Sent 📬');
      } else {
        toast.success('Saved 🎉');
      }
    } catch (exp) {
      toast.error('Saving failed 😬');
    }
  };

  cloneOutbound = async (projectId, outboundId) => {
    try {
      const outboundToClone = this.outboundRules.find(
        (outbound) => outbound.id === outboundId,
      );

      if (outboundToClone) {
        const newOutboundData = {
          ...outboundToClone,
          name: `Copy of ${outboundToClone.name}`,
          status: 'draft',
          aiSummaryOutdated: outboundToClone.aiSummaryOutdated,
        };

        if (outboundToClone.type === 'SURVEY') {
          const clonedActionTypeID =
            await this.stores.projectStore?.cloneActionType(
              outboundToClone?.actionType,
            );
          newOutboundData.actionType = clonedActionTypeID;
        }

        await createOutboundRule(projectId, newOutboundData);
        this.loadOutboundRules();
      }
    } catch (error) {
      toast.error('Fehler beim Duplizieren des Outbounds.');
    }
  };

  moveOutbound = async (outbound) => {
    try {
      const updatedOutboundRule = await moveOutboundRule(
        this.stores.projectStore.currentProject.id,
        outbound.id,
        outbound.project,
      );

      runInAction(() => {
        this.setOutboundRule(updatedOutboundRule);
      });

      this.loadOutboundRules();
    } catch (exp) {
      toast.error('Move outreach failed 😬');
    }
  };

  exportOutboundResponses = async (args: { id: string; query: any }) => {
    const { id, query } = args;
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    try {
      const response = await exportOutboundResponses(
        this.stores.projectStore.currentProject.id,
        id,
        query,
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const hiddenElement = document.createElement('a');
      hiddenElement.href = url;
      hiddenElement.target = '_blank';
      hiddenElement.download = `${this.outboundRule?.name
        .replace(/\s/g, '_')
        .toLowerCase()}.csv`;
      document.body.appendChild(hiddenElement); // Append to the DOM
      hiddenElement.click();
      document.body.removeChild(hiddenElement); // Clean up
      window.URL.revokeObjectURL(url); // Free up memory
    } catch (exp) {
      toast.error('Could not export outbound responses.');
    }
  };

  fetchAndSetOutboundActivities = async (args: {
    query: any;
    loadMore?: boolean;
  }) => {
    if (
      this.outboundActivityDataList.isLoading ||
      !this.stores.projectStore.currentProject ||
      !this.outboundRule
    ) {
      return;
    }

    try {
      this.outboundActivityDataList.isLoading = true;

      if (args.loadMore) {
        this.outboundActivityDataList.pageIndex += 1;
      } else {
        this.outboundActivityDataList.pageIndex = 0;
        this.outboundActivityDataList.data = [];
      }

      const response = await getOutboundActivities({
        projectId: this.stores.projectStore.currentProject.id,
        outboundId: this.outboundRule?.id,
        query: {
          ...args.query,
          ...getSkipAndLimitFromPage({
            pageIndex: this.outboundActivityDataList.pageIndex,
            itemsInPage,
          }),
        },
      });

      if (response.status === 200) {
        runInAction(() => {
          this.outboundActivityDataList.data = [
            ...this.outboundActivityDataList.data,
            ...response.data,
          ];
        });
      }

      this.outboundActivityDataList.isLoading = false;
    } catch (_) {
      this.outboundActivityDataList.isLoading = false;
    }
  };

  fetchAndSetEmailOverviewData = async (args: { query?: any }) => {
    if (!this.stores.projectStore.currentProject || !this.outboundRule) {
      return;
    }

    try {
      const response = await getEmailOverviewStatistic({
        projectId: this.stores.projectStore.currentProject.id,
        query: { ...args.query, outboundId: this.outboundRule?.id },
      });

      if (response.status === 200) {
        runInAction(() => {
          this.emailOverviewData = response.data;
        });
      }
    } catch (_) { }
  };

  fetchAndSetEmailClientUsageData = async (args: { query?: any }) => {
    if (!this.stores.projectStore.currentProject || !this.outboundRule) {
      return;
    }

    try {
      const response = await getEmailClientUsageStatistic({
        projectId: this.stores.projectStore.currentProject.id,
        query: { ...args.query, outboundId: this.outboundRule?.id },
      });

      if (response.status === 200) {
        runInAction(() => {
          this.emailClientUsageData = response.data;
        });
      }
    } catch (_) { }
  };

  getOutboundActivitiesCount = async (args: { query: any }) => {
    if (!this.stores.projectStore.currentProject || !this.outboundRule) {
      return 0;
    }

    try {
      const response = await getOutboundActivitiesCount({
        projectId: this.stores.projectStore.currentProject.id,
        outboundId: this.outboundRule?.id,
        query: args.query,
      });

      if (response.status === 200) {
        return response.data.count;
      }

      return 0;
    } catch (_) {
      return 0;
    }
  };

  getOutboundRecipients = async (args: {
    outboundId: string;
    loadMore?: boolean;
  }) => {
    if (
      this.outboundRecipientsDataList.isLoading ||
      !this.stores.projectStore.currentProject
    ) {
      return;
    }

    try {
      this.outboundRecipientsDataList.isLoading = true;

      if (args.loadMore) {
        this.outboundRecipientsDataList.pageIndex += 1;
      } else {
        this.outboundRecipientsDataList.pageIndex = 0;
        this.outboundRecipientsDataList.data = [];
      }

      const response = await getOutboundRuleRecipients(
        this.stores.projectStore.currentProject.id,
        args.outboundId,
        getSkipAndLimitFromPage({
          pageIndex: this.outboundRecipientsDataList.pageIndex,
          itemsInPage,
        }),
      );

      if (response.status === 200) {
        runInAction(() => {
          this.outboundRecipientsDataList.data = [
            ...this.outboundRecipientsDataList.data,
            ...response.data,
          ];
        });
      }

      this.outboundRecipientsDataList.isLoading = false;
    } catch (_) {
      this.outboundRecipientsDataList.isLoading = false;
    }
  };

  refreshData = () => {
    this.loadOutboundRules();

    /*const id = this.outboundRule?.id;
    if (this.outboundRule && id) {
      this.loadOutboundRule(id);
      this.loadOutboundRuleResponses(id);
    }*/
  };
}
