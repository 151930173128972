import ListTable, { CellAction, CellPaymentStatus, CellText } from 'components/ListTable/ListTable';
import Loading from 'components/Loading/Loading';
import { currencySymbols } from 'components/PaddlePriceTable/PaddlePriceTable';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { useMemo } from 'react';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { PaddleStore } from 'stores/private/PaddleStore';
import './PaddleInvoiceTable.scss';

interface PaddleInvoiceTableProps {
  organisationStore?: OrganisationStore;
  paddleStore?: PaddleStore;
}

const PaddleInvoiceTable = ({ organisationStore, paddleStore }: PaddleInvoiceTableProps) => {
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: (row) => ({
          transactionId: row.transactionId,
          invoiceNumber: row.invoice_number,
        }),
        Cell: (row) => (
          <CellAction action={() => {
            paddleStore!.downloadPDFForTransaction(row.value.transactionId);
          }} text={row.value.invoiceNumber} />
        ),
      },
      {
        Header: 'Created at',
        accessor: 'created_at',
        Cell: (row) => (
          <CellText text={moment(row.value).format('LL')} />
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (row) => (
          <CellPaymentStatus text={row.value} />
        ),
      },
      {
        Header: 'Total',
        accessor: 'details',
        Cell: (row) => (
          <CellText text={`${currencySymbols[row?.value?.totals?.currency_code ?? "USD"]}${((row?.value?.totals?.grand_total ?? 0) / 100).toFixed(2)}`} />
        ),
      },
    ],
    [],
  );

  if (!organisationStore?.currentOrganisation) {
    return <Loading />;
  }

  return (
    <div className='list-table-container'>
      <ListTable data={paddleStore?.transactions} columns={columns} />
      {paddleStore?.transactions?.length === 0 && (<div className='text centered mt-20'>No invoices yet.</div>)}
    </div>
  );
};

export default inject('organisationStore', 'paddleStore')(observer(PaddleInvoiceTable));
