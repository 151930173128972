import { BubbleMenu, Editor } from '@tiptap/react';
import { ReactComponent as AIIcon } from 'assets/icons/ai.svg';
import GiphyAttachment from 'components/GiphyAttachment/GiphyAttachment';
import LoadingAnimationSmall from 'components/LoadingAnimationSmall/LoadingAnimationSmall';
import PerformHelpcenterAction from 'components/PerformHelpcenterAction/PerformHelpcenterAction';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import ReactTooltip from 'react-tooltip';
import { uploadFileToServer } from 'services/FileUpload';
import { performAITextAction } from 'services/ProjectHttpService';
import { ModalStore } from 'stores/private/ModalStore';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import Swal from 'sweetalert2';
import { useFilePicker } from 'use-file-picker';
import './Toolbar.scss';
import ButtonToolbarItem from './components/ButtonToolbarItem/ButtonToolbarItem';
import IframeToolbarItem from './components/IframeToolbarItem/IframeToolbarItem';
import LinkToolbarItem from './components/LinkToolbarItem/LinkToolbarItem';
import VariableToolbarItem, {
  defaultVariables,
} from './components/VariableToolbarItem/VariableToolbarItem';
import VideoToolbarItem from './components/VideoToolbarItem/VideoToolbarItem';

export enum ToolbarItems {
  Basic = 'Basic',
  Advanced = 'Advanced',
  Image = 'Image',
  Embedded = 'Embedded',
  AI = 'AI',
  VARIABLES = 'VARIABLES',
  HELPCENTER = 'HELPCENTER',
  CALLOUTS = 'CALLOUTS',
}

interface ToolbarProps {
  editor: Editor | null;
  floating?: boolean;
  items?: ToolbarItems[];
  projectStore?: ProjectStore;
  organisationStore?: OrganisationStore;
  modalStore?: ModalStore;
  aiStyle?: string;
  articleAsUrl?: boolean;
  additionalVariables?: any[];
  popupPosition?: 'top' | 'bottom';
  language?: string;
  disableSmartLinks?: boolean;
}

const Toolbar = ({
  editor,
  projectStore,
  organisationStore,
  modalStore,
  floating = false,
  articleAsUrl = false,
  aiStyle = 'agent',
  popupPosition = 'bottom',
  items = [ToolbarItems.Basic, ToolbarItems.Advanced, ToolbarItems.AI],
  language,
  additionalVariables,
  disableSmartLinks,
}: ToolbarProps) => {
  const [aiLoading, setAiLoading] = useState(false);
  const [showAITools, setShowAITools] = useState(false);
  const navigate = useNavigate();

  const [openFileSelector, { plainFiles, clear }] = useFilePicker({
    readAs: 'DataURL',
    accept: ['image/*'],
    multiple: false,
    limitFilesConfig: { max: 1 },
    maxFileSize: 10,
  });

  const insertImageFromFile = async (file) => {
    const uploadedAttachment = await uploadFileToServer(file, `helparticle`);
    if (uploadedAttachment) {
      editor?.chain().focus().setImage({ src: uploadedAttachment }).run();
    }
    clear();
  };

  const insertGiphy = (gifData: any) => {
    editor
      ?.chain()
      .focus()
      .setImage({ src: gifData.images.original.url })
      .run();
  };

  useEffect(() => {
    if (plainFiles && plainFiles.length > 0) {
      insertImageFromFile(plainFiles[0]);
    }
  }, [plainFiles]);

  if (!editor) {
    return <></>;
  }

  const doAIAction = async (action, plainText, selection) => {
    if (!projectStore?.currentProject?.id) {
      setShowAITools(false);
      return;
    }

    setAiLoading(true);

    return performAITextAction(
      projectStore?.currentProject?.id ?? '',
      action,
      plainText,
      selection ? 'plain' : aiStyle,
    )
      .then((response) => {
        if (response.status === 200 && response.data && response.data.text) {
          // Repace text.
          setAiLoading(false);
          setShowAITools(false);

          return response.data.text;
        }
        return null;
      })
      .catch(() => {
        setAiLoading(false);

        Swal.fire({
          text: 'AI assist is not supported in your legacy plan. Move to our new plans to unlock AI assist.',
          showCancelButton: true,
          confirmButtonText: `Update plan`,
          denyButtonText: `Cancel`,
        }).then(async (result) => {
          if (result.isConfirmed) {
            navigate(
              `/organization/${organisationStore?.currentOrganisation?.id}/billing`,
            );
            modalStore?.closeModal();
          }
        });
        return null;
      });
  };

  const workAIAction = async (action) => {
    var text = '';

    const { from, to, empty } = editor.state.selection;
    if (empty) {
      text = editor.getText();
    } else {
      text = editor.state.doc.textBetween(from, to, ' ');
    }

    if (text && text.length > 5) {
      const result = await doAIAction(action, text, !empty);
      if (result) {
        var textToPaste = result.replace(/(?:\r\n|\r|\n)/g, '<br>');
        if (empty) {
          editor.commands.clearContent();
          editor.commands.insertContent(textToPaste);
        } else {
          editor.commands.setTextSelection({ from: from, to: to });
          editor.commands.deleteSelection();
          editor.commands.insertContentAt(from, textToPaste, {
            updateSelection: true,
            parseOptions: {
              preserveWhitespace: 'full',
            },
          });
        }
      }
    }
  };

  const insertHelpCenterArticle = (article: any) => {
    if (editor) {
      editor.commands.insertContent({
        type: 'helpCenterArticle',
        attrs: {
          articleId: article.id,
          articleTitle: article.title,
          articleDescription: article.description,
          articleUrl: article.url,
        },
      });
    }
  };

  const renderAITools = () => {
    if (aiLoading) {
      return (
        <div className="ai-toolbar-loading">
          <LoadingAnimationSmall />
        </div>
      );
    }

    return (
      <>
        <ReactTooltip
          id="toolbarTooltip"
          className="infoTooltipBold"
          delayHide={0}
          type="light"
          offset={{
            top: 14,
          }}
          place={'top'}
          effect="solid"
        />
        <div
          data-for="toolbarTooltip"
          data-tip="Expand text"
          onClick={() => {
            workAIAction('expand');
          }}
          className={'bubble-menu-item bubble-menu-item-ai'}
        >
          <i className="fa-regular fa-pen-nib" />
        </div>
        <div
          data-for="toolbarTooltip"
          data-tip="Rephrase text"
          onClick={() => {
            workAIAction('rephrase');
          }}
          className={'bubble-menu-item bubble-menu-item-ai'}
        >
          <i className="fa-regular fa-pen" />
        </div>
        <div
          data-for="toolbarTooltip"
          data-tip="Rewrite text to sound more fun and energizing"
          onClick={() => {
            workAIAction('energize');
          }}
          className={'bubble-menu-item bubble-menu-item-ai'}
        >
          <i className="fa-regular fa-face-smile-relaxed" />
        </div>
        <div
          data-for="toolbarTooltip"
          data-tip="Rewrite text to sound more professional"
          onClick={() => {
            workAIAction('formal');
          }}
          className={'bubble-menu-item bubble-menu-item-ai'}
        >
          <i className="fa-regular fa-face-meh" />
        </div>
        <div
          data-for="toolbarTooltip"
          data-tip="Close AI tools"
          onClick={() => {
            setShowAITools(false);
          }}
          className={'bubble-menu-item'}
        >
          <i className="fa-sharp fa-solid fa-circle-xmark" />
        </div>
      </>
    );
  };

  const renderBaseTools = () => {
    return (
      <>
        <ReactTooltip
          id="toolbarTooltip"
          className="infoTooltipBold"
          delayHide={0}
          type="light"
          offset={{
            top: 14,
          }}
          place={'top'}
          effect="solid"
        />
        {items.includes(ToolbarItems.Advanced) && (
          <>
            <div
              data-for="toolbarTooltip"
              data-tip="Heading 2"
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 2 }).run()
              }
              className={`bubble-menu-item ${editor.isActive('heading', { level: 2 }) ? 'is-active' : ''
                }`}
            >
              <i className="fa-solid fa-h2" />
            </div>
            <div
              data-for="toolbarTooltip"
              data-tip="Heading 3"
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 3 }).run()
              }
              className={`bubble-menu-item ${editor.isActive('heading', { level: 3 }) ? 'is-active' : ''
                }`}
            >
              <i className="fa-solid fa-h3" />
            </div>
          </>
        )}
        {items.includes(ToolbarItems.Basic) && (
          <>
            <div
              data-for="toolbarTooltip"
              data-tip="Bold"
              onClick={() => editor.chain().focus().toggleBold().run()}
              className={`bubble-menu-item ${editor.isActive('bold') ? 'is-active' : ''
                }`}
            >
              <i className="fa-solid fa-bold" />
            </div>
            <div
              data-for="toolbarTooltip"
              data-tip="Italic"
              onClick={() => editor.chain().focus().toggleItalic().run()}
              className={`bubble-menu-item ${editor.isActive('italic') ? 'is-active' : ''
                }`}
            >
              <i className="fa-solid fa-italic" />
            </div>
            <div
              data-for="toolbarTooltip"
              data-tip="Strikethrough"
              onClick={() => editor.chain().focus().toggleStrike().run()}
              className={`bubble-menu-item ${editor.isActive('strike') ? 'is-active' : ''
                }`}
            >
              <i className="fa-solid fa-strikethrough" />
            </div>
            <LinkToolbarItem
              position={popupPosition}
              articleAsUrl={articleAsUrl}
              additionalVariables={
                items.includes(ToolbarItems.VARIABLES)
                  ? [...defaultVariables, ...(additionalVariables ?? [])]
                  : []
              }
              disableSmartLinks={disableSmartLinks}
              language={language}
              editor={editor}
            />
          </>
        )}
        {items.includes(ToolbarItems.VARIABLES) && (
          <VariableToolbarItem
            position={popupPosition}
            editor={editor}
            additionalVariables={additionalVariables}
          />
        )}
        {items.includes(ToolbarItems.CALLOUTS) && (
          <div
            data-for="toolbarTooltip"
            data-tip="Callout"
            onClick={() => {
              editor.commands.insertContent({
                type: 'callout',
                attrs: { type: 'info' },
                content: [
                  {
                    type: 'text',
                    text: 'Your callout text goes here...',
                  },
                ],
              });
            }}
            className={`bubble-menu-item ${editor.isActive('callout') ? 'is-active' : ''
              }`}
          >
            <i className="fa-solid fa-message-lines" />
          </div>
        )}
        {items.includes(ToolbarItems.Advanced) && (
          <>
            <div
              data-for="toolbarTooltip"
              data-tip="List"
              onClick={() => editor.chain().focus().toggleBulletList().run()}
              className={`bubble-menu-item ${editor.isActive('bulletList') ? 'is-active' : ''
                }`}
            >
              <i className="fa-solid fa-list" />
            </div>
            <div
              data-for="toolbarTooltip"
              data-tip="Code block"
              onClick={() => editor.chain().focus().toggleCodeBlock().run()}
              className={`bubble-menu-item ${editor.isActive('codeBlock') ? 'is-active' : ''
                }`}
            >
              <i className="fa-solid fa-square-code" />
            </div>
          </>
        )}
        {!floating && items.includes(ToolbarItems.Image) && (
          <>
            <div
              data-for="toolbarTooltip"
              data-tip="Insert image"
              onClick={() => {
                openFileSelector();
              }}
              className={`bubble-menu-item ${editor.isActive('image') ? 'is-active' : ''
                }`}
            >
              <i className="fa-solid fa-image" />
            </div>
            {items.includes(ToolbarItems.Embedded) && (
              <>
                <VideoToolbarItem position={popupPosition} editor={editor} />
                <IframeToolbarItem position={popupPosition} editor={editor} />
              </>
            )}
          </>
        )}
        {items.includes(ToolbarItems.Advanced) && (
          <>
            <ButtonToolbarItem position={popupPosition} editor={editor} />
            <GiphyAttachment showOver={popupPosition === "top"} onGifSelected={insertGiphy} />
          </>
        )}
        {items.includes(ToolbarItems.HELPCENTER) && (
          <PerformHelpcenterAction
            onArticleSelected={insertHelpCenterArticle}
            position={popupPosition}
          />
        )}
        {/*items.includes(ToolbarItems.AI) && (
          <div
            data-for="toolbarTooltip"
            data-tip="Open AI assist tools"
            onClick={() => {
              setShowAITools(true);
            }}
            className={'bubble-menu-item'}
          >
            <AIIcon className="bubble-menu-item-aiicon" />
          </div>
          )*/}
      </>
    );
  };

  const renderActiveCallout = () => {
    const iconMap = {
      warning: 'triangle-exclamation',
      error: 'square-exclamation',
      info: 'circle-info',
      success: 'circle-check',
    };

    return (
      <>
        {['info', 'warning', 'error', 'success'].map((type) => {
          const isActive = editor.isActive('callout', { type });
          const iconClass = `fa-${isActive ? 'solid' : 'regular'} fa-${iconMap[type]
            }`;

          return (
            <div
              key={type}
              data-for="toolbarTooltip"
              data-tip={type + ' callout'}
              onClick={() => {
                editor.commands.updateAttributes('callout', { type: type });
              }}
              className={`bubble-menu-item ${isActive ? 'is-active' : ''}`}
            >
              <i className={iconClass} />
            </div>
          );
        })}
        <LinkToolbarItem
          position={popupPosition}
          articleAsUrl={articleAsUrl}
          additionalVariables={
            items.includes(ToolbarItems.VARIABLES)
              ? [...defaultVariables, ...(additionalVariables ?? [])]
              : []
          }
          disableSmartLinks={disableSmartLinks}
          language={language}
          editor={editor}
        />
      </>
    );
  };

  const buildToolbar = () => {
    if (editor.isActive('callout')) {
      return renderActiveCallout();
    }

    if (showAITools) {
      return renderAITools();
    }

    return renderBaseTools();
  };

  if (floating) {
    return (
      <BubbleMenu
        className="bubble-menu bubble-menu-container"
        tippyOptions={{ duration: 100 }}
        editor={editor}
      >
        {buildToolbar()}
      </BubbleMenu>
    );
  }

  return <div className="bubble-menu">{buildToolbar()}</div>;
};

export default inject(
  'projectStore',
  'organisationStore',
  'modalStore',
)(observer(Toolbar));
