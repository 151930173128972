import React, { useEffect, useState } from 'react';
import './WidgetButtonPreview.scss';
import { inject, observer } from 'mobx-react';
import { ProjectStore } from 'stores/private/ProjectStore';
import { useParams } from 'react-router';
import Loading from 'components/Loading/Loading';
import { UsersStore } from 'stores/private/UsersStore';
import { toast } from 'react-toastify';
import Gleap from 'gleap';
import { calculateContrast, calculateShadeColor } from 'helper/Color';
import { getLanguageProperty } from 'helper/AssignObjectKeysHelper';

interface WidgetButtonPreviewProps {
  projectStore?: ProjectStore;
  usersStore?: UsersStore;
}

function WidgetButtonPreview({ projectStore, usersStore }: WidgetButtonPreviewProps) {
  // @ts-ignore
  const { projectId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { flowConfig } = projectStore?.editingProject || {};
  const currentLang = projectStore?.currentLanguage ?? 'en';

  useEffect(() => {
    setIsLoading(true);
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
    setIsLoading(false);
  }, [projectId]);

  useEffect(() => {
    Gleap.showFeedbackButton(false);

    return () => {
      Gleap.showFeedbackButton(true);
    }
  }, []);

  if (isLoading || !flowConfig) {
    return <Loading />;
  }

  const showDemoNotice = () => {
    toast.success('You clicked your demo widget 🥳.');
  };

  const cornerRadius = flowConfig.borderRadius ?? 20;
  const backgroundColor = flowConfig.backgroundColor ?? '#ffffff';
  const contrastBackgroundColor = calculateContrast(backgroundColor);
  const subTextColor =
    contrastBackgroundColor === '#ffffff'
      ? calculateShadeColor(backgroundColor, 100)
      : calculateShadeColor(backgroundColor, -100);

  return (
    <div className="widget-button-preview">
      {flowConfig.feedbackButtonPosition &&
        flowConfig.feedbackButtonPosition.includes('CLASSIC') && (
          <div
            className="widget-bugbattle--feedback-button"
            onClick={() => {
              showDemoNotice();
            }}
          >
            <div
              className={`widget-bugbattle--feedback-button-text ${flowConfig.feedbackButtonPosition === 'BUTTON_CLASSIC_LEFT'
                ? 'widget-bugbattle--feedback-button-text--left'
                : ''
                }${flowConfig.feedbackButtonPosition === 'BUTTON_CLASSIC_BOTTOM'
                  ? 'widget-bugbattle--feedback-button-text--bottom'
                  : ''
                }`}
              style={{
                backgroundColor: flowConfig.buttonColor ?? '#2142E7',
                color: calculateContrast(flowConfig.buttonColor ?? '#2142E7'),
                borderTopLeftRadius: cornerRadius * 0.4,
                borderTopRightRadius: cornerRadius * 0.4,
              }}
            >
              {getLanguageProperty(
                flowConfig,
                'widgetButtonText',
                currentLang,
                true,
              ) ?? 'Feedback'}
            </div>
          </div>
        )}
      <div className="widget-button-preview-browser">
        <div className="widget-button-preview-browser-head">
          <div className="widget-button-preview-browser-head-close" />
          <div className="widget-button-preview-browser-head-minimize" />
          <div className="widget-button-preview-browser-head-maximize" />
        </div>
        <div className="widget-button-preview-browser-body">
          {flowConfig.feedbackButtonPosition &&
            flowConfig.feedbackButtonPosition !== 'BUTTON_NONE' &&
            !flowConfig.feedbackButtonPosition.includes('CLASSIC') && (
              <div
                className={`widget-bugbattle--feedback-new-button ${flowConfig.feedbackButtonPosition === 'BOTTOM_LEFT'
                  ? 'widget-bugbattle--feedback-new-button--left'
                  : ''
                  }`}
                style={{
                  left: `${flowConfig.buttonX ?? 20}px`,
                  right: `${flowConfig.buttonX ?? 20}px`,
                  bottom: `${flowConfig.buttonY ?? 20}px`,
                }}
              >
                <div
                  className="widget-bugbattle--feedback-new-button-button"
                  style={{
                    backgroundColor: flowConfig.buttonColor ?? '#2142E7',
                    color: calculateContrast(
                      flowConfig.buttonColor ?? '#2142E7',
                    ),
                  }}
                  onClick={() => {
                    showDemoNotice();
                  }}
                >
                  {flowConfig.buttonLogo && flowConfig.buttonLogo.length > 0 ? (
                    <img src={flowConfig.buttonLogo} />
                  ) : (
                    <svg className="widget-default-icon" width="145" height="144" viewBox="0 0 145 144" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M38.9534 15H105.047C113.857 15 121 22.1426 121 30.9534L121 89.5238L121 96.015L121 125.541C121 128.759 117.393 130.66 114.739 128.84L90.1188 111.968H38.9534C30.1426 111.968 23 104.826 23 96.015V30.9534C23 22.1426 30.1426 15 38.9534 15Z" fill={calculateContrast(
                        flowConfig.buttonColor ?? '#2142E7',
                      )}></path>
                    </svg>
                  )}
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

export default inject('projectStore', 'usersStore')(observer(WidgetButtonPreview));
