import { ReactComponent as SaveIcon } from 'assets/icons/np_save_2209758_4C5AF6.svg';
import classNames from 'classnames';
import LinkButton from 'components/LinkButton/LinkButton';
import { inject, observer } from 'mobx-react';
import { Bug } from 'models/Bug';
import { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { useNavigate } from 'react-router-dom';
import { BugStore } from 'stores/private/BugStore';
import BugScreenContent from './BugScreenContent';
import { getReporterInfo } from './Comments';
import './Comments.scss';
import './Details.scss';
import FormData from './FormData';
import './TicketInfoComment.scss';
import InfoBox from 'components/InfoBox/InfoBox';
import { ProjectStore } from 'stores/private/ProjectStore';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';

interface TicketInfoCommentProps {
  ticket: Bug | undefined;
  loadingComments?: boolean;
  shared?: boolean;
  allowActions?: boolean;
  bugStore?: BugStore;
  projectStore?: ProjectStore;
  organisationStore?: OrganisationStore;
  smallContainer?: boolean;
}

const TicketInfoComment = ({
  ticket,
  loadingComments,
  shared,
  allowActions = true,
  bugStore,
  projectStore,
  organisationStore,
  smallContainer,
}: TicketInfoCommentProps) => {
  const navigate = useNavigate();
  const [notSpamLoading, setNotSpamLoading] = useState(false);
  const [showAttachedLightbox, setShowAttachedLightbox] = useState(
    new Array(ticket?.attachments?.length).fill(false),
  );

  const canShowStaticComment = ticket && !loadingComments;
  if (!canShowStaticComment) {
    return null;
  }

  const renderAttachments = () => {
    if (!ticket?.attachments) {
      return <></>;
    }

    return (
      <div className="attachments attachments--standalone">
        {ticket.attachments.map((attachment, index) => {
          const isImage = attachment.name.match(/\.(jpeg|jpg|gif|png|bmp)$/i);

          return (
            <div className="attachment" key={index}>
              {isImage ? (
                <>
                  <div
                    className="attachment__img"
                    onClick={() =>
                      setShowAttachedLightbox((prev) => {
                        const newState = [...prev];
                        newState[index] = !newState[index];
                        return newState;
                      })
                    }
                    key={index}
                  >
                    <img
                      src={attachment.url}
                      width={100}
                      height={'auto'}
                      alt="Image"
                    />
                  </div>
                  <div className="attachment__name">
                    <a href={attachment.url} target="_blank" rel="noreferrer">
                      <SaveIcon />
                      image.png{' '}
                      {/* Hier wird der feste Text "image.png" angezeigt */}
                    </a>
                  </div>
                </>
              ) : (
                <div className="attachment__name">
                  <a href={attachment.url} target="_blank" rel="noreferrer">
                    <SaveIcon />
                    {attachment.name}
                  </a>
                </div>
              )}
              {showAttachedLightbox[index] && isImage && (
                <Lightbox
                  mainSrc={attachment.url}
                  onCloseRequest={() =>
                    setShowAttachedLightbox((prev) => {
                      const newState = [...prev];
                      newState[index] = false;
                      return newState;
                    })
                  }
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const mainFeedbackContainerClass = classNames({
    'main-feedback-container': true,
    'main-feedback-container--shared': shared,
    'main-feedback-container--small': smallContainer,
  });

  const infoBoxContentClass = classNames({
    'info-box-content': true,
    'info-box-content--small': smallContainer,
  });

  return (
    <>
      <div className="static-comments">
        <div className={mainFeedbackContainerClass}>
          {!shared && ticket.parentTicket && (
            <Row alignItems="center" className="ticket-breadcrumbs">
              <div
                className="parent-ticket-title"
                onClick={() => {
                  projectStore?.openFeedbackItem({
                    shareToken: ticket.parentTicket!.shareToken,
                  });
                }}
              >
                <i className="fa-solid fa-diagram-subtask" />
                {ticket.parentTicket?.title ?? `#${ticket.parentTicket?.bugId}`}
              </div>
              <i className="fa-solid fa-chevron-right chevron-icon" />
              <div className="current-ticket-title">
                {ticket.title ?? `#${ticket.bugId}`}
              </div>
            </Row>
          )}
          {!(
            projectStore?.currentProject?.domainVerification?.postmarkData
              ?.SPFVerified &&
            projectStore?.currentProject?.domainVerification?.postmarkData
              ?.DKIMVerified
          ) &&
            organisationStore?.isOrganisationAdmin &&
            !shared && (
              <InfoBox
                simple={smallContainer}
                className="info-box--error verify-domain-info mb-20"
              >
                <div className={infoBoxContentClass}>
                  Improve your email deliverability by verifying your email
                  domain.
                  <PrimaryButton
                    fancy
                    className={smallContainer ? '' : 'ml-10'}
                    label="Start verification"
                    onClick={() => {
                      navigate(
                        `/projects/${projectStore?.currentProject?.id}/settings/domainverification`,
                      );
                    }}
                  />
                </div>
              </InfoBox>
            )}
          <BugScreenContent
            shared={shared}
            ticket={ticket}
            allowActions={allowActions}
          />
          <div className="main-feedback-content">
            <FormData
              shared={shared}
              ticket={ticket}
              allowActions={allowActions}
            />
            {!shared && ticket?.isSpam && allowActions && (
              <div className="static-comment-spaminfo">
                <div className="static-comment-spaminfo-inner">
                  <i className="fa-solid fa-hexagon-exclamation"></i> This
                  feedback item has been marked as spam.
                  <LinkButton
                    className="ml-10 unmark-spam-button"
                    label="Not spam"
                    isLoading={notSpamLoading}
                    onClick={async () => {
                      setNotSpamLoading(true);
                      bugStore?.unarchiveBug(bugStore?.currentBug?.id!);
                      setNotSpamLoading(false);
                    }}
                  />
                </div>
              </div>
            )}
            {renderAttachments()}
          </div>
        </div>
      </div>
    </>
  );
};

export default inject(
  'bugStore',
  'projectStore',
  'organisationStore',
)(observer(TicketInfoComment));
