import React from 'react';
import './SentimentTag.scss';

type SentimentTagProps = {
    sentiment: 'negative' | 'neutral' | 'positive';
};

const SentimentTag: React.FC<SentimentTagProps> = ({ sentiment }) => {
    let emoji = '';
    let title = '';
    let className = 'sentiment-tag';

    switch (sentiment) {
        case 'negative':
            emoji = '😞';
            title = 'Negative';
            break;
        case 'neutral':
            emoji = '🙂';
            title = 'Neutral';
            break;
        case 'positive':
            emoji = '😍';
            title = 'Positive';
            className += ' sentiment-tag--positive';
            break;
        default:
            break;
    }

    return (
        <div className={className}>
            <span className='icon' role="img" aria-label={title}>
                {emoji}
            </span>
            <span>{title}</span>
        </div>
    );
};

export default SentimentTag;
