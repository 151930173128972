import { HeadLine } from 'components/HeadLine/HeadLine';
import LinkButton from 'components/LinkButton/LinkButton';
import { Plantypes } from 'components/PriceTable/PriceTable';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import { inject } from 'mobx-react';
import { useEffect } from 'react';
import { BugStore } from 'stores/private/BugStore';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import Swal from 'sweetalert2';
import './SuggestChangeSubscriptionModal.scss';
import { useNavigate } from 'react-router';

interface SuggestChangeSubscriptionModalProps {
  modalStore?: ModalStore;
  bugStore?: BugStore;
  organisationStore?: OrganisationStore;
}

const SuggestChangeSubscriptionModal = ({
  modalStore,
  bugStore,
  organisationStore,
}: SuggestChangeSubscriptionModalProps) => {
  const navigate = useNavigate();

  const getSubtitle = () => {
    if (
      modalStore?.customData &&
      modalStore?.customData.type === 'projectlimit'
    ) {
      return 'Please upgrade your plan to add more projects.';
    }

    if (modalStore?.customData && modalStore?.customData.type === 'branding') {
      return 'Please upgrade your plan to remove our branding.';
    }

    if (modalStore?.customData && modalStore?.customData.type === 'feature') {
      return `Please upgrade your plan to use ${modalStore?.customData.name}.`;
    }

    if (
      modalStore?.customData &&
      modalStore?.customData.type === 'forwardtointegrations'
    ) {
      return `Please upgrade your plan to send feedback to your integrations.`;
    }

    if (
      modalStore?.customData &&
      modalStore?.customData.type === 'custom_feedback_type'
    ) {
      return `Please upgrade your plan to create custom boards.`;
    }

    return 'Please upgrade your plan to use this feature.';
  };

  return (
    <SizedContainer size={ContainerSizes.M}>
      <HeadLine title="Upgrade your plan 🚀" subtitle={getSubtitle()} />
      <div className="submit-container mt-15">
        {modalStore?.customData &&
          modalStore?.customData.type === 'dataretention' && (
            <LinkButton
              onClick={() => {
                modalStore!.closeModal();
                bugStore!.archiveBug(modalStore?.customData.bugId);
              }}
              label="Archive ticket"
            />
          )}
        <PrimaryButton
          className="plans-button"
          label="Upgrade now ✨"
          onClick={() => {
            if (organisationStore!.currentOrganisation) {
              modalStore!.closeModal();
              modalStore!.closeModal();
              navigate(`/organization/${organisationStore?.currentOrganisation?.id}/billing`);
            } else {
              modalStore!.closeModal();
              Swal.fire({
                text: 'Please contact your organization admin to upgrade your plan.',
                showCancelButton: false,
                confirmButtonText: `Ok`,
              });
            }
          }}
        />
      </div>
    </SizedContainer>
  );
};

export default inject(
  'modalStore',
  'bugStore',
  'organisationStore',
)(SuggestChangeSubscriptionModal);
