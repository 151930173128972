import React from 'react';
import classNames from 'classnames';
import './StatCard.scss';
import { formatCSATScore, getEmojiForVal } from 'helper/ScoreFormater';
import LoadingAnimationSmall from 'components/LoadingAnimationSmall/LoadingAnimationSmall';

function secondsToDhms(seconds) {
  seconds = Number(seconds);
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);

  const dDisplay = d > 0 ? `${d}d ` : '';
  const hDisplay = h > 0 ? `${h}h ` : '';
  const mDisplay = m > 0 ? `${m}m` : '';
  return dDisplay + hDisplay + mDisplay;
}

function percIncrease(a, b) {
  let percent;
  if (b !== 0) {
    if (a !== 0) {
      percent = ((b - a) / a) * 100;
    } else {
      percent = b * 100;
    }
  } else {
    percent = -a * 100;
  }
  return Math.floor(percent);
}

interface StatCardProps {
  icon: string;
  label: string;
  subLabel?: string;
  value?: number;
  prevValue?: number;
  valueSubLabel: string;
  className?: any;
  type?: string;
  hideChange?: boolean;
  showNoData?: boolean;
  isLoading?: boolean;
  loadingLabel?: string;
}

const StatCard = ({
  icon,
  label,
  subLabel,
  value,
  prevValue,
  valueSubLabel,
  className,
  hideChange = false,
  type = 'numbers',
  showNoData = false,
  isLoading = false,
  loadingLabel = '',
}: StatCardProps) => {
  const statCardClassName = classNames('stat-card', className);

  let change = 0;
  if (typeof prevValue !== 'undefined') {
    const prevVal = prevValue !== 0 ? prevValue : 0;
    change = percIncrease(prevVal, value);
  }

  const changeClass = () => {
    if (change === 0) {
      return '';
    }

    return change > 0 ? 'stat-card-change-plus' : 'stat-card-change-minus';
  };

  const formatValue = (val) => {
    if (type === 'numbers') {
      return Math.round(val);
    }

    if (type === 'csat') {
      const csatVal = formatCSATScore(val);
      return `${csatVal} ${getEmojiForVal(Math.round(val * 10))}`;
    }

    if (type === 'timespan') {
      return secondsToDhms(Math.round(val));
    }

    return '';
  };

  const getContent = () => {
    if (isLoading) {
      return (
        <>
          <div className="stat-card-body-title">
            <LoadingAnimationSmall />
          </div>
          <div className="stat-card-body-subtitle mt-20">{loadingLabel}</div>
        </>
      );
    }

    return showNoData ? (
      <div className="stat-card-body-subtitle">No data yet</div>
    ) : (
      <>
        {value && (
          <div className="stat-card-body-title">{formatValue(value)}</div>
        )}
        <div className="stat-card-body-subtitle">{valueSubLabel}</div>
        {!hideChange && (
          <div className={`stat-card-change ${changeClass()}`}>
            {change > 0 ? '+' : ''}
            {Math.round(change)}%
          </div>
        )}
      </>
    );
  };

  return (
    <div className={statCardClassName}>
      <div className="stat-card-header">
        <div className="stat-card-header-title">
          <i className={`fa-solid fa-${icon}`} /> {label}
        </div>
        {subLabel && (
          <div className="stat-card-header-subtitle">{subLabel}</div>
        )}
      </div>
      <div className="stat-card-body">{getContent()}</div>
    </div>
  );
};

export default StatCard;
