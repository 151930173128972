import { useState, useEffect } from 'react';
import './VerifyCode.scss';
import { HeadLine } from 'components/HeadLine/HeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { UsersStore } from 'stores/private/UsersStore';
import { inject } from 'mobx-react';
import ResendTimer from 'components/ResendTimer/ResendTimer';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import { useNavigate } from 'react-router';
import ReactCodeInput from 'react-verification-code-input';
import bbLogo from 'assets/icons/Gleap.svg';
import LinkButton from 'components/LinkButton/LinkButton';

interface VerifyCodeProps {
  usersStore?: UsersStore;
}

const CODE_LENGTH = 6;

const VerifyCode = ({ usersStore }: VerifyCodeProps) => {
  const navigate = useNavigate();
  const [isResend, setIsResend] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [code, setCode] = useState('');

  useEffect(() => {
    if (localStorage.getItem('@verifyTime')) {
      setIsResend(true);
    }
  }, []);

  useEffect(() => {
    if (!usersStore?.currentUser) {
      navigate('/login');
    }
  }, []);

  const verifyCode = async (currentCode: string) => {
    const isCorrect = await usersStore!.verifyCode(currentCode);
    if (!isCorrect) {
      setHasError(true);
    }
  };

  const verifycodeClassName = classNames({
    error: hasError,
  });

  return (
    <div className="verify-code-container">
      <img src={bbLogo} className="logo" alt="logo" />
      <SizedContainer
        size={ContainerSizes.L}
        className={`verify-code ${verifycodeClassName}`}
      >
        <HeadLine title="Email verification" className="mb-5" />
        <div className="information mb-30 text">
          We just sent you an email verification code. Please check your inbox. Used the wrong email? <span
            className="link"
            onClick={() => {
              usersStore?.logout();
            }}
          >
            Logout
          </span>
        </div>
        <ReactCodeInput
          className="code-input"
          type="number"
          fields={6}
          onComplete={(value) => {
            verifyCode(value);
          }}
          onChange={(value) => {
            setHasError(false);
            setCode(value);
          }}
        />
        <div className="footer mt-30">
          {!isResend && (
            <LinkButton
              label="Resend"
              className="text link-button"
              onClick={() => {
                usersStore!.resend();
                setIsResend(true);
              }}
            />
          )}
          {isResend && (
            <ResendTimer
              timeGone={() => {
                setIsResend(false);
              }}
            />
          )}
          <PrimaryButton
            label="Verify"
            icon='chevron-right'
            iconSideRight={true}
            onClick={() => {
              if (code.length === CODE_LENGTH) {
                verifyCode(code);
              } else {
                setHasError(true);
                toast.error('Please complete your verification code');
              }
            }}
          />
        </div>
      </SizedContainer>
    </div>
  );
};

export default inject('usersStore')(VerifyCode);
