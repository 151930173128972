import SimpleSidenavElement from 'components/Sidenav/SimpleSidenavElement/SimpleSidenavElement';
import SidenavContainer from 'components/SidenavContainer/SidenavContainer';
import SubSidenav from 'components/SubSidenav/SubSidenav';
import { inject, observer } from 'mobx-react';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router';
import { SidenavStore } from 'stores/private/SidenavStore';
import './Bots.scss';
import CustomBots from './CustomBots/CustomBots';
import FlowChart from '../BotConfigurationPage/FlowChart';
import FeedbackActionsEditor from 'components/FeedbackActionsEditor/FeedbackActionsEditor';
import FeedbackActionEditorItem from 'components/FeedbackActionsEditor/FeedbackActionEditorItem';
import { useEffect } from 'react';
import { ProjectStore } from 'stores/private/ProjectStore';

const Bots = ({
  sidenavStore,
  projectStore,
}: {
  sidenavStore?: SidenavStore;
  projectStore?: ProjectStore;
}) => {
  const { projectId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId, false, true);
    }
  }, [projectId]);

  const isActive = (path) => {
    if (location.pathname) {
      const parts = location.pathname.split('/');

      if (parts.length >= 4 && parts[4] === path) {
        return true;
      }

      if (path === '' && parts.length === 4 && parts[3] === 'bots') {
        return true;
      }
    }

    return false;
  };

  return (
    <SidenavContainer sidenavHidden={sidenavStore?.sidenavHidden}>
      <SubSidenav title="Workflows">
        <SimpleSidenavElement
          name="Workflows"
          faIcon="bolt"
          onClick={() => {
            navigate(`/projects/${projectId}/bots`);
          }}
          isActive={isActive('')}
        />
        <SimpleSidenavElement
          name="Forms"
          onClick={() => {
            navigate(`/projects/${projectId}/bots/forms`);
          }}
          faIcon="pen-field"
          isActive={isActive('forms')}
        />
      </SubSidenav>
      <Routes>
        <Route path="/" element={<CustomBots />} />
        <Route path="/custombots/:botId" element={<FlowChart />} />
        <Route
          path="/forms/:feedbackFlowId"
          element={<FeedbackActionEditorItem />}
        />
        <Route path="/forms" element={<FeedbackActionsEditor />} />
      </Routes>
    </SidenavContainer>
  );
};

export default inject('sidenavStore', 'projectStore')(observer(Bots));
