import React from 'react';
import './IntroCard.scss';
import FancyBlurBG from 'assets/bg/fancyblurbg.png';
import FancyBlurBG2 from 'assets/bg/fancyblurbg2.png';
import { title } from 'process';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';

type IntroCardProps = {
    headline: string;
    content: string;
    buttonText?: string;
    buttonAction?: () => void;
    image?: any;
    altBg?: boolean;
    imagePosition?: string;
};

const IntroCard: React.FC<IntroCardProps> = ({ headline, content, buttonText, buttonAction, image, altBg, imagePosition = 'center' }) => {
    return (
        <div className='intro-card' style={{
            backgroundImage: `url(${altBg ? FancyBlurBG2 : FancyBlurBG})`
        }}>
            <div className='intro-card-content'>
                <h1>{headline}</h1>
                <p className='text'>{content}</p>
                {buttonText && buttonAction && <PrimaryButton label={buttonText} onClick={buttonAction} />}
            </div>
            {image &&
                <img className='intro-card-image' src={image} alt={title} style={{
                    objectPosition: imagePosition ? imagePosition : 'center'
                }} />}
        </div>
    );
};

export default IntroCard;
