import InfoBox from 'components/InfoBox/InfoBox';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import { Title } from 'components/Title/Title';
import { checkIntegrationData } from 'helper/Integration';
import { inject, observer } from 'mobx-react';
import { INTEGRATION } from 'models/Integration';
import React, { useEffect, useState } from 'react';
import { GithubIntegrationStore } from 'stores/integrations/GithubIntegrationStore';
import { ProjectStore } from 'stores/private/ProjectStore';

interface GithubConfigurationProps {
  integrationID: string;
  index: number;
  githubIntegrationStore?: GithubIntegrationStore;
  projectStore?: ProjectStore;
  onChange: (data: any) => any;
}

const GithubConfiguration = ({
  integrationID,
  index,
  githubIntegrationStore,
  projectStore,
  onChange,
}: GithubConfigurationProps) => {
  const project = projectStore?.currentProject;

  const [currentIntegrationInfo, setCurrentIntegrationInfo] = useState(
    {} as any,
  );

  // @ts-ignore
  const [repositoryID, setRepositoryID] = useState('');

  const repositories = githubIntegrationStore?.repositories;

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    if (project && isMounted) {
      initialize();
    }
  }, [project, isMounted]);

  useEffect(() => {
    saveIntegrationInfo();
  }, [repositoryID]);

  const initialize = async () => {
    githubIntegrationStore!.setIntegrationID(integrationID);
    await githubIntegrationStore!.getRepositories();

    if (
      !checkIntegrationData({
        project,
        integrationName: INTEGRATION.GITHUB,
        integrationID,
        actionIndex: index,
      })
    ) {
      return;
    }

    const integrationInfo =
      project?.integrations?.github[integrationID]?.actions[index]
        ?.integrationInfo;

    setCurrentIntegrationInfo(integrationInfo);
  };

  const chooseRepository = async (repositoryId) => {
    setRepositoryID(repositoryId);
  };

  const saveIntegrationInfo = () => {
    const integrationInfo = {
      repositoryID,
    };

    onChange(integrationInfo);
  };

  return (
    <div className="configuration-container mt-30">
      <SelectDropDown
        className="mb-15"
        label="Repositories"
        labelPropertyName="name"
        valuePropertyName="id"
        selectedItem={repositories.find(
          (element) => element.id === currentIntegrationInfo?.repositoryID,
        )}
        isLoading={repositories.length === 0}
        items={repositories}
        onChange={(option) => {
          chooseRepository(option.id);
        }}
      />
      <InfoBox>
        <><b>Please note</b>, it might take some time to fetch all the repositories.</>
      </InfoBox>
    </div>
  );
};

export default inject(
  'githubIntegrationStore',
  'projectStore',
)(observer(GithubConfiguration));
