import classNames from 'classnames';
import './SidenavContainer.scss';

const SidenavContainer = ({ className, children, sidenavHidden, isCollapsed, type }: any) => {
  const isCollapsedBasedOnProjectType =
    type !== 'CRASH' && type !== 'RATING' && type !== 'INQUIRY' && type !== 'FEATURE_REQUEST' && isCollapsed;

  const SidenavContainerClassName = classNames(
    {
      'sidenav-container': true,
      'sidenav-container--sidenav-hidden': sidenavHidden,
      'sidenav-container--small': !isCollapsed,
      'sidenav-container--collapsed': isCollapsedBasedOnProjectType,
    },
    className,
  );

  return <div className={SidenavContainerClassName}>{children}</div>;
};

export default SidenavContainer;
