import classNames from 'classnames';
import { useEffect, useState } from 'react';
import Select, { ActionMeta } from 'react-select';
import './LanguageDropdown.scss';

interface LanguageDropdownProps {
  items: any[];
  onChange: (value: any, actionMeta?: ActionMeta<any>) => void;
  selectedItem?: any;
  placeholder?: string;
  disabled?: boolean;
  labelPropertyName?: string;
  valuePropertyName?: string;
  className?: any;
  isLoading?: boolean;
  showDot?: boolean;
  getOptionlabel?: (option: any) => string;
  getOptionValue?: (option: any) => string;
}

const LanguageDropdown = ({
  items,
  onChange,
  selectedItem,
  placeholder,
  disabled,
  labelPropertyName = 'name',
  valuePropertyName = 'code',
  className,
  isLoading,
  showDot = false,
  getOptionlabel = (option) => {
    return `${option[labelPropertyName]} ${option.hasTranslation}`;
  },
  getOptionValue = (option) => option[valuePropertyName],
}: LanguageDropdownProps) => {
  const [selectedValue, setSelectedValue] = useState();

  const selectClassName = classNames(
    {
      'language-select': true,
    },
    className,
  );

  useEffect(() => {
    if (!items) {
      return;
    }

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if ('options' in item) {
        delete item.options;
      }
    }
  }, [items]);

  useEffect(() => {
    if (selectedItem && selectedItem !== null && selectedItem !== '') {
      onChange(selectedItem);
      setSelectedValue(selectedItem);
    }
  }, [selectedItem]);

  return (
    <Select
      isLoading={isLoading}
      className={selectClassName}
      classNamePrefix="language-selection"
      value={selectedValue ?? selectedItem}
      components={{
        SingleValue: (option: any) => {
          return (
            <div className="language-item">
              {showDot && (
                <div className={`language-item-dot language-item-dot-main`} />
              )}
              <span>{option.data.name}</span>
            </div>
          );
        },
        Option: (option: any) => {
          return (
            <div
              className="option option-list-item language-item"
              onClick={() => {
                if (option.selectOption) {
                  option.selectOption(option.data);
                }
              }}
            >
              {showDot && (
                <div
                  className={`language-item-dot ${
                    option.data.hasTranslation
                      ? 'language-item-dot--ok'
                      : 'language-item-dot--missing'
                  }`}
                />
              )}
              <span>{option.data.name}</span>
            </div>
          );
        },
      }}
      onChange={(val) => {
        setSelectedValue(val);
        onChange(val);
      }}
      isSearchable={false}
      options={items}
      placeholder={placeholder}
      isDisabled={disabled}
    />
  );
};

export default LanguageDropdown;
