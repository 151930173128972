import { runInAction } from 'mobx';
import { Property } from 'models/Property';

export const setProperty = (obj: any, path: string, val: any): void => {
  if (typeof obj !== 'object' || obj === null) {
    obj = {};
  }

  const pathParts = path.split(/[\.\[\]]/).filter((p) => p); // Split by dots and square brackets

  let current = obj;

  for (let i = 0; i < pathParts.length; i++) {
    const part = pathParts[i];

    if (i === pathParts.length - 1) {
      current[part] = val;
    } else {
      // Check if the next part is an integer (array index)
      if (!isNaN(parseInt(pathParts[i + 1]))) {
        current[part] = current[part] || [];
      } else {
        current[part] = current[part] || {};
      }
      current = current[part];
    }
  }
};

export const getProperty = (
  obj: any,
  path: string,
  fallback: boolean = false,
  fallbackValue: any = '',
): any => {
  const pathParts = path.split(/[\.\[\]]/).filter((p) => p); // This splits by dots and square brackets, producing an array of keys and indices.

  return pathParts.reduce((current, part) => {
    if (current?.[part] !== undefined && current?.[part] !== null) {
      return current[part];
    }

    if (fallback) {
      if (current?.['en'] !== undefined && current?.['en'] !== null) {
        return current['en'];
      }
    }

    return fallbackValue;
  }, obj);
};

export const setLanguageProperty = (
  obj: any,
  path: string,
  language: string = 'en',
  val: any,
): void => {
  const languagePath = `${path}.localized.${language}`;

  setProperty(obj, languagePath, val);
};

export const getLanguageProperty = (
  obj: any,
  path: string,
  language: string = 'en',
  fallback: boolean = false,
): any => {
  const languagePath = `${path}.localized.${language}`;

  // Backwards compatibility
  const value = path.split('.').reduce((obj, key) => {
    if (obj !== undefined && obj !== null && obj[key] !== undefined) {
      return obj[key];
    }
    return null;
  }, obj);

  if (
    typeof value === 'string' ||
    typeof value === 'number' ||
    value?.type === 'doc' // TipTap backwards compatibility
  ) {
    // Forwards compatibility. If the value is a string or number, we need to create it.
    setProperty(obj, path, null); // Remove the old value
    setProperty(obj, `${path}.localized.${language}`, value);
    return value;
  }

  const valueToReturn = getProperty(obj, languagePath, fallback);

  return valueToReturn;
};

export const hasTranslationForLanguage = (obj, languageCode) => {
  if (typeof obj !== 'object' || obj === null) {
    return false; // Base case: obj is not an object or is null, no translation needed
  }

  if (Array.isArray(obj)) {
    for (const item of obj) {
      if (hasTranslationForLanguage(item, languageCode)) {
        return true; // At least one item in the array has translation for the specified language code
      }
    }
    return false; // No item in the array has translation for the specified language code
  }

  for (const key in obj) {
    if (key === 'localized') {
      const translations = obj[key];

      if (translations && translations[languageCode]) {
        return true; // Translation for the specified language code exists
      }

      continue; // Skip further processing for 'localized' property
    }

    if (hasTranslationForLanguage(obj[key], languageCode)) {
      return true; // Found a property with translation for the specified language code
    }
  }

  return false; // No property has translation for the specified language code
};

export const localizeDocument = (localizedDoc, lang = 'en') => {
  const getLocalizedValue = (obj, propName, lang) => {
    const prop = obj[propName];
    if (prop && typeof prop === 'object' && prop.hasOwnProperty('localized')) {
      let localizedValue;
      if (prop.localized[lang] && prop.localized[lang] !== '') {
        localizedValue = prop.localized[lang];
      } else if (
        lang.includes('-') &&
        prop.localized[lang.split('-')[0]] &&
        prop.localized[lang.split('-')[0]] !== ''
      ) {
        localizedValue = prop.localized[lang.split('-')[0]];
      } else if (prop.localized.en && prop.localized.en !== '') {
        localizedValue = prop.localized.en;
      } else {
        localizedValue = prop.localized[Object.keys(prop.localized)[0]];
      }
      return localizedValue || '';
    } else {
      if (typeof prop === 'boolean') {
        return prop;
      }
      return prop || '';
    }
  };

  const localizeObject = (obj) => {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        let localizedValue = getLocalizedValue(obj, prop, lang);

        if (typeof obj[prop] === 'object' && obj[prop] !== null) {
          localizeObject(obj[prop]);
        }

        obj[prop] = localizedValue;
      }
    }
  };

  localizeObject(localizedDoc);

  return localizedDoc;
};

// For old translations table (legacy)
export const translateText = (customTranslations, key, lang) => {
  if (!key || key == '') {
    return '';
  }

  if (typeof customTranslations === 'undefined' || !customTranslations) {
    return key;
  }

  const searchForTranslationTable = (langKey: string) => {
    var customTranslation = null;
    const translationKeys = Object.keys(customTranslations);
    for (var i = 0; i < translationKeys.length; i++) {
      const translationKey = translationKeys[i];
      if (
        langKey &&
        translationKey &&
        langKey === translationKey.toLowerCase()
      ) {
        if (customTranslations[translationKey]) {
          customTranslation = customTranslations[translationKey];
        }
      }
    }

    return customTranslation;
  };

  var customTranslation = searchForTranslationTable(lang);

  // Extended search for language match only.
  if (!customTranslation && lang) {
    const langKeys = lang.split('-');
    if (langKeys && langKeys.length > 1) {
      customTranslation = searchForTranslationTable(langKeys[0]);
    }
  }

  if (customTranslation && customTranslation[key]) {
    return customTranslation[key];
  }

  return key;
};

export const setSchemaProperty = (obj: any, property: Property, val: any) => {
  let path = property.fieldId;

  if (property.path && property.path !== '') {
    path = `${property.path}.${property.fieldId}`;
  }

  runInAction(() => {
    setProperty(obj, path, val);
  });
};

export const getSchemaProperty = (
  obj: any,
  property: Property,
  fallback: boolean = false,
  fallbackValue: any = '',
) => {
  let path = property.fieldId;

  if (property.path && property.path !== '') {
    path = `${property.path}.${property.fieldId}`;
  }

  return getProperty(obj, path, fallback, fallbackValue);
};
