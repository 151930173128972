import InfoBox from 'components/InfoBox/InfoBox';
import LoadingAnimation from 'components/LoadingAnimation/LoadingAnimation';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import Gleap from 'gleap';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { OutboundStore } from 'stores/private/OutboundStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { SidenavStore } from 'stores/private/SidenavStore';
import Swal from 'sweetalert2';
import './ProductTourEditorPage.scss';

interface ProductTourEditorPageProps {
  projectStore?: ProjectStore;
  outboundStore?: OutboundStore;
  sidenavStore?: SidenavStore;
  type?: string;
}

const ProductTourEditorPage = ({
  projectStore,
  outboundStore,
  sidenavStore,
  type,
}: ProductTourEditorPageProps) => {
  const { projectId, outboundId } = useParams();
  const outboundRule = outboundStore?.outboundRule;
  const [productTourInitialUrl, setProductTourInitialUrl] =
    useState<string>('https://');

  useEffect(() => {
    projectStore!.loadProjectById(projectId!);

    Gleap.showFeedbackButton(false);

    runInAction(() => {
      sidenavStore!.sidenavHidden = true;
      sidenavStore!.mainSidenavHidden = true;
    });
  }, []);

  useEffect(() => {
    if (projectStore?.currentProject && outboundId) {
      projectStore.getStreamedEventKeys();
      outboundStore!.loadOutboundRule(outboundId);
      outboundStore!.loadOutboundRuleResponses({ id: outboundId });
    }
  }, [projectStore?.currentProject]);

  useEffect(() => {
    if (outboundRule?.config?.startURL) {
      setProductTourInitialUrl(outboundRule?.config?.startURL);
    }
  }, [outboundRule]);

  const openURL = () => {
    if (!productTourInitialUrl || productTourInitialUrl.length === 0) {
      Swal.fire('Error', 'Please enter a valid URL', 'error');
      return;
    }

    if (productTourInitialUrl.indexOf('http') !== 0) {
      Swal.fire(
        'Error',
        'Please enter a valid URL starting with "https://"',
        'error',
      );
      return;
    }

    window.location.href = productTourInitialUrl;
  };

  const editor = () => {
    if (!outboundRule) {
      return (
        <div className="centered">
          <LoadingAnimation />
        </div>
      );
    }

    return (
      <>
        <div className="title mb-20">
          {type === 'tooltips'
            ? 'Enter your website to add tooltips'
            : 'Choose the website where you start your tour on'}
        </div>
        <TextInput
          placeholder="Enter website URL"
          onEnter={() => {
            openURL();
          }}
          value={productTourInitialUrl}
          onChange={(value) => {
            setProductTourInitialUrl(value);
          }}
        />
        <InfoBox className="mt-20">
          Please make sure that you have installed the <b>latest Gleap SDK</b>{' '}
          on your website.
        </InfoBox>
        <PrimaryButton
          className="mt-20"
          label="Start"
          onClick={() => {
            openURL();
          }}
        />
      </>
    );
  };

  return (
    <div className="product-tour-startpage">
      <div className="product-tour-startpage--inner">{editor()}</div>
    </div>
  );
};

export default inject(
  'projectStore',
  'outboundStore',
  'sidenavStore',
)(observer(ProductTourEditorPage));
