import React, { useEffect, useRef, useState } from 'react';
import './PushNotificationConfiguration.scss';
import { ProjectStore } from 'stores/private/ProjectStore';
import { inject, observer } from 'mobx-react';
import {
  getLanguageProperty,
  setLanguageProperty,
} from 'helper/AssignObjectKeysHelper';
import { Editor } from '@tiptap/react';
import { Outbound } from 'models/Outbound';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import TextInput from 'components/TextInput/TextInput';
import { runInAction } from 'mobx';
import Toolbar, { ToolbarItems } from 'components/Editors/ToolBar/Toolbar';
import EmailEditor from 'components/Editors/EmailEditor/EmailEditor';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import PushNotificationPreview from './PushNotificationPreview';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { languages } from 'constants/Languages';

interface PushNotificationConfigurationProps {
  outboundRule: Outbound;
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

const PushNotificationConfiguration = ({
  outboundRule,
  projectStore,
  modalStore,
}: PushNotificationConfigurationProps) => {
  const editorRef: React.MutableRefObject<Editor | null> = useRef(null);
  const [toggleState, setToggleState] = useState(false);
  const availableLanguages = languages.filter((language) =>
    projectStore?.currentProject?.localizations.find(
      (item) => item.language === language.code,
    ),
  );
  const currentLang = projectStore?.currentLanguage ?? 'en';
  console.log(currentLang)

  useEffect(() => {
    const message = getLanguageProperty(outboundRule, 'message', currentLang);

    editorRef?.current?.commands.setContent(message !== '' ? message : {});
  }, [editorRef, currentLang]);

  const _buildEditor = () => {
    return (
      <SizedContainer size={ContainerSizes.L}>
        <Column>
          <TextInput
            label="Title"
            placeholder="Enter title"
            error=""
            localizedable
            value={getLanguageProperty(outboundRule, 'subject', currentLang)}
            onChange={(val) => {
              runInAction(() => {
                setLanguageProperty(outboundRule, 'subject', currentLang, val);
              });
            }}
          />
          <PrimaryButton
            fancy
            small
            className="button-container"
            label="AI-translate"
            onClick={() => {
              const targetLanguage = availableLanguages.find(
                (lang) => lang.code === currentLang,
              );

              modalStore?.openModalSmart(MODALTYPE.DYNAMIC_AI_TRANSLATION, {
                baseLanguage: targetLanguage,
                targetLanguage: availableLanguages,
                languageList: availableLanguages,
                getContent: (baseLang) => {
                  const content = getLanguageProperty(
                    outboundRule,
                    'message',
                    baseLang,
                  );
                  const title = getLanguageProperty(
                    outboundRule,
                    'subject',
                    baseLang,
                  );

                  return {
                    title: title,
                    content: content,
                  };
                },
                setContent: (translatedContent, lang) => {
                  const { content, title } = translatedContent;
                  if (content) {
                    runInAction(() => {
                      setLanguageProperty(
                        outboundRule,
                        'message',
                        lang,
                        content,
                      );
                    });
                    
                    if (lang === currentLang) {
                      editorRef?.current?.commands.setContent(
                        content !== '' ? content : {},
                      );
                    }
                  }
                  if (title) {
                    setLanguageProperty(
                      outboundRule,
                      'subject',
                      lang,
                      title,
                    );
                  }
                },
              });
            }}
          />
          <div className="push-input-container">
            <div className="toolbar-wrapper">
              <Toolbar
                editor={editorRef?.current}
                aiStyle="news"
                items={[ToolbarItems.VARIABLES]}
              />
            </div>
            <div className="editor-wrapper">
              <EmailEditor
                editorRef={editorRef}
                onEditorReady={() => {
                  setToggleState(!toggleState);
                }}
                content={getLanguageProperty(
                  outboundRule,
                  'message',
                  currentLang,
                )}
                inputContentChanged={(content) => {
                  runInAction(() => {
                    setLanguageProperty(
                      outboundRule,
                      'message',
                      currentLang,
                      content,
                    );

                    outboundRule.message = {
                      ...outboundRule.message,
                    };
                  });
                }}
              />
            </div>
          </div>
        </Column>
      </SizedContainer>
    );
  };

  const _buildPreview = () => {
    return <PushNotificationPreview outboundRule={outboundRule} />;
  };

  return (
    <Row justifyContent="space-between">
      {_buildEditor()}
      {_buildPreview()}
    </Row>
  );
};

export default inject(
  'projectStore',
  'modalStore',
)(observer(PushNotificationConfiguration));
