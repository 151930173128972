import { useEffect, useState } from 'react';
import './FeatureRequestsOrder.scss';
import Select from 'react-select';
import { inject, observer } from 'mobx-react';
import { SharedBoardStorePublic } from 'stores/public/SharedBoardStorePublic';

interface FeatureRequestsOrderProps {
  value?: any;
  onValueChanged?: (value?: string) => void;
  sharedBoardStorePublic?: SharedBoardStorePublic;
}

export const PublicOrderFilters = {
  VOTES: {
    sortKey: 'upvotesCount',
    sortDirection: 'desc',
  },
  RECENT: {
    sortKey: 'createdAt',
    sortDirection: 'desc',
  },
  RANDOM: {
    sortKey: 'createdAt',
    sortDirection: 'desc',
    afterFetchAction: (data: any) => {
      // Randomize the order of the feature requests
      data.sort(() => Math.random() - 0.5);
    },
  },
};

const FeatureRequestsOrder = ({
  value,
  onValueChanged,
  sharedBoardStorePublic,
}: FeatureRequestsOrderProps) => {
  const [data, setData] = useState(undefined as any);
  const sharedFeatureRequestStates = [
    {
      title: sharedBoardStorePublic?.translateText('featureRequestSort'),
      icon: 'arrow-down-wide-short',
      key: 'VOTES',
      ...PublicOrderFilters.VOTES,
    },
    {
      title: sharedBoardStorePublic?.translateText('featureRequestSortDate'),
      key: 'RECENT',
      ...PublicOrderFilters.RECENT,
      icon: 'rss',
    },
    {
      title: sharedBoardStorePublic?.translateText('featureRequestSortRandom'),
      key: 'RANDOM',
      ...PublicOrderFilters.RANDOM,
      icon: 'shuffle',
    },
  ];

  useEffect(() => {
    const filterStatus = sharedFeatureRequestStates.find(
      (x) => x.key === value,
    );
    setData(filterStatus);
  }, [value]);

  return (
    <Select
      className="dropdown-selection feature-requests-order-filter"
      classNamePrefix="dropdown-selection"
      defaultValue={data}
      value={data}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 99999, minWidth: 180 }),
      }}
      menuPortalTarget={document.body}
      menuPosition="fixed"
      isSearchable={false}
      components={{
        SingleValue: (option: any) => {
          return (
            <div className="order-prio-item">
              <i className={`fa-solid fa-${option.data.icon}`}></i>
              <span>{option.data.title}</span>
            </div>
          );
        },
        Option: (option: any) => {
          return (
            <div
              className="option option-list-item order-prio-item"
              onClick={() => {
                if (option.selectOption) {
                  option.selectOption(option.data);
                }
              }}
            >
              <i className={`fa-solid fa-${option.data.icon}`}></i>
              <span>{option.data.title}</span>
            </div>
          );
        },
      }}
      onChange={(selected: any) => {
        setData(selected);
        if (onValueChanged) {
          onValueChanged(selected);
        }
      }}
      options={sharedFeatureRequestStates}
    />
  );
};

export default inject('sharedBoardStorePublic')(observer(FeatureRequestsOrder));
