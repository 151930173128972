import BoardSearchFilter from 'components/BoardSearchFilter/BoardSearchFilter';
import Filter from 'components/Filter/Filter';
import InfoBox from 'components/InfoBox/InfoBox';
import LinkButton from 'components/LinkButton/LinkButton';
import ListTable, {
  CellGestureDetector,
  CellTag,
  CellTextIcon,
} from 'components/ListTable/ListTable';
import Loading from 'components/Loading/Loading';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import { getDataDescription } from 'helper/FormDataHelper';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import TimeAgo from 'react-timeago';
import { ProjectStore } from 'stores/private/ProjectStore';
import './ProjectArchive.scss';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { cleanupName } from 'components/FeedbackUserFilter/FeedbackUserFilter';
import { getSessionName } from 'services/GuestNameHelper';
import { Outlet, useNavigate } from 'react-router';
import { PropertyStore } from 'stores/private/PropertyStore';

interface ProjectArchiveProps {
  projectStore?: ProjectStore;
  propertyStore?: PropertyStore;
  type?: string;
}

const ProjectArchive = ({
  projectStore,
  propertyStore,
  type,
}: ProjectArchiveProps) => {
  const archivedBugs = projectStore!.archivedBugsDataList.data;
  const [filter, setFilter] = useState('ALL');
  const navigate = useNavigate();

  useEffect(() => {
    if (projectStore?.currentProject) {
      projectStore!.getArchivedFeedbackItems({
        type,
      });
    }
  }, [projectStore?.currentProject]);

  useEffect(() => {
    projectStore!.archivedBugsDataList.pageIndex = 0;
    projectStore!.getArchivedFeedbackItems({
      isSpam: filter === 'SPAM',
      type,
    });
  }, [filter]);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (row) => ({
          bug: row,
        }),
        Cell: (row) => (
          <CellGestureDetector
            onClick={() => {
              projectStore!.openFeedbackItem({
                shareToken: row.value.bug.shareToken,
              });
            }}
            label={`#${row.value.bug.bugId} ${getDataDescription({
              data: row.value.bug,
              maxLength: 45,
              propertyStore,
              feedbackType: row.value.bug.type,
            })}`}
          />
        ),
      },
      {
        Header: 'Assigned',
        width: '16%',
        accessor: (row) => ({
          bug: row,
        }),
        Cell: (row) => {
          const processingUser = row.value.bug.processingUser;

          return (
            <div className="user-avatar-container-createdBy">
              {processingUser && processingUser.firstName && (
                <>
                  <UserAvatar
                    className="mr-10"
                    xsmall
                    imageUrl={processingUser.profileImageUrl}
                  />
                  <span>{`${processingUser.firstName} ${processingUser.lastName}`}</span>
                </>
              )}
            </div>
          );
        },
      },
      {
        Header: 'Reported by',
        width: '15%',
        accessor: (row) => ({
          bug: row,
        }),
        Cell: (row) => (
          <CellGestureDetector
            onClick={() => {
              navigate(
                `/projects/${projectStore?.currentProject?.id}/sessions/${row.value.bug.session.id}`,
              );
            }}
            label={cleanupName(getSessionName(row.value.bug.session), 20)}
          />
        ),
      },
      {
        Header: 'Type',
        width: '50px',
        accessor: (row) => ({
          bug: row,
        }),
        Cell: (row) => {
          return <CellTag className="" text={row.value.bug.type} />;
        },
      },
      {
        className: 'hide-on-mobile',
        width: '15%',
        Header: 'Created at',
        accessor: (row) => ({
          bug: row,
        }),
        Cell: (row) => moment(row.value.bug.createdAt).format('MMM Do, h:mma'),
      },
      {
        className: 'hide-on-mobile',
        width: '15%',
        Header: 'Deletion in',
        accessor: (row) => ({
          bug: row,
        }),
        Cell: (row) => {
          if (row.value.bug.archivedAt) {
            return (
              <TimeAgo
                date={moment(row.value.bug.archivedAt).add(365, 'days')}
              />
            );
          }
          return 'n/a';
        },
      },
      {
        className: 'hide-on-mobile',
        Header: 'Spam',
        width: '5%',
        accessor: (row) => ({
          bug: row,
        }),
        Cell: (row) =>
          row.value.bug.isSpam ? (
            <CellTextIcon className="cell-tag--red" icon={'xmark'} />
          ) : (
            <CellTextIcon className="cell-tag--green" icon={'check'} />
          ),
      },
    ],
    [],
  );
  return (
    <PageContainer>
      <PageHeadLine
        title={`Archive${
          (projectStore?.archivedBugsDataList?.totalItems ?? 0) > 0
            ? ` (${projectStore?.archivedBugsDataList?.totalItems})`
            : ''
        }`}
      >
        <div className="filter-bar">
          <Filter
            className="mr-20"
            onValueChange={(value) => {
              setFilter(value);
            }}
            value={filter}
            options={[
              { name: 'All', value: 'ALL' },
              { name: 'Spam', value: 'SPAM' },
            ]}
          />
          <BoardSearchFilter />
        </div>
      </PageHeadLine>
      <PageContent hasTitle>
        <InfoBox className="mb-30">
          <>All archived items will be deleted automatically after 1 year.</>
        </InfoBox>
        <ListTable data={archivedBugs} columns={columns} />
        {projectStore?.archivedBugsDataList.isLoading &&
          projectStore!.archivedBugsDataList.data.length === 0 && (
            <div className="mt-20">
              <Loading />
            </div>
          )}
        {projectStore!.archivedBugsDataList.data.length > 0 &&
          projectStore!.archivedBugsDataList.itemsInPage *
            (projectStore!.archivedBugsDataList.pageIndex + 1) ===
            projectStore!.archivedBugsDataList.data.length && (
            <div className="mt-20">
              <LinkButton
                isLoading={projectStore?.archivedBugsDataList?.isLoading}
                label="Load more"
                onClick={() => {
                  projectStore!.getArchivedFeedbackItems({
                    loadMore: true,
                    isSpam: filter === 'SPAM',
                    type,
                  });
                }}
              />
            </div>
          )}
      </PageContent>
      <Outlet />
    </PageContainer>
  );
};

export default inject(
  'projectStore',
  'propertyStore',
)(observer(ProjectArchive));
