import InfoBox from 'components/InfoBox/InfoBox';
import LinkButton from 'components/LinkButton/LinkButton';
import ListTable, {
  CellGestureDetector,
  CellTag,
  CellTextIcon,
} from 'components/ListTable/ListTable';
import TabButton from 'components/TabButton/TabButton';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import TimeAgo from 'react-timeago';
import { HelpcenterStore } from 'stores/private/HelpcenterStore';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './DataSources.scss';
import Gleap from 'gleap';
import Swal from 'sweetalert2';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';

interface DataSourcesProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
  organisationStore?: OrganisationStore;
  helpcenterStore?: HelpcenterStore;
}

const DataSources = ({
  projectStore,
  modalStore,
  organisationStore,
  helpcenterStore,
}: DataSourcesProps) => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [currentSource, setCurrentSource] = useState('snippets');
  const currentProject = projectStore?.currentProject;

  const renderTaskColumns = useMemo(
    () => [
      {
        Header: 'URL',
        width: '50%',
        accessor: (row) => row,
        Cell: (row) => (
          <CellGestureDetector
            onClick={() => {
              modalStore!.openModalSmart(MODALTYPE.CRAWLED_PAGES, {
                crawlerTask: row.value,
              });
            }}
            label={row.value.url}
          />
        ),
      },
      {
        Header: 'Pages indexed',
        accessor: (row) => row,
        Cell: (row) => {
          const status = row.value.status;
          const crawledUrls = row.value.crawledUrls;

          if (status === 'FINISHED' && crawledUrls === 0) {
            return (
              <LinkButton
                iconSideRight={false}
                onClick={() => {
                  Gleap.openHelpCenterArticle('157', false);
                }}
                icon="circle-info"
                label="Resolve issue"
              />
            );
          }

          return <CellTextIcon icon="hashtag" text={crawledUrls} />;
        },
      },
      {
        Header: 'Status',
        accessor: (row) => row,
        Cell: (row) => {
          const status = row.value.status;
          const crawledUrls = row.value.crawledUrls;

          let statusText = 'Unknown';
          let icon = 'question-circle';
          if (status === 'REQUESTED' || status === 'PENDING') {
            statusText = 'Requested';
            icon = 'hourglass-half';
          } else if (status === 'IN_PROGRESS') {
            statusText = 'In progress';
            icon = 'rotate fa-spin';
          } else if (status === 'FINISHED') {
            if (crawledUrls > 0) {
              statusText = 'Finished';
              icon = 'check-circle';
            } else {
              statusText = 'Crawler blocked';
              icon = 'times-circle';
            }
          } else {
            statusText = 'Failed';
            icon = 'times-circle';
          }

          return <CellTextIcon icon={icon} text={statusText} />;
        },
      },
      {
        Header: 'Last crawled',
        accessor: 'lastTimeCrawled',
        Cell: (row) => {
          if (row.value) {
            return <TimeAgo date={row.value} />;
          }
          return <CellTextIcon icon="hourglass-half" text="Not crawled yet" />;
        },
      },
      {
        width: 200,
        Header: 'Action',
        accessor: (row) => row,
        Cell: (row) => (
          <div className="buttons-line">
            <LinkButton
              iconSideRight={false}
              className="danger"
              onClick={() => {
                if (!projectStore?.currentProject?.id) {
                  return;
                }

                projectStore?.deleteProjectCrawlerTask(
                  projectStore?.currentProject?.id,
                  row.value.id,
                );
              }}
              icon="trash"
            />
            <LinkButton
              className="ml-10"
              iconSideRight={false}
              onClick={() => {
                if (!projectStore?.currentProject?.id) {
                  return;
                }

                modalStore!.openModalSmart(MODALTYPE.CRAWLED_PAGES, {
                  crawlerTask: row.value,
                });
              }}
              icon="memo-circle-check"
              label="View pages"
            />
          </div>
        ),
      },
    ],
    [],
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Question',
        width: '65%',
        accessor: (row) => row,
        Cell: (row) => (
          <CellGestureDetector
            onClick={() => {
              modalStore!.openModal(MODALTYPE.EDIT_QA, {
                answer: row.value,
              });
            }}
            label={row.value.question}
          />
        ),
      },
      {
        Header: 'Status',
        accessor: (row) => row,
        Cell: (row) => <CellTag className="cell-tag--green" text="Active" />,
      },
      {
        Header: 'Created at',
        accessor: 'createdAt',
        Cell: (row) => <TimeAgo date={row.value} />,
      },
      {
        width: 140,
        Header: 'Action',
        accessor: (row) => row,
        Cell: (row) => (
          <div>
            <LinkButton
              iconSideRight={false}
              onClick={() => {
                modalStore!.openModal(MODALTYPE.EDIT_QA, {
                  answer: row.value,
                });
              }}
              icon="edit"
              label="Edit"
            />
          </div>
        ),
      },
    ],
    [],
  );

  const functionColumns = useMemo(
    () => [
      {
        Header: 'Function name',
        width: '65%',
        accessor: (row) => row,
        Cell: (row) => (
          <CellGestureDetector
            onClick={() => {
              modalStore!.openModal(MODALTYPE.CREATE_AI_FUNCTION_CALL, {
                aiFunction: row.value,
              });
            }}
            label={row.value.name}
          />
        ),
      },
      {
        Header: 'Created at',
        accessor: 'createdAt',
        Cell: (row) => <TimeAgo date={row.value} />,
      },
      {
        width: 140,
        Header: 'Action',
        accessor: (row) => row,
        Cell: (row) => (
          <div className="buttons-line">
            <LinkButton
              iconSideRight={false}
              className="danger mr-10"
              onClick={() => {
                Swal.fire({
                  text: 'Do you really want to delete this function? This action cannot be undone.',
                  showCancelButton: true,
                  confirmButtonText: `Yes`,
                  denyButtonText: `No`,
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    projectStore?.deleteAIFunction(row.value.id);
                  }
                });
              }}
              icon="trash"
            />

            <LinkButton
              iconSideRight={false}
              onClick={() => {
                modalStore!.openModal(MODALTYPE.CREATE_AI_FUNCTION_CALL, {
                  aiFunction: row.value,
                });
              }}
              icon="edit"
              label="Edit"
            />
          </div>
        ),
      },
    ],
    [],
  );

  const fileColumns = useMemo(
    () => [
      {
        Header: 'File name',
        width: '65%',
        accessor: (row) => row,
        Cell: (row) => (
          <CellGestureDetector
            onClick={() => {
              modalStore!.openModal(MODALTYPE.EDIT_QA, {
                answer: row.value,
              });
            }}
            label={row.value.question}
          />
        ),
      },
      {
        Header: 'Status',
        accessor: (row) => row,
        Cell: (row) => <CellTag className="cell-tag--green" text="Active" />,
      },
      {
        Header: 'Created at',
        accessor: 'createdAt',
        Cell: (row) => <TimeAgo date={row.value} />,
      },
      {
        width: 140,
        Header: 'Action',
        accessor: (row) => row,
        Cell: (row) => (
          <div>
            <LinkButton
              iconSideRight={false}
              onClick={() => {
                modalStore!.openModal(MODALTYPE.EDIT_QA, {
                  answer: row.value,
                });
              }}
              icon="edit"
              label="Edit"
            />
          </div>
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    if (currentProject) {
      projectStore!.getQAAnswers(currentProject.id, false, 0, 500);
      projectStore!.getProjectAnswerStats(currentProject.id);
      projectStore!.getProjectCrawlerTasks(currentProject.id);
      projectStore!.loadAIFunctions();
      helpcenterStore?.getCollections();
    }
  }, [currentProject]);

  if (!currentProject) {
    return <></>;
  }

  const answers =
    projectStore?.currentQAAnswers.filter((answer) => {
      if (answer.skipped || !answer.acknowledged || answer.type === 'file') {
        return false;
      }

      return true;
    }) || [];

  const files =
    projectStore?.currentQAAnswers.filter((answer) => {
      if (answer.skipped || !answer.acknowledged || answer.type !== 'file') {
        return false;
      }

      return true;
    }) || [];

  const crawlerTasks = projectStore?.crawlerTasks ?? [];

  const renderAnswers = () => {
    return (
      <div className="table-view-card">
        <div className="table-view-card-header">
          <div className="table-view-card-header-title">💬 Snippets</div>
        </div>
        <InfoBox className="mb-30">
          Kai <b>uses the snippets</b> below to answer customer questions.
          Snippets are short answers to common questions that are only used by
          Kai.
        </InfoBox>
        <ListTable data={answers} columns={columns} />
        {answers.length % 500 === 0 && answers.length > 0 && (
          <div className="centered">
            <PrimaryButton
              isLoading={projectStore!.loadingQAAnswers}
              className="mt-20"
              label="Load more"
              onClick={() => {
                projectStore!.loadMoreQAAnswers(
                  currentProject.id,
                  false,
                  answers.length,
                  500,
                );
              }}
            />
          </div>
        )}
      </div>
    );
  };

  const renderFiles = () => {
    return (
      <div className="table-view-card">
        <div className="table-view-card-header">
          <div className="table-view-card-header-title">🗃️ Files</div>
        </div>
        <InfoBox className="mb-30">
          Kai <b>uses the files</b> below to answer customer questions. Kai
          converts files into text and uses the text to answer customer
          questions.
        </InfoBox>
        <ListTable data={files} columns={fileColumns} />
      </div>
    );
  };

  const renderAPIsources = () => {
    return (
      <div className="table-view-card">
        <div className="table-view-card-header">
          <div className="table-view-card-header-title">☁️ API sources</div>
        </div>
        <InfoBox className="mb-30">
          Kai uses <b>the API sources</b> specified below to gather context to
          answer customer support inquiries. This allows you to provide user
          with customer-specific information like order status, account
          information, etc.
          <br />
          <PrimaryButton
            className="mt-10"
            label="Learn more"
            onClick={() => {
              Gleap.openHelpCenterArticle('154', false);
            }}
          />
        </InfoBox>
        <ListTable
          data={projectStore?.aiFunctions ?? []}
          columns={functionColumns}
        />
      </div>
    );
  };

  const renderCrawlerTasks = () => {
    return (
      <div className="table-view-card">
        <div className="table-view-card-header">
          <div className="table-view-card-header-title">🌎 Websites</div>
        </div>
        <InfoBox className="mb-30">
          Kai <b>browses the specified URLs</b> below to find answers for
          customer support queries. Kai will crawl the URLs once a week. Please
          note that the website must be publicly accessible and respond within
          10 seconds. JavaScript loaded content is supported if loaded fast
          enough. By default we support 5 websites with 250 pages each. Contact
          our support team for custom limits.
          <br />
          <a
            href="#"
            onClick={() => {
              Gleap.openHelpCenterArticle('96', false);
            }}
          >
            Learn more
          </a>
        </InfoBox>
        <ListTable data={crawlerTasks} columns={renderTaskColumns} />
      </div>
    );
  };

  const renderCollection = (collectionItem) => {
    const previewLanguageCode = 'en';
    return (
      <div className="collection-list-item pl-0">
        <div className="collection-list-item-details">
          <CellGestureDetector
            onClick={() => {
              navigate(
                `/projects/${projectId}/helpcenter/collections/${collectionItem.id}`,
              );
            }}
            className="r-66-first"
            label={
              (previewLanguageCode &&
                collectionItem.title &&
                Object.keys(collectionItem.title).length > 0 &&
                collectionItem.title[previewLanguageCode] !== '' &&
                collectionItem.title[previewLanguageCode]) ??
              'No title set for default language'
            }
          />
          <CellTag className="cell-tag--green" text="Active" />
          <div className="text ml-20 r-33">
            {collectionItem.articlesCount} articles
          </div>
          <div className="r-33 collection-list-item-details-date">
            {moment(collectionItem.createdAt).format('MMM Do, h:mm:ss a')}
          </div>
        </div>
      </div>
    );
  };

  const renderArticles = () => {
    return (
      <div className="table-view-card">
        <div className="table-view-card-header">
          <div className="table-view-card-header-title">📕 Help articles</div>
        </div>
        <InfoBox className="mb-30">
          Kai <b>uses all your help articles</b> to find answers for customer
          support queries.
        </InfoBox>
        {helpcenterStore?.collections?.map((collection) => {
          return renderCollection(collection);
        })}
      </div>
    );
  };

  const renderSwitch = () => {
    return (
      <>
        <div className="kai-sources-header mb-30">
          <div className="kai-sources-header-title">
            To answer customer questions, Kai is using:
          </div>
          <div className="kai-sources-header-sources">
            <div className="kai-sources-header-source">
              <div className="kai-sources-header-source-title">
                {answers.length >= 500 && '>'}
                {answers.length}
              </div>
              <div className="kai-sources-header-source-sub">Snippets</div>
            </div>
            <div className="kai-sources-header-source">
              <div className="kai-sources-header-source-title">
                {crawlerTasks.length}
              </div>
              <div className="kai-sources-header-source-sub">Websites</div>
            </div>
            <div className="kai-sources-header-source">
              <div className="kai-sources-header-source-title">
                {helpcenterStore?.collections?.length ?? 0}
              </div>
              <div className="kai-sources-header-source-sub">
                Help collections
              </div>
            </div>
            <div className="kai-sources-header-source">
              <div className="kai-sources-header-source-title">
                {files.length}
              </div>
              <div className="kai-sources-header-source-sub">Files</div>
            </div>
            <div className="kai-sources-header-source">
              <div className="kai-sources-header-source-title">
                {(projectStore?.aiFunctions ?? []).length}
              </div>
              <div className="kai-sources-header-source-sub">API sources</div>
            </div>
          </div>
        </div>
        <div className="tab-buttons mb-30">
          <div className="tab-buttons--inner">
            <TabButton
              active={currentSource === 'snippets'}
              label="💬 Snippets"
              onClick={() => {
                setCurrentSource('snippets');
              }}
            />
            <TabButton
              active={currentSource === 'urls'}
              label="🌎 Websites"
              onClick={() => {
                setCurrentSource('urls');
              }}
            />
            <TabButton
              active={currentSource === 'articles'}
              label="📕 Help articles"
              onClick={() => {
                setCurrentSource('articles');
              }}
            />
            <TabButton
              active={currentSource === 'files'}
              label="🗃️ Files"
              onClick={() => {
                setCurrentSource('files');
              }}
            />
            <TabButton
              active={currentSource === 'api'}
              label="☁️ API sources"
              onClick={() => {
                setCurrentSource('api');
              }}
            />
          </div>
        </div>
        {currentSource === 'snippets' && renderAnswers()}
        {currentSource === 'files' && renderFiles()}
        {currentSource === 'urls' && renderCrawlerTasks()}
        {currentSource === 'articles' && renderArticles()}
        {currentSource === 'api' && renderAPIsources()}
      </>
    );
  };

  return renderSwitch();
};

export default inject(
  'projectStore',
  'modalStore',
  'organisationStore',
  'helpcenterStore',
)(observer(DataSources));
