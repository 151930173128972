import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { BugStore } from 'stores/private/BugStore';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { BugPriorities } from '../../models/Bug';
import './BugDataSelection.scss';

interface BugAssignUserProps {
  bugStore?: BugStore;
  organisationStore?: OrganisationStore;
}

const BugDataSelection = ({
  bugStore,
  organisationStore,
}: BugAssignUserProps) => {
  const bug = bugStore?.currentBug;
  const [data, setData] = useState(BugPriorities.LOW);

  useEffect(() => {
    if (bug?.priority) {
      setData(BugPriorities[bug?.priority]);
    } else {
      setData(BugPriorities.LOW);
    }
  }, [bug?.priority]);

  if (!bug || !organisationStore?.currentOrganisationUsers) {
    return <></>;
  }

  return (
    <Select
      className="dropdown-selection"
      classNamePrefix="dropdown-selection"
      defaultValue={data}
      value={data}
      placeholder="Priority"
      isClearable={false}
      isSearchable={false}
      components={{
        SingleValue: (option: any) => {
          return (
            <div className="bug-prio-item">
              <div
                className="bug-prio-item-dot"
                style={{
                  backgroundColor: option.data.color,
                }}
              />
              <span>{option.data.title}</span>
            </div>
          );
        },
        Option: (option: any) => {
          return (
            <div
              className="option option-list-item bug-prio-item"
              onClick={() => {
                if (option.selectOption) {
                  option.selectOption(option.data);
                }
              }}
            >
              <div
                className="bug-prio-item-dot"
                style={{
                  backgroundColor: option.data.color,
                }}
              />
              <span>{option.data.title}</span>
            </div>
          );
        },
      }}
      onChange={(selected: any) => {
        if (selected) {
          bugStore!.updateBug(bug.id, {
            priority: selected.value,
          });
          setData(selected);
        }
      }}
      options={Object.values(BugPriorities)}
    />
  );
};

export default inject(
  'bugStore',
  'organisationStore',
)(observer(BugDataSelection));
