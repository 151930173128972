/* eslint-disable @typescript-eslint/no-unused-expressions */
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { TextHref } from 'components/TextHref/TextHref';
import TextInput from 'components/TextInput/TextInput';
import { EMAIL_REGEX } from 'constants/Regexpatterns';
import { inject } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { validateRecaptchaAction } from 'services/Recaptcha';
import { UsersStore } from 'stores/private/UsersStore';
import './RegisterForm.scss';
import SocialLoginButton from 'components/SocialLoginButton/SocialLoginButton';
import { SocialButtonType } from 'models/Enums';

interface RegisterFormProps {
  usersStore?: UsersStore;
}

const RegisterForm = ({ usersStore }: RegisterFormProps) => {
  const [tos, setTos] = useState(false);
  const [showEmailSignup, setShowEmailSignup] = useState(false);
  const [presetEmail, setPresetEmail] = useState('');
  const { register, handleSubmit, formState, setValue } = useForm();
  const navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const invitee = query.get('invitee');
    if (invitee) {
      setPresetEmail(invitee);
      setValue('email', invitee);
      setShowEmailSignup(true);
    }
  }, []);

  const onSubmit = async (data: any) => {
    // Show the full form if the user has entered an email address and it's not shown yet.
    if (!tos) {
      toast.error('Please accept our privacy policy and terms of use.');
      return;
    }

    let token = '';
    try {
      token = (await validateRecaptchaAction('register')) as any;
    } catch (exp) {
      toast.error('Are you human?');
      return;
    }

    if (data.email && data.password && data.name) {
      usersStore?.register(token, data.email, data.password, data.name);
    } else {
      toast.error('Please enter your name, email, and a password.');
    }
  };

  const generateErrorText = (type: string) => {
    if (type === 'minLength') {
      return 'The password must contain at least 6 characters.';
    }
    if (type === 'required') {
      return 'Input is required';
    }
    if (type === 'pattern') {
      return 'Wrong input';
    }
    return '';
  };

  const getEmailFieldErrors = () => {
    if (formState.errors.email) {
      return 'Please enter a valid work email address.';
    }
    return '';
  };

  const renderEmailSignup = (smallForm) => {
    return (<form
      onSubmit={handleSubmit(onSubmit)}
      className="register-form full-width"
      autoComplete="off"
    >
      <TextInput
        readOnly={presetEmail.length > 0}
        placeholder=""
        type="text"
        label="Work email"
        name="email"
        className={'mb-20'}
        autoComplete="email"
        initalValue={presetEmail}
        onEnter={() => {
          setShowEmailSignup(true);
        }}
        inputRef={register('email', {
          required: true,
          pattern: EMAIL_REGEX,
        })}
        error={getEmailFieldErrors()}
        required
      />
      {smallForm ? <>
        <div className="btn-container">
          <PrimaryButton
            fullWidth
            className="mt-0"
            icon="arrow-right"
            iconSideRight
            onClick={() => {
              setShowEmailSignup(true);
            }}
            label="Continue"
          />
        </div>
      </> : <>
        <TextInput
          placeholder=""
          type="text"
          name="name"
          label="Name"
          autoComplete="name"
          className="mb-20"
          initalValue=""
          inputRef={register('name', {
            required: true,
            minLength: 2,
          })}
          error={formState.errors.name ? 'Please enter your name' : ''}
          required
        />
        <TextInput
          label="Password"
          placeholder=""
          type="password"
          name="password"
          autoComplete="new-password"
          initalValue=""
          inputRef={register('password', {
            required: true,
            minLength: 8,
          })}
          error={
            formState.errors.password
              ? generateErrorText(formState.errors.password.type) || ''
              : ''
          }
          required
          className="mb-30"
        />
        <div
          className="checkbox-container mb-20"
          onClick={() => {
            setTos(!tos);
          }}
        >
          <input
            type="checkbox"
            className="checkbox"
            checked={tos}
            onChange={() => { }}
          />
          <span>
            I agree to the{' '}
            <a
              target="_blank"
              href="https://www.gleap.io/terms-of-service"
              rel="noreferrer"
            >
              terms of service
            </a>
            ,{' '}
            <a
              target="_blank"
              href="https://gleap.io/privacy-policy"
              rel="noreferrer"
            >
              privacy policy
            </a>{' '}
            and{' '}
            <a
              target="_blank"
              href="https://www.gleap.io/data-processing-addendum"
              rel="noreferrer"
            >
              data processing addendum
            </a>
            .
          </span>
        </div>
        <div className="btn-container">
          <PrimaryButton
            fullWidth
            className="mt-10"
            submit
            icon="arrow-right"
            iconSideRight
            onClick={() => { }}
            label="Sign up with email"
          />
        </div>
      </>}
    </form>);
  }

  return (
    <div className="register-form-container" onClick={() => { }}>
      {showEmailSignup && <i className={`icon fa-solid fa-chevron-left back-button`} onClick={() => setShowEmailSignup(false)} />}
      {!showEmailSignup && (
        <>
          <div className="social-container-button">
            {renderEmailSignup(true)}
            <div className='signup-or mt-20 mb-20'>
              <span>OR</span>
            </div>
            <SocialLoginButton
              title="Sign up with Google"
              type={SocialButtonType.GOOGLE}
            />
          </div>
        </>
      )}
      {showEmailSignup && renderEmailSignup(false)}
      {!showEmailSignup && <TextHref
        className="href text"
        label="Already have an account? Sign in"
        onClick={() => {
          navigate('/login');
        }}
      />}
    </div>
  );
};

export default inject('usersStore')(RegisterForm);
