import LinkButton from 'components/LinkButton/LinkButton';
import { inject, observer } from 'mobx-react';
import ReactTooltip from 'react-tooltip';
import { isMacintosh, useHotkey } from 'services/Helper';
import { BugStore } from 'stores/private/BugStore';
import './UnreadItemButton.scss';

interface UnreadItemButtonProps {
  bugStore?: BugStore;
  isInbox?: boolean;
}

const UnreadItemButton = ({
  bugStore,
  isInbox = false,
}: UnreadItemButtonProps) => {
  const bug = bugStore?.currentBug;

  const markConversationAsUnread = () => {
    if (!bug) {
      return;
    }

    bugStore!.updateBug(bug.id, {
      notificationsUnread: true,
    });
    bugStore!.clearCurrentBug();
  };

  useHotkey(76, markConversationAsUnread);

  if (!bug) {
    return null;
  }

  return (
    <>
      <ReactTooltip
        id="unreadButtonTooltip"
        className="infoTooltip infoTooltipButton"
        delayHide={0}
        type="light"
        effect="solid"
        getContent={(content) => {
          return (
            <div className="send-key-tooltip">
              <span>{content}</span>
              <div className="hotkey-help">
                {isMacintosh() ? <div>⌘</div> : <div>Ctrl</div>}
                <div>Shift</div>
                <div>L</div>
              </div>
            </div>
          );
        }}
      />
      <div className='unreadbutton-container' data-for="unreadButtonTooltip" data-tip={`Mark ${isInbox ? 'conversation' : 'ticket'} as unread`}>
        <LinkButton
          className="bfw"
          iconSideRight={false}
          icon="eye-slash"
          onClick={() => {
            markConversationAsUnread();
          }}
        />
      </div>
    </>
  );
};

export default inject('bugStore')(observer(UnreadItemButton));
