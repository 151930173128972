import { useCallback, useState } from 'react';
import './PDFFileUpload.scss';
import { useDropzone } from 'react-dropzone';
import { uploadFileToServer } from 'services/FileUpload';
import ReactLoading from 'react-loading';
import classNames from 'classnames';

interface PDFFileUploadProps {
  afterPDFFileUpload?: any;
  className?: string,
}

const PDFFileUpload = ({
  afterPDFFileUpload,
  className,
}: PDFFileUploadProps) => {
  const accept = '.pdf';
  const [loading, setLoading] = useState(false);
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (afterPDFFileUpload && acceptedFiles.length > 0 && !loading) {
        setLoading(true);
        try {
          afterPDFFileUpload(acceptedFiles[0]);
          // eslint-disable-next-line no-empty
        } catch (exp) { }
      }
    },
    [afterPDFFileUpload, loading],
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: accept,
  });

  const renderFilePicker = () => {
    return (
      <div className="file-picker">
        <div className="draginfo-label">
          Drag and drop your file here
          <br />
          <span className="accepts-label">
            (.pdf)
          </span>
        </div>
        <input className="image-file-picker" {...getInputProps()} />
      </div>
    );
  };

  const imageClassName = classNames('image-upload-component', className, {
    'image-upload-component--gragging': isDragActive,
  });

  return (
    <div className="image-upload-wrapper">
      <div className={imageClassName} {...getRootProps()}>
        {loading ? (
          <div className="loading-container">
            <ReactLoading
              className="react-loading-spinner"
              type="spinningBubbles"
              height={50}
              width={50}
            />
          </div>
        ) : (
          renderFilePicker()
        )}
      </div>
    </div>
  );
};

export default PDFFileUpload;
