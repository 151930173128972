import LinkButton from "components/LinkButton/LinkButton";
import TextInput from "components/TextInput/TextInput";

const FooterLinks = ({ footerLinks, onFooterLinksChange }) => {
  const handleAddFooterLink = () => {
    const newLink = {
      title: 'New link',
      url: '',
      key: Math.random().toString(36).substring(7),
    };
    onFooterLinksChange([...footerLinks, newLink]);
  };

  const handleDeleteFooterLink = (index) => {
    const updatedFooterLinks = [...footerLinks];
    updatedFooterLinks.splice(index, 1);
    onFooterLinksChange(updatedFooterLinks);
  };

  const handleChange = (index, field, value) => {
    const updatedFooterLinks = [...footerLinks];
    updatedFooterLinks[index][field] = value;
    onFooterLinksChange(updatedFooterLinks);
  };

  return (
    <div className="options-group">
      <div className="options-group-header">Footer links</div>
      <div className="footer-link-container">
        {footerLinks.map((feedbackTag, index) => (
          <div className="footer-link-row mt-15" key={`tag-${feedbackTag.key}`}>
            <TextInput
              className="mr-15"
              value={feedbackTag.title}
              placeholder="Title"
              error=""
              onChange={(val) => handleChange(index, 'title', val)}
              required
            />
            <TextInput
              className="mr-15"
              value={feedbackTag.url}
              placeholder="https://"
              error=""
              onChange={(val) => handleChange(index, 'url', val)}
              required
            />
            <div className="delete-item" onClick={() => handleDeleteFooterLink(index)}>
              <i className="fa-solid fa-trash" />
            </div>
          </div>
        ))}
        {footerLinks.length === 0 && (
          <div className="footer-link-row mb-10 mt-10">No footer links added yet</div>
        )}
        <LinkButton
          label="Add footer link"
          className="mt-15"
          icon="plus"
          onClick={handleAddFooterLink}
        />
      </div>
    </div>
  );
};

export default FooterLinks;