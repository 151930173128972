import { HeadLine } from 'components/HeadLine/HeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { inject, observer } from 'mobx-react';
import { BugStore } from 'stores/private/BugStore';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { PropertyStore } from 'stores/private/PropertyStore';
import TextInput from 'components/TextInput/TextInput';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import { PropertyType } from '../PropertyConfigurationModal/PropertyConfigurationModal';
import { Property } from 'models/Property';
import TextArea from 'components/TextArea/TextArea';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import {
  getLanguageProperty,
  setSchemaProperty,
} from 'helper/AssignObjectKeysHelper';
import { SessionStore } from 'stores/private/SessionStore';
import { groupExtensionsByMimeType } from 'helper/MimeTypeHelper';
import BugTypeSelection from 'components/BugTypeSelection/BugTypeSelection';
import RecipientPicker from 'components/RecipientPicker/RecipientPicker';
import { Session } from 'models/Session';
import './CreateTicketModal.scss';
import AssignedFilter from 'components/MagicFilter/AssignedFilter/AssignedFilter';

interface ManuallyAddBugModalProps {
  bugStore?: BugStore;
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
  propertyStore?: PropertyStore;
  sessionStore?: SessionStore;
  isSession?: boolean;
}

const CreateTicketModal = ({
  bugStore,
  modalStore,
  projectStore,
  propertyStore,
  sessionStore,
  isSession = false,
}: ManuallyAddBugModalProps) => {
  const { register, handleSubmit, formState, setValue, getValues, watch } =
    useForm({
      mode: 'onChange',
    });

  const [isLoading, setIsLoading] = useState(false);
  const [assignee, setAssignee] = useState<string | undefined>();
  const [recipients, setRecipients] = useState<Session[] | null>([]);
  const modalData = modalStore?.getCustomData(MODALTYPE.CREATE_TICKET);
  const sessionModalData = modalStore?.getCustomData(MODALTYPE.CREATE_SESSION);
  const currentProjectType = projectStore?.currentFeedbackType;
  const initialSessions: string[] = [bugStore?.currentBug?.session?.id || '']
    .concat(bugStore?.currentBug?.sessions?.map((s) => s.id) ?? [])
    .filter((o) => o && o.length > 0);

  const [currentTicketType, setCurrentTicketType] = useState(
    isSession ? 'SESSION' : currentProjectType?.type ?? 'BUG',
  );

  const properties = propertyStore?.getVisiblePropertiesForType({
    feedbackType: currentTicketType,
    visabilityType: 'create',
  });

  useEffect(() => {
    if (isSession) {
      if (sessionModalData && sessionModalData.form) {
        let keys = Object.keys(sessionModalData.form);
        keys.forEach((key) => {
          setValue(key, sessionModalData.form[key]);
        });
      }
    } else {
      if (modalData && modalData.form) {
        let keys = Object.keys(modalData.form);
        keys.forEach((key) => {
          setValue(key, modalData.form[key]);
        });
      }
    }
  }, []);

  useEffect(() => {
    if (bugStore?.currentBug?.processingUser && !assignee) {
      setAssignee(bugStore?.currentBug?.processingUser?.id);
    }
  }, [bugStore?.currentBug?.processingUser]);

  const onSubmit = async (data: any) => {
    setIsLoading(true);

    let preparedData = {};

    const propertyFields = Object.keys(data);

    for (let i = 0; i < propertyFields.length; i++) {
      const field = propertyFields[i];
      const property: any = properties?.find((item) => item.fieldId === field);

      setSchemaProperty(preparedData, property, data[field]);
    }

    if (assignee) {
      preparedData['processingUser'] = assignee;
    }

    if (recipients && recipients.length > 0) {
      preparedData['session'] = recipients[0];

      if (recipients.length > 1) {
        recipients.shift();
        preparedData['sessions'] = recipients;
      }
    }

    if (modalData && modalData.sessionId) {
      preparedData['sessionId'] = modalData.sessionId;
    }

    if (currentTicketType && !isSession) {
      preparedData['type'] = currentTicketType;
    }

    if (modalData?.parentTicket) {
      preparedData['parentTicket'] = modalData.parentTicket;
    }

    if (isSession) {
      await sessionStore?.createSession(preparedData);
    } else {
      await bugStore?.createBug(preparedData);
    }
    modalStore!.closeModal();
    setIsLoading(false);
  };

  let typeName = (
    projectStore?.currentProject?.projectTypes.find(
      (type) => type.type === currentTicketType,
    )?.name ?? 'bug'
  ).toLowerCase();
  if (modalData?.type === 'FEATURE_REQUEST') {
    typeName = 'feature request';
  }

  const getWidgetForType = (property: Property) => {
    switch (property.type) {
      case PropertyType.TEXT:
        return (
          <TextInput
            inputRef={register(property.fieldId, {
              required: property.required ?? false,
            })}
            name={property.fieldId}
            placeholder={getLanguageProperty(property, 'placeholder', 'en')}
            error={formState.errors[property.fieldId]?.message}
            label={property.label}
          />
        );
      case PropertyType.NUMBER:
        return (
          <TextInput
            inputRef={register(property.fieldId, {
              required: property.required ?? false,
            })}
            type="number"
            name={property.fieldId}
            placeholder={getLanguageProperty(property, 'placeholder', 'en')}
            error={formState.errors[property.fieldId]?.message}
            label={property.label}
          />
        );
      case PropertyType.EMAIL:
        return (
          <TextInput
            inputRef={register(property.fieldId, {
              required: property.required ? 'Email is required' : false,
              pattern: {
                value: /^[A-Z0-1._%+-]+@[A-Z0-2.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address',
              },
            })}
            type="email"
            name={property.fieldId}
            placeholder={getLanguageProperty(property, 'placeholder', 'en')}
            error={formState.errors[property.fieldId]?.message}
            label={property.label}
          />
        );
      case PropertyType.DATE:
        return (
          <TextInput
            inputRef={register(property.fieldId, {
              required: property.required ?? false,
            })}
            type="date"
            name={property.fieldId}
            placeholder={getLanguageProperty(property, 'placeholder', 'en')}
            error={formState.errors[property.fieldId]?.message}
            label={property.label}
          />
        );
      case PropertyType.TIME:
        return (
          <TextInput
            inputRef={register(property.fieldId, {
              required: property.required ?? false,
            })}
            type="time"
            name={property.fieldId}
            placeholder={getLanguageProperty(property, 'placeholder', 'en')}
            error={formState.errors[property.fieldId]?.message}
            label={property.label}
          />
        );
      case PropertyType.TEXTAREA:
      case PropertyType.RICHTEXT:
        return (
          <TextArea
            inputRef={register(property.fieldId, {
              required: property.required ?? false,
            })}
            name={property.fieldId}
            placeholder={getLanguageProperty(property, 'placeholder', 'en')}
            error={formState.errors[property.fieldId]?.message}
            label={property.label}
          />
        );
      case PropertyType.UPLOAD:
        watch(property.fieldId);
        return (
          <ImageUpload
            className="manually-add-screenshot"
            label={property.label}
            image={getValues(property.fieldId)}
            accept={groupExtensionsByMimeType(property.restrictions)}
            formatInfo={property.restrictions}
            editable
            uploadPath="manuallyuploaded"
            afterImageUpload={(imageURL) => {
              setValue(property.fieldId, imageURL);
            }}
          />
        );
      case PropertyType.SELECT:
      case PropertyType.MULTISELECT:
        return (
          <SelectDropDown
            label={property.label}
            labelPropertyName="label.localized.en"
            valuePropertyName="value"
            items={property.options ?? []}
            isMulti={property.type === PropertyType.MULTISELECT}
            onChange={(item) => {
              setValue(property.fieldId, item.key);
            }}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="add-ticket-modal">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="manually-create-bug centered"
      >
        <Column>
          <HeadLine
            className="mb-15"
            title={isSession ? 'Add new contact' : `Create new ${typeName}`}
          />
          {!isSession && (
            <BugTypeSelection
              value={currentTicketType}
              onValueChanged={(selected) => {
                setCurrentTicketType(selected.value);
              }}
            />
          )}
          <div className="mb-15" />
          {properties?.map((property) => {
            return <div className="mb-15">{getWidgetForType(property)}</div>;
          })}
          {!isSession && (
            <div>
              <div className="feedback-selection-row feedback-selection-row">
                <div className="feedback-selection-row-label">Assignee:</div>
                <div className="feedback-selection-row-checkbox">
                  <AssignedFilter
                    value={assignee}
                    onValueChanged={(val) => setAssignee(val)}
                    truncate={50}
                    biggerIcon
                    truncatePreview={50}
                    placeholder="Select assignee (optional)"
                  />
                </div>
              </div>
              {/* <RecipientPicker
                label='Recipients:'
                initialSessionId={initialSessions}
                placeholder='Select recipients (optional)'
                sessions={recipients}
                multipleSessions
                smallText
                placeholderClassName='recipient-picker-placeholder'
                onChange={(selectedSession) => {
                  setRecipients(selectedSession);
                }}
              /> */}
            </div>
          )}
          <PrimaryButton
            submit
            className="mt-20"
            disabled={!formState.isValid}
            label={isSession ? 'Add' : 'Create'}
            isLoading={isLoading}
            onClick={() => {}}
          />
        </Column>
      </form>
    </div>
  );
};

export default inject(
  'bugStore',
  'modalStore',
  'projectStore',
  'propertyStore',
  'sessionStore',
)(observer(CreateTicketModal));
