import LoadingAnimationSmall from 'components/LoadingAnimationSmall/LoadingAnimationSmall';
import { inject, observer } from 'mobx-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { suggestFeatureRequest } from 'services/BugHttpService';
import { useClickedOutside } from 'services/Helper';
import { BugStore } from 'stores/private/BugStore';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import './CreateTicketAction.scss';
import { toast } from 'react-toastify';

const CreateTicketAction = ({
  modalStore,
  bugStore,
  ticketId,
  sessionId,
}: {
  modalStore?: ModalStore;
  bugStore?: BugStore;
  ticketId?: string;
  sessionId?: string;
}) => {
  const scrollContainerRef = useRef(null as any);
  const [showfeatureRequestsList, setShowFeatureRequestsList] = useState(false);
  const showfeatureRequestsListRef = useRef(showfeatureRequestsList);
  showfeatureRequestsListRef.current = showfeatureRequestsList;
  const [featureRequest, setFeatureRequest] = useState({} as any);
  const [duplicatesList, setDuplicatesList] = useState([] as any[]);
  const wrapperRef = useRef(null);
  useClickedOutside(wrapperRef, () => {
    setShowFeatureRequestsList(false);
  });
  const [loading, setLoading] = useState(false);

  const loadFeatureRequestSuggestion = async () => {
    setLoading(true);

    try {
      if (ticketId && sessionId) {
        const data = await suggestFeatureRequest(ticketId);

        if (!showfeatureRequestsListRef.current) {
          setLoading(false);
          return;
        }

        if (data?.data) {
          const dups = (data?.data?.matchingFeatureRequests ?? []);
          setDuplicatesList(dups);

          setFeatureRequest({
            title: data?.data?.title,
            description: data?.data?.description,
          });

          if (dups.length === 0) {
            setTimeout(() => {
              performActionForIndex(0);
            }, 0);
          }
        }
      }
    } catch (exp) {
      console.log(exp);
    }

    setLoading(false);
  }

  useEffect(() => {
    if (showfeatureRequestsList && ticketId) {
      loadFeatureRequestSuggestion();
    }
  }, [showfeatureRequestsList, ticketId]);

  const performCreate = () => {
    setShowFeatureRequestsList(false);

    modalStore!.openModalSmart(MODALTYPE.CREATE_TICKET, {
      form: {
        title: featureRequest?.title ?? "",
        description: featureRequest?.description ?? "",
      },
      type: 'FEATURE_REQUEST',
      sessionId: sessionId,
    });
  };

  const performActionForIndex = (index) => {
    if (!duplicatesList) {
      return;
    }

    const featureRequest = duplicatesList[index];
    if (!featureRequest) {
      return;
    }

    try {
      const email = bugStore?.currentBug?.session?.email;
      if (email && email.length > 0) {
        bugStore?.subscribeUser(featureRequest, email);
      }
    } catch (exp) { }

    setShowFeatureRequestsList(false);

    toast.success('User subscribed to feature request');
  };

  const handleUserKeyPress = useCallback((event: any) => {
    if (!showfeatureRequestsListRef.current) {
      return;
    }

    if (event.key === 'Escape') {
      setDuplicatesList([]);
      setShowFeatureRequestsList(false);
      event.preventDefault();
      return;
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  return (
    <div className="featureRequest-container" ref={wrapperRef}>
      {showfeatureRequestsList && (
        <div className="featureRequest-list">
          <div className="featureRequest-list-title">
            💡 Feature requests
          </div>
          <div className="featureRequest-list-list" ref={scrollContainerRef}>
            {loading ?
              <div className='centered p-20'>
                <div>
                  <LoadingAnimationSmall />
                </div>
                <div className='text mt-10'>Analyzing conversation...</div>
              </div> : <>
                <div
                  className={`featureRequest-list-item`}
                  key={"create"}
                  onClick={() => {
                    performCreate();
                  }}
                >
                  <div className="featureRequest-list-item-shortcut">
                    <i className="fa-regular fa-plus"></i>
                  </div>
                  <div className="featureRequest-list-item-shortcut-content">
                    Create new feature request
                  </div>
                </div>
                {duplicatesList.length > 0 && <div className='duplicate-sub-title'>
                  or subscribe user to existing feature request:
                </div>}
                {duplicatesList.map((feature, index) => {
                  if (!feature) {
                    return null;
                  }

                  return (
                    <div
                      className={`featureRequest-list-item`}
                      key={index}
                      onClick={() => {
                        performActionForIndex(index);
                      }}
                    >
                      <div className="featureRequest-list-item-shortcut">
                        <i className="fa-solid fa-chevron-up"></i>
                        <span>{feature?.upvotesCount}</span>
                      </div>
                      <div className="featureRequest-list-item-shortcut-content">
                        {feature?.title}
                      </div>
                    </div>
                  );
                })}
              </>
            }
          </div>
        </div>
      )}
      {!showfeatureRequestsList && (
        <ReactTooltip
          id="startfeatureRequestButtonTooltip"
          className="infoTooltip infoTooltipButton"
          delayHide={0}
          type="light"
          effect="solid"
          getContent={(content) => {
            return (
              <div className="send-key-tooltip">
                <span>{content}</span>
              </div>
            );
          }}
        />
      )}
      <div
        data-for="startfeatureRequestButtonTooltip"
        data-tip="Create feature request"
        className={`featureRequest-container-item ${showfeatureRequestsList && 'featureRequest-container-item--active'
          }`}
        onClick={() => {
          setShowFeatureRequestsList(!showfeatureRequestsList);
        }}
      >
        <i className="fa-solid fa-lightbulb" />
      </div>
    </div>
  );
};

export default inject(
  'modalStore',
  'bugStore'
)(observer(CreateTicketAction));