import ApiKey from 'components/ApiKey/ApiKey';
import InfoBox from 'components/InfoBox/InfoBox';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import { inject, observer } from 'mobx-react';
import { ProjectTeam } from 'models/ProjectTeam';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ProjectStore } from 'stores/private/ProjectStore';
import './ProjectEmailForwarding.scss';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import { languages } from 'constants/Languages';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';

interface ProjectEmailForwardingProps {
  projectStore?: ProjectStore;
}

const ProjectEmailForwarding = ({
  projectStore,
}: ProjectEmailForwardingProps) => {
  const project = projectStore!.currentProject;
  const teams = projectStore?.currentProjectTeams ?? [];
  const [loading, setLoading] = useState(false);
  const [
    defaultInboundLanguage,
    setDefaultInboundLanguage,
  ] = useState("en");

  useEffect(() => {
    projectStore!.getProjectTeams();
  }, []);

  useEffect(() => {
    if (project) {
      setDefaultInboundLanguage(project.defaultInboundLanguage);
    }
  }, [project]);

  if (!project) {
    return null;
  }

  const emailDomain = 'gleapmail.com'; // project?.postmarkServer?.InboundDomain ?? 

  return (
    <PageContainer>
      <PageHeadLine title="Email forwarding" />
      <PageContent hasTitle isMediumBoxed className="email-settings">
        <div className="options-group">
          <div className="options-group-header">Inbound settings</div>
          <div className="text mb-10">
            Gleap automatically attempts to determine the language of incoming emails. Should the language be unidentifiable, Gleap will default to using the pre-set language.
          </div>
          <SelectDropDown
            isSearchable={true}
            labelPropertyName="name"
            valuePropertyName="code"
            selectedItem={languages.find((option) => option.code === defaultInboundLanguage)}
            items={languages}
            onChange={(option) => {
              if (option?.code) {
                setDefaultInboundLanguage(option.code);
              }
            }}
          />
          <PrimaryButton
            isLoading={loading}
            onClick={async () => {
              setLoading(true);

              try {
                await projectStore!.updateProject(project!.id, {
                  defaultInboundLanguage,
                });
                // eslint-disable-next-line no-empty
              } catch (exp) { }

              setLoading(false);
            }}
            className="mt-20"
            label="Save"
          />
        </div>
        <div className="options-group">
          <div className="options-group-header">General email inbox</div>
          <div className="text mt-10">
            Gleap allows the creation of inquiries by email. As soon as a new
            email arrives in your inbox, a new inquiry will be
            created.
            <br />
            <br />
            <b>The email address of your general email inbox is:</b>
          </div>
          <div className="mt-10 mb-10">
            <ApiKey
              icon="mailbox"
              apiKey={`project+id${project.apiKey}@${emailDomain}`}
            />
          </div>
          <InfoBox>
            <b>Pro tip:</b> Forward your support emails directly to your Gleap inbound email address for centralized and efficient handling of all inquiries in one place.
          </InfoBox>
        </div>
        <div className="options-group">
          <div className="options-group-header">Team inbox</div>
          <div className="text mt-10 mb-30">
            Gleap team inboxes allow you to forward emails directly to a specific team within Gleap. Simply forward an email to the email address of the team and a new inquiry will be created in the team's inbox.
          </div>
          {teams.map((team: ProjectTeam, index: number) => {
            return (<div>
              <b className='title-text'>{team.name}</b>
              <div className="mt-10 mb-10">
                <ApiKey
                  icon="mailbox"
                  apiKey={`team+id${team._id}@${emailDomain}`}
                />
              </div>
            </div>);
          })}
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'projectStore',
)(observer(ProjectEmailForwarding));
