import { Editor } from '@tiptap/react';
import classNames from 'classnames';
import EmailEditor from 'components/Editors/EmailEditor/EmailEditor';
import Toolbar, { ToolbarItems } from 'components/Editors/ToolBar/Toolbar';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { ModalStore } from 'stores/private/ModalStore';
import './NoteActionEditor.scss';
import { ProjectStore } from 'stores/private/ProjectStore';

interface NoteActionEditorProps {
  action: any;
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
}

const NoteActionEditor = ({
  action,
  modalStore,
  projectStore,
}: NoteActionEditorProps) => {
  const currentLang = projectStore?.currentLanguage ?? 'en';
  const editorRef: MutableRefObject<Editor | null> = useRef(null);
  const [toggleState, setToggleState] = useState(false);

  useEffect(() => {
    const message = action?.data?.content;
    if (message) {
      editorRef?.current?.commands.setContent(message !== '' ? message : {});
    }
  }, [editorRef]);

  const messageActionContainerClassName = classNames({
    'edit-feature-request-modal': true,
    'message-action-container': true
  });

  return (
    <div className={messageActionContainerClassName}>
      <PageContainer>
        <PageHeadLine title="Edit note">
          <div className="header-content-right">
            <PrimaryButton
              label="Close"
              iconSideRight={false}
              onClick={() => {
                modalStore!.closeModal();
              }}
            />
          </div>
        </PageHeadLine>
        <PageContent hasTitle hasPadding={false}>
          <div className="bot-message-editor">
            <div className="toolbar-wrapper">
              <Toolbar
                editor={editorRef?.current}
                aiStyle="agent"
                items={[
                  ToolbarItems.Basic,
                  ToolbarItems.Advanced,
                  ToolbarItems.Image,
                  ToolbarItems.AI,
                ]}
                language={currentLang}
              />
            </div>
            <div className="editor-wrapper">
              <EmailEditor
                editorRef={editorRef}
                onEditorReady={() => {
                  setToggleState(!toggleState);
                }}
                placeholder="Write your note..."
                content={action?.data?.content}
                inputContentChanged={(content) => {
                  runInAction(() => {
                    if (!action.data) {
                      action.data = {};
                    }
                    action.data.content = content;
                  });
                }}
              />
            </div>
          </div>
        </PageContent>
      </PageContainer>
    </div>
  );
};

export default inject(
  'modalStore',
  'projectStore',
)(observer(NoteActionEditor));
