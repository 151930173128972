import './SharedFeatureRequestsRoadmap.scss';
import { inject, observer } from 'mobx-react';
import SharedBoard from 'components/SharedBoard/SharedBoard';
import { SharedBoardStorePublic } from 'stores/public/SharedBoardStorePublic';
import MobileSharedBoard from 'components/SharedBoard/MobileSharedBoard';

interface SharedFeatureRequestsRoadmapProps {
  embedMode: boolean;
  sharedBoardStorePublic?: SharedBoardStorePublic;
}

const SharedFeatureRequestsRoadmap = ({
  sharedBoardStorePublic,
  embedMode,
}: SharedFeatureRequestsRoadmapProps) => {
  const isMobile = window.innerWidth < 768;
  if (!sharedBoardStorePublic?.currentProject) {
    return null;
  }

  return (
    <div className="shared-feature-requests-roadmap">
      {isMobile ? <MobileSharedBoard /> : <SharedBoard embedMode={embedMode}/>}
    </div>
  );
};
export default inject('sharedBoardStorePublic')(
  observer(SharedFeatureRequestsRoadmap),
);
