import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper';
import ListDataTable, {
  DataRow,
  DataTableHeader,
} from 'components/ListDataTable/ListDataTable';
import React, { useEffect, useState } from 'react';
import './FutureStatisticDataTable.scss';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import { getEmojiForVal } from 'helper/ScoreFormater';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import { formatNumber } from '../FactChart/FactChart';
import { CellGestureDetector } from 'components/ListTable/ListTable';
import { ProjectStore } from 'stores/private/ProjectStore';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import LinkButton from 'components/LinkButton/LinkButton';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';

export const StatisticListTypes = {
  ARTICLE_SEARCH_RESULT_LIST: 'ARTICLE_SEARCH_RESULT_LIST',
  ARTICLE_SEARCH_WITH_NO_RESULT_LIST: 'ARTICLE_SEARCH_WITH_NO_RESULT_LIST',
  AVERAGE_RATING_PER_USER_RESULT_LIST: 'AVERAGE_RATING_PER_USER_RESULT_LIST',
  TEAM_PERFORMANCE_LIST: 'TEAM_PERFORMANCE_LIST',
  ARTICLE_VIEW_STATISTICS_LIST: 'ARTICLE_VIEW_STATISTICS_LIST',
  TICKETS_WITH_RATING_LIST: 'TICKETS_WITH_RATING_LIST',
  LIVE_AGENTS_TEAM: 'LIVE_AGENTS_TEAM',
  SNAPSHOT_TICKETS: 'SNAPSHOT_TICKETS',
};

interface FutureStatisticDataTableProps {
  title?: string;
  future: () => Promise<any>;
  width?: number | string;
  infoText?: string;
  headerActions?: React.ReactNode;
  projectStore?: ProjectStore;
  showReloadButton?: boolean;
}

const FutureStatisticDataTable = ({
  title,
  future,
  width,
  infoText,
  headerActions,
  projectStore,
  showReloadButton = false,
}: FutureStatisticDataTableProps) => {
  const navigate = useNavigate();
  const [lastDateDataLoaded, setLastDateDataLoaded] = useState<Date | null>();
  const currentProject = projectStore?.currentProject;
  const [statisticDataTable, setStatisticDataTable] = useState<
    any | undefined | null
  >({
    title: title,
  } as any);
  const [isLoading, setIsLoading] = useState(true);

  const initialize = async () => {
    try {
      setIsLoading(true);
      setStatisticDataTable(null);

      const data = await future();

      const overrideTitle = title ?? data?.title ?? '';
      setStatisticDataTable({ ...data, title: overrideTitle } as any);

      setLastDateDataLoaded(new Date());

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initialize();
  }, [future]);

  const _buildProgress = (progressValue, isPositiveGood = true) => {
    if (progressValue == null || progressValue === 0 || progressValue === '--')
      return <></>;

    const progressDirection =
      (isPositiveGood && progressValue < 0) ||
      (!isPositiveGood && progressValue > 0)
        ? 'down'
        : 'up';

    return (
      <div
        className={`row-progress-item row-progress-item--${progressDirection}`}
      >
        {formatNumber(progressValue)}%
      </div>
    );
  };

  const prepareColumns = () => {
    if (!statisticDataTable?.columns) return [];

    const headers: DataTableHeader[] = statisticDataTable?.columns?.map(
      (column) => {
        return {
          child: <div className="bold">{column.title}</div>,
        };
      },
    );

    return headers;
  };

  const dataItemBuilder = (dataItem: any, index: number): DataRow => {
    return {
      key: index.toString(),
      children: statisticDataTable?.columns?.map((column) => {
        if (dataItem['processingUser'] && column.key === 'processingUserREF') {
          return {
            child: (
              <div
                onClick={() =>
                  navigate(
                    `/projects/${currentProject?.id}/reports/user/${dataItem['processingUser']?.id}`,
                  )
                }
                className="processing-user-row"
              >
                <UserAvatar
                  small
                  email={dataItem['processingUser']?.email}
                  imageUrl={dataItem['processingUser']?.profileImageUrl}
                />
                {dataItem['processingUser']?.firstName}{' '}
                {dataItem['processingUser']?.lastName}
              </div>
            ),
          };
        }

        if (column.hasProgress) {
          const positiveProgressIsGood =
            column.key === 'totalCountForUser' ||
            column.key === 'commentCount' ||
            column.key === 'averageRating' ||
            column.key === 'rawClosed';

          return {
            child: (
              <Row alignItems="center">
                <p>{dataItem[column.key].value}</p>
                {_buildProgress(
                  dataItem[column.key].progressValue,
                  positiveProgressIsGood,
                )}
              </Row>
            ),
          };
        }

        if (column.key === 'ticketTitle') {
          return {
            child: (
              <CellGestureDetector
                label={dataItem[column.key]}
                onClick={() => {
                  projectStore!.openFeedbackItem({
                    shareToken: dataItem.ticketShareToken,
                  });
                }}
              />
            ),
          };
        }

        if (
          !isNaN(dataItem['averageRating']) &&
          column.key === 'averageRating'
        ) {
          if (dataItem['averageRating'] || dataItem['averageRating'] === 0) {
            return {
              child: (
                <p>
                  {getEmojiForVal(dataItem['averageRating'] * 10)}{' '}
                  {Math.round(dataItem['averageRating'] * 10)}%
                </p>
              ),
            };
          } else {
            return {
              child: <p className="pl-20">No ratings yet</p>,
            };
          }
        }

        return {
          child: <p>{dataItem[column.key]}</p>,
        };
      }),
    };
  };

  return (
    <div className="mb-15" style={{ width: width ?? '100%' }}>
      <ComponentWrapper
        className="future-statistics-data-table"
        noPadding
        infoText={infoText}
      >
        <Column alignItems="flex-start">
          <Row justifyContent="space-between">
            <div className="title">{statisticDataTable?.title}</div>
            {headerActions && !isLoading && (
              <div className="header-actions mr-30">{headerActions}</div>
            )}
          </Row>
          <ListDataTable
            columns={prepareColumns()}
            data={statisticDataTable?.data ?? []}
            dataTableItemBuilder={dataItemBuilder}
            isLoading={isLoading}
            noDataMessage="No data to display"
            isScrollable
          />
        </Column>
      </ComponentWrapper>
      {showReloadButton && (
        <Column>
          <Row className="mt-10" justifyContent="flex-end" alignItems="center">
            <div className="text mr-10">
              Last update: <b>{lastDateDataLoaded?.toLocaleString()}</b>
            </div>
            <PrimaryButton small label="Refresh" onClick={() => initialize()} />
          </Row>
        </Column>
      )}
    </div>
  );
};

export default inject('projectStore')(observer(FutureStatisticDataTable));
