import React, { useEffect, useRef, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { toast } from 'react-toastify';
import { ProjectStore } from 'stores/private/ProjectStore';
import { BugStore } from 'stores/private/BugStore';
import SendInputEditor from 'components/SendInputEditor/SendInputEditor';
import { UsersStore } from 'stores/private/UsersStore';
import './EditCommentModal.scss';
import { uploadFileToServer } from 'services/FileUpload';

interface EditCommentModalProps {
  modalStore?: ModalStore;
  bugStore?: BugStore;
  projectStore?: ProjectStore;
  usersStore?: UsersStore;
}

const EditCommentModal = ({
  modalStore,
  bugStore,
  projectStore,
  usersStore,
}: EditCommentModalProps) => {
  const { comment } = modalStore?.getCustomData(MODALTYPE.EDIT_COMMENT) || {};
  const attachmentsRef = useRef();
  const [attachments, setAttachments] = useState([] as File[]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (comment.attachments) {
      setAttachments(comment.attachments);
    }
  }, [comment]);

  const updateComment = async (newContent) => {
    setLoading(true);

    var uploadedAttachments = [] as any[];
    if (attachments) {
      for (let i = 0; i < attachments.length; i++) {
        const file = attachments[i];
        if (!file) {
            continue
        }
        if (file["_id"]) {
            uploadedAttachments.push(file);
            continue;
        }
        const uploadedAttachment = await uploadFileToServer(
          file,
          `${comment.bug}/attachments`,
        );
        if (uploadedAttachment) {
          uploadedAttachments.push({
            url: uploadedAttachment,
            name: file.name,
            type: file.type,
          });
        }
      }
    }

    const data = {
        content: newContent,
        attachments: uploadedAttachments,
    };
  

    const updatedComment = await bugStore?.updateComment(
      data,
      comment.id,
      comment.bug,
    );
    modalStore?.closeModal();

    if (attachmentsRef && attachmentsRef.current) {
      (attachmentsRef.current as any).clearFiles();
    }

    if (!updatedComment) {
      toast.error("You can't edit this message anymore.");
    }

    setLoading(false);
  };

  return (
    <div className="edit-comment-modal">
      <SendInputEditor
        isSending={loading}
        inputTypes={['Reply']}
        currentInputType={'Reply'}
        disableEmailFallback={
          projectStore?.currentProject?.disableEmailFallback ?? false
        }
        onFilesSelected={(files) => {
          setAttachments([...files, ...attachments]);
        }}
        attachmentsUpdated={(files) => {
          setAttachments([...files]);
        }}
        variables={{
          project: projectStore?.currentProject ?? {},
          session: bugStore?.currentBug?.session ?? {},
          user: usersStore?.currentUser ?? {},
        }}
        customLabel="Update"
        defaultContent={comment?.data?.content}
        attachmentsRef={attachmentsRef}
        attachments={attachments}
        onClickSend={updateComment}
        showAttachments={true}
        inputContentChanged={(json) => {}}
        hideTabButtons={true}
        disableTooltips
        inputPlaceholder={'Write a message...'}
        mentions={
          projectStore?.currentProjectUsers
            ? projectStore?.currentProjectUsers.map((userItem) => {
                return {
                  label: `${userItem.firstName} ${userItem.lastName}`,
                  id: userItem.id,
                  email: userItem.email,
                  profileImageUrl: userItem.profileImageUrl,
                };
              })
            : []
        }
      />
    </div>
  );
};

export default inject(
  'modalStore',
  'bugStore',
  'projectStore',
  'usersStore',
)(observer(EditCommentModal));
