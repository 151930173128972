import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import './MobileSharedBoard.scss';
import { SharedBoardStorePublic } from 'stores/public/SharedBoardStorePublic';
import { inject, observer } from 'mobx-react';
import { PublicOrderFilters } from 'components/FeatureRequestsOrder/FeatureRequestsOrder';

interface Props {
    sharedBoardStorePublic?: SharedBoardStorePublic;
    currentLanes: any;
    selectedLane: any;
    setSelectedLane: (val) => void;
    order: any
    onOrderChange: (val) => void;
}

const MobileSharedBoardHeader = ({
    currentLanes,
    sharedBoardStorePublic,
    selectedLane,
    setSelectedLane,
    order,
    onOrderChange,
}: Props) => {
    const [orderData, setOrderData] = useState(undefined as any);
    const sharedFeatureRequestStates = [
        {
            title: sharedBoardStorePublic?.translateText('featureRequestSort'),
            icon: 'arrow-down-wide-short',
            key: 'VOTES',
            ...PublicOrderFilters.VOTES,
        },
        {
            title: sharedBoardStorePublic?.translateText('featureRequestSortDate'),
            key: 'RECENT',
            ...PublicOrderFilters.RECENT,
            icon: 'rss',
        },
        {
            title: sharedBoardStorePublic?.translateText('featureRequestSortRandom'),
            key: 'RANDOM',
            ...PublicOrderFilters.RANDOM,
            icon: 'shuffle',
        },
    ];

    useEffect(() => {
        const filterStatus = sharedFeatureRequestStates.find(
            (x) => x.key === order,
        );
        setOrderData(filterStatus);
    }, [order]);

    return (
        <div className="board-filter-select">
            <div className='filter-select'>
                <Select
                    className="dropdown-selection feature-requests-status-filter"
                    classNamePrefix="dropdown-selection"
                    value={orderData}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    isSearchable={false}
                    components={{
                        SingleValue: (option: any) => {
                            return (
                                <div className="order-prio-item">
                                    <i className={`fa-solid fa-${option.data.icon}`}></i>
                                    <span>{option.data.title}</span>
                                </div>
                            );
                        },
                        Option: (option: any) => {
                            return (
                                <div
                                    className="option option-list-item order-prio-item"
                                    onClick={() => {
                                        if (option.selectOption) {
                                            option.selectOption(option.data);
                                        }
                                    }}
                                >
                                    <i className={`fa-solid fa-${option.data.icon}`}></i>
                                    <span>{option.data.title}</span>
                                </div>
                            );
                        },
                    }}
                    onChange={(selected: any) => {
                        onOrderChange(selected.key);
                    }}
                    options={sharedFeatureRequestStates}
                />
            </div>
            <div className='filter-select'>
                <Select
                    className="dropdown-selection feature-requests-status-filter"
                    classNamePrefix="dropdown-selection"
                    value={selectedLane}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    isSearchable={false}
                    components={{
                        SingleValue: (option: any) => {
                            return (
                                <div className="bug-prio-item">
                                    <div
                                        className="bug-prio-item-dot"
                                        style={{
                                            backgroundColor: option.data.color,
                                        }}
                                    />
                                    <span>{option.data.title}</span>
                                </div>
                            );
                        },
                        Option: (option: any) => {
                            return (
                                <div
                                    className="option option-list-item bug-prio-item"
                                    onClick={() => {
                                        if (option.selectOption) {
                                            option.selectOption(option.data);
                                        }
                                    }}
                                >
                                    <div
                                        className="bug-prio-item-dot"
                                        style={{
                                            backgroundColor: option.data.color,
                                        }}
                                    />
                                    <span>{option.data.title}</span>
                                </div>
                            );
                        },
                    }}
                    onChange={(selected: any) => {
                        setSelectedLane(selected);
                    }}
                    options={currentLanes}
                />
            </div>
        </div>
    )
}

export default inject('sharedBoardStorePublic')(observer(MobileSharedBoardHeader));
