import { MDXProvider } from '@mdx-js/react';
import LinkButton from 'components/LinkButton/LinkButton';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { importMDX } from 'mdx.macro';
import { inject, observer } from 'mobx-react';
import PLATFORM from 'models/Platform';
import { lazy, Suspense, useState } from 'react';
import { useNavigate } from 'react-router';
import { ProjectStore } from 'stores/private/ProjectStore';
import MdxCodeSnippet from './MDXComponents/MdxCodeSnippet/MdxCodeSnippet';
import MdxHeadLine from './MDXComponents/MdxHeadLine/MdxHeadLine';
import MdxLineBreak from './MDXComponents/MdxLineBreak/MdxLineBreak';
import MdxParagraph from './MDXComponents/MdxParagraph/MdxParagraph';
import MdxTitle from './MDXComponents/MdxTitle/MdxTitle';
import './SDKTutorial.scss';
import VerifyInstallation from './VerifyInstallation';

const IOSSdk = lazy(() =>
  importMDX('../../../assets/platforms/ios/ios_sdk.mdx'),
);

const IOSInitialization = lazy(() =>
  importMDX('../../../assets/platforms/ios/ios_initialization.mdx'),
);

const CordovaSdk = lazy(() =>
  importMDX('../../../assets/platforms/cordova/cordova_sdk.mdx'),
);

const CordovaInitialization = lazy(() =>
  importMDX('../../../assets/platforms/cordova/cordova_initialization.mdx'),
);

const IonicSdk = lazy(() =>
  importMDX('../../../assets/platforms/ionic/ionic_sdk.mdx'),
);

const IonicInitialization = lazy(() =>
  importMDX('../../../assets/platforms/ionic/ionic_initialization.mdx'),
);

const AndroidSdk = lazy(() =>
  importMDX('../../../assets/platforms/android/android_sdk.mdx'),
);

const AndroidInitialization = lazy(() =>
  importMDX('../../../assets/platforms/android/android_initialization.mdx'),
);

const ReactNativeSdk = lazy(() =>
  importMDX('../../../assets/platforms/reactnative/reactnative_sdk.mdx'),
);

const ReactNativeInitialization = lazy(() =>
  importMDX(
    '../../../assets/platforms/reactnative/reactnative_initialization.mdx',
  ),
);

const ReactNativeExpoSdk = lazy(() =>
  importMDX(
    '../../../assets/platforms/reactnativeexpo/reactnativeexpo_sdk.mdx',
  ),
);

const ReactNativeExpoInitialization = lazy(() =>
  importMDX(
    '../../../assets/platforms/reactnativeexpo/reactnativeexpo_initialization.mdx',
  ),
);

const ReactNativeExpoBuild = lazy(() =>
  importMDX(
    '../../../assets/platforms/reactnativeexpo/reactnativeexpo_build.mdx',
  ),
);

const NextJSSdk = lazy(() =>
  importMDX('../../../assets/platforms/nextjs/nextjs_sdk.mdx'),
);

const NextJSSdkInit = lazy(() =>
  importMDX('../../../assets/platforms/nextjs/nextjs_sdk_init.mdx'),
);

const JSSdk = lazy(() => importMDX('../../../assets/platforms/js/js_sdk.mdx'));

const JSReactSdk = lazy(() =>
  importMDX('../../../assets/platforms/react/js_sdk.mdx'),
);

const JSReactSdkInit = lazy(() =>
  importMDX('../../../assets/platforms/react/js_sdk_init.mdx'),
);

const JSAngularSdk = lazy(() =>
  importMDX('../../../assets/platforms/angular/js_sdk.mdx'),
);

const JSAngularSdkInit = lazy(() =>
  importMDX('../../../assets/platforms/angular/js_sdk_init.mdx'),
);

const JSVueSdk = lazy(() =>
  importMDX('../../../assets/platforms/vue/js_sdk.mdx'),
);

const JSVueSdkInit = lazy(() =>
  importMDX('../../../assets/platforms/vue/js_sdk_init.mdx'),
);

const FlutterSdk = lazy(() =>
  importMDX('../../../assets/platforms/flutter/flutter_sdk.mdx'),
);

const FlutterNative = lazy(() =>
  importMDX('../../../assets/platforms/flutter/flutter_native.mdx'),
);

const FlutterInitialization = lazy(() =>
  importMDX('../../../assets/platforms/flutter/flutter_initialization.mdx'),
);

const WordpressInitialization = lazy(() =>
  importMDX('../../../assets/platforms/wordpress/wordpress_initialization.mdx'),
);

const WixInitialization = lazy(() =>
  importMDX('../../../assets/platforms/wix/wix_initialization.mdx'),
);

const WixAddCode = lazy(() =>
  importMDX('../../../assets/platforms/wix/wix_addcode.mdx'),
);

const SquarespaceInitialization = lazy(() =>
  importMDX(
    '../../../assets/platforms/squarespace/squarespace_initialization.mdx',
  ),
);

const WeeblyInitialization = lazy(() =>
  importMDX('../../../assets/platforms/weebly/weebly_initialization.mdx'),
);

const WebflowInitialization = lazy(() =>
  importMDX('../../../assets/platforms/webflow/webflow_initialization.mdx'),
);

const GoogleTagManagerInitialization = lazy(() =>
  importMDX('../../../assets/platforms/googletagmanager/googletagmanager_initialization.mdx'),
);

const HighLevelInitialization = lazy(() =>
  importMDX('../../../assets/platforms/highlevel/highlevel_initialization.mdx'),
);

const HighLevelIdentify = lazy(() =>
  importMDX('../../../assets/platforms/highlevel/highlevel_identify.mdx'),
);

const components = {
  h1: MdxHeadLine,
  h2: MdxTitle,
  h4: MdxLineBreak,
  p: MdxParagraph,
  pre: MdxCodeSnippet,
};

const tutorialConfig = {};
tutorialConfig[PLATFORM.IOS] = [
  {
    title: 'Install the iOS SDK',
    component: <IOSSdk components={components} />,
  },
  {
    title: 'Initialize the iOS SDK',
    component: <IOSInitialization components={components} />,
  },
];
tutorialConfig[PLATFORM.FLUTTER] = [
  {
    title: 'Install the Flutter SDK',
    component: <FlutterSdk components={components} />,
  },
  {
    title: 'Install the native dependencies',
    component: <FlutterNative components={components} />,
  },
  {
    title: 'Initialize the Flutter SDK',
    component: <FlutterInitialization components={components} />,
  },
];
tutorialConfig[PLATFORM.WORDPRESS] = [
  {
    title: 'Install the WordPress Plugin',
    component: <WordpressInitialization components={components} />,
  },
];
tutorialConfig[PLATFORM.ANDROID] = [
  {
    title: 'Install the Android SDK',
    component: <AndroidSdk components={components} />,
  },
  {
    title: 'Initialize the Android SDK',
    component: <AndroidInitialization components={components} />,
  },
];
tutorialConfig[PLATFORM.IONIC] = [
  {
    title: 'Install the Ionic / Capacitor SDK',
    component: <IonicSdk components={components} />,
  },
  {
    title: 'Initialize the Ionic / Capacitor SDK',
    component: <IonicInitialization components={components} />,
  },
];
tutorialConfig[PLATFORM.CORDOVA] = [
  {
    title: 'Install the Cordova SDK',
    component: <CordovaSdk components={components} />,
  },
  {
    title: 'Initialize the Cordova SDK',
    component: <CordovaInitialization components={components} />,
  },
];
tutorialConfig[PLATFORM.REACTNATIVE] = [
  {
    title: 'Install the ReactNative SDK',
    component: <ReactNativeSdk components={components} />,
  },
  {
    title: 'Initialize the ReactNative SDK',
    component: <ReactNativeInitialization components={components} />,
  },
];
tutorialConfig[PLATFORM.EXPO] = [
  {
    title: 'Install the Expo SDK',
    component: <ReactNativeExpoSdk components={components} />,
  },
  {
    title: 'Initialize the Expo SDK',
    component: <ReactNativeExpoInitialization components={components} />,
  },
  {
    title: 'Build your Expo app',
    component: <ReactNativeExpoBuild components={components} />,
  },
];
tutorialConfig[PLATFORM.JS] = [
  {
    title: 'Install the JavaScript SDK',
    component: <JSSdk components={components} />,
  },
];
tutorialConfig[PLATFORM.JSWEBSITE] = [
  {
    title: 'Install the JavaScript SDK',
    component: <JSSdk components={components} />,
  },
];
tutorialConfig[PLATFORM.NEXTJS] = [
  {
    title: 'Install the NextJS SDK',
    component: <NextJSSdk components={components} />,
  },
  {
    title: 'Initialize the NextJS SDK',
    component: <NextJSSdkInit components={components} />,
  },
];
tutorialConfig[PLATFORM.REACT] = [
  {
    title: 'Install the React SDK',
    component: <JSReactSdk components={components} />,
  },
  {
    title: 'Initialize the React SDK',
    component: <JSReactSdkInit components={components} />,
  },
];
tutorialConfig[PLATFORM.ANGULAR] = [
  {
    title: 'Install the Angular SDK',
    component: <JSAngularSdk components={components} />,
  },
  {
    title: 'Initialize the Angular SDK',
    component: <JSAngularSdkInit components={components} />,
  },
];
tutorialConfig[PLATFORM.VUE] = [
  {
    title: 'Install the VueJS SDK',
    component: <JSVueSdk components={components} />,
  },
  {
    title: 'Initialize the VueJS SDK',
    component: <JSVueSdkInit components={components} />,
  },
];
tutorialConfig[PLATFORM.WIX] = [
  {
    title: 'Install the SDK',
    component: <WixInitialization components={components} />,
  },
  {
    title: 'Install the SDK',
    component: <WixAddCode components={components} />,
  },
];
tutorialConfig[PLATFORM.SQUARESPACE] = [
  {
    title: 'Install the SDK',
    component: <SquarespaceInitialization components={components} />,
  },
];
tutorialConfig[PLATFORM.WEEBLY] = [
  {
    title: 'Install the SDK',
    component: <WeeblyInitialization components={components} />,
  },
];
tutorialConfig[PLATFORM.WEBFLOW] = [
  {
    title: 'Install the SDK',
    component: <WebflowInitialization components={components} />,
  },
];
tutorialConfig[PLATFORM.GOOGLETAGMANAGER] = [
  {
    title: 'Add the SDK',
    component: <GoogleTagManagerInitialization components={components} />,
  },
];
tutorialConfig[PLATFORM.HIGHLEVEL] = [
  {
    title: 'Add the SDK',
    component: <HighLevelInitialization components={components} />,
  },
  {
    title: 'Identify users',
    component: <HighLevelIdentify components={components} />,
  },
];

interface SDKTutorialProps {
  platform: PLATFORM;
  showOverview?: () => void;
  projectStore?: ProjectStore;
}

const SDKTutorial = ({
  platform,
  showOverview,
  projectStore,
}: SDKTutorialProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  const prev = () => {
    if (currentIndex === 0) {
      if (showOverview) {
        showOverview();
      }
    } else {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const next = () => {
    setCurrentIndex(currentIndex + 1);
  };

  const closeModal = () => {
    if (projectStore?.currentProject) {
      // navigate(`/projects/${projectStore?.currentProject.id}/bugs`);
    }
  };

  const renderTutorial = () => {
    const platformTutorial = tutorialConfig[platform];
    if (platformTutorial && currentIndex < platformTutorial.length) {
      const progress = Math.round(
        ((currentIndex + 1) / (platformTutorial.length + 1)) * 100,
      );
      return (
        <>
          <div className="card">
            <Suspense fallback={<div />}>
              <MDXProvider>
                {platformTutorial[currentIndex].component}
                <div className="navigation-buttons">
                  <LinkButton label="Back" onClick={prev} />
                  <PrimaryButton
                    label="Next"
                    onClick={next}
                    icon="arrow-right"
                    iconSideRight
                  />
                </div>
              </MDXProvider>
            </Suspense>
            {/* <div
              className="progress-bar"
              style={{
                width: `${progress}%`,
              }}
            /> */}
          </div>
        </>
      );
    }

    return (
      <>
        <div className="card">
          <VerifyInstallation
            platform={platform}
            closeModal={closeModal}
            showPrev={prev}
          />
          {/** <div
            className="progress-bar"
            style={{
              width: `100%`,
            }}
          /> */}
        </div>
      </>
    );
  };

  return <div className="tutorial-container">{renderTutorial()}</div>;
};

export default inject('projectStore')(observer(SDKTutorial));
