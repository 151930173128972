import { useNavigate, useParams } from 'react-router';
import { useEffect } from 'react';
import IntegrationCard from 'components/IntegrationCard/IntegrationCard';
import { inject, observer } from 'mobx-react';
import { ProjectStore } from 'stores/private/ProjectStore';
import './ChooseIntegration.scss';
import { integrations } from 'models/Integration';
import PageContainer from 'components/PageContainer/PageContainer';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContent from 'components/PageContent/PageContent';

interface ChooseIntegrationProps {
  projectStore?: ProjectStore;
}

const ChooseIntegration = ({ projectStore }: ChooseIntegrationProps) => {
  const navigate = useNavigate();
  // @ts-ignore
  const { projectId } = useParams();

  const hasStripeIntegrations = Object.keys(projectStore?.currentProject?.integrations?.stripe ?? {}).length > 0;
  const hasCalendlyIntegrations = Object.keys(projectStore?.currentProject?.integrations?.calendly ?? {}).length > 0;
  const integrationData = Object.keys(integrations);

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
  }, [projectId]);

  return (
    <PageContainer>
      <PageHeadLine
        title="Connect an integration"
        onActionBack={() => {
          navigate(`/projects/${projectId}/integrations`);
        }}
      />
      <PageContent hasTitle isCentered isMediumBoxed>
        <div className="configure-integration-container">
          {integrationData.map((objectKey: any) => {
            const integrationItem = integrations[objectKey];
            if (hasStripeIntegrations && objectKey === "stripe") {
              return null;
            }
            if (hasCalendlyIntegrations && objectKey === "calendly") {
              return null;
            }

            return (
              <IntegrationCard
                key={objectKey}
                title={integrationItem.title}
                description={integrationItem.description}
                connect
                image={integrationItem.icon}
                onClick={() => {
                  integrationItem.handleConnection(projectId);
                }}
                entitled={projectStore?.isIntegrationInPlan(
                  integrationItem.name,
                )}
              />
            );
          })}
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore')(observer(ChooseIntegration));
