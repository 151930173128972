import AggregationPicker from 'components/AggregationPicker/AggregationPicker';
import FutureFactChart from 'components/Charts/FactChart/FutureFactChart';
import FutureBarChart from 'components/Charts/StatisicBarChart/FutureStatisticBarChart';
import { formatAxisLabelForSeconds } from 'components/Charts/StatisicBarChart/StatisticBarChart';
import Wrap from 'components/LayoutComponents/WrapComponent/WrapComponent';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import SmartDateRangePicker from 'components/SmartDateRangePicker/SmartDateRangePicker';
import TicketTypeFilter from 'components/TicketTypeFilter/TicketTypeFilter';
import { inject, observer } from 'mobx-react';
import { BarChartStatisticTypes, FactTypes } from 'models/Chart';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import {
  getBarChartStatisic,
  getFactStatistic,
} from 'services/StatisticsService';
import { ProjectStore } from 'stores/private/ProjectStore';

interface CustomerSupportEffectivenessProps {
  projectStore?: ProjectStore;
}

const CustomerSupportEffectiveness = ({
  projectStore,
}: CustomerSupportEffectivenessProps) => {
  const projectId = projectStore?.currentProject?.id;
  const [filterTypes, setFilterTypes] = useState([] as string[]);
  const [filterTags, setFilterTags] = useState([] as string[]);
  const [filter, setFilter] = useState('MEDIAN');
  const [startDate, setStartDate] = useState(
    moment().subtract(1, 'months').startOf('day') as Moment | null,
  );
  const [endDate, setEndDate] = useState(
    moment().endOf('day') as Moment | null,
  );

  return (
    <PageContainer>
      <PageHeadLine title="Effectiveness" tag="BETA">
        <div className="filter-bar hide-on-mobile">
          <AggregationPicker
            className="border-margin-right"
            setFilter={setFilter}
            filter={filter}
          />
          <SmartDateRangePicker
            startIndex={1}
            onDateChange={(start, end) => {
              setStartDate(start);
              setEndDate(end);
            }}
          />
        </div>
      </PageHeadLine>
      <PageContent hasTitle>
        <TicketTypeFilter
          filterTypes={filterTypes}
          setFilterTypes={setFilterTypes}
          selectedTags={filterTags}
          setSelectedTags={setFilterTags}
        />
        <Wrap>
          <FutureFactChart
            infoText="The Ticket Replies Count metric offers a comprehensive view of interactions during a specified time frame. It encompasses both the count and sum of replies generated for tickets, providing a holistic understanding of engagement and support activities. Monitoring this metric allows organizations to assess communication dynamics, evaluate team performance, and gain insights into customer satisfaction. It's an essential tool for ensuring effective customer support and improving overall user experiences."
            width="50%"
            future={getFactStatistic({
              projectId: projectId,
              query: {
                chartType: FactTypes.TICKET_REPLIES_COUNT,
                startDate: startDate?.toDate(),
                endDate: endDate?.toDate(),
                'data.type_in': filterTypes,
                'data.tags_in': filterTags,
              },
            })}
          />
          <FutureFactChart
            infoText="The Median First Assignment Time metric measures the median time it takes for a ticket to be assigned to the first responder or team after its creation. This metric helps assess how quickly incoming issues or requests are directed to the appropriate personnel for resolution. A lower median time indicates more efficient ticket assignment processes, contributing to faster issue resolution and improved customer satisfaction. Monitoring this metric allows teams to identify bottlenecks in ticket assignment and make improvements as needed."
            width="50%"
            future={getFactStatistic({
              projectId: projectId,
              query: {
                chartType: FactTypes.MEDIAN_FIRST_ASSIGNMENT_TIME,
                startDate: startDate?.toDate(),
                endDate: endDate?.toDate(),
                aggsType: filter,
                'data.type_in': filterTypes,
                'data.tags_in': filterTags,
              },
            })}
            isPositiveProgressGood={false}
          />
          <FutureFactChart
            infoText="The median time to first close is determined by finding the median value of the duration taken to close all tickets. This metric serves as a key indicator of the overall efficiency in resolving issues or inquiries."
            width="50%"
            future={getFactStatistic({
              projectId: projectId,
              query: {
                chartType: FactTypes.MEDIAN_TIME_TO_CLOSE,
                startDate: startDate?.toDate(),
                endDate: endDate?.toDate(),
                aggsType: filter,
                'data.type_in': filterTypes,
                'data.tags_in': filterTags,
              },
            })}
            isPositiveProgressGood={false}
          />
        </Wrap>
        <FutureBarChart
          infoText="The Replies Sent Count provides a daily breakdown of the total number of replies sent in response to tickets. This metric is crucial for understanding the volume and distribution of replies over time, offering insights into the team's communication activity and efficiency in addressing customer queries. By breaking down this data on a day-to-day basis, it helps in monitoring and managing the team's responsiveness and engagement levels with customers."
          futureWithAbortion={async (controller) => {
            const res = await getBarChartStatisic({
              projectId: projectId,
              query: {
                chartType: BarChartStatisticTypes.REPLIES_COUNT,
                startDate: startDate?.toDate(),
                endDate: endDate?.toDate(),
                timezone: moment.tz.guess(),
                'data.type_in': filterTypes,
                'data.tags_in': filterTags,
              },
              signal: controller.signal,
            });
            return res;
          }}
        />
        <FutureBarChart
          infoText="The Median Time to First Assignment metric measures the median amount of time it takes for a ticket to be assigned to the first agent after it's created. This median value helps gauge the efficiency of the ticket assignment process and provides insights into how quickly customer inquiries or issues are attended to by the support team. Monitoring this metric can aid in improving response times and customer satisfaction by identifying and addressing bottlenecks in the assignment workflow."
          formatter={formatAxisLabelForSeconds}
          future={getBarChartStatisic({
            projectId: projectId,
            query: {
              chartType: BarChartStatisticTypes.MEDIAN_TIME_TO_FIRST_ASSIGNMENT,
              startDate: startDate?.toDate(),
              endDate: endDate?.toDate(),
              timezone: moment.tz.guess(),
              'data.type_in': filterTypes,
              'data.tags_in': filterTags,
              aggsType: filter,
            },
          })}
        />
        <FutureBarChart
          infoText="This metric measures the median time it takes for a ticket to be closed after it has been assigned to the first agent. It provides insights into the efficiency of resolving customer inquiries or issues from the moment they are assigned to an agent until they are successfully closed. This data helps in monitoring and optimizing the ticket resolution process to enhance customer satisfaction and streamline support operations."
          formatter={formatAxisLabelForSeconds}
          future={getBarChartStatisic({
            projectId: projectId,
            query: {
              chartType: BarChartStatisticTypes.MEDIAN_TIME_TO_CLOSE,
              startDate: startDate?.toDate(),
              endDate: endDate?.toDate(),
              timezone: moment.tz.guess(),
              aggsType: filter,
              'data.type_in': filterTypes,
              'data.tags_in': filterTags,
            },
          })}
        />
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore')(observer(CustomerSupportEffectiveness));
