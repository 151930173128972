import Modal from 'react-modal';
import { useLocation, useNavigate } from 'react-router-dom';
import BugDetail from './BugDetail';
import { inject, observer } from 'mobx-react';
import { BugStore } from 'stores/private/BugStore';

const TicketDetailScreen = ({
  bugStore,
}: {
  bugStore?: BugStore;
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const navigateBack = () => {
    bugStore?.clearCurrentBug(false);

    const parts = location.pathname.split('/');
    parts.pop(); // remove the last part
    const newPath = parts.join('/');
    navigate(newPath);
  };

  return (
    <Modal
      overlayClassName="ticket-detail-modal"
      className="modal modal--full-width-right-top"
      isOpen
      ariaHideApp={false}
      closeTimeoutMS={500}
      onRequestClose={() => {
        navigateBack();
      }}
    >
      <BugDetail shared={false} />
      <div
        className="close-button"
        onClick={() => {
          navigateBack();
        }}
      >
        <i className="fa-solid fa-xmark" />
      </div>
    </Modal>
  );
};

export default inject(
  'bugStore',
)(observer(TicketDetailScreen));
