import LinkButton from 'components/LinkButton/LinkButton';
import './ChargesList.scss';

export const convertTimestampToDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString();
}

export const convertCentsToUnits = (cents) => {
    return (cents / 100).toFixed(2);
}

export const getCurrencySymbol = (currency) => {
    switch (currency) {
        case 'usd': return '$';
        case 'eur': return '€';
        // Add more cases as needed
        default: return '';
    }
}

const ChargeList = ({ charges, customer }) => {
    const getIcon = (status) => {
        let iconName = "";

        if (status === 'succeeded') {
            iconName = 'fa-check';
        } else if (status === 'pending') {
            iconName = 'fa-hourglass-half';
        } else if (status === 'failed') {
            iconName = 'fa-xmark';
        }

        return iconName;
    }

    return (
        <div className="charges-list">
            {charges?.map((charge, index) => (
                <div className="charges-list-item" key={index}>
                    <div className={`charge-icon charge-icon-${charge.status.toLowerCase()}`}>
                        <i className={`fa-regular ${getIcon(charge.status)}`} />
                    </div>
                    <div>
                        <div className='charge-title'>{getCurrencySymbol(charge.currency)}{convertCentsToUnits(charge.amount)} {charge.status}</div>
                        <div className='charge-date'>{convertTimestampToDate(charge.created)}</div>
                    </div>
                </div>
            ))}
            {(!charges || charges?.length === 0) && <div className='text mt-20'>No charges yet</div>}
            <LinkButton
                className="bfw mt-15"
                icon="arrow-up-right"
                label="Show all charges"
                iconSideRight={true}
                onClick={() => {
                    window.open(`https://dashboard.stripe.com/payments?customer=${customer.id}`, "_blank");
                }}
            />
        </div>
    );
};

export default ChargeList;
