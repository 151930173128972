import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { BugStore } from 'stores/private/BugStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './BugStatusSelection.scss';
import doneIcon from 'assets/icons/np_check_1688085_000000.svg';
import openIcon from 'assets/icons/np_inbox_3629724_000000.svg';
import otherIcon from 'assets/icons/np_jewel_3913685_000000.svg';
import inProgressIcon from 'assets/icons/np_loading_2550337_000000.svg';
import testIcon from 'assets/icons/shapes.svg';

export const statusDefaultIcons = {
  OPEN: openIcon,
  DONE: doneIcon,
  INPROGRESS: inProgressIcon,
  TOTEST: testIcon,
};

interface BugAssignUserProps {
  bugStore?: BugStore;
  projectStore?: ProjectStore;
  isInbox?: boolean;
}

const BugStatusSelection = ({ bugStore, projectStore }: BugAssignUserProps) => {
  const bug = bugStore?.currentBug;
  const [data, setData] = useState('');
  const [laneKeys, setLaneKeys] = useState([] as any[]);

  useEffect(() => {
    const currentFeedbackType = projectStore!.findFeedbackTypeForType(
      bug?.type,
    );

    if (
      currentFeedbackType &&
      currentFeedbackType.options &&
      currentFeedbackType.options.possibleLanes
    ) {
      const laneKeysPrep: any[] = [];
      for (
        let i = 0;
        i < currentFeedbackType.options.possibleLanes.length;
        i++
      ) {
        const possibleLane = currentFeedbackType.options.possibleLanes[i];
        let icon = statusDefaultIcons[possibleLane.key];
        if (!icon) {
          icon = otherIcon;
        }

        if (possibleLane.key === 'SNOOZED') {
          continue;
        }

        laneKeysPrep.push({
          title: possibleLane.title,
          value: possibleLane.key,
          icon,
        });
      }

      setLaneKeys(laneKeysPrep);
    }
  }, [bugStore?.currentBug, projectStore?.currentProject]);

  useEffect(() => {
    if (bug?.status && laneKeys) {
      const bugStatus = laneKeys.find((x) => x.value === bug?.status);
      setData(bugStatus);
    }
  }, [bug?.status, laneKeys]);

  if (!bug || laneKeys.length === 0 || data === '') {
    return <></>;
  }

  return (
    <>
      <Select
        className="dropdown-selection"
        classNamePrefix="dropdown-selection"
        defaultValue={data}
        value={data}
        placeholder="Status"
        isClearable={false}
        isSearchable={false}
        components={{
          SingleValue: (option: any) => {
            return (
              <div className="bug-status-item">
                <img
                  src={option.data.icon}
                  alt="status"
                  className="status-icon"
                />
                {option.data.title}
              </div>
            );
          },
          Option: (option: any) => {
            return (
              <div
                className="option option-list-item bug-status-item"
                onClick={() => {
                  if (option.selectOption) {
                    option.selectOption(option.data);
                  }
                }}
              >
                <img
                  src={option.data.icon}
                  alt="status"
                  className="status-icon"
                />
                {option.data.title}
              </div>
            );
          },
        }}
        onChange={(selected: any) => {
          if (selected) {
            bugStore!.updateBug(bug.id, {
              status: selected.value,
            });
            setData(selected);
          }
        }}
        options={laneKeys}
      />
    </>
  );
};

export default inject('bugStore', 'projectStore')(observer(BugStatusSelection));
