import { arrayMoveImmutable } from 'array-move';
import { getKaiSupportCapability } from 'components/Modals/ActionEditorModal/AnswerBotActionEditor';
import { convertTipTapToPlainText } from 'helper/TipTapHelper';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Action, ActionFlowNode, ActionTypes } from 'models/Bot';
import { useState } from 'react';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { Handle, Position, useReactFlow } from 'reactflow';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { PropertyStore } from 'stores/private/PropertyStore';
import { ReactComponent as DragIcon } from '../../../../../assets/icons/dragicon.svg';
import ActionEditorItem from '../ActionEditorItem/ActionEditorItem';
import ActionTriggerEditor from '../ActionTriggerEditor/ActionTriggerEditor';
import BotActionAddOptions, {
  botActionTypes,
} from '../BotActionAddOptions/BotActionAddOptions';
import './ActionEditor.scss';

export const generateTextFromContent = (content) => {
  try {
    return convertTipTapToPlainText({ content: content });
  } catch (exp) {}
  return '';
};

const DragHandle = SortableHandle(() => (
  <DragIcon className="action-element-drag-icon" />
));

const SortableItem = SortableElement(
  ({ action, currentIndex, data, addActionComponent }) => {
    const botAction = botActionTypes.find(
      (botAction) => botAction.value === action.type,
    );

    return (
      <div key={currentIndex}>
        {actionIsEndType(action) && addActionComponent}
        <div className="action-item-wrapper">
          <ActionEditorItem
            action={action}
            data={data}
            currentActionIndex={currentIndex}
          />

          {!botAction?.endOption && <DragHandle />}
        </div>
      </div>
    );
  },
);

const SortableList = SortableContainer(
  ({ actions, data, addActionComponent }) => {
    return (
      <div key={'sortable-items'}>
        {actions.map((action, index) => (
          <SortableItem
            key={index}
            index={index}
            action={action}
            currentIndex={index}
            data={data}
            addActionComponent={addActionComponent}
          />
        ))}
      </div>
    );
  },
);

interface ActionEditorProps {
  data: ActionFlowNode;
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
  propertyStore?: PropertyStore;
}

export const actionIsEndType = (action: Action) => {
  return (
    action.type === ActionTypes.BUTTONS ||
    action.type === ActionTypes.CONDITION ||
    action.type === ActionTypes.CREATE_TICKET ||
    action.type === ActionTypes.ANSWERBOTFLOW ||
    action.type === ActionTypes.BOTFLOW ||
    action.type === ActionTypes.TICKETUPDATE_CLOSE ||
    action.type === ActionTypes.FEEDBACKFLOW ||
    action.type === ActionTypes.ACTIONFLOW ||
    action.type === ActionTypes.LINK
  );
};

const ActionEditor = ({
  data,
  modalStore,
  projectStore,
  propertyStore,
}: ActionEditorProps) => {
  const reactFlow = useReactFlow();

  const actions = data?.actions ?? [];
  const hasEndAction = actions.find((action) => actionIsEndType(action));
  const hasForwardAction = actions.find(
    (action) => action.type === ActionTypes.BUTTONS,
  );
  const currentLang = projectStore?.currentLanguage ?? 'en';

  const [showAddQuestion, setShowAddQuestion] = useState(false);

  const _buildAddAction = () => {
    return (
      <div className="add-action-container-outer">
        <div
          className="add-action-container mt-10 mb-20"
          onClick={() => {
            setShowAddQuestion(!showAddQuestion);
          }}
        >
          <div className="line" />
          <i className="icon fa-solid fa-circle-plus" />
          <div className="line" />
        </div>
        {showAddQuestion && (
          <BotActionAddOptions
            canShowEndOptions={!hasEndAction}
            onBotActionAdded={(type) => {
              var action: any = {
                type,
              };

              // Default buttons.
              if (type === ActionTypes.BUTTONS) {
                action.buttons = [
                  {
                    text: {
                      localized: { [currentLang]: 'New button option' },
                    },
                  },
                ];
              }

              // Default input for rating.
              if (type === ActionTypes.RATECONVERSATION) {
                action.npsType = 'emoji';
                action.title = {
                  localized: {
                    [currentLang]: 'Rate your conversation',
                  },
                };
              }

              // Default input type.
              if (type === ActionTypes.INPUT) {
                action.actionType = 'attribute';
                action.title = {
                  localized: {
                    [currentLang]: 'What is your email address?',
                  },
                };
              }

              if (type === ActionTypes.TICKETUPDATE_STATUS) {
                action.ticketType = 'INQUIRY';
              }

              if (type === ActionTypes.CREATE_TICKET) {
                action.title = {
                  localized: {
                    [currentLang]:
                      'This action does not trigger another workflow.',
                  },
                };
              }

              if (type === ActionTypes.ANSWERBOTFLOW) {
                action.showReplyTimes = true;

                action.question = {
                  localized: {
                    [currentLang]:
                      "Hey 👋! I'm here to assist you. How can I help you?",
                  },
                };

                action.askForAdditionalHelp = {
                  localized: {
                    [currentLang]:
                      'Simply reply below to ask another question.',
                  },
                };

                action.talkToHuman = {
                  localized: {
                    [currentLang]: 'Talk to our support team',
                  },
                };

                // Set default forwarding text.
                action.forwardingText = {
                  localized: {
                    en: 'Connecting you with a human agent.',
                    de: 'Ich verbinde dich mit einem Mitarbeiter.',
                    fr: 'Je vous mets en relation avec un agent.',
                    es: 'Te conecto con un agente humano.',
                    it: 'Ti connetto con un agente umano.',
                    nl: 'Ik verbind je met een menselijke agent.',
                    pt: 'Vou conectá-lo com um agente humano.',
                    ru: 'Я свяжу вас с человеком.',
                    ja: '人間のエージェントに接続します。',
                    zh: '我将您与人工客服连接。',
                    ua: "Я зв'яжу вас з людиною.",
                  },
                };

                // Initialize with pass to support feature.
                action.capabilities = [getKaiSupportCapability()];
              }

              // Default input condition.
              if (type === ActionTypes.CONDITION) {
                action.conditions = [
                  {
                    type: ActionTypes.ACTIONFLOW,
                    actionFlow: `af-${Math.floor(Date.now() * 1000)}`,
                    action: 0,
                    predicates: {
                      type: 'and',
                      predicates: [
                        {
                          type: 'and',
                          predicates: [{}],
                        } as any,
                      ],
                    },
                  },
                ];
              }

              // Feedback form
              if (type === ActionTypes.FEEDBACKFLOW) {
                // Set bug reporting as default flow.
                action.flow = 'bugreporting';
              }

              runInAction(() => {
                if (hasEndAction) {
                  actions.splice(actions.length - 1, 0, action);
                } else {
                  actions.push(action);
                }
              });

              setShowAddQuestion(false);
            }}
            closeAddOptions={() => {
              setShowAddQuestion(false);
            }}
          />
        )}
      </div>
    );
  };

  const onSortEnd = (event) => {
    try {
      projectStore!.botNodesDraggable = true;

      // Should not be able to move the last item
      if (event.newIndex === actions.length - 1) {
        return;
      }

      runInAction(() => {
        data.actions = arrayMoveImmutable(
          actions,
          event.oldIndex,
          event.newIndex,
        );
      });
    } catch (_) {}
  };

  return (
    <div className="action-editor-container">
      {data.type !== 'start' && (
        <Handle
          className="action-target"
          type="target"
          position={Position.Left}
        >
          <i className="fa-sharp fa-solid fa-arrow-right" />
        </Handle>
      )}
      <SortableList
        data={data}
        addActionComponent={_buildAddAction()}
        actions={actions}
        onSortEnd={onSortEnd}
        useDragHandle
        onSortStart={() => {
          runInAction(() => {
            projectStore!.botNodesDraggable = false;
          });
        }}
        shouldCancelStart={(event) => {
          // Find the index of the item being dragged
          const { target } = event;
          const index = actions.indexOf(target);
          // Prevent dragging if the item is the last one
          return index === actions.length - 1;
        }}
      />
      {!hasEndAction && _buildAddAction()}
      {!hasForwardAction && (
        <div className="action-editor-container-endtag">
          <span>END</span>
        </div>
      )}
      {data.type === 'start' && (
        <div className="action-editor-container-trigger-action">
          <ActionTriggerEditor />
        </div>
      )}
      {data.type !== 'start' && (
        <div
          className="node-delete-button"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            if (reactFlow) {
              reactFlow.deleteElements({
                nodes: [
                  {
                    id: data.id,
                  },
                ],
              });
            }
          }}
        >
          <i className="fa-solid fa-trash" />
        </div>
      )}
    </div>
  );
};

export default inject(
  'modalStore',
  'projectStore',
  'propertyStore',
)(observer(ActionEditor));
