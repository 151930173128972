import BotDropdown from 'components/BotDropdown/BotDropdown';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Action } from 'models/Bot';
import { useEffect } from 'react';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './BotActionEditor.scss';

interface BotActionEditorProps {
  action: Action;
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
}

const BotActionEditor = ({
  action,
  modalStore,
  projectStore,
}: BotActionEditorProps) => {
  useEffect(() => {
    if (projectStore?.currentProject?.id && projectStore?.bots?.length === 0) {
      projectStore?.getBots(projectStore?.currentProject?.id);
    }
  }, [projectStore?.currentProject?.id]);

  return (
    <div className="bot-action-editor">
      <PageContainer>
        <PageHeadLine title="Start a workflow">
          <div className="header-content-right">
            <PrimaryButton
              label="Close"
              iconSideRight={false}
              onClick={() => {
                modalStore!.closeModal();
              }}
            />
          </div>
        </PageHeadLine>
        <PageContent hasTitle>
          <div className="text">
            This action sends the conversation to the specified workflow.
          </div>
          <div className="input-label mt-20">Workflow</div>
          <BotDropdown
            projectId={projectStore?.currentProject?.id}
            value={projectStore?.bots?.find((bot) => {
              return bot.id === action.botId;
            })}
            options={projectStore?.bots ?? []}
            onValueChanged={(selectedItem) => {
              runInAction(() => {
                action.botId = selectedItem.id;
              });
            }}
          />
        </PageContent>
      </PageContainer>
    </div>
  );
};

export default inject('modalStore', 'projectStore')(observer(BotActionEditor));
