import BoardView from 'components/ListView/BoardView';
import ListView from 'components/ListView/ListView';
import Loading from 'components/Loading/Loading';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { ProjectStore } from 'stores/private/ProjectStore';
import './ProjectFeedback.scss';

interface ProjectFeedbackProps {
  projectStore?: ProjectStore;
  forceTypePath?: string;
}

const ProjectFeedback: React.FC<ProjectFeedbackProps> = ({
  projectStore,
  forceTypePath,
}: ProjectFeedbackProps) => {
  const { feedbackTypePath } = useParams();

  useEffect(() => {
    try {
      const viewKey = `project-${projectStore?.currentProject?.id}-${projectStore?.currentFeedbackType?.type}-view`;
      const storedView = localStorage.getItem(viewKey);
      if (storedView && storedView.length > 0) {
        projectStore!.setViewType(storedView);
      }
    } catch (error) {}
  }, [projectStore?.currentFeedbackType?.type]);

  useEffect(() => {
    if (projectStore?.currentProject) {
      if (forceTypePath) {
        projectStore!.setFeedbackTypeForPath(forceTypePath);
      } else if (feedbackTypePath) {
        projectStore!.setFeedbackTypeForPath(feedbackTypePath);
      } else {
        projectStore!.setFeedbackTypeForPath('bugs');
      }
    }
  }, [projectStore?.currentProject, feedbackTypePath, forceTypePath]);

  let RenderComponent =
    projectStore?.viewType === 'LIST' ? ListView : BoardView;

  if (!projectStore?.currentFeedbackType || !RenderComponent) {
    return (
      <PageContainer>
        <PageContent isCentered>
          <Loading />
        </PageContent>
      </PageContainer>
    );
  }

  return (
    <>
      <RenderComponent
        key={`${projectStore.currentProject?.id}-${projectStore?.viewType}`}
      />
      <Outlet />
    </>
  );
};

export default inject('projectStore')(observer(ProjectFeedback));
