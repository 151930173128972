import React from 'react';
import './SetDataAttributeEditor.scss';
import { Action } from 'models/Bot';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { inject, observer } from 'mobx-react';
import PageContainer from 'components/PageContainer/PageContainer';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import PageContent from 'components/PageContent/PageContent';
import PropertySelect from 'components/PropertyComponents/PropertySelect/PropertySelect';
import { runInAction } from 'mobx';
import { PropertyStore } from 'stores/private/PropertyStore';
import PropertyInput from 'components/PropertyComponents/PropertyInput/PropertyInput';
import {
  getSchemaProperty,
  setSchemaProperty,
} from 'helper/AssignObjectKeysHelper';

interface SetDataAttributeEditorProps {
  action: Action;
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
  propertyStore?: PropertyStore;
}

const SetDataAttributeEditor = ({
  action,
  modalStore,
  projectStore,
  propertyStore,
}: SetDataAttributeEditorProps) => {
  const currentProperty = propertyStore?.currentProjectProperties.find(
    (p) => p._id === action.propertyId,
  );

  let preparedValue = {};
  if (currentProperty) {
    setSchemaProperty(preparedValue, currentProperty, action.value);
  }

  return (
    <div className="input-action-editor">
      <PageContainer>
        <PageHeadLine title="Set data attribute">
          <div className="header-content-right">
            <PrimaryButton
              label="Close"
              iconSideRight={false}
              onClick={() => {
                modalStore!.closeModal();
              }}
            />
          </div>
        </PageHeadLine>
        <PageContent hasTitle>
          <PropertySelect
            className="mb-20"
            selectedItemId={action.propertyId}
            useId
            onChange={(property) => {
              runInAction(() => {
                action.propertyId = property._id;
              });
            }}
          />
          {currentProperty && (
            <>
              <div className="text property-title-action">Value</div>
              <div className="property-input-action">
                <PropertyInput
                  data={preparedValue}
                  property={currentProperty}
                  onSetData={(data) => {
                    const value = getSchemaProperty(data, currentProperty);

                    runInAction(() => {
                      action.value = value;
                    });
                  }}
                />
              </div>
            </>
          )}
        </PageContent>
      </PageContainer>
    </div>
  );
};

export default inject(
  'modalStore',
  'projectStore',
  'propertyStore',
)(observer(SetDataAttributeEditor));
