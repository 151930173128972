import { CopyToClipboard } from 'react-copy-to-clipboard';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';
import copy from '../../assets/icons/copy.svg';
import './ApiKey.scss';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';

interface ApikeyProps {
  apiKey: String;
  icon?: String;
}

const ApiKey = ({ apiKey, icon = "key" }: ApikeyProps) => {
  return (
    <div className="apikey">
      <i className={`fa-solid fa-${icon} api-key-icon`} />
      <div className="key">{apiKey}</div>
      <CopyToClipboard
        text={apiKey}
        onCopy={() => {
          toast.success('Successfully copied ✓');
        }}
      >
        <PrimaryButton label="Copy" />
      </CopyToClipboard>
    </div>
  );
};

export default inject('projectStore')(observer(ApiKey));
