import { inject, observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { useClickedOutside, useEscape } from 'services/Helper';
import { ProjectStore } from 'stores/private/ProjectStore';
import './BoardSortFilter.scss';

interface BoardSortFilterProps {
  value?: any;
  filterOptions?: any[];
  initialFilter?: { sortKey: string; sortDirection: any };
  projectStore?: ProjectStore;
  onChange?: (value: any) => void;
}

const sortDirectionOptions = [
  {
    key: 'desc',
    title: 'Descending',
    path: null,
  },
  {
    key: 'asc',
    title: 'Ascending',
    path: null,
  },
];

const BoardSortFilter = ({
  filterOptions = [],
  projectStore,
  initialFilter,
  onChange,
}: BoardSortFilterProps) => {
  const feedbackTypePath =
    projectStore?.currentFeedbackType?.path ?? 'inquiries';
  const [showFilter, setShowFilter] = useState(false);
  const [sortKey, setSortKey] = useState(undefined as any);
  const [sortDirection, setSortDirection] = useState(
    sortDirectionOptions[0] as any,
  );

  const wrapperRef = useRef(null);
  useClickedOutside(wrapperRef, () => {
    setShowFilter(false);
  });
  useEscape(() => {
    setShowFilter(false);
  });

  useEffect(() => {
    if (feedbackTypePath && projectStore?.currentProject) {
      try {
        const boardSort = localStorage.getItem(
          `boardSort_${projectStore?.currentProject?.id}_${feedbackTypePath}`,
        );

        if (boardSort) {
          const boardSortData = JSON.parse(boardSort);

          if (
            boardSortData &&
            boardSortData?.sortKey &&
            boardSortData?.sortDirection
          ) {
            setSortDirection(
              sortDirectionOptions.find(
                (o) => o.key === boardSortData.sortDirection,
              ),
            );

            setSortKey(
              filterOptions.find((o) => o.key === boardSortData.sortKey),
            );
          }

          projectStore!.currentTicketDataSort = {
            ...boardSortData,
            path: feedbackTypePath,
          };

          projectStore?.filterTicketsData();
        } else {
          setSortDirection(sortDirectionOptions[0]);
          setSortKey(filterOptions[0]);
        }
        // eslint-disable-next-line no-empty
      } catch (exp) {
        setSortDirection(sortDirectionOptions[0]);
        setSortKey(filterOptions[0]);
      }
    }
  }, [feedbackTypePath, projectStore?.currentProject]);

  useEffect(() => {
    if (initialFilter && Object.keys(initialFilter).length > 0) {
      setSortKey(filterOptions.find((o) => o.key === initialFilter.sortKey));
      setSortDirection(
        sortDirectionOptions.find((o) => o.key === initialFilter.sortDirection),
      );
    }
  }, [initialFilter]);

  const notifyUpdate = (sort, direction) => {
    const value = {
      sortKey: sort.key,
      sortDirection: direction.key,
    };

    if (onChange) {
      onChange(value);
      return;
    }

    if (sort && direction) {
      try {
        localStorage.setItem(
          `boardSort_${projectStore?.currentProject?.id}_${feedbackTypePath}`,
          JSON.stringify(value),
        );
        // eslint-disable-next-line no-empty
      } catch (exp) {}

      projectStore!.currentTicketDataSort = {
        ...value,
        path: feedbackTypePath,
      };
      projectStore?.filterTicketsData();
    }
  };

  var icon =
    sortDirection && sortDirection.key === 'desc'
      ? 'arrow-down-long'
      : 'arrow-up-long';
  var isActive = !(sortKey && sortKey.key === 'lexorank');

  return (
    <div className="board-sort-filter hide-on-mobile" ref={wrapperRef}>
      <div
        className="board-sort-filter-button"
        onClick={() => {
          setShowFilter(!showFilter);
        }}
      >
        <i
          className={`fa-solid fa-${
            sortKey && sortKey.key === 'lexorank'
              ? 'arrow-up-triangle-square'
              : icon
          }`}
        />
        <span>{sortKey?.title}</span>
        {isActive && <div className="board-filter-active-indicator" />}
      </div>
      {showFilter && (
        <div className="board-sort-filter-selection">
          <Select
            className="dropdown-selection board-sort-filter-dropdown"
            classNamePrefix="dropdown-selection"
            value={sortKey}
            isSearchable={false}
            getOptionLabel={(option) => option.title}
            getOptionValue={(option) => option.key}
            placeholder="Sort by"
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            components={{
              SingleValue: (option: any) => {
                return (
                  <div className="board-sort-item">
                    <span>{option.data.title}</span>
                  </div>
                );
              },
              Option: (option: any) => {
                return (
                  <div
                    className="option option-list-item board-sort-item"
                    onClick={() => {
                      if (option.selectOption) {
                        option.selectOption(option.data);
                      }
                    }}
                  >
                    <span>{option.data.title}</span>
                  </div>
                );
              },
            }}
            onChange={(selectedTag: any) => {
              if (selectedTag) {
                setSortKey(selectedTag);
                notifyUpdate(selectedTag, sortDirection);
              }
            }}
            options={filterOptions ?? []}
          />
          {!(sortKey && sortKey.key === 'lexorank') && (
            <Select
              className="dropdown-selection board-sort-filter-dropdown mt-5"
              classNamePrefix="dropdown-selection"
              value={sortDirection}
              isSearchable={false}
              getOptionLabel={(option) => option.title}
              getOptionValue={(option) => option.key}
              placeholder="Sort by"
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
              menuPortalTarget={document.body}
              menuPosition="fixed"
              components={{
                SingleValue: (option: any) => {
                  return (
                    <div className="board-sort-item">
                      <span>{option.data.title}</span>
                    </div>
                  );
                },
                Option: (option: any) => {
                  return (
                    <div
                      className="option option-list-item board-sort-item"
                      onClick={() => {
                        if (option.selectOption) {
                          option.selectOption(option.data);
                        }
                      }}
                    >
                      <span>{option.data.title}</span>
                    </div>
                  );
                },
              }}
              onChange={(selectedTag: any) => {
                if (selectedTag) {
                  setSortDirection(selectedTag);
                  notifyUpdate(sortKey, selectedTag);
                }
              }}
              options={sortDirectionOptions ?? []}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default inject('projectStore')(observer(BoardSortFilter));
