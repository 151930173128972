import axios from '../Axios';

const getAuthCodeFromHubspot = (projectID) => {
  const redirectUri = `${process.env.REACT_APP_BASEURL}/integrations/hubspot`;

  return window.open(
    `https://app.hubspot.com/oauth/authorize?client_id=${
      process.env.REACT_APP_HUBSPOT_CLIENT_ID
    }&redirect_uri=${encodeURIComponent(
      redirectUri,
    )}&scope=tickets%20crm.objects.contacts.read%20files%20crm.objects.contacts.write&state=${projectID}`,
    '_self',
  );
};

const setHubspotAuthToken = (code, projectID) => {
  return axios.post(`/projects/${projectID}/integrations/hubspot`, {
    code,
  });
};

const getPipelines = ({ projectID, integrationID }) => {
  return axios.get(
    `/projects/${projectID}/integrations/hubspot/${integrationID}/pipelines`,
  );
};

const getPiplelineStages = ({ projectID, integrationID, pipelineID }) => {
  return axios.get(
    `/projects/${projectID}/integrations/hubspot/${integrationID}/pipelines/${pipelineID}/stages`,
  );
};

const setHubspotActions = ({ projectID, integrationID, actions, settings }) => {
  return axios.put(
    `/projects/${projectID}/integrations/hubspot/${integrationID}`,
    {
      actions,
      settings,
    },
  );
};

const disconnectHubspotIntegration = ({ projectID, integrationID }) => {
  return axios.delete(
    `/projects/${projectID}/integrations/hubspot/${integrationID}`,
  );
};

export {
  getAuthCodeFromHubspot,
  getPipelines,
  getPiplelineStages,
  setHubspotAuthToken,
  setHubspotActions,
  disconnectHubspotIntegration,
};
