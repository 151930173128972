import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import { checkIntegrationData } from 'helper/Integration';
import { inject, observer } from 'mobx-react';
import { INTEGRATION } from 'models/Integration';
import React, { useEffect, useState } from 'react';
import { HubspotIntegrationStore } from 'stores/integrations/HubspotIntegrationStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import Switch from 'react-switch';
import './HubSpotConfiguration.scss';

interface HubSpotConfigurationProps {
  integrationID: string;
  index: number;
  hubspotIntegrationStore?: HubspotIntegrationStore;
  projectStore?: ProjectStore;
  onChange: (data: any) => any;
}

const HubSpotConfiguration = ({
  integrationID,
  index,
  hubspotIntegrationStore,
  projectStore,
  onChange,
}: HubSpotConfigurationProps) => {
  const project = projectStore?.currentProject;

  const [currentIntegrationInfo, setCurrentIntegrationInfo] = useState(
    {} as any,
  );

  const pipelines = hubspotIntegrationStore?.pipelines;
  const piplelineStages = hubspotIntegrationStore?.piplelineStages;

  const [pipelineID, setPipelineID] = useState('');
  const [pipelineStageID, setPipelineStageID] = useState('');
  const [sendCommentsAsNotes, setSendCommentsAsNotes] = useState(false);

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    if (project && isMounted) {
      initialize();
    }
  }, [project, isMounted]);

  useEffect(() => {
    saveIntegrationInfo();
  }, [pipelineID, pipelineStageID, sendCommentsAsNotes]);

  const initialize = async () => {
    hubspotIntegrationStore!.setIntegrationID(integrationID);
    await hubspotIntegrationStore!.getPipelines();

    if (
      !checkIntegrationData({
        project,
        integrationName: INTEGRATION.HUBSPOT,
        integrationID,
        actionIndex: index,
      })
    ) {
      return;
    }

    const integrationInfo =
      project?.integrations?.hubspot[integrationID]?.actions[index]
        ?.integrationInfo;

    setSendCommentsAsNotes(integrationInfo.sendCommentsAsNotes ?? false);
    setCurrentIntegrationInfo(integrationInfo);
  };

  const saveIntegrationInfo = () => {
    const integrationInfo = {
      pipelineID,
      pipelineStageID,
      sendCommentsAsNotes,
    };

    onChange(integrationInfo);
  };

  return (
    <div className="configuration-container mt-30">
      <SelectDropDown
        className="mb-15"
        label="Pipline (optional)"
        labelPropertyName="label"
        valuePropertyName="id"
        selectedItem={pipelines.find(
          (element) => element.id === currentIntegrationInfo?.pipelineID,
        )}
        items={pipelines}
        onChange={(option) => {
          setPipelineID(option.id);
          hubspotIntegrationStore?.getPiplineStages(option.id);
        }}
      />
      {pipelineID && pipelineID !== '' && (
        <SelectDropDown
          className="mb-15"
          label="Pipline stage"
          labelPropertyName="label"
          valuePropertyName="id"
          selectedItem={piplelineStages.find(
            (element) => element.id === currentIntegrationInfo?.pipelineStageID,
          )}
          items={piplelineStages}
          onChange={(option) => {
            setPipelineStageID(option.id);
          }}
        />
      )}
      <div className="switch-container">
        <Switch
          width={40}
          onColor="#2142E7"
          height={20}
          checkedIcon={false}
          uncheckedIcon={false}
          onChange={(checked) => {
            setSendCommentsAsNotes(checked);
          }}
          checked={sendCommentsAsNotes ? true : false}
        />
        <span>Send comments as notes.</span>
      </div>
    </div>
  );
};

export default inject(
  'projectStore',
  'hubspotIntegrationStore',
)(observer(HubSpotConfiguration));
