import DropDown from 'components/DropDown/DropDown';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { useNavigate } from 'react-router';
import { useTable } from 'react-table';
import './ListTable.scss';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import verifiedIcon from 'assets/icons/np_verify_1896650_4FC47E.svg';
import notVerifiedIcon from 'assets/icons/np_delete_45885_D9D9D9.svg';
import { toast } from 'react-toastify';
import moment from 'moment';
import { cleanupName } from 'components/FeedbackUserFilter/FeedbackUserFilter';
import { getSessionName } from 'services/GuestNameHelper';

interface ListTableProps {
  columns: any;
  data: any;
  showHeader?: boolean;
}

function ListTable({ columns, data, showHeader = true }: ListTableProps) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  // Render the UI for your table
  return (
    <div className="list-table">
      {!showHeader && <div className="top-spacer" />}
      <table {...getTableProps()}>
        {showHeader && (
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps({
                      style: {
                        width: column.width,
                        paddingLeft: column.paddingLeft,
                      },
                      className: column.className,
                    })}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        )}
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, j) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={headerGroups[0].headers[j].className}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export const CellIconAction = ({
  action,
  Icon,
}: {
  action: () => void;
  Icon: any;
}) => {
  return (
    <div className="cell-icon-action">
      <Icon onClick={action} />
    </div>
  );
};

export const CellAction = ({
  action,
  text,
}: {
  action: () => void;
  text: string;
}) => {
  return (
    <span className="cell-link" onClick={action}>
      <span>{text}</span>
    </span>
  );
};

export const CellLink = ({
  link,
  text,
  unread,
  icon,
  className = '',
}: {
  link: string;
  text: string;
  unread?: number;
  icon?: string;
  className?: string;
}) => {
  return (
    <a className={`cell-link ${className}`} href={link}>
      <span>{text}</span>
      {unread && unread > 0 ? (
        <div className="unread-count">
          <div className="unread-count-dot" />
          {unread} unread response{unread === 1 ? '' : 's'}
        </div>
      ) : null}
      {icon && <i className={`fa-solid fa-${icon}`} />}
    </a>
  );
};

export const CellLinkInternal = ({
  link,
  text,
  unread,
  icon,
  className = '',
}: {
  link: string;
  text: string;
  unread?: number;
  icon?: string;
  className?: string;
}) => {
  const navigate = useNavigate();

  return (
    <a
      className={`cell-link ${className}`}
      href="#"
      onClick={() => {
        navigate(link);
      }}
    >
      <span>{text}</span>
      {unread && unread > 0 ? (
        <div className="unread-count">
          <div className="unread-count-dot" />
          {unread} unread response{unread === 1 ? '' : 's'}
        </div>
      ) : null}
      {icon && <i className={`fa-solid fa-${icon}`} />}
    </a>
  );
};

export const StatusCell = ({
  label,
  className,
  bubbleColor,
  bubbleTooltip,
}: {
  label: string;
  className?: string;
  bubbleColor?: string;
  bubbleTooltip?: string;
}) => {
  return (
    <div className={`cell-status cell-status--bubble ${className}`}>
      {bubbleColor ? (
        <div
          className="bubble-dot"
          data-for="bubbleTooltip"
          data-tip={bubbleTooltip || ''}
          style={{
            backgroundColor: bubbleColor,
          }}
        />
      ) : null}
      <span>{label || 'No title set'}</span>
    </div>
  );
};

export const CellGestureDetector = ({
  label,
  subLabel,
  onClick,
  unread,
  className,
  bubbleColor,
  icon,
  bubbleTooltip,
}: {
  label: string;
  subLabel?: string;
  onClick: any;
  unread?: boolean;
  className?: string;
  bubbleColor?: string;
  icon?: string;
  bubbleTooltip?: string;
}) => {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      className={`cell-link ${className} ${icon ? 'cell-link--icon' : ''
        } ${className} ${bubbleColor ? 'cell-link--bubble' : ''}`}
      onClick={onClick}
    >
      {icon ? <i className={`fa-solid fa-${icon}`} /> : null}
      {unread ? <div className="unread-dot" /> : null}
      {bubbleColor ? (
        <div
          className="bubble-dot"
          data-for="bubbleTooltip"
          data-tip={bubbleTooltip || ''}
          style={{
            backgroundColor: bubbleColor,
          }}
        />
      ) : null}
      <span>{label || 'No title set'}</span>
      {subLabel ? (
        <>
          <span className="sub-label">{subLabel}</span>
        </>
      ) : null}
    </a>
  );
};

export const CellSession = ({
  session,
  url,
  openNewTab = false,
}: {
  session: any;
  url: string;
  openNewTab?: boolean;
}) => {
  const navigate = useNavigate();
  if (!session) {
    return (
      <div className="session-item-cell">
        <i className="session-deleted-icon fa-solid fa-circle-xmark" />
        <div className="name guest-name">Deleted</div>
      </div>
    );
  }
  const isOnline = (Date.now() - Date.parse(session.lastActivity)) / 60000 < 2;

  return (
    <div
      className="session-item-cell"
      onClick={() => {
        if (openNewTab) {
          (window as any).open(url ?? '', '_blank').focus();
        } else {
          navigate(url);
        }
      }}
    >
      <div className="unread" />
      <UserAvatar
        email={session.email ? session.email : session.gleapId}
        small
        isOnline={isOnline}
      />{' '}
      <div className={`name ${!session.userId ? 'guest-name' : ''}`}>
        {cleanupName(getSessionName(session), 30)}
      </div>
      {session.userId && (
        <div className="type-guest-tag">
          <i className="fa-solid fa-badge-check"></i>
        </div>
      )}
    </div>
  );
};

export const CellUser = ({ user, link }: { user: any, link?: string }) => {
  const navigate = useNavigate();
  let userIsOnline = false;

  if (
    user.lastSeen &&
    moment(user.lastSeen) > moment(Date.now() - 5 * 60 * 1000)
  ) {
    userIsOnline = user.available ?? true;
  }

  return (
    <div
      onClick={() => {
        if (link) {
          navigate(link);
        }
      }}
      className={`user-item-cell ${link && "user-item-cell-link"}`}>
      <UserAvatar
        email={user.email}
        imageUrl={user.profileImageUrl}
        small
        isOffline={!userIsOnline}
        isOnline={userIsOnline}
      />{' '}
      <div className="name">
        {user.firstName} {user.lastName}
      </div>
    </div>
  );
};

export const CellOrganisation = ({ organisation }: { organisation: any }) => {
  return (
    <div className="organisation-option">
      {!organisation.picture && (
        <div className="image-placeholder mr-15">
          {organisation.name && organisation.name.charAt(0).toLocaleUpperCase()}
        </div>
      )}
      {organisation.picture && (
        <img
          src={organisation.picture}
          className="mr-15"
          alt="organisationpicture"
        />
      )}
      <div className="organisation-name">{organisation.name}</div>
    </div>
  );
};

export const CellInvitedUser = ({ email }: { email: string }) => {
  return (
    <div className="user-invited-item-cell">
      <UserAvatar email={email} small />{' '}
      <a className="email" href={`mailto:${email}`}>
        {email}
      </a>
    </div>
  );
};

export const CellDropdown = ({
  menuItems,
  label,
  onUpdate,
}: {
  menuItems: any[];
  label: string;
  onUpdate: (item: any) => void;
}) => {
  return (
    <DropDown
      className="dropdown celldropdown"
      items={menuItems}
      label={label}
      onClick={(itemId) => {
        onUpdate(itemId);
      }}
    />
  );
};

export const CellText = ({
  text,
  className = '',
}: {
  text: string;
  className?: string;
}) => {
  return <div className={`cell-text ${className}`}>{text}</div>;
};

export const CellPaymentStatus = ({
  text,
  className = '',
}: {
  text: string;
  className?: string;
}) => {
  return (
    <div className="cell-badge-container">
      <div
        className={`cell-badge ${(text === 'paid' || text === 'completed') && 'cell-badge--paid'
          } ${className}`}
      >
        {text}
      </div>
    </div>
  );
};

export const CellTextIcon = ({
  text,
  icon,
  className = '',
}: {
  text?: string;
  icon: string;
  className?: string;
}) => {
  return (
    <div className={`cell-text-icon-outer`}>
      <div
        className={
          text ? `cell-text-icon ${className}` : `cell-icon-only ${className}`
        }
      >
        <i className={`fa-solid fa-${icon}`} /> {text}
      </div>
    </div>
  );
};

export const CellTextCopy = ({
  text,
  className = '',
}: {
  text: string;
  className?: string;
}) => {
  return (
    <div className={`cell-text-copy ${className}`}>
      <CopyToClipboard
        text={text}
        onCopy={() => {
          toast.success('Copied to clipboard.');
        }}
      >
        <div>
          <span>{text}</span>
          <span className="copy-link">COPY</span>
        </div>
      </CopyToClipboard>
    </div>
  );
};

export const CellTag = ({
  text,
  className,
}: {
  text: string;
  className: string;
}) => {
  return <div className={`cell-tag ${className}`}>{text}</div>;
};

export const CellVerified = ({
  verified,
  label,
}: {
  verified: boolean;
  label: string;
}) => {
  return (
    <div className="cell-verify">
      <img
        src={verified ? verifiedIcon : notVerifiedIcon}
        alt={verified ? 'Verified' : 'Not verified'}
      />
      <div className="cell-verify-text">
        <span>{label}</span>
        <span>{verified ? 'Verified' : 'Not verified'}</span>
      </div>
    </div>
  );
};

export default ListTable;
