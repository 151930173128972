import {
  IMessage,
  INotificationCenterStyles,
  NovuProvider,
  PopoverNotificationCenter,
} from '@novu/notification-center';
import { ReactComponent as GleapLogo } from 'assets/icons/GleapLogoBlack.svg';
import { ReactComponent as BellIcon } from 'assets/icons/bell-solid.svg';
import { ReactComponent as HelpCenterIconActive } from 'assets/icons/book-open-solid.svg';
import { ReactComponent as HelpCenterIcon } from 'assets/icons/circle-question-solid.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/gear-regular.svg';
import { ReactComponent as InboxIcon } from 'assets/icons/inbox-regular_new.svg';
import { ReactComponent as InboxIconActive } from 'assets/icons/inbox-solid.svg';
import { ReactComponent as KaiIcon } from 'assets/icons/kaiicon.svg';
import { ReactComponent as BotIconActive } from 'assets/icons/kaiiconhighlight.svg';
import { ReactComponent as ReportIcon } from 'assets/icons/np_poll_14229_000000.svg';
import { ReactComponent as WidgetIconActive } from 'assets/icons/np_widget_411203_FFFFFF.svg';
import { ReactComponent as OutboundIcon } from 'assets/icons/paper-plane-regular.svg';
import { ReactComponent as OutboundIconActive } from 'assets/icons/paper-plane-solid.svg';
import { ReactComponent as SettingsIconActive } from 'assets/icons/settings.svg';
import { ReactComponent as KanbanIcon } from 'assets/icons/square-kanban-solid.svg';
import { ReactComponent as RoadmapIcon } from 'assets/icons/lightbulb-duotone.svg';
import { ReactComponent as ContactsIcon } from 'assets/icons/user-group-regular_new.svg';
import { ReactComponent as ContactsIconActive } from 'assets/icons/user-group-solid.svg';
import { ReactComponent as WidgetIcon } from 'assets/icons/widget-config.svg';
import ProfileDropUp from 'components/ProfileDropUp/ProfileDropUp';
import SingleSidenavElement from 'components/Sidenav/SidenavElement/SingleSidenavElement';
import { inject, observer } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router';
import { sum } from 'services/Helper';
import { OutboundStore } from 'stores/private/OutboundStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { UsersStore } from 'stores/private/UsersStore';
import './MainNavBar.scss';

interface MainNavBarProps {
  usersStore?: UsersStore;
  projectStore?: ProjectStore;
  outboundStore?: OutboundStore;
}

const MainNavBar = ({
  usersStore,
  projectStore,
  outboundStore,
}: MainNavBarProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const userIsComplete =
    usersStore?.currentUser && usersStore.currentUser.confirmed;
  const project = projectStore?.currentProject;
  const currentUser = usersStore?.currentUser;

  const onNotificationClick = (message: IMessage) => {
    if (message?.cta?.data?.url) {
      // Ensure the path starts with a '/'
      const newPath = message.cta.data.url.startsWith('/')
        ? message.cta.data.url
        : '/' + message.cta.data.url;

      navigate(newPath);
    }
  };

  if (!userIsComplete) {
    return <></>;
  }

  const renderProjectLinks = () => {
    if (!project || !location.pathname.includes('/project')) {
      return (<>
        <div className="growing" />
        {usersStore?.celloLoaded && (
          <div className='cello-launcher'>
            <div className="sidenav-element">
              <i className="fa-solid fa-gift"></i>
              <span className="sidenav-tooltip">Refer friends, get rewards 🏆</span>
            </div>
          </div>
        )}
      </>);
    }

    const extraTypes =
      project.projectTypes
        .filter(function (projectType) {
          if (
            projectType.path === 'inquiries'
          ) {
            return false;
          }
          if (
            projectType.path === 'featurerequests'
          ) {
            return false;
          }
          return true;
        })
        .map((projectType) => {
          return projectType.type;
        }) ?? [];

    const extraPaths =
      project.projectTypes
        .filter(function (projectType) {
          if (
            projectType.path === 'inquiries'
          ) {
            return false;
          }
          if (
            projectType.path === 'featurerequests'
          ) {
            return false;
          }
          return true;
        })
        .map((projectType) => {
          return projectType.path;
        }) ?? [];

    const getCustomNotificationsCount = (key = 'FEATURE_REQUEST') => {
      var unreadCount = 0;

      for (const itemKey in projectStore.unreadItems) {
        if (
          projectStore.unreadItems[itemKey] &&
          projectStore.unreadItems[itemKey].type === key
        ) {
          unreadCount++;
        }
      }

      return unreadCount;
    };

    const getNotificationCount = () => {
      var types: string[] = [];
      for (let i = 0; i < extraTypes.length; i++) {
        types.push(extraTypes[i]);
      }

      var unreadCount = 0;

      for (const itemKey in projectStore.unreadItems) {
        if (
          projectStore.unreadItems[itemKey] &&
          types.includes(projectStore.unreadItems[itemKey].type)
        ) {
          unreadCount++;
        }
      }

      return unreadCount;
    };

    const getSurveysNotificationCount = () => {
      return sum(outboundStore?.currentProjectSurveyUnreadCount);
    };

    return (
      <>
        <div className="nav-links">
          <SingleSidenavElement
            name="Inbox"
            Icon={InboxIcon}
            IconActive={InboxIconActive}
            projectId={project.id}
            notificationCount={getCustomNotificationsCount('INQUIRY')}
            projectPath="inquiries"
            className="sidenav-element--main"
          />
          <SingleSidenavElement
            name="Tickets"
            Icon={KanbanIcon}
            IconActive={KanbanIcon}
            projectId={project.id}
            projectPath="bugs"
            extraProjectPaths={[
              ...extraPaths,
              'archive',
              'dashboard',
              'insights',
            ]}
            notificationCount={getNotificationCount()}
            className="sidenav-element--main"
          />
          <SingleSidenavElement
            name="Roadmap"
            Icon={RoadmapIcon}
            IconActive={RoadmapIcon}
            projectId={project.id}
            projectPath="featurerequests"
            notificationCount={getCustomNotificationsCount('FEATURE_REQUEST')}
            className="sidenav-element--main"
          />
          <SingleSidenavElement
            name="Outreach"
            projectId={project.id}
            Icon={OutboundIcon}
            IconActive={OutboundIconActive}
            projectPath="outbound"
            notificationCount={getSurveysNotificationCount()}
            className="sidenav-element--main"
          />
          <div className="growing" />
          <SingleSidenavElement
            name="Workflows"
            Icon={BotIconActive}
            IconActive={BotIconActive}
            projectId={project.id}
            projectPath="bots"
            className="sidenav-element--main sidenav-element--main--workflows"
          />
          <SingleSidenavElement
            name="AI Chatbot"
            projectId={project.id}
            Icon={KaiIcon}
            IconActive={KaiIcon}
            notificationCount={projectStore.contentSuggestionsCount}
            projectPath="aibot"
            className="sidenav-element--main sidenav-element--main--bot"
          />
          <SingleSidenavElement
            name="Help center"
            Icon={HelpCenterIcon}
            IconActive={HelpCenterIconActive}
            projectId={project.id}
            projectPath="helpcenter"
            className="sidenav-element--main"
          />
          <SingleSidenavElement
            name="Reports"
            Icon={ReportIcon}
            IconActive={ReportIcon}
            projectId={project.id}
            projectPath="reports/customer-support/overview"
            extraProjectPaths={['reports', 'customer-support']}
            className="sidenav-element--main"
          />
          <SingleSidenavElement
            name="Contacts"
            Icon={ContactsIcon}
            IconActive={ContactsIconActive}
            projectId={project.id}
            projectPath="sessions"
            className="sidenav-element--main"
          />
          <SingleSidenavElement
            name="Widget configuration"
            Icon={WidgetIcon}
            IconActive={WidgetIconActive}
            projectId={project.id}
            projectPath="widget"
          />
          <SingleSidenavElement
            name="Settings"
            Icon={SettingsIcon}
            IconActive={SettingsIconActive}
            projectId={project.id}
            projectPath="settings"
          />
        </div>
      </>
    );
  };

  const renderHeader = () => {
    if (true || !project || !location.pathname.includes('/project')) {
      if (project) {
        return (
          <div
            className="project-option"
            onClick={() => {
              navigate('/');
            }}
          >
            {!project.picture && (
              <div className="image-placeholder">
                {project.name && project.name.charAt(0).toLocaleUpperCase()}
              </div>
            )}
            {project.picture && <img src={project.picture} alt="projpicture" />}
            {project.name && project.name.length > 0 && (
              <span className="logo-tooltip">{project.name}</span>)}
          </div>
        );
      }

      return (
        <div className="logo">
          <GleapLogo
            onClick={() => {
              navigate('/');
            }}
          />
        </div>
      );
    }
  };

  const buildNotificationCenter = () => {
    if (!currentUser) {
      return <></>;
    }

    const notificationCenterStyles: INotificationCenterStyles = {
      notifications: {
        listItem: {
          unread: {
            '&::before': {
              background: 'linear-gradient(0deg,#374af8a3 0%,#2142E7 100%)',
            },
          },
        },
      },
    };

    return (
      <div className="notification-container">
        <NovuProvider
          subscriberId={currentUser.id}
          backendUrl={process.env.REACT_APP_NOVU_BACKEND_URL}
          socketUrl={process.env.REACT_APP_NOVU_SOCKET_URL}
          applicationIdentifier={process.env.REACT_APP_NOVU_APP_ID ?? ''}
          styles={notificationCenterStyles}
          initialFetchingStrategy={{
            fetchNotifications: true,
            fetchUnseenCount: true,
            fetchUserPreferences: true,
          }}
        >
          <PopoverNotificationCenter
            colorScheme="light"
            position="right"
            onNotificationClick={onNotificationClick}
            showUserPreferences
            footer={() => {
              return <></>;
            }}
          >
            {({ unseenCount }) => (
              <SingleSidenavElement
                name="Notifications"
                IconActive={BellIcon}
                notificationCount={unseenCount}
                readOnly
              />
            )}
          </PopoverNotificationCenter>
        </NovuProvider>
      </div>
    );
  };

  return (
    <div className="mainnavbar">
      <div className="mainnavbar--header">{renderHeader()}</div>
      {renderProjectLinks()}
      {buildNotificationCenter()}
      <ProfileDropUp />
    </div>
  );
};

export default inject(
  'usersStore',
  'projectStore',
  'outboundStore',
)(observer(MainNavBar));
