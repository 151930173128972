import { runInAction } from 'mobx';
import React, { useState } from 'react';
import Switch from 'react-switch';

const FeedbackActionEditorItemSwitch = ({
  feedbackTypeItem,
  exclusionKey,
}: {
  feedbackTypeItem: any;
  exclusionKey: any;
}) => {
  const [toggle, setToggle] = useState(
    feedbackTypeItem.excludeData &&
      feedbackTypeItem.excludeData[exclusionKey.key]
      ? true
      : false,
  );

  return (
    <div className="switch-container mb-20" key={exclusionKey.key}>
      <Switch
                  width={40}
                  onColor="#2142E7"
                  height={20}
        checkedIcon={false}
        uncheckedIcon={false}
        onChange={(checked) => {
          runInAction(() => {
            feedbackTypeItem.excludeData[exclusionKey.key] = checked;
            setToggle(checked);
          });
        }}
        checked={toggle}
      />
      <span>{exclusionKey.title}</span>
    </div>
  );
};

export default FeedbackActionEditorItemSwitch;
