import PDFFileUpload from 'components/PDFFileUpload/PDFFileUpload';
import { Title } from 'components/Title/Title';
import { inject, observer } from 'mobx-react';
import { uploadPDFAnswer } from 'services/ProjectHttpService';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './UploadPDFModal.scss';

interface UploadPDFModalProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

const UploadPDFModal = ({
  modalStore,
  projectStore,
}: UploadPDFModalProps) => {
  const toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
  });

  return (
    <div className="modal-with-title">
      <div className='modal-title-container'>
        <Title label="Upload a PDF" />
      </div>
      <div className='modal-body'>
        <PDFFileUpload afterPDFFileUpload={(file) => {
          if (!file) {
            return;
          }

          return toBase64(file).then(async (result) => {
            await projectStore?.uploadPDFToAnswers((result as string), file.name);

            modalStore!.closeModal();
          });
        }} />
      </div>
    </div>
  );
};

export default inject(
  'modalStore',
  'projectStore',
)(observer(UploadPDFModal));
