import { Editor } from '@tiptap/react';
import ActionPopover from 'components/ActionPopover/ActionPopover';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import React, { useRef, useState } from 'react';

interface IframeToolbarItemProps {
  editor: Editor | null;
  position?: 'top' | 'bottom';
}

const IframeToolbarItem = ({ editor, position = "bottom" }: IframeToolbarItemProps) => {
  const [link, setlink] = useState('');

  const popoverRef = useRef(null as any);

  const setLink = () => {
    if (!editor) return;

    // empty
    if (link === '') {
      return;
    }

    // update link
    editor!.chain().focus().setIframe({ src: link }).run();

    popoverRef?.current.close();
  };

  return (
    <ActionPopover
      ref={popoverRef}
      position={position}
      onOpen={() => {
        const previousUrl = editor!.getAttributes('link').href;
        if (previousUrl) {
          setlink(previousUrl);
        }
      }}
      child={
        <div
          className="bubble-menu-item"
          data-for="toolbarTooltip"
          data-tip="Embed iFrame"
        >
          <i className="fa-regular fa-window-restore" />{' '}
        </div>
      }
    >
      <Column>
        <TextInput
          className="mt-10"
          placeholder="Enter an iframe URL"
          value={link}
          onChange={(val) => {
            setlink(val);
          }}
        />
        <PrimaryButton
          className="mt-20"
          label="Save"
          disabled={link === ''}
          onClick={setLink}
        />
      </Column>
    </ActionPopover>
  );
};

export default IframeToolbarItem;
