import { ReactComponent as BG } from 'assets/BG.svg';
import { ReactComponent as BGClassic } from 'assets/BGclassic.svg';
import { ReactComponent as Bot } from 'assets/bot.svg';
import InfoBox from 'components/InfoBox/InfoBox';
import Loading from 'components/Loading/Loading';
import Gleap from 'gleap';
import { getLanguageProperty } from 'helper/AssignObjectKeysHelper';
import { calculateContrast, calculateShadeColor } from 'helper/Color';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ReactSVG } from 'react-svg';
import { toast } from 'react-toastify';
import { ProjectStore } from 'stores/private/ProjectStore';
import { UsersStore } from 'stores/private/UsersStore';
import './WidgetPreview.scss';
import ClassicWidgetPreview from '../ClassicWidgetPreview/ClassicWidgetPreview';

interface WidgetPreviewProps {
  projectStore?: ProjectStore;
  usersStore?: UsersStore;
  projectIdExternal?: string;
}

function WidgetPreview({
  projectStore,
  usersStore,
  projectIdExternal,
}: WidgetPreviewProps) {
  // @ts-ignore
  const params = useParams();
  const projectId = params?.projectId || projectIdExternal;
  const [isLoading, setIsLoading] = useState(false);
  const { flowConfig } = projectStore?.editingProject || {};
  const currentLang = projectStore?.currentLanguage ?? 'en';

  useEffect(() => {
    Gleap.showFeedbackButton(false);

    return () => {
      Gleap.showFeedbackButton(true);
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (projectId) {
      projectStore!.loadProjectById(projectId, false, true);
    }
    setIsLoading(false);
  }, [projectId]);

  if (isLoading || !flowConfig) {
    return <Loading />;
  }

  if (flowConfig.v !== 2) {
    return (<ClassicWidgetPreview />);
  }

  const showDemoNotice = () => {
    toast.success('You clicked your demo widget 🥳.');
  };

  const cornerRadius = flowConfig.borderRadius ?? 20;
  const backgroundColor = flowConfig.backgroundColor ?? '#ffffff';
  const contrastBackgroundColor = calculateContrast(backgroundColor);
  const subTextColor =
    contrastBackgroundColor === '#ffffff'
      ? calculateShadeColor(backgroundColor, 100)
      : calculateShadeColor(backgroundColor, -100);

  const resolveTeamMember = (memberId: any) => {
    if (!projectStore?.currentProjectUsers) {
      return null;
    }

    // Find member in project users
    const member = projectStore?.currentProjectUsers.find(
      (user) => user.id === memberId,
    );
    if (!member) {
      return null;
    }

    return {
      id: member.id,
      profileImageUrl: member.profileImageUrl,
    };
  };
  let team = (projectStore?.teamPreview ?? []).slice(0, 3);
  if (flowConfig?.visibleTeamMembers && flowConfig?.visibleTeamMembers.length > 0) {
    team = flowConfig.visibleTeamMembers
      .map((memberId) => resolveTeamMember(memberId))
      .filter((member) => member !== null) as any;
  }

  const css = `
  :root {
    --widget-header-color: ${flowConfig.headerColor ?? '#000000'};
    --widget-header-color-2: ${flowConfig.headerColor2 ?? '#ffffff'};
    --widget-header-color-3: ${flowConfig.headerColor3 ?? '#ffffff'};
    --widget-primary-color-22: ${flowConfig.color}22;
    --widget-background-color: ${flowConfig.backgroundColor ?? '#ffffff'};
    --widget-background-color-99: ${flowConfig.backgroundColor ?? '#ffffff'}99;
    --widget-background-color-EE: ${flowConfig.backgroundColor ?? '#ffffff'}EE;
    --widget-primary-color: ${flowConfig.color};
    --widget-primary-inv-color: ${calculateContrast(
    flowConfig.color ?? '#fff',
  )};
    --widget-header-inv: ${calculateContrast(
    flowConfig.headerColor ?? '#2142E7',
  )};
    --widget-bg-inv: ${calculateContrast(
    flowConfig.backgroundColor ?? '#2142E7',
  )};
  }`;

  const renderBG = () => {
    if (flowConfig.bgType === 'image') {
      if (flowConfig.bgImage && flowConfig.bgImage.length > 0) {
        return (<img src={flowConfig.bgImage} className='feedback-box-bg feedback-box-bg--image' />);
      }

      return null;
    }

    if (flowConfig.bgType === 'classic') {
      return (
        <BGClassic className={`feedback-box-bg`}
          style={{
            borderTopLeftRadius: cornerRadius,
            borderTopRightRadius: cornerRadius,
          }} />);
    }

    return (
      <BG className={`feedback-box-bg ${flowConfig.animateBG ? 'bg-animating' : ''}`}
        style={{
          borderTopLeftRadius: cornerRadius,
          borderTopRightRadius: cornerRadius,
        }} />
    );
  }

  const renderWidget = () => {
    return (<div
      className="feedback-box"
      style={{
        borderRadius: cornerRadius,
        backgroundColor,
      }}
    >
      {renderBG()}
      <div className="feedback-box-scroll" style={flowConfig.bgBlur ? {
        backdropFilter: 'blur(30px)',
        borderRadius: cornerRadius,
      } : {
        borderRadius: cornerRadius,
      }}>
        <div
          className="feedback-box-header"
          style={{
            borderTopLeftRadius: cornerRadius,
            borderTopRightRadius: cornerRadius,
          }}
        >
          {flowConfig.logo.length > 0 ? (
            <img src={flowConfig.logo} />
          ) : (
            <svg
              width="127"
              height="129"
              viewBox="0 0 127 129"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.5507 32.306C20.4495 41.0714 17.3437 52.8384 17.3438 64.1091C17.3438 75.3799 20.4497 87.1469 27.5508 95.9123C34.4039 104.372 45.6889 110.937 64.1725 110.937C83.6599 110.937 93.3637 102.356 98.4673 94.1976C102.058 88.4577 103.344 84.2626 103.805 81.4366C104.114 79.5459 105.616 77.9841 107.531 77.9841H117.938C119.853 77.9841 121.429 79.5376 121.265 81.4463C120.835 86.4687 119.175 93.7981 113.171 103.396C105.135 116.242 90.0723 128.281 64.1725 128.281C41.0305 128.281 24.5652 119.779 14.0745 106.83C3.83175 94.1866 0.000111649 78.2036 2.4398e-09 64.1092C-0.000111645 50.0147 3.83155 34.0317 14.0744 21.3884C24.0327 9.09622 39.3744 0.811764 60.7001 0.00243821C62.6145 -0.0702131 64.1725 1.49027 64.1725 3.40601V13.8123C64.1725 15.728 62.6176 17.2712 60.704 17.3608C44.2594 18.1311 33.9643 24.3893 27.5507 32.306Z"
                fill={calculateContrast(
                  flowConfig.headerColor ?? '#2142E7',
                )}
              />
              <path
                d="M126.609 43.2966C126.609 50.9596 120.397 57.1716 112.734 57.1716C105.071 57.1716 98.8594 50.9596 98.8594 43.2966C98.8594 35.6337 105.071 29.4216 112.734 29.4216C120.397 29.4216 126.609 35.6337 126.609 43.2966Z"
                fill={calculateContrast(
                  flowConfig.headerColor ?? '#2142E7',
                )}
              />
            </svg>
          )}
          {!flowConfig.hideTeam && (
            <div className="feedback-box-header-team">
              {team.map((teamUser, index) => {
                return <img key={index} src={teamUser.profileImageUrl} />;
              })}
            </div>
          )}
          <div className='preview-message preview-message--hidebot'>
            <Bot />
            <div className='preview-message-content'>
              <span>
                Hey{' '}
                {flowConfig.hideUsersName
                  ? ''
                  : usersStore?.currentUser?.firstName ?? 'there'}{' '}
              </span>
              <span className="hand">
                {flowConfig.hideWavingHandAfterName ? '' : '👋'}
              </span>
            </div>
          </div>
          <div className='preview-message'>
            <Bot />
            <div className='preview-message-content'>
              {getLanguageProperty(
                flowConfig,
                'welcomeText',
                currentLang,
                true,
              ) ?? 'How can we help?'}
            </div>
          </div>
        </div>
        <div className="feedback-box-body">
          {projectStore?.currentProject?.homeWidgets &&
            projectStore?.currentProject?.homeWidgets.map((homeWidget, key) => {
              if (homeWidget.type === 'menu') {
                const menuItem = homeWidget.config;
                return (
                  <div
                    className="feedback-box-body-item"
                    onClick={() => {
                      showDemoNotice();
                    }}
                  >
                    <ReactSVG className="feedback-box-body-item-icon" src={menuItem.icon} />
                    <div
                      className="feedback-box-body-item-title"
                    >
                      {getLanguageProperty(
                        menuItem,
                        'title',
                        currentLang,
                        true,
                      )}
                    </div>
                  </div>
                );
              }

              return null;
            })}
        </div>
        <div className="feedback-box-footer"></div>
        {!(
          flowConfig.hideNews &&
          flowConfig.hideKnowledgeBase &&
          flowConfig.hideFeatureRequests &&
          flowConfig.hideAllConversations
        ) && (
            <div
              className="tab-bar"
              style={{
                backgroundColor: backgroundColor,
                borderBottomLeftRadius: cornerRadius,
                borderBottomRightRadius: cornerRadius,
              }}
            >
              <div className="tab-bar-item active">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 576 512"
                >
                  <path
                    fill={flowConfig.color}
                    d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c.2 35.5-28.5 64.3-64 64.3H128.1c-35.3 0-64-28.7-64-64V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"
                  ></path>
                </svg>
                <span
                  style={{
                    color: flowConfig.color,
                  }}
                >
                  {getLanguageProperty(
                    flowConfig.staticTranslations,
                    'homeLabel',
                    currentLang,
                    true,
                  ) ?? 'Home'}
                </span>
              </div>
              {!flowConfig.hideAllConversations && (
                <div className="tab-bar-item false">
                  <div className="icon-container">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8V13.2C21 14.8802 21 15.7202 20.673 16.362C20.3854 16.9265 19.9265 17.3854 19.362 17.673C18.7202 18 17.8802 18 16.2 18H9.68375C9.0597 18 8.74767 18 8.44921 18.0613C8.18443 18.1156 7.9282 18.2055 7.68749 18.3285C7.41617 18.4671 7.17252 18.662 6.68521 19.0518L4.29976 20.9602C3.88367 21.2931 3.67563 21.4595 3.50054 21.4597C3.34827 21.4599 3.20422 21.3906 3.10923 21.2716C3 21.1348 3 20.8684 3 20.3355V7.8Z" stroke={contrastBackgroundColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                  </div>
                  <span
                    style={{
                      color: contrastBackgroundColor,
                    }}
                  >
                    {getLanguageProperty(
                      flowConfig,
                      'allConversationsMenuLabel',
                      currentLang,
                      true,
                    ) ?? 'Messages'}
                  </span>
                </div>
              )}
              {!flowConfig.hideNews && (
                <div className="tab-bar-item false">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path stroke={contrastBackgroundColor} d="M22 7.99992V11.9999M10.25 5.49991H6.8C5.11984 5.49991 4.27976 5.49991 3.63803 5.82689C3.07354 6.11451 2.6146 6.57345 2.32698 7.13794C2 7.77968 2 8.61976 2 10.2999L2 11.4999C2 12.4318 2 12.8977 2.15224 13.2653C2.35523 13.7553 2.74458 14.1447 3.23463 14.3477C3.60218 14.4999 4.06812 14.4999 5 14.4999V18.7499C5 18.9821 5 19.0982 5.00963 19.1959C5.10316 20.1455 5.85441 20.8968 6.80397 20.9903C6.90175 20.9999 7.01783 20.9999 7.25 20.9999C7.48217 20.9999 7.59826 20.9999 7.69604 20.9903C8.64559 20.8968 9.39685 20.1455 9.49037 19.1959C9.5 19.0982 9.5 18.9821 9.5 18.7499V14.4999H10.25C12.0164 14.4999 14.1772 15.4468 15.8443 16.3556C16.8168 16.8857 17.3031 17.1508 17.6216 17.1118C17.9169 17.0756 18.1402 16.943 18.3133 16.701C18.5 16.4401 18.5 15.9179 18.5 14.8736V5.1262C18.5 4.08191 18.5 3.55976 18.3133 3.2988C18.1402 3.05681 17.9169 2.92421 17.6216 2.88804C17.3031 2.84903 16.8168 3.11411 15.8443 3.64427C14.1772 4.55302 12.0164 5.49991 10.25 5.49991Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                  <span
                    style={{
                      color: contrastBackgroundColor,
                    }}
                  >
                    {getLanguageProperty(
                      flowConfig,
                      'newsMenuLabel',
                      currentLang,
                      true,
                    ) ?? 'News'}
                  </span>
                </div>
              )}
              {!flowConfig.hideFeatureRequests && (
                <div className="tab-bar-item false">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 18L2 22V6L9 2M9 18L16 22M9 18V2M16 22L22 18V2L16 6M16 22V6M16 6L9 2" stroke={contrastBackgroundColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>                        <span
                    style={{
                      color: contrastBackgroundColor,
                    }}
                  >
                    {getLanguageProperty(
                      flowConfig,
                      'featureRequestsMenuLabel',
                      currentLang,
                      true,
                    ) ?? 'Roadmap'}
                  </span>
                </div>
              )}
              {!flowConfig.hideKnowledgeBase && (
                <div className="tab-bar-item false">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill={contrastBackgroundColor}
                      d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464zM256 336c-18 0-32 14-32 32s13.1 32 32 32c17.1 0 32-14 32-32S273.1 336 256 336zM289.1 128h-51.1C199 128 168 159 168 198c0 13 11 24 24 24s24-11 24-24C216 186 225.1 176 237.1 176h51.1C301.1 176 312 186 312 198c0 8-4 14.1-11 18.1L244 251C236 256 232 264 232 272V288c0 13 11 24 24 24S280 301 280 288V286l45.1-28c21-13 34-36 34-60C360 159 329 128 289.1 128z"
                    />
                  </svg>
                  <span
                    style={{
                      color: contrastBackgroundColor,
                    }}
                  >
                    {getLanguageProperty(
                      flowConfig,
                      'knowledgeBaseMenuLabel',
                      currentLang,
                      true,
                    ) ?? 'Help'}
                  </span>
                </div>
              )}
            </div>
          )}
      </div>
    </div>);
  }

  return (
    <div className="widget-preview">
      <style>{css}</style>
      {flowConfig.feedbackButtonPosition &&
        flowConfig.feedbackButtonPosition.includes('CLASSIC') && (
          <div
            className="widget-bugbattle--feedback-button"
            onClick={() => {
              showDemoNotice();
            }}
          >
            <div
              className={`widget-bugbattle--feedback-button-text ${flowConfig.feedbackButtonPosition === 'BUTTON_CLASSIC_LEFT'
                ? 'widget-bugbattle--feedback-button-text--left'
                : ''
                }${flowConfig.feedbackButtonPosition === 'BUTTON_CLASSIC_BOTTOM'
                  ? 'widget-bugbattle--feedback-button-text--bottom'
                  : ''
                }`}
              style={{
                backgroundColor: flowConfig.buttonColor ?? '#2142E7',
                color: calculateContrast(flowConfig.buttonColor ?? '#2142E7'),
                borderTopLeftRadius: cornerRadius * 0.4,
                borderTopRightRadius: cornerRadius * 0.4,
              }}
            >
              {getLanguageProperty(
                flowConfig,
                'widgetButtonText',
                currentLang,
                true,
              ) ?? 'Feedback'}
            </div>
          </div>
        )}
      <div className="widget-preview-browser">
        <div className="widget-preview-browser-head">
          <div className="widget-preview-browser-head-close" />
          <div className="widget-preview-browser-head-minimize" />
          <div className="widget-preview-browser-head-maximize" />
        </div>
        <div className="widget-preview-browser-body">
          {flowConfig.v >= 2 ? renderWidget() : <InfoBox>
            Upgrade widget to v2 to see the preview 🚀
          </InfoBox>}
          {flowConfig.feedbackButtonPosition &&
            flowConfig.feedbackButtonPosition !== 'BUTTON_NONE' &&
            !flowConfig.feedbackButtonPosition.includes('CLASSIC') && (
              <div
                className={`widget-bugbattle--feedback-new-button ${flowConfig.feedbackButtonPosition === 'BOTTOM_LEFT'
                  ? 'widget-bugbattle--feedback-new-button--left'
                  : ''
                  }`}
              >
                <div
                  className="widget-bugbattle--feedback-new-button-button"
                  style={{
                    backgroundColor: flowConfig.buttonColor ?? '#2142E7',
                    color: calculateContrast(
                      flowConfig.buttonColor ?? '#2142E7',
                    ),
                  }}
                  onClick={() => {
                    showDemoNotice();
                  }}
                >
                  {flowConfig.buttonLogo && flowConfig.buttonLogo.length > 0 ? (
                    <img src={flowConfig.buttonLogo} />
                  ) : (
                    <svg className="widget-default-icon" width="145" height="144" viewBox="0 0 145 144" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M38.9534 15H105.047C113.857 15 121 22.1426 121 30.9534L121 89.5238L121 96.015L121 125.541C121 128.759 117.393 130.66 114.739 128.84L90.1188 111.968H38.9534C30.1426 111.968 23 104.826 23 96.015V30.9534C23 22.1426 30.1426 15 38.9534 15Z" fill={calculateContrast(
                        flowConfig.buttonColor ?? '#2142E7',
                      )}></path>
                    </svg>
                  )}
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

export default inject('projectStore', 'usersStore')(observer(WidgetPreview));