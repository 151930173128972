import PaddleBillingAddress from 'components/PaddleBillingAddress/PaddleBillingAddress';
import PaddleBusinessForm from 'components/PaddleBusinessForm/PaddleBusinessForm';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';
import { ModalStore } from 'stores/private/ModalStore';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { PaddleStore } from 'stores/private/PaddleStore';
import './UpdateBillingAddressModal.scss';
import { HeadLine } from 'components/HeadLine/HeadLine';
import LoadingAnimationMedium from 'components/LoadingAnimationMedium/LoadingAnimationMedium';
import Swal from 'sweetalert2';

interface UpdateBillingAddressModalProps {
  modalStore?: ModalStore;
  organisationStore?: OrganisationStore;
  paddleStore?: PaddleStore;
}

const UpdateBillingAddressModal = ({
  modalStore,
  organisationStore,
  paddleStore,
}: UpdateBillingAddressModalProps) => {
  useEffect(() => {
    paddleStore!.getCurrentCustomer();
  }, []);

  const validateForm = () => {
    const business = paddleStore?.customer?.business;
    if (business) {
      const validName = business.name && business.name.length > 0;
      const hasContacts = business.contacts && business.contacts.length > 0;
      var validContact = false;
      if (hasContacts) {
        const contact = business.contacts[0];
        const validContactName = contact.name && contact.name.length > 0;
        const validContactEmail = contact.email && contact.email.length > 0;
        validContact = validContactName && validContactEmail;
      }

      if (!validName || !validContact) {
        return false;
      }
    }
    return true;
  };

  const renderEditor = () => {
    return (
      <>
        <PaddleBusinessForm
          value={paddleStore?.customer?.business}
          onChange={(updatedData) => {
            runInAction(() => {
              if (!paddleStore?.customer?.business) {
                paddleStore!.customer.business = {};
              }
              paddleStore!.customer.business = updatedData;
            });
          }}
        />
        <div className="billing-input-options mt-30">
          <PaddleBillingAddress
            value={paddleStore?.customer?.address ?? {}}
            onChange={(updatedData) => {
              runInAction(() => {
                if (!paddleStore?.customer?.address) {
                  paddleStore!.customer.address = {};
                }
                paddleStore!.customer.address = updatedData;
              });
            }}
          />
        </div>
        <div className="centered">
          <PrimaryButton
            className="mt-20"
            isLoading={paddleStore?.savingCustomer}
            label="Update billing address"
            onClick={async () => {
              const formValid = validateForm();
              if (!formValid) {
                Swal.fire({
                  text: 'Please enter all required business details.',
                  showCancelButton: false,
                  confirmButtonText: `Ok`,
                });
                return;
              }

              const success = await paddleStore!.saveCurrentCustomer();
              if (success) {
                modalStore!.closeModal();
              }
            }}
          />
        </div>
      </>
    );
  };

  return (
    <div className="update-billing-address-modal">
      <HeadLine
        className="centered mb-20"
        title="Update billing address"
        subtitle="Updates will be effective with the next invoice."
      />
      {paddleStore?.loadingCustomer ? (
        <div className="centered">
          <LoadingAnimationMedium />
        </div>
      ) : (
        renderEditor()
      )}
    </div>
  );
};

export default inject(
  'modalStore',
  'organisationStore',
  'paddleStore',
)(observer(UpdateBillingAddressModal));
