import { ReactComponent as GleapLogo } from 'assets/icons/GleapGray.svg';
import { inject, observer } from 'mobx-react';
import { useNavigate, useParams } from 'react-router';
import { sendPostMessage } from 'services/Helper';
import { SharedBoardStorePublic } from 'stores/public/SharedBoardStorePublic';
import './FeatureRequestsHeadLine.scss';
import { useEffect, useState } from 'react';

interface FeatureRequestsHeadLineProps {
  sharedBoardStorePublic?: SharedBoardStorePublic;
  headerConfig: any;
  page: string;
  isAppWidget: boolean,
  isEmbedded: boolean
}

const FeatureRequestsHeadLine = ({
  sharedBoardStorePublic,
  headerConfig,
  page,
  isAppWidget,
  isEmbedded
}: FeatureRequestsHeadLineProps) => {
  const { boardShareToken } = useParams();
  const navigate = useNavigate();

  const navigateTo = (type) => {
    sendPostMessage(
      JSON.stringify({
        type: 'app',
        name: 'data',
        data: {
          tab: type,
        },
      }),
    );

    var route = '';
    const params = new URLSearchParams(window.location.search);
    const widgetApp = params.get('widgetApp');

    if (type === 'featurerequests') {
      route = '';
    } else if (type === 'roadmap') {
      route = 'roadmap';
    } else if (type === 'news') {
      route = 'news';
    }

    navigate(`/sharedboard/${boardShareToken}/${route}${widgetApp ? `?widgetApp=${widgetApp}` : ""}`);
  };

  useEffect(() => {
    changeFavicon('32x32', headerConfig?.roadmapFavicon);
    changeFavicon('16x16', headerConfig?.roadmapFavicon);
  }, [headerConfig?.roadmapFavicon]);

  const changeFavicon = (size, newHref) => {
    try {
      let link: any = document.querySelector(`link[rel='icon'][sizes='${size}']`);
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        link.type = 'image/png';
        link.sizes = size;
        document.head.appendChild(link);
      }
      link.href = newHref;
    } catch (error) {
      console.log('Error changing favicon', error);
    }
  };

  return (
    <div className="feature-requests-headline">
      <div className="feature-requests-headline--inner">
        <a href="#" onClick={() => {
          if (headerConfig?.link?.length > 0) {
            sendPostMessage(
              JSON.stringify({
                type: 'app',
                name: 'openLink',
                data: {
                  url: headerConfig.link,
                },
              }),
            );
          }
        }}>
          {headerConfig?.logo && headerConfig?.logo !== '' ? (
            <img className="shared-logo" src={headerConfig?.logo} alt="Logo" />
          ) : (
            <GleapLogo className="shared-logo shared-logo--default" />
          )}
        </a>
        <div className="links">
          {sharedBoardStorePublic?.showSharedRoadmapTab && (
            <div
              className={page === 'roadmap' ? 'active' : ''}
              onClick={() => {
                navigateTo(`roadmap`);
              }}
            >
              <i className="fa-regular fa-map-location"></i>{' '}
              {sharedBoardStorePublic?.translateText('roadmap')}
            </div>
          )}
          {sharedBoardStorePublic?.showSharedFeatureRequestTab && (
            <div
              className={page === 'featurerequests' ? 'active' : ''}
              onClick={() => {
                navigateTo(`featurerequests`);
              }}
            >
              <i className="fa-regular fa-lightbulb"></i>{' '}
              {sharedBoardStorePublic?.translateText('featureRequests')}
            </div>
          )}
          {!isAppWidget && sharedBoardStorePublic?.showSharedNewsTab && (
            <div
              className={page === 'news' ? 'active' : ''}
              onClick={() => {
                navigateTo(`news`);
              }}
            >
              <i className="fa-regular fa-megaphone"></i>{' '}
              {sharedBoardStorePublic?.translateText('news')}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default inject('sharedBoardStorePublic')(
  observer(FeatureRequestsHeadLine),
);
