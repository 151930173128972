import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper';
import InfoBox from 'components/InfoBox/InfoBox';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import ProjectLanguageDropdown from 'components/ProjectLanguageDropdown/ProjectLanguageDropdown';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import TextInput from 'components/TextInput/TextInput';
import {
  getLanguageProperty,
  setLanguageProperty,
} from 'helper/AssignObjectKeysHelper';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { staticTranslationsGrouped } from 'stores/Translations';
import { ProjectStore } from 'stores/private/ProjectStore';
import './StaticTranslations.scss';

interface StaticTranslationsProps {
  projectStore?: ProjectStore;
}

const StaticTranslations = ({ projectStore }: StaticTranslationsProps) => {
  const currentLanguage = projectStore?.currentLanguage;
  const flowConfig = projectStore?.editingProject?.flowConfig;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    projectStore?.setEditingProject();
  }, [projectStore?.currentProject, projectStore?.flowConfig]);

  const saveFlowConfig = async () => {
    setIsLoading(true);

    await projectStore?.saveEditingProject({
      flowConfig: { staticTranslations: flowConfig.staticTranslations },
    });

    setIsLoading(false);
  };

  const translationEditorForLang = () => {
    return (
      <div key={currentLanguage}>
        <div className="translation-table">
          {staticTranslationsGrouped.map((group) => {
            return (
              <ComponentWrapper title={group.name}>
                {group.translations.map((translation) => {
                  return (
                    <div
                      key={`${currentLanguage}-${translation.key}`}
                      className="translation-editor-row"
                    >
                      <div className="translation-editor-cell">
                        {translation.label}
                      </div>
                      <div className="translation-editor-cell">
                        <TextInput
                          name={translation.key}
                          placeholder="..."
                          type="text"
                          error=""
                          value={getLanguageProperty(
                            flowConfig,
                            `staticTranslations.${translation.key}`,
                            currentLanguage,
                          )}
                          onChange={(text) => {
                            setLanguageProperty(
                              flowConfig,
                              `staticTranslations.${translation.key}`,
                              currentLanguage,
                              text,
                            );
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </ComponentWrapper>
            );
          })}
        </div>
      </div>
    );
  };

  if (!flowConfig) {
    return <></>;
  }

  return (
    <PageContainer>
      <PageHeadLine title="Static translations">
        <div className="language-translation-selector">
          <div className="language-translation-selector-label">
            Edit translations for:
          </div>
          <SizedContainer size={ContainerSizes.S}>
            <ProjectLanguageDropdown />
          </SizedContainer>
        </div>
        <PrimaryButton
          label="Save"
          isLoading={isLoading}
          onClick={saveFlowConfig}
        />
      </PageHeadLine>
      <PageContent hasTitle>
        <InfoBox className="mb-30">
          <b>New translation editor</b>
          <br />
          This page allows you to edit static translations for your project.
          Static translations are used for things like button labels, alerts,
          page titles, etc.
          <br />
          If you want to edit translations for your dynamic content (like forms,
          bots, surveys, etc.) you can now do so directly in the content editor.
        </InfoBox>
        {translationEditorForLang()}
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore')(observer(StaticTranslations));
