import { ConditionTagStatic } from 'components/ConditionTagStatic/ConditionTagStatic';
import { OutboundCondition } from 'models/OutboundCondition';
import './ConditionDisplay.scss';

interface ConditionDisplayProps {
    conditions: OutboundCondition[];
    streamedEventKeys?: any[];
    userProperties?: any[];
    className?: string;
}

const ConditionDisplay = ({ conditions, userProperties = [], streamedEventKeys = [], className = "mb-10" }: ConditionDisplayProps) => {
    return <>{conditions.map((condition, index) => {
        const hasNext = conditions.length > index + 1;
        return (
            <>
                <ConditionTagStatic
                    streamedEventKeys={streamedEventKeys}
                    userProperties={userProperties}
                    key={`condition-${condition.event}-${index}`}
                    className={className}
                    value={condition} />
                {hasNext && <div className="condition-picker-conditions-operator">and</div>}
            </>
        );
    })}</>;
};

export default ConditionDisplay;
