import { Editor } from '@tiptap/react';
import ActionPopover from 'components/ActionPopover/ActionPopover';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import { ReactComponent as ButtonIcon } from '../../../../../assets/icons/button_icon.svg';
import React, { useRef, useState } from 'react';
import { TextSelection } from '@tiptap/pm/state';

interface ButtonToolbarItemProps {
  editor: Editor | null;
  position?: 'top' | 'bottom';
}

const ButtonToolbarItem = ({
  editor,
  position = 'bottom',
}: ButtonToolbarItemProps) => {
  const [link, setlink] = useState('');

  const popoverRef = useRef(null as any);

  const isActive = editor?.isActive('link', { linktype: 'button' });

  const setLink = () => {
    if (!editor) return;

    // Empty
    if (link === '') {
      editor!.chain().focus().extendMarkRange('link').unsetLink().run();
      return;
    }

    editor
      .chain()
      .focus()
      .insertContent({
        type: 'text',
        text: '\u200B\u200B',
        marks: [
          {
            type: 'link',
            attrs: {
              href: link,
              linktype: 'button',
            },
          },
        ],
      })
      .run();
    
    const { tr } = editor.state;
    const pos = tr.selection.from - 1;
    tr.setSelection(TextSelection.create(tr.doc, pos));
    editor.view.dispatch(tr);

    // Replace empty <a> tags with <p> tags cause it takes the button style into the next line
    editor.view.dom.addEventListener('keydown', (event) => {
      if (event.key === 'Enter') {
        let htmlContent = editor.getHTML();
        htmlContent = replaceEmptyLinks(htmlContent);
        editor.commands.setContent(htmlContent);
      }
    });

    popoverRef?.current.close();
  };

  function replaceEmptyLinks(html) {
    const regex = /<a [^>]*>(\s|&nbsp;|&#8203;|​)*<\/a>/g;
  
    return html.replace(regex, '');
  }

  return (
    <ActionPopover
      className="ml-5 mr-5"
      ref={popoverRef}
      position={position}
      ignoreOnClick={isActive}
      onOpen={() => {
        const previousUrl = editor!.getAttributes('link').href;
        if (previousUrl) {
          setlink(previousUrl);
        }
      }}
      child={
        <div
          data-for="toolbarTooltip"
          data-tip="Insert button"
          className={'bubble-menu-item'}
          onClick={() => {
            if (isActive) {
              editor?.chain().focus().unsetLink().run();
            }
          }}
        >
          {isActive ? (
            <ButtonIcon className="bubble-menu-item-icon isActive" />
          ) : (
            <ButtonIcon className="bubble-menu-item-icon" />
          )}
        </div>
      }
    >
      <Column>
        <TextInput
          className="mt-10"
          placeholder="Enter a button link"
          value={link}
          onChange={(val) => {
            setlink(val);
          }}
        />
        <PrimaryButton
          className="mt-20"
          label="Save"
          disabled={link === ''}
          onClick={setLink}
        />
      </Column>
    </ActionPopover>
  );
};

export default ButtonToolbarItem;
