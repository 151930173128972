import classNames from 'classnames';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { languages } from 'constants/Languages';
import { convertTipTapToHtml } from 'helper/TipTapHelper';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Outbound } from 'models/Outbound';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import { OutboundStore } from 'stores/private/OutboundStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './TooltipsConfiguration.scss';

interface TooltipsConfigurationProps {
  outboundRule: Outbound;
  outboundStore?: OutboundStore;
  projectStore?: ProjectStore;
}

const TooltipsConfiguration = ({
  outboundRule,
  outboundStore,
  projectStore,
}: TooltipsConfigurationProps) => {
  const { projectId, outboundId } = useParams();
  const popupRef = useRef(null as Window | null);
  const currentProject = projectStore?.currentProject;
  const messageContainerClassName = classNames({
    'tours-container': true,
  });
  const availableLanguages = languages.filter((language) =>
    currentProject?.localizations.find(
      (item) => item.language === language.code,
    ),
  );

  const steps = outboundRule?.config?.steps ?? [];

  const sendMessage = (data: any) => {
    if (popupRef.current) {
      popupRef.current.postMessage(
        JSON.stringify({
          ...data,
          type: 'admin',
        }),
        '*',
      );
    }
  };

  const receiveMessage = (event) => {
    try {
      const data = JSON.parse(event.data);
      // Respond with a 'pong' message
      if (data.type === 'admin') {
        if (data.name === 'init') {
          const token = localStorage.getItem('@bbtoken');

          sendMessage({
            name: 'load',
            data: {
              outboundRule,
              token,
              availableLanguages,
              type: 'tooltips',
            },
          });
        }

        if (data.name === 'smartlink-search') {
          projectStore!
            .searchSmartLinks(data.data.searchTerm, data.data.lang)
            .then((preparedArticles) => {
              sendMessage({
                name: 'smartlink-search-result',
                data: {
                  links: preparedArticles ?? [],
                },
              });
            });
        }

        if (data.name === 'save') {
          runInAction(() => {
            var steps = data.data.steps;

            // Resolve tiptap to html.
            for (let i = 0; i < steps.length; i++) {
              if (steps[i] && steps[i].message?.localized) {
                steps[i].html = {
                  localized: {},
                };

                const langKeys = Object.keys(steps[i].message.localized);
                for (let j = 0; j < langKeys.length; j++) {
                  steps[i].html.localized[langKeys[j]] = convertTipTapToHtml({
                    content: steps[i].message.localized[langKeys[j]],
                  });
                }
              }
            }

            outboundRule.config!.steps = steps;

            if (data?.data?.startURL?.length > 0) {
              outboundRule.config!.startURL = data.data.startURL;
            }

            // Save.
            outboundStore!.updateOutboundRule(outboundRule?.id, outboundRule);

            // Close popup.
            if (popupRef.current) {
              popupRef.current.close();
            }
          });
        }
      }
    } catch (e) {}
  };

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);

    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, []);

  const openProductTourEditor = () => {
    popupRef.current = window.open(
      `https://app.gleap.io/projects/${projectId}/outbound/${outboundId}/starttooltips`,
      'gleapPopup',
      'width=' +
        window.screen.width * 0.8 +
        ',height=' +
        window.screen.height * 0.8 +
        ',scrollbars=yes,resizable=yes,location=no',
    );
  };

  return (
    <div className={messageContainerClassName}>
      <div className="options-group">
        <div className="options-group-header">
          Tooltip group
          {steps?.length > 0 && (
            <PrimaryButton
              label="Edit tooltips"
              onClick={() => {
                openProductTourEditor();
              }}
            />
          )}
        </div>
        {steps?.length === 0 ? (
          <div className="product-tour-cont-start">
            <PrimaryButton
              className="primary-button"
              label="Add tooltips"
              icon="play"
              iconSideRight={true}
              onClick={() => {
                openProductTourEditor();
              }}
            />
          </div>
        ) : (
          <div className="tooltipstep-container-full">
            <div className="tooltipstep">
              {steps.map((step, index) => {
                return (
                  <div key={index} className={`tooltipstep-cont`}>
                    <Tooltip
                      key={index}
                      tooltip={step}
                      onClick={() => {
                        openProductTourEditor();
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default inject(
  'outboundStore',
  'projectStore',
)(observer(TooltipsConfiguration));
