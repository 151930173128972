import { inject, observer } from 'mobx-react';
import { ProjectStore } from 'stores/private/ProjectStore';
import './AppWidgetItem.scss';
import InfoBox from 'components/InfoBox/InfoBox';
import { getLanguageProperty, getProperty, setLanguageProperty } from 'helper/AssignObjectKeysHelper';
import TextInput from 'components/TextInput/TextInput';
import LinkButton from 'components/LinkButton/LinkButton';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { useEffect, useState } from 'react';
import UserTypeDropDown from 'components/UserTypeDropDown/UserTypeDropDown';
import ConditionPicker from 'components/ConditionPicker/ConditionPicker';
import { audienceOptions } from 'pages/private/ProjectOutbounds/ProjectOutboundConfiguration/components/OutboundTriggerConfiguration/OutboundTriggerConfiguration';

interface AppWidgetItemProps {
  projectStore?: ProjectStore;
  appWidget: any;
  onSave(menuItem): any;
  onDelete(): any;
}

const AppWidgetItem = ({
  projectStore,
  appWidget,
  onSave,
  onDelete,
}: AppWidgetItemProps) => {
  const [initalMenuItem, setInitalMenuItem] = useState(null as any);
  const [initialAudience, setInitialAudience] = useState(null as any);
  const [initialConditions, setInitialConditions] = useState([] as any[]);

  useEffect(() => {
    setInitalMenuItem(JSON.parse(JSON.stringify(appWidget?.config ?? {})));
    setInitialAudience(appWidget?.targetAudience);
    setInitialConditions(appWidget?.conditions ?? []);
  }, []);

  const app = initalMenuItem?.app;
  const currentLang = projectStore?.currentLanguage ?? 'en';

  const renderContentForType = () => {
    if (app === "LATEST_NEWS") {
      return (<InfoBox>
        This widget will show the latest news item. The widget only shows up if there is at least one news item.
      </InfoBox>);
    }

    if (app === "HELP_CENTER_SEARCH") {
      return (
        <>
          <InfoBox>
            This widget will a help center search bar as well as the latest help articles. The widget only shows up if there is at least one help article.
          </InfoBox>
          <TextInput
            name="Search label"
            className="mt-20"
            localizedable
            placeholder=""
            type="text"
            error=""
            value={getLanguageProperty(initalMenuItem, 'label', currentLang)}
            translationObject={getProperty(initalMenuItem, 'label')}
            label="Search label"
            onChange={(text) => {
              let updateItem = JSON.parse(JSON.stringify(initalMenuItem));
              setLanguageProperty(updateItem, 'label', currentLang, text);
              setInitalMenuItem(updateItem);
            }}
          />
        </>
      );
    }

    return null;
  }

  return (<>
    {renderContentForType()}
    <div className="input-label mb-10 mt-30">Audience rules</div>
    <div className="audience-selection">
      <div className='audience-dropdown'>
        <UserTypeDropDown
          onValueChanged={(value) => {
            setInitialAudience(value.value);
          }}
          value={audienceOptions.find(({ value }) => value === initialAudience)}
          options={audienceOptions}
        />
      </div>
      <ConditionPicker
        type='audience'
        conditions={initialConditions}
        onChange={(conditions) => {
          setInitialConditions(conditions);
        }}
        streamedEventKeys={projectStore?.streamedEventKeys ?? []}
      />
    </div>
    <div className="form-widget-buttons mt-30">
      <LinkButton
        icon="trash"
        className="danger"
        iconSideRight={false}
        label="Delete"
        onClick={() => {
          onDelete();
        }}
      />
      <PrimaryButton
        label="Save"
        onClick={() => {
          onSave({
            type: 'app',
            config: initalMenuItem,
            targetAudience: initialAudience,
            conditions: initialConditions,
          });
        }}
      />
    </div>
  </>)
};

export default inject('projectStore')(observer(AppWidgetItem));
