import InfoBox from 'components/InfoBox/InfoBox';
import TextInput from 'components/TextInput/TextInput';
import Gleap from 'gleap';
import { checkIntegrationData } from 'helper/Integration';
import { inject, observer } from 'mobx-react';
import { INTEGRATION } from 'models/Integration';
import { useEffect, useState } from 'react';
import { ProjectStore } from 'stores/private/ProjectStore';

interface StripeConfigurationProps {
  integrationID: string;
  index: number;
  projectStore?: ProjectStore;
  onChange: (data: any) => any;
}

const StripeConfiguration = ({
  integrationID,
  index,
  projectStore,
  onChange,
}: StripeConfigurationProps) => {
  const project = projectStore?.currentProject;
  const [apiKey, setApiKey] = useState('');

  useEffect(() => {
    if (project) {
      initialize();
    }
  }, [project]);

  useEffect(() => {
    saveIntegrationInfo();
  }, [apiKey]);

  const initialize = async () => {
    if (
      !checkIntegrationData({
        project,
        integrationName: INTEGRATION.STRIPE,
        integrationID,
        actionIndex: index,
      })
    ) {
      return;
    }

    const integrationInfo =
      project?.integrations?.stripe[integrationID]?.actions[index]
        ?.integrationInfo;

    if (integrationInfo && integrationInfo.apiKey) {
      setApiKey(integrationInfo.apiKey);
    }
  };

  const saveIntegrationInfo = () => {
    const integrationInfo = {
      apiKey,
    };

    onChange(integrationInfo);
  };

  return (
    <div className="configuration-container mt-30">
      <TextInput
        error=""
        className="mb-15"
        placeholder={apiKey?.length > 0 ? "**************************" : "Stripe API key (with read only access)"}
        label="Stripe API key"
        initalValue=""
        helpAction={() => {
          Gleap.openHelpCenterArticle("61", false);
        }}
        onChange={(val) => {
          setApiKey(val);
        }}
      />
      <a href="#" className="text-link" onClick={() => {
        Gleap.openHelpCenterArticle("61", false);
      }}>Learn how to connect Gleap with Stripe.</a>
    </div>
  );
};

export default inject('projectStore')(observer(StripeConfiguration));
