import './OutboundMessagePreview.scss';
import { Outbound } from 'models/Outbound';
import { ProjectStore } from 'stores/private/ProjectStore';
import { inject, observer } from 'mobx-react';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { truncateString } from 'components/FeedbackUserFilter/FeedbackUserFilter';
import EmailTemplate from 'components/EmailTemplate/EmailTemplate';
import WidgetButtonPreview from 'components/WidgetButtonPreview/WidgetButtonPreview';
import { getLanguageProperty } from 'helper/AssignObjectKeysHelper';
import { convertTipTapToPlainText } from 'helper/TipTapHelper';
import TipTapPreview from 'components/Editors/RichTextEditor/TipTapPreview';

interface OutboundMessagePreviewProps {
  outbound?: Outbound;
  projectStore?: ProjectStore;
}

export const OutboundMessagePreview = ({
  outbound,
  projectStore,
}: OutboundMessagePreviewProps) => {
  const currentLang = projectStore?.currentLanguage ?? 'en';

  const senderId = outbound?.sender;
  const sender = projectStore?.currentProjectUsers.find(
    (o) => o.id === senderId,
  );
  const flowConfig = projectStore?.flowConfig;
  if (!flowConfig) {
    return <></>;
  }

  if (outbound?.type === 'EMAIL') {
    return (
      <div className="outbound-email-composer">
        <div className="widget-preview">
          <div className="widget-preview-browser">
            <div className="widget-preview-browser-head">
              <div className="widget-preview-browser-head-close" />
              <div className="widget-preview-browser-head-minimize" />
              <div className="widget-preview-browser-head-maximize" />
            </div>
            <div className="widget-preview-browser-body">
              <div className="widget-preview-browser-body-header">
                <span>RE:</span>{' '}
                {getLanguageProperty(outbound, 'subject', currentLang)}
              </div>
              <EmailTemplate viewOnly templateId={outbound.emailTemplate}>
                <TipTapPreview
                  className='remove-padding'
                  content={getLanguageProperty(
                    outbound,
                    'message',
                    currentLang,
                  )}
                />
              </EmailTemplate>
            </div>
          </div>
        </div>
      </div>
    );
  }

  var message = getLanguageProperty(outbound, 'message', currentLang);

  if (typeof message !== 'string') {
    message = convertTipTapToPlainText({ content: message });
  }

  return (
    <div className="outbound-message-container">
      <div className="outbound-message-item">
        <UserAvatar
          small
          email={sender?.email}
          imageUrl={sender?.profileImageUrl}
        />
        <div className="outbound-message-item-container">
          <div className="outbound-message-item-sender">
            {sender?.firstName} {sender?.lastName}
          </div>
          <div className="outbound-message-item-content">
            {message ? truncateString(message, 70) : '--'}
          </div>
        </div>
      </div>
      <WidgetButtonPreview />
    </div>
  );
};

export default inject('projectStore')(observer(OutboundMessagePreview));
