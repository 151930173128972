import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';
import { PaddleStore } from 'stores/private/PaddleStore';
import './PaddleDefaultCheckoutPage.scss';

interface PaddleDefaultCheckoutPageProps {
  paddleStore?: PaddleStore;
}

const PaddleDefaultCheckoutPage = ({ paddleStore }: PaddleDefaultCheckoutPageProps) => {
  useEffect(() => {
    const parsedParams = new URLSearchParams(window.location.search);
    if (parsedParams) {
      const transactionId = parsedParams.get('_ptxn');
      if (transactionId) {
        paddleStore!.checkoutForTransaction(transactionId);
      }
    }
  }, []);

  return (
    <div className="default-checkout-page"></div>
  );
};

export default inject('paddleStore')(observer(PaddleDefaultCheckoutPage));
