import UserAvatar from 'components/UserAvatar/UserAvatar';
import { getDataDescription } from 'helper/FormDataHelper';
import TimeAgo from 'react-timeago';
import { Bug } from '../../models/Bug';
import './InboxItem.scss';
import moment from 'moment';
import { cleanupName } from 'components/FeedbackUserFilter/FeedbackUserFilter';
import { getSessionName } from 'services/GuestNameHelper';
import PublicSkeleton from 'components/Skeletons/PublicSkeleton';
import { convertTipTapToHtml } from 'helper/TipTapHelper';
import { PropertyStore } from 'stores/private/PropertyStore';
import { inject, observer } from 'mobx-react';

export const getDescription = (args: {
  item: any;
  propertyStore?: PropertyStore;
}) => {
  const { item, propertyStore } = args;

  var prefix = '';

  if (
    item.latestComment?.type === 'NOTE' ||
    item.latestComment?.type === 'TEXT'
  ) {
    prefix = 'Team: ';
  }

  if (item.latestComment?.data && item.latestComment?.data.text) {
    return prefix + item.latestComment?.data.text;
  }

  if (item.latestComment?.data?.showReplyTimes) {
    return 'Sent availability 🕣';
  }

  if (item.latestComment?.data?.content) {
    var content = item.latestComment?.data?.content;
    if (content.localized) {
      const keys = Object.keys(content.localized);
      content = content.localized[keys.includes('en') ? 'en' : keys[0]];
    }

    if (typeof content === 'string') {
      return prefix + stripHtml(content);
    } else {
      try {
        const html = convertTipTapToHtml({
          content: content,
        });

        return prefix + stripHtml(html);
      } catch (exp) {}
    }
  }

  if (item.form?.description?.value) {
    return item.form?.description?.value.substring(0, 80);
  }

  return getDataDescription({
    data: item,
    maxLength: 100,
    propertyStore,
    feedbackType: item.type,
  });
};

export function stripHtml(html) {
  let tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
}

interface InboxItemProps {
  item: Bug;
  onClick?: any;
  active?: boolean;
  propertyStore?: PropertyStore;
}

const InboxItem = ({
  item,
  onClick,
  active,
  propertyStore,
}: InboxItemProps) => {
  const dueDate = item.dueDate ? moment(item.dueDate) : null;
  const isDue =
    item.status !== 'DONE' && dueDate
      ? dueDate.isBefore(moment(), 'date')
      : false;
  const isDueToday =
    item.status !== 'DONE' && dueDate
      ? dueDate.isSame(moment(), 'date')
      : false;

  const renderDueDate = () => {
    if (!dueDate) {
      return null;
    }

    return (
      <div
        className={`due-date ${isDue && 'due-date--isdue'} ${
          isDueToday && 'due-date--isduetoday'
        }`}
      >
        <i className="fa-solid fa-clock-three" />
      </div>
    );
  };
  const desc = getDescription({ item, propertyStore }).substring(0, 100);

  const renderCommentPreview = () => {
    return (
      <div className="inbox-item-body">
        <div className="LinesEllipsis">{desc}</div>
        <TimeAgo
          date={item.lastNotification ?? item.createdAt}
          formatter={(value, unit, suffix) => {
            return `${value}${unit.charAt(0)}`;
          }}
        />
      </div>
    );
  };

  const isOnline = item?.session?.lastActivity
    ? (Date.now() - Date.parse(item?.session?.lastActivity)) / 60000 < 2
    : false;

  const isSnoozed = item.status === 'SNOOZED' && item.snoozedUntil;
  const unreadSnoozed =
    !isSnoozed && item.notificationsUnread && item.snoozedUntil;
  const unread = !isSnoozed && item.notificationsUnread && !item.snoozedUntil;

  const slaBreached = item.slaBreached ?? false;

  return (
    <div
      className={`inbox-item ${active ? 'inbox-item--active' : ''} ${
        unread ? 'inbox-item--unread' : ''
      } ${unreadSnoozed ? 'inbox-item--unread-snoozed' : ''} ${
        isSnoozed ? 'inbox-item--snoozed' : ''
      } ${slaBreached ? 'inbox-item--slabreached' : ''}`}
      onClick={() => {
        if (onClick) {
          onClick(item);
        }
      }}
    >
      <div className="inbox-item-header">
        <div className="inbox-item-header-userinfo">
          <UserAvatar
            isOnline={isOnline}
            email={
              item.session?.email ? item.session?.email : item.session?.gleapId
            }
          />
          <div className="userinfo">
            {cleanupName(getSessionName(item.session), 20)}
          </div>
          {renderDueDate()}
          {item.processingUser && (
            <div className="user-processing">
              <UserAvatar
                small
                email={item.processingUser.email}
                imageUrl={item.processingUser.profileImageUrl}
              />
            </div>
          )}
          {(isSnoozed || unreadSnoozed) && (
            <div className="snoozed-badge">
              {isSnoozed && (
                <span className="time">
                  {moment(item.snoozedUntil).format(
                    moment(item.snoozedUntil).isSame(moment(), 'day')
                      ? 'h:mm A'
                      : 'MMM DD',
                  )}
                </span>
              )}
              <i className="unread-snoozed-icon fa-solid fa-moon" />
            </div>
          )}
          {slaBreached && (
            <div className="sla-escalated">
              <i className="fa-solid fa-triangle-exclamation" />
            </div>
          )}
        </div>
      </div>
      {renderCommentPreview()}
    </div>
  );
};

export default inject('propertyStore')(observer(InboxItem));

export const InboxItemSkeleton = () => {
  return (
    <div className="inbox-item inbox-item--skeleton">
      <div className="inbox-item-header">
        <div className="inbox-item-header-userinfo">
          <PublicSkeleton
            width="26px"
            height="26px"
            style={{
              borderRadius: '100%',
            }}
          />
          <div className="userinfo">
            <PublicSkeleton height="18px" width="160px" />
          </div>
        </div>
      </div>
      <div className="inbox-item-body">
        <PublicSkeleton
          height="14px"
          width="130px"
          style={{ marginLeft: '5px' }}
        />
      </div>
    </div>
  );
};
