import { truncateString } from 'components/FeedbackUserFilter/FeedbackUserFilter';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { BugStore } from 'stores/private/BugStore';
import { ProjectStore } from 'stores/private/ProjectStore';

interface TicketAssignTeamProps {
  bugStore?: BugStore;
  projectStore?: ProjectStore;
}

const TicketAssignTeam = ({
  bugStore,
  projectStore,
}: TicketAssignTeamProps) => {
  const bug = bugStore?.currentBug;
  const [selectedTeam, setSelectedTeam] = useState(undefined as any);

  useEffect(() => {
    if (bug?.processingTeam) {
      // Find the team in the list of teams
      const team = projectStore?.currentProjectTeams?.find(
        (team) => team._id === bug?.processingTeam,
      );

      setSelectedTeam(team);
    } else {
      setSelectedTeam(undefined);
    }
  }, [bug?.processingTeam]);

  if (!bug || !projectStore?.currentProjectTeams) {
    return <></>;
  }

  return (
    <div className="user-filter">
      <Select
        className="dropdown-selection"
        classNamePrefix="dropdown-selection"
        defaultValue={selectedTeam}
        value={selectedTeam}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option._id}
        placeholder={
          <div className="user-selection-placeholder">
            <div className="user-selection-placeholder-avatar" />
            <div className="user-selection-placeholder-label">Not assigned</div>
          </div>
        }
        isClearable
        components={{
          SingleValue: (option: any) => {
            return <>{truncateString(option.data.name, 23)}</>;
          },
          Option: (option: any) => {
            return (
              <div
                className="option-list-item user-option-list-item"
                onClick={() => {
                  if (option.selectOption) {
                    option.selectOption(option.data);
                  }
                }}
              >
                {option.data.name}
              </div>
            );
          },
        }}
        onChange={(selectedProcessingTeam: any) => {
          if (selectedProcessingTeam && selectedProcessingTeam._id) {
            bugStore!.updateBug(bug.id, {
              processingTeam: selectedProcessingTeam._id,
            });
          } else {
            bugStore!.updateBug(bug.id, {
              processingTeam: '',
            });
          }
        }}
        options={projectStore?.currentProjectTeams}
      />
    </div>
  );
};

export default inject('bugStore', 'projectStore')(observer(TicketAssignTeam));
