import LinkButton from 'components/LinkButton/LinkButton';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SizedContainer, {
  ContainerSizes
} from 'components/SizedContainer/SizedContainer';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './EditQAModal.scss';
import TextInput from 'components/TextInput/TextInput';
import { Title } from 'components/Title/Title';

interface EditQAModalProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

const EditQAModal = ({
  modalStore,
  projectStore,
}: EditQAModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const answerObj = modalStore?.customData?.answer ?? {};
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [sourceLink, setSourceLink] = useState("");
  const [sourceName, setSourceName] = useState("");
  const [type, setType] = useState("text");
  const isFile = type === 'file';

  useEffect(() => {
    if (answerObj && answerObj.question) {
      setQuestion(answerObj.question);
    }
    if (answerObj && answerObj.answer) {
      setAnswer(answerObj.answer);
    }
    if (answerObj && answerObj.sourceLink) {
      setSourceLink(answerObj.sourceLink);
    }
    if (answerObj && answerObj.sourceName) {
      setSourceName(answerObj.sourceName);
    }
    if (answerObj && answerObj.type) {
      setType(answerObj.type);
    }
  }, []);

  const sendAnswer = async (data) => {
    if (!projectStore?.currentProject?.id) {
      return null;
    }

    setIsLoading(true);
    await projectStore?.updateQAAnswer(projectStore.currentProject?.id, answerObj.id, data);
    modalStore!.closeModal();
    setIsLoading(false);
  };

  const deleteAnswer = async () => {
    if (!projectStore?.currentProject?.id) {
      return null;
    }

    setIsLoading(true);
    await projectStore?.deleteQAAnswer(projectStore.currentProject?.id, answerObj.id);
    modalStore!.closeModal();
    setIsLoading(false);
  };

  return (
    <div className="modal-with-title">
      <div className='modal-title-container'>
        <Title label={isFile ? "Edit file snippet" : "Edit snippet"} />
      </div>
      <div className='modal-body'>
        <div className="edit-qa-modal">
          <TextInput
            name="question"
            placeholder={isFile ? "File name" : "Question"}
            type="text"
            className="mb-20"
            error=""
            value={question ?? ''}
            label={isFile ? "File name" : "Question"}
            onChange={(text) => {
              setQuestion(text);
            }}
          />
          <div className="input-label">
            {isFile ? "File content" : "Answer"}
          </div>
          <textarea
            className="default-textarea mb-10"
            placeholder=""
            style={{
              height: 200,
            }}
            value={answer ?? ''}
            onChange={(e) => {
              setAnswer(e.target.value);
            }}
          />
          <TextInput
            name="linktitle"
            placeholder="Provide a name for the resource"
            type="text"
            className="mb-20"
            error=""
            value={sourceName ?? ''}
            label="Read more title"
            onChange={(text) => {
              setSourceName(text);
            }}
          />
          <TextInput
            name="linkurl"
            placeholder="Link that provides more informations on the answer"
            type="text"
            className="mb-20"
            error=""
            value={sourceLink ?? ''}
            label="Read more URL"
            onChange={(text) => {
              setSourceLink(text);
            }}
          />
          <div className='buttons'>
            <LinkButton className="danger-button" icon="trash" iconSideRight={false} isLoading={isLoading} label='Delete' onClick={() => {
              deleteAnswer();
            }} />
            <PrimaryButton isLoading={isLoading} label='Save' icon="check" onClick={() => {
              sendAnswer({
                question,
                answer,
                sourceLink,
                sourceName,
              });
            }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default inject(
  'modalStore',
  'projectStore',
)(observer(EditQAModal));
