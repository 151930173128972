import { inject, observer } from 'mobx-react';
import { ProjectStore } from 'stores/private/ProjectStore';
import './ProjectAdminCheck.scss';
import PageContainer from 'components/PageContainer/PageContainer';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContent from 'components/PageContent/PageContent';
import LoadingAnimationMedium from 'components/LoadingAnimationMedium/LoadingAnimationMedium';
import IntroCard from 'components/IntroCard/IntroCard';

interface ProjectAdminCheckProps {
  projectStore?: ProjectStore;
  children: React.ReactNode;
  title?: string;
}

const ProjectAdminCheck: React.FC<ProjectAdminCheckProps> = ({
  projectStore,
  children,
  title,
}) => {
  if (!projectStore?.isProjectAdminLoaded) {
    return (<>
      <PageContainer className="project-installation">
        <PageHeadLine title={title} />
        <PageContent hasTitle isCentered>
          <LoadingAnimationMedium />
        </PageContent>
      </PageContainer>
    </>)
  }

  if (!projectStore?.isProjectAdmin) {
    return (<>
      <PageContainer className="project-installation">
        <PageHeadLine title={title} />
        <PageContent hasTitle isBoxed>
          <div className='mt-50'>
            <IntroCard
              headline={`Admin privileges required 🚨`}
              content={`You need to be an admin of this project to access this page. Ask the project owner to make you an admin.`}
            />
          </div>
        </PageContent>
      </PageContainer>
    </>)
  }

  // Ensure you return a valid JSX element. If you just want to render children, use this:
  return <>{children}</>;
};

export default inject('projectStore')(observer(ProjectAdminCheck));
