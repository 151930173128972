import classNames from 'classnames';
import './Title.scss';
import LinkButton from 'components/LinkButton/LinkButton';

interface TitleProps {
  label: string;
  style?: any;
  className?: any;
  small?: boolean;
  collapseIcon?: boolean;
  onClickCollapse?: any;
  isCollapsed?: boolean;
}

export const Title = ({
  label,
  style,
  className,
  small = false,
  collapseIcon,
  onClickCollapse,
  isCollapsed,
}: TitleProps) => {
  const titleClassName = classNames(
    {
      title: true,
      'title--small': small,
    },
    className,
  );

  return (
    <div className={titleClassName} style={style}>
      {label}
      {collapseIcon && <LinkButton icon={isCollapsed ? "arrow-right-long-to-line" : "arrow-left-long-to-line"} className={"title-collapse-button"} onClick={onClickCollapse} />}
    </div>
  );
};
