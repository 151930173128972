import { getDataDescription } from 'helper/FormDataHelper';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { COLORS } from 'styles/Constants';
import './IssueCard.scss';
import { PropertyStore } from 'stores/private/PropertyStore';
import { inject, observer } from 'mobx-react';

interface IssueCardProps {
  ticket: any;
  propertyStore?: PropertyStore;
}

const IssueCard = ({ ticket, propertyStore }: IssueCardProps) => {
  const navigate = useNavigate();
  const dueDate = ticket.dueDate ? moment(ticket.dueDate) : null;
  const isDue =
    ticket.status !== 'DONE' && dueDate
      ? dueDate.isBefore(moment(), 'date')
      : false;
  const isDueToday =
    ticket.status !== 'DONE' && dueDate
      ? dueDate.isSame(moment(), 'date')
      : false;

  const renderDueDate = () => {
    if (!dueDate) {
      return null;
    }

    return (
      <div
        className={`ticket-due-date ${isDue && 'ticket-due-date--isdue'} ${
          isDueToday && 'ticket-due-date--isduetoday'
        }`}
      >
        <i className="fa-solid fa-clock-three" />
        {dueDate?.format('MMM Do')}
      </div>
    );
  };

  const renderPrioIcon = () => {
    let icon = 'fa-solid fa-circle';
    let color = COLORS.BUGBATTLE_GREEN;
    if (ticket.priority === 'MEDIUM') {
      icon = 'fa-solid fa-circle';
      color = COLORS.BUGBATTLE_ORANGE;
    } else if (ticket.priority === 'HIGH') {
      icon = 'fa-solid fa-exclamation-circle';
      color = COLORS.BUGBATTLE_RED;
    }

    return (
      <i
        style={{
          color: color,
        }}
        className={icon}
      />
    );
  };

  return (
    <div
      className={`ticket-item ${
        ticket.notificationsUnread ? 'ticket-item--unread' : ''
      } ${isDue && 'ticket-item--isdue'} ${
        isDueToday && 'ticket-item--isduetoday'
      }`}
      key={`ticket-${ticket.bugId}`}
      onClick={() => {
        navigate(
          `/projects/${ticket.project.id}/${ticket.path ?? 'bugs'}/${
            ticket.shareToken
          }`,
        );
      }}
    >
      <div className="ticket-icon">{renderPrioIcon()}</div>
      <div className="ticket-text">
        {getDataDescription({
          data: ticket,
          maxLength: 200,
          propertyStore,
          feedbackType: ticket.type,
        })}
      </div>
      <div className="ticket-project">
        <div
          className="ticket-project-tag"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            navigate(`/projects/${ticket.project.id}/${ticket.path ?? 'bugs'}`);
          }}
        >
          {ticket?.project?.name}
        </div>
      </div>
      {renderDueDate()}
    </div>
  );
};

export default inject('propertyStore')(observer(IssueCard));
