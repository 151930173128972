import { useEffect, useState } from 'react';
import './WdigetApps.scss';
import { inject, observer } from 'mobx-react';
import { ProjectStore } from 'stores/private/ProjectStore';
import { useParams } from 'react-router';
import Loading from 'components/Loading/Loading';
import PageContainer from 'components/PageContainer/PageContainer';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContent from 'components/PageContent/PageContent';
import WidgetAppCard from 'components/WidgetAppCard/WidgetAppCard';
import { ReactComponent as news } from 'assets/icons/bullhorn-solid.svg';
import { ReactComponent as chat } from 'assets/icons/message-solid.svg';
import { ReactComponent as featureRequests } from 'assets/icons/map-location-solid.svg';
import { ReactComponent as circleQuestion } from 'assets/icons/circle-question-solid.svg';
import { ReactComponent as checklist } from 'assets/icons/check-done-01-active.svg';

interface WdigetAppsProps {
  projectStore?: ProjectStore;
}

function WdigetApps({
  projectStore,
}: WdigetAppsProps) {
  // @ts-ignore
  const { projectId } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
    setIsLoading(false);
  }, [projectId]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <PageContainer className="relativ-full-width-page-container">
      <PageHeadLine title="Widget apps" />
      <PageContent hasTitle>
        <div className="widget-general-settings widget-apps">
          <WidgetAppCard item={{
            type: 'conversations',
            image: chat,
            title: "Conversations",
            description: "Show existing conversations to your users.",
          }} />
          <WidgetAppCard item={{
            type: 'news',
            image: news,
            title: "News",
            description: "Show news & release notes to your users.",
          }} />
          <WidgetAppCard
            item={{
              type: "featureRequests",
              image: featureRequests,
              title: "Roadmap",
              description: "Include your roadmap in the widget.",
            }}
          />
          <WidgetAppCard
            item={{
              type: "knowledgeBase",
              image: circleQuestion,
              title: "Help center",
              description: "Include your help center in the widget.",
            }}
          />
          <WidgetAppCard
            item={{
              type: "checklist",
              image: checklist,
              title: "Checklists",
              description: "Turn signups into superusers.",
            }}
          />
        </div>
      </PageContent>
    </PageContainer>
  );
}

export default inject(
  'projectStore',
)(observer(WdigetApps));
