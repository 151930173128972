import classNames from 'classnames';
import './LinkButton.scss';
import LoadingAnimationSmall from 'components/LoadingAnimationSmall/LoadingAnimationSmall';

interface LinkButtonProps {
  label?: string;
  onClick: any;
  icon?: any;
  className?: any;
  iconSideRight?: boolean;
  noBorder?: boolean;
  faIconType?: string;
  isLoading?: boolean;
  disabled?: boolean;
}

const LinkButton = ({
  label,
  onClick,
  icon,
  className,
  noBorder = false,
  iconSideRight = true,
  faIconType = 'regular',
  isLoading = false,
  disabled = false,
}: LinkButtonProps) => {
  const iconOnly = label === undefined && icon !== undefined;
  const linkButtonClassName = classNames(
    {
      'link-button': true,
      'link-button--icon-right': iconSideRight,
      'link-button--no-border': noBorder,
      'link-button--disabled': disabled,
      'link-button--icononly': iconOnly,
    },
    className,
  );

  if (isLoading) {
    return (
      <div className={linkButtonClassName}>
        <LoadingAnimationSmall />
      </div>
    );
  }

  return (
    <button
      type="button"
      className={linkButtonClassName}
      onClick={() => {
        if (!disabled && !isLoading && onClick) {
          onClick();
        }
      }}
    >
      {!iconSideRight && icon && <i className={`icon fa-${faIconType} fa-${icon}`} />}
      {label}
      {iconSideRight && icon && <i className={`icon fa-${faIconType} fa-${icon}`} />}
    </button>
  );
};

export default LinkButton;
