import LinkButton from 'components/LinkButton/LinkButton';
import TextInput from 'components/TextInput/TextInput';
import React, { useState, useEffect } from 'react';

interface Business {
    name?: string | null;
    tax_identifier?: string | null;
    contacts?: {
        name?: string,
        email?: string
    }[]
}

interface Props {
    value?: Business;
    onChange?: (data: Business) => void;
}

const defaultData: Business = {
    name: null,
    tax_identifier: null,
};

const PaddleBusinessForm: React.FC<Props> = ({ value, onChange }) => {
    const [data, setData] = useState<Business>({} as any);

    const handleContactChange = (name: any, val: string) => {
        const updatedData = { ...data };

        if (!updatedData.contacts || updatedData.contacts.length === 0) {
            updatedData.contacts = [{
                name: undefined,
                email: undefined,
            }];
        }

        if (updatedData.contacts && updatedData.contacts[0]) {
            updatedData.contacts[0][name] = val;
        }

        setData(updatedData);
        onChange?.(updatedData);
    };

    const handleChange = (name: string, val: string) => {
        const updatedData = { ...data, [name]: val };
        setData(updatedData);
        onChange?.(updatedData);
    };

    useEffect(() => {
        if (value) {
            setData(value);
        }
    }, [value]);

    const getContactValue = (key) => {
        if (data?.contacts && data?.contacts.length > 0) {
            return data?.contacts[0][key];
        }
        return null;
    }

    return (
        <>
            <TextInput
                className="mb-5"
                error=""
                placeholder="Business name*"
                value={data.name}
                onChange={(value) => handleChange("name", value)}
            />
            <TextInput
                className="mb-5"
                error=""
                required={false}
                placeholder="Tax or VAT Number"
                value={data.tax_identifier}
                onChange={(value) => {
                    if (value) {
                        const newVal = value.replace(/ /g, '');
                        handleChange("tax_identifier", newVal);
                    }
                }}
            />
            <TextInput
                className="mb-5"
                error=""
                placeholder="Contact name*"
                value={getContactValue("name")}
                onChange={(value) => handleContactChange("name", value)}
            />
            <TextInput
                className="mb-5"
                error=""
                placeholder="Contact email*"
                value={getContactValue("email")}
                onChange={(value) => handleContactChange("email", value)}
            />
        </>
    );
}

export default PaddleBusinessForm;