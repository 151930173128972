import { convertTipTapToPlainText } from 'helper/TipTapHelper';
import { ReactComponent as ArrowRight } from 'assets/icons/angle-right-solid.svg';
import './NewsCardPreview.scss';

function NewsCardPreview({ news, onClick }: { news: any; onClick: any }) {
  if (!news) {
    return null;
  }

  var previewText = '';
  try {
    if (news.message) {
      previewText = convertTipTapToPlainText({
        content: news.message,
      });
    }
  } catch (_) {}

  return (
    <div className="news-card" onClick={onClick}>
      {news.coverImageUrl && news.coverImageUrl.length > 0 && (
        <img className="news-card-image" src={news.coverImageUrl} />
      )}
      <div className="news-card-content">
        <div className="news-card-content-content">
          <div className="news-card-content-title">
            {news?.config?.pinned && '📌 '}
            {news.subject}
          </div>
          <div className="news-card-content-meta">{previewText}</div>
        </div>
        <ArrowRight className="news-card-content-show-details" />
      </div>
    </div>
  );
}

export default NewsCardPreview;
