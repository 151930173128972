import { BugStore } from 'stores/private/BugStore';
import { inject, observer } from 'mobx-react';
import './FeatureRequestComment.scss';
import FeatureRequestUpvoteItem from 'components/FeatureRequestUpvoteItem/FeatureRequestUpvoteItem';
import { getFormFieldValue } from 'helper/FormDataHelper';
import './Details.scss';
import { ProjectStore } from 'stores/private/ProjectStore';
import PublicSkeleton from 'components/Skeletons/PublicSkeleton';
import { formatDate } from 'helper/DateHelper';

interface FeatureRequestCommentProps {
  bugStore?: BugStore;
  projectStore?: ProjectStore;
  shared?: boolean;
}

const FeatureRequestComment = ({
  bugStore,
  projectStore,
}: FeatureRequestCommentProps) => {
  const bug = bugStore!.currentBug;

  if (!bug) {
    return (
      <div className="feature-request-content" onClick={() => {}}>
        <div className="feature-request-content-upvotes-placeholder">
          <PublicSkeleton
            width={60}
            height={60}
            style={{
              borderRadius: '8px',
            }}
          />
        </div>
        <div className="feature-request-content-body">
          <div className="feature-request-content-body-title">
            <PublicSkeleton
              width={280}
              height={18}
              style={{
                borderRadius: '8px',
              }}
            />
          </div>
          <div className="feature-request-content-body-date">
            <PublicSkeleton
              width={100}
              height={14}
              style={{
                borderRadius: '8px',
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="feature-request-content" onClick={() => {}}>
      <FeatureRequestUpvoteItem featureRequest={bug} />
      <div className="feature-request-content-body">
        <div className="feature-request-content-body-title">
          {getFormFieldValue({
            bug,
            key: 'title',
          })}
        </div>
        <div className="feature-request-content-body-date">
          {formatDate(bug.createdAt, 'full')}
        </div>
      </div>
    </div>
  );
};

export default inject(
  'bugStore',
  'projectStore',
)(observer(FeatureRequestComment));
