import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import React from 'react';
import Loading from 'components/Loading/Loading';
import Chart from 'react-apexcharts';

export const HeatmapTypes = {
  BUSIEST_HOURS_PER_WEEKDAY: 'BUSIEST_HOURS_PER_WEEKDAY',
};

interface StatisticHeatmapProps {
  heatmapData: any;
  isLoading?: boolean;
  infoText?: string;
}

const StatisticHeatmap = ({
  heatmapData,
  isLoading,
  infoText,
}: StatisticHeatmapProps) => {
  const _buildNoData = () => {
    return (
      <Column>
        <div className="apexcharts-title-text">
          Busiest period for new tickets
        </div>
        <span>No data to display</span>
      </Column>
    );
  };

  return (
    <ComponentWrapper className="statistic-bar-chart" infoText={infoText}>
      {(isLoading || !heatmapData) && <Loading />}
      {heatmapData?.series?.length === 0 && !isLoading && _buildNoData()}
      {!isLoading && heatmapData?.series?.length > 0 && (
        <Chart
          type="heatmap"
          options={{
            title: {
              text: 'Busiest period for new tickets',
            },
            chart: {
              type: 'heatmap',
              toolbar: {
                show: false,
              },
            },
            colors: ['#2142E7'],
          }}
          series={heatmapData?.series?.reverse() ?? []}
          height={400}
        />
      )}
    </ComponentWrapper>
  );
};

export default StatisticHeatmap;
