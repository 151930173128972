import axios from '../Axios';

const getAuthCodeFromGithub = (projectID) => {
  return window.open(
    `https://github.com/login/oauth/authorize?client_id=${process.env.REACT_APP_GITHUB_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_BASEURL}/integrations/github&state=${projectID}&scope=repo`,
    '_self',
  );
};

const setGithubAuthToken = (code, projectID) => {
  return axios.post(`/projects/${projectID}/integrations/github`, {
    code,
  });
};

const getRepositories = ({ projectID, integrationID }) => {
  return axios.get(
    `/projects/${projectID}/integrations/github/${integrationID}/repositories`,
  );
};

const setGithubActions = ({ projectID, integrationID, actions, settings }) => {
  return axios.put(
    `/projects/${projectID}/integrations/github/${integrationID}`,
    {
      actions,
      settings,
    },
  );
};

const disconnectGithubIntegration = ({ projectID, integrationID }) => {
  return axios.delete(
    `/projects/${projectID}/integrations/github/${integrationID}`,
  );
};

export {
  getAuthCodeFromGithub,
  setGithubAuthToken,
  getRepositories,
  setGithubActions,
  disconnectGithubIntegration,
};
