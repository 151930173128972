import CreateableSelectDropdown from 'components/CreateableSelectDropdown/CreateableSelectDropdown';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './AiTagActionEditor.scss';
import InfoBox from 'components/InfoBox/InfoBox';

interface AiTagActionEditorProps {
  action: any;
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
}

const AiTagActionEditor = ({
  action,
  modalStore,
  projectStore,
}: AiTagActionEditorProps) => {
  const [tags, setTags] = useState<any[]>(action.tags ?? []);

  useEffect(() => {
    if (tags.length === 0) {
      tags.push({
        tag: null,
        description: '',
      });
      setTags([...tags]);
    }
  }, [action.tags]);

  useEffect(() => {
    // Save the tags to the action
    action.tags = tags;
  }, [tags]);

  const possibleTags = projectStore?.currentProject?.feedbackTags
    ? projectStore?.currentProject?.feedbackTags!.map((tagItem) => {
        return {
          label: tagItem.label,
          value: tagItem.label,
          color: tagItem.color ?? '#060d25',
        };
      })
    : [];

  return (
    <div className="ai-tagging-request-modal">
      <PageContainer>
        <PageHeadLine title="AI based tagging">
          <div className="header-content-right">
            <PrimaryButton
              label="Close"
              iconSideRight={false}
              onClick={() => {
                modalStore!.closeModal();
              }}
            />
          </div>
        </PageHeadLine>
        <PageContent hasTitle>
          <InfoBox className='mb-20'>
            AI based tagging allows you to automatically tag a ticket based on
            it's content. You can define tags and the conditions when a tag
            should be applied.
            <br />
            <br />
            <b>Example:</b>
            <br />
            <b>Bug report</b> - User reports a bug
            <br />
            <b>Refund</b> - User asks for a refund
            <br />
          </InfoBox>
          {tags.map((tagItem, index) => {
            const selectedTag = possibleTags.find(
              (tag) => tag?.value === tagItem?.tag,
            );

            return (
              <Row key={index} alignItems="center" className="mt-5">
                <div className="tagcontainer">
                  <CreateableSelectDropdown
                    showBorder={true}
                    shared={false}
                    selectedItem={selectedTag}
                    isMulti={false}
                    items={possibleTags}
                    onChange={(selectedItem: any) => {
                      if (selectedItem && selectedItem.value) {
                        let existingTags = (
                          projectStore?.currentProject?.feedbackTags ?? []
                        ).map((tagItem) => tagItem.label);

                        // Add the tag to the project if it doesn't exist
                        projectStore?.handleTags([
                          ...existingTags,
                          selectedItem.value,
                        ]);

                        // Save tag to the action.
                        tags[index].tag = selectedItem?.value;
                        setTags([...tags]);
                      }
                    }}
                  />
                </div>
                <TextInput
                  name="prompt"
                  placeholder="When to apply this tag?"
                  icon="wand-magic-sparkles"
                  type="text"
                  error=""
                  initalValue={tagItem?.description ?? ''}
                  onChange={(text) => {
                    tags[index].description = text;
                    setTags([...tags]);
                  }}
                />
                <PrimaryButton
                  className="ml-10"
                  iconOnly
                  icon="trash"
                  onClick={() => {
                    tags.splice(index, 1);
                    setTags([...tags]);
                  }}
                />
              </Row>
            );
          })}
          <div className="mt-10">
            <PrimaryButton
              label="Add another tag"
              onClick={() => {
                tags.push({
                  tag: null,
                  description: '',
                });
                setTags([...tags]);
              }}
            />
          </div>
        </PageContent>
      </PageContainer>
    </div>
  );
};

export default inject(
  'modalStore',
  'projectStore',
)(observer(AiTagActionEditor));
