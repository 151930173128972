import { truncateString } from 'components/FeedbackUserFilter/FeedbackUserFilter';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { BugStore } from 'stores/private/BugStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './BugAssignUser.scss';

interface BugAssignUserProps {
  bugStore?: BugStore;
  projectStore?: ProjectStore;
}

const BugAssignUser = ({ bugStore, projectStore }: BugAssignUserProps) => {
  const bug = bugStore?.currentBug;
  const [selectedUser, setSelectedUser] = useState(undefined as any);

  useEffect(() => {
    if (bug?.processingUser) {
      setSelectedUser(bug?.processingUser);
    } else {
      setSelectedUser(undefined);
    }
  }, [bug?.processingUser]);

  if (!bug || !projectStore?.currentProjectUsers) {
    return <></>;
  }

  return (
    <div className='user-filter'>
      <Select
        className="dropdown-selection"
        classNamePrefix="dropdown-selection"
        defaultValue={selectedUser}
        value={selectedUser}
        getOptionLabel={(option) => option.firstName}
        getOptionValue={(option) => option.id}
        placeholder={
          <div className="user-selection-placeholder">
            <div className="user-selection-placeholder-avatar" />
            <div className="user-selection-placeholder-label">Not assigned</div>
          </div>
        }
        isClearable
        components={{
          SingleValue: (option: any) => {
            const name = `${option.data.firstName} ${option.data.lastName}`;
            return (
              <>
                <UserAvatar
                  small
                  email={option.data.email}
                  imageUrl={option.data.profileImageUrl}
                />
                {truncateString(name, 13)}
              </>
            );
          },
          Option: (option: any) => {
            const name = `${option.data.firstName} ${option.data.lastName}`;
            return (
              <div
                className="option-list-item user-option-list-item"
                onClick={() => {
                  if (option.selectOption) {
                    option.selectOption(option.data);
                  }
                }}
              >
                <UserAvatar
                  small
                  email={option.data.email}
                  imageUrl={option.data.profileImageUrl}
                />
                {name}
              </div>
            );
          },
        }}
        onChange={(selectedProcessingUser: any) => {
          if (selectedProcessingUser && selectedProcessingUser.id) {
            bugStore!.updateBug(bug.id, {
              processingUser: selectedProcessingUser.id,
            });
          } else {
            bugStore!.updateBug(bug.id, {
              processingUser: '',
            });
          }
        }}
        options={projectStore?.currentProjectUsers}
      />
    </div>
  );
};

export default inject('bugStore', 'projectStore')(observer(BugAssignUser));
