import StatusSelection from 'components/BugStatusSelection/StatusSelection';
import BugTypeSelection from 'components/BugTypeSelection/BugTypeSelection';
import ConditionPicker from 'components/ConditionPicker/ConditionPicker';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import UserTypeDropDown from 'components/UserTypeDropDown/UserTypeDropDown';
import { setProperty } from 'helper/AssignObjectKeysHelper';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { audienceOptions } from 'pages/private/ProjectOutbounds/ProjectOutboundConfiguration/components/OutboundTriggerConfiguration/OutboundTriggerConfiguration';
import * as CodeBlock from 'react-code-blocks';
import Collapsible from 'react-collapsible';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './TriggerActionEditorModal.scss';
import InfoBox from 'components/InfoBox/InfoBox';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import { timeIntervalOptions } from 'components/ConditionTag/ConditionTag';

export const botTriggerOptions = [
  { name: 'No automatic trigger', value: 'none' },
  { name: 'On conversation started', value: 'on-conversation-started' },
  { name: 'On ticket created', value: 'on-ticket-created' },
  { name: 'On ticket closed', value: 'on-conversation-closed' },
  { name: 'On ticket status update', value: 'on-ticket-status-update' },
  { name: 'On ticket no response', value: 'on-ticket-no-response' },
  {
    name: 'On customer comment',
    value: 'on-ticket-new-customer-comment',
  },
  {
    name: 'On agent comment',
    value: 'on-ticket-new-admin-comment',
  },
];

interface TriggerActionEditorModalProps {
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
}

const TriggerActionEditorModal = ({
  modalStore,
  projectStore,
}: TriggerActionEditorModalProps) => {
  const renderAudience = () => {
    return (
      <>
        <div className="collapsible-options-group">
          <div className="collapsible-options-group-header">Audience</div>
          <div className="audience-selection">
            <div className="audience-dropdown">
              <UserTypeDropDown
                onValueChanged={(value) => {
                  runInAction(() => {
                    projectStore!.bot!.targetAudience = value.value;
                  });
                }}
                value={audienceOptions.find(
                  ({ value }) => value === projectStore?.bot?.targetAudience,
                )}
                options={audienceOptions}
              />
            </div>
            <ConditionPicker
              type="audience"
              conditions={projectStore?.bot?.conditions ?? []}
              onChange={(conditions) => {
                runInAction(() => {
                  if (!conditions || conditions.length <= 0) {
                    projectStore!.bot!.conditions = [];
                  }
                  projectStore!.bot!.conditions = conditions;
                });
              }}
              streamedEventKeys={projectStore?.streamedEventKeys ?? []}
            />
          </div>
        </div>
      </>
    );
  };

  var triggerType = projectStore?.bot?.triggerFilter?.type?.data;
  if (!triggerType || triggerType === '') {
    triggerType = 'ALL';
  }

  const trigger = botTriggerOptions.find(
    ({ value }) => value === projectStore?.bot?.trigger,
  );

  const renderNoReplyDelay = () => {
    const currentInterval =
      projectStore?.bot?.triggerDelaySettings?.noResponseInterval ?? 'minute';

    const valueInSeconds =
      projectStore?.bot?.triggerDelaySettings?.noResponseTriggerDelayDays
        ?.data ?? 0;
    var valueToRender = valueInSeconds;
    if (currentInterval === 'minute') {
      valueToRender = valueInSeconds / 60;
    } else if (currentInterval === 'hour') {
      valueToRender = valueInSeconds / 60 / 60;
    } else if (currentInterval === 'day') {
      valueToRender = valueInSeconds / 60 / 60 / 24;
    }
    valueToRender = Math.ceil(valueToRender);

    return (
      <div className="trigger-type mt-20">
        <Row className="audience-dropdown">
          <div className="max-width-300">
            <TextInput
              value={valueToRender}
              placeholder=""
              type="number"
              error=""
              step="1"
              onChange={(val) => {
                runInAction(() => {
                  var num = parseInt(val);
                  if (isNaN(num) || num < 1) {
                    num = 1;
                  }

                  var seconds = num;
                  if (currentInterval === 'minute') {
                    seconds = num * 60;
                  } else if (currentInterval === 'hour') {
                    seconds = num * 60 * 60;
                  } else if (currentInterval === 'day') {
                    seconds = num * 60 * 60 * 24;
                  }

                  setProperty(
                    projectStore!.bot,
                    'triggerDelaySettings.noResponseTriggerDelayDays',
                    {
                      matchOperator: 'is',
                      data: seconds,
                    },
                  );
                });
              }}
            />
          </div>
          <div className="interval-selector condition-picker">
            <SelectDropDown
              isSearchable={false}
              labelPropertyName="label"
              valuePropertyName="id"
              selectedItem={timeIntervalOptions.find(
                (option) => option.id === currentInterval,
              )}
              items={timeIntervalOptions}
              onChange={(option) => {
                if (option.id && option.id.length > 0) {
                  runInAction(() => {
                    setProperty(
                      projectStore!.bot,
                      'triggerDelaySettings.noResponseInterval',
                      option.id,
                    );
                  });
                }
              }}
            />
          </div>
        </Row>
      </div>
    );
  };

  return (
    <PageContainer>
      <PageHeadLine title="Configure bot trigger">
        <PrimaryButton
          label="Close"
          iconSideRight={false}
          onClick={() => {
            modalStore!.closeModal();
          }}
        />
      </PageHeadLine>
      <PageContent hasTitle hasPadding={false}>
        <div className="trigger-action-configuration-container">
          <Collapsible
            className="Collapsible-big"
            open={true}
            trigger={
              <div className="collapsible-header">
                <div className="collapsible-header-title">
                  Trigger this bot automatically{' '}
                  <i className="fa-sharp fa-solid fa-chevron-right"></i>
                </div>
                <div className="collapsible-header-sub">
                  {trigger?.value && trigger?.value !== 'none' ? (
                    <div>
                      <i className="fa-regular fa-bolt" /> {trigger?.name}
                    </div>
                  ) : (
                    <div>
                      <i className="fa-regular fa-bolt" /> Expand to set up your
                      rules
                    </div>
                  )}
                </div>
              </div>
            }
            transitionTime={200}
            overflowWhenOpen="initial"
            openedClassName="Collapsible-big Collapsible--opened"
            onClose={() => {}}
          >
            <div className="collapsible-options-group">
              <div>
                <div className="mb-10 text">
                  You can trigger this bot automatically by specifying a trigger
                  and audience. If multiple workflows match at the same time,
                  Gleap will pick the bot with the highest priority.
                </div>
              </div>
            </div>
            <div className="collapsible-options-group">
              <div className="collapsible-options-group-header">Trigger</div>
              <div className="trigger-type-sideby">
                <div className="audience-dropdown">
                  <UserTypeDropDown
                    onValueChanged={(value) => {
                      runInAction(() => {
                        projectStore!.bot!.trigger = value.value;
                      });
                    }}
                    value={trigger}
                    options={botTriggerOptions}
                  />
                </div>
                {trigger?.value !== 'none' &&
                  trigger?.value !== 'on-conversation-started' && (
                    <div className="trigger-type">
                      <BugTypeSelection
                        showAllOption
                        value={triggerType}
                        onValueChanged={(selected) => {
                          runInAction(() => {
                            setProperty(
                              projectStore!.bot,
                              'triggerFilter.type',
                              {
                                matchOperator: 'is',
                                data: selected.value,
                              },
                            );
                          });
                        }}
                      />
                    </div>
                  )}
                {trigger?.value === 'on-ticket-status-update' && (
                  <div className="trigger-type">
                    <StatusSelection
                      status={projectStore?.bot?.triggerFilter?.status?.data}
                      feedbackType={
                        projectStore?.bot?.triggerFilter?.type?.data
                      }
                      onChange={(status) => {
                        runInAction(() => {
                          setProperty(
                            projectStore!.bot,
                            'triggerFilter.status',
                            {
                              matchOperator: 'is',
                              data: status,
                            },
                          );
                        });
                      }}
                    />
                  </div>
                )}
              </div>
              {trigger?.value === 'on-ticket-no-response' &&
                renderNoReplyDelay()}
              {trigger?.value === 'on-conversation-started' && (
                <InfoBox className="mt-10">
                  This trigger starts the workflow, when a new conversation is
                  started. This is useful to start an automation, before a
                  ticket is created.
                </InfoBox>
              )}
            </div>
            {renderAudience()}
            <div className="collapsible-options-group">
              <div className="collapsible-options-group-header">Priority</div>
              <div className="max-width-300">
                <div className="audience-dropdown">
                  <TextInput
                    value={projectStore!.bot!.triggerPriority}
                    placeholder=""
                    type="number"
                    error=""
                    onChange={(val) => {
                      runInAction(() => {
                        var num = parseInt(val);
                        if (isNaN(num)) {
                          return;
                        }

                        projectStore!.bot!.triggerPriority = num;
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </Collapsible>
          <Collapsible
            className="Collapsible-big"
            trigger={
              <div className="collapsible-header">
                <div className="collapsible-header-title">
                  Manually start this workflow
                </div>
                <div className="collapsible-header-sub">
                  <div>
                    <i className="fa-regular fa-code"></i> Start by code
                    <br />
                  </div>
                </div>
              </div>
            }
            transitionTime={200}
            overflowWhenOpen="initial"
            openedClassName="Collapsible-big Collapsible--opened"
            onClose={() => {}}
          >
            <div className="collapsible-options-group">
              <div>
                <div className="mb-10 text">
                  The following code snippet shows how to start this bot by
                  code. This is useful if you want to have full control over it.
                </div>
                <CodeBlock.CodeBlock
                  text={`Gleap.startBot("${projectStore?.bot?.id}");`}
                  language={'js'}
                  showLineNumbers={false}
                />
                <div className="mt-10 text">
                  Check out our{' '}
                  <a
                    href="https://docs.gleap.io/javascript/surveys/#manually-sending-surveys"
                    target="_blank"
                    rel="noreferrer"
                  >
                    full documentation
                  </a>{' '}
                  on how to get started with workflows.
                </div>
              </div>
            </div>
          </Collapsible>
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'modalStore',
  'projectStore',
)(observer(TriggerActionEditorModal));
