import classNames from 'classnames';
import { truncateString } from 'components/FeedbackUserFilter/FeedbackUserFilter';
import TextInput from 'components/TextInput/TextInput';
import { useRef, useState } from 'react';
import { useClickedOutside, useEscape } from 'services/Helper';
import './SelectPageUrl.scss';
import InfoBox from 'components/InfoBox/InfoBox';

export const pageRuleOptions = [
  {
    label: 'is',
    value: 'is',
  },
  {
    label: 'is not',
    value: 'isnot',
  },
  {
    label: 'contains',
    value: 'contains',
  },
  {
    label: 'does not contain',
    value: 'notcontains',
  },
  {
    label: 'starts with',
    value: 'startswith',
  },
  {
    label: 'ends with',
    value: 'endswith',
  },
];

export const getLabelForPageRuleOption = (value: string) => {
  const option = pageRuleOptions.find((option) => option.value === value);
  if (option) {
    return option.label;
  }
  return '';
}

export const SelectPageUrl = ({ className, pageFilter, onPageFilterChange, pageFilterType, onPageFilterTypeChange }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);
  useClickedOutside(wrapperRef, () => {
    setIsOpen(false);
  });
  useEscape(() => {
    setIsOpen(false);
  });
  const cardClassName = classNames(
    {
      'select-page-url': true,
    },
    className,
  );

  const renderDelayText = () => {
    if (!pageFilter || pageFilter.length === 0) {
      return (<>+ Add page rule</>);
    }

    return (<>Current page {getLabelForPageRuleOption(pageFilterType)} <b>{truncateString(pageFilter, 10)}</b></>);
  }

  return (
    <div
      className={cardClassName}
      ref={wrapperRef}>
      <div
        className={`select-page-url--inner ${pageFilter && pageFilter.length > 0 && 'select-page-url--inner--valueadded'}`}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {renderDelayText()}
      </div>
      <div className={`select-page-url-overlay ${isOpen && 'select-page-url-overlay--open'}`}>
        <div className="input-label input-label--small">
          Trigger only on a specific page
        </div>
        <div className="text mt-10">
          Send this outreach message only when the page name or URL contains the
          following text.
        </div>
        <div className="count-editor-container mt-10">
          <div className="count-editor-container-options">
            {pageRuleOptions.map((option, index) => {
              return (
                <>
                  <label
                    className="bb-feedback-multiplechoice-container"
                    key={`${option.value}-${index}`}
                  >
                    <div className="text">{option.label}</div>
                    <input
                      type="radio"
                      name={`condition-${option.value}`}
                      checked={pageFilterType === option.value}
                      onChange={() => {
                        onPageFilterTypeChange(option.value);
                      }}
                    />
                    <span className="bb-feedback-multiplechoice-checkmark" />
                  </label>
                  {option.value === pageFilterType && (
                    <>
                      <div className="audience-page-filter">
                        <TextInput
                          name="highest-value"
                          initalValue={pageFilter}
                          placeholder="i.e. https://yourapp.tld/dashboard"
                          type="text"
                          error=""
                          onChange={(val) => {
                            if (onPageFilterChange) {
                              onPageFilterChange(val);
                            }
                          }}
                        />
                      </div>
                      {(option.value === 'contains' || option.value === 'notcontains') && (
                        <InfoBox className='mt-10'>
                          <b>Using dynamic urls?</b> You can replace any dynamic parts of the url with a placeholder. An example:
                          https://yourapp.tld/user/1234. You can replace the user id with a placeholder like this: https://yourapp.tld/user/{`{userId}`}.
                        </InfoBox>
                      )}
                    </>
                  )}
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
