import React, { useState, useEffect } from 'react';
import { Title } from 'components/Title/Title';
import { inject, observer } from 'mobx-react';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './DynamicAITranslationModal.scss';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { toast } from 'react-toastify';
import LanguageDropdown from 'components/LanguageDropdown/LanguageDropdown';
import InfoBox from 'components/InfoBox/InfoBox';
import Switch from 'react-switch';
import { hasTranslation } from 'services/Helper';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import LinkButton from 'components/LinkButton/LinkButton';

interface DynamicAiTranslationModalProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

interface Language {
  name: string;
  code: string;
}

const DynamicAiTranslationModal = ({
  modalStore,
  projectStore,
}: DynamicAiTranslationModalProps) => {
  const data = modalStore?.getCustomData(MODALTYPE.DYNAMIC_AI_TRANSLATION);
  const { showStatus, article } = data;
  const [baseLanguage, setBaseLanguage] = useState<Language | null>(
    data.baseLanguage,
  );
  const [availableLanguages, setAvailableLanguages] = useState<Language[]>(
    data.languageList,
  );
  const [selectedLanguages, setSelectedLanguages] = useState<Language[]>(
    data.targetLanguage ? data.targetLanguage : [],
  );
  const [translating, setTranslating] = useState(false);

  useEffect(() => {
    updateAvailableLanguages();
  }, [baseLanguage]);

  const updateAvailableLanguages = () => {
    if (baseLanguage) {
      const newAvailableLanguages = data.languageList.filter(
        (lang: Language) => lang.code !== baseLanguage.code,
      );
      setAvailableLanguages(newAvailableLanguages);
      setSelectedLanguages((prevSelected) =>
        prevSelected.filter((lang) => lang.code !== baseLanguage.code),
      );
    }
  };

  const toggleAll = () => {
    if (selectedLanguages.length === availableLanguages.length) {
      setSelectedLanguages([]);
    } else {
      setSelectedLanguages(availableLanguages);
    }
  };

  const handleBaseLanguageChange = (value: Language) => {
    setBaseLanguage(value);
  };

  const handleTargetLanguageToggle = (lang: Language, checked: boolean) => {
    setSelectedLanguages((prevSelected) => {
      if (checked) {
        return [...prevSelected, lang];
      } else {
        return prevSelected.filter((l) => l.code !== lang.code);
      }
    });
  };

  const translateData = async () => {
    if (translating) return;
    if (!baseLanguage) return;
    if (!data.getContent) {
      console.error('Missing getContent function!');
      return;
    }
    if (!data.setContent) {
      console.error('Missing setContent function!');
      return;
    }

    setTranslating(true);

    try {
      const translationPromises = selectedLanguages.map(async (targetLang) => {
        const translateObject = data.getContent(baseLanguage?.code);

        const translated = await projectStore?.aiTranslate(
          translateObject,
          targetLang.code,
          data?.projectId,
        );

        if (translated) {
          data.setContent(translated, targetLang.code);
        }
      });

      await Promise.all(translationPromises);

      modalStore?.closeModal();
    } catch (error) {
      toast.error('An error occurred while translating the article');
    }

    setTranslating(false);
  };

  if (!baseLanguage) {
    return null;
  }

  const renderTranslateButton = () => {
    if (selectedLanguages.length === 0) {
      return (
        <div>
          <PrimaryButton
            icon="fa-solid fa-check-double"
            iconOnly
            className="mt-20"
            onClick={toggleAll}
          />
          <InfoBox icon="none" className="mt-20">
            ⛔️ Please select at least one target language for translation.
          </InfoBox>
        </div>
      );
    }

    return (
      <Row gap={4} alignItems="center">
        <PrimaryButton
          label={`Translate from ${baseLanguage.name} to ${selectedLanguages.length} language(s)`}
          className="mt-20"
          disabled={selectedLanguages.length === 0}
          onClick={translateData}
          isLoading={translating}
        />
        <LinkButton
          icon="fa-solid fa-check-double"
          className="mt-20"
          onClick={toggleAll}
        />
      </Row>
    );
  };

  return (
    <div className="compose-message-modal">
      <Title label="Translation 🪄" className="mb-20" />
      <InfoBox className="mb-20">
        This feature uses AI to translate the content of the article. The
        translation consumes AI-tokens.
      </InfoBox>
      <div className="dynamic-ai-content">
        Choose the language version you want to translate from.
        <LanguageDropdown
          items={data.languageList}
          selectedItem={baseLanguage}
          className="mt-10 language-dropdown"
          onChange={handleBaseLanguageChange}
        />
        <p>Which languages do you want to translate to?</p>
        <div className="language-list">
          {availableLanguages.length > 0 &&
            availableLanguages.map((lang) => {
              let status = false;
              if (showStatus && article) {
                status = hasTranslation(lang.code, article);
              }

              return (
                <div key={lang.code} className="language-selector">
                  {showStatus && article && (
                    <div
                      className={`status-indicator ${
                        status ? 'status-indicator--completed' : ''
                      }`}
                    ></div>
                  )}
                  <Switch
                    width={40}
                    onColor="#2142E7"
                    height={20}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={(checked) =>
                      handleTargetLanguageToggle(lang, checked)
                    }
                    checked={selectedLanguages.some(
                      (l) => l.code === lang.code,
                    )}
                  />
                  {lang.name}
                </div>
              );
            })}
        </div>
        {renderTranslateButton()}
      </div>
    </div>
  );
};

export default inject(
  'modalStore',
  'projectStore',
)(observer(DynamicAiTranslationModal));
