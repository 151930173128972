import { PropertyType } from 'components/Modals/PropertyConfigurationModal/PropertyConfigurationModal';
import {
  getLanguageProperty,
  getProperty,
  getSchemaProperty,
  setSchemaProperty,
} from 'helper/AssignObjectKeysHelper';
import { Property } from 'models/Property';
import './PropertyInput.scss';
import FileUpload from 'components/FileUpload/FileUpload';
import TextInputPreviewField from '../TextInputPreviewField/TextInputPreviewField';
import TextAreaPreviewField from '../TextAreaPreviewField/TextAreaPreviewField';
import SelectPreviewField from '../SelectPreviewField/SelectPreviewField';
import RichTextPreviewField, {
  makeRichTextBackwardsComp,
} from '../RichTextPreviewField/RichTextPreviewField';
import ObjectInputPreviewField from '../ObjectInputPreviewField/ObjectInputPreviewField';

interface PropertyInputProps {
  viewType?: 'details' | 'sidebar';
  data: any;
  property: Property;
  onSetData: (data: any) => void;
  shared?: boolean;
}

const PropertyInput = ({
  viewType = 'details',
  data,
  property,
  onSetData,
  shared,
}: PropertyInputProps) => {
  const save = () => {
    const dataToSave = getProperty(data, property.path ?? 'formData');

    if (property.path && property.path !== '') {
      onSetData({ [property.path]: dataToSave });
      return;
    }

    // Root level
    onSetData({ [property.fieldId]: dataToSave[property.fieldId] });
  };

  const getInputTypeFromWidgetType = () => {
    switch (property.type) {
      case PropertyType.TEXT:
        return 'text';
      case PropertyType.NUMBER:
        return 'number';
      case PropertyType.EMAIL:
        return 'email';
      case PropertyType.DATE:
        return 'date';
      case PropertyType.TIME:
        return 'time';
      default:
        return 'text';
    }
  };

  const getDetailsWidgetForType = () => {
    const value = getSchemaProperty(data, property);

    if (
      (typeof value === 'object' || Array.isArray(value)) &&
      property.type !== PropertyType.RICHTEXT &&
      property.type !== PropertyType.MULTISELECT
    ) {
      return (
        <ObjectInputPreviewField
          defaultValue={value}
          name={property.fieldId}
          placeholder={getLanguageProperty(property, 'placeholder', 'en')}
          readOnly={shared || property.readOnly}
          onChange={(e) => {
            setSchemaProperty(data, property, e.target.value);
          }}
          onBlur={() => {
            save();
          }}
        />
      );
    }

    switch (property.type) {
      case PropertyType.TEXT:
      case PropertyType.NUMBER:
      case PropertyType.EMAIL:
      case PropertyType.DATE:
      case PropertyType.TIME:
        return (
          <TextInputPreviewField
            type={getInputTypeFromWidgetType()}
            defaultValue={value}
            name={property.fieldId}
            placeholder={getLanguageProperty(property, 'placeholder', 'en')}
            readOnly={shared || property.readOnly}
            onChange={(e) => {
              setSchemaProperty(data, property, e.target.value);
            }}
            onBlur={() => {
              save();
            }}
          />
        );
      case PropertyType.TEXTAREA:
        return (
          <TextAreaPreviewField
            defaultValue={value}
            name={property.fieldId}
            placeholder={getLanguageProperty(property, 'placeholder', 'en')}
            readOnly={shared || property.readOnly}
            onChange={(e) => {
              setSchemaProperty(data, property, e.target.value);
            }}
            onBlur={() => {
              save();
            }}
          />
        );
      case PropertyType.UPLOAD:
        return (
          <FileUpload
            label={property.label}
            url={value}
            editable={!shared && !property.readOnly}
            shared={shared}
            uploadPath="manuallyuploaded"
            afterFileUpload={(imageURL) => {
              setSchemaProperty(data, property, imageURL);
              save();
            }}
          />
        );
      case PropertyType.SELECT:
      case PropertyType.MULTISELECT:
        let selectedValues: any = property.options?.find(
          (item) => item.value === value,
        );

        if (
          property.type === PropertyType.MULTISELECT &&
          Array.isArray(value)
        ) {
          selectedValues = property.options?.filter((item) =>
            value.includes(item.value),
          );
        }

        return (
          <SelectPreviewField
            labelPropertyName="label.localized.en"
            valuePropertyName="value"
            items={property.options ?? []}
            selectedItem={selectedValues}
            disabled={shared || property.readOnly}
            isMulti={property.type === PropertyType.MULTISELECT}
            onChange={(item) => {
              if (Array.isArray(item)) {
                const preparedItems = item.map((i) => i.value);
                setSchemaProperty(data, property, preparedItems);
              } else {
                if (value === item.value) return;

                setSchemaProperty(data, property, item.value);
              }

              save();
            }}
          />
        );
      case PropertyType.RICHTEXT:
        return (
          <RichTextPreviewField
            value={
              typeof value === 'string'
                ? makeRichTextBackwardsComp(value)
                : value
            }
            readOnly={shared || property.readOnly}
            onChange={(content) => {
              setSchemaProperty(data, property, content);
            }}
            onBlur={() => {
              save();
            }}
          />
        );
      default:
        return (
          <TextInputPreviewField
            type={getInputTypeFromWidgetType()}
            defaultValue={value}
            name={property.fieldId}
            placeholder={getLanguageProperty(property, 'placeholder', 'en')}
            readOnly={shared || property.readOnly}
            onChange={(e) => {
              setSchemaProperty(data, property, e.target.value);
            }}
            onBlur={() => {
              save();
            }}
          />
        );
    }
  };

  if (!property) {
    return <></>;
  }

  return getDetailsWidgetForType();
};

export default PropertyInput;
