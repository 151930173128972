import { Editor } from '@tiptap/react';
import classNames from 'classnames';
import EmailEditor from 'components/Editors/EmailEditor/EmailEditor';
import Toolbar, { ToolbarItems } from 'components/Editors/ToolBar/Toolbar';
import EmailTemplate from 'components/EmailTemplate/EmailTemplate';
import EmailTemplateSelection from 'components/EmailTemplateSelection/EmailTemplateSelection';
import FeedbackUserFilter from 'components/FeedbackUserFilter/FeedbackUserFilter';
import InfoBox from 'components/InfoBox/InfoBox';
import LanguageDropdown from 'components/LanguageDropdown/LanguageDropdown';
import LinkButton from 'components/LinkButton/LinkButton';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import TypeIconSelection from 'components/TypeIconSelection/TypeIconSelection';
import { languages } from 'constants/Languages';
import {
  getLanguageProperty,
  setLanguageProperty,
} from 'helper/AssignObjectKeysHelper';
import { debounce } from 'lodash';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ProjectStore } from 'stores/private/ProjectStore';
import Swal from 'sweetalert2';
import FeedbackPossibleLanes from './FeedbackPossibleLanes';
import './FeedbackTypeEditor.scss';
import PageContent from 'components/PageContent/PageContent';
import PageContainer from 'components/PageContainer/PageContainer';

const feedbackTypeServerVariables = [
  {
    label: 'Ticket',
    options: [
      {
        label: 'Title',
        value: 'ticket.title',
      },
      {
        label: 'Formdata',
        value: 'ticket.formData',
      },
      {
        label: 'Screenshot',
        value: 'ticket.screenshot',
      },
    ],
  },
  {
    label: 'Organization',
    options: [
      {
        label: 'Organization name',
        value: 'organization.name',
      },
    ],
  },
];

interface FeedbackTypeEditorProps {
  projectStore?: ProjectStore;
}

const FeedbackTypeEditor = ({ projectStore }: FeedbackTypeEditorProps) => {
  const navigate = useNavigate();
  const { feedbackTypePath } = useParams();
  const [currentFeedbackTypeIndex, setCurrentFeedbackTypeIndex] = useState(
    null as any,
  );

  const feedbackTypes = projectStore?.currentProject?.projectTypes;
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [isSaveButtonDisabled, setSaveButtonDisabled] = useState(false);

  const editorRef: React.MutableRefObject<Editor | null> = useRef(null);

  const [toggleState, setToggleState] = useState(false);

  const currentLang = projectStore?.currentLanguage ?? 'en';

  useEffect(() => {
    if (feedbackTypePath && feedbackTypes && feedbackTypes.length > 0) {
      const index = feedbackTypes.findIndex((x) => x.path === feedbackTypePath);
      if (index >= 0) {
        setCurrentFeedbackTypeIndex(index);
      }
    }
  }, [feedbackTypePath, feedbackTypes]);

  useEffect(() => {
    if (
      currentFeedbackTypeIndex != null &&
      feedbackTypes &&
      feedbackTypes.length > 0
    ) {
      const currentFeedbackType =
        feedbackTypes[Object.keys(feedbackTypes)[currentFeedbackTypeIndex]];
      const message = getLanguageProperty(
        currentFeedbackType,
        'receievedEmailDescription',
        currentLang,
      );

      editorRef?.current?.commands.setContent(message !== '' ? message : {});
    }
  }, [editorRef, currentLang]);

  const closeAndSave = async () => {
    setIsLoadingSave(true);
    await updateProjectTypes();
    setIsLoadingSave(false);
    setCurrentFeedbackTypeIndex(null);
    navigate(
      `/projects/${projectStore?.currentProject?.id}/settings/feedbacktypes`,
    );
  };

  const updateProjectTypes = async () => {
    await projectStore!.updateProject(
      projectStore!.currentProject!.id,
      {
        projectTypes: projectStore!.currentProject!.projectTypes,
      },
      false,
      false,
    );
  };

  const debouncedSave = useCallback(
    debounce(() => updateProjectTypes(), 1000),
    [],
  );

  if (!(feedbackTypes && currentFeedbackTypeIndex != null)) {
    return null;
  }

  const feedbackType =
    feedbackTypes[Object.keys(feedbackTypes)[currentFeedbackTypeIndex]];

  if (!feedbackType) {
    return null;
  }

  const currentProject = projectStore?.currentProject;
  const availableLanguages = languages.filter((language) =>
    currentProject?.localizations.find(
      (item) => item.language === language.code,
    ),
  );

  const translationTableSubject =
    feedbackType?.receievedEmailTitle?.localized ?? {};
  const translationTableMessage =
    feedbackType?.receievedEmailDescription?.localized ?? {};

  const allTranslated = availableLanguages.every((item) => {
    return (
      translationTableSubject[item.code] &&
      translationTableSubject[item.code] !== '' &&
      translationTableMessage[item.code] &&
      Object.keys(translationTableMessage[item.code]).length !== 0
    );
  });

  const currentLanguage = availableLanguages.find(
    (item) => item.code === currentLang,
  );

  return (
    <PageContainer>
      <PageHeadLine
        title={feedbackType.name}
        onActionBack={() => {
          navigate(
            `/projects/${projectStore?.currentProject?.id}/settings/feedbacktypes`,
          );
        }}
      >
        <div className="filter-bar">
          {!feedbackType.default && (
            <LinkButton
              className="danger mr-10"
              label="Delete"
              onClick={() => {
                Swal.fire({
                  text: "Do you really want to delete this feedback type and all of it's feedback items?",
                  showCancelButton: true,
                  confirmButtonText: `Yes`,
                  denyButtonText: `No`,
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    runInAction(() => {
                      projectStore!.currentProject!.projectTypes.splice(
                        currentFeedbackTypeIndex,
                        1,
                      );
                      updateProjectTypes();
                      navigate(
                        `/projects/${projectStore?.currentProject?.id}/settings/feedbacktypes`,
                      );
                    });
                  }
                });
              }}
            />
          )}
          <PrimaryButton
            className="ml-10"
            small
            disabled={isSaveButtonDisabled}
            isLoading={isLoadingSave}
            label="Save"
            onClick={() => {
              closeAndSave();
            }}
          />
        </div>
      </PageHeadLine>
      <PageContent hasTitle className="feedback-type-editor-container">
        <div className="feedback-type-content-page">
          <div className="options-group">
            <div className="options-group-header">General</div>
            <TextInput
              name="Name"
              placeholder=""
              type="text"
              error=""
              initalValue={feedbackType.name}
              label="Name"
              readOnly={feedbackType.default}
              onChange={(text) => {
                runInAction(() => {
                  const baseName = text.toLowerCase().replace(/[^a-z]/g, '');

                  const isNameEmpty = !text.trim();
                  const isNameAlreadyExists =
                    projectStore?.currentProject?.projectTypes
                      .map((item) => item.menuName.toLowerCase())
                      .includes(baseName);

                  if (!isNameAlreadyExists && !isNameEmpty) {
                    feedbackType.name = text;
                    feedbackType.menuName = `${text}`;
                    feedbackType.path = `${baseName}`;
                    debouncedSave();
                    setSaveButtonDisabled(false);
                  } else {
                    setSaveButtonDisabled(true);
                  }
                });
              }}
            />
            <div className="mt-20">
              <div className="input-label">Icon</div>
              <TypeIconSelection
                hasBorder
                value={feedbackType.icon}
                onValueChange={(icon) => {
                  runInAction(() => {
                    feedbackType.icon = icon;
                    updateProjectTypes();
                  });
                }}
              />
            </div>
          </div>
          {feedbackType.options && feedbackType.options.possibleLanes && (
            <div className="options-group">
              <div className="options-group-header">Columns</div>
              <div className="mt-20">
                <FeedbackPossibleLanes
                  feedbackType={feedbackType.type}
                  possibleLanes={feedbackType.options.possibleLanes}
                  onChange={(possibleLanes) => {
                    runInAction(() => {
                      feedbackType.options.possibleLanes = possibleLanes;
                      debouncedSave();
                    });
                  }}
                />
              </div>
            </div>
          )}
          <div className="options-group">
            <div className="options-group-header">
              Email template for user replies
            </div>
            <EmailTemplateSelection
              templateId={feedbackType.emailReplyTemplate}
              onTemplateSelected={(templateId) => {
                runInAction(() => {
                  feedbackType.emailReplyTemplate = templateId;
                  debouncedSave();
                });
              }}
            />
            {projectStore?.isProjectAdmin && (
              <div className="mt-10">
                <a
                  href="#"
                  onClick={() => {
                    navigate(
                      `/projects/${projectStore?.currentProject?.id}/settings/email-templates`,
                    );
                  }}
                  className="info-link text-right"
                >
                  Open email template editor
                </a>
              </div>
            )}
          </div>
          <div className="options-group">
            <div className="options-group-header">
              Feedback received notification email template
            </div>
            <EmailTemplateSelection
              className="mb-30"
              templateId={feedbackType.feedbackReceivedEmailTemplate}
              onTemplateSelected={(templateId) => {
                runInAction(() => {
                  feedbackType.feedbackReceivedEmailTemplate = templateId;
                  debouncedSave();
                });
              }}
            />
            <div className="widget-preview-browser">
              <div className="widget-preview-browser-head">
                <div className="widget-preview-browser-head-close" />
                <div className="widget-preview-browser-head-minimize" />
                <div className="widget-preview-browser-head-maximize" />
              </div>
              <div className="widget-preview-browser-body">
                <div className="widget-preview-browser-body-header">
                  <TextInput
                    name="email-subject"
                    value={getLanguageProperty(
                      feedbackType,
                      'receievedEmailTitle',
                      currentLang,
                    )}
                    placeholder="Email subject"
                    type="text"
                    error=""
                    onChange={(val) => {
                      runInAction(() => {
                        setLanguageProperty(
                          feedbackType,
                          'receievedEmailTitle',
                          currentLang,
                          val,
                        );
                      });
                    }}
                  />
                  <LanguageDropdown
                    className="language-dropdown"
                    items={availableLanguages.map((lang) => {
                      return {
                        ...lang,
                        hasTranslation:
                          translationTableSubject[lang.code] &&
                          translationTableSubject[lang.code] !== '' &&
                          translationTableMessage[lang.code] &&
                          Object.keys(translationTableMessage[lang.code])
                            .length !== 0,
                      };
                    })}
                    selectedItem={currentLanguage}
                    onChange={(value) => {
                      runInAction(() => {
                        projectStore!.currentLanguage = value.code;
                      });
                    }}
                  />
                </div>
                {editorRef?.current && (
                  <>
                    <div className="toolbar-wrapper">
                      <Toolbar
                        editor={editorRef?.current}
                        aiStyle="email"
                        items={[
                          ToolbarItems.Basic,
                          ToolbarItems.Advanced,
                          ToolbarItems.Image,
                          ToolbarItems.AI,
                          ToolbarItems.VARIABLES,
                        ]}
                        additionalVariables={feedbackTypeServerVariables}
                        language={currentLang}
                        disableSmartLinks
                      />
                    </div>
                    <Toolbar
                      editor={editorRef?.current}
                      aiStyle="email"
                      items={[
                        ToolbarItems.Basic,
                        ToolbarItems.Advanced,
                        ToolbarItems.Image,
                        ToolbarItems.AI,
                        ToolbarItems.VARIABLES,
                      ]}
                      additionalVariables={feedbackTypeServerVariables}
                      floating
                      disableSmartLinks
                      language={currentLang}
                    />
                  </>
                )}
                <EmailTemplate
                  templateId={feedbackType.feedbackReceivedEmailTemplate}
                >
                  <EmailEditor
                    editorRef={editorRef}
                    onEditorReady={() => {
                      setToggleState(!toggleState);
                    }}
                    content={getLanguageProperty(
                      feedbackType,
                      'receievedEmailDescription',
                      currentLang,
                    )}
                    inputContentChanged={(content) => {
                      let stringifiedOutputData = JSON.stringify(content);
                      stringifiedOutputData = stringifiedOutputData.replaceAll(
                        'http://{{{',
                        '{{{',
                      );
                      stringifiedOutputData = stringifiedOutputData.replaceAll(
                        'https://{{{',
                        '{{{',
                      );
                      stringifiedOutputData = stringifiedOutputData.replaceAll(
                        'http://{{',
                        '{{',
                      );
                      stringifiedOutputData = stringifiedOutputData.replaceAll(
                        'https://{{',
                        '{{',
                      );
                      content = JSON.parse(stringifiedOutputData);

                      runInAction(() => {
                        setLanguageProperty(
                          feedbackType,
                          'receievedEmailDescription',
                          currentLang,
                          content,
                        );
                      });
                    }}
                  />
                </EmailTemplate>
              </div>
            </div>
            <InfoBox className="mt-20">
              You might want to use the following variables within your email
              templates: <i>{`{{feedbackId}}`}</i>, <i>{`{{{formData}}}`}</i>,{' '}
              <i>{`{{{screenshot}}}`}</i>, <i>{`{{feedbackUrl}}`}</i>,{' '}
              <i>{`{{receiverName}}`}</i>, <i>{`{{companyName}}`}</i>.
            </InfoBox>
          </div>
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore')(observer(FeedbackTypeEditor));
