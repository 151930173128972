import { Editor } from '@tiptap/react';
import { ReactComponent as MouseIcon } from 'assets/icons/computer-mouse-scrollwheel-solid.svg';
import { ReactComponent as NextIcon } from 'assets/icons/square-arrow-right-solid.svg';
import { ReactComponent as InputIcon } from 'assets/icons/input-pipe-solid.svg';
import EmailEditor from 'components/Editors/EmailEditor/EmailEditor';
import Toolbar, { ToolbarItems } from 'components/Editors/ToolBar/Toolbar';
import IconDropdown from 'components/IconDropdown/IconDropdown';
import LanguageDropdown from 'components/LanguageDropdown/LanguageDropdown';
import LinkButton from 'components/LinkButton/LinkButton';
import VideoPointerUpload from 'components/VideoPointerUpload/VideoPointerUpload';
import { getLanguageProperty, setLanguageProperty } from 'helper/AssignObjectKeysHelper';
import { inject, observer } from 'mobx-react';
import { useRef, useState } from 'react';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';

interface ProductTourEditorProps {
    availableLanguages?: any[],
    currentStep?: any,
    updateStepData: (data: any) => void,
    pickSelector: () => void,
    currentLang: string,
    setCurrentLang: (lang: string) => void,
    performClick: (selector: string) => void,
    modalStore?: ModalStore;
    projectId?: string;
}

const dropdownTypes = [
    {
        label: 'Next or Done button',
        value: 'BUTTON',
        icon: NextIcon,
    },
    {
        label: 'Click on the element',
        value: 'CLICK',
        icon: MouseIcon,
    },
    {
        label: 'Input text',
        value: 'INPUT',
        icon: InputIcon,
    },
];

const ProductTourEditor = ({
    availableLanguages,
    currentStep,
    updateStepData,
    pickSelector,
    currentLang,
    setCurrentLang,
    performClick,
    modalStore,
    projectId,
}: ProductTourEditorProps) => {
    const editorRef: React.MutableRefObject<Editor | null> = useRef(null);
    const [toggleState, setToggleState] = useState(false);

    const currentLanguage = availableLanguages?.find(
        (item) => item.code === currentLang,
    );

    if (!currentStep) {
        return null;
    }

    const isVideoPointer = currentStep.type === 'video-pointer';
    const hasTail = currentStep.type !== 'post';

    const renderOptions = () => {
        return (<div className='step-options'>
            {currentStep.type !== 'post' ? <div className="step-option-advance">
                <IconDropdown
                    value={dropdownTypes.find((dropdownType) => dropdownType.value === currentStep?.mode)}
                    placeholder={'Choose an option'}
                    options={dropdownTypes}
                    onValueChanged={(selectedItem) => {
                        updateStepData({
                            mode: selectedItem.value,
                        });

                        // Auto click on selection.
                        if (selectedItem.value === 'CLICK') {
                            performClick(currentStep.selector);
                        }
                    }}
                />
            </div> : <div></div>}
            <Row justifyContent='flex-end' alignItems='center' gap={8}>
                <PrimaryButton
                    className={'tooltip-editor-translate-button'}
                    fancy
                    small
                    label="AI-translate"
                    onClick={() => {
                    modalStore?.openModalSmart(MODALTYPE.DYNAMIC_AI_TRANSLATION, {
                        projectId: projectId,
                        baseLanguage: currentLanguage,
                        targetLanguage: availableLanguages,
                        languageList: availableLanguages,
                        getContent: (baseLang) => {
                            const content = getLanguageProperty(
                                currentStep,
                                'message',
                                baseLang,
                            );
                            return {
                                content: content,
                            };
                        },
                        setContent: (translatedContent, lang) => {
                            const { content } = translatedContent;
                            if (content) {
                                setLanguageProperty(
                                currentStep,
                                'message',
                                lang,
                                content,
                                );
                                if (lang === currentLang) {
                                updateStepData({
                                    message: currentStep.message,
                                });
                                editorRef?.current?.commands.setContent(
                                    content !== '' ? content : {},
                                );
                                }
                            }
                        },
                    });
                    }}
                />
                <LanguageDropdown
                    className="flow-chart-bot-language-dropdown"
                    items={availableLanguages ?? []}
                    selectedItem={currentLanguage}
                    onChange={(value) => {
                        setCurrentLang(value.code);
                    }}
                />
            </Row>
        </div>);
    }

    const renderEditor = () => {
        return (<>
            <div className="tour-editor-container">
                <div className="toolbar-wrapper">
                    <Toolbar
                        editor={editorRef?.current}
                        aiStyle="news"
                        items={[
                            ToolbarItems.Basic,
                            ToolbarItems.Advanced,
                            ToolbarItems.Image,
                            ...(currentStep.type === 'post' ? [ToolbarItems.Embedded] : []),
                        ]}
                        language={currentLang}
                    />
                </div>
                <div className="editor-wrapper">
                    <EmailEditor
                        key={currentLang}
                        editorRef={editorRef}
                        onEditorReady={() => {
                            setToggleState(!toggleState);
                        }}
                        content={getLanguageProperty(
                            currentStep,
                            'message',
                            currentLang,
                        )}
                        inputContentChanged={(content) => {
                            setLanguageProperty(
                                currentStep,
                                'message',
                                currentLang,
                                content,
                            );
                            updateStepData({
                                message: currentStep.message,
                            });
                        }}
                    />
                </div>
            </div>
            {renderOptions()}
        </>);
    };

    const renderVideoUpload = () => {
        return (<div className='tour-editor-video'>
            <VideoPointerUpload fileUrl={getLanguageProperty(
                currentStep,
                'videoUrl',
                currentLang,
            )} afterVideoPointerUpload={(file) => {
                if (file) {
                    setLanguageProperty(
                        currentStep,
                        'videoUrl',
                        currentLang,
                        file,
                    );
                    updateStepData({
                        videoUrl: currentStep.videoUrl,
                    });
                }
            }} />
            {renderOptions()}
        </div>);
    };

    return (<div className='tour-editor'>
        <div className={`tour-editor-inner tour-editor-type-${currentStep.type} ${hasTail && 'tour-editor-hastail'}`}>
            {isVideoPointer ? renderVideoUpload() : renderEditor()}
        </div>
        {hasTail && <div className='selector-tag-container'>
            <div className='selector-tag'>
                <input type="text" value={currentStep.selector} onChange={(e) => {
                    updateStepData({
                        selector: e.target.value,
                    });
                }} />
            </div>
            {pickSelector &&
                <LinkButton icon="crosshairs" onClick={() => {
                    pickSelector();
                }} />}
        </div>}
        {currentStep.mode === 'CLICK' && <div className='selector-elem'>
            <LinkButton label='Trigger click event' onClick={() => {
                performClick(currentStep.selector);
            }} />
        </div>}
    </div>);
};

export default inject(
    'projectStore',
    'modalStore',
)(observer(ProductTourEditor));
