/* eslint-disable jsx-a11y/label-has-associated-control */

export enum Plantypes {
  FREE = 'free',
  STARTER = 'starter',
  GROWTH = 'growth',
  PRO = 'pro',
}

export const plans = {
  free: {
    name: 'Gleap Free',
    nameSolo: 'Free',
    mainFeature1: 'Full access to detailed bug reports',
    mainFeature2: 'Access to all integrations',
    prices: ['free', 'trial'],
    monthly: {
      plan: 'free',
      price: 0,
    },
    annually: {
      plan: 'free',
      price: 0,
    },
  },
  starter: {
    name: 'Gleap Hobby',
    nameSolo: 'Hobby',
    mainFeature1: 'Access to environment data',
    mainFeature2: '31 days data retention',
    prices: [
      process.env.REACT_APP_HOBBY_PLAN,
      'price_1HldaKFIu8tJdh4ndvlfeP8T',
      'price_1IqLlGFIu8tJdh4nhxvN7PUR',
      'price_1JMu4pFIu8tJdh4nGcNyuISj',
      'price_1MVztuFIu8tJdh4nP1sEVZJX',
      'price_1MWgllFIu8tJdh4nEQwX5BJb',
      'price_1MY8meFIu8tJdh4nlFcBJvpX',
      'price_1MZwcMFIu8tJdh4niugCvrl4',
      'price_1MXMF7FIu8tJdh4n9G7VL2Ay',
    ],
    monthly: {
      plan: 'price_1MY8meFIu8tJdh4nlFcBJvpX',
      price: 30,
    },
    annually: {
      plan: 'price_1MZwcMFIu8tJdh4niugCvrl4',
      price: 24,
    },
  },
  growth: {
    name: 'Gleap Team',
    nameSolo: 'Team',
    mainFeature1: 'Full access to detailed bug reports',
    mainFeature2: 'Access to all integrations',
    prices: [
      process.env.REACT_APP_TEAM_PLAN,
      'price_1KP2xOFIu8tJdh4nrLlOv1W4',
      'price_1KP2xOFIu8tJdh4nMlFSwszP',
      'price_1HldbkFIu8tJdh4nZbwb9oA2',
      'price_1IW2ZjFIu8tJdh4ny99NS9Zq',
      'price_1IqLjeFIu8tJdh4nXhyWN0m8',
      'price_1JMwjRFIu8tJdh4njduiDLo1',
      'price_1JfjfQFIu8tJdh4n7R30m3kl',
      'price_1Jfjg2FIu8tJdh4nYeCawixv',
      'price_1MVxQFFIu8tJdh4nIedlO8Pd',
      'price_1MWJ7iFIu8tJdh4nFUVFHqdK',
      'price_1MWgdSFIu8tJdh4nfGpWj1fS',
      'price_1MXMFbFIu8tJdh4nBDPtJTcH',
      'price_1MXPuoFIu8tJdh4n9B4GdX1w',
      'price_1MXm1FFIu8tJdh4npoK2mgoi',
      'price_1MY8oQFIu8tJdh4nmWSYrzQr',
      'price_1Mg59JFIu8tJdh4nONfclUnT',
      'price_1MXm1FFIu8tJdh4npoK2mgoi',
    ],
    monthly: {
      plan: 'price_1MY8oQFIu8tJdh4nmWSYrzQr',
      price: 150,
    },
    annually: {
      plan: 'price_1Mg59JFIu8tJdh4nONfclUnT',
      price: 120,
    },
  },
  pro: {
    name: 'Gleap Pro',
    nameSolo: 'Pro',
    mainFeature1: 'Full access to detailed bug reports',
    mainFeature2: 'Access to all integrations',
    prices: [
      'price_1KbYOnFIu8tJdh4nLqWygBAY',
      'price_1KbYP2FIu8tJdh4nBGhtAm2a',
      'price_1JMu6WFIu8tJdh4n5byvxDwP',
      'price_1KPMceFIu8tJdh4nzHqNJQJ0',
      'price_1K55kyFIu8tJdh4nuMQeiXPM',
      'price_1JcmKKFIu8tJdh4nEcJOGGTM',
      'price_1IW2ZJFIu8tJdh4n80PJNY10',
      'price_1HlddCFIu8tJdh4nDwQVxDIc',
    ],
    monthly: {
      plan: 'price_1KbYP2FIu8tJdh4nBGhtAm2a',
      price: 399,
    },
    annually: {
      plan: 'price_1KbYOnFIu8tJdh4nLqWygBAY',
      price: 3588,
    },
  },
};