import FancyBlurBG2 from 'assets/bg/fancyblurbg2opa.png';
import OutboundBlankImage from 'assets/icons/outboundmessages.png';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { inject, observer } from 'mobx-react';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';

const UpgradePlan = ({ modalStore }: {
    modalStore?: ModalStore,
}) => {
    return (
        <div className='outreach-addon'>
            <div className='intro-card' style={{
                backgroundImage: `url(${FancyBlurBG2})`
            }}>
                <div className='intro-card-content'>
                    <h1>Subscription expired</h1>
                    <p className='text'>Your subscription expired. Please choose a subscription to get started.</p>
                    <p className='text'>
                        <span className='title-text'>🚀 Increase sales</span><br />
                        <span className='title-text'>👏 Pro-active support</span><br />
                        <span className='title-text'>🤖 Kai, AI chatbot</span>
                    </p>
                    <PrimaryButton label="Upgrade now" onClick={() => {
                        modalStore?.openModalSmart(MODALTYPE.MANAGE_SUBSCRIPTION);
                    }} />
                </div>
                <img className='intro-card-image' src={OutboundBlankImage} style={{
                    objectPosition: 'center'
                }} />
            </div>
        </div>
    );
};

export default inject('modalStore')(observer(UpgradePlan));