import { inject, observer } from 'mobx-react';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import './OrganisationPrivacy.scss';

import LinkButton from 'components/LinkButton/LinkButton';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import { UsersStore } from 'stores/private/UsersStore';


interface OrganisationPrivacyProps {
  organisationStore?: OrganisationStore;
  usersStore?: UsersStore;
}
const OrganisationPrivacy = ({
  organisationStore,
  usersStore,
}: OrganisationPrivacyProps) => {
  const organisation = organisationStore?.currentOrganisation;

  if (!organisation) {
    return null;
  }

  return (
    <PageContainer>
      <PageHeadLine title="Privacy documents" />
      <PageContent hasTitle isMediumBoxed>
        <div className="options-group">
          <div className="options-group-header">Privacy documents</div>
          <div className="danger-zone">
            <div className="danger-zone-container">
              <div className="danger-zone-item">
                <div className="danger-zone-label">Data processing addendum</div>
                <LinkButton
                  iconSideRight={false}
                  icon="file-shield"
                  label="Show"
                  onClick={() => {
                    window.open('https://www.gleap.io/data-processing-addendum', '_blank');
                  }}
                />
              </div>
              <div className="danger-zone-item">
                <div className="danger-zone-label">Privacy policy</div>
                <LinkButton
                  iconSideRight={false}
                  icon="shield-halved"
                  label="Show"
                  onClick={() => {
                    window.open('https://gleap.io/privacy-policy/', '_blank');
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'organisationStore',
  'usersStore',
)(observer(OrganisationPrivacy));
