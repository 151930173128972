import GleapWidget from 'assets/GleapWidget.png';
import InvitationCard from 'components/InvitationCard/InvitationCard';
import OnboardingHeader from 'components/OnboardingHeader/OnboardingHeader';
import { inject, observer } from 'mobx-react';
import { UsersStore } from 'stores/private/UsersStore';
import './InvitationsOnboarding.scss';

interface InvitationsOnboardingProps {
    usersStore?: UsersStore;
}

export const InvitationsOnboarding = ({ usersStore }: InvitationsOnboardingProps) => {
    if (!usersStore) {
        return <></>;
    }

    const user = usersStore!.currentUser;
    if (!user) {
        return <></>;
    }

    /*return (
        <>
            <div className="invitation-onboarding">
                <div className="invitation-onboarding-body">
                    <div className="invitation-onboarding-body--header">
                        <HeadLine
                            title={`Hi ${user.firstName} 👋`}
                            subtitle="You have been invited to join an existing team."
                        />
                        <LinkButton label='Skip' onClick={() => usersStore!.skipInvitations()} />
                    </div>
                    <div className="invitation-onboarding-body--body">
                        {usersStore!.invitations.length > 0 && (
                            <>
                                {usersStore!.invitations.map((invitation, key) => {
                                    return <InvitationCard invitation={invitation} key={key} />;
                                })}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );*/

    return (
        <>

            <div className='onboarding-cont'>
                <div className='onboarding-cont-main'>
                    <div className='onboarding-title'>
                        Hey {usersStore?.currentUser?.firstName ?? 'there'} 👋<br />
                        You have been invited to join an existing team.
                    </div>
                    <div className='invitations-list'>
                        {usersStore!.invitations.length > 0 && (
                            <>
                                {usersStore!.invitations.map((invitation, key) => {
                                    return <InvitationCard invitation={invitation} key={key} />;
                                })}
                            </>
                        )}
                    </div>
                    <div className='mt-30'>
                        <a href="#" onClick={() => usersStore!.skipInvitations()}>Skip</a>
                    </div>
                </div>
                <div className='onboarding-cont-side'>
                    <img src={GleapWidget} />
                </div>
            </div>
        </>
    );
};

export default inject('usersStore')(observer(InvitationsOnboarding));
