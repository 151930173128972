import axios from '../Axios';

const getAuthCodeFromHelpscout = (projectID) => {
  return window.open(
    `https://secure.helpscout.net/authentication/authorizeClientApplication?client_id=${process.env.REACT_APP_HELPSCOUT_APP_ID}&state=${projectID}`,
  );
};

const setHelpscoutAuthToken = (code, projectID) => {
  return axios.post(`/projects/${projectID}/integrations/helpscout`, {
    code,
  });
};

const setHelpscoutActions = ({
  projectID,
  integrationID,
  actions,
  settings,
}) => {
  return axios.put(
    `/projects/${projectID}/integrations/helpscout/${integrationID}`,
    {
      actions,
      settings,
    },
  );
};

const disconnectHelpscoutIntegration = ({ projectID, integrationID }) => {
  return axios.delete(
    `/projects/${projectID}/integrations/helpscout/${integrationID}`,
  );
};

const getHelpscoutMailboxes = ({ projectID, integrationID }) => {
  return axios.get(
    `/projects/${projectID}/integrations/helpscout/${integrationID}/mailboxes`,
  );
};

export {
  getAuthCodeFromHelpscout,
  setHelpscoutAuthToken,
  setHelpscoutActions,
  disconnectHelpscoutIntegration,
  getHelpscoutMailboxes,
};
