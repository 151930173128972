import { inject, observer } from 'mobx-react';
import React from 'react';
import { ModalStore } from 'stores/private/ModalStore';

interface ChildrenModalProps {
  modalStore?: ModalStore;
}

const ChildrenModal = ({ modalStore }: ChildrenModalProps) => {
  const children = modalStore?.customData?.children ?? <></>;
  return children;
};

export default inject('modalStore')(observer(ChildrenModal));
