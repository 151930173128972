import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import { languages } from 'constants/Languages';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { ProjectStore } from 'stores/private/ProjectStore';

interface ProjectLanguageDropdownProps {
  projectStore?: ProjectStore;
}

const ProjectLanguageDropdown = ({
  projectStore,
}: ProjectLanguageDropdownProps) => {
  const availableLanguages = languages.filter((language) =>
    projectStore?.currentProject?.localizations.find(
      (item) => item.language === language.code,
    ),
  );


  return (
    <SelectDropDown
      showBorder
      items={availableLanguages}
      selectedItem={availableLanguages.find(
        (lang) => lang.code === projectStore?.currentLanguage,
      )}
      labelPropertyName="name"
      valuePropertyName="code"
      placeholder="Select language"
      onChange={(value) => {
        runInAction(() => {
          projectStore!.currentLanguage = value.code;
        });
      }}
    />
  );
};

export default inject('projectStore')(observer(ProjectLanguageDropdown));
