import { GenericHttpClient } from './config/http.generic.client';

export class HttpTeamService extends GenericHttpClient<any> {
  static _instance: HttpTeamService;
  static getInstance(): HttpTeamService {
    if (this._instance == null) {
      this._instance = new HttpTeamService('/teams');
    }
    return this._instance;
  }
}
