import { inject, observer } from 'mobx-react';
import DatePicker from 'react-date-picker-gleap/dist/entry';
import { BugStore } from 'stores/private/BugStore';
import './DueToDataSelection.scss';

interface BugAssignUserProps {
  bugStore?: BugStore;
  placeholder?: string;
}

const DueToDataSelection = ({
  bugStore,
  placeholder,
}: BugAssignUserProps) => {
  const bug = bugStore?.currentBug;
  if (!bug) {
    return <></>;
  }

  const getDueDate = () => {
    if (typeof bug.dueDate === "string") {
      if (bug.dueDate === '') {
        return null;
      }
      return new Date(bug.dueDate);
    }

    return bug.dueDate;
  }

  return (
    <DatePicker
      placeholderText={placeholder ? placeholder : "Select due date"}
      onChange={(selectedDate) => {
        bugStore!.updateBug(bug.id, {
          dueDate: selectedDate ? selectedDate : '',
        });
      }}
      value={getDueDate()}
      showLeadingZeros={true}
      calendarIcon={<i className="fa-solid fa-calendar" />}
    />
  );
};

export default inject(
  'bugStore',
)(observer(DueToDataSelection));
