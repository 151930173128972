import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import TextInput from 'components/TextInput/TextInput';
import React, { useState, useEffect } from 'react';
import { countryTableAsKeyValue } from 'services/CountryHelper';

interface Address {
    description: string | null;
    first_line: string | null;
    second_line: string | null;
    city: string | null;
    postal_code: string;
    region: string | null;
    country_code: string;
}

interface Props {
    value?: Address;
    onChange?: (data: Address) => void;
}

const defaultData: Address = {
    description: null,
    first_line: null,
    second_line: null,
    city: null,
    postal_code: "",
    region: null,
    country_code: "US",
};

const PaddleBillingAddress: React.FC<Props> = ({ value = defaultData, onChange }) => {
    const [data, setData] = useState<Address>(value);
    const countryList = Object.entries(countryTableAsKeyValue).map(([code, name]) => {
        return {
            id: code,
            label: name
        }
    });

    const handleChange = (name: keyof Address, val: string) => {
        const updatedData = { ...data, [name]: val };
        setData(updatedData);
        onChange?.(updatedData);
    };

    useEffect(() => {
        setData(value);
    }, [value]);

    return (
        <>
            <TextInput
                className="mb-5"
                error=""
                placeholder="Address"
                value={data.first_line}
                onChange={(value) => handleChange("first_line", value)}
            />
            <TextInput
                className="mb-5"
                error=""
                placeholder="City"
                value={data.city}
                onChange={(value) => handleChange("city", value)}
            />
            <TextInput
                className="mb-5"
                error=""
                placeholder="Postal Code"
                value={data.postal_code}
                onChange={(value) => handleChange("postal_code", value)}
            />
            <TextInput
                className="mb-5"
                error=""
                placeholder="Region"
                value={data.region}
                onChange={(value) => handleChange("region", value)}
            />
            <div className="mb-5">
                <SelectDropDown
                    labelPropertyName="label"
                    valuePropertyName="id"
                    selectedItem={countryList.find((elem) => {
                        return elem.id === data.country_code;
                    })}
                    items={countryList}
                    onChange={(option) => {
                        handleChange("country_code", option.id);
                    }}
                />
            </div>
        </>
    );
}

export default PaddleBillingAddress;