import TabButton from 'components/TabButton/TabButton';
import './AggregationPicker.scss';

interface AggregationPickerProps {
  setFilter: (filter: string) => void;
  filter?: string;
  className?: string;
}

const AggregationPicker = ({
  setFilter,
  filter = 'median',
  className,
}: AggregationPickerProps) => {
  return (
    <div className={`tab-buttons ${className}`}>
      <div className="tab-buttons--inner">
        <TabButton
          active={filter === 'MEDIAN'}
          label="Median"
          onClick={() => {
            setFilter('MEDIAN');
          }}
        />
        <TabButton
          active={filter === 'AVERAGE'}
          label="Average"
          onClick={() => {
            setFilter('AVERAGE');
          }}
        />
      </div>
    </div>
  );
};

export default AggregationPicker;
