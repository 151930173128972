import ReplaysInfo from 'assets/icons/ReplayFrame.png';
import classNames from 'classnames';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SuggestSubscription from 'components/SuggestSubscription/SuggestSubscription';
import { inject } from 'mobx-react';
import { Feature } from 'models/Enums';
import 'rc-slider/assets/index.css';
import { useNavigate } from 'react-router';
import { BugStore } from 'stores/private/BugStore';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import suggestSubscriptionIcon from '../../../assets/icons/video-duotone.svg';
import AppReplay from './AppReplay';
import './Replay.scss';
import WebReplay from './WebReplay';

interface ReplayProps {
  bugStore?: BugStore;
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

const Replay = ({
  bugStore,
  projectStore,
  modalStore,
}: ReplayProps) => {
  const navigate = useNavigate();
  const bug = bugStore!.currentBug;
  const customData = modalStore?.getCustomData(MODALTYPE.REPLAYS) ?? {};
  const shared = customData?.shared;

  const planIsEntitled = bugStore?.isFeatureInPlan(Feature.REPLAYS);
  const darkMode = localStorage.getItem('theme') === 'dark';
  const suggestSubscriptionClass = classNames(
    {
      'replay-container--bg--light': !darkMode,
      'replay-container--bg--dark': darkMode,
    },
    'replay-container replay-container--bg',
  );

  if (!planIsEntitled) {
    return (
      <div className={suggestSubscriptionClass}>
        <SuggestSubscription
          icon={suggestSubscriptionIcon}
          title="Replays"
          description="Unlock Gleap Team for $109/month to access a full replay of a user’s steps before a report, allowing you to see exactly what they see."
        />
      </div>
    );
  }

  if (bug && bug.replay && bug.replay.frames.length > 0) {
    return <AppReplay />;
  }

  if (bug && bug.hasWebReplay) {
    return <WebReplay />;
  }

  const navigateToReplaySettings = () => {
    modalStore!.closeModal();
    modalStore!.closeModal();

    setTimeout(() => {
      navigate(
        `/projects/${projectStore?.currentProject?.id}/settings/developer`,
      );
    }, 800);
  }

  return (
    <div className="replay-container replay-container--no-contents text">
      <img src={ReplaysInfo} className="no-replays" alt="Gleap Replays" />
      <div className='text'>No replay recorded for this ticket. Enable replays to get started.</div>
      {!shared && (
        <div className="mt-20">
          <PrimaryButton
            label="Enable replays"
            onClick={() => {
              navigateToReplaySettings();
            }}
          />
        </div>
      )}
    </div>
  );
};

export default inject('bugStore', 'projectStore', 'modalStore')(Replay);
