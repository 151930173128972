import { inject, observer } from 'mobx-react';
import { BugStore } from 'stores/private/BugStore';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './MoveHelpcenterArticleModal.scss';
import { HelpcenterStore } from 'stores/private/HelpcenterStore';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import { HeadLine } from 'components/HeadLine/HeadLine';
import LinkButton from 'components/LinkButton/LinkButton';
import { useEffect, useState } from 'react';
import { getHelpcenterCollections } from 'services/HelpcenterHttpService';
import { useNavigate } from 'react-router';

interface MoveHelpcenterArticleModalProps {
  bugStore?: BugStore;
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
  helpcenterStore?: HelpcenterStore;
}

const MoveHelpcenterArticleModal = ({
  modalStore,
  projectStore,
  helpcenterStore,
}: MoveHelpcenterArticleModalProps) => {
  const navigate = useNavigate();

  const data = modalStore?.customData;
  const helpcenterConfig = projectStore?.flowConfig?.helpcenterConfig;
  const previewLanguageCode = helpcenterConfig?.localization[0].language;
  const [selectedCollection, setSelectedCollection] = useState('');
  const [collections, setCollections] = useState([] as any[]);

  const loadCollections = async () => {
    if (!projectStore?.currentProject?.id) {
      return;
    }

    const allCollections = await getHelpcenterCollections(
      projectStore?.currentProject.id,
      'all',
    );
    setCollections(allCollections.data);
  };

  useEffect(() => {
    loadCollections();
  }, []);

  const title =
    (previewLanguageCode &&
      data.title &&
      Object.keys(data.title).length > 0 &&
      data.title[previewLanguageCode] !== '' &&
      data.title[previewLanguageCode]) ??
    'No title set for default language';

  return (
    <div className="move-article-modal">
      <div className="move-article-modal__header mb-10">
        <HeadLine
          title={'Move this article into another collection'}
          subtitle={title}
        ></HeadLine>
      </div>
      <SelectDropDown
        label="Select collection"
        selectedItem={selectedCollection}
        items={(collections || [])
          .filter(
            (collection) =>
              collection.id !== modalStore?.customData.helpcenterCollection,
          )
          .map((item) => {
            const title =
              previewLanguageCode && item.title[previewLanguageCode];
            return {
              id: item.id,
              label: title,
            };
          })}
        onChange={(e) => {
          if (e.id) {
            const newCollectionId = e.id;
            setSelectedCollection(newCollectionId);
          }
        }}
      />
      <div className="mt-10">
        <LinkButton
          disabled={!selectedCollection}
          label="Move article"
          onClick={async () => {
            const movedHelpcenterArticle = await helpcenterStore?.moveArticle(
              data,
              selectedCollection,
            );

            if (movedHelpcenterArticle) {
              navigate(
                `/projects/${projectStore?.currentProject?.id}/helpcenter/collections/${selectedCollection}`,
              );

              modalStore?.closeModal();
            }
          }}
        />
      </div>
    </div>
  );
};

export default inject(
  'modalStore',
  'projectStore',
  'helpcenterStore',
)(observer(MoveHelpcenterArticleModal));
