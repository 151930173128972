import FutureStatisticDataTable, {
  StatisticListTypes,
} from 'components/Charts/StatisticDataTable/FutureStatisticDataTable';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import { inject, observer } from 'mobx-react';
import { getListStatistic } from 'services/StatisticsService';
import { ProjectStore } from 'stores/private/ProjectStore';
import './CustomerSupport.scss';

interface CustomerSupportSnapshotProps {
  projectStore?: ProjectStore;
}

const CustomerSupportSnapshot = ({
  projectStore,
}: CustomerSupportSnapshotProps) => {
  const projectId = projectStore?.currentProject?.id;

  return (
    <PageContainer>
      <PageHeadLine title="Snapshot" tag="Live" />
      <PageContent hasTitle>
        <FutureStatisticDataTable
          infoText="Current ticket counts by type and status."
          future={async () => {
            return getListStatistic({
              projectId: projectId,
              query: {
                chartType: StatisticListTypes.SNAPSHOT_TICKETS,
              },
            });
          }}
        />
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore')(observer(CustomerSupportSnapshot));
