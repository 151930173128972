import InfoBox from 'components/InfoBox/InfoBox';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Action } from 'models/Bot';
import Switch from 'react-switch';
import { ModalStore } from 'stores/private/ModalStore';
import './CustomActionEditor.scss';

interface CustomActionEditorProps {
  action: Action;
  modalStore?: ModalStore;
}

const CustomActionEditor = ({
  action,
  modalStore,
}: CustomActionEditorProps) => {
  return (
    <div className="api-action-editor">
      <PageContainer>
        <PageHeadLine title="Start custom action">
          <div className="header-content-right">
            <PrimaryButton
              label="Close"
              iconSideRight={false}
              onClick={() => {
                modalStore!.closeModal();
              }}
            />
          </div>
        </PageHeadLine>
        <PageContent hasTitle>
          <TextInput
            name="custom-action-name"
            className="mt-20"
            label="Custom action name"
            value={action.actionName ?? ''}
            placeholder=""
            type="text"
            error=""
            onChange={(val) => {
              runInAction(() => {
                action.actionName = val;
              });
            }}
          />
          <InfoBox className="mt-20">
            You can find more information on custom actions in{' '}
            <a href="https://docs.gleap.io/javascript/custom-actions">
              our docs
            </a>
            .
          </InfoBox>

          <div className="input-label mt-30 mb-10">Options</div>
          <div className="switch-container mb-20">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={(checked) => {
                runInAction(() => {
                  action.closeWidget = checked;
                });
              }}
              checked={action.closeWidget ?? true}
            />
            <span>Close widget automatically when custom action is triggered.</span>
          </div>
        </PageContent>
      </PageContainer>
    </div>
  );
};

export default inject('modalStore')(observer(CustomActionEditor));
