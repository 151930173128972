import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import TextHeadline from 'components/TextHeadline/TextHeadline';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore, menuOptions } from 'stores/private/ProjectStore';
import './AddMenuOptionModal.scss';
import { getTranslatedDefaultObject } from 'services/Helper';
import { getInfoForAppWidget } from 'pages/private/ProjectWidget/FormWidgetSettings/FormWidgetSettings';

interface AddMenuOptionModalProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

const AddMenuOptionModal = ({
  projectStore,
  modalStore,
}: AddMenuOptionModalProps) => {
  const flowConfig = projectStore?.flowConfig;

  return (
    <SizedContainer size={ContainerSizes.XXL} className="centered">
      <TextHeadline text="Add a new home widget" />
      <div className="addmenuoptions mt-20">
        {menuOptions.map((homeWidget, index) => {
          let title = "";
          let icon: any = undefined;
          const isMenu = homeWidget.type === 'menu';

          if (homeWidget.type === 'menu') {
            icon = homeWidget.config.icon;
            title = getTranslatedDefaultObject(homeWidget.config.title);
          } else if (homeWidget.type === 'app') {
            const info = getInfoForAppWidget(homeWidget.config.app!);

            title = info.title;
            icon = info.icon;
          }

          return (
            <div
              key={index}
              className="card addmenuoption-card"
              onClick={() => {
                runInAction(() => {
                  const newHomeWidgets = [...projectStore!.currentProject!.homeWidgets, homeWidget];
                  projectStore?.updateProject(projectStore.currentProject!.id, {
                    homeWidgets: newHomeWidgets,
                  }, true, false, true);

                  modalStore!.closeModal();
                });
              }}
            >
              <div
                className={`icon-container ${!isMenu ? 'icon-container--appicon' : ''}`}
              >
                {!isMenu ? (<i className={`fa-solid fa-${icon}`} />) : (<img alt="Menu option" src={icon} />)}
              </div>
              <div className="info">
                <div className="title">{title}</div>
                <div className="description">{isMenu ? 'Menu item' : 'App widget'}</div>
              </div>
            </div>
          );
        })}
      </div>
    </SizedContainer>
  );
};

export default inject(
  'projectStore',
  'modalStore',
)(observer(AddMenuOptionModal));
