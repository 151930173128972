import classNames from 'classnames';
import InfoBox from 'components/InfoBox/InfoBox';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import ProjectUserSelection from 'components/ProjectUserSelection/ProjectUserSelection';
import TextInput from 'components/TextInput/TextInput';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { ProjectTeam } from 'models/ProjectTeam';
import { useState } from 'react';
import Collapsible from 'react-collapsible';
import { toast } from 'react-toastify';
import { HttpTeamService } from 'services/http.clients/http.team.client';
import { ProjectStore } from 'stores/private/ProjectStore';
import './ProjectTeamSettings.scss';
import LinkButton from 'components/LinkButton/LinkButton';
import Swal from 'sweetalert2';
import Switch from 'react-switch';

interface ProjectTeamSettingsProps {
  projectStore?: ProjectStore;
}

const ProjectTeamSettings = ({ projectStore }: ProjectTeamSettingsProps) => {
  const teams = projectStore?.currentProjectTeams ?? [];

  const [isLoading, setIsLoading] = useState(false);

  const saveTeam = async (team: ProjectTeam) => {
    setIsLoading(true);

    try {
      if (team._id) {
        await HttpTeamService.getInstance().updateOne({
          id: team._id,
          data: team,
        });
      } else {
        await HttpTeamService.getInstance().create({ data: team });
      }

      toast.success('Team saved');

      projectStore?.getProjectTeams();
    } catch (_) {
      toast.error('Failed to save team');
    }

    setIsLoading(false);
  };

  const deleteTeam = async (team: ProjectTeam, teamIndex: number) => {
    setIsLoading(true);

    try {
      if (team._id) {
        await HttpTeamService.getInstance().deleteOne({
          id: team._id,
        });
        toast.success(`${team.name} deleted`);
        projectStore?.getProjectTeams();
      } else {
        throw new Error('Team ID is missing.');
      }
    } catch (error) {
      runInAction(() => {
        const newTeams = [...teams];
        newTeams.splice(teamIndex, 1);
        projectStore!.currentProjectTeams = [...newTeams];
        toast.success(`${team.name} deleted`);
      });
    }

    setIsLoading(false);
  };

  const _buildAssignmentMethod = (
    isActive,
    icon,
    title,
    description,
    onClick,
  ) => {
    const assignmentClass = classNames({
      'assignment-method': true,
      'assignment-method--active': isActive,
    });

    return (
      <Column onClick={onClick} alignItems="center" className={assignmentClass}>
        <i className={`fa-light ${icon} assignment-method-icon`} />
        <div className="bold">{title}</div>
        <div className="text assignment-method-description">{description}</div>
      </Column>
    );
  };

  const _buildMemberItem = (memberId: any, teamIndex: number) => {
    if (!projectStore?.currentProjectUsers) {
      return <></>;
    }

    // Find member in project users
    const member = projectStore?.currentProjectUsers.find(
      (user) => user.id === memberId,
    );
    if (!member) {
      return <></>;
    }

    return (
      <Row
        alignItems="center"
        className="member-item"
        justifyContent="space-between"
      >
        <Row alignItems="center">
          <UserAvatar
            className="member-item-avatar"
            small
            email={member.email}
            imageUrl={member.profileImageUrl}
          />
          <div className="member-item-name ml-10 text bold">
            {member.firstName}
          </div>
        </Row>
        <i
          className="fa-solid fa-trash member-item-delete"
          onClick={() => {
            runInAction(() => {
              const newTeams = [...teams];
              const team = newTeams[teamIndex];
              team.members = team.members.filter(
                (member: any) => member !== memberId,
              );

              projectStore!.currentProjectTeams = [...newTeams];
            });
          }}
        />
      </Row>
    );
  };

  const _buildTeamItem = (team: ProjectTeam, index: number) => {
    return (
      <Collapsible
        trigger={<div className="action-item-header">{team.name}</div>}
        transitionTime={200}
        overflowWhenOpen="initial"
        openedClassName="Collapsible--opened"
        onClose={() => {}}
      >
        <Column justifyContent="flex-start" alignItems="flex-start">
          <TextInput
            placeholder="Template name"
            type="text"
            error=""
            value={team.name}
            onChange={(text) => {
              const newTeams = [...teams];
              newTeams[index].name = text;

              runInAction(() => {
                projectStore!.currentProjectTeams = [...newTeams];
              });
            }}
          />
          <div className="bold mt-30">Choose assignment method</div>
          <div className="grid project-methods-grid mt-15">
            {_buildAssignmentMethod(
              team.assignmentMethod === 'manual',
              'fa-comment',
              'Manual',
              'Tickets are not automatically assigned',
              () => {
                const newTeams = [...teams];
                newTeams[index].assignmentMethod = 'manual';

                runInAction(() => {
                  projectStore!.currentProjectTeams = [...newTeams];
                });
              },
            )}
            {_buildAssignmentMethod(
              team.assignmentMethod === 'random',
              'fa-shuffle',
              'Random',
              'Tickets are assigned to teammates in a random order',
              () => {
                const newTeams = [...teams];
                newTeams[index].assignmentMethod = 'random';

                runInAction(() => {
                  projectStore!.currentProjectTeams = [...newTeams];
                });
              },
            )}
            {_buildAssignmentMethod(
              team.assignmentMethod === 'balanced',
              'fa-scale-balanced',
              'Balanced',
              'Tickets are assigned to teammates with the fewest active tickets',
              () => {
                const newTeams = [...teams];
                newTeams[index].assignmentMethod = 'balanced';

                runInAction(() => {
                  projectStore!.currentProjectTeams = [...newTeams];
                });
              },
            )}
            {_buildAssignmentMethod(
              team.assignmentMethod === 'circular',
              'fa-arrow-rotate-right',
              'Circular',
              'Tickets are assigned to teammates in circular order',
              () => {
                const newTeams = [...teams];
                newTeams[index].assignmentMethod = 'circular';

                runInAction(() => {
                  projectStore!.currentProjectTeams = [...newTeams];
                });
              },
            )}
          </div>
          <div className="bold mt-30 mb-15">Members</div>
          <div className="switch-container mb-20">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={(checked) => {
                runInAction(() => {
                  const newTeams = [...teams];
                  const team = newTeams[index];
                  team.selectOnlyOnlineMembers = checked;

                  projectStore!.currentProjectTeams = [...newTeams];
                });
              }}
              checked={team.selectOnlyOnlineMembers ? true : false}
            />
            <span>
              Select only online members (last seen within the last 5 minutes)
            </span>
          </div>
          <div className="members-list mb-15">
            {team.members.map((memberId: any) => {
              return _buildMemberItem(memberId, index);
            })}
          </div>
          <ProjectUserSelection
            onSelectedUser={(user) => {
              runInAction(() => {
                const newTeams = [...teams];
                const team = newTeams[index];
                const userExists = team.members.find(
                  (member) => member === user.id,
                );
                if (!userExists) {
                  team.members.push(user.id);
                }

                projectStore!.currentProjectTeams = [...newTeams];
              });
            }}
          />
          <div className="form-buttons">
            <PrimaryButton
              icon="save"
              label="Save"
              className="mr-10 mt-20"
              isLoading={isLoading}
              onClick={() => {
                saveTeam(team);
              }}
            />
            <LinkButton
              iconSideRight={false}
              className="danger-button"
              label="Delete"
              icon={'trash'}
              onClick={() => {
                Swal.fire({
                  title: `Do you really want to delete this team?`,
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'Yes, delete it!',
                }).then((result) => {
                  if (result.isConfirmed) {
                    deleteTeam(team, index);
                  }
                });
              }}
            />
          </div>
        </Column>
      </Collapsible>
    );
  };

  return (
    <PageContainer>
      <PageHeadLine title="Teams" />
      <PageContent hasTitle isMediumBoxed>
        {teams.map((team: ProjectTeam, index: number) => {
          return _buildTeamItem(team, index);
        })}
        {teams.length === 0 && (
          <InfoBox>
            No teams yet. You can add teams to your project to manage your
            project.
          </InfoBox>
        )}
        <PrimaryButton
          icon="plus"
          label="Add new team"
          className="mr-10 mt-20"
          onClick={() => {
            runInAction(() => {
              projectStore!.currentProjectTeams = [
                ...teams,
                {
                  name: 'New team',
                  assignmentMethod: 'random',
                  members: [],
                },
              ];
            });
          }}
        />
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore')(observer(ProjectTeamSettings));
