import { Editor } from '@tiptap/react';
import EmailEditor from 'components/Editors/EmailEditor/EmailEditor';
import Toolbar, { ToolbarItems } from 'components/Editors/ToolBar/Toolbar';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { ModalStore } from 'stores/private/ModalStore';
import './MessageActionEditor.scss';
import { ProjectStore } from 'stores/private/ProjectStore';
import {
  getLanguageProperty,
  setLanguageProperty,
} from 'helper/AssignObjectKeysHelper';
import { languages } from 'constants/Languages';
import LanguageDropdown from 'components/LanguageDropdown/LanguageDropdown';
import classNames from 'classnames';

interface MessageActionEditorProps {
  action: any;
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
}

const MessageActionEditor = ({
  action,
  modalStore,
  projectStore,
}: MessageActionEditorProps) => {
  const editorRef: MutableRefObject<Editor | null> = useRef(null);
  const [toggleState, setToggleState] = useState(false);

  const currentLang = projectStore?.currentLanguage ?? 'en';
  const availableLanguages = languages.filter((language) =>
    projectStore?.currentProject?.localizations.find(
      (item) => item.language === language.code,
    ),
  );

  const translationTableContent = action?.data?.content?.localized ?? {};

  const allTranslated = availableLanguages.every((item) => {
    return (
      translationTableContent[item.code] &&
      Object.keys(translationTableContent[item.code]).length !== 0
    );
  });

  const currentLanguage = availableLanguages.find(
    (item) => item.code === currentLang,
  );

  useEffect(() => {
    const message = getLanguageProperty(action, 'data.content', currentLang);

    editorRef?.current?.commands.setContent(message !== '' ? message : {});
  }, [editorRef, currentLang]);

  const messageActionContainerClassName = classNames({
    'message-action-container': true,
    'message-action-container--notalltranslated': !allTranslated,
    'message-action-container--alltranslated': allTranslated,
  });

  return (
    <div className={messageActionContainerClassName}>
      <PageContainer>
        <PageHeadLine title="Edit chat message">
          <LanguageDropdown
            className="language-dropdown ml-15"
            items={availableLanguages.map((lang) => {
              return {
                ...lang,
                hasTranslation:
                  translationTableContent[lang.code] &&
                  Object.keys(translationTableContent[lang.code]).length !== 0,
              };
            })}
            selectedItem={currentLanguage}
            onChange={(value) => {
              runInAction(() => {
                projectStore!.currentLanguage = value.code;
              });
            }}
          />
          <div className="header-content-right">
            <PrimaryButton
              label="Close"
              iconSideRight={false}
              onClick={() => {
                modalStore!.closeModal();
              }}
            />
          </div>
        </PageHeadLine>
        <PageContent hasTitle hasPadding={false}>
          <div className="bot-message-editor">
            <div className="toolbar-wrapper">
              <Toolbar
                editor={editorRef?.current}
                aiStyle="agent"
                items={[
                  ToolbarItems.Basic,
                  ToolbarItems.Advanced,
                  ToolbarItems.Image,
                  ToolbarItems.Embedded,
                  ToolbarItems.AI,
                  ToolbarItems.VARIABLES,
                ]}
                language={currentLang}
              />
            </div>
            <div className="editor-wrapper">
              <EmailEditor
                editorRef={editorRef}
                onEditorReady={() => {
                  setToggleState(!toggleState);
                }}
                placeholder="Write your chat message..."
                content={getLanguageProperty(
                  action,
                  'data.content',
                  currentLang,
                )}
                inputContentChanged={(content) => {
                  runInAction(() => {
                    setLanguageProperty(
                      action,
                      `data.content`,
                      currentLang,
                      content,
                    );
                  });
                }}
              />
            </div>
          </div>
        </PageContent>
      </PageContainer>
    </div>
  );
};

export default inject(
  'modalStore',
  'projectStore',
)(observer(MessageActionEditor));
