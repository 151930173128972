/* eslint-disable prefer-destructuring */
import ChecklistDetails from 'components/ChecklistDetails/ChecklistDetails';
import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper';
import EventTimeline from 'components/EventTimeline/EventTimeline';
import FeedbackItem from 'components/FeedbackItem/FeedbackItem';
import { cleanupName } from 'components/FeedbackUserFilter/FeedbackUserFilter';
import LinkButton from 'components/LinkButton/LinkButton';
import Loading from 'components/Loading/Loading';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import StripeDetails from 'components/StripeDetails/StripeDetails';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import { getSessionName } from 'services/GuestNameHelper';
import { getChecklistsForSession } from 'services/ProjectHttpService';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { SessionStore } from 'stores/private/SessionStore';
import Swal from 'sweetalert2';
import SessionDetails from '../../../components/SessionDetails/SessionDetails';
import './ProjectContactDetails.scss';
import SessionMuteButton from 'components/SessionMuteButton/SessionMuteButton';

interface ProjectContactDetailsProps {
  projectStore?: ProjectStore;
  sessionStore?: SessionStore;
  modalStore?: ModalStore;
}

const ProjectContactDetails = ({
  projectStore,
  sessionStore,
  modalStore,
}: ProjectContactDetailsProps) => {
  // @ts-ignore
  const { projectId, contactId } = useParams();
  const session = sessionStore?.session;
  const navigate = useNavigate();
  const [checklists, setCheclists] = useState(null as any);

  const loadChecklistData = async (contactId) => {
    setCheclists(null);

    try {
      const response = await getChecklistsForSession({
        sessionId: contactId,
        projectId: projectStore?.currentProject?.id ?? '',
        limit: 10,
      });

      if (response.status === 200) {
        setCheclists(response.data);
      }
    } catch (err: any) {}
  };

  useEffect(() => {
    const handlePopstate = () => {
      navigate(`/projects/${projectStore?.currentProject?.id}/sessions`);
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  useEffect(() => {
    if (contactId && projectStore?.currentProject?.id) {
      loadChecklistData(contactId);
    } else {
      setCheclists(null);
    }
  }, [contactId]);

  useEffect(() => {
    projectStore!.loadProjectById(projectId!);
  }, []);

  useEffect(() => {
    if (projectStore?.currentProject) {
      sessionStore!.loadSession(contactId);
    }
  }, [projectStore?.currentProject, contactId]);

  if (!session) {
    return (
      <PageContainer>
        <PageContent isCentered>
          <Loading />
        </PageContent>
        <Outlet />
      </PageContainer>
    );
  }

  const isOnline = (Date.now() - Date.parse(session.lastActivity)) / 60000 < 2;

  const renderChecklists = () => {
    if (!checklists || checklists.length === 0) {
      return null;
    }

    return (
      <ComponentWrapper
        className="checklists-details-group mb-20"
        title="Checklists"
      >
        <ChecklistDetails checklists={checklists} />
      </ComponentWrapper>
    );
  };

  const renderStripeCustomer = () => {
    const hasStripeIntegrations =
      Object.keys(projectStore?.currentProject?.integrations?.stripe ?? {})
        .length > 0;
    if (!hasStripeIntegrations) {
      return null;
    }

    return (
      <ComponentWrapper
        className="session-details-group mb-20"
        title="Stripe data"
      >
        <StripeDetails session={sessionStore?.session} />
      </ComponentWrapper>
    );
  };

  return (
    <PageContainer>
      <div className="profile">
        <div className="profile-header">
          <LinkButton
            className="profile-header-back"
            icon="arrow-left"
            label="Back"
            iconSideRight={false}
            onClick={() => {
              const canGoBack = window.history.state.idx !== 0;
              if (canGoBack) {
                navigate(-1);
              } else {
                navigate(
                  `/projects/${projectStore?.currentProject?.id}/sessions`,
                );
              }
            }}
          />
          <div className="contact-details-header-avatar">
            <UserAvatar
              isOnline={isOnline}
              email={session?.email ? session.email : session.gleapId}
            />
            <div className="data">
              <div className="title">
                {cleanupName(getSessionName(session), 20)}
              </div>
              <div className="subtitle">
                <TimeAgo date={session.lastActivity} />
              </div>
            </div>
          </div>
          <div className="profile-header-actions hide-on-mobile">
            {!session.blocked && (
              <LinkButton
                label="Mute contact"
                icon="volume-mute"
                iconSideRight={false}
                onClick={() => {
                  sessionStore?.updateSession(session?.id, {
                    blocked: true,
                  });
                }}
              />
            )}
            <LinkButton
              onClick={() => {
                Swal.fire({
                  text: 'Do you really want to delete this contact and all associated tickets?',
                  showCancelButton: true,
                  confirmButtonText: `Yes`,
                  denyButtonText: `No`,
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    await sessionStore!.removeSession(
                      sessionStore!.session!.id,
                    );

                    navigate(
                      `/projects/${projectStore?.currentProject!.id}/sessions`,
                    );
                  }
                });
              }}
              icon="trash"
              label="Delete"
              iconSideRight={false}
              className="mr-10 ml-10"
            />
            <LinkButton
              icon="file-export"
              iconSideRight={false}
              className="mr-10"
              onClick={() => {
                sessionStore!.downloadBugsAsJSON();
              }}
              label="Export data"
            />
            <PrimaryButton
              icon="pen-to-square"
              onClick={() => {
                modalStore!.openModal(MODALTYPE.COMPOSE_MESSAGE, {
                  sessionId: sessionStore!.session!.id,
                });
              }}
              label="New conversation"
            />
          </div>
        </div>
        <div className="contact-details-container-inner">
          <SessionDetails title="Details" session={sessionStore?.session} />
          <div className="feedback-item-activity">
            {sessionStore?.sessionFeedbackActivities.map((feedbackItem) => {
              return (
                <FeedbackItem
                  key={feedbackItem.id}
                  feedbackItem={feedbackItem}
                />
              );
            })}
            {(!sessionStore?.sessionFeedbackActivities ||
              sessionStore?.sessionFeedbackActivities.length === 0) && (
              <div className="no-activities-yet">No conversations yet 😎</div>
            )}
          </div>
          <div>
            {renderChecklists()}
            {renderStripeCustomer()}
            <EventTimeline />
          </div>
        </div>
      </div>
      <Outlet />
    </PageContainer>
  );
};

export default inject(
  'projectStore',
  'sessionStore',
  'modalStore',
)(observer(ProjectContactDetails));
