import { useEffect, useState } from 'react';
import './FeedbackActionSummaryItem.scss';
import Chart from 'react-apexcharts';
import { getEmojiForVal } from 'helper/ScoreFormater';
import ListTableVirtualized from 'components/ListTableVirtualized/ListTableVirtualized';
import FeedbackTextAnswer from 'components/FeedbackTextAnswer/FeedbackTextAnswer';
import { ProjectStore } from 'stores/private/ProjectStore';
import { inject, observer } from 'mobx-react';
import { getLanguageProperty } from 'helper/AssignObjectKeysHelper';
import NPSHistoryChart from 'components/NPSHistoryChart/NPSHistoryChart';
import { calculateNPS } from 'helper/CalculateNPS';

interface FeedbackActionSummaryItemProps {
  fullForm?: any;
  formItem: any;
  stats: any;
  projectStore?: ProjectStore;
}

const FeedbackActionSummaryItem = ({
  fullForm,
  formItem,
  stats,
  projectStore,
}: FeedbackActionSummaryItemProps) => {
  const currentLang = projectStore?.currentLanguage ?? 'en';
  const [axisData, setAxisData] = useState([] as any);
  const [chartData, setChartData] = useState([] as any);
  const [listData, setListData] = useState([] as any);

  var npsKey = null;
  var npsData: any = null;
  if (fullForm) {
    for (let i = 0; i < fullForm.length; i++) {
      if (fullForm[i].type === 'netpromoterscore') {
        npsKey = fullForm[i].name;
        npsData = fullForm[i];
      }
    }
  }

  useEffect(() => {
    generateStatisticsForType();
  }, [stats]);

  const generateStatisticsForType = () => {
    switch (formItem.type) {
      case 'onetofive':
        getOneToFiveScaleStatistics();
        break;
      case 'multiplechoice':
        getMultiChoiceStatistics();
        break;
      case 'multiple-choice-multi-select':
        getMultiChoiceStatistics();
        break;
      case 'rating':
        getRatingStatistcs();
        break;
      case 'text':
        getTextStatistics();
        break;
      case 'textarea':
        getTextStatistics();
        break;
      default:
        break;
    }
  };

  const getChartDataByValue = (currentAxisData) => {
    if (!currentAxisData) {
      return null;
    }

    const values: any[] = [];

    for (let i = 0; i < stats.length; i++) {
      const { formData } = stats[i];
      if (!formData || !formItem.name) {
        continue;
      }

      const currentValue = formData[formItem.name];

      if (formItem.type === 'rating') {
        values.push(getEmojiForVal(currentValue * 10));
      } else {
        values.push(currentValue);
      }
    }

    const currentChartData: any[] = [];
    for (let i = 0; i < currentAxisData.length; i++) {
      const filteredChartAxisData = values.filter(
        (element) => element === currentAxisData[i],
      );

      currentChartData.push(filteredChartAxisData.length);
    }

    setAxisData(currentAxisData);
    setChartData(currentChartData);
  };

  const getChartDataById = (currentAxisData) => {
    if (!currentAxisData) {
      return null;
    }

    let values: any[] = [];

    for (let i = 0; i < stats.length; i++) {
      const { formData } = stats[i];
      if (!formData || !formItem.name) {
        continue;
      }

      const currentValue = formData[formItem.name];

      if (
        formItem.type === 'multiple-choice-multi-select' &&
        Array.isArray(currentValue)
      ) {
        for (let j = 0; j < currentValue.length; j++) {
          values.push(currentValue[j]);
        }
      } else {
        values.push(currentValue);
      }
    }

    const currentChartData: any[] = [];
    for (let i = 0; i < currentAxisData.length; i++) {
      const filteredChartAxisData = values.filter(
        (element) => element === currentAxisData[i].id,
      );

      currentChartData.push(filteredChartAxisData.length);
    }

    // Translate axis data
    const currentAxisDataCopy = Array.from(currentAxisData);

    const currentAxisDataTranslated = currentAxisDataCopy.map((item) =>
      getLanguageProperty(item, 'label', currentLang),
    );

    setAxisData(currentAxisDataTranslated);
    setChartData(currentChartData);
  };

  const getOneToFiveScaleStatistics = () => {
    const upToNumber = formItem.upToNumber ? formItem.upToNumber : 5;
    const fromNumber = !isNaN(formItem.fromNumber) ? formItem.fromNumber : 0;
    const currentAxisData = Array.from(
      { length: upToNumber - (fromNumber - 1) },
      (_, i) => String(fromNumber + i),
    );
    getChartDataByValue(currentAxisData);
  };

  const getMultiChoiceStatistics = () => {
    const currentAxisData = formItem.choices;
    getChartDataById(currentAxisData);
  };

  const getRatingStatistcs = () => {
    const currentAxisData = ['😍', '🙂', '😐', '😢', '😡'];
    getChartDataByValue(currentAxisData);
  };

  const getTextStatistics = () => {
    const values: any[] = [];

    for (let i = 0; i < stats.length; i++) {
      const { formData } = stats[i];
      if (!formData || !formItem.name) {
        continue;
      }

      const currentValue = formData[formItem.name];
      if (currentValue) {
        var data = {
          content: currentValue,
          shareToken: stats[i].shareToken,
          createdAt: stats[i].createdAt,
        };
        if (
          npsKey &&
          formData[npsKey] !== undefined &&
          formData[npsKey] !== null
        ) {
          data['nps'] = formData[npsKey];
        }
        values.push(data);
      }
    }

    values.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    setListData(values);
  };

  const chartOptions: any = {
    chart: {
      id: 'Feedbackaction',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
      },
    },
    fill: {
      colors: ['#2142E7'],
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: false,
    },
    xaxis: {
      categories: axisData,
      labels: {
        trim: true,
      },
      // tooltip: {
      //   enabled: true,
      // },
    },
  };

  const chartSeries = [
    {
      name: '',
      data: chartData,
    },
  ];

  const buildChartContent = () => {
    return (
      <div className="chart-wrapper">
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="bar"
          height={350}
          width="100%"
        />
      </div>
    );
  };

  const buildNPSContent = () => {
    const {
      detractorsCount,
      npsScore,
      passivesCount,
      promotersCount,
      npsType,
      values,
      npsOptions
    } = calculateNPS(stats, formItem);

    const npsTypeClassifier = (nps) => {
      switch (npsType) {
        case 'classic':
          return nps >= 9 ? 'promoters' : nps >= 7 ? 'passives' : 'detractors';
        case 'modern':
          return nps === 5 ? 'promoters' : nps >= 3 ? 'passives' : 'detractors';
        case 'emoji':
          if (nps === 10) return 'promoters'; // 😍
          else if (nps === 8) return 'passives'; // 😊
          else return 'detractors'; // 😡, 😢, 😐
        default:
          return nps >= 9 ? 'promoters' : nps >= 7 ? 'passives' : 'detractors';
      }
    };

    const npsTypeLabel = (nps) => {
      if (npsType === 'emoji') {
        switch (nps) {
          case 0:
            return '😡';
          case 2:
            return '😢';
          case 4:
            return '😐';
          case 6:
            return '😐';
          case 8:
            return '😊';
          case 10:
            return '😍';
          default:
            return '';
        }
      } else {
        return nps.toString();
      }
    };

    return (
      <div className='nps-container'>
        <div className="nps-wrapper">
          <div className="nps-overview">
            <div className="chart-wrapper">
              <Chart
                options={{
                  labels: ['Promoters', 'Passives', 'Detractors'],
                  plotOptions: {
                    pie: {
                      dataLabels: {
                        offset: 5,
                      },
                      donut: {
                        labels: {
                          show: true,
                        },
                        size: '90%',
                      },
                    },
                  },
                  dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                      return Math.round(parseFloat(String(val))) + '%';
                    },
                    dropShadow: {
                      enabled: false,
                    },
                    background: {
                      enabled: true,
                      padding: 10,
                      foreColor: '#fff',
                      borderRadius: 10,
                      borderWidth: 1,
                      borderColor: '#fff',
                      dropShadow: {
                        enabled: false,
                      },
                    },
                  },
                  colors: ['#19b097', '#f4ba56', '#ea555a'],
                }}
                series={[promotersCount, passivesCount, detractorsCount]}
                type="donut"
                height={360}
                width={360}
              />
            </div>
            <div className="nps-score">
              <div className="nps-score-value">
                {isNaN(npsScore) ? '--' : `${npsScore > 0 ? '+' : ''}${npsScore}`}
              </div>
              <div className="nps-score-label">NPS®</div>
            </div>
          </div>
          <div className="nps-info">NPS® = %PROMOTERS - %DETRACTORS</div>
          <div className="nps-bubble-wrapper">
            {npsOptions.map((nps) => {
              const countValue = values[nps] || 0;
              const label = npsTypeLabel(nps);

              return (
                <div
                  className={`nps-bubble nps-bubble--${npsTypeClassifier(nps)}`}
                  key={`nps-${nps}-${countValue}`}
                >
                  <div className="nps-bubble-circle">{label}</div>
                  <div className="nps-bubble-label">{countValue}</div>
                </div>
              );
            })}
          </div>
        </div>
        <NPSHistoryChart stats={stats} formItem={formItem} />
      </div>
    );
  };

  const buildAnswerListContent = () => {
    if (!listData || listData.length === 0) {
      return <></>;
    }

    return (
      <ListTableVirtualized
        data={listData}
        renderCell={(row, index) => {
          return (
            <FeedbackTextAnswer
              projectStore={projectStore}
              answer={row}
              npsType={npsData?.npsType}
              key={`${index}`}
            />
          );
        }}
      />
    );
  };

  const buildActionContent = () => {
    switch (formItem.type) {
      case 'onetofive':
        return buildChartContent();
      case 'netpromoterscore':
        return buildNPSContent();
      case 'multiplechoice':
        return buildChartContent();
      case 'multiple-choice-multi-select':
        return buildChartContent();
      case 'rating':
        return buildChartContent();
      case 'text':
        return buildAnswerListContent();
      case 'textarea':
        return buildAnswerListContent();
      default:
        return <></>;
    }
  };

  const getTypeLabel = () => {
    switch (formItem.type) {
      case 'netpromoterscore':
        return 'Net Promoter Score®';
      case 'onetofive':
        return 'Numeric scale';
      case 'multiplechoice':
        return 'Single choice';
      case 'multiple-choice-multi-select':
        return 'Multiple choice';
      case 'rating':
        return 'Smiley rating';
      case 'text':
        return 'Short text';
      case 'upload':
        return 'File upload';
      case 'textarea':
        return 'Long text';
      default:
        return '';
    }
  };

  return (
    <div className="feedback-action-summary-item-container">
      <div className="header">
        <div className="title">
          {getLanguageProperty(formItem, 'title', currentLang)}
        </div>
        <div className="type">{getTypeLabel()}</div>
      </div>
      {buildActionContent()}
    </div>
  );
};

export default inject('projectStore')(observer(FeedbackActionSummaryItem));
