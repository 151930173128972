import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import 'styles/swal-theme.scss';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { inject, observer } from 'mobx-react';
import './OrganisationDangerZone.scss';
import { useNavigate } from 'react-router';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContainer from 'components/PageContainer/PageContainer';
import NoAdminCard from 'components/NoAdminCard/NoAdminCard';
import LinkButton from 'components/LinkButton/LinkButton';

interface OrganisationDangerZoneProps {
  organisationStore?: OrganisationStore;
}
const OrganisationDangerZone = ({
  organisationStore,
}: OrganisationDangerZoneProps) => {
  const organisation = organisationStore?.currentOrganisation;
  const navigate = useNavigate();

  if (!organisation) {
    return null;
  }

  const deleteCompany = async () => {
    Swal.fire({
      html: 'Do you really want to delete your <span style="font-weight: bold;">organization?</span>',
      showCancelButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `No`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await organisationStore?.deleteOrganisation();
          navigate('/dashboard');
        } catch (err: any) {
          if (err.response.status === 409) {
            toast.error(
              'You have to delete all projects of the organization first.',
            );
          } else {
            toast.error('Could not delete organization.');
          }
        }
      }
    });
  };

  const renderContent = () => {
    return (
      <div className="options-group">
        <div className="options-group-header">Danger zone</div>
        <div className="danger-zone">
          <div className="danger-zone-container">
            <div className="danger-zone-item">
              <div className="danger-zone-label">Delete organization</div>
              <LinkButton
                className="danger-button"
                label="Delete"
                onClick={deleteCompany}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <PageContainer>
      <PageHeadLine title="Danger zone" />
      <PageContent hasTitle isMediumBoxed>
        {!organisationStore?.isOrganisationAdmin ? (
          <NoAdminCard />
        ) : (
          renderContent()
        )}
      </PageContent>
    </PageContainer>
  );
};

export default inject('organisationStore')(observer(OrganisationDangerZone));
