import { Session } from 'models/Session';
import { GenericHttpClient } from './config/http.generic.client';

export class HttpSessionService extends GenericHttpClient<Session> {
  static _instance: HttpSessionService;
  static getInstance(): HttpSessionService {
    if (this._instance == null) {
      this._instance = new HttpSessionService('/sessions');
    }
    return this._instance;
  }

  getSessionActivities = async (args: {
    id: string;
    query?: any;
  }): Promise<any> => {
    try {
      const response = await this.get<any>(
        `${this.path}/${args.id}/activities`, {
          params: args.query,
        }
      );
      return response.data;
    } catch (err) {
      return await Promise.reject(err);
    }
  };

  resubscribeSession = async (args: { id: string }): Promise<any> => {
    try {
      const response = await this.put<any>(
        `${this.path}/${args.id}/resubscribe`,
      );
      return response.data;
    } catch (err) {
      return await Promise.reject(err);
    }
  };

  getSessionEvents = async (args: {
    id: string;
    query?: any;
  }): Promise<any> => {
    try {
      const response = await this.get<any>(`${this.path}/${args.id}/events`, {
        params: args.query,
      });
      return response.data;
    } catch (err) {
      return await Promise.reject(err);
    }
  };

  searchForSessions = async (args: {
    searchTerm: string;
  }): Promise<Session[] | undefined> => {
    try {
      const response = await this.get<Session[]>(`${this.path}/search`, {
        params: {
          searchTerm: args.searchTerm,
        },
      });
      return response.data as Session[];
    } catch (err) {
      return await Promise.reject(err);
    }
  };

  unsubscribeSessions = async (args: {
    sessions: any[];
  }): Promise<Session[] | undefined> => {
    try {
      const response = await this.post(`${this.path}/unsubscribe`, {
        sessions: args.sessions,
      });
      return response.data as Session[];
    } catch (err) {
      return await Promise.reject(err);
    }
  };

  importSessions = async (args: {
    sessions: any[];
  }): Promise<Session[] | undefined> => {
    try {
      const response = await this.post(`${this.path}/importer`, {
        sessions: args.sessions,
      });
      return response.data as Session[];
    } catch (err) {
      return await Promise.reject(err);
    }
  };

  importSessionsFromIntercom = async (args: {
    endpoint: string;
    apiKey: string;
  }): Promise<Session[] | undefined> => {
    try {
      const response = await this.post(`${this.path}/importer/intercom`, {
        endpoint: args.endpoint,
        apiKey: args.apiKey,
      });
      return response.data as Session[];
    } catch (err) {
      return await Promise.reject(err);
    }
  };
}
