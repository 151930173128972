import { ReactComponent as GleapBannerBadge } from 'assets/GleapBannerBadge.svg';
import LinkButton from 'components/LinkButton/LinkButton';
import ProductTourEditor from 'components/ProductTourEditor/ProductTourEditor';
import { TourStep } from 'components/TourStep/TourStep';
import Gleap from 'gleap';
import { isMatchingUrl } from 'helper/UrlHelper';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { SidenavStore } from 'stores/private/SidenavStore';
import Swal from 'sweetalert2';
import './ProductTourBuilder.scss';
import { injectCustomHeader } from 'services/Axios';

interface ProductTourBuilderProps {
  sidenavStore?: SidenavStore;
}

const ProductTourBuilder = ({
  sidenavStore,
}: ProductTourBuilderProps) => {
  const outboundRule = useRef<any>(null);
  const [steps, setSteps] = useState<any[]>([]);
  const [currentStatus, setCurrentStatus] = useState<'editor' | 'picker' | 'navigate'>('navigate');
  const [currentPage, setCurrentPage] = useState<string>('');
  const [currentItem, setCurrentItem] = useState<number>(-1);
  const [availableLanguages, setAvailableLanguages] = useState<any[]>([]);
  const [currentLang, setCurrentLang] = useState<string>('en');
  const [hoverAdd, setHoverAdd] = useState<string>("");
  const currentItemRef = useRef(currentItem);
  const stepsRef = useRef(steps);
  currentItemRef.current = currentItem;
  stepsRef.current = steps;

  const performClick = (selector) => {
    sendMessage({
      name: "click",
      data: {
        selector: selector,
      },
    });
  };

  useEffect(() => {
    if (currentItem && currentItem >= 0) {
      var latestClickSelector: string = '';
      let localSteps = stepsRef.current;

      for (let i = 0; i < currentItem; i++) {
        if (localSteps[i].mode === 'CLICK') {
          latestClickSelector = localSteps[i].selector;
        }
      }

      if (latestClickSelector && latestClickSelector.length > 0) {
        performClick(latestClickSelector);
      }
    }

    // Cleanup function to clear timeouts if the component unmounts
    return () => {
      // Clear all pending timeouts
    };
  }, [currentItem]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const updatedSteps = [...stepsRef.current];
    const [removed] = updatedSteps.splice(result.source.index, 1);
    updatedSteps.splice(result.destination.index, 0, removed);

    setSteps(updatedSteps);
  };


  useEffect(() => {
    Gleap.showFeedbackButton(false);

    runInAction(() => {
      sidenavStore!.sidenavHidden = true;
      sidenavStore!.mainSidenavHidden = true;
    });

    return () => {
      Gleap.showFeedbackButton(true);
    }
  }, []);

  const sendMessage = (data: any) => {
    if (window.parent) {
      window.parent.postMessage(JSON.stringify({
        ...data,
        type: "tourbuilder"
      }), "*");
    }
  }

  const startTour = () => {
    if (outboundRule?.current?.pageFilter && outboundRule?.current?.pageFilter.length > 0 && !isMatchingUrl(currentPage, outboundRule?.current?.pageFilter)) {
      setCurrentStatus('editor');

      Swal.fire({
        text: `Do you really want to start the tour on the current page? Previously your tour started on ${outboundRule!.current?.config!.startURL}. If you want to start the tour on the current page, please click "Yes".`,
        showCancelButton: true,
        confirmButtonText: `Yes`,
        denyButtonText: `No`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          // Update initial URL.
          outboundRule!.current!.config!.startURL = currentPage;
          updateInitialUrl();

          // Start tour.
          setCurrentStatus('editor');
        } else {
          setCurrentStatus('navigate');
        }
      });
    } else {
      setCurrentStatus('editor');
    }
  }

  const updateStepData = (stepIndex, newData) => {
    // Check if the provided index is valid
    if (stepIndex < 0 || stepIndex >= stepsRef.current?.length) {
      return;
    }

    // Clone the steps array
    const updatedSteps = [...stepsRef.current];

    // Update the specific step with new data
    updatedSteps[stepIndex] = { ...updatedSteps[stepIndex], ...newData };

    // Set the new state
    setSteps(updatedSteps);
  };

  useEffect(() => {
    sendMessage({
      name: "status-changed",
      data: currentStatus,
    });
  }, [currentStatus]);

  // This function handles received messages
  const receiveMessage = (event) => {
    try {
      const data = JSON.parse(event.data);

      // Respond with a 'pong' message
      if (data.type === 'tourbuilder') {
        if (data.name === 'data') {
          outboundRule.current = data.data?.outboundRule;
          injectCustomHeader(data.data?.token);
          setAvailableLanguages(data.data?.availableLanguages ?? []);
          setSteps(data.data?.outboundRule?.config?.steps ?? []);
        }

        if (data.name === 'page-changed') {
          setCurrentPage(data.data.page);
        }

        if (data.name === 'element-picked') {
          updateStepData(currentItemRef.current, {
            selector: data.data.selector,
            url: data.data.url,
          });

          setCurrentStatus('editor');
        }
      }
    } catch (e) { }
  };

  useEffect(() => {
    // Listen for message events
    window.addEventListener('message', receiveMessage, false);

    sendMessage({
      name: "loaddata"
    });

    // Cleanup listener when the component is unmounted
    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, []);

  useEffect(() => {
    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = `
      body, html, #root {
        background-color: transparent !important;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  const updateInitialUrl = () => {
    if (!currentPage) {
      return;
    }

    outboundRule!.current!.pageFilter = currentPage;
    outboundRule!.current!.pageFilterDelay = 0;
  }

  const renderToolbar = () => {
    // Picker
    if (currentStatus === 'picker') {
      return (<div className='product-tour-editor-toolbar'>
        <div className='product-tour-editor-toolbar-info'>
          <GleapBannerBadge className='product-tour-editor-toolbar-info-icon' />
          <div className='product-tour-editor-toolbar-info-text'>Point to the element where you'd like to pin the step to</div>
        </div>
        <div></div>
      </div>);
    }

    const createNewItem = (type) => {
      setSteps([...steps, {
        selector: '',
        message: '',
        type: type,
        mode: 'BUTTON',
      }]);

      setCurrentItem(steps.length);

      // Start selector picker
      if (type !== 'post') {
        setCurrentStatus('picker');
      }
    }

    // Editor
    if (currentStatus === "editor") {
      const items = [
        { action: 'post', hoverText: 'Add post', iconClass: 'fa-sharp fa-regular fa-square' },
        { action: 'pointer', hoverText: 'Add pointer', iconClass: 'fa-sharp fa-regular fa-message' },
        { action: 'video-pointer', hoverText: 'Add video pointer', iconClass: 'fa-sharp fa-regular fa-video' },
      ];

      return (<>
        <div className='product-tour-spacer'>
          {renderEditor()}
        </div>
        <div className='product-tour-editor-largetoolbar'>
          <div className='tour-main-nav'>
            <div className='tour-steps-container'>
              <div className='tour-steps-drag'>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable direction='horizontal' droppableId="steps">
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {steps.map((step, index) => (
                          <Draggable key={index} draggableId={`step-${index}`} index={index}>
                            {(provided) => (
                              <div ref={provided.innerRef} {...provided.draggableProps}>
                                <TourStep
                                  key={index}
                                  currentLang={currentLang}
                                  selected={currentItem === index}
                                  tourStep={step}
                                  draggableProps={provided.dragHandleProps}
                                  onDelete={() => {
                                    setSteps(steps.filter((_, i) => i !== index));
                                    setCurrentItem(-1);
                                  }}
                                  onClick={() => {
                                    setCurrentItem(index);
                                  }} />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                <div className='add-tour-step-outer'>
                  <div className='add-tour-step'>
                    {items.map((item, index) => (
                      <div
                        key={index}
                        className='add-tour-step-inner'
                        onClick={() => createNewItem(item.action)}
                        onMouseOver={() => setHoverAdd(item.hoverText)}
                        onMouseOut={() => setHoverAdd("")}
                      >
                        <i className={item.iconClass}></i>
                      </div>
                    ))}
                    {(hoverAdd && hoverAdd.length > 0) && <div className='add-tour-step-inner-hover'>{hoverAdd}</div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='tour-sub-nav'>
            <div className='product-tour-editor-toolbar-info'>
              <GleapBannerBadge className='product-tour-editor-toolbar-info-icon' />
            </div>
            <div className='subbar-menu-items'>
              <LinkButton label='Save' onClick={() => {
                outboundRule!.current!.config!.steps = steps;
                outboundRule!.current!.config!.startURL = currentPage;

                // Initially update page filter…
                if (!outboundRule?.current?.pageFilter || outboundRule?.current?.pageFilter?.length === 0) {
                  updateInitialUrl();
                }

                sendMessage({
                  name: "save",
                  data: {
                    steps: steps,
                    startURL: currentPage,
                    pageFilterDelay: outboundRule.current.pageFilterDelay,
                    pageFilter: outboundRule.current.pageFilter,
                  },
                });
              }} />
            </div>
          </div>
        </div>
      </>);
    }

    // Navigate
    return (<div className='product-tour-editor-toolbar'>
      <div className='product-tour-editor-toolbar-info'>
        <GleapBannerBadge className='product-tour-editor-toolbar-info-icon' />
        <div className='product-tour-editor-toolbar-info-text'>Navigate to the page, where you want to start the product tour</div>
      </div>
      <div>
        <LinkButton label='Start tour here' onClick={() => {
          startTour();
        }} />
      </div>
    </div>);
  }

  const renderEditor = () => {
    if (currentStatus !== 'editor') {
      return;
    }

    var currentStep = steps[currentItem];

    return (<ProductTourEditor
      currentLang={currentLang}
      performClick={performClick}
      setCurrentLang={(lang) => {
        setCurrentLang(lang);
      }}
      availableLanguages={availableLanguages}
      pickSelector={() => {
        setCurrentStatus('picker');
      }}
      key={currentItem}
      updateStepData={(data) => {
        updateStepData(currentItem, data);
      }}
      projectId={outboundRule.current?.project}
      currentStep={currentStep} />)
  }

  return (
    <div className='product-tour-editor'>
      {renderToolbar()}
    </div>
  );
};

export default inject(
  'sidenavStore',
)(observer(ProductTourBuilder));