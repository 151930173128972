import React, { useEffect, useState } from 'react';
import './AssignedFilter.scss';
import { ProjectStore } from 'stores/private/ProjectStore';
import { inject, observer } from 'mobx-react';
import Select from 'react-select';
import UserAvatar from 'components/UserAvatar/UserAvatar';

interface AssignedFilterProps {
  projectStore?: ProjectStore;
  value?: any;
  placeholder?: string;
  truncatePreview?: number;
  truncate?: number;
  onValueChanged?: (value?: string) => void;
  hasBorder?: boolean;
  biggerIcon?: boolean;
}

export const truncateString = (str, num) => {
  if (str.length > num) {
    return `${str.slice(0, num)}...`;
  }
  return str;
};

export const cleanupName = (name, num, showFirstNameOnly = false) => {
  var newName = '';
  try {
    newName = truncateString(
      name ? name.split('@')[0].split('.')[0].split('+')[0] : '',
      num,
    );
    if (showFirstNameOnly) {
      newName = newName.split(' ')[0];
    }
  } catch (exp) {
    newName = truncateString(name, num);
  }
  if (!newName || newName.length === 0) {
    newName = 'Unknown';
  }
  return newName;
};

const AssignedFilter = ({
  projectStore,
  value,
  onValueChanged,
  placeholder = 'Filter by assignee',
  truncatePreview = 5,
  truncate = 13,
  hasBorder = false,
  biggerIcon = false,
}: AssignedFilterProps) => {
  const [selectedUser, setSelectedUser] = useState(undefined as any);

  useEffect(() => {
    if (value && projectStore?.currentProjectUsers) {
      const user = projectStore?.currentProjectUsers.find(
        (o) => o.id === value,
      );
      setSelectedUser(user);
    } else {
      setSelectedUser(undefined);
    }
  }, [value, projectStore?.currentProjectUsers]);

  if (!projectStore?.currentProjectUsers) {
    return <></>;
  }

  const filterOptions = (candidate, input) => {
    if (input) {
      if (candidate.label.toLowerCase().includes(input.toLowerCase()))
        return true;

      return false;
    }
    return true;
  };

  return (
    <div className="user-filter">
      <Select
        className={`dropdown-selection ${hasBorder && 'dropdown-selection--border'
          }`}
        classNamePrefix="dropdown-selection"
        value={selectedUser}
        getOptionLabel={(option) => option.firstName}
        getOptionValue={(option) => option.id}
        placeholder={placeholder}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        isClearable
        filterOption={filterOptions}
        components={{
          SingleValue: (option: any) => {
            const name = `${option.data.firstName} ${option.data.lastName}`;
            return (
              <>
                <UserAvatar
                  small={!biggerIcon}
                  email={option.data.email}
                  imageUrl={option.data.profileImageUrl}
                />
                {truncateString(name, truncatePreview)}
              </>
            );
          },
          Option: (option: any) => {
            const name = `${option.data.firstName} ${option.data.lastName}`;
            return (
              <div
                className="option-list-item user-option-list-item"
                onClick={() => {
                  if (option.selectOption) {
                    option.selectOption(option.data);
                  }
                }}
              >
                <UserAvatar
                  small={!biggerIcon}
                  email={option.data.email}
                  imageUrl={option.data.profileImageUrl}
                />
                {truncateString(name, truncate)}
              </div>
            );
          },
        }}
        onChange={(selectedProcessingUser: any) => {
          let valToUpdate;
          if (selectedProcessingUser && selectedProcessingUser.id) {
            valToUpdate = selectedProcessingUser.id;
          }

          if (onValueChanged) {
            onValueChanged(valToUpdate);
          }
        }}
        options={projectStore?.currentProjectUsers}
      />
    </div>
  );
};

export default inject('bugStore', 'projectStore')(observer(AssignedFilter));
