import { ReactComponent as GleapBannerBadge } from 'assets/GleapBannerBadge.svg';
import LinkButton from 'components/LinkButton/LinkButton';
import { Tooltip } from 'components/Tooltip/Tooltip';
import TooltipEditor from 'components/TooltipEditor/TooltipEditor';
import Gleap from 'gleap';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { injectCustomHeader } from 'services/Axios';
import { SidenavStore } from 'stores/private/SidenavStore';
import './TooltipBuilder.scss';

interface TooltipBuilderProps {
  sidenavStore?: SidenavStore;
}

const TooltipBuilder = ({
  sidenavStore,
}: TooltipBuilderProps) => {
  const outboundRule = useRef<any>(null);
  const [steps, setSteps] = useState<any[]>([]);
  const [currentStatus, setCurrentStatus] = useState<'editor' | 'picker' | 'navigate'>('navigate');
  const [currentPage, setCurrentPage] = useState<string>('');
  const [currentItem, setCurrentItem] = useState<number>(-1);
  const [availableLanguages, setAvailableLanguages] = useState<any[]>([]);
  const [currentLang, setCurrentLang] = useState<string>('en');
  const [hoverAdd, setHoverAdd] = useState<string>("");
  const currentItemRef = useRef(currentItem);
  const stepsRef = useRef(steps);
  currentItemRef.current = currentItem;
  stepsRef.current = steps;

  useEffect(() => {
    Gleap.showFeedbackButton(false);

    runInAction(() => {
      sidenavStore!.sidenavHidden = true;
      sidenavStore!.mainSidenavHidden = true;
    });

    return () => {
      Gleap.showFeedbackButton(true);
    }
  }, []);

  const sendMessage = (data: any) => {
    if (window.parent) {
      window.parent.postMessage(JSON.stringify({
        ...data,
        type: "tourbuilder"
      }), "*");
    }
  }

  const startTour = () => {
    setCurrentStatus('editor');
  }

  const updateStepData = (stepIndex, newData) => {
    // Check if the provided index is valid
    if (stepIndex < 0 || stepIndex >= stepsRef.current?.length) {
      return;
    }

    // Clone the steps array
    const updatedSteps = [...stepsRef.current];

    // Update the specific step with new data
    updatedSteps[stepIndex] = { ...updatedSteps[stepIndex], ...newData };

    // Set the new state
    setSteps(updatedSteps);
  };

  useEffect(() => {
    sendMessage({
      name: "status-changed",
      data: currentStatus,
    });
  }, [currentStatus]);

  // This function handles received messages
  const receiveMessage = (event) => {
    try {
      const data = JSON.parse(event.data);

      // Respond with a 'pong' message
      if (data.type === 'tourbuilder') {
        if (data.name === 'data') {
          outboundRule.current = data.data?.outboundRule;
          injectCustomHeader(data.data?.token);
          setAvailableLanguages(data.data?.availableLanguages ?? []);
          setSteps(data.data?.outboundRule?.config?.steps ?? []);
        }

        if (data.name === 'page-changed') {
          setCurrentPage(data.data.page);
        }

        if (data.name === 'element-picked') {
          updateStepData(currentItemRef.current, {
            selector: data.data.selector,
            url: data.data.url,
          });

          setCurrentStatus('editor');
        }
      }
    } catch (e) { }
  };

  useEffect(() => {
    // Listen for message events
    window.addEventListener('message', receiveMessage, false);

    sendMessage({
      name: "loaddata"
    });

    // Cleanup listener when the component is unmounted
    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, []);

  useEffect(() => {
    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = `
      body, html, #root {
        background-color: transparent !important;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  const updateInitialUrl = () => {
    if (!currentPage) {
      return;
    }

    outboundRule!.current!.pageFilter = currentPage;
    outboundRule!.current!.pageFilterDelay = 0;
  }

  const renderToolbar = () => {
    // Picker
    if (currentStatus === 'picker') {
      return (<div className='tooltip-editor-toolbar'>
        <div className='tooltip-editor-toolbar-info'>
          <GleapBannerBadge className='tooltip-editor-toolbar-info-icon' />
          <div className='tooltip-editor-toolbar-info-text'>Point to the element where you'd like to pin the step to</div>
        </div>
        <div></div>
      </div>);
    }

    const createNewItem = (type) => {
      setSteps([...steps, {
        selector: '',
        message: '',
        type: type,
        animated: true,
        mode: 'hotspot',
        color: '#000000',
        icon: 'circle-dot',
        posX: 'right',
        posY: 'center',
      }]);

      setCurrentItem(steps.length);

      // Start selector picker
      if (type !== 'post') {
        setCurrentStatus('picker');
      }
    }

    // Editor
    if (currentStatus === "editor") {
      const items = [
        { action: 'tooltip', hoverText: 'Add tooltip', iconClass: 'fa-sharp fa-regular fa-bullseye-pointer' },
      ];

      return (<>
        <div className='product-tooltip-spacer'>
          {renderEditor()}
        </div>
        <div className='tooltip-editor-largetoolbar'>
          <div className='tooltip-main-nav'>
            <div className='tooltip-steps-container'>
              {steps.map((step, index) => (
                <Tooltip
                  key={index}
                  currentLang={currentLang}
                  selected={currentItem === index}
                  tooltip={step}
                  onDelete={() => {
                    setSteps(steps.filter((_, i) => i !== index));
                    setCurrentItem(-1);
                  }}
                  onClick={() => {
                    setCurrentItem(index);
                  }} />
              ))}
              <div className='add-tooltip-step-outer'>
                <div className='add-tooltip-step'>
                  {items.map((item, index) => (
                    <div
                      key={index}
                      className='add-tooltip-step-inner'
                      onClick={() => createNewItem(item.action)}
                      onMouseOver={() => setHoverAdd(item.hoverText)}
                      onMouseOut={() => setHoverAdd("")}
                    >
                      <i className={item.iconClass}></i>
                    </div>
                  ))}
                  {(hoverAdd && hoverAdd.length > 0) && <div className='add-tooltip-step-inner-hover'>{hoverAdd}</div>}
                </div>
              </div>
            </div>
          </div>
          <div className='tooltip-sub-nav'>
            <div className='tooltip-editor-toolbar-info'>
              <GleapBannerBadge className='tooltip-editor-toolbar-info-icon' />
            </div>
            <div className='subbar-menu-items'>
              <LinkButton label='Save' onClick={() => {
                outboundRule!.current!.config!.steps = steps;
                outboundRule!.current!.config!.startURL = currentPage;

                // Initially update page filter…
                if (!outboundRule?.current?.pageFilter || outboundRule?.current?.pageFilter?.length === 0) {
                  updateInitialUrl();
                }

                sendMessage({
                  name: "save",
                  data: {
                    steps: steps,
                    startURL: currentPage,
                    pageFilterDelay: outboundRule.current.pageFilterDelay,
                    pageFilter: outboundRule.current.pageFilter,
                  },
                });
              }} />
            </div>
          </div>
        </div>
      </>);
    }

    // Navigate
    return (<div className='tooltip-editor-toolbar'>
      <div className='tooltip-editor-toolbar-info'>
        <GleapBannerBadge className='tooltip-editor-toolbar-info-icon' />
        <div className='tooltip-editor-toolbar-info-text'>Navigate to the page, where you want to add tooltips</div>
      </div>
      <div>
        <LinkButton label='Start here' onClick={() => {
          startTour();
        }} />
      </div>
    </div>);
  }

  const renderEditor = () => {
    if (currentStatus !== 'editor') {
      return;
    }

    var currentStep = steps[currentItem];

    return (<TooltipEditor
      currentLang={currentLang}
      setCurrentLang={(lang) => {
        setCurrentLang(lang);
      }}
      availableLanguages={availableLanguages}
      pickSelector={() => {
        setCurrentStatus('picker');
      }}
      key={currentItem}
      updateStepData={(data) => {
        updateStepData(currentItem, data);
      }}
      projectId={outboundRule.current?.project}
      currentStep={currentStep} />)
  }

  return (
    <div className='tooltip-editor'>
      {renderToolbar()}
    </div>
  );
};

export default inject(
  'sidenavStore',
)(observer(TooltipBuilder));