import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import TextInput from 'components/TextInput/TextInput';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { ProjectStore } from 'stores/private/ProjectStore';
import { isEqual } from 'lodash';
import './HelpcenterCustomizations.scss';
import { useParams } from 'react-router-dom';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import LinkButton from 'components/LinkButton/LinkButton';
import Classic from 'assets/classic.svg';
import Modern from 'assets/modern.svg';

const homeStyles = [
  {
    label: 'Modern',
    value: 'MODERN',
    img: Modern,
  },
  {
    label: 'Classic',
    value: 'CLASSIC',
    img: Classic,
  },
];

interface HelpcenterCustomizationsProps {
  projectStore?: ProjectStore;
}

const HelpcenterCustomizations = ({
  projectStore,
}: HelpcenterCustomizationsProps) => {
  const { flowConfig } = projectStore?.editingProject || {};
  const { projectId } = useParams();
  const helpcenterConfig = flowConfig?.helpcenterConfig;
  const [footerLinks, setFooterLinks] = useState([] as any[]);
  const [initializedFooterLinks, setInitializedFooterLinks] = useState(false);

  useEffect(() => {
    if (
      footerLinks &&
      projectStore!.editingProject &&
      projectStore!.editingProject!.flowConfig
    ) {
      if (!flowConfig) {
        return;
      }

      if (!flowConfig?.helpcenterConfig) {
        projectStore!.editingProject!.flowConfig.helpcenterConfig = {};
      }

      if (!flowConfig?.helpcenterConfig?.config) {
        projectStore!.editingProject!.flowConfig.helpcenterConfig['config'] = {
          footerLinks: footerLinks,
        };
      } else {
        projectStore!.editingProject!.flowConfig.helpcenterConfig.config.footerLinks =
          footerLinks;
      }
    }
  }, [footerLinks]);

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    projectStore?.setEditingProject();

    if (projectStore?.currentProject && !initializedFooterLinks) {
      setInitializedFooterLinks(true);
      setFooterLinks(
        projectStore?.currentProject?.flowConfig.helpcenterConfig?.config?.footerLinks?.map(
          (item) => {
            return { ...item, key: Math.random().toString(36).substring(7) };
          },
        ) ?? [],
      );
    }
  }, [projectStore?.currentProject, projectStore?.flowConfig]);

  const _buildHelpcenterCustomizationForm = () => {
    return (
      <div className="options-group">
        <div className="options-group-header">🎨 Look & feel</div>
        <div className="input-label mb-10">Home style</div>
        <div className="positiontypeselection">
          {homeStyles.map((homeStyle) => {
            return (
              <div
                key={homeStyle.value}
                className={
                  (flowConfig?.helpcenterConfig
                    ?.config?.style ?? 'MODERN') === homeStyle.value
                    ? 'active'
                    : ''
                }
                onClick={() => {
                  runInAction(() => {
                    try {
                      if (
                        !flowConfig
                          ?.helpcenterConfig?.config
                      ) {
                        projectStore!.editingProject!.flowConfig.helpcenterConfig[
                          'config'
                        ] = {
                          style: homeStyle.value,
                        };
                      } else {
                        projectStore!.editingProject!.flowConfig.helpcenterConfig.config.style =
                          homeStyle.value;
                      }
                    } catch (exp) { }
                  });
                }}
              >
                <img src={homeStyle.img} alt={homeStyle.label} />
                <div>{homeStyle.label}</div>
              </div>
            );
          })}
        </div>
        <div className="input-label mb-10 mt-30">Logo</div>
        <ImageUpload
          image={helpcenterConfig?.config?.logoUrl}
          label="Logo"
          className="helpcenter-logo-upload"
          editable
          uploadPath="helpcenter_logos"
          afterImageUpload={(imageURL) => {
            runInAction(() => {
              try {
                if (
                  !flowConfig?.helpcenterConfig
                    ?.config
                ) {
                  projectStore!.editingProject!.flowConfig.helpcenterConfig[
                    'config'
                  ] = {
                    logoUrl: imageURL,
                  };
                } else {
                  projectStore!.editingProject!.flowConfig.helpcenterConfig.config.logoUrl =
                    imageURL;
                }
              } catch (exp) { }
            });
          }}
        />
        <div className="input-label mb-10 mt-30">Favicon</div>
        <ImageUpload
          image={helpcenterConfig?.config?.favUrl}
          label="Favicon"
          className="helpcenter-favicon-upload"
          editable
          uploadPath="helpcenter_logos"
          afterImageUpload={(imageURL) => {
            runInAction(() => {
              try {
                if (
                  !flowConfig?.helpcenterConfig
                    ?.config
                ) {
                  projectStore!.editingProject!.flowConfig.helpcenterConfig[
                    'config'
                  ] = {
                    favUrl: imageURL,
                  };
                } else {
                  projectStore!.editingProject!.flowConfig.helpcenterConfig.config.favUrl =
                    imageURL;
                }
              } catch (exp) { }
            });
          }}
        />
        <div className="input-label mb-10 mt-30">
          Header background image (Home)
        </div>
        <ImageUpload
          image={helpcenterConfig?.config?.headerBgUrl}
          label="Header background image"
          className="helpcenter-logo-upload"
          editable
          uploadPath="helpcenter_logos"
          afterImageUpload={(imageURL) => {
            runInAction(() => {
              try {
                if (
                  !flowConfig?.helpcenterConfig
                    ?.config
                ) {
                  projectStore!.editingProject!.flowConfig.helpcenterConfig[
                    'config'
                  ] = {
                    headerBgUrl: imageURL,
                  };
                } else {
                  projectStore!.editingProject!.flowConfig.helpcenterConfig.config.headerBgUrl =
                    imageURL;
                }
              } catch (exp) { }
            });
          }}
        />
        <TextInput
          className="mt-30"
          placeholder="https://"
          error=""
          label="Link to your homepage"
          value={helpcenterConfig?.config?.link}
          onChange={(val) => {
            runInAction(() => {
              try {
                if (
                  !flowConfig?.helpcenterConfig
                    ?.config
                ) {
                  projectStore!.editingProject!.flowConfig.helpcenterConfig[
                    'config'
                  ] = {
                    link: val,
                  };
                } else {
                  projectStore!.editingProject!.flowConfig.helpcenterConfig.config.link =
                    val;
                }
              } catch (exp) { }
            });
          }}
        />
      </div>
    );
  };

  const _buildHelpcenterCustomizationFormFooter = () => {
    return (
      <div className="options-group">
        <div className="options-group-header">Footer links</div>
        <div className="footer-link-container">
          {footerLinks &&
            footerLinks.map((feedbackTag, index) => (
              <div
                className="footer-link-row mt-15"
                key={`tag-${feedbackTag.key}`}
              >
                <TextInput
                  className="mr-15"
                  initalValue={feedbackTag.title}
                  placeholder="Title"
                  error={''}
                  onChange={(val) => {
                    runInAction(() => {
                      footerLinks[index].title = val;
                      setFooterLinks([...footerLinks]);
                    });
                  }}
                  required
                />
                <TextInput
                  className="mr-15"
                  initalValue={feedbackTag.url}
                  placeholder="https://"
                  error={''}
                  onChange={(val) => {
                    runInAction(() => {
                      footerLinks[index].url = val;
                      setFooterLinks([...footerLinks]);
                    });
                  }}
                  required
                />
                <div
                  className="delete-item"
                  onClick={() => {
                    runInAction(() => {
                      footerLinks.splice(index, 1);
                      setFooterLinks([...footerLinks]);
                    });
                  }}
                >
                  <i className="fa-solid fa-trash" />
                </div>
              </div>
            ))}
          {!footerLinks ||
            (footerLinks.length === 0 && (
              <div className="footer-link-row mb-10 mt-10">
                No footer links added yet
              </div>
            ))}
          <LinkButton
            label="Add footer link"
            className="mt-15"
            icon="plus"
            onClick={() => {
              runInAction(() => {
                setFooterLinks([
                  ...footerLinks,
                  {
                    title: 'New link',
                    url: '',
                    key: Math.random().toString(36).substring(7),
                  },
                ]);
              });
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <PageContainer>
      <PageHeadLine title="Customize">
        <div className="form-widget-buttons header-content-right">
          <PrimaryButton
            label="Save"
            disabled={isEqual(projectStore?.flowConfig, flowConfig)}
            onClick={() => {
              projectStore?.saveEditingProject({
                flowConfig: {
                  helpcenterConfig: flowConfig.helpcenterConfig,
                },
              });
            }}
          />
        </div>
      </PageHeadLine>
      <PageContent hasTitle>
        <SizedContainer size={ContainerSizes.XXL}>
          {_buildHelpcenterCustomizationForm()}
          {_buildHelpcenterCustomizationFormFooter()}
        </SizedContainer>
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore')(observer(HelpcenterCustomizations));
