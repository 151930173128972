import { inject, observer } from 'mobx-react';
import 'rc-slider/assets/index.css';
import { ModalStore } from 'stores/private/ModalStore';
import { PaddleStore } from 'stores/private/PaddleStore';
import './EndTrialEarlyModal.scss';
import { HeadLine } from 'components/HeadLine/HeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';

interface EndTrialEarlyModalProps {
  modalStore?: ModalStore;
  paddleStore?: PaddleStore;
}

const EndTrialEarlyModal = ({
  modalStore,
  paddleStore,
}: EndTrialEarlyModalProps) => {
  return (
    <div className="update-billing-address-modal">
      <HeadLine
        className="centered"
        title="Increase sending limits 🚀"
        subtitle="In order to increase your sending limits, you need to end your trial early. The limits will be increased immediately."
      />
      <div>
        <PrimaryButton onClick={() => {
          paddleStore?.endTrialEarly();
        }} label="End trial now" />
      </div>
    </div>
  );
};

export default inject(
  'modalStore',
  'paddleStore',
)(observer(EndTrialEarlyModal));
