import FancyBlurBG from 'assets/bg/fancyblurbg.png';
import ApiKey from 'components/ApiKey/ApiKey';
import FooterLinks from 'components/FooterLinks/FooterLinks';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import InfoBox from 'components/InfoBox/InfoBox';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Outbound } from 'models/Outbound';
import { TwitterPicker } from 'react-color';
import Switch from 'react-switch';
import { OutboundStore } from 'stores/private/OutboundStore';
import FeatureSatisfication from 'assets/surveys/ProductQuality.png';
import './SurveyShareOptions.scss';
import { UsersStore } from 'stores/private/UsersStore';
import { useState } from 'react';

interface SurveyShareOptionsProps {
  outboundRule: Outbound;
  outboundStore?: OutboundStore;
  usersStore?: UsersStore;
  type: 'email' | 'link';
}

const SurveyShareOptions = ({
  outboundRule,
  outboundStore,
  usersStore,
  type,
}: SurveyShareOptionsProps) => {
  const [creatingEmail, setCreatingEmail] = useState(false);
  const surveyURL = `https://forms.gleap.io/${outboundRule.id}`;

  const renderLinkShare = () => {
    if (type === 'email') {
      return (<div className='intro-card' style={{
        backgroundImage: `url(${FancyBlurBG})`
      }}>
        <div className='intro-card-content'>
          <h1>Send survey by email</h1>
          <p className='text'>Create a new email campaign to send the survey to your desired audience.</p>
          <PrimaryButton label='Create survey email campaign' isLoading={creatingEmail} onClick={() => {
            setCreatingEmail(true);

            outboundStore?.createEmail({
              title: `Survey email campaign: ${outboundRule?.name}`,
              image: FeatureSatisfication,
              trigger: null,
              frequencyType: 'fixed',
              pageFilterDelay: 0,
              subject: {
                localized: {
                  en: 'We want your feedback - Let`s make things better together! 🌟'
                }
              },
              message: {
                localized: {
                  en: {
                    type: 'doc',
                    content: [
                      {
                        type: 'paragraph',
                        content: [
                          {
                            "type": "text",
                            "text": "Hey "
                          },
                          {
                            "type": "serverVariable",
                            "attrs": {
                              "variableName": "session.firstName",
                              "variableLabel": "First name",
                              "fallbackValue": "there"
                            }
                          },
                          {
                            "type": "text",
                            "text": ","
                          }
                        ],
                      },
                      {
                        "type": "paragraph"
                      },
                      {
                        "type": "paragraph",
                        "content": [
                          {
                            "type": "text",
                            "text": "We hope you're thriving! In our journey to continuously improve, your feedback is invaluable, that's why we've put together a brief survey. Click on the link below to get started."
                          }
                        ]
                      },
                      {
                        "type": "paragraph"
                      },
                      {
                        "type": "paragraph",
                        "content": [
                          {
                            "type": "text",
                            "marks": [
                              {
                                "type": "link",
                                "attrs": {
                                  "href": `${surveyURL}?gleap-id={{session.gleapId}}&gleap-hash={{session.gleapHash}}`,
                                  "target": "_blank",
                                  "rel": "noopener noreferrer nofollow",
                                  "class": "editor-custom-link",
                                  "linktype": "button"
                                }
                              }
                            ],
                            "text": "Begin survey"
                          }
                        ]
                      },
                      {
                        "type": "paragraph"
                      },
                      {
                        type: 'paragraph',
                        content: [
                          {
                            type: 'text',
                            text: `Best,\n${usersStore?.currentUser?.firstName ?? ''
                              }`,
                          },
                        ],
                      },
                    ],
                  },
                },
              },
            });
          }} />
        </div>
      </div>);
    }

    return (<div className='intro-card' style={{
      backgroundImage: `url(${FancyBlurBG})`
    }}>
      <div className='intro-card-content'>
        <div className='flex flex-center'>
          <span className='text mb-20'>
            Share this link with your audience to start the survey.
          </span>
          <ApiKey
            icon="share-from-square"
            apiKey={surveyURL}
          />
        </div>
      </div>
    </div>)
  };

  return (
    <div className='survey-share-options'>
      {renderLinkShare()}
      <div className="options-group">
        <div className="options-group-header">Share page options</div>
        <InfoBox className='mb-30'>
          The share page is the page that is shown to the user when they start the survey through the link or an email.
        </InfoBox>
        <div className='mb-30'>
          <div className="input-label mb-10 mt-30">Background image</div>
          <ImageUpload
            image={outboundRule?.data?.backgroundImage}
            label="Background image"
            className="survey-background-image"
            editable
            uploadPath="surveys_background"
            afterImageUpload={(imageURL) => {
              runInAction(() => {
                if (!outboundRule.data) {
                  outboundRule.data = {};
                }

                outboundRule.data.backgroundImage = imageURL;
              });
            }}
          />
          <div className="input-label mb-10 mt-30">Background color</div>
          <div className="widget-color-picker">
            <div className="color-picker">
              <TwitterPicker
                colors={[]}
                color={outboundRule?.data?.backgroundColor ?? '#f8f8f8'}
                onChangeComplete={(color) => {
                  runInAction(() => {
                    if (!outboundRule.data) {
                      outboundRule.data = {};
                    }

                    outboundRule.data.backgroundColor = color.hex;
                  });
                }}
              />
              <div
                className="color-preview"
                style={{
                  backgroundColor: outboundRule?.data?.backgroundColor ?? '#f8f8f8',
                }}
              />
            </div>
          </div>
          <div className="input-label mt-30">Link color</div>
          <div className="widget-color-picker">
            <div className="color-picker">
              <TwitterPicker
                colors={[]}
                color={outboundRule?.data?.linkColor ?? '#00000099'}
                onChangeComplete={(color) => {
                  runInAction(() => {
                    if (!outboundRule.data) {
                      outboundRule.data = {};
                    }

                    outboundRule.data.linkColor = color.hex;
                  });
                }}
              />
              <div
                className="color-preview"
                style={{
                  backgroundColor: outboundRule?.data?.linkColor ?? '#00000099',
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="options-group">
        <div className="options-group-header">On submission</div>
        <div className="checkbox-settings-content">
          <div className="checkbox-settings-container">
            <div className="switch-container">
              <Switch
                width={40}
                onColor="#2142E7"
                height={20}
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={(checked) => {
                  runInAction(() => {
                    if (!outboundRule.data) {
                      outboundRule.data = {};
                    }

                    outboundRule.data.redirectOnSubmit = checked;
                  });
                }}
                checked={outboundRule?.data?.redirectOnSubmit ?? false}
              />
              <span>
                Redirect the user to a specific page after submission
              </span>
            </div>
          </div>
        </div>
        {outboundRule?.data?.redirectOnSubmit && (
          <div className="mt-30">
            <TextInput
              name="URL"
              placeholder="https://..."
              type="text"
              className="mb-20"
              error=""
              initalValue={outboundRule.data.redirectPage}
              label="URL to open"
              onChange={(text) => {
                runInAction(() => {
                  if (!outboundRule.data) {
                    outboundRule.data = {};
                  }

                  outboundRule.data.redirectPage = text;
                });
              }}
            />
          </div>
        )}
      </div>
      <FooterLinks footerLinks={outboundRule?.data?.footerLinks ?? []} onFooterLinksChange={(links) => {
        runInAction(() => {
          if (!outboundRule.data) {
            outboundRule.data = {};
          }

          outboundRule.data.footerLinks = links;
        });
      }} />
    </div>);
};

export default inject('outboundStore', 'usersStore')(observer(SurveyShareOptions));
