import axios from '../Axios';

const getAuthCodeFromDiscord = (projectID) => {
  const baseURL = encodeURIComponent(process.env.REACT_APP_BASEURL ?? '');
  const redirectURI = `${baseURL}/integrations/discord`;
  const state = encodeURIComponent(projectID);
  const url = `https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&redirect_uri=${redirectURI}&state=${state}&response_type=code&permissions=429497043968&scope=bot`;

  return window.open(url, '_self');
};

const setDiscordAuthToken = (code, projectID) => {
  return axios.post(`/projects/${projectID}/integrations/discord`, {
    code,
  });
};

const disconnectDiscordIntegration = ({ projectID, integrationID }) => {
  return axios.delete(
    `/projects/${projectID}/integrations/discord/${integrationID}`,
  );
};

const setDiscordActions = ({ projectID, integrationID, actions, settings }) => {
  return axios.put(
    `/projects/${projectID}/integrations/discord/${integrationID}`,
    {
      actions,
      settings,
    },
  );
};

const getChannels = ({ projectID, integrationID }) => {
  return axios.get(
    `/projects/${projectID}/integrations/discord/${integrationID}/channels`,
  );
};

export {
  getAuthCodeFromDiscord,
  setDiscordAuthToken,
  disconnectDiscordIntegration,
  setDiscordActions,
  getChannels,
};
