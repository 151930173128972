import ImageUpload from 'components/ImageUpload/ImageUpload';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import Switch from 'react-switch';
import { ProjectStore } from 'stores/private/ProjectStore';
import './UpdateProjectNotifications.scss';
import InfoBox from 'components/InfoBox/InfoBox';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';

interface UpdateProjectNotificationsProps {
  projectStore?: ProjectStore;
}

const UpdateProjectNotifications = ({
  projectStore,
}: UpdateProjectNotificationsProps) => {
  const project = projectStore!.currentProject;
  const emailTemplateLogo = projectStore?.currentProject?.emailTemplateLogo;
  const [loading, setLoading] = useState(false);
  const [
    sendFeedbackReceivedNotifications,
    setSendFeedbackReceivedNotifications,
  ] = useState(false);
  const [emailFallback, setEmailFallback] = useState(true);
  const [conversationHistory, setConversationHistory] = useState(true);
  const [chatStyle, setChatStyle] = useState('CHAT');
  const [showTicketNumberInSubject, setShowTicketNumberInSubject] =
    useState(false);
  useEffect(() => {
    projectStore!.getProjectTeams();
  }, []);

  useEffect(() => {
    if (project) {
      setSendFeedbackReceivedNotifications(
        project!.sendFeedbackReceivedNotifications,
      );
      setEmailFallback(!project!.disableEmailFallback);
      setConversationHistory(project!.emailHistory);
      setChatStyle(project!.emailMessageStyle);
      setShowTicketNumberInSubject(project!.showTicketNumberInSubject ?? true);
    }
  }, [project]);

  if (!project) {
    return null;
  }

  return (
    <PageContainer>
      <PageHeadLine title="Email settings" />
      <PageContent hasTitle isMediumBoxed className="email-settings">
        <div className="options-group">
          <div className="options-group-header">Email messages</div>
          <div className="checkbox-settings-content">
            <div className="checkbox-settings-container mt-10">
              <div className="switch-container mb-20">
                <Switch
                  width={40}
                  onColor="#2142E7"
                  height={20}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={(checked) => {
                    setSendFeedbackReceivedNotifications(checked);
                  }}
                  checked={sendFeedbackReceivedNotifications}
                />
                <span className="text">
                  <b>Feedback received confirmation:</b> send a feedback
                  received email confirmations to the reporter of a new feedback
                  item.
                </span>
              </div>
            </div>
            <div className="checkbox-settings-content">
              <div className="checkbox-settings-container mt-10">
                <div className="switch-container mb-20">
                  <Switch
                    width={40}
                    onColor="#2142E7"
                    height={20}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={(checked) => {
                      setEmailFallback(checked);
                    }}
                    checked={emailFallback}
                  />
                  <span className="text">
                    <b>Email-fallback for chat messages:</b> if a user does not
                    read a chat message within a few minutes, an email will be
                    sent to the user.
                  </span>
                </div>
              </div>
            </div>
            <div className="checkbox-settings-content">
              <div className="checkbox-settings-container mt-10">
                <div className="switch-container mb-20">
                  <Switch
                    width={40}
                    onColor="#2142E7"
                    height={20}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={(checked) => {
                      setConversationHistory(checked);
                    }}
                    checked={conversationHistory}
                  />
                  <span className="text">
                    <b>Conversation history:</b> the conversation history will
                    be included in email answers.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <PrimaryButton
            isLoading={loading}
            onClick={async () => {
              setLoading(true);

              try {
                await projectStore!.updateProject(project!.id, {
                  sendFeedbackReceivedNotifications,
                  disableEmailFallback: !emailFallback,
                  emailHistory: conversationHistory,
                  emailMessageStyle: chatStyle,
                });
                // eslint-disable-next-line no-empty
              } catch (exp) {}

              setLoading(false);
            }}
            className="mt-20"
            label="Save"
          />
        </div>
        <div className="options-group">
          <div className="options-group-header">Email style settings</div>
          <div className="switch-container">
            <span className="switch-title">
              Chat message style:
            </span>
            <SelectDropDown
              onChange={(item) => {
                setChatStyle(item.value);
              }}
              selectedItem={chatStyle}
              className="w-30"
              items={[
                { label: 'Chat bubble', value: 'CHAT' },
                { label: 'Plain text', value: 'PLAIN' },
              ]}
            />
          </div>
          <div className="checkbox-settings-content">
            <div className="checkbox-settings-container mt-10">
              <div className="switch-container mb-20">
                <Switch
                  width={40}
                  onColor="#2142E7"
                  height={20}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={(checked) => {
                    setShowTicketNumberInSubject(checked);
                  }}
                  checked={showTicketNumberInSubject}
                />
                <span className="text">
                  <b>Show ticket number in subject:</b> If no custom ticket
                  title is set, Gleap will add the ticket number to the title.
                </span>
              </div>
            </div>
          </div>
          <PrimaryButton
            isLoading={loading}
            onClick={async () => {
              setLoading(true);

              try {
                await projectStore!.updateProject(project!.id, {
                  emailMessageStyle: chatStyle,
                  showTicketNumberInSubject: showTicketNumberInSubject,
                });
                // eslint-disable-next-line no-empty
              } catch (exp) {}

              setLoading(false);
            }}
            className="mt-20"
            label="Save"
          />
        </div>
        <div className="options-group">
          <div className="options-group-header">Email template header logo</div>
          <ImageUpload
            image={emailTemplateLogo}
            label="Logo"
            editable
            formatInfo="(JPG, PNG)"
            accept="image/jpeg, image/png"
            className="email-header-upload mt-20"
            uploadPath="feedback_widgets"
            afterImageUpload={async (imageURL) => {
              try {
                await projectStore!.updateProject(
                  projectStore!.currentProject!.id,
                  {
                    emailTemplateLogo: imageURL,
                  },
                  false,
                  false,
                );
                // eslint-disable-next-line no-empty
              } catch (exp) {}
            }}
          />
          <InfoBox className="mt-20">
            Once uploaded, you can use the variable {`{{headerLogo}}`} to
            display the logo in your email templates.
          </InfoBox>
          <PrimaryButton
            isLoading={loading}
            onClick={async () => {
              setLoading(true);

              try {
                await projectStore!.updateProject(project!.id, {
                  sendFeedbackReceivedNotifications,
                  disableEmailFallback: !emailFallback,
                  emailHistory: conversationHistory,
                });
                // eslint-disable-next-line no-empty
              } catch (exp) {}

              setLoading(false);
            }}
            className="mt-20"
            label="Save"
          />
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore')(observer(UpdateProjectNotifications));
