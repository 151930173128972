import LoadingAnimationMedium from 'components/LoadingAnimationMedium/LoadingAnimationMedium';
import { inject, observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { useClickedOutside } from 'services/Helper';
import { ProjectStore } from 'stores/private/ProjectStore';
import debounce from 'lodash/debounce';
import PoweredBy from 'assets/Poweredby_640px-White_HorizText.png';
import './GiphyAttachment.scss';

interface GiphyAttachmentProps {
    onGifSelected: (article: any) => void;
    projectStore?: ProjectStore;
    showOver?: boolean;
}

const GIPHY_API_KEY = 'GjrQXAbWmwkKyUPa1hlMtkSqOwxQcg35';

const GiphyAttachment = ({
    onGifSelected,
    showOver = true,
}: GiphyAttachmentProps) => {
    const scrollContainerRef = useRef(null as any);

    const [initiallySearched, setInitiallySearched] = useState(false);
    const [searchingGifs, setSearchingGifs] = useState(false);
    const [showGifList, setShowGifList] = useState(false);
    const [gifs, setGifs] = useState([]);
    const [currentlyFocused, setCurrentlyFocused] = useState(0);

    useEffect(() => {
        if (showGifList) {
            debouncedPerformGiphySearch();
        }
    }, [showGifList]);

    const showGifListRef = useRef(showGifList);
    showGifListRef.current = showGifList;

    const gifRef = useRef(gifs);
    gifRef.current = gifs;

    const currentlyFocusedRef = useRef(0);
    currentlyFocusedRef.current = currentlyFocused;

    const wrapperRef = useRef(null);

    useClickedOutside(wrapperRef, () => {
        setShowGifList(false);
    });

    useEffect(() => {
        const handleEscape = (event: KeyboardEvent) => {
            if (event.key === 'Escape' && showGifListRef.current) {
                setShowGifList(false);
            }
        };

        document.addEventListener('keydown', handleEscape);

        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, []);

    const debouncedPerformGiphySearch = debounce(async (searchTerm) => {
        try {
            const apiUrl = searchTerm
                ? `https://api.giphy.com/v1/gifs/search?q=${searchTerm}&api_key=${GIPHY_API_KEY}`
                : `https://api.giphy.com/v1/gifs/trending?api_key=${GIPHY_API_KEY}`;
    
            const response = await fetch(apiUrl);
            const data = await response.json();
            setGifs(data.data);
            setSearchingGifs(false);
        } catch (error) {}
    }, 1000); 

    const handleGifSelect = (gif: any) => {
        onGifSelected(gif);
        setShowGifList(false);
    }

    const renderList = () => {
        if (!gifs || gifs.length === 0) {
            return (
                <div className="gif-list-wrapper gif-list-wrapper--nocontent">
                    {initiallySearched && !searchingGifs && (
                        <div className="text">No articles found</div>
                    )}
                    {searchingGifs && <LoadingAnimationMedium />}
                    {!initiallySearched && (
                        <div className="text">Start typing to search for articles</div>
                    )}
                </div>
            );
        }

        return (
            <div className="gif-list-wrapper" ref={scrollContainerRef}>
                <div className="gif-list-columns">
                    {gifs.map((article: any, index: number) => (
                        <div
                            key={index}
                            className={`gif-list-item ${currentlyFocusedRef.current === index && 'focused'}`}
                            onMouseEnter={() => {
                                setCurrentlyFocused(index);
                            }}
                            onClick={() => {
                                handleGifSelect(article);
                            }}
                        >
                            <img
                                src={article.images.preview_gif.url}
                                alt={article.title}
                                className="gif-list-item-gif"
                            />
                        </div>
                    ))}
                </div>
            </div>
        );
    };


    return (
        <div className="gif-action-container" ref={wrapperRef}>
            {showGifList && (
            <div className={`gif-list ${showOver ? 'gif-list-top' : 'gif-list-bottom'}`} ref={scrollContainerRef}>
                    <ReactTooltip
                        id="mauLimitTooltip"
                        className="infoTooltip"
                        delayHide={300}
                        type="light"
                        effect="solid"
                        getContent={(content) => {
                            try {
                                const data = JSON.parse(content);
                                return (
                                    <div className="ai-info-tooltip">
                                        <b>{data.title}</b>
                                        <br />
                                        {data.description}
                                    </div>
                                );
                            } catch (exp) { }
                            return null;
                        }}
                    />
                    <div className="gif-list-title">
                        <input
                            className="gif-list-title-input"
                            type="text"
                            autoFocus
                            placeholder="Search for gifs..."
                            onChange={(e) => {
                                const searchTerm = e.target.value;
                                setInitiallySearched(true);
                                setSearchingGifs(true);
                                debouncedPerformGiphySearch(searchTerm);
                            }}
                        />
                        <div className="gif-list-title-tag">Gifs</div>
                    </div>
                    {renderList()}
                    <div className="gif-action-container-footer">
                        <div className="hotkeys-list">
                            <div className="hotkeys-list-label">
                                <img src={PoweredBy} alt="Powered by Giphy" />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {!showGifList && (
                <ReactTooltip
                    id="gifButtonTooltip"
                    className="infoTooltip infoTooltipButton"
                    delayHide={0}
                    type="light"
                    effect="solid"
                    getContent={(content) => {
                        return (
                            <div className="send-key-tooltip">
                                <span>{content}</span>
                            </div>
                        );
                    }}
                />
            )}
            <div
                data-for="gifButtonTooltip"
                data-tip="Insert gif"
                className="gif-container-item"
                onClick={() => {
                    setShowGifList(!showGifList);
                }}
            >
                <i className="fa-solid fa-gif"></i>
            </div>
        </div>
    );
};

export default inject('projectStore')(observer(GiphyAttachment));
