import classNames from 'classnames';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { TourStep } from 'components/TourStep/TourStep';
import { inject, observer } from 'mobx-react';
import { Outbound } from 'models/Outbound';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import Switch from 'react-switch';
import './ProductTourConfiguration.scss';
import FeedbackUserFilter from 'components/FeedbackUserFilter/FeedbackUserFilter';
import { runInAction } from 'mobx';
import { OutboundStore } from 'stores/private/OutboundStore';
import { languages } from 'constants/Languages';
import { ProjectStore } from 'stores/private/ProjectStore';

interface ProductTourConfigurationProps {
  outboundRule: Outbound;
  outboundStore?: OutboundStore;
  projectStore?: ProjectStore;
}

const ProductTourConfiguration = ({
  outboundRule,
  outboundStore,
  projectStore,
}: ProductTourConfigurationProps) => {
  const { projectId, outboundId } = useParams();
  const popupRef = useRef(null as Window | null);
  const currentProject = projectStore?.currentProject;
  const messageContainerClassName = classNames({
    'tours-container': true,
  });
  const availableLanguages = languages.filter((language) =>
    currentProject?.localizations.find(
      (item) => item.language === language.code,
    ),
  );

  const steps = outboundRule?.config?.steps ?? [];

  const sendMessage = (data: any) => {
    if (popupRef.current) {
      popupRef.current.postMessage(JSON.stringify({
        ...data,
        type: "admin"
      }), "*");
    }
  }

  const receiveMessage = (event) => {
    try {
      const data = JSON.parse(event.data);

      // Respond with a 'pong' message
      if (data.type === 'admin') {
        if (data.name === 'init') {
          const token = localStorage.getItem('@bbtoken');

          sendMessage({
            name: "load",
            data: {
              outboundRule,
              token,
              availableLanguages,
              type: 'producttours',
            },
          });
        }

        if (data.name === 'smartlink-search') {
          projectStore!.searchSmartLinks(data.data.searchTerm, data.data.lang).then((preparedArticles) => {
            sendMessage({
              name: "smartlink-search-result",
              data: {
                links: preparedArticles ?? [],
              },
            });
          });
        }

        if (data.name === 'save') {
          runInAction(() => {
            outboundRule.config!.steps = data.data.steps;

            if (data?.data?.pageFilterDelay?.length > 0) {
              outboundRule.pageFilterDelay = data.data.pageFilterDelay;
            }

            if (data?.data?.startURL?.length > 0) {
              outboundRule.config!.startURL = data.data.startURL;
            }

            if (data?.data?.pageFilter?.length > 0) {
              outboundRule.pageFilter = data.data.pageFilter;
              outboundRule.pageFilterType = 'contains';
            }

            // Save.
            outboundStore!.updateOutboundRule(outboundRule?.id, outboundRule);

            // Close popup.
            if (popupRef.current) {
              popupRef.current.close();
            }
          });
        }
      }
    } catch (e) { }
  };

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);

    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, []);

  const openProductTourEditor = () => {
    popupRef.current = window.open(`https://app.gleap.io/projects/${projectId}/outbound/${outboundId}/startproducttour`, 'gleapPopup', 'width=' + (window.screen.width * 0.8) + ',height=' + (window.screen.height * 0.8) + ',scrollbars=yes,resizable=yes,location=no');
  }

  return (
    <div className={messageContainerClassName}>
      <div className="options-group">
        <div className="options-group-header">
          Product tour
          {outboundRule.config?.steps?.length > 0 && (<PrimaryButton label='Edit product tour' onClick={() => {
            openProductTourEditor();
          }} />)}
        </div>
        {outboundRule.config?.steps?.length === 0 ? (<div className='product-tour-cont-start'>
          <PrimaryButton label='Design your product tour' icon='play' iconSideRight={true} onClick={() => {
            openProductTourEditor();
          }} />
        </div>) : <div className='tour-steps-container-full'>
          <div className='tour-steps'>
            {steps.map((step, index) => {
              return (<div key={index} className={`tour-step-cont`}>
                <TourStep key={index} tourStep={step} onClick={() => {
                  openProductTourEditor();
                }} />
              </div>);
            })}
          </div>
        </div>}
      </div>
      <div className="options-group">
        <div className="options-group-header">
          Options
        </div>
        <div className="input-label">Allow dismiss</div>
        <div className="switch-container mb-20 mt-10">
          <Switch
            width={40}
            onColor="#2142E7"
            height={20}
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={(checked) => {
              runInAction(() => {
                outboundRule.config!.allowClose = checked;
              });
            }}
            checked={outboundRule.config!.allowClose}
          />
          <span>Allow users to dismiss the onboarding</span>
        </div>
        <div className="input-label mt-30">Back button</div>
        <div className="switch-container mb-20 mt-10">
          <Switch
            width={40}
            onColor="#2142E7"
            height={20}
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={(checked) => {
              runInAction(() => {
                outboundRule.config!.backButton = checked;
              });
            }}
            checked={outboundRule.config!.backButton}
          />
          <span>Show a back button</span>
        </div>
        <div className="mt-30">
          <div className="input-label">Sender</div>
          <FeedbackUserFilter
            value={outboundRule.sender}
            truncatePreview={50}
            truncate={50}
            placeholder="Select user"
            onValueChanged={(value) => {
              runInAction(() => {
                outboundRule.sender = value;
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default inject('outboundStore', 'projectStore')(observer(ProductTourConfiguration));
