export const isMatchingUrl = (url: string, match: string) => {
    if (!url || !match) {
        return false;
    }

    try {
        var dynamicPattern = /{\w+}/g;
        var regexPattern = match.replace(dynamicPattern, '[\\w-]+');
        var pattern = new RegExp('^' + regexPattern + '$');
        return pattern.test(url);
    } catch (e) {
        return false;
    }
}