import React, { useEffect, useRef, useState } from 'react';
import './EmailTemplate.scss';
import { OutboundStore } from 'stores/private/OutboundStore';
import { inject, observer } from 'mobx-react';
import { getLanguageProperty } from 'helper/AssignObjectKeysHelper';
import { languages } from 'constants/Languages';
import { ProjectStore } from 'stores/private/ProjectStore';
import { calculateContrast } from 'helper/Color';
import { UsersStore } from 'stores/private/UsersStore';
import Handlebars from 'handlebars';
import ReactDOM from 'react-dom';

interface EmailTemplateProps {
  templateId: string;
  viewOnly?: boolean;
  outboundStore?: OutboundStore;
  projectStore?: ProjectStore;
  usersStore?: UsersStore;
}

function EmailTemplate({
  children,
  templateId,
  outboundStore,
  projectStore,
  usersStore,
  viewOnly,
}: React.PropsWithChildren<EmailTemplateProps>) {
  const template = outboundStore?.currentTemplate;
  const currentLang = projectStore?.currentLanguage ?? 'en';
  const availableLanguages = languages.filter((language) =>
    projectStore?.currentProject?.localizations.find(
      (item) => item.language === language.code,
    ),
  );
  const currentLanguage = availableLanguages.find(
    (item) => item.code === currentLang,
  );
  const [modifiedHtmlContent, setModifiedHtmlContent] = useState('');
  const containerRef = useRef(null);

  const htmlContent = getLanguageProperty(
    template,
    'htmlContent',
    currentLanguage?.code,
    true,
  );

  const contrastColor = calculateContrast(projectStore?.flowConfig?.color);

  let htmlContentWithVariables = htmlContent;

  try {
    if (htmlContent) {
      htmlContentWithVariables = Handlebars.compile(htmlContent)({
        user: {
          profileImageUrl: usersStore?.currentUser?.profileImageUrl ?? '',
          name: usersStore?.currentUser?.firstName ?? 'John',
          projectName: projectStore?.currentProject?.name ?? 'ACME Inc.',
        },
        title: '{{title}}',
        headerLogo: projectStore?.currentProject?.emailTemplateLogo,
        color: projectStore?.currentProject?.flowConfig?.color,
        contrastColor: contrastColor,
        unsubscribeLink: '#',
        htmlContent: '{{{htmlContent}}}',
        unsubscribeText: 'Unsubscribe from these emails',
        bugRef: '#{{bugRef}}',
        conversationHistoryLayoutAlign: projectStore?.currentProject
          ?.emailHistory
          ? 'left'
          : 'center',
      });
    }
  } catch (_) {}

  useEffect(() => {
    if (outboundStore?.currentTemplate?.id !== templateId) {
      fetchTemplate(templateId);
      return;
    }
  }, [templateId]);

  useEffect(() => {
    htmlContentWithVariables = htmlContentWithVariables.replace(
      '{{{htmlContent}}}',
      `<div id="content-placeholder" class="${
        viewOnly ? 'no-background-color' : ''
      }"></div>`,
    );
    setModifiedHtmlContent(htmlContentWithVariables);
  }, [htmlContentWithVariables]);

  useEffect(() => {
    if (containerRef.current) {
      const placeholder = document.getElementById('content-placeholder');
      if (placeholder) {
        ReactDOM.render(<div>{children}</div>, placeholder);
      }
    }
  }, [containerRef.current, children, templateId, modifiedHtmlContent]);

  const fetchTemplate = async (templateId: string) => {
    await outboundStore?.getTemplate(templateId);
  };

  return (
    <div
      className="email-template-personal"
      ref={containerRef}
      dangerouslySetInnerHTML={{ __html: modifiedHtmlContent }}
    />
  );
}

export default inject(
  'outboundStore',
  'projectStore',
  'usersStore',
)(observer(EmailTemplate));
