import { inject, observer } from 'mobx-react';
import { ProjectStore } from 'stores/private/ProjectStore';
import './ActionTriggerEditor.scss';
import { Title } from 'components/Title/Title';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { botTriggerOptions } from 'components/Modals/TriggerActionEditorModal/TriggerActionEditorModal';
import { iconList } from 'components/UserTypeDropDown/UserTypeDropDown';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';

interface ActionTriggerEditorProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

const ActionTriggerEditor = ({
  projectStore,
  modalStore,
}: ActionTriggerEditorProps) => {
  const trigger = botTriggerOptions.find(
    ({ value }) => value === projectStore?.bot?.trigger,
  );

  return (
    <div
      className="trigger-action-container"
      onClick={() => {
        modalStore?.openModal(MODALTYPE.TRIGGER_ACTION_EDITOR);
      }}
    >
      <Row alignItems='flex-start'>
        <div className="hexagon">
          <i
            className={`fa-solid ${
              trigger?.name ? iconList[trigger?.value] : 'fa-bolt'
            } trigger-icon mb-10`}
          />
        </div>
        <div>
          <Title
            className="trigger-title"
            label={trigger?.name ?? 'Select trigger'}
          />
          <span className="bot-action-option-item-subtitle">
            Edit the trigger that will start the conversation with your bot.
          </span>
        </div>
      </Row>
    </div>
  );
};

export default inject(
  'projectStore',
  'modalStore',
)(observer(ActionTriggerEditor));
