import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import { checkIntegrationData } from 'helper/Integration';
import { inject, observer } from 'mobx-react';
import { INTEGRATION } from 'models/Integration';
import React, { useEffect, useState } from 'react';
import { DiscordIntegrationStore } from 'stores/integrations/DiscordIntegrationStore';
import { ProjectStore } from 'stores/private/ProjectStore';

interface DiscordConfigurationProps {
  integrationID: string;
  index: number;
  discordIntegrationStore?: DiscordIntegrationStore;
  projectStore?: ProjectStore;
  onChange: (data: any) => any;
}

const DiscordConfiguration = ({
  integrationID,
  index,
  discordIntegrationStore,
  projectStore,
  onChange,
}: DiscordConfigurationProps) => {
  const project = projectStore?.currentProject;

  const [currentIntegrationInfo, setCurrentIntegrationInfo] = useState(
    {} as any,
  );

  const channels = discordIntegrationStore?.channels;

  const [channelID, setChannelID] = useState('');

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    if (project && isMounted) {
      initialize();
    }
  }, [project, isMounted]);

  useEffect(() => {
    saveIntegrationInfo();
  }, [channelID]);

  const initialize = async () => {
    discordIntegrationStore!.setIntegrationID(integrationID);
    discordIntegrationStore!.getChannels();

    if (
      !checkIntegrationData({
        project,
        integrationName: INTEGRATION.DISCORD,
        integrationID,
        actionIndex: index,
      })
    ) {
      return;
    }

    const integrationInfo =
      project?.integrations?.discord[integrationID]?.actions[index]
        ?.integrationInfo;

    setCurrentIntegrationInfo(integrationInfo);
  };

  const chooseChannel = async (channelId) => {
    setChannelID(channelId);
  };

  const saveIntegrationInfo = () => {
    const integrationInfo = {
      channelID,
    };

    onChange(integrationInfo);
  };

  return (
    <div className="configuration-container mt-30">
      <SelectDropDown
        className="mb-15"
        label="Channel"
        labelPropertyName="name"
        valuePropertyName="id"
        selectedItem={channels.find(
          (element) => element.id === currentIntegrationInfo?.channelID,
        )}
        isLoading={channels.length === 0}
        items={channels}
        onChange={(option) => {
          chooseChannel(option.id);
        }}
      />
    </div>
  );
};

export default inject(
  'discordIntegrationStore',
  'projectStore',
)(observer(DiscordConfiguration));
