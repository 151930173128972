import InfoBox from 'components/InfoBox/InfoBox';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import PropertySelect from 'components/PropertyComponents/PropertySelect/PropertySelect';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Action } from 'models/Bot';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './ChatInputActionEditor.scss';

interface ChatInputActionEditorProps {
  action: Action;
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
}

const ChatInputActionEditor = ({
  action,
  modalStore,
  projectStore,
}: ChatInputActionEditorProps) => {
  return (
    <div className="input-action-editor">
      <PageContainer>
        <PageHeadLine title="Collect customer reply">
          <div className="header-content-right">
            <PrimaryButton
              label="Close"
              iconSideRight={false}
              onClick={() => {
                modalStore!.closeModal();
              }}
            />
          </div>
        </PageHeadLine>
        <PageContent hasTitle>
          <>
            <InfoBox>
              This action will collect a new message from the user. If you want to additionally store it as an attribute, please fill out the fields below.
            </InfoBox>
            <PropertySelect
              label={'Attribute (optional)'}
              className="mt-50"
              selectedItemId={action.attributeId}
              contactPropertiesFirst={true}
              useId
              onChange={(item) => {
                runInAction(() => {
                  action.attributeId = item._id;
                });
              }}
            />
          </>
        </PageContent>
      </PageContainer>
    </div>
  );
};

export default inject(
  'modalStore',
  'projectStore',
)(observer(ChatInputActionEditor));
