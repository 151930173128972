import * as React from 'react';
import './ConditionValueSelect.scss';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import { useState } from 'react';
import Switch from 'react-switch';
import DatePicker from 'react-date-picker-gleap/dist/entry';
import { isValidDate } from 'services/Helper';
import ReactTooltip from 'react-tooltip';
import { useParams } from 'react-router';

interface ConditionValueTypeSelectorProps {
  attribute: any;
  label?: string;
  defaultType?: string;
  onValueChange: (val: any) => void;
  requiredType?: string;
}

const typeOptions = [
  { label: 'Text', value: 'string' },
  { label: 'Number', value: 'number' },
  { label: 'Boolean', value: 'boolean' },
  { label: 'Date', value: 'date' },
];

const ConditionValueSelect = ({
  defaultType = 'string',
  attribute,
  onValueChange,
  requiredType,
  label,
}: ConditionValueTypeSelectorProps) => {
  const { projectId } = useParams();
  const [selectedType, setSelectedType] = useState(requiredType || defaultType);

  const renderInput = () => {
    switch (selectedType) {
      case 'string':
        return (
          <input
            className="textinput-gray"
            value={attribute.data}
            type="text"
            onChange={(inputVal) => {
              if (inputVal.target.value && inputVal.target.value.length > 0) {
                onValueChange({
                  ...attribute,
                  data: inputVal.target.value,
                });
              } else {
                onValueChange({
                  ...attribute,
                  data: '',
                });
              }
            }}
          />
        );
      case 'number':
        return (
          <input
            className="textinput-gray"
            value={attribute.data}
            type="number"
            onChange={(inputVal) => {
              const value = parseFloat(inputVal.target.value);
              if (typeof value === 'number' && !isNaN(value)) {
                onValueChange({
                  ...attribute,
                  data: value,
                });
              } else {
                onValueChange({
                  ...attribute,
                  data: 0,
                });
              }
            }}
          />
        );
      case 'boolean':
        return (
          <div className="boolean-input">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={(checked) => {
                onValueChange({
                  ...attribute,
                  data: checked,
                });
              }}
              checked={attribute.data}
            />
            {attribute.data ? 'True' : 'False'}
          </div>
        );
      default:
        return (
          <DatePicker
            onChange={(selectedDate) => {
              if (selectedDate && selectedDate instanceof Date) {
                onValueChange({
                  ...attribute,
                  data: selectedDate,
                });
              }
            }}
            value={
              attribute.data instanceof Date
                ? attribute.data
                : new Date(attribute.data ?? new Date())
            }
            clearIcon={<></>}
            calendarIcon={<></>}
            showLeadingZeros={false}
          />
        );
    }
  };

  const setValueType = (type: any) => {
    const selectedType = type.value;
    setSelectedType(selectedType);

    switch (selectedType) {
      case 'string':
        if (typeof attribute.data !== 'string') {
          onValueChange({
            ...attribute,
            data: attribute?.data ? attribute.data.toString() : '',
          });
        }
        break;
      case 'number':
        if (!isNaN(attribute.data)) {
          onValueChange({
            ...attribute,
            data: parseInt(attribute.data),
          });
        } else if (typeof attribute.data !== 'number') {
          onValueChange({
            ...attribute,
            data: 1,
          });
        }
        break;
      case 'boolean':
        if (typeof attribute.data !== 'boolean') {
          onValueChange({
            ...attribute,
            data: true,
          });
        }
        break;
      case 'date':
        if (!isValidDate(attribute.data)) {
          onValueChange({
            ...attribute,
            data: new Date(),
          });
        }
        break;
      default:
        onValueChange({
          ...attribute,
          data: '',
        });
        break;
    }
  };

  return (
    <div className="select-row">
      <div className="input-container">{renderInput()}</div>
      <div className="type-dropdown">
        {requiredType ? (
          <div
            className="fixed-type-container"
            data-for="typeTooltip"
            data-tip={`${label ?? 'This field'} is defined as a ${
              typeOptions.find((o) => o.value === requiredType)?.label
            } type in the`}
          >
            {requiredType === 'date' && (
              <i className="fa-regular fa-calendar" />
            )}
            {requiredType === 'string' && (
              <i className="fa-regular fa-font-case" />
            )}
            {requiredType === 'number' && <i className="fa-regular fa-1" />}
            {requiredType === 'boolean' && (
              <i className="fa-regular fa-toggle-on" />
            )}
          </div>
        ) : (
          <SelectDropDown
            isSearchable={false}
            items={typeOptions}
            selectedItem={typeOptions.find(
              (option) => option.value === selectedType,
            )}
            placeholder="Select value type"
            onChange={setValueType}
            className="condition-value-type-selector"
          />
        )}
      </div>
      <ReactTooltip
        id="typeTooltip"
        className="infoTooltip infoTooltipButton"
        delayHide={250}
        type="light"
        effect="solid"
        getContent={(content) => {
          return (
            <div className="send-key-tooltip">
              <span>{content}</span>
              <a
                className="attribute-link"
                href={`/projects/${projectId}/settings/attributes`}
                target="_blank"
              >
                Data attributes
              </a>
            </div>
          );
        }}
      />
    </div>
  );
};

export default ConditionValueSelect;
