import React from 'react';
import classNames from 'classnames';
import './TextArea.scss';
import { on } from 'events';
import { ProjectStore } from 'stores/private/ProjectStore';
import { inject, observer } from 'mobx-react';
import { languages } from 'constants/Languages';
import LanguageDropdown from 'components/LanguageDropdown/LanguageDropdown';
import { runInAction } from 'mobx';

interface TextAreaProps {
  placeholder?: string;
  inputRef?: any;
  rows?: number;
  value?: any;
  className?: any;
  textAreaClassName?: any;
  labelClassName?: any;
  error?: string;
  onEnter?: any;
  name?: string;
  label?: string;
  translationObject?: any;
  required?: boolean;
  readonly?: boolean;
  onClick?: any;
  onChange?: (text) => void;
  localizedable?: boolean;
  projectStore?: ProjectStore;
  onBlur?: () => void;
  initalValue?: string;
}

const TextArea = ({
  placeholder,
  value,
  className,
  labelClassName,
  rows = 3,
  onEnter,
  error,
  inputRef,
  name,
  label,
  required,
  readonly,
  onClick,
  translationObject,
  onChange,
  localizedable = false,
  projectStore,
  onBlur,
  textAreaClassName,
  initalValue = undefined,
}: TextAreaProps) => {
  const currentProject = projectStore?.currentProject;
  const hasTranslations =
    currentProject?.localizations && currentProject?.localizations.length > 1;
  const currentLang = projectStore?.currentLanguage ?? 'en';

  const availableLanguages = languages.filter((language) =>
    currentProject?.localizations.find(
      (item) => item.language === language.code,
    ),
  );

  const translationTable = translationObject?.localized ?? {};
  const allTranslated = availableLanguages.every((item) => {
    return translationTable[item.code] && translationTable[item.code] !== '';
  });

  const currentLanguage = availableLanguages.find(
    (item) => item.code === currentLang,
  );

  const onEnterPressed = (event: any) => {
    if (event.key === 'Enter') {
      if (onEnter) {
        onEnter();
      }
    }
  };

  let rOnChange: any = null;
  let restInputRef = {};

  if (inputRef) {
    const { onChange: rOnC, ...restInputR } = inputRef;
    rOnChange = rOnC;
    restInputRef = restInputR;
  }

  const handleChange = (e) => {
    if (onChange) {
      onChange(e);
    }

    if (rOnChange) {
      rOnChange(e);
    }
  };

  const renderEditableInput = () => {
    const textAreaEditableClassName = classNames(
      {
        'textarea-gray': true,
        'textarea-error': error !== '',
      },
      textAreaClassName,
    );

    return (
      <div className="textarea-container">
        <textarea
          {...restInputRef}
          rows={rows}
          className={textAreaEditableClassName}
          name={name}
          placeholder={placeholder}
          value={value}
          readOnly={readonly}
          onChange={handleChange}
          onBlur={onBlur}
          defaultValue={initalValue}
        />
        {onClick && (
          <div onClick={onClick} className="update-button">
            Update
          </div>
        )}
      </div>
    );
  };

  const buildLocalizeDropdown = () => {
    if (!hasTranslations) {
      return <></>;
    }

    return (
      <LanguageDropdown
        className="language-dropdown"
        items={availableLanguages.map((lang) => {
          return {
            ...lang,
            hasTranslation:
              translationTable[lang.code] && translationTable[lang.code] !== '',
          };
        })}
        selectedItem={currentLanguage}
        onChange={(value) => {
          runInAction(() => {
            projectStore!.currentLanguage = value.code;
          });
        }}
      />
    );
  };

  const textAreaCn = classNames(
    {
      'field-container': true,
      'field-container--translateable': localizedable && hasTranslations,
      'field-container--missingtranslation':
        localizedable && (!value || value === ''),
      'field-container--notalltranslated': localizedable && !allTranslated,
      'field-container--alltranslated': localizedable && allTranslated,
    },
    className,
  );

  return (
    <div
      className={textAreaCn}
      onClick={() => {}}
      onKeyPress={(event) => {
        onEnterPressed(event);
      }}
    >
      <div className="label-container">
        {label && (
          <div className="input-label">
            {label} {required && <span className="input-required">*</span>}
          </div>
        )}
        {localizedable && buildLocalizeDropdown()}
      </div>
      {renderEditableInput()}
    </div>
  );
};

export default inject('projectStore')(observer(TextArea));
