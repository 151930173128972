import React, { useState, useEffect } from 'react';
import './FeedbackPossibleLanes.scss';
import { inject, observer } from 'mobx-react';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { arrayMoveImmutable as arrayMove } from 'array-move';
import SortableTextInput from 'components/SortableTextInput/SortableTextInput';
import { ReactComponent as DragIcon } from '../../assets/icons/dragicon.svg';
import { runInAction } from 'mobx';

interface FeedbackPossibleLanesProps {
  feedbackType?: string;
  possibleLanes: any[];
  onChange: (possibleLanes: any[]) => void;
}

const FeedbackPossibleLanes = ({
  feedbackType,
  possibleLanes,
  onChange,
}: FeedbackPossibleLanesProps) => {
  const possibleLanesDeep = JSON.parse(JSON.stringify(possibleLanes));
  const [editableColumns, setEditableColumns] = useState(
    possibleLanesDeep.filter((lane) => {
      if (
        lane.key === 'OPEN' ||
        lane.key === 'DONE' ||
        lane.key === 'SNOOZED'
      ) {
        return false;
      }
      return true;
    }),
  );

  useEffect(() => {
    const firstLane = possibleLanesDeep.find((lane) => lane.key === 'OPEN');
    const snoozedLane = possibleLanesDeep.find(
      (lane) => lane.key === 'SNOOZED',
    );
    const lastLane = possibleLanesDeep.find((lane) => lane.key === 'DONE');

    const result = [firstLane, ...editableColumns, lastLane];

    // If SNOOZED exists, insert it in the second position
    if (snoozedLane) {
      result.splice(1, 0, snoozedLane);
    }

    onChange(result);
  }, [editableColumns]);

  const onSortEnd = (event) => {
    runInAction(() => {
      setEditableColumns(
        arrayMove(editableColumns, event.oldIndex, event.newIndex),
      );
    });
  };

  const editColumnItem = (input, index) => {
    runInAction(() => {
      const array = editableColumns.map((l) => ({ ...l }));
      array[index].title = input;
      setEditableColumns(array);
    });
  };

  const deleteColumn = (index) => {
    runInAction(() => {
      const array = editableColumns.map((l) => ({ ...l }));
      array.splice(index, 1);
      setEditableColumns(array);
    });
  };

  const addColumnToBoard = () => {
    runInAction(() => {
      const array = editableColumns.map((l) => ({ ...l }));
      array.push({
        title: 'New Column',
        key: Math.random().toString(36).substring(7),
      });
      setEditableColumns(array);
    });
  };

  const DragHandle = SortableHandle(() => (
    <DragIcon className="lanes-drag-icon" />
  ));

  const SortableItem = SortableElement((value) => (
    <div className="draggable-list-item">
      <DragHandle />
      <SortableTextInput
        input={value}
        onChange={editColumnItem}
        onEnter={() => {}}
      />
      <div
        className="delete-item"
        onClick={() => {
          deleteColumn(value.value.index);
        }}
      >
        <i className="fa-solid fa-trash" />
      </div>
    </div>
  ));

  const SortableList = SortableContainer(({ itemsList }) => {
    return (
      <div>
        {itemsList.map((value, index) => (
          <SortableItem
            key={value.key}
            index={index}
            value={{ title: value.title, index }}
          />
        ))}
      </div>
    );
  });

  if (!possibleLanes) {
    return null;
  }

  function renderLaneByKeyName(keyName) {
    const lane = possibleLanes.find((lane) => lane.key === keyName);
    if (!lane) return <></>;

    return (
      <div className="draggable-list-item not-editable">
        <div className="spacer" />
        <div>{lane.title}</div>
      </div>
    );
  }

  return (
    <>
      <div className="sortable-item-container">
        <div className="draggable-list-header mb-5">
          <div className="list-header">Columns</div>
          <div className="list-header add-column" onClick={addColumnToBoard}>
            + Add column
          </div>
        </div>

        {renderLaneByKeyName('OPEN')}

        {feedbackType === 'INQUIRY' && renderLaneByKeyName('SNOOZED')}

        <SortableList
          pressDelay={100}
          itemsList={editableColumns}
          onSortEnd={onSortEnd}
          useDragHandle
          helperClass="sortable-helper"
        />

        {renderLaneByKeyName('DONE')}
      </div>
    </>
  );
};

export default inject('projectStore')(observer(FeedbackPossibleLanes));
