import './FeedbackActionQuestionPreview.scss';
import { ReactComponent as CheckIcon } from 'assets/icons/check-solid.svg';
import CSSInjector from 'components/CSSInjector/CSSInjector';
import { ReactComponent as ArrowRight } from 'assets/icons/angle-right-solid.svg';
import { ReactComponent as GleapIcon } from 'assets/icons/GleapIcon.svg';
import { inject, observer } from 'mobx-react';
import { ProjectStore } from 'stores/private/ProjectStore';
import { getLanguageProperty } from 'helper/AssignObjectKeysHelper';
import { PropertyStore } from 'stores/private/PropertyStore';

interface FeedbackActionQuestionPreviewProps {
  formItem: any;
  updateToggle: boolean;
  feedbackType: string;
  chatStyle?: boolean;
  projectStore?: ProjectStore;
  introMessage?: string;
  outroMessage?: string;
  editingType?: 'question' | 'intro' | 'outro';
  propertyStore?: PropertyStore;
}

export const emojiList = [
  {
    value: 0,
    label: '😡',
  },
  {
    value: 2,
    label: '😢',
  },
  {
    value: 6,
    label: '😐',
  },
  {
    value: 8,
    label: '😊',
  },
  {
    value: 10,
    label: '😍',
  },
];

const FeedbackActionQuestionPreview = ({
  formItem,
  updateToggle,
  feedbackType,
  chatStyle = false,
  projectStore,
  introMessage,
  outroMessage,
  editingType,
  propertyStore,
}: FeedbackActionQuestionPreviewProps) => {
  const currentLang = projectStore?.currentLanguage ?? 'en';

  const buildNPSContentPreview = () => {
    const npsType = formItem.npsType ? formItem.npsType : 'classic';
    var fields: any[] = [];
    if (npsType === 'classic') {
      fields = Array.from({ length: 11 }, (_, i) => {
        return {
          value: i,
          label: i,
        };
      });
    } else if (npsType === 'modern') {
      fields = Array.from({ length: 6 }, (_, i) => {
        return {
          value: i * 2,
          label: i,
        };
      });
    } else if (npsType === 'emoji') {
      fields = emojiList;
    }

    const renderOption = (value: any) => {
      return (
        <div className="nps-form-item-item" key={value.value}>
          <span>{getLanguageProperty(value, 'label', currentLang, true)}</span>
        </div>
      );
    };

    return (
      <div className={`nps-form-item nps-form-item-${npsType}`}>
        <div className="nps-form-item-items">
          {fields.map((value: any) => renderOption(value))}
        </div>
        {formItem.hideInfoLabel ? null : (
          <div className="nps-form-item-labels">
            <span>
              {getLanguageProperty(fields[0], 'label', currentLang, true)} -{' '}
              {getLanguageProperty(
                formItem,
                'lowestValueLabel',
                currentLang,
                true,
              )}
            </span>
            <span>
              {getLanguageProperty(
                fields[fields.length - 1],
                'label',
                currentLang,
              )}{' '}
              -{' '}
              {getLanguageProperty(
                formItem,
                'highestValueLabel',
                currentLang,
                true,
              )}
            </span>
          </div>
        )}
      </div>
    );
  };

  const buildOneToFiveScaleContentPreview = () => {
    const upToNumber = formItem.upToNumber ? formItem.upToNumber : 5;
    const fromNumber = !isNaN(formItem.fromNumber) ? formItem.fromNumber : 0;
    const fields = Array.from(
      { length: upToNumber - (fromNumber - 1) },
      (_, i) => fromNumber + i,
    );

    const renderOption = (value: any) => {
      return (
        <div
          className="one-to-five-form-item-item"
          key={value}
          onClick={() => {}}
        >
          <span>{value}</span>
        </div>
      );
    };

    return (
      <div className="one-to-five-form-item">
        <div className="one-to-five-form-item-items">
          {fields.map((value: any) => renderOption(value))}
        </div>
        <div className="one-to-five-form-item-labels">
          <span>
            {fromNumber} -{' '}
            {getLanguageProperty(
              formItem,
              'lowestValueLabel',
              currentLang,
              true,
            )}
          </span>
          <span>
            {upToNumber} -{' '}
            {getLanguageProperty(
              formItem,
              'highestValueLabel',
              currentLang,
              true,
            )}
          </span>
        </div>
      </div>
    );
  };

  const buildMultipleChoiceSelectContentPreview = () => {
    const getCharacter = (index: number) => {
      return String.fromCharCode(97 + index);
    };

    const currentDataAttribute = propertyStore?.getPropertyByFieldId({
      fieldId: formItem.name,
      feedbackType: feedbackType,
    });

    const choices = currentDataAttribute?.options ?? formItem.choices;

    return (
      <div className="multiple-choice-form-container">
        <div className="multiple-choice-form-item">
          {choices &&
            choices.map((option, index) => {
              return (
                <div key={index} className={`multiple-choice-form-item-choice`}>
                  <div className="multiple-choice-form-item-choice-shortcut">
                    {getCharacter(index)}
                  </div>
                  <div className="multiple-choice-form-item-choice-value">
                    {getLanguageProperty(option, `label`, currentLang)}
                  </div>
                  <div className="multiple-choice-form-item-choice-check">
                    <CheckIcon />
                  </div>
                </div>
              );
            })}
        </div>
        {(!choices || choices.length === 0) && (
          <div className="no-options-added">No options added yet. 🧐</div>
        )}
      </div>
    );
  };

  const buildPrioritySelectionContentPreview = () => {
    return (
      <div className="multiple-choice-form-container">
        <div className="multiple-choice-form-item">
          {formItem.choices &&
            formItem.choices.map((option, index) => {
              return (
                <div key={index} className={`multiple-choice-form-item-choice`}>
                  <div
                    className={`priority-item-dot priority-item-dot--${index}`}
                  />
                  <div className="multiple-choice-form-item-choice-value">
                    {getLanguageProperty(option, `label`, currentLang)}
                  </div>
                  <div className="multiple-choice-form-item-choice-check">
                    <CheckIcon />
                  </div>
                </div>
              );
            })}
        </div>
        {(!formItem.choices || formItem.choices.length === 0) && (
          <div className="no-options-added">No options added yet. 🧐</div>
        )}
      </div>
    );
  };

  const buildRatingContentPreview = () => {
    const ratingOptions = ['😡', '😢', '😐', '😊', '😍'];
    const renderOption = (value: any, index: number) => {
      return (
        <div key={index} className="rating-form-item-option">
          {ratingOptions[index]}
        </div>
      );
    };

    return (
      <div className="rating-form-item">
        <div className="rating-form-item-items">
          {[1, 2.5, 5, 7.5, 10].map((value: any, index: number) =>
            renderOption(value, index),
          )}
        </div>
      </div>
    );
  };

  const buildQuestionContentPreview = (isTextfield) => {
    return (
      <>
        {isTextfield ? (
          <div className={`text-form-item text-form-item--button`}>
            <input
              className="text-form-item-input"
              placeholder={getLanguageProperty(
                formItem,
                'placeholder',
                currentLang,
              )}
            />
            <button type="button">
              <ArrowRight />
            </button>
          </div>
        ) : (
          <div className="textarea-form-item">
            <textarea
              className="textarea-form-item-textarea"
              placeholder={getLanguageProperty(
                formItem,
                'placeholder',
                currentLang,
              )}
            />
          </div>
        )}
      </>
    );
  };

  const buildUploadContentPreview = () => {
    return (
      <div className="file-upload-preview">
        <p>Choose a file or drag it here.</p>
      </div>
    );
  };

  const renderPreview = () => {
    switch (formItem.type) {
      case 'netpromoterscore':
        return buildNPSContentPreview();
      case 'onetofive':
        return buildOneToFiveScaleContentPreview();
      case 'multiplechoice':
        return buildMultipleChoiceSelectContentPreview();
      case 'multiple-choice-multi-select':
        return buildMultipleChoiceSelectContentPreview();
      case 'priority-selection':
        return buildPrioritySelectionContentPreview();
      case 'rating':
        return buildRatingContentPreview();
      case 'text':
        return buildQuestionContentPreview(true);
      case 'textarea':
        return buildQuestionContentPreview(false);
      case 'upload':
        return buildUploadContentPreview();
      default:
        return (
          <div className="text p-20">
            This form item is not supported anymore.
          </div>
        );
    }
  };

  const hasTitle =
    formItem && formItem.title && Object.keys(formItem.title).length > 0;

  const getAvatar = () => {
    const operatorAvatarImage =
      projectStore?.currentProject?.flowConfig?.operatorAvatarImage;
    if (operatorAvatarImage && operatorAvatarImage.length > 0) {
      return <img src={operatorAvatarImage} />;
    }

    return <GleapIcon />;
  };

  const renderMessageBubble = () => {
    if (editingType === 'intro') {
      return (
        <div className="feedback-widget-preview-chatbubble">
          <div className="feedback-widget-preview-chatbubble-avatar">
            {getAvatar()}
          </div>
          <div className="feedback-widget-preview-chatbubble-message">
            <div className="message">
              {introMessage ? introMessage : <i>No intro message set.</i>}
            </div>
          </div>
        </div>
      );
    }

    if (editingType === 'outro') {
      return (
        <div className="feedback-widget-preview-chatbubble">
          <div className="feedback-widget-preview-chatbubble-avatar">
            {getAvatar()}
          </div>
          <div className="feedback-widget-preview-chatbubble-message">
            <div className="message">
              {outroMessage ? outroMessage : <i>No thank you message set.</i>}
            </div>
          </div>
        </div>
      );
    }

    if (chatStyle && hasTitle) {
      return (
        <div className="feedback-widget-preview-chatbubble">
          <div className="feedback-widget-preview-chatbubble-avatar">
            {getAvatar()}
          </div>
          <div className="feedback-widget-preview-chatbubble-message">
            <div className="message">
              {getLanguageProperty(formItem, 'title', currentLang, true)}
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="feedback-widget-preview-container">
      {renderMessageBubble()}
      {formItem && editingType === 'question' && (
        <div className="feedback-widget-preview">
          <div className="feedback-widget-preview-content">
            <div className="feedback-content-preview">
              {!chatStyle && hasTitle && (
                <div className="form-item-label">
                  {getLanguageProperty(formItem, 'title', currentLang, true)}
                  {formItem.required && (
                    <span className="form-item-label-required">*</span>
                  )}
                </div>
              )}
              {!chatStyle && formItem.description && (
                <div className="form-item-description">
                  {getLanguageProperty(
                    formItem,
                    'description',
                    currentLang,
                    true,
                  )}
                </div>
              )}
              {renderPreview()}
            </div>
          </div>
        </div>
      )}
      <CSSInjector />
    </div>
  );
};

export default inject(
  'projectStore',
  'propertyStore',
)(observer(FeedbackActionQuestionPreview));
