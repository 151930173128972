import moment from "moment";

const dateFormatOptions = {
  today: {
    hour: '2-digit',
    minute: '2-digit',
  },
  later: {
    year: undefined,
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: undefined,
  },
  full: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  },
  bugBoard: {
    month: 'short',
    day: 'numeric',
  },
  bugBoardYearOld: {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  },
}

export function formatDateDayLater(date: Date | null, format: string = 'today', formatLater: string = 'later') {
  const userLanguage = navigator.language;


  if (!date) {
    return null;
  }

  var dateFormat = !isCommentOlderThanToday(date) ? dateFormatOptions[format] : dateFormatOptions[formatLater];
  
  return new Date(date).toLocaleString(userLanguage, dateFormat!);
}

export function formatDateYearLater(date: Date | null, format: string = 'bugBoard', formatLater: string = 'bugBoardYearOld') {
  const userLanguage = navigator.language;
  const momentCreatedAt = moment(date);
  const currentYear = moment().year();

  if (!date) {
    return null;
  }

  if(momentCreatedAt.year() === currentYear) {
    var dateFormat = dateFormatOptions[format];
  } else {
    var dateFormat = dateFormatOptions[formatLater];
  }
  
  return new Date(date).toLocaleString(userLanguage, dateFormat!);
}

export function formatDate(date: Date | null, format: string = 'today') {
  const userLanguage = navigator.language;

  if (!date) {
    return null;
  }

  return new Date(date).toLocaleString(userLanguage, dateFormatOptions[format]);
}

export function isCommentOlderThanToday(createdAt: Date) {
  const now: Date = new Date();
  const commentDate: Date = new Date(createdAt);
  return (
    commentDate.getDate() !== now.getDate() ||
    commentDate.getMonth() !== now.getMonth() ||
    commentDate.getFullYear() !== now.getFullYear()
  );
}


export function commentOlderThanLastYear(createdAt: Date) {
  const now: Date = new Date();
  const commentDate: Date = new Date(createdAt);
  return (
    commentDate.getDate() !== now.getDate() ||
    commentDate.getMonth() !== now.getMonth() ||
    commentDate.getFullYear() !== now.getFullYear()
  );
}