import CreateableSelectDropdown from 'components/CreateableSelectDropdown/CreateableSelectDropdown';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { inject, observer } from 'mobx-react';
import { useState } from 'react';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './TagActionEditor.scss';

interface TagActionEditorProps {
  action: any;
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
}

const TagActionEditor = ({
  action,
  modalStore,
  projectStore,
}: TagActionEditorProps) => {
  const [toggleState, setToggleState] = useState(false);

  const tags = ((action.tags ?? []) as any[]).map((tagItem) => {
    return {
      label: tagItem,
      value: tagItem,
      color: '#060d25',
    };
  });

  return (
    <div className="edit-feature-request-modal">
      <PageContainer>
        <PageHeadLine title="Assign tags">
          <div className="header-content-right">
            <PrimaryButton
              label="Close"
              iconSideRight={false}
              onClick={() => {
                modalStore!.closeModal();
              }}
            />
          </div>
        </PageHeadLine>
        <PageContent hasTitle>
          <div className="tagcontainer">
            <div className="input-label">
              Choose tags to apply to the conversation
            </div>
            <CreateableSelectDropdown
              showBorder={false}
              shared={false}
              selectedItem={tags}
              items={
                projectStore?.currentProject?.feedbackTags
                  ? projectStore?.currentProject?.feedbackTags!.map(
                      (tagItem) => {
                        return {
                          label: tagItem.label,
                          value: tagItem.label,
                          color: tagItem.color ?? '#060d25',
                        };
                      },
                    )
                  : []
              }
              onChange={(selectedItems: any[]) => {
                const tags = selectedItems
                  ? selectedItems.map((item) => item.value)
                  : [];
                action.tags = tags;
                projectStore?.handleTags(tags);
              }}
            />
          </div>
        </PageContent>
      </PageContainer>
    </div>
  );
};

export default inject('modalStore', 'projectStore')(observer(TagActionEditor));
