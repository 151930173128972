import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import { getChannels, getTeams } from 'services/integrations/AzureHttpService';

export class AzureIntegrationStore {
  stores: any = {};

  integrationID: string = '';

  teams: any = [];
  channels: any = [];

  constructor() {
    makeAutoObservable(this);
  }

  setStores(stores) {
    this.stores = stores;
  }

  setIntegrationID = (integrationID) => {
    this.integrationID = integrationID;
  };

  setTeams = (teams) => {
    this.teams = teams;
  };

  getTeams = async () => {
    try {
      const response = await getTeams({
        projectID: this.stores.projectStore.currentProject.id,
        integrationID: this.integrationID,
      });
      if (response.status === 200) {
        this.setTeams(response.data.value);
      }
    } catch (err: any) {
      toast.error('Something went wrong!');
    }
  };

  setChannels = (channels) => {
    this.channels = channels;
  };

  getChannels = async (teamID: string) => {
    if (!teamID || !this.stores?.projectStore?.currentProject?.id) {
      return;
    }

    try {
      const response = await getChannels({
        projectID: this.stores.projectStore.currentProject.id,
        integrationID: this.integrationID,
        teamID,
      });
      if (response.status === 200) {
        this.setChannels(response.data.value);
      }
    } catch (err: any) {
      toast.error('Something went wrong!');
    }
  };
}
