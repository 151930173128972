import React, { useEffect, useState } from 'react';
import Editor from '@monaco-editor/react';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import PageContainer from 'components/PageContainer/PageContainer';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContent from 'components/PageContent/PageContent';
import './ProjectEmailEditor.scss';
import classNames from 'classnames';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { useNavigate, useParams } from 'react-router';
import { ProjectStore } from 'stores/private/ProjectStore';
import { inject, observer } from 'mobx-react';
import { languages } from 'constants/Languages';
import Handlebars from 'handlebars';
import {
  getLanguageProperty,
  setLanguageProperty,
} from 'helper/AssignObjectKeysHelper';
import { runInAction } from 'mobx';
import Filter from 'components/Filter/Filter';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import { calculateContrast } from 'helper/Color';
import { UsersStore } from 'stores/private/UsersStore';
import Swal from 'sweetalert2';

const initalContent = `<style type="text/css">
* {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 1;
    margin: 0px;
    padding: 0px;
}

body {
    margin: 0;
    padding: 0;
    background-color: #f6f7fb;
}

.body {
    background-color: #f6f7fb;
    width: 100%;
}

.container {
    margin: 0 auto !important;
    max-width: 600px;
    padding: 0;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 600px;
}

.gleapref {
    font-size: 12px;
    font-weight: normal;
    margin-top: 20px;
    text-align: center;
    color: rgb(89, 97, 125);
}

.unsubscribe {
    font-size: 12px;
    font-weight: normal;
    margin-top: 20px;
    text-align: center;
    color: rgb(89, 97, 125);
}

.unsubscribe a {
    font-size: 12px;
    color: rgb(89, 97, 125);
    text-decoration: underline;
}

.main-container {
    font-size: 16px;
    line-height: 22px;
    color: rgb(89, 97, 125);
    border-top: 3px solid {{color}};
    font-family: inherit;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 16px;
    padding-top: 16px;
    background-color: rgb(255, 255, 255);
    margin-top: 30px;
    margin-bottom: 0px;
    text-align: inherit;
}

.main-container p {
    font-size: 16px;
    line-height: 22px;
    color: rgb(89, 97, 125);
    margin-bottom: 14px;
    margin-top: 14px;
}

.main-container a {
    font-size: 16px;
    color: {{color}};
    text-decoration: underline;
}

.main-container img {
    max-width: 100%;
    height: auto;
}

.linktype-button {
    width: auto;
    border-radius: 8px;
    border: unset;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    white-space: nowrap;
    background-color: #000;
    color: #fff;
    text-decoration: none !important;
    background-color: {{color}} !important;
    color: {{contrastColor}} !important;
    box-shadow: rgba(31, 35, 40, 0.1) 0px 1px 8px 0px;
    margin-bottom: 10px;
    padding: 10px 20px;
    display: table-cell !important;
    white-space: inherit;
    line-height: 25px;
}

.user-profile-container {
    color: rgb(89, 97, 125);
    font-size: 14px;
    text-align: left;
    padding-top: 20px !important;
    padding-left: 30px !important;
    padding-bottom: 50px !important;
    height: 38px;
}

.user-profile-image {
    width: 38px;
    height: 38px;
    object-fit: cover;
    border-radius: 38px;
    background-color: #eee;
    float: left;
}

.user-profile-text {
    font-size: 15px;
    line-height: 38px  !important;
    padding-left: 12px !important;
}
</style>

<table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body">
<tr>
    <td>&nbsp;</td>
    <td class="container">
        <table width="100%" height="100%" cellpadding="0" cellspacing="0" style="padding-top: 30px; padding-bottom: 30px;">
            <tr>
                <td align="center" valign="top">
                    <table width="600" cellpadding="0" cellspacing="0">
                        <tr>
                            <td>
                                <div class="main-container">
                                    {{{htmlContent}}}
                                </div>
                            </td>
                        </tr>
                        {{#user}}
                        <tr>
                            <td>
                                <div class="user-profile-container">
                                    <img src="{{profileImageUrl}}" class="user-profile-image" width="38" height="38" />
                                    <span class="user-profile-text">
                                        <b>{{name}}</b> {{#projectName}}from {{.}}{{/projectName}}
                                    </span>
                                </div>
                            </td>
                        </tr>
                        {{/user}}
                        <tr>
                            <td>
                                    <div class="unsubscribe">
                                        <a href="{{unsubscribeLink}}">{{unsubscribeText}}</a>
                                    </div>
                                    <div class="gleapref">
                                        GLEAPID:{{bugRef}}
                                    </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <br />
                                <br />
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
    </td>
    <td>&nbsp;</td>
</tr>
</table>`;

export const getLayoutSettings = (breakpoint: string): { width: any } => {
  switch (breakpoint) {
    case 'xs':
      return { width: 400 };
    case 'md':
      return { width: 992 };
    case 'lg':
      return { width: '100%' };
    default:
      return { width: '100%' };
  }
};

interface IProjectEmailEditorProps {
  projectStore?: ProjectStore;
  usersStore?: UsersStore;
}

const ProjectEmailEditor = ({
  projectStore,
  usersStore,
}: IProjectEmailEditorProps) => {
  const { projectId, templateId } = useParams();
  const navigate = useNavigate();

  const [htmlEditorIsActive, setHtmlEditorIsActive] = useState(false);
  const [currentBreakpoint, setCurrentBreakpoint] = useState('lg');
  const [isLoading, setIsLoading] = useState(false);

  const currentEmailTemplate: any = projectStore?.currentEmailTemplate;

  const currentProject = projectStore?.currentProject;
  const currentLang = projectStore?.currentLanguage ?? 'en';
  const availableLanguages = languages.filter((language) =>
    currentProject?.localizations.find(
      (item) => item.language === language.code,
    ),
  );

  const currentLanguage = availableLanguages.find(
    (item) => item.code === currentLang,
  );

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    if (templateId === 'new') {
      let newEmailTemplate: any = {};

      runInAction(() => {
        newEmailTemplate.title = 'New email template';
        setLanguageProperty(
          newEmailTemplate,
          'htmlContent',
          currentLanguage?.code,
          initalContent,
        );

        runInAction(() => {
          projectStore!.currentEmailTemplate = newEmailTemplate;
        });
      });
    } else {
      projectStore?.getAndSetCurrentProjectEmailTemplate(templateId!);
    }
  }, [templateId, currentProject]);

  const saveEmailTemplate = async () => {
    setIsLoading(true);

    let isValid = true;
    const variablesThatMustBeIncluded = ['htmlContent', 'unsubscribeLink'];
    const localized = currentEmailTemplate?.htmlContent?.localized;
    let keys = Object.keys(localized);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      const templateString = localized[key];
      if (templateString && templateString.length > 0) {
        for (let j = 0; j < variablesThatMustBeIncluded.length; j++) {
          const variable = variablesThatMustBeIncluded[j];
          if (!templateString.includes(`{{${variable}}}`)) {
            isValid = false;
            break;
          }
        }
      }
    }

    if (!isValid) {
      setIsLoading(false);

      Swal.fire({
        title: 'Validation error',
        text: 'Your template must contain the variables {{{htmlContent}}} and {{unsubscribeLink}} in all languages with content set.',
        icon: 'error',
        confirmButtonText: 'Ok',
      });

      return;
    }

    if (templateId === 'new') {
      const createdEmailTemplate = await projectStore?.createEmailTemplate(
        currentEmailTemplate,
      );

      if (createdEmailTemplate?._id) {
        navigate(
          `/projects/${projectId}/settings/email-templates/${createdEmailTemplate?._id}`,
        );
      }
    } else {
      await projectStore?.updateEmailTemplate(
        templateId!,
        currentEmailTemplate,
      );
    }

    setIsLoading(false);
  };

  const buildBreakpointItem = (size: string, icon: string): JSX.Element => {
    const breakpointItemClassName = classNames({
      'breakpoint-item': true,
      'breakpoint-item--active': currentBreakpoint === size,
    });

    return (
      <div
        className={breakpointItemClassName}
        onClick={() => {
          setCurrentBreakpoint(size);
        }}
      >
        <i className={`fa-regular ${icon}`} />
      </div>
    );
  };

  const buildTopToolBar = (): JSX.Element => {
    return (
      <Row
        className="break-point-container"
        justifyContent="center"
        alignItems="center"
      >
        {buildBreakpointItem('lg', 'fa-laptop')}
        {buildBreakpointItem('md', 'fa-tablet')}
        {buildBreakpointItem('xs', 'fa-mobile-notch')}
      </Row>
    );
  };

  const buildHTMLPreview = () => {
    const project = projectStore?.currentProject;

    const htmlContent = getLanguageProperty(
      currentEmailTemplate,
      'htmlContent',
      currentLanguage?.code,
    );

    const contrastColor = calculateContrast(project?.flowConfig?.color);

    let htmlContentWithVariables = htmlContent;

    try {
      htmlContentWithVariables = Handlebars.compile(htmlContent)({
        user: {
          profileImageUrl: usersStore?.currentUser?.profileImageUrl ?? '',
          name: usersStore?.currentUser?.firstName ?? 'John',
          projectName: project?.name ?? 'ACME Inc.',
        },
        title: '{{title}}',
        headerLogo: project?.emailTemplateLogo,
        color: project?.flowConfig?.color,
        contrastColor: contrastColor,
        unsubscribeLink: '#',
        unsubscribeText: 'Unsubscribe from these emails',
        htmlContent: `{{htmlContent}}`,
        bugRef: '#{{bugRef}}',
        conversationHistoryLayoutAlign: project?.emailHistory
          ? 'left'
          : 'center',
      });
    } catch (_) {}

    return (
      <PageContent className="html-preview-wrapper" hasTitle>
        {buildTopToolBar()}
        <div className="html-preview-container">
          <iframe
            title="html-email-preview"
            className="html-preview-container-iframe"
            srcDoc={`<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
            <html xmlns="http://www.w3.org/1999/xhtml">
            <head>
              <meta name="viewport" content="width=device-width, initial-scale=1.0" />
              <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
              <title></title>
              <style type="text/css">
              * {
                font-family: sans-serif;
              }
              .linktype-button {
                display: inline-block;
                line-height: 42px;
                width: auto;
                padding: 0px 20px;
                border-radius: 8px;
                border: unset;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                text-align: center;
                cursor: pointer;
                justify-content: center;
                align-items: center;
                display: inline-flex;
                white-space: nowrap;
                background-color: #485bff;
                color: #000;
                text-decoration: none;
              }
              img {
                max-width: 100%;
                width: 100%;
                height: auto;
                object-fit: cover;
              }
              </style>
              <style>
                  @media screen and (max-width: 600px) {
                      table[width="600"] {
                          width: 100% !important;
                      }
              
                      .container {
                          padding: 0 !important;
                          padding-top: 8px !important;
                          width: 100% !important;
                      }
                  }
              </style>
            </head>
            <body>
            ${htmlContentWithVariables}
            </body>
            </html>`}
            style={{ maxWidth: getLayoutSettings(currentBreakpoint).width }}
          />
        </div>
      </PageContent>
    );
  };

  return (
    <PageContainer>
      <PageHeadLine
        title={currentEmailTemplate?.title}
        onActionBack={() => {
          navigate(`/projects/${projectId}/settings/email-templates`);
        }}
        isEditable
        onChangeTitle={(newTitle) => {
          runInAction(() => {
            currentEmailTemplate.title = newTitle;
          });
        }}
      >
        <Row
          className="email-actions-container"
          justifyContent="flex-end"
          alignItems="center"
        >
          <SelectDropDown
            labelPropertyName="name"
            valuePropertyName="code"
            selectedItem={currentLanguage}
            items={availableLanguages}
            onChange={(option) => {
              runInAction(() => {
                projectStore!.currentLanguage = option.code;
              });
            }}
          />
          <Filter
            className="ml-30 hide-on-mobile"
            onValueChange={(value) => {
              setHtmlEditorIsActive(value === 'true');
            }}
            value={htmlEditorIsActive.toString()}
            options={[
              { name: 'Preview', value: 'false' },
              { name: 'Source', value: 'true' },
            ]}
          />
          <PrimaryButton
            label="Save"
            className="ml-30"
            onClick={saveEmailTemplate}
            isLoading={isLoading}
          />
        </Row>
      </PageHeadLine>
      {htmlEditorIsActive && (
        <Editor
          height="100vh"
          defaultLanguage="html"
          value={getLanguageProperty(
            currentEmailTemplate,
            'htmlContent',
            currentLanguage?.code,
          )}
          onChange={(html: any) => {
            runInAction(() => {
              setLanguageProperty(
                currentEmailTemplate!,
                'htmlContent',
                currentLanguage?.code,
                html,
              );
            });
          }}
        />
      )}
      {!htmlEditorIsActive && buildHTMLPreview()}
    </PageContainer>
  );
};

export default inject(
  'projectStore',
  'usersStore',
)(observer(ProjectEmailEditor));
