import './MobileSharedBoard.scss';
import 'react-virtualized/styles.css';
import { inject, observer } from 'mobx-react';
import { Bug } from 'models/Bug';
import FeatureRequestCard, {
    FeatureRequestCardSkeleton,
} from 'components/FeatureRequestCard/FeatureRequestCard';
import { useParams } from 'react-router';
import { SharedBoardStorePublic } from 'stores/public/SharedBoardStorePublic';
import { useEffect, useState } from 'react';
import MobileSharedBoardHeader from './MobileSharedBoardHeader';

interface SharedBoardProps {
    sharedBoardStorePublic?: SharedBoardStorePublic;
    items?: Bug[];
}

const MobileSharedBoard = ({ sharedBoardStorePublic }: SharedBoardProps) => {
    const { boardShareToken } = useParams();
    let currentLanes = sharedBoardStorePublic!.getLanesForFeedbackType().map((lane) => {
        if (lane && lane.key) {

            let laneTitle = lane.title
            if (lane.key === 'OPEN') {
                laneTitle = 'Under review';
            }
            if (lane.key === 'DONE') {
                laneTitle = sharedBoardStorePublic?.translateText('released');
            }
            const featureRequestState = sharedBoardStorePublic
                ?.getFeatureRequestStates(false)
                .find((x) => {
                    return x.key === lane.key;
                });
            const color = featureRequestState?.color;

            return {
                key: lane.key,
                title: laneTitle,
                color: color,
            };
        }
        return null;
    });
    currentLanes = currentLanes.filter((lane) => {
        if (!lane) {
            return false;
        }
        if (
            lane &&
            (lane.key === 'OPEN' || lane.key === 'CLOSED' || lane?.title === "Parked") &&
            sharedBoardStorePublic?.currentFeedbackType?.type === 'FEATURE_REQUEST'
        ) {
            return false;
        }
        return true;
    });
    const [selectedLane, setSelectedLane] = useState(currentLanes[0])
    const [order, setOrder] = useState('VOTES');
    let cards = selectedLane?.key && Array.isArray(sharedBoardStorePublic?.currentTicketsData[selectedLane.key]?.data)
        ? sharedBoardStorePublic?.currentTicketsData[selectedLane.key]?.data.slice() // create a copy of the array
        : [];
    cards = cards.sort((a, b) => {
        if (order === 'VOTES') {
            return b.upvotesCount - a.upvotesCount;
        } else if (order === 'RECENT') {
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        } else if (order === 'RANDOM') {
            return Math.random() - 0.5;
        }
        return 0;
    });

    useEffect(() => {
        if (!selectedLane) {
            setSelectedLane(currentLanes[0]);
        }
    }, [currentLanes])


    const handleScroll = (e) => {
        const bottom =
            Math.abs(
                e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop,
            ) < 1;
        if (bottom) {
            sharedBoardStorePublic?.fetchAndSetTicketsDataForLane({
                laneKey: selectedLane.key,
                loadMore: true,
            });
        }
    };

    return (
        <div
            className="shared-board"
            onScroll={(e) => {
                handleScroll(e);
            }}
        >
            <div
                className='mobile-roadmap-container'

            >
                <MobileSharedBoardHeader
                    currentLanes={currentLanes}
                    selectedLane={selectedLane}
                    setSelectedLane={setSelectedLane}
                    order={order}
                    onOrderChange={setOrder}
                />
                <div className="roadmap-cards-list"
                >
                    {cards.map((bug) => {
                        if (bug.isLoading) {
                            return (
                                <FeatureRequestCardSkeleton height={130} key={bug.id} />
                            );
                        }

                        return (
                            <FeatureRequestCard
                                key={bug.id}
                                isRoadmap={true}
                                showTag={false}
                                isShared={true}
                                featureRequest={bug}
                                boardShareToken={boardShareToken}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default inject('sharedBoardStorePublic')(observer(MobileSharedBoard));
