import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import { setProperty } from 'helper/AssignObjectKeysHelper';
import useNotificationPermission from 'helper/UseNotificationPermission';
import { inject, observer } from 'mobx-react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { UsersStore } from 'stores/private/UsersStore';

const intervalValues = [
  {
    id: 'hourly',
    name: 'Hourly',
  },
  {
    id: 'daily',
    name: 'Daily',
  },
  {
    id: 'never',
    name: 'Never',
  },
];

interface ProfileNotificationSettingsProps {
  usersStore?: UsersStore;
}

const ProfileNotificationSettings = ({
  usersStore,
}: ProfileNotificationSettingsProps) => {
  const user = usersStore?.currentUser;
  const permission = useNotificationPermission();
  const [isLoading, setisLoading] = useState(false);

  const updateUser = async () => {
    setisLoading(true);

    const toUpdateUser: any = {
      notificationSettings: user?.notificationSettings,
    };

    try {
      await usersStore!.updateUser(user!.id, toUpdateUser);
    } catch (err) {
      toast.error('Profile update failed');
    }

    setisLoading(false);
  };

  if (!user) {
    return <></>;
  }

  const renderPushNotificationSettings = () => {

    return (<>
      <div className="input-label">Notifications</div>
      <div className='text mb-40'>
        {!permission ? <>
          Your browser is not supported yet.
        </> : <>
          Enable notifications to be informed when new tickets are created, assigned to you, or when comments and replies are posted.<br />
          {permission === 'granted' ? <div>
            <div className='text mt-20'>✅ Notifications are enabled</div>
          </div> : <PrimaryButton className="mt-10" label='Enable notifications' onClick={() => {
            usersStore!.registerFcmToken();
          }} />}
        </>}
      </div>
    </>);
  }

  return (
    <PageContainer>
      <PageHeadLine title="My notifications" />
      <PageContent hasTitle isSmallBoxed>
        {renderPushNotificationSettings()}
        <PrimaryButton
          label="Save"
          isLoading={isLoading}
          onClick={updateUser}
        />
      </PageContent>
    </PageContainer>
  );
};

export default inject('usersStore')(observer(ProfileNotificationSettings));
