/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { inject } from 'mobx-react';
import 'rc-slider/assets/index.css';
import { BugStore } from 'stores/private/BugStore';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import Details from '../Details/Details';

interface MobileActionsProps {
    bugStore?: BugStore;
    modalStore?: ModalStore;
}

const MobileActions = ({
    bugStore,
    modalStore,
}: MobileActionsProps) => {
    const bug = bugStore!.currentBug;
    const customData = modalStore?.getCustomData(MODALTYPE.MOBILE_BUG_ACTIONS) ?? {};
    const shared = customData?.shared ?? true;
    const isInbox = bug && bug.type === "INQUIRY";

    return (<Details showComments={false} shared={shared} isInbox={isInbox} />);
};

export default inject('bugStore', 'modalStore')(MobileActions);
