import { conditionIsValid } from 'components/ConditionTag/ConditionTag';
import FeedbackActionQuestionEditor from 'components/FeedbackActionQuestionEditor/FeedbackActionQuestionEditor';
import Filter from 'components/Filter/Filter';
import LinkButton from 'components/LinkButton/LinkButton';
import Loading from 'components/Loading/Loading';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import Gleap from 'gleap';
import { debounce } from 'lodash';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { OutboundStore } from 'stores/private/OutboundStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { SidenavStore } from 'stores/private/SidenavStore';
import Swal from 'sweetalert2';
import './ProjectOutboundConfiguration.scss';
import BannerConfiguration from './components/BannerConfiguration/BannerConfiguration';
import ChecklistConfiguration from './components/ChecklistConfiguration/ChecklistConfiguration';
import EmailConfiguration from './components/EmailConfiguration/EmailConfiguration';
import MessageConfiguration from './components/MessageConfiguration/MessageConfiguration';
import NewsConfiguration from './components/NewsConfiguration/NewsConfiguration';
import OptionsConfiguration from './components/OptionsConfiguration/OptionsConfiguration';
import OutboundTriggerConfiguration from './components/OutboundTriggerConfiguration/OutboundTriggerConfiguration';
import ProductTourConfiguration from './components/ProductTourConfiguration/ProductTourConfiguration';
import PushNotificationConfiguration from './components/PushNotificationConfiguration/PushNotificationConfiguration';
import TooltipsConfiguration from './components/TooltipsConfiguration/TooltipsConfiguration';

interface ProjectOutboundConfigurationProps {
  projectStore?: ProjectStore;
  outboundStore?: OutboundStore;
  sidenavStore?: SidenavStore;
  modalStore?: ModalStore;
}

const ProjectOutboundConfiguration = ({
  projectStore,
  outboundStore,
  sidenavStore,
  modalStore,
}: ProjectOutboundConfigurationProps) => {
  const { projectId, outboundId } = useParams();
  const navigate = useNavigate();

  const project = projectStore?.currentProject;
  const outboundRule = outboundStore?.outboundRule;
  const [currentPage, setCurrentPage] = useState('questions');
  const [toggle, setToggle] = useState(false);

  const debouncedQueryFixedRecievers = useCallback(
    debounce(
      (_outboundId, _conditions, _targetAudience) =>
        outboundStore!.queryFixedRecievers(
          _outboundId,
          _conditions,
          _targetAudience,
        ),
      1000,
    ),
    [],
  );

  useEffect(() => {
    if (outboundId && outboundRule?.frequencyType === 'fixed') {
      outboundStore!.loadingOutboundRecipients(true);
      debouncedQueryFixedRecievers(
        outboundId,
        outboundRule?.conditions,
        outboundRule?.targetAudience,
      );
    }
  }, [
    outboundRule?.frequencyType,
    outboundRule?.conditions,
    outboundRule?.targetAudience,
  ]);

  useEffect(() => {
    runInAction(() => {
      sidenavStore!.sidenavHidden = true;
      sidenavStore!.mainSidenavHidden = true;
    });

    return () => {
      runInAction(() => {
        sidenavStore!.sidenavHidden = false;
        sidenavStore!.mainSidenavHidden = false;
      });
    };
  }, []);

  useEffect(() => {
    projectStore!.loadProjectById(projectId!);
  }, []);

  useEffect(() => {
    if (projectStore?.currentProject && outboundId) {
      projectStore.getStreamedEventKeys();
      outboundStore!.loadOutboundRule(outboundId);
      outboundStore!.loadOutboundRuleResponses({ id: outboundId });
    }
  }, [projectStore?.currentProject]);

  useEffect(() => {
    const isHomePage =
      currentPage === 'questions' ||
      currentPage === 'message' ||
      currentPage === 'email' ||
      currentPage === 'banner' ||
      currentPage === 'push' ||
      currentPage === 'tours' ||
      currentPage === 'tooltips' ||
      currentPage === 'news';

    if (isHomePage) {
      if (outboundRule && outboundRule.type === 'MESSAGE') {
        setCurrentPage('message');
      }
      if (outboundRule && outboundRule.type === 'EMAIL') {
        setCurrentPage('email');
      }
      if (outboundRule && outboundRule.type === 'BANNER') {
        setCurrentPage('banner');
      }
      if (outboundRule && outboundRule.type === 'SURVEY') {
        setCurrentPage('questions');
      }
      if (outboundRule && outboundRule.type === 'NEWS') {
        setCurrentPage('news');
      }
      if (outboundRule && outboundRule.type === 'CHECKLIST') {
        setCurrentPage('checklist');
      }
      if (outboundRule && outboundRule.type === 'PUSH') {
        setCurrentPage('push');
      }
      if (outboundRule && outboundRule.type === 'TOUR') {
        setCurrentPage('tours');
      }
      if (outboundRule && outboundRule.type === 'TOOLTIP') {
        setCurrentPage('tooltips');
      }
    }

    const query = new URLSearchParams(window.location.search);
    const activeTab = query.get('tab');
    if (activeTab) {
      if (activeTab === 'share') {
        setCurrentPage('trigger');
      } else {
        setCurrentPage(activeTab);
      }
    }
  }, [outboundRule?.id]);

  if (
    !outboundStore?.loadingOutboundRule &&
    project &&
    outboundRule &&
    project?.projectActions &&
    outboundRule.type === 'SURVEY' &&
    !(
      outboundRule.actionType &&
      project?.projectActions[outboundRule.actionType]
    )
  ) {
    return (
      <PageContainer>
        <PageHeadLine title="Outbound" />
        <PageContent hasTitle isCentered>
          <div className="input-label">Misconfigured survey</div>
          <div className="text">
            This survey is misconfigured and must be deleted. If this happened
            by accident,{' '}
            <a
              href="#"
              onClick={() => {
                Gleap.startFeedbackFlow('contact', true);
              }}
            >
              please contact
            </a>{' '}
            us.
          </div>
          <LinkButton
            className="danger mt-20"
            label="Delete"
            onClick={async () => {
              Swal.fire({
                text: 'Do you really want to delete this outreach and all associated replies?',
                showConfirmButton: false,
                showCancelButton: true,
                showDenyButton: true,
                denyButtonText: `Delete`,
                cancelButtonText: `Cancel`,
              }).then(async (result) => {
                if (result.isDenied) {
                  await outboundStore!.removeOutboundRule(outboundId);
                  navigate(
                    `/projects/${projectStore?.currentProject!.id}/outbound`,
                  );
                  toast.success(`Outreach "${outboundRule.name}" deleted`);
                }
              });
            }}
          />
        </PageContent>
      </PageContainer>
    );
  }

  if (
    outboundStore?.loadingOutboundRule ||
    !project ||
    !outboundRule ||
    !project?.projectActions ||
    (outboundRule.type === 'SURVEY' &&
      !(
        outboundRule.actionType &&
        project?.projectActions[outboundRule.actionType]
      ))
  ) {
    return (
      <PageContainer>
        <PageHeadLine
          title="..."
          onActionBack={() => {
            navigate(`/projects/${projectId}/outbound`);
          }}
        />
        <PageContent hasTitle isCentered>
          <Loading />
        </PageContent>
      </PageContainer>
    );
  }

  const getConditions = () => {
    if (!outboundRule.conditions || outboundRule.conditions.length === 0) {
      if (outboundRule?.eventTrigger && outboundRule?.eventTrigger.length > 0) {
        return [
          {
            event: outboundRule.eventTrigger,
            type: 'firstoccured',
            dateOperator: 'relative',
            matchOperator: 'greaterthan',
            data: outboundRule.eventTriggerDelay || 0,
          },
        ] as any[];
      } else {
        return [];
      }
    }
    return outboundRule.conditions;
  };

  const preSaveCheck = () => {
    // Update old conditions.
    outboundRule.conditions = getConditions();

    if (outboundStore?.loadingFixedRecievers) {
      Swal.fire(
        'Preview not loaded yet',
        'Please wait for the preview to be loaded.',
        'info',
      );

      return false;
    }

    if (
      outboundRule.conditions &&
      outboundRule.conditions.length > 0 &&
      !outboundRule.conditions
        .map((condition) => conditionIsValid(condition))
        .every((condition) => condition === true)
    ) {
      Swal.fire(
        'Invalid audience rules specified',
        'Please fix your audience rules.',
        'info',
      );

      return false;
    }

    var triggerNeeded = true;
    if (
      outboundRule.type === 'NEWS' ||
      outboundRule.type === 'CHECKLIST' ||
      outboundRule.frequencyType === 'fixed'
    ) {
      triggerNeeded = false;
    }
    if (
      outboundRule.type !== 'SURVEY' &&
      outboundRule.type !== 'TOOLTIP' &&
      triggerNeeded &&
      (!outboundRule.trigger || !conditionIsValid(outboundRule.trigger))
    ) {
      Swal.fire(
        'Invalid trigger event specified',
        'Please fix your trigger event.',
        'info',
      );

      return false;
    }

    if (outboundRule.type === 'MESSAGE') {
      if (!outboundRule.sender) {
        Swal.fire(
          'No sender specified',
          'You must specify a sender for your message.',
          'info',
        );
        return false;
      }
    }
    if (outboundRule.type === 'EMAIL') {
      if (!outboundRule.sender) {
        Swal.fire(
          'No sender specified',
          'You must specify a sender for your message.',
          'info',
        );
        return false;
      }

      if (!outboundRule.emailTemplate) {
        Swal.fire(
          'No template specified',
          'You must specify a template for your email.',
          'info',
        );
        return false;
      }

      if (
        !outboundRule.subject ||
        !outboundRule.subject.localized ||
        !outboundRule.subject.localized.en ||
        outboundRule.subject.localized.en.length === 0
      ) {
        Swal.fire(
          'No subject specified',
          'You must specify a subject for your message.',
          'info',
        );
        return false;
      }
    }

    return true;
  };

  const getMenuOptions = () => {
    if (outboundRule && outboundRule.type === 'MESSAGE') {
      return [
        { name: 'Message', value: 'message' },
        { name: 'Trigger', value: 'trigger' },
      ];
    }
    if (outboundRule && outboundRule.type === 'SURVEY') {
      return [
        { name: 'Questions', value: 'questions' },
        { name: 'Options', value: 'options' },
        { name: 'Share', value: 'trigger' },
      ];
    }
    if (outboundRule && outboundRule.type === 'EMAIL') {
      return [
        { name: 'Email', value: 'email' },
        { name: 'Trigger', value: 'trigger' },
      ];
    }
    if (outboundRule && outboundRule.type === 'BANNER') {
      return [
        { name: 'Banner', value: 'banner' },
        { name: 'Trigger', value: 'trigger' },
      ];
    }

    if (outboundRule && outboundRule.type === 'PUSH') {
      return [
        { name: 'Push', value: 'push' },
        { name: 'Trigger', value: 'trigger' },
      ];
    }

    if (outboundRule && outboundRule.type === 'NEWS') {
      return [
        { name: 'News', value: 'news' },
        { name: 'Audience', value: 'trigger' },
      ];
    }

    if (outboundRule && outboundRule.type === 'CHECKLIST') {
      return [
        { name: 'Checklist', value: 'checklist' },
        { name: 'Audience', value: 'trigger' },
      ];
    }

    if (outboundRule && outboundRule.type === 'TOUR') {
      return [
        { name: 'Product tour', value: 'tours' },
        { name: 'Trigger', value: 'trigger' },
      ];
    }

    if (outboundRule && outboundRule.type === 'TOOLTIP') {
      return [
        { name: 'Tooltips', value: 'tooltips' },
        { name: 'Audience', value: 'trigger' },
      ];
    }

    return [];
  };

  const setOutboundLive = async (updateProjectActions = false) => {
    if (!preSaveCheck()) {
      return;
    }

    if (outboundRule.frequencyType === 'fixed') {
      const shouldContinue = await Swal.fire({
        text: `Are you sure you want to send this message to ${
          outboundStore?.fixedLimitReached
            ? outboundStore?.fixedRecieversLimitCount
            : outboundStore?.fixedRecieversCount
        } people now?`,
        showCancelButton: true,
        confirmButtonText: `Send now ⚡️`,
        denyButtonText: `Cancel`,
      });
      if (!shouldContinue.isConfirmed) {
        return;
      }
    }

    if (updateProjectActions) {
      await projectStore!.updateProject(
        project!.id,
        {
          projectActions: project?.projectActions,
        },
        false,
      );
    }

    outboundRule.newTrigger = true;
    outboundRule.status = 'live';
    await outboundStore!.updateOutboundRule(outboundId, outboundRule);
    navigate(`/projects/${projectStore?.currentProject!.id}/outbound`);
  };

  const canSetLive = currentPage === 'trigger';

  const buildHeadline = () => {
    return (
      <div className="header-content-right">
        {outboundRule.type === 'EMAIL' && (
          <>
            <LinkButton
              icon="paper-plane-top"
              className="hide-on-mobile"
              onClick={async () => {
                if (!preSaveCheck()) {
                  return;
                }

                outboundRule.newTrigger = true;
                await outboundStore!.updateOutboundRule(
                  outboundId,
                  outboundRule,
                );
                await outboundStore?.loadOutboundRules();

                // Show preview.
                modalStore?.openModal(MODALTYPE.EMAIL_PREVIEW, {
                  outboundId: outboundRule.id,
                });
              }}
              label="Send preview"
            />
          </>
        )}
        {outboundRule.type === 'NEWS' && outboundRule.status === 'live' && (
          <LinkButton
            className="open-new-tab"
            icon="external-link"
            label="Open in new tab"
            onClick={() => {
              const hasCustomDomain =
                projectStore?.currentProject?.customDomain &&
                projectStore?.currentProject?.customDomain.length > 0;
              const shareDefaultURL = `${process.env.REACT_APP_BASEURL}/sharedboard/${projectStore?.currentProject?.apiKey}/news/${outboundRule.id}`;
              const customDomainShareURL = `https://${projectStore?.currentProject?.customDomain}/news/${outboundRule.id}`;
              const shareURL = hasCustomDomain
                ? customDomainShareURL
                : shareDefaultURL;
              window.open(shareURL, '_blank');
            }}
          />
        )}
        <LinkButton
          className="danger ml-10"
          label="Delete"
          onClick={async () => {
            Swal.fire({
              text: 'Do you really want to delete this outreach and all associated replies?',
              showConfirmButton: false,
              showCancelButton: true,
              showDenyButton: true,
              denyButtonText: `Delete`,
              cancelButtonText: `Cancel`,
            }).then(async (result) => {
              if (result.isDenied) {
                await outboundStore!.removeOutboundRule(outboundId);
                navigate(
                  `/projects/${projectStore?.currentProject!.id}/outbound`,
                );
                toast.success(`Outreach "${outboundRule.name}" deleted`);
              }
            });
          }}
        />
        <LinkButton
          className="save-button ml-10"
          onClick={async () => {
            if (!preSaveCheck()) {
              return;
            }

            outboundRule.newTrigger = true;
            if (outboundRule && outboundRule.type === 'SURVEY') {
              // Update the outbound actions.
              if (project?.projectActions) {
                await projectStore!.updateProject(
                  project!.id,
                  {
                    projectActions: project?.projectActions,
                  },
                  false,
                );
              }
            }
            await outboundStore!.updateOutboundRule(outboundId, outboundRule);
            await outboundStore?.loadOutboundRules();
          }}
          label="Save"
        />
        {outboundRule.status === 'live' &&
          outboundRule.frequencyType === 'dynamic' && (
            <PrimaryButton
              className="save-button danger ml-10"
              icon="pause"
              label="Unpublish"
              onClick={async () => {
                outboundRule.status = 'draft';

                await projectStore!.updateProject(
                  project!.id,
                  {
                    projectActions: project?.projectActions,
                  },
                  false,
                );

                await outboundStore!.updateOutboundRule(
                  outboundId,
                  outboundRule,
                );
                navigate(
                  `/projects/${projectStore?.currentProject!.id}/outbound`,
                );
              }}
            />
          )}
        {outboundRule.status === 'draft' && (
          <PrimaryButton
            className={`save-button ml-10 ${canSetLive ? 'live' : ''}`}
            onClick={async () => {
              if (outboundRule.type === 'SURVEY') {
                if (currentPage === 'questions') {
                  setCurrentPage('options');
                } else if (currentPage === 'options') {
                  setCurrentPage('trigger');
                } else {
                  setOutboundLive(true);
                }
              }

              if (
                currentPage === 'message' ||
                currentPage === 'email' ||
                currentPage === 'banner' ||
                currentPage === 'push' ||
                currentPage === 'news' ||
                currentPage === 'checklist' ||
                currentPage === 'tours' ||
                currentPage === 'tooltips'
              ) {
                setCurrentPage('trigger');
              } else {
                setOutboundLive();
              }
            }}
            icon={canSetLive ? 'play' : undefined}
            label={
              canSetLive
                ? outboundRule.frequencyType === 'fixed'
                  ? 'Send now'
                  : 'Launch'
                : 'Continue'
            }
          />
        )}
      </div>
    );
  };

  return (
    <PageContainer className="page-centered-main-tabs" key={outboundId}>
      <PageHeadLine
        title={outboundRule.name}
        isEditable
        onChangeTitle={(val) => {
          if (
            project!.projectActions &&
            project!.projectActions[outboundRule.actionType]
          ) {
            project!.projectActions[outboundRule.actionType].title = val;
          }
          outboundRule.name = val;
          setToggle(!toggle);
        }}
        onActionBack={() => {
          if (outboundRule.type === 'NEWS') {
            navigate(`/projects/${projectId}/outbound`);
          } else if (outboundRule.status === 'live') {
            navigate(`/projects/${projectId}/outbound/${outboundId}`);
          } else {
            navigate(`/projects/${projectId}/outbound`);
          }
        }}
      >
        <div className="centered-main-tabs">
          <div className="main-tabs">
            <Filter
              onValueChange={(value) => {
                setCurrentPage(value);
              }}
              value={currentPage}
              options={getMenuOptions()}
            />
          </div>
        </div>
        {buildHeadline()}
      </PageHeadLine>
      <PageContent hasTitle className="page-content--fulltabs">
        {currentPage === 'email' && (
          <div className="outbound-configuration-container outbound-configuration-container--large">
            <EmailConfiguration outboundRule={outboundRule} />
          </div>
        )}
        {currentPage === 'tooltips' && (
          <div className="outbound-configuration-container outbound-configuration-container--large">
            <TooltipsConfiguration outboundRule={outboundRule} />
          </div>
        )}
        {currentPage === 'tours' && (
          <div className="outbound-configuration-container outbound-configuration-container--large">
            <ProductTourConfiguration outboundRule={outboundRule} />
          </div>
        )}
        {currentPage === 'banner' && (
          <div className="outbound-configuration-container outbound-configuration-container--large">
            <BannerConfiguration outboundRule={outboundRule} />
          </div>
        )}
        {currentPage === 'message' && (
          <div className="outbound-configuration-container outbound-configuration-container--large">
            <MessageConfiguration outboundRule={outboundRule} />
          </div>
        )}
        {currentPage === 'push' && (
          <div className="outbound-configuration-container outbound-configuration-container--large">
            <PushNotificationConfiguration outboundRule={outboundRule} />
          </div>
        )}
        {currentPage === 'news' && (
          <div className="outbound-configuration-container outbound-configuration-container--large">
            <NewsConfiguration outboundRule={outboundRule} />
          </div>
        )}
        {currentPage === 'checklist' && (
          <div className="outbound-configuration-container outbound-configuration-container--full">
            <ChecklistConfiguration outboundRule={outboundRule} />
          </div>
        )}
        {currentPage === 'questions' && (
          <FeedbackActionQuestionEditor
            feedbackAction={project?.projectActions[outboundRule.actionType]}
            isSurvey
          />
        )}
        {currentPage === 'options' && (
          <div className="outbound-configuration-container">
            <OptionsConfiguration
              outboundRule={outboundRule}
              feedbackAction={project?.projectActions[outboundRule.actionType]}
            />
          </div>
        )}
        {(currentPage === 'trigger' || currentPage === 'trigger') && (
          <div className="outbound-configuration-container">
            <OutboundTriggerConfiguration outboundRule={outboundRule} />
          </div>
        )}
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'projectStore',
  'outboundStore',
  'sidenavStore',
  'modalStore',
)(observer(ProjectOutboundConfiguration));
