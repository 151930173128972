import { ReactComponent as BotIcon } from 'assets/icons/kaiiconhighlight.svg';
import classNames from 'classnames';
import BoardSearchFilter from 'components/BoardSearchFilter/BoardSearchFilter';
import BugDetail from 'components/BugDetail/BugDetail';
import TicketDetailScreen from 'components/BugDetail/TicketDetailScreen';
import IconButton from 'components/IconButton/IconButton';
import InboxItem, { InboxItemSkeleton } from 'components/InboxItem/InboxItem';
import InboxTicketStatusSelection from 'components/InboxTicketStatusSelection/InboxTicketStatusSelection';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import ProjectArchive from 'components/ProjectArchive/ProjectArchive';
import ResizableContainer from 'components/ResizableContainer/ResizableContainer';
import SimpleSidenavElement from 'components/Sidenav/SimpleSidenavElement/SimpleSidenavElement';
import SidenavContainer from 'components/SidenavContainer/SidenavContainer';
import SubSidenav from 'components/SubSidenav/SubSidenav';
import { Title } from 'components/Title/Title';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutlet,
  useParams,
} from 'react-router';
import { toast } from 'react-toastify';
import { AutoSizer, List } from 'react-virtualized';
import 'react-virtualized/styles.css';
import { BugStore } from 'stores/private/BugStore';
import { MODALSIZE, MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { UsersStore } from 'stores/private/UsersStore';
import Swal from 'sweetalert2';
import './ProjectInbox.scss';
import UpgradePlan from 'components/UpgradePlan/UpgradePlan';
import TicketViewModal from 'components/Modals/TicketViewModal/TicketViewModal';

export const getInboxBody = () => {
  if (window.innerWidth > 1100) {
    return <BugDetail shared={false} />;
  }

  return <TicketDetailScreen />;
};

const inboxFilterStates = [
  { name: 'My inbox', icon: 'user', value: 'personalinbox' },
  { name: 'All', icon: 'user-group', value: 'all' },
  { name: 'Unassigned', icon: 'user-slash', value: 'unassigned' },
  { name: 'Mentions', icon: 'at', value: 'mentions' },
  { name: 'Bots', icon: 'bot', value: 'kai' },
];

const staticFilterStates = [
  { name: 'Open', icon: 'inbox', value: 'OPEN' },
  { name: 'Snoozed', icon: 'moon', value: 'SNOOZED' },
  { name: 'Closed', icon: 'check', value: 'DONE' },
];

interface ProjectInboxProps {
  projectStore?: ProjectStore;
  usersStore?: UsersStore;
  bugStore?: BugStore;
  modalStore?: ModalStore;
  filterBugState?: string;
  filterBugAssignedTo?: string;
}

const ProjectInbox = ({
  projectStore,
  usersStore,
  bugStore,
  modalStore,
}: ProjectInboxProps) => {
  const navigate = useNavigate();
  const outlet = useOutlet();

  const [showArchive, setShowArchive] = useState(false);
  const [currentInbox, setCurrentInbox] = useState({
    type: 'INBOX',
    value: 'all' as any,
  });
  const [filterBugState, setFilterBugState] = useState('OPEN');
  const [subSidenavCollapsed, setSubSidenavCollapsed] = useState(() => {
    const storedValue = localStorage.getItem('subSidenavCollapsed');
    return storedValue ? JSON.parse(storedValue) : false;
  });
  const [rerenderChatPreview, setRerenderChatPreview] = useState(false);
  const { shareToken, projectId } = useParams();
  const location = useLocation();

  const feedbackTypes = projectStore?.currentProject?.projectTypes;
  const currentFeedbackType = feedbackTypes?.find((feedbackType) => {
    return feedbackType.type === 'INQUIRY';
  });

  const customViews =
    projectStore?.getTicketViewsForFeedbackType('INQUIRY') ?? [];

  useEffect(() => {
    modalStore?.setSideNavCollapsed(subSidenavCollapsed);
  }, [subSidenavCollapsed]);

  useEffect(() => {
    const localStorageKey = `project-${projectId}-${currentFeedbackType?.type}-status`;
    const storedData = localStorage.getItem(localStorageKey);

    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setFilterBugState(parsedData.status || 'OPEN');
    } else {
      setFilterBugState('OPEN');
    }
  }, [projectId, currentFeedbackType?.type]);

  const updateSubSidenavCollapsed = (newState) => {
    localStorage.setItem('subSidenavCollapsed', JSON.stringify(newState));
    setSubSidenavCollapsed(newState);
  };

  const handleSubSidenavCollapseToggle = () => {
    updateSubSidenavCollapsed(!subSidenavCollapsed);
  };

  const projectTeams = projectStore?.currentProjectTeams ?? [];

  const preparedColumns =
    currentFeedbackType?.options?.possibleLanes
      ?.filter((lane) => {
        if (
          lane.key === 'OPEN' ||
          lane.key === 'DONE' ||
          lane.key === 'SNOOZED'
        ) {
          return false;
        }
        return true;
      })
      .map((lane) => {
        return {
          name: lane.title,
          value: lane.key,
          icon: lane.icon,
        };
      }) ?? [];

  // Make a copy of staticFilterStates
  const filterStates = [...staticFilterStates];

  // Find the index of the 'Snoozed' state
  const snoozedIndex = filterStates.findIndex(
    (state) => state.value === 'SNOOZED',
  );

  // Insert the other feedback types after the 'Snoozed' state
  filterStates.splice(snoozedIndex + 1, 0, ...preparedColumns);

  const initialize = async () => {
    try {
      if (projectId) {
        await projectStore!.loadProjectById(projectId);
        projectStore!.setFeedbackTypeForPath('inquiries');
      }
    } catch (err) {
      toast.error('Could not load project');
      navigate('/dashboard');
    }
  };

  useEffect(() => {
    if (location.pathname.includes('inquiries')) {
      bugStore!.clearCurrentBug(false);
      initialize();
    }
  }, []);

  useEffect(() => {
    runInAction(() => {
      if (currentInbox.value === 'personalinbox') {
        projectStore!.currentTicketDataFilter = {
          processingUser: [usersStore?.currentUser?.id],
        };
      } else if (currentInbox.value === 'kai') {
        projectStore!.currentTicketDataFilter = {
          type: 'BOT',
        };
      } else if (currentInbox.value === 'unassigned') {
        projectStore!.currentTicketDataFilter = {
          processingUser: [null, undefined],
        };
      } else if (currentInbox.value === 'mentions') {
        projectStore!.currentTicketDataFilter = {
          mentions: [usersStore?.currentUser?.id],
        };
      } else if (currentInbox.type === 'TEAM') {
        projectStore!.currentTicketDataFilter = {
          processingTeam: [currentInbox.value],
        };
      } else if (currentInbox.type === 'CUSTOM_VIEW') {
        projectStore!.currentTicketDataFilter = (
          currentInbox.value as any
        ).conditions;
        projectStore!.currentTicketDataSort = (
          currentInbox.value as any
        ).sortBy;
      } else {
        projectStore!.currentTicketDataFilter = {};
      }

      projectStore?.filterTicketsData();
    });
  }, [filterBugState, currentInbox]);

  const getCountForInbox = (inbox, type = 'INBOX') => {
    var checkFunc = function (item) {
      if (item.type === 'INQUIRY') {
        return true;
      }
      return false;
    };

    if (inbox === 'personalinbox') {
      checkFunc = function (item) {
        if (
          item.type === 'INQUIRY' &&
          item.processingUser === usersStore?.currentUser?.id
        ) {
          return true;
        }
        return false;
      };
    } else if (inbox === 'unassigned') {
      checkFunc = function (item) {
        if (
          item.type === 'INQUIRY' &&
          (!item.processingUser || item.processingUser === null)
        ) {
          return true;
        }
        return false;
      };
    } else if (inbox === 'mentions') {
      checkFunc = function (item) {
        if (
          item.type === 'INQUIRY' &&
          item.mentions &&
          item.mentions.includes(usersStore?.currentUser?.id)
        ) {
          return true;
        }
        return false;
      };
    } else if (inbox === 'kai') {
      checkFunc = function (item) {
        return false;
      };
    }

    if (type === 'TEAM') {
      checkFunc = function (item) {
        if (item.type === 'INQUIRY' && item.processingTeam === inbox) {
          return true;
        }
        return false;
      };
    }

    var unreadCount = 0;

    for (const itemKey in projectStore?.unreadItems) {
      if (
        projectStore?.unreadItems[itemKey] &&
        checkFunc(projectStore?.unreadItems[itemKey])
      ) {
        unreadCount++;
        if (unreadCount > 9) {
          return 10;
        }
      }
    }

    return unreadCount;
  };

  useEffect(() => {
    if (shareToken && projectStore?.currentProject) {
      projectStore!.openFeedbackItem({
        shareToken,
        openModal: false,
      });
    }
  }, [shareToken, projectStore?.currentProject]);

  const handleScroll = (e, laneKey) => {
    const bottom = Math.abs(e.scrollHeight - e.clientHeight - e.scrollTop) < 1;
    if (bottom) {
      projectStore?.fetchAndSetTicketsDataForLane({ laneKey, loadMore: true });
    }
  };

  const inboxBodyClassName = classNames({
    'inbox-body-item': true,
  });

  const inboxClassName = classNames({
    'inbox-sidebox': true,
  });

  const setNextTicketId = (filteredTickets: any[]) => {
    if (!bugStore?.currentBug?.id) {
      return;
    }

    if (filteredTickets.length > 0) {
      const currentTicketIndex = filteredTickets.findIndex(
        (ticket) => ticket.id === bugStore?.currentBug?.id,
      );

      if (currentTicketIndex >= 0) {
        const nextTicket = currentTicketIndex + 1;
        if (
          nextTicket < filteredTickets.length &&
          filteredTickets[nextTicket]
        ) {
          projectStore!.setNextTicketId(filteredTickets[nextTicket].shareToken);
          return;
        }
      }

      projectStore!.setNextTicketId(null);
    }
  };

  const isLoading =
    (!projectStore?.currentTicketsData ||
      !projectStore?.currentTicketsData[filterBugState] ||
      projectStore?.currentTicketsData[filterBugState].isLoading) &&
    projectStore?.currentTicketsData[filterBugState]?.data?.length === 0;

  let filteredTickets =
    projectStore?.currentTicketsData &&
    projectStore?.currentTicketsData[filterBugState]
      ? projectStore?.currentTicketsData[filterBugState].data ?? []
      : [];
  setNextTicketId(filteredTickets);

  // Check if loadMore is ongoing
  var showLoadingIndicator = false;
  if (
    (!projectStore?.currentTicketsData ||
      !projectStore?.currentTicketsData[filterBugState] ||
      projectStore?.currentTicketsData[filterBugState].isLoading) &&
    projectStore?.currentTicketsData[filterBugState]?.data?.length > 0
  ) {
    showLoadingIndicator = true;
  }

  const rowRenderer = ({ index, key, style }) => {
    const bug = filteredTickets[index];

    if (bug.isLoading) {
      return (
        <div key={key} style={style}>
          <InboxItemSkeleton />
        </div>
      );
    }
    return (
      <div key={key} style={style}>
        <InboxItem
          active={bug.shareToken === shareToken}
          key={bug.id}
          onClick={() => {
            if (window.innerWidth <= 1100) {
              projectStore!.openFeedbackItem({ shareToken: bug.shareToken });
            } else {
              navigate(`/projects/${projectId}/inquiries/${bug.shareToken}`);
            }
          }}
          item={bug}
        />
      </div>
    );
  };

  const renderInboxList = () => {
    if (filteredTickets.length === 0 && !isLoading) {
      return (
        <div className="sidebar-placeholder">
          {filterBugState === 'OPEN'
            ? 'All done 🎉. Great job.'
            : 'Nothing here yet.'}
        </div>
      );
    }

    if (isLoading) {
      return (
        <>
          {Array.from(Array(5).keys()).map((val, index) => (
            <div
              key={index}
              style={{
                width: '100%',
              }}
            >
              <InboxItemSkeleton />
            </div>
          ))}
        </>
      );
    }

    return (
      <>
        <AutoSizer>
          {({ height, width }) => {
            return (
              <List
                onScroll={(e) => {
                  handleScroll(e, filterBugState);
                }}
                height={height}
                width={width}
                rowCount={filteredTickets.length}
                style={{
                  transition: 'background-color 0.2s ease',
                }}
                rowRenderer={rowRenderer}
                rowHeight={80}
              />
            );
          }}
        </AutoSizer>
        {showLoadingIndicator && (
          <div className="loading-data">
            <i className="fa-duotone fa-spinner-third fa-spin" />
          </div>
        )}
      </>
    );
  };

  const clearCurrentBug = () => {
    projectStore!.refreshBugsForCurrentProject();
    bugStore!.clearCurrentBug(false);
    navigate(`/projects/${projectId}/inquiries`);
  };

  const getActiveInboxName = () => {
    let inboxName = 'Inbox';

    if (currentInbox.type === 'TEAM') {
      const activeTeam = projectTeams.find(
        (team) => team._id === currentInbox.value,
      );
      if (activeTeam) {
        inboxName = activeTeam.name;
      }
    } else {
      const activeInbox = inboxFilterStates.find(
        (state) => state.value === currentInbox.value,
      );
      if (activeInbox) {
        inboxName = activeInbox.name;
      }
    }

    return inboxName;
  };

  const render = () => {
    return (
      <div className="project-inbox">
        <PageContainer>
          <PageContent hasPadding={false}>
            <ResizableContainer
              minWidth={200}
              maxWidth={400}
              defaultWidth={300}
              storageKey="inboxChatPreviews"
              dragFinished={() => setRerenderChatPreview(!rerenderChatPreview)}
            >
              <div className={inboxClassName}>
                <div className="sidebar-header">
                  <div className="sidebar-header-title">
                    <Title label={getActiveInboxName()} />
                  </div>
                  <div className="filter">
                    <InboxTicketStatusSelection
                      items={filterStates}
                      selected={filterStates.find(
                        (state) => state.value === filterBugState,
                      )}
                      onChange={(value) => {
                        setFilterBugState(value);
                        clearCurrentBug();
                      }}
                    />
                  </div>
                </div>
                <div
                  key={JSON.stringify(rerenderChatPreview)}
                  className="sidebar-list-items"
                >
                  {renderInboxList()}
                </div>
              </div>
            </ResizableContainer>
            <div
              key={shareToken ?? 'notselected'}
              className={inboxBodyClassName}
            >
              <Outlet />
            </div>
          </PageContent>
        </PageContainer>
      </div>
    );
  };

  const renderArchive = () => {
    return (
      <>
        <ProjectArchive type="INQUIRY" />
        {outlet && <TicketDetailScreen />}
      </>
    );
  };

  const collapseClass = classNames({
    flex: true,
    'sub-sidenav-actions-collapsed': subSidenavCollapsed,
  });

  const collabsedSideNavClass = classNames({
    sidenavtitle: true,
    'sidenavtitle--collapsed': subSidenavCollapsed,
  });

  const isExpired = projectStore?.isPlanExpired();
  if (isExpired) {
    return (
      <div className="add-outreach-full">
        <UpgradePlan />
      </div>
    );
  }

  return (
    <SidenavContainer
      isCollapsed={subSidenavCollapsed}
      className="sidenav-container--small"
    >
      <SubSidenav
        title="Inbox"
        isCollapsed={subSidenavCollapsed}
        onCollapseToggle={handleSubSidenavCollapseToggle}
        buttons={
          <div className={collapseClass}>
            <BoardSearchFilter iconOnly />
            <IconButton
              className="primary"
              icon="pen-to-square"
              onClick={() => {
                modalStore!.openModal(MODALTYPE.COMPOSE_MESSAGE);
              }}
            />
          </div>
        }
      >
        {subSidenavCollapsed && <div style={{ height: 50 }} />}
        {inboxFilterStates.map((state) => {
          const getFaIcon = (state) => {
            if (state.value !== 'personalinbox' && state.value !== 'kai') {
              return state.icon;
            }
          };

          const getIconComponent = (state, usersStore) => {
            if (state.value === 'kai') {
              return <BotIcon className="kaiicon" />;
            }
            if (state.value === 'personalinbox') {
              return (
                <UserAvatar
                  small
                  collapsed={subSidenavCollapsed}
                  email={usersStore?.currentUser?.email}
                  imageUrl={usersStore?.currentUser?.profileImageUrl}
                />
              );
            }
          };

          return (
            <SimpleSidenavElement
              className={state.value === 'kai' ? 'mt-20' : ''}
              key={state.value}
              name={state.name}
              onClick={() => {
                clearCurrentBug();
                setCurrentInbox({ type: 'INBOX', value: state.value });
                setShowArchive(false);
              }}
              faIcon={getFaIcon(state)}
              IconComponent={getIconComponent(state, usersStore)}
              isActive={currentInbox.value === state.value && !showArchive}
              notificationCount={getCountForInbox(state.value)}
            />
          );
        })}
        <div className={collabsedSideNavClass}>
          <span className="sidenavtitle-text">Teams</span>
          <div
            className="sidenavtitle-icon"
            onClick={() => {
              navigate(`/projects/${projectId}/settings/project-teams`);
            }}
          >
            <i className="fa-solid fa-pen" />
          </div>
        </div>
        {projectTeams.map((team) => {
          return (
            <SimpleSidenavElement
              key={team._id}
              name={team.name}
              className="simple-sidenav-element--team"
              onClick={() => {
                clearCurrentBug();
                setCurrentInbox({ type: 'TEAM', value: team._id! });
                setShowArchive(false);
              }}
              isActive={
                currentInbox.type === 'TEAM' &&
                currentInbox.value === team._id &&
                !showArchive
              }
              notificationCount={getCountForInbox(team._id, 'TEAM')}
            />
          );
        })}
        <div className={collabsedSideNavClass}>
          <span className="sidenavtitle-text">Views</span>
          <div
            className="sidenavtitle-icon"
            onClick={() => {
              modalStore?.showModal(
                <TicketViewModal ticketType={currentFeedbackType?.type} />,
                MODALSIZE.XL_NOPAD,
              );
            }}
          >
            <i className="fa-solid fa-pen" />
          </div>
        </div>

        {customViews.map((view) => {
          const isActive = currentInbox.value._id === view._id;
          return (
            <SimpleSidenavElement
              key={view._id}
              name={view.name}
              onClick={() => {
                clearCurrentBug();
                setCurrentInbox({ type: 'CUSTOM_VIEW', value: view });
                setShowArchive(false);
              }}
              faIcon={isActive ? 'pencil' : ''}
              iconEnd
              isActive={
                currentInbox.type === 'CUSTOM_VIEW' && isActive && !showArchive
              }
              iconClick={() => {
                modalStore?.showModal(
                  <TicketViewModal
                    ticketType={currentFeedbackType?.type}
                    ticketView={view}
                  />,
                  MODALSIZE.XL_NOPAD,
                );
              }}
            />
          );
        })}
        <SimpleSidenavElement
          key="add-view"
          name="Add view"
          onClick={() => {
            modalStore?.showModal(
              <TicketViewModal ticketType={currentFeedbackType?.type} />,
              MODALSIZE.XL_NOPAD,
            );
          }}
          faIcon="plus"
        />
        <SimpleSidenavElement
          key="archive"
          name="Archive"
          onClick={() => {
            clearCurrentBug();
            setShowArchive(true);
          }}
          faIcon="box-archive"
          isActive={showArchive}
        />
      </SubSidenav>
      {showArchive ? renderArchive() : render()}
    </SidenavContainer>
  );
};

export default inject(
  'projectStore',
  'usersStore',
  'bugStore',
  'modalStore',
)(observer(ProjectInbox));
