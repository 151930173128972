import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper';
import { BarChartData } from 'models/Chart';
import React from 'react';
import Chart from 'react-apexcharts';
import './StatisticBarChart.scss';
import Loading from 'components/Loading/Loading';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import { getEmojiForVal } from 'helper/ScoreFormater';

export const formatAxisLabelForSeconds = (value) => {
  if (value >= 3600) {
    return (value / 3600).toFixed(2) + ' hrs';
  } else if (value >= 60) {
    return (value / 60).toFixed(2) + ' mins';
  } else {
    try {
      return `${parseFloat(value).toFixed(2)} secs`;
    } catch (e) {
      return value + ' secs';
    }
  }
};

export const formatAxisLabelForCSAT = (value) => {
  if (isNaN(value)) {
    return '';
  }

  if (value > 10) {
    return '';
  }

  return getEmojiForVal(value * 10);
};

interface StatisticBarChartProps {
  chartData?: BarChartData | undefined | null;
  isLoading?: boolean;
  formatter?: (value: number) => string;
  infoText?: string;
  title?: string;
  colors?: any;
  stacked?: boolean;
}

const StatisticBarChart = ({
  chartData,
  isLoading,
  formatter,
  infoText,
  title,
  colors,
  stacked = false,
}: StatisticBarChartProps) => {
  const chartHasData = () => {
    return (
      chartData?.dataSets &&
      chartData?.dataSets.length > 0 &&
      chartData?.dataSets[0].data &&
      chartData?.dataSets[0].data.length > 0
    );
  };

  const prepareSeriesData = () => {
    return chartData?.dataSets.map((data) => {
      return {
        data: data.data.map((item) => {
          return { x: item.label, y: item.value };
        }),
        name: data.label ?? title ?? chartData?.title,
      };
    });
  };

  const _buildNoData = () => {
    return (
      <Column>
        <div className="apexcharts-title-text">{title ?? chartData?.title}</div>
        <span>No data to display</span>
      </Column>
    );
  };

  return (
    <ComponentWrapper className="statistic-bar-chart" infoText={infoText}>
      {(isLoading || !chartData) && <Loading />}
      {!chartHasData() && !isLoading && _buildNoData()}
      {!isLoading && chartHasData() && (
        <Chart
          type="bar"
          options={{
            title: {
              text: title ?? chartData?.title,
              margin: 25,
            },
            chart: {
              toolbar: {
                show: false,
              },
              stacked: stacked,
            },
            yaxis: {
              labels: {
                formatter: formatter,
              },
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
              },
            },
            dataLabels: {
              enabled: false,
            },
            colors: [
              function ({ seriesIndex, dataPointIndex, w }) {
                if (!colors) {
                  return '#2142E7';
                }
                const currentLabel = w.globals.seriesNames[seriesIndex];

                return colors[currentLabel] || '#2142E7';
              },
            ],
            tooltip: {
              enabled: true,
            },
          }}
          series={prepareSeriesData()}
          height={360}
          width="100%"
        />
      )}
    </ComponentWrapper>
  );
};

export default StatisticBarChart;
