import { actionTypes } from 'components/FeedbackActionQuestionListItem/FeedbackActionQuestionListItem';
import { FeedbackAction } from 'models/Project';
import './FeedbackActionQuestionEditorAddOptions.scss';
import { ReactComponent as IntroIcon } from 'assets/icons/intro.svg';
import { useClickedOutside } from 'services/Helper';
import { useRef } from 'react';

interface FeedbackActionQuestionEditorAddOptionsProps {
  feedbackAction: FeedbackAction;
  isSurvey?: boolean;
  onQuestionAdded: (type: string) => void;
  closeAddOptions: () => void;
}

const FeedbackActionQuestionEditorAddOptions = ({
  feedbackAction,
  isSurvey,
  onQuestionAdded,
  closeAddOptions,
}: FeedbackActionQuestionEditorAddOptionsProps) => {
  const localActionTypes =
    isSurvey ||
      (feedbackAction.introMessage &&
        Object.keys(feedbackAction.introMessage).length > 0)
      ? actionTypes
      : [
        { label: 'Welcome message', icon: IntroIcon, value: 'intro' },
        ...actionTypes,
      ];
  const wrapperRef = useRef(null);
  useClickedOutside(wrapperRef, () => {
    closeAddOptions();
  });

  return (
    <div
      className="feedback-action-questions-container-addoptions"
      ref={wrapperRef}
    >
      {localActionTypes.map((actionType) => {
        return (
          <div
            className="feedback-action-questions-container-addoptions-item"
            onClick={() => {
              if (actionType.value === 'intro') {
                feedbackAction.introMessage = {
                  localized: { en: 'Welcome 👋' },
                };
              } else if (actionType.value === 'priority-selection') {
                const defaultValue = {
                  id: Math.random().toString(36).substring(7),
                  label: { localized: { en: 'Low' } },
                  value: "LOW",
                };

                feedbackAction.form.push({
                  title: { localized: { en: 'Set the priority for this issue.' } },
                  type: actionType.value,
                  name: Math.random().toString(36).substring(7),
                  required: false,
                  defaultValue: defaultValue.id,
                  choices: [
                    defaultValue,
                    {
                      id: Math.random().toString(36).substring(7),
                      label: { localized: { en: 'Medium' } },
                      value: "MEDIUM",
                    },
                    {
                      id: Math.random().toString(36).substring(7),
                      label: { localized: { en: 'High' } },
                      value: "HIGH",
                    }
                  ],
                });
              } else {
                feedbackAction.form.push({
                  title: { localized: { en: 'What would you like to ask?' } },
                  type: actionType.value,
                  fromNumber: 0,
                  upToNumber: 10,
                  lowestValueLabel: { localized: { en: 'Not likely at all' } },
                  highestValueLabel: { localized: { en: 'Extremely likely' } },
                  name: Math.random().toString(36).substring(7),
                  required: false,
                });
              }

              setTimeout(() => {
                onQuestionAdded(actionType.value);
                closeAddOptions();
              }, 0);
            }}
          >
            <div className="feedback-action-questions-container-addoptions-item-icon">
              <actionType.icon />
            </div>
            <div className="feedback-action-questions-container-addoptions-item-title">
              {actionType.label}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FeedbackActionQuestionEditorAddOptions;
