const GleapDemoFrame = () => {
    return (
        <div style={{ position: 'relative', paddingBottom: 'calc(49.24137931034483% + 41px)', height: '0', width: '100%' }}>
            <iframe
                src="https://demo.arcade.software/MZd2dBcBQmpfezpNzw2Z?embed&show_copy_link=true"
                title="Gleap Demo"
                frameBorder="0"
                loading="lazy"
                allowFullScreen
                allow="clipboard-write"
                style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', colorScheme: 'light' }}
            ></iframe>
        </div>
    );
};

export default GleapDemoFrame;