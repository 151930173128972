import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import { checkIntegrationData } from 'helper/Integration';
import { inject, observer } from 'mobx-react';
import { INTEGRATION } from 'models/Integration';
import React, { useEffect, useState } from 'react';
import { LinearIntegrationStore } from 'stores/integrations/LinearIntegrationStore';
import { ProjectStore } from 'stores/private/ProjectStore';

interface LinearConfigurationProps {
  integrationID: string;
  index: number;
  linearIntegrationStore?: LinearIntegrationStore;
  projectStore?: ProjectStore;
  onChange: (data: any) => any;
}

const LinearConfiguration = ({
  integrationID,
  index,
  linearIntegrationStore,
  projectStore,
  onChange,
}: LinearConfigurationProps) => {
  const project = projectStore?.currentProject;

  const [currentIntegrationInfo, setCurrentIntegrationInfo] = useState(
    {} as any,
  );

  const teams = linearIntegrationStore?.teams;
  const projects = linearIntegrationStore?.projects;

  const [teamID, setTeamID] = useState('');
  const [projectID, setProjectID] = useState('');

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    if (project && isMounted) {
      initialize();
    }
  }, [project, isMounted]);

  useEffect(() => {
    saveIntegrationInfo();
  }, [teamID, projectID]);

  const initialize = async () => {
    linearIntegrationStore!.setIntegrationID(integrationID);
    await linearIntegrationStore!.getTeams();
    await linearIntegrationStore!.getProjects();

    if (
      !checkIntegrationData({
        project,
        integrationName: INTEGRATION.LINEAR,
        integrationID,
        actionIndex: index,
      })
    ) {
      return;
    }

    const integrationInfo =
      project?.integrations?.linear[integrationID]?.actions[index]
        ?.integrationInfo;

    setCurrentIntegrationInfo(integrationInfo);
  };

  const chooseTeam = async (teamId) => {
    setTeamID(teamId);
  };

  const chooseProject = async (projectId) => {
    setProjectID(projectId);
  };

  const saveIntegrationInfo = () => {
    const integrationInfo = {
      teamID,
      projectID,
    };

    onChange(integrationInfo);
  };

  return (
    <div className="configuration-container mt-30">
      <SelectDropDown
        className="mb-15"
        label="Team"
        labelPropertyName="name"
        valuePropertyName="id"
        selectedItem={teams.find(
          // eslint-disable-next-line no-underscore-dangle
          (element) => element.id === currentIntegrationInfo?.teamID,
        )}
        isLoading={teams.length === 0}
        items={teams}
        onChange={(option) => {
          chooseTeam(option.id);
        }}
      />
      <SelectDropDown
        className="mb-15"
        label="Project (Optional)"
        labelPropertyName="name"
        valuePropertyName="id"
        selectedItem={projects.find(
          // eslint-disable-next-line no-underscore-dangle
          (element) => element.id === currentIntegrationInfo?.projectID,
        )}
        items={projects}
        onChange={(option) => {
          chooseProject(option.id);
        }}
      />
    </div>
  );
};

export default inject(
  'linearIntegrationStore',
  'projectStore',
)(observer(LinearConfiguration));
