import iosIcon from 'assets/icons/iossdk.png';
import androidIcon from 'assets/icons/platforms/android.png';
import jsIcon from 'assets/icons/platforms/JS-Logo.png';
import jsWebIcon from 'assets/icons/platforms/browser-duotone.svg';
import flutterIcon from 'assets/icons/platforms/flutter.svg';
import ionicIcon from 'assets/icons/platforms/Ionic_Logo.svg';
import cordovaIcon from 'assets/icons/platforms/cordova.png';
import wordpressIcon from 'assets/icons/platforms/wordpress.png';
import angularIcon from 'assets/icons/platforms/angular.png';
import vuejsIcon from 'assets/icons/platforms/vuejs.png';
import reactIcon from 'assets/icons/platforms/react.png';
import wixIcon from 'assets/icons/platforms/wixlogo.png';
import squarespaceIcon from 'assets/icons/platforms/squarespace.png';
import weeblyIcon from 'assets/icons/platforms/weeblylogo.png';
import webflowIcon from 'assets/icons/platforms/webflowIcon.png';
import nextJSIcon from 'assets/icons/platforms/nextjs-black-logo.svg';
import expoIcon from 'assets/icons/platforms/ExpoIcon.svg';
import restIcon from 'assets/icons/platforms/rest.svg';
import nodejsIcon from 'assets/icons/platforms/nodejs.svg';
import googleTagManagerIcon from 'assets/icons/platforms/GoogleTagManagerIcon.svg';
import highlevelIcon from 'assets/icons/platforms/highlevel.png';

enum PLATFORM {
  'ANDROID',
  'IOS',
  'REACTNATIVE',
  'FLUTTER',
  'REACT',
  'ANGULAR',
  'VUE',
  'WORDPRESS',
  'JS',
  'JSWEBSITE',
  'WIX',
  'SQUARESPACE',
  'WEEBLY',
  'WEBFLOW',
  'GOOGLETAGMANAGER',
  'EXPO',
  'NEXTJS',
  'IONIC',
  'CORDOVA',
  'REST',
  'NODEJS',
  'HIGHLEVEL',
}
export const getPlatformForKey = (key) => {
  for (let i = 0; i < Platforms.length; i++) {
    if (Platforms[i].key === key) {
      return Platforms[i];
    }
  }
  return null;
};

export const Platforms = [
  {
    key: PLATFORM.JSWEBSITE,
    image: jsWebIcon,
    title: 'Website',
    description: 'Install the Gleap on your website.',
    type: ['websites', 'webshops', 'webapps'],
  },
  {
    key: PLATFORM.JS,
    image: jsIcon,
    title: 'JavaScript',
    description: 'Install the SDK for JavaScript projects.',
    type: ['websites', 'webshops', 'webapps'],
  },
  {
    key: PLATFORM.REACT,
    image: reactIcon,
    title: 'React',
    description: 'Install the SDK for React projects.',
    type: ['webapps'],
  },
  {
    key: PLATFORM.ANGULAR,
    image: angularIcon,
    title: 'Angular',
    description: 'Install the SDK for Angular projects.',
    type: ['webapps'],
  },
  {
    key: PLATFORM.VUE,
    image: vuejsIcon,
    title: 'Vue.js',
    description: 'Install the SDK for Vue.js projects.',
    type: ['webapps'],
  },
  {
    key: PLATFORM.WORDPRESS,
    image: wordpressIcon,
    title: 'WordPress',
    description: 'Install the Plugin for WordPress websites.',
    type: ['websites', 'webshops'],
  },
  {
    key: PLATFORM.NEXTJS,
    image: nextJSIcon,
    title: 'NextJS',
    description: 'Add the SDK to your NextJS app.',
    type: ['websites', 'webapps'],
  },
  {
    key: PLATFORM.IOS,
    image: iosIcon,
    title: 'iOS',
    description: 'Install the SDK for iOS projects.',
    type: ['mobileapps'],
  },
  {
    key: PLATFORM.ANDROID,
    image: androidIcon,
    title: 'Android',
    description: 'Install the SDK for Android projects.',
    type: ['mobileapps'],
  },
  {
    key: PLATFORM.REACTNATIVE,
    image: reactIcon,
    title: 'ReactNative',
    description: 'Install the SDK for ReactNative projects.',
    type: ['mobileapps'],
  },
  {
    key: PLATFORM.EXPO,
    image: expoIcon,
    title: 'Expo',
    description: 'Add the SDK to your RN Expo app.',
    type: ['mobileapps', 'webapps'],
  },
  {
    key: PLATFORM.FLUTTER,
    image: flutterIcon,
    title: 'Flutter',
    description: 'Install the SDK for Flutter projects.',
    type: ['mobileapps', 'webapps'],
  },
  {
    key: PLATFORM.IONIC,
    image: ionicIcon,
    title: 'Ionic / Capacitor',
    description: 'Install the SDK for Ionic / Capacitor projects.',
    type: ['mobileapps', 'webapps'],
  },
  {
    key: PLATFORM.CORDOVA,
    image: cordovaIcon,
    title: 'Cordova',
    description: 'Install the SDK for Cordova projects.',
    type: ['mobileapps'],
  },
  {
    key: PLATFORM.WIX,
    image: wixIcon,
    title: 'WIX',
    description: 'Add the SDK to your WIX website.',
    type: ['websites', 'webshops'],
  },
  {
    key: PLATFORM.SQUARESPACE,
    image: squarespaceIcon,
    title: 'Squarespace',
    description: 'Add the SDK to your Squarespace website.',
    type: ['websites', 'webshops'],
  },
  {
    key: PLATFORM.WEEBLY,
    image: weeblyIcon,
    title: 'Weebly',
    description: 'Add the SDK to your Weebly website.',
    type: ['websites', 'webshops'],
  },
  {
    key: PLATFORM.WEBFLOW,
    image: webflowIcon,
    title: 'Webflow',
    description: 'Add the SDK to your Webflow website.',
    type: ['websites', 'webshops'],
  },
  {
    key: PLATFORM.GOOGLETAGMANAGER,
    image: googleTagManagerIcon,
    title: 'Google Tag Manager',
    description: 'Use the Google Tag Manager to add the SDK to your website.',
    type: ['websites', 'webshops'],
  },
  {
    key: PLATFORM.HIGHLEVEL,
    image: highlevelIcon,
    title: 'HighLevel',
    description: 'Integrate Gleap for your HighLevel agencies.',
    type: ['websites'],
  },
];

export const ApiPlatforms = [
  {
    key: PLATFORM.REST,
    image: restIcon,
    title: 'REST api',
    description: 'Use our REST api to track events.',
    url: "https://docs.gleap.io/server/rest-api"
  },
  {
    key: PLATFORM.NODEJS,
    image: nodejsIcon,
    title: 'Node.js',
    description: 'Use our Node.js library to track events.',
    url: "https://docs.gleap.io/server/nodejs"
  },
];

export default PLATFORM;
