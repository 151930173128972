export type PaginationDataList<Type> = {
  data: Type[];
  pageIndex: number;
  itemsInPage: number;
  isLoading: boolean;
  metaData?: {
    currentFeedbackType: string;
    appliedQuery?: any;
  };
  totalItems?: number;
  hasMore?: boolean;
};

export const getSkipAndLimitFromPage = (args: {
  pageIndex: number;
  itemsInPage: number;
}) => {
  const skip: number = args.pageIndex * args.itemsInPage;
  const limit: number = args.itemsInPage;

  return { skip, limit };
};
