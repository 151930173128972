import React, { useEffect, useState } from 'react'
import './NPSHistoryChart.scss'
import Chart from 'react-apexcharts';
import { calculateNPS } from 'helper/CalculateNPS';
import moment from 'moment';
import lodash from 'lodash';

interface NPSHistoryChartProps {
    stats: any;
    formItem: any;
}

const NPSHistoryChart = ({ stats, formItem }: NPSHistoryChartProps) => {
    const [historyData, setHistoryData] = useState<any[]>([]);

    useEffect(() => {
        generateData();
    }, [stats])

    const generateData = () => {
        const sorted = stats.sort((a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

        const groupedByMonth = lodash.groupBy(sorted, (stat) =>
            moment(stat.createdAt).format('YY-MMM')
        );
        const months = Object.keys(groupedByMonth);

        const cumulativeDataByMonth: any = months.reduce((acc: any, month, index) => {
            const monthsToInclude = months.slice(0, index + 1);
            const combinedMonthData = lodash.flatMap(monthsToInclude, (w) => groupedByMonth[w]);
            const score = calculateNPS(combinedMonthData, formItem);

            acc.push({ month: month, npsScore: score.npsScore });
            return acc;
        }, []);


        setHistoryData(cumulativeDataByMonth);
    }

    return (
        <div className='nps-history-container'>
            <Chart
                options={{
                    chart: {
                        id: "basic-bar",
                        toolbar: {
                            show: false
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        width: [4, 0, 0]
                    },
                    xaxis: {
                        categories: historyData.map((data, index) => data.month),
                    },
                    colors: ['#19b097', '#f4ba56', '#ea555a'],
                }}
                series={[{
                    name: "NPS Score",
                    type: "line",
                    data: historyData.map((data) => data.npsScore)
                },]}
                type="line"
                height={360}
                width={1000}
            />
        </div>
    )
}

export default NPSHistoryChart